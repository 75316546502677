import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup, Popover, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';

export default function SelectThresholdDurationModal(props) {
    const classes = useStyles();
    const [type, setType] = useState(props.type)
    const [duration, setDuration] = useState(props.duration)

    const handleSelectNoneClick = () => {
        setType(-1)
        setDuration('')
    }

    const handleFilterClick = () => {
        props.onFilterClick(duration, type)
        props.hideModal()
    }

    const handleChange = (event) => {
        setDuration(event.target.value);
    }

    return <Popover open
                    anchorEl={props.anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    className={classes.popover}
                    onClose={props.hideModal}>
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.headerInner}>
                    <Typography variant={'subtitle1'}>Threshold Duration</Typography>
                </div>
                <Button variant="text"
                        color="primary"
                        className={classes.headerButton}
                        onClick={handleSelectNoneClick}>Select None</Button>
                <Button variant="contained"
                        color="primary"
                        className={classes.headerButton}
                        onClick={handleFilterClick}>Filter</Button>
            </div>
            <div className={classes.body}>
                <Typography variant={'body2'} className={classes.subtitle}>Downtime exceeding</Typography>
                <div className={classes.inputDiv}>
                    <TextField variant='filled'
                               className={classes.durationTextField}
                               size='small'
                               type='number'
                               hiddenLabel
                               onChange={handleChange}
                               value={duration}
                               inputProps={{className: classes.durationTextFieldRoot }} />

                    <Button variant={type === 1 ? 'outlined' : 'text'}
                            className={classes.button}
                            color={type === 1 ? 'secondary' : 'default'}
                            onClick={() => setType(1)}>Minutes</Button>
                    <Button variant={type === 2 ? 'outlined' : 'text'}
                            className={classes.button}
                            color={type === 2 ? 'secondary' : 'default'}
                            onClick={() => setType(2)}>Hours</Button>
                </div>
            </div>
        </div>
    </Popover>
}

//######## STYLE ########
const useStyles = makeStyles(theme => ({
    popover:{
        marginTop: 32
    },
    container: {
        width: 525,
        maxHeight: 490,
        background: '#fff',
        borderRadius: 8,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 36,
        width: '100%',
        paddingTop: 24,
        paddingBottom: 8
    },
    headerInner: {
        flex: 1,
        marginLeft: 24
    },
    headerButton: {
        marginRight: '24px !important'
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 408,
        width: '100%',
        overflowY: 'scroll',
        marginBottom: 30,
        padding: 30
    },
    durationTextField: {
        width: 84,
        marginRight: 10
    },
    durationTextFieldRoot: {
        backgroundColor: '#F2F2F2'
    },
    subtitle: {
        color: '#666',
        marginBottom: 30
    },
    button: {
        marginRight: 5,
        marginLeft: 5,
        width: 84,

    },
    inputDiv: {
        display: 'flex',
        alignItems: 'center'
    }
}));