import React, {Component} from 'react';
import {FontIcon} from "material-ui";
import {Button, FormControl, InputLabel, Menu, MenuItem, Select, Typography} from "@mui/material";

class UserManagementOrgFactoryDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            open_org: false,
            open_factory: false,
            anchorOrigin: {
                horizontal: 'left',
                vertical: 'bottom',
            },
            targetOrigin: {
                horizontal: 'left',
                vertical: 'bottom',
            },
        }
    }

    /* -------- LIFECYCLE METHODS -------- */
    componentDidMount() {
        if(!this.props.factory) {
            let factoryEl = window.document.getElementById("factory-dropdown")
            this.setState({
                anchorEl: factoryEl,
                open_factory: true
            })
        }
    }

    /* -------- FUNCTIONS -------- */
    getOrgName = () => {
        if(this.props.organization && this.props.organizations) {
            return this.props.organizations.find((org) => org.id === this.props.organization).name
        }
    }

    handleOrgOpenMenu = (event) => {
        event.preventDefault();
        this.setState({
            open_org: true,
            anchorEl: event.currentTarget,
        });
    }

    handleOrgClose = () => {
        this.setState({
            anchorEl: null,
            open_org: false,

        });
    }

    handleOrgMenuClick = (id) => {
        this.props.onOrgSelected(id)
        let factoryEl = window.document.getElementById("factory-dropdown")
        this.setState({
            anchorEl: factoryEl,
            open_org: false,

        }, () => {
            this.setState({
                open_factory: true
            })
        });
    }

    getFactoryName = () => {
        let org = this.props.organizations.find(org => org.id === this.props.organization)
        if(org && org.factories) {
            let factory = org.factories.find(factory => factory.id === this.props.factory)
            return factory ? factory.name : "Select Factory"
        }
    }

    handleFactoryOpenMenu = (event) => {
        event.preventDefault();
        this.setState({
            open_factory: true,
            anchorEl: event.currentTarget,
        });
    }

    handleFactoryClose = () => {
        if(this.props.factory) {
            this.setState({
                anchorEl: null,
                open_factory: false
            });
        }
    }

    handleFactoryMenuClick = (id) => {
        this.props.onFactorySelected(id)
        this.setState({
            anchorEl: null,
            open_factory: false,

        })
    }

    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    renderFactoryMenuItems = () => {
        let org = this.props.organizations.find(org => org.id === this.props.organization)
        if(org && org.factories) {
            return org.factories.map((factory) => {
                    return <MenuItem  value={factory.name}
                                      key={"factory-"+factory.id}
                                      onClick={this.handleFactoryMenuClick.bind(this, factory.id)}>
                        {factory.name}
                    </MenuItem>
                })
        }
    }


    render() {
        return <div>
            <div className="user-management__subhead">
                <Typography sx={{ fontWeight: "400 !important", fontSize: "1.875rem" }} variant='h5' className={""}>User Settings</Typography>
                <div style={{ marginBottom: "12px" }} className="user-management__subhead-selectors">
                    <div style={{ marginRight: "24px" }} className="user-management__subhead-text">
                        <FormControl variant="standard" sx={{ maxWidth: 300, minWidth: 200 }}>
                            <InputLabel id="org_select_label">Organization</InputLabel>
                            <Select
                                labelId="org_select_label"
                                id="org_select"
                                value={`${this.getOrgName()}`}
                                label="Organization"
                            >
                                {this.props.organizations.map((org) => {
                                    return <MenuItem  value={org.name}
                                                    onClick={this.handleOrgMenuClick.bind(this, org.id)}
                                                    key={"org-"+org.id}>{org.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="user-management__subhead-text">
                        <FormControl variant="standard" sx={{ maxWidth: 300, minWidth: 200 }}>
                            <InputLabel id="factory_select_label">Factory</InputLabel>
                            <Select
                                labelId="factory_select_label"
                                id="factory_select"
                                value={`${this.getFactoryName()}`}
                                label="Factory"
                            >
                                {this.renderFactoryMenuItems()}
                            </Select>
                        </FormControl>
                        {/* <label className="user-management--dropdown--title">Factory</label>
                        <Button
                            id="factory-dropdown"
                            className='user-management--dropdown'
                            onClick={this.handleFactoryOpenMenu.bind(this)}
                            style={{fontSize: "24px", color: "#000", fontWeight: 600, paddingLeft: 0, textTransform: "initial"}}
                            endIcon={<FontIcon style={{fontSize: "24px", color: "#000", fontWeight: 600, paddingLeft: 0}} className="zmdi zmdi-caret-down"/>}>
                            {this.getFactoryName()}
                        </Button>
                        <Menu open={this.state.open_factory}
                              anchorEl={this.state.anchorEl}
                              getContentAnchorEl={null}
                              anchorOrigin={this.state.anchorOrigin}
                              onClose={this.handleFactoryClose}>
                            {this.renderFactoryMenuItems()}
                        </Menu> */}
                    </div>
                </div>
            </div>
        </div>
    }
}

export default UserManagementOrgFactoryDropdown