import React, { Component } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {
    Button,
    Tab,
    Tabs,
    Dialog,
    Paper,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    IconButton
} from '@material-ui/core';
import { TrendingFlat, KeyboardBackspace, ArrowRightAlt, ArrowBack, ArrowForward } from '@material-ui/icons';

class MultiDatesPicker extends Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleDayRangeClick = this.handleDayRangeClick.bind(this);
        this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
        this.state = {
            tab: props.onlyRange ? 0 : props.type === 'days' ? 0 : 1,
            selectedDays: [],
            from: null,
            to: null,
            enteredTo: null,
            type: 'days',
            disabled: true,
            currentDate: new Date()
        };
    }

    /* -------- LIFECYCLE METHODS -------- */
    componentDidMount() {
        if (this.props.type === 'days') {
            this.setState({
                selectedDays: [...this.props.selectedDays],
                type: 'days',
                disabled: this.props.selectedDays.length === 0
            });
        } else {
            this.setState({
                from: this.props.from,
                to: this.props.to,
                enteredTo: this.props.to,
                type: 'range',
                disabled: this.props.from === null || this.props.to === null
            });
        }
    }

    /* -------- FUNCTIONS -------- */

    onTabChange = (e, i) => {
        if (i === 0) {
            if (this.props.onlyRange) {
                this.setState({
                    tab: 0,
                    type: 'range',
                    selectedDays: [],
                    disabled: true
                });
            }
            else {
                this.setState({
                    tab: 0,
                    type: 'days',
                    from: null,
                    to: null,
                    enteredTo: null,
                    disabled: true
                });
            }
        } else {
            this.setState({
                tab: 1,
                type: 'range',
                selectedDays: [],
                disabled: true
            });
        }
    };

    handleDayClick(day, { selected }) {
        let updatedSelectedDays = [...this.state.selectedDays];
        if (selected) {
            const selectedIndex = updatedSelectedDays.findIndex(selectedDay => DateUtils.isSameDay(selectedDay, day));
            updatedSelectedDays.splice(selectedIndex, 1);
        } else {
            updatedSelectedDays.push(day);
        }
        let disabled = updatedSelectedDays.length === 0;
        this.setState({ selectedDays: updatedSelectedDays, disabled });
    }

    handleDayRangeClick(day) {
        const { from, to } = this.state;
        if (from && to && day >= from && day <= to) {
            this.setState({
                from: null,
                to: null,
                enteredTo: null,
                disabled: true
            });
            return;
        }
        if (this.isSelectingFirstDay(from, to, day)) {
            this.setState({
                from: day,
                to: null,
                enteredTo: null,
                disabled: true
            });
        } else if (day < new Date()) {
            this.setState({
                to: day,
                enteredTo: day,
                disabled: false
            });
        }
    }

    handleDayMouseEnter(day) {
        const { from, to } = this.state;
        if (!this.isSelectingFirstDay(from, to, day) && day <= this.state.currentDate) {
            this.setState({
                enteredTo: day
            });
        }
    }

    isSelectingFirstDay = (from, to, day) => {
        const isRangeSelected = from && to;
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        return !from || isBeforeFirstDay || isRangeSelected;
    };

    handleSave = () => {
        if (this.state.type === 'range') {
            this.props.setReportRangeDates(this.state.from, this.state.to); //range
        } else {
            this.props.setReportMultipleDates(this.state.selectedDays);
        }
    };

    handleClose = () => {
        this.setState({
            selectedDays: [],
            from: null,
            to: null,
            enteredTo: null,
            type: 'day'
        });

        this.props.handleClose();
    };

    /* -------- API CALLS -------- */

    /* -------- RENDERS -------- */
    renderNavbar = ({ showNextButton, onPreviousClick, onNextClick, className }) => {
        return (
            <div className={className}>
                <Button className="navButton" disableTouchRipple onClick={() => onPreviousClick()}>
                    <ArrowBack />
                </Button>
                <Button
                    className="navButton"
                    disableTouchRipple
                    style={{ opacity: showNextButton ? 1 : 0.3 }}
                    disabled={!showNextButton}
                    onClick={() => onNextClick()}
                >
                    <ArrowForward />
                </Button>
            </div>
        );
    };

    render() {
        const { from, enteredTo, type, disabled, tab } = this.state;
        const modifiers = { start: from, end: enteredTo, today: new Date() };
        const day_modifiers = { today: new Date() };
        const modifiersStyles = { today: { color: '#000' } };
        const disabledDays = { before: this.state.from, after: new Date() };
        const selectedDays = [from, { from, to: enteredTo }];

        return (
            <Dialog
                className="date-picker-dialog"
                open={true}
                onClose={this.props.hideModal}
                scroll="paper"
                fullWidth={true}
                classes={{ paperFullWidth: 'modal__content__date_range_picker' }}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle disableTypography style={{ paddingBottom: '4px' }}>
                    <Typography variant="subtitle1">Select Dates</Typography>
                </DialogTitle>
                <DialogContent style={{ maxHeight: '350px', padding: '8px', overflowY: 'hidden' }}>
                    <div className="date-picker-wrapper">
                        <Paper square>
                            <Tabs
                                className="tabs"
                                value={this.state.tab}
                                onChange={this.onTabChange}
                                variant="fullWidth"
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                {!this.props.onlyRange ? (
                                    <Tab label="Days" style={{ opacity: type === 'days' ? 1 : 0.3 }} />
                                ) : null}
                                <Tab label="Date Range" style={{ opacity: type !== 'days' ? 1 : 0.3 }} />
                            </Tabs>
                        </Paper>
                        {tab === 0 && (
                            <>
                            {this.props.onlyRange ? (
                                <DayPicker
                                    navbarElement={this.renderNavbar.bind(this)}
                                    className="selectable"
                                    numberOfMonths={2}
                                    fromMonth={from}
                                    selectedDays={selectedDays}
                                    disabledDays={{ after: new Date() }}
                                    modifiers={modifiers}
                                    modifiersStyles={modifiersStyles}
                                    toMonth={new Date()}
                                    onDayClick={this.handleDayRangeClick}
                                    onDayMouseEnter={this.handleDayMouseEnter}
                                />
                            ) : (
                                <DayPicker
                                    navbarElement={this.renderNavbar.bind(this)}
                                    selectedDays={this.state.selectedDays}
                                    numberOfMonths={2}
                                    disabledDays={{ after: new Date() }}
                                    modifiers={day_modifiers}
                                    modifiersStyles={modifiersStyles}
                                    toMonth={new Date()}
                                    onDayClick={this.handleDayClick}
                                />
                            )}
                            </>
                        )}
                        {tab === 1 && (
                            <DayPicker
                                navbarElement={this.renderNavbar.bind(this)}
                                className="selectable"
                                numberOfMonths={2}
                                fromMonth={from}
                                selectedDays={selectedDays}
                                disabledDays={{ after: this.state.currentDate }}
                                modifiers={modifiers}
                                modifiersStyles={modifiersStyles}
                                toMonth={this.state.currentDate}
                                onDayClick={this.handleDayRangeClick}
                                onDayMouseEnter={this.handleDayMouseEnter}
                            />
                        )}
                    </div>
                </DialogContent>
                <DialogActions style={{ marginBottom: '10px', padding: '6px 12px' }}>
                    <div>
                        <Button variant={'text'} color={'primary'} onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button variant={'text'} disabled={disabled} color={'primary'} onClick={this.handleSave}>
                            OK
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default MultiDatesPicker;
