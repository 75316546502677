import React, {Component} from 'react';
import moment from "moment-timezone";

class CustomTimeSpeedTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    showTime = () => {
        const { payload } = this.props;

        if (!payload[0] || !payload[0].payload) {
            return '';
        }
        return 'Time: ' + moment(payload[0].payload.time).tz(this.props.timeZone).format('LTS');

    }

    showSpeed = () => {
        const { payload } = this.props;

        if (!payload[0] || !payload[0].payload) {
            return '';
        }
        return 'Speed: ' + payload[0].payload.amt;

    }


    render() {
        const { active } = this.props;

        if (active) {
            const { payload, label } = this.props;

            if(payload) {
                return (
                    <div className="tooltip">
                        <p className="tooltip__reason">{this.showTime()}</p>
                        <p className="tooltip__time-lost">{this.showSpeed()}</p>
                    </div>
                );
            } else {
                return null;
            }
        }

        return null;
    }
}

export default CustomTimeSpeedTooltip
