import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    margin: {},
    typo: {
        ...theme.typography.body1,
        padding: '10px',
        paddingTop: '8px',
        color: '#999'
    }
}));

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #999',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        '&:focus': {
            borderRadius: 4
            //borderColor: '#999',
            //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        }
    }
}))(InputBase);

const PerPage = ({ sendResponseToUpPerPage }) => {
    const classes = useStyles();
    const [perPage, setPerPage] = useState(JSON.parse(localStorage.getItem('perPage')) || 15);
    const handleChangePage = event => {
        localStorage.setItem('perPage', JSON.stringify(event.target.value));
        setPerPage(event.target.value);
        sendResponseToUpPerPage(event.target.value);
    };

    useEffect(() => {
        const checkLocalStorage = JSON.parse(localStorage.getItem('perPage'));
        if (!checkLocalStorage) {
            localStorage.setItem('perPage', JSON.stringify(15));
            setPerPage(15);
        }
    }, []);
    return (
        <Box mt={1.3} display="flex">
            <div className={classes.typo}>Show</div>
            <FormControl className={classes.margin}>
                <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={perPage}
                    onChange={handleChangePage}
                    input={<BootstrapInput />}
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={100}>All</MenuItem>
                </Select>
            </FormControl>
            <div className={classes.typo}>per page</div>
        </Box>
    );
};

export default PerPage;
