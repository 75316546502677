import { DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Dialog } from 'material-ui';
import React, { useState } from 'react';

const WeekdayPickerModal = (props) => {

    const [newDay, setNewDay] = useState(props.day)
    const [newDayDisplay, setNewDayDisplay] = useState(props.weekday[props.day])

    const onDayChange = (number) => {
        setNewDay(number)
        setNewDayDisplay(props.weekday[number])
    }

    const onSaveWeekday = () => {
        props.onSave(newDay)
    }

    return (
        <Dialog
            open={true}
            onClose={props.onHide}
            bodyStyle={{ padding: "0px" }}
            style={{ width: "482px !important", maxWidth: "482px !important", left: "35%" }}
        >
            {/* <DialogTitle style={{ textAlign: "center", paddingTop: "0px", width: "100%" }} disableTypography>
                <Typography style={{ textTransform: "none" }} variant="subtitle2">Days to the compilation date</Typography>
            </DialogTitle> */}
            <div style={{ background: "#1975D0", color: "#FFFFFF", height: "32px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <p style={{ fontSize: "14px", fontWeight: "400", textAlign: "center" }}>{newDayDisplay}</p>
            </div>
            <DialogContent>
                <div style={{ display: "flex" , justifyContent: "center", marginTop: "12px"}}>
                    <div style={{ width: "328px", height: "18px", display: "flex", justifyContent: "space-evenly" }}>
                        <div onClick={() => onDayChange(0)} style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", width: "38px", height: "38px", fontSize: "12px", fontWeight: "500", borderRadius: "999px", background: `${newDay === 0 ? "#1975D0" : "transparent"}`, color: `${newDay === 0 ? "#FFFFFF" : "#828282"}` }}>
                            S
                        </div>
                        <div onClick={() => onDayChange(1)} style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", width: "38px", height: "38px", fontSize: "12px", fontWeight: "500", borderRadius: "999px", background: `${newDay === 1 ? "#1975D0" : "transparent"}`, color: `${newDay === 1 ? "#FFFFFF" : "#828282"}` }}>
                            M
                        </div>
                        <div onClick={() => onDayChange(2)} style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", width: "38px", height: "38px", fontSize: "12px", fontWeight: "500", borderRadius: "999px", background: `${newDay === 2 ? "#1975D0" : "transparent"}`, color: `${newDay === 2 ? "#FFFFFF" : "#828282"}` }}>
                            T
                        </div>
                        <div onClick={() => onDayChange(3)} style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", width: "38px", height: "38px", fontSize: "12px", fontWeight: "500", borderRadius: "999px", background: `${newDay === 3 ? "#1975D0" : "transparent"}`, color: `${newDay === 3 ? "#FFFFFF" : "#828282"}` }}>
                            W
                        </div>
                        <div onClick={() => onDayChange(4)} style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", width: "38px", height: "38px", fontSize: "12px", fontWeight: "500", borderRadius: "999px", background: `${newDay === 4 ? "#1975D0" : "transparent"}`, color: `${newDay === 4 ? "#FFFFFF" : "#828282"}` }}>
                            T
                        </div>
                        <div onClick={() => onDayChange(5)} style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", width: "38px", height: "38px", fontSize: "12px", fontWeight: "500", borderRadius: "999px", background: `${newDay === 5 ? "#1975D0" : "transparent"}`, color: `${newDay === 5 ? "#FFFFFF" : "#828282"}` }}>
                            F
                        </div>
                        <div onClick={() => onDayChange(6)} style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", width: "38px", height: "38px", fontSize: "12px", fontWeight: "500", borderRadius: "999px", background: `${newDay === 6 ? "#1975D0" : "transparent"}`, color: `${newDay === 6 ? "#FFFFFF" : "#828282"}` }}>
                            S
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "end", margin: "38px 8px 24px 0px"}}>
                    <div onClick={props.onHide} style={{cursor: "pointer", marginRight: "20px", display: "flex", justifyContent: "center", alignItems: "center", color: "#1975D0", fontWeight: "500", fontSize: "14px"}}>
                        CANCEL
                    </div>
                    <div onClick={onSaveWeekday} style={{cursor: "pointer", marginLeft: "20px", display: "flex", justifyContent: "center", alignItems: "center", color: "#1975D0", fontWeight: "500", fontSize: "14px"}}>
                        OK
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default WeekdayPickerModal