import { DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Dialog } from 'material-ui';
import React, { useState } from 'react';

const CancelEmailedReport = (props) => {

    return (
        <Dialog
            open={true}
            onClose={props.onHide}
            style={{ width: "482px !important", maxWidth: "482px !important", left: "25%" }}
        >
            <DialogContent>
                <div style={{ display: "flex" , justifyContent: "center", textAlign: "center", fontSize: "20px", fontWeight: "semi-bold", color: "#333333", lineHeight: "28px"}}>
                    Are you sure you want to cancel the report creation? All unsaved data will be lost.
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "65px"}}>
                    <div onClick={props.onHide} style={{cursor: "pointer", marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center", width: "128px", height: "51px", background: "transparent", color: "#1975D0", fontWeight: "500", fontSize: "16px", border: "1px solid #1975D0", borderRadius: "8px"}}>
                        No
                    </div>
                    <div onClick={props.onConfirm} style={{cursor: "pointer", marginLeft: "8px", display: "flex", justifyContent: "center", alignItems: "center", width: "128px", height: "51px", background: "#1975D0", color: "#F2F2F2", fontWeight: "500", fontSize: "16px", borderRadius: "8px"}}>
                        Yes
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default CancelEmailedReport