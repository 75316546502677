import React, { Component } from 'react';

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import LinearProgress from 'material-ui/LinearProgress';

import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';
import {CircularProgress, FontIcon, IconButton} from "material-ui";
import { DatePicker, TimePicker } from "@material-ui/pickers";

class EditGiveawayModal extends Component {
    constructor (props) {
        super(props);
        this.state = {
            edit_confirmed: false,
            submitting_edit: false,
            edit_status: null,
            giveaway_details: null,
            amount_error: null,
            batch_error: null,
            amount: '',
            amountKg: '',
            editing_output: false,
            editing_output_type: "add",
            editing_output_error: null,
            modified_batch: false,
            deletingItem: false,
            submitting_delete: false,
            batch_id: '',
            OUTPUT_ID: '',
            height: 'inherit',
            dateError: null,
            date_from: this.getDate(new Date()),
            time_from: this.getTime(new Date()),
            giveaway_id: '',
            weight_unit_conversion: 1,
            weight_unit: 'Kg'
        };

    }

    /* ######## Lifecycle Methods ######## */
    componentDidMount = () => {
        this.setKeyListener();
        this.getGiveawayDetails();
    }

    componentWillUnmount = () => {
        this.cancelKeyListener();
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            if (event.keyCode === 13) {
                this.submitAddEditGiveaway()
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }


    giveawayText () {
        if (this.state.giveaway_details == null) {
            return <div className="delete-user__text">
                Loading... <CircularProgress size={20} thickness={2}/>
            </div>
        }
    }

    validateInputs () {
        let error_free = true;

        this.setState({
            amount_error: null,
        });

        if (this.state.amount == '') {
            error_free = false;
            this.setState({
                amount_error: "Amount Required"
            })
        }

        return error_free;
    }

    getGiveawayDetails = () => {
        let params = {
            path: "giveaway_details",
            path_variables: {
                RUN_ID: this.props.run_id,
                LOCATION_ID: this.props.location_id,
            },
            success: this.getGiveawaySuccess,
            error: this.getGiveawayError
        }

        API.c(params, true);
    }


    getGiveawaySuccess = (data) => {
        this.setState({
            giveaway_details: data.giveaways,
            weight_unit: data.unit_to_display,
        });
        if(data.giveaways.length > 6) {
            this.setState({
                height: '300px'
            })
        }
        switch (data.unit_to_display) {
            case "g":
                this.setState({weight_unit_conversion: 0.001});
                break;
            case "lbs":
                this.setState({weight_unit_conversion: 0.45359237});
                break;
            default:
                this.setState({weight_unit_conversion: 1});
        }
    }


    getGiveawayError = (error) => {
        this.setState({
            giveaway_details: null,
        })
    }


    hideModal = () => {
        this.props.hideModal();
    }


    buttonStyle () {
        return {
            marginRight: "15px"
        }
    }

    deleteButtonStyle () {
        return {
            width: "20px",
            marginRight: "15px",
            height: "inherit",
            padding: "inherit"
        }
    }


    handleChange (event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }


    batchButtons () {
        if (this.state.submitting_edit == false) {
            return <div className="delete-user__buttons">
                <RaisedButton label="Close" primary={true} onClick={this.doHideModal} style={this.buttonStyle()} />
            </div>
        } else {
            return <div className="add-user__buttons">
                <LinearProgress mode="indeterminate" />
            </div>
        }
    }


    doHideModal = () => {
        if (this.state.modified_batch == true) {
            this.props.refreshReport();
        }

        this.hideModal();
    }


    changeDeleteConfirmation = (event, value) => {
        this.setState({
            delete_confirmed: value
        });
    }


    editStatus () {
        if (this.state.edit_error) {
            let e = "Unable to save";

            if (error) {
                if (error.error) {
                    e = error.error;
                }

                if (error.detail) {
                    e = error.detail;
                }
            }

            return <div className="delete-user__error">{e}</div>
        } else {
            return <div></div>
        }
    }


    tableRowColumnStyle() {
        return {
            fontFamily: "Roboto",
            fontSize: "12px",
            paddingTop: "15px",
            paddingBottom: "15px",
            color: "#000000",
            border: "none"
        }
    }

    editDeleteTableRowColumnStyle() {
        return {
            fontFamily: "Roboto",
            fontSize: "12px",
            paddingTop: "0px",
            paddingBottom: "0px",
            color: "#000000",
            border: "none"
        }
    }


    tableRowStyle(index) {
        let bgColor = "#ffffff";
        if ( index % 2 == 0)  bgColor = "#f2f2f2";

        return {
            border: "none",
            backgroundColor: bgColor
        }
    }


    tableHeaderStyle() {
        return {
            textTransform: "uppercase",
            height: "20px",
            color: "#000000",
            borderBottom: "1px solid #999999"
        }
    }


    giveawayDetails = () => {
        if (this.state.giveaway_details == null) {
            return;
        }

        return <Table
            selectable={false}
            multiSelectable={false}
            height={this.state.height}
        >
            <TableHeader
                displaySelectAll={false}
                adjustForCheckbox={false}
                enableSelectAll={false}
            >
                <TableRow>
                    <TableHeaderColumn style={this.tableHeaderStyle()}>Sample Time</TableHeaderColumn>
                    <TableHeaderColumn style={this.tableHeaderStyle()}>Weight</TableHeaderColumn>
                    <TableHeaderColumn style={this.tableHeaderStyle()}>Modify</TableHeaderColumn>
                </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
                {this.detailData()}
            </TableBody>
        </Table>
    }


    detailData = () => {
        let { giveaway_details } = this.state;
        let state = this.state

        return giveaway_details.map((item, index) =>
            <TableRow
                style={this.tableRowStyle(index)}
                className="report__row"
                key={`row-${index}`}
            >
                <TableRowColumn style={this.tableRowColumnStyle()}>{Util.formatFullDateTime(item.giveaway_time)}</TableRowColumn>
                <TableRowColumn style={this.tableRowColumnStyle()}>{Util.roundTo((item.amount_kg / this.state.weight_unit_conversion), 2) + " " + this.state.weight_unit}</TableRowColumn>
                <TableRowColumn style={this.editDeleteTableRowColumnStyle()}>
                    {state.submitting_edit == false &&
                    <div>
                        <span style={{paddingTop: "15px", paddingBottom: "15px"}} onClick={this.setGiveawayEdit.bind(this, item)}>EDIT</span>
                        <span> | </span>
                        <span style={{paddingTop: "15px", paddingBottom: "15px"}} onClick={this.setGiveawayDelete.bind(this, item)}>DELETE</span>
                    </div>
                    }
                    {state.deletingItem && state.giveaway_id == item.giveaway_id && !this.state.submitting_delete &&
                    <div>
                        <IconButton onClick={this.giveawayDelete} style={this.deleteButtonStyle()}> <FontIcon className="zmdi zmdi-delete" color={"#d9534f"}/></IconButton>
                        <span style={{paddingTop: "15px", paddingBottom: "15px"}} onClick={this.cancelDeleteGiveaway}>CANCEL</span>
                    </div>
                    }
                    {state.submitting_delete && state.giveaway_id == item.giveaway_id &&
                    <div className="batch-edit__buttons">
                        Deleting... <CircularProgress size={20} thickness={2}/>
                    </div>
                    }
                </TableRowColumn>
            </TableRow>
        )
    }


    setGiveawayDelete = (item) => {
        this.setState({
            giveaway_id: item.giveaway_id,
            deletingItem: true,
            submitting_edit: true
        })
    }



    setGiveawayEdit = (item) => {
        this.setState({
            editing_output: true,
            editing_output_type: "edit",
            amount: Util.roundTo(item.amount_kg / this.state.weight_unit_conversion, 2),
            amountKg: item.amount_kg,
            date_from: this.getDate(item.giveaway_time),
            time_from: this.getTime(item.giveaway_time),
            giveaway_id: item.giveaway_id,
            batch_id: item.batch_id,
        })
    }

    getDate = (d) => {
        if(d == null) {
            return new Date();
        }
        return new Date(d)
    }

    getTime = (d) => {
        let date = new Date(d);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        let time = new Date();
        time.setHours(hours);
        time.setMinutes(minutes);
        time.setSeconds(seconds);
        return time;
    }

    giveawayDelete = () => {
        this.setState({ submitting_delete: true });
        let params = {
            path: "delete_giveaway",
            path_variables: {
                GIVEAWAY_ID: this.state.giveaway_id
            },
            success: this.deleteGiveawaySuccess,
            error: this.deleteGiveawayError
        }

        API.c(params);
    }


    deleteGiveawaySuccess = (data) => {
        this.getGiveawayDetails();
        this.props.modified();
        this.setState({
            editing_output: false,
            editing_output_error: null,
            submitting_delete: false,
            modified_batch: true,
            deletingItem: false,
            submitting_edit: false
        })
    }


    deleteGiveawayError = (error) => {
        this.setState({
            editing_output_error: error,
            submitting_delete: false,
            submitting_edit: false,
            giveaway_id: ''
        })
    }


    handleAmountChange = (event) => {
        this.setState({
            amountKg: event.target.value * this.state.weight_unit_conversion,
            amount: event.target.value
        })
    }

    handleDateFromChange = (event, date) => {
        this.setState({
            date_from: date
        })
    }

    handleTimeFromChange = (event, time) => {
        this.setState({
            time_from: time,
            date_from: this.appendTimeFrom(time)
        })
    }

    appendTimeFrom = (t) => {
        let date = this.state.date_from;
        date.setHours(t.getHours());
        date.setMinutes(t.getMinutes());
        date.setSeconds(t.getSeconds());

        return date
    }


    giveawayAdd = () => {
        if (this.state.giveaway_details == null) {
            return;
        }
        if (this.state.editing_output == false) {
            return <div className="batch-edit__add-button">
                <RaisedButton label="Add New Giveaway Sample" primary={true} onClick={this.showAddNewGiveaway} style={this.buttonStyle()} />
            </div>
        }

        return <div className="batch-edit">
            <div className="batch-edit__field">
                <TextField
                    className="batch-edit__field"
                    id="amount"
                    floatingLabelText={"Weight " + this.state.weight_unit}
                    value={this.state.amount}
                    onChange={this.handleAmountChange}
                    fullWidth={true}
                    errorText={this.state.amount_error}
                />
            </div>
            <DatePicker
                label="Start Date"
                shouldDisableDate={this.disableFutureDates}
                value={this.state.date_from}
                onChange={this.handleDateFromChange}
                format="yyyy-MMMM-DD"
                className="reports__edit-item_picker"
            />
            <TimePicker
                id={"start-time-time-picker"}
                onChange={this.handleTimeFromChange}
                value={this.state.time_from}
                label="Start Time"
                className="reports__edit-item_picker"
            />
            {this.state.submitting_edit != true &&
            <div className="batch-edit__buttons">
                <RaisedButton label="Cancel" primary={true} onClick={this.cancelAddNewGiveaway} style={this.buttonStyle()} />
                <RaisedButton label="Save" primary={true} onClick={this.submitAddEditGiveaway} style={this.buttonStyle()} />
            </div>
            }
            {this.state.submitting_edit == true &&
            <div className="batch-edit__buttons">
                Saving... <CircularProgress size={20} thickness={2}/>
            </div>
            }

            <div className="batch-edit__error">{this.state.editing_output_error}</div>
        </div>
    }

    showAddNewGiveaway = () => {
        this.setState({
            editing_output: true,
            editing_output_type: "add",
            date_from: this.getDate(new Date()),
            time_from: this.getTime(new Date())
        });
    }


    cancelAddNewGiveaway = () => {
        this.setState({
            editing_output: false,
            batch_id: '',
            amount: '',
            amountKg: '',
            giveaway_id: ''
        })
    }

    cancelDeleteGiveaway = () => {
        this.setState({
            deletingItem: false,
            giveaway_id: '',
            submitting_edit: false
        })
    }


    submitAddEditGiveaway = () => {
        let params;

        if (this.validateInputs() == false) {
            return;
        }

        this.setState({ submitting_edit: true });
        let data;
        if (this.state.editing_output_type == "add") {
            data = [{
                amount: this.state.amountKg,
                time: this.state.date_from.toISOString(),
                run: this.props.run_id,
                location: this.props.location_id
            }]

            params = {
                path: "update_giveaway",
                path_variables: {},
                data: data,
                success: this.addGiveawaySuccess,
                error: this.addGiveawayError
            }
        } else {
            if(this.state.amountKg == ''){
                this.setState({
                    amountKg: this.state.amount * this.state.weight_unit_conversion
                })
            }
            data = [{
                id: this.state.giveaway_id,
                amount: this.state.amountKg,
                batch: this.state.batch_id,
                time: this.state.date_from.toISOString(),
                run: this.props.run_id,
                location: this.props.location_id
            }];

            params = {
                path: "update_giveaway",
                path_variables: {},
                data: data,
                success: this.editGiveawaySuccess,
                error: this.editGiveawayError
            }
        }

        API.c(params, 2);
    }


    editGiveawaySuccess = (data) => {
        this.setState({
            editing_output: false,
            amount: '',
            giveaway_id: '',
            batch_id: '',
            submitting_edit: false,
            modified_batch: true
        })
        this.props.modified();
        this.getGiveawayDetails();
    }


    editGiveawayError = (error) => {
        let e = "Unable to Save";

        if (error) {
            if (error.error) {
                e = error.error;
            }

            if (error.detail) {
                e = error.detail;
            }
        }
        this.setState({
            editing_output_error: e,
            submitting_edit: false
        })
    }


    addGiveawaySuccess = (data) => {
        this.setState({
            editing_output: false,
            amount: '',
            submitting_edit: false,
            modified_batch: true
        })
        this.props.modified();
        this.getGiveawayDetails();
    }


    addGiveawayError = (error) => {
        let e = "Unable to Save";

        if (error) {
            if (error.error) {
                e = error.error;
            }

            if (error.detail) {
                e = error.detail;
            }
        }

        this.setState({
            editing_output_error: e,
            submitting_edit: false
        })
    }

    fromJulianDate = (n) => {
        let day = n.toString().substr(0, 3)
        let year = n.toString().substr(3)

        let yearLoop = new Date();
        for(let i = yearLoop.getFullYear(); i > yearLoop.getFullYear() - 10; i--){
            if (i.toString().substr(3) == year){
                year = i;
            }
        }


        let date = new Date(year, 0); // initialize a date in `year-01-01`
        return new Date(date.setDate(day)); // add the number of days
    }


    render() {
        return <div>
            {this.giveawayText()}
            {this.giveawayDetails()}
            {this.giveawayAdd()}

            {this.editStatus()}
        </div>
    }
}

export default EditGiveawayModal;