import React, { Component } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Stepper,
    Step,
    StepLabel,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import UnitRow from '../components/settings/products/UnitRow';
import UnitChangeConfirmationRow from '../components/settings/products/UnitChangeConfirmationRow';
import { Memory, Speed } from '@material-ui/icons';
import ReworkIcon from '../components/icons/ReworkIcon';
import WasteIcon from '../components/icons/WasteIcon';
import FinishedGoodsIcon from '../components/icons/FinishedGoodsIcon';

class ManageLineUnitsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            activeStep: 0,
            sensor_count_values: this.getOriginalUnitValue('output'),
            speed_values: this.getOriginalUnitValue('speed'),
            waste_values: this.getOriginalUnitValue('waste'),
            rework_values: this.getOriginalUnitValue('rework'),
            finished_goods_values: this.getOriginalUnitValue('finished_goods'),
            sensor_count_unit_type: this.getOriginalUnitTypeId('output'),
            speed_unit_type: this.getOriginalUnitTypeId('speed'),
            waste_unit_type: this.getOriginalUnitTypeId('waste'),
            rework_unit_type: this.getOriginalUnitTypeId('rework'),
            finished_goods_unit_type: this.getOriginalUnitTypeId('finished_goods'),
            sensor_count_display_unit_type: this.getOriginalUnitTypeId('output', true),
            speed_display_unit_type: this.getOriginalUnitTypeId('speed', true),
            waste_display_unit_type: this.getOriginalUnitTypeId('waste', true),
            rework_display_unit_type: this.getOriginalUnitTypeId('rework', true),
            finished_goods_display_unit_type: this.getOriginalUnitTypeId('finished_goods', true),
            speed_unit_conversion_value: this.getOriginalBaseUnitConversion('speed'),
            waste_unit_conversion_value: this.getOriginalBaseUnitConversion('waste'),
            rework_unit_conversion_value: this.getOriginalBaseUnitConversion('rework'),
            finished_goods_unit_conversion_value: this.getOriginalBaseUnitConversion('finished_goods'),
            sensor_count_unit_type_error: false,
            speed_unit_type_error: false,
            waste_unit_type_error: false,
            rework_unit_type_error: false,
            finished_goods_unit_type_error: false,
            sensor_count_change: false,
            speed_change: false,
            waste_change: false,
            rework_change: false,
            finished_goods_change: false,
            calls: 0
        };
    }

    /* -------- LIFECYCLE METHODS -------- */
    componentDidMount() {}

    /* -------- FUNCTIONS -------- */
    getOriginalUnitValue = type => {
        let { line_units } = this.props;

        if (!line_units) {
            return [''];
        }

        let unitObj = line_units[type];

        if (!unitObj) {
            return [''];
        }

        let unitArr = [];
        unitArr.push(unitObj.conversion_value);

        return unitArr;
    };

    getOriginalBaseUnitConversion = type => {
        let { line_units } = this.props;

        if (!line_units) {
            return '';
        }

        let unitObj = line_units[type];

        if (!unitObj) {
            return '';
        }

        return unitObj.base_unit_conversion_value;
    };

    getOriginalUnitTypeId = (type, display) => {
        let { line_units, units } = this.props;

        if (!line_units) {
            return '';
        }

        let unitObj = line_units[type];

        if (!unitObj) {
            return '';
        }

        let unitType = '';
        let unit_id = display ? unitObj.display_unit_id : unitObj.input_unit_id;
        let unitItem = units.find(u => u.id === unit_id);
        if (unitItem) {
            unitType = unitItem.id;
        }

        return unitType;
    };

    getUnitType = type => {
        let { units } = this.props;
        let unit_id = -1;
        let unit_type = '';

        switch (type) {
            case 'output':
                unit_id = this.state.sensor_count_unit_type;
                break;
            case 'speed':
                unit_id = this.state.speed_unit_type;
                break;
            case 'rework':
                unit_id = this.state.rework_unit_type;
                break;
            case 'waste':
                unit_id = this.state.waste_unit_type;
                break;
            case 'finished_goods':
                unit_id = this.state.finished_goods_unit_type;
        }

        let unit = units.find(u => u.id === unit_id);

        if (unit) {
            unit_type = unit.name;
        }

        return unit_type;
    };

    getOriginalUnitTypeText = unit_id => {
        let { units } = this.props;
        let unit_type = '';
        let unit = units.find(u => u.id === unit_id);

        if (unit) {
            unit_type = unit.name;
        }

        return unit_type;
    };

    handleNext = () => {
        if (this.state.activeStep === 0) {
            if (this.validateInput()) {
                this.setState({ activeStep: 1 });
            }
        } else {
            this.submitLineUpdate();
        }
    };

    handleBack = () => {
        if (this.state.activeStep === 1) {
            this.setState({ activeStep: 0 });
        } else {
            this.props.hideModal();
        }
    };

    validateInput = () => {
        let errorFree = true;
        this.setState({
            sensor_count_unit_type_error: false,
            speed_unit_type_error: false,
            waste_unit_type_error: false,
            rework_unit_type_error: false,
            finished_goods_unit_type_error: false
        });

        if (this.state.sensor_count_change && this.state.sensor_count_unit_type === '') {
            errorFree = false;
            this.setState({
                sensor_count_unit_type_error: true
            });
        }

        if (this.state.speed_change && this.state.speed_unit_type === '') {
            errorFree = false;
            this.setState({
                speed_unit_type_error: true
            });
        }

        if (this.state.waste_change && this.state.waste_unit_type === '') {
            errorFree = false;
            this.setState({
                waste_unit_type_error: true
            });
        }

        if (this.state.rework_change && this.state.rework_unit_type === '') {
            errorFree = false;
            this.setState({
                rework_unit_type_error: true
            });
        }

        if (this.state.finished_goods_change && this.state.finished_goods_unit_type === '') {
            errorFree = false;
            this.setState({
                finished_goods_unit_type_error: true
            });
        }

        return errorFree;
    };

    getSubValue = type => {
        let subValues = [];
        switch (type) {
            case 'output':
                subValues = this.state.sensor_count_values;
                break;
            case 'speed':
                subValues = this.state.speed_values;
                break;
            case 'rework':
                subValues = this.state.rework_values;
                break;
            case 'waste':
                subValues = this.state.waste_values;
                break;
            case 'finished_goods':
                subValues = this.state.finished_goods_values;
        }

        return subValues;
    };

    setSubValues = (type, values) => {
        this.setChange(type);
        switch (type) {
            case 'output':
                this.setState({
                    sensor_count_values: values
                });
                break;
            case 'speed':
                this.setState({
                    speed_values: values
                });
                break;
            case 'rework':
                this.setState({
                    rework_values: values
                });
                break;
            case 'waste':
                this.setState({
                    waste_values: values
                });
                break;
            case 'finished_goods':
                this.setState({
                    finished_goods_values: values
                });
        }
    };

    onConversionValueChange = (type, event) => {
        this.setChange(type);
        switch (type) {
            case 'speed':
                this.setState({
                    speed_unit_conversion_value: event.target.value
                });
                break;
            case 'rework':
                this.setState({
                    rework_unit_conversion_value: event.target.value
                });
                break;
            case 'waste':
                this.setState({
                    waste_unit_conversion_value: event.target.value
                });
                break;
            case 'finished_goods':
                this.setState({
                    finished_goods_unit_conversion_value: event.target.value
                });
                break;
        }
    };

    // onValueChange = (type, index, event) => {
    //     let subValues = this.getSubValue(type);

    //     let newSubValue = [...subValues];
    //     newSubValue[index]['value'] = event.target.value;

    //     this.setSubValues(type, newSubValue);
    // };

    onValueChange = (type, event) => {
        let subValues = this.getSubValue(type);

        let newSubValue = [...subValues];
        newSubValue[0] = event.target.value;

        this.setSubValues(type, newSubValue);
    };

    onNotesChange = (type, index, event) => {
        let subValues = this.getSubValue(type);

        let newSubValue = [...subValues];
        newSubValue[index]['notes'] = event.target.value;

        this.setSubValues(type, newSubValue);
    };

    addSubValue = type => {
        let subValues = this.getSubValue(type);

        let newSubValue = [...subValues];
        if (!newSubValue[newSubValue.length - 1] || newSubValue[newSubValue.length - 1].value !== '') {
            newSubValue.push('');
            this.setSubValues(type, newSubValue);
        }
    };

    onSubValueDelete = (type, index) => {
        let subValues = [];
        switch (type) {
            case 'output':
                subValues = [...this.state.sensor_count_values];
                break;
            case 'speed':
                subValues = [...this.state.speed_values];
                break;
            case 'rework':
                subValues = [...this.state.rework_values];
                break;
            case 'waste':
                subValues = [...this.state.waste_values];
                break;
            case 'finished_goods':
                subValues = [...this.state.finished_goods_values];
                break;
        }

        if (subValues.length > 0) {
            subValues.splice(index, 1);
            switch (type) {
                case 'output':
                    this.setState({
                        sensor_count_values: subValues
                    });
                    break;
                case 'speed':
                    this.setState({
                        speed_values: subValues
                    });
                    break;
                case 'rework':
                    this.setState({
                        rework_values: subValues
                    });
                    break;
                case 'waste':
                    this.setState({
                        waste_values: subValues
                    });
                    break;
                case 'finished_goods':
                    this.setState({
                        finished_goods_values: subValues
                    });
                    break;
            }
            this.setChange(type);
        }
    };

    onUnitTypeChange = (type, event) => {
        this.setChange(type);
        switch (type) {
            case 'output':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        sensor_count_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        sensor_count_display_unit_type: event.target.value
                    });
                }
                break;
            case 'speed':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        speed_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        speed_display_unit_type: event.target.value
                    });
                }
                break;
            case 'rework':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        rework_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        rework_display_unit_type: event.target.value
                    });
                }
                break;
            case 'waste':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        waste_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        waste_display_unit_type: event.target.value
                    });
                }
                break;
            case 'finished_goods':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        finished_goods_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        finished_goods_display_unit_type: event.target.value
                    });
                }
                break;
        }
    };

    setChange = type => {
        switch (type) {
            case 'output':
                this.setState({
                    sensor_count_change: true
                });
                break;
            case 'speed':
                this.setState({
                    speed_change: true
                });
                break;
            case 'rework':
                this.setState({
                    rework_change: true
                });
                break;
            case 'waste':
                this.setState({
                    waste_change: true
                });
                break;
            case 'finished_goods':
                this.setState({
                    finished_goods_change: true
                });
        }
    };

    /* -------- API CALLS -------- */
    submitLineUpdate = () => {
        let {
            sensor_count_change,
            speed_change,
            waste_change,
            rework_change,
            finished_goods_change,
            sensor_count_unit_type,
            speed_unit_type,
            waste_unit_type,
            rework_unit_type,
            finished_goods_unit_type,
            sensor_count_values,
            speed_values,
            waste_values,
            rework_values,
            finished_goods_values,
            sensor_count_display_unit_type,
            speed_display_unit_type,
            waste_display_unit_type,
            rework_display_unit_type,
            finished_goods_display_unit_type,
            speed_unit_conversion_value,
            waste_unit_conversion_value,
            rework_unit_conversion_value,
            finished_goods_unit_conversion_value
        } = this.state;
        let { line_id } = this.props;

        if (sensor_count_change || speed_change || waste_change || rework_change || finished_goods_change) {
            let data = {};
            if (sensor_count_change) {
                data['output'] = {
                    input_unit_id: sensor_count_unit_type,
                    display_unit_id: sensor_count_display_unit_type,
                    base_unit_conversion_value: 1,
                    unit_conversion_value: parseFloat(sensor_count_values[0])
                };
            }

            if (speed_change) {
                data['speed'] = {
                    input_unit_id: speed_unit_type,
                    display_unit_id: speed_display_unit_type,
                    base_unit_conversion_value: parseFloat(speed_unit_conversion_value),
                    unit_conversion_value: parseFloat(speed_values[0])
                };
            }

            if (waste_change) {
                data['waste'] = {
                    input_unit_id: waste_unit_type,
                    display_unit_id: waste_display_unit_type,
                    base_unit_conversion_value: parseFloat(waste_unit_conversion_value),
                    unit_conversion_value: parseFloat(waste_values[0])
                };
            }

            if (rework_change) {
                data['rework'] = {
                    input_unit_id: rework_unit_type,
                    display_unit_id: rework_display_unit_type,
                    base_unit_conversion_value: parseFloat(rework_unit_conversion_value),
                    unit_conversion_value: parseFloat(rework_values[0])
                };
            }

            if (finished_goods_change) {
                data['finished_goods'] = {
                    input_unit_id: finished_goods_unit_type,
                    display_unit_id: finished_goods_display_unit_type,
                    base_unit_conversion_value: parseFloat(finished_goods_unit_conversion_value),
                    unit_conversion_value: parseFloat(finished_goods_values[0])
                };
            }

            let params = {
                path: 'edit_line_units_defaults',
                path_variables: {
                    LINE_ID: parseInt(line_id)
                },
                data,
                success: this.onUpdateProductOnLineSuccess,
                error: this.onUpdateProductOnLineError
            };

            API.c(params, 2);
        }
    };

    onUpdateProductOnLineSuccess = data => {
        this.props.hideModal();
        this.props.refresh();
    };

    onUpdateProductOnLineError = error => {
        console.log(error);
    };

    /* -------- RENDERS -------- */

    renderBody = () => {
        const { classes } = this.props;
        switch (this.state.activeStep) {
            case 0:
                return (
                    <div className={classes.table}>
                        <UnitRow
                            enabled
                            name={'Sensor Count'}
                            icon={<Memory htmlColor={'#666'} />}
                            type={'output'}
                            units={this.props.units}
                            unitType={this.state.sensor_count_unit_type}
                            displayUnitType={this.state.sensor_count_display_unit_type}
                            unitTypeError={this.state.sensor_count_unit_type_error}
                            onValueChange={this.onValueChange}
                            onNotesChange={this.onNotesChange}
                            onUnitTypeChange={this.onUnitTypeChange}
                            subValues={this.state.sensor_count_values}
                            addSubValue={this.addSubValue}
                            onSubValueDelete={this.onSubValueDelete}
                        />
                        <UnitRow
                            enabled
                            name={'Speed'}
                            icon={<Speed htmlColor={'#666'} />}
                            type={'speed'}
                            units={this.props.units}
                            unitType={this.state.speed_unit_type}
                            displayUnitType={this.state.speed_display_unit_type}
                            conversionUnitType={this.state.sensor_count_display_unit_type}
                            conversionValue={this.state.speed_unit_conversion_value}
                            onConversionValueChange={this.onConversionValueChange}
                            unitTypeError={this.state.speed_unit_type_error}
                            onValueChange={this.onValueChange}
                            onNotesChange={this.onNotesChange}
                            onUnitTypeChange={this.onUnitTypeChange}
                            subValues={this.state.speed_values}
                            addSubValue={this.addSubValue}
                            onSubValueDelete={this.onSubValueDelete}
                        />
                        <UnitRow
                            enabled
                            name={'Rework'}
                            icon={<ReworkIcon color={'#666'} />}
                            type={'rework'}
                            units={this.props.units}
                            unitType={this.state.rework_unit_type}
                            displayUnitType={this.state.rework_display_unit_type}
                            conversionUnitType={this.state.sensor_count_display_unit_type}
                            conversionValue={this.state.rework_unit_conversion_value}
                            onConversionValueChange={this.onConversionValueChange}
                            unitTypeError={this.state.rework_unit_type_error}
                            onValueChange={this.onValueChange}
                            onNotesChange={this.onNotesChange}
                            onUnitTypeChange={this.onUnitTypeChange}
                            subValues={this.state.rework_values}
                            addSubValue={this.addSubValue}
                            onSubValueDelete={this.onSubValueDelete}
                        />
                        <UnitRow
                            enabled
                            name={'Waste'}
                            icon={<WasteIcon color={'#666'} />}
                            type={'waste'}
                            units={this.props.units}
                            unitType={this.state.waste_unit_type}
                            displayUnitType={this.state.waste_display_unit_type}
                            conversionUnitType={this.state.sensor_count_display_unit_type}
                            conversionValue={this.state.waste_unit_conversion_value}
                            onConversionValueChange={this.onConversionValueChange}
                            unitTypeError={this.state.waste_unit_type_error}
                            onValueChange={this.onValueChange}
                            onNotesChange={this.onNotesChange}
                            onUnitTypeChange={this.onUnitTypeChange}
                            subValues={this.state.waste_values}
                            addSubValue={this.addSubValue}
                            onSubValueDelete={this.onSubValueDelete}
                        />
                        <UnitRow
                            enabled
                            name={'Finished Goods'}
                            icon={<FinishedGoodsIcon color={'#666'} />}
                            type={'finished_goods'}
                            units={this.props.units}
                            unitType={this.state.finished_goods_unit_type}
                            displayUnitType={this.state.finished_goods_display_unit_type}
                            conversionUnitType={this.state.sensor_count_display_unit_type}
                            conversionValue={this.state.finished_goods_unit_conversion_value}
                            onConversionValueChange={this.onConversionValueChange}
                            unitTypeError={this.state.finished_goods_unit_type_error}
                            onValueChange={this.onValueChange}
                            onNotesChange={this.onNotesChange}
                            onUnitTypeChange={this.onUnitTypeChange}
                            subValues={this.state.finished_goods_values}
                            addSubValue={this.addSubValue}
                            onSubValueDelete={this.onSubValueDelete}
                        />
                    </div>
                );
            case 1:
                return (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell className={classes.label}>Current</TableCell>
                                    <TableCell className={classes.label}>Edits</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <UnitChangeConfirmationRow
                                    name={'Sensor Count'}
                                    type={'output'}
                                    changed={this.state.sensor_count_change}
                                    originalUnitType={this.getOriginalUnitTypeText(
                                        this.getOriginalUnitTypeId('output')
                                    )}
                                    unitType={this.getUnitType('output')}
                                    originalValues={this.getOriginalUnitValue('output')}
                                    subValues={this.state.sensor_count_values}
                                />
                                <UnitChangeConfirmationRow
                                    name={'Speed'}
                                    type={'speed'}
                                    changed={this.state.speed_change}
                                    originalUnitType={this.getOriginalUnitTypeText(this.getOriginalUnitTypeId('speed'))}
                                    originalValues={this.getOriginalUnitValue('speed')}
                                    unitType={this.getUnitType('speed')}
                                    subValues={this.state.speed_values}
                                />
                                <UnitChangeConfirmationRow
                                    name={'Rework'}
                                    type={'rework'}
                                    changed={this.state.rework_change}
                                    originalUnitType={this.getOriginalUnitTypeText(
                                        this.getOriginalUnitTypeId('rework')
                                    )}
                                    originalValues={this.getOriginalUnitValue('rework')}
                                    unitType={this.getUnitType('rework')}
                                    subValues={this.state.rework_values}
                                />
                                <UnitChangeConfirmationRow
                                    name={'Waste'}
                                    type={'waste'}
                                    changed={this.state.waste_change}
                                    originalUnitType={this.getOriginalUnitTypeText(this.getOriginalUnitTypeId('waste'))}
                                    originalValues={this.getOriginalUnitValue('waste')}
                                    unitType={this.getUnitType('waste')}
                                    subValues={this.state.waste_values}
                                />
                                <UnitChangeConfirmationRow
                                    name={'Finished Goods'}
                                    type={'finished_goods'}
                                    changed={this.state.finished_goods_change}
                                    originalUnitType={this.getOriginalUnitTypeText(
                                        this.getOriginalUnitTypeId('finished_goods')
                                    )}
                                    originalValues={this.getOriginalUnitValue('finished_goods')}
                                    unitType={this.getUnitType('finished_goods')}
                                    subValues={this.state.finished_goods_values}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                );
        }
    };

    renderButtons = () => {
        let { sensor_count_change, speed_change, waste_change, rework_change, finished_goods_change } = this.state;

        if (!this.state.submitting) {
            return (
                <DialogActions
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'baseline',
                        padding: '12px 24px'
                    }}
                >
                    <Button variant="text" color="primary" onClick={this.handleBack}>
                        {this.state.activeStep === 0 ? 'Cancel' : 'Back'}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            !sensor_count_change &&
                            !speed_change &&
                            !waste_change &&
                            !rework_change &&
                            !finished_goods_change
                        }
                        onClick={this.handleNext}
                    >
                        {this.state.activeStep === 0 ? 'Next' : 'Confirm'}
                    </Button>
                </DialogActions>
            );
        } else {
            return (
                <DialogActions
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'baseline',
                        padding: '12px 24px'
                    }}
                >
                    <CircularProgress mode="indeterminate" />
                </DialogActions>
            );
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <Dialog
                open={true}
                onClose={this.props.hideModal}
                scroll="paper"
                fullWidth={true}
                classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle disableTypography>
                    <Typography variant="subtitle1">Edit Line Default Units</Typography>
                </DialogTitle>
                <DialogContent>
                    <Stepper activeStep={this.state.activeStep} classes={{ root: classes.stepperRoot }}>
                        <Step>
                            <StepLabel>Edit Units</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Confirm Edits</StepLabel>
                        </Step>
                    </Stepper>
                    {/*<Typography variant="subtitle1" color="secondary">{this.getLineNames()}</Typography>*/}
                    {this.renderBody()}
                </DialogContent>
                {this.renderButtons()}
            </Dialog>
        );
    }
}

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 140
    },
    dialogCustomizedWidth: {
        'max-width': '1200px'
    },
    stepperRoot: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '360px'
    },
    label: {
        fontWeight: 700,
        color: '#999'
    },
    table: {
        display: 'flex',
        flexDirection: 'column'
    }
});

export default withStyles(styles)(ManageLineUnitsModal);
