import React, { useState, useEffect } from 'react';
import { Typography, Box, Divider } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import CustomValueLabel from '../RechartCustomComponents/CustomValueLabel';
import CustomAxisTickMultiLine from '../CustomAxisTickMultiLine';
import CustomToolTip from '../CustomToolTip';
import CustomBarCenterLabel from '../RechartCustomComponents/CustomBarCenterLabel';

import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import PerPage from './per-page';
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';

const RPCC = withRouter(ReportParetoChart);

function ReportParetoChart(props) {


    let { report, getShortReason } = props;
    const checkLocal = JSON.parse(localStorage.getItem('perPage'));

    const [data, setData] = useState([])
    const [perPages, setPerPages] = useState(checkLocal);

    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);

    useEffect(() => {
        let reportData = [];
        let unreportedBar = null;
        if (report.lost_time_list[0]) {
            let unreportedBarInfo = report.lost_time_list.find(lost_time => {
                return lost_time.reason_name === 'Unrecorded';
            });
            if (unreportedBarInfo) {
                unreportedBar = {
                    name: report.lost_time_list.length,
                    reason: 'Unreported',
                    reason_id: unreportedBarInfo.reason_id,
                    category: null,
                    short: getShortReason('Unreported'),
                    time_lost: parseFloat(unreportedBarInfo.lost_time_percent).toFixed(1),
                    time_lost_seconds: unreportedBarInfo.lost_time_seconds,
                    amt: Math.floor(unreportedBarInfo.lost_time_percent * 1000) / 1000,
                    non_oee: unreportedBarInfo.not_factored_in_oee
                };
            }

            report.lost_time_list.forEach((item, i) => {
                if (item !== undefined && item.reason_name !== 'Unrecorded') {
                    reportData.push({
                        name: i + 1,
                        reason: item.reason_name,
                        reason_id: item.reason_id,
                        category: item.reason_category,
                        short: props.getShortReason(item.reason_name),
                        time_lost: parseFloat(item.lost_time_percent).toFixed(1),
                        time_lost_seconds: item.lost_time_seconds,
                        amt: Math.floor(item.lost_time_percent * 1000) / 1000,
                        non_oee: item.not_factored_in_oee
                    });
                }
            })

            let index_of_null_item = 1;
            while (index_of_null_item !== -1) {
                index_of_null_item = reportData.findIndex(el => el.amt === 0);
                if (index_of_null_item > -1) {
                    reportData.splice(index_of_null_item, 1);
                }
            }

            let index_of_not_report = 1;
            while (index_of_not_report !== -1) {
                index_of_not_report = reportData.findIndex(el => el.amt < 0.05 && el.amt > -0.05);
                if (index_of_not_report > -1) {
                    reportData.splice(index_of_not_report, 1);
                }
            }
            reportData.sort((a, b) => {
                return b.amt - a.amt;
            });

            if (unreportedBar) {
                reportData.push(unreportedBar);
            }
        }

        setPages(reportData.length > perPages ? Math.ceil((reportData.length)/ perPages) : 1)
        setData(reportData);
    }, [report?.lost_time_list]);

    const handleChangePage = (event, value) => {
        setPage(value);
    };
    const sendResponseToUpPerPage = perPages => {
        setPerPages(perPages);
        if (perPages > data.length) {
            setPages(1);
        } else {
            setPages(Math.ceil(data.length / perPages));
        }

        setPage(1);
    };

    const useStyles = makeStyles(theme =>
        createStyles({
            pag: {
                '& .MuiPaginationItem-textPrimary.Mui-selected': {
                    color: 'white'
                },
                '& .MuiPaginationItem-root': {
                    color: '#999'
                },

                '& .MuiPagination-ul li:first-child .MuiPaginationItem-icon': {
                    color: `${page !== 1 ? '#0A71CE' : 'lightgray'}`
                },

                '& .MuiPagination-ul li:last-child .MuiPaginationItem-icon': {
                    color: `${page !== 10 ? '#0A71CE' : 'lightgray'}`
                }
            },
            side_text: {
                position: 'absolute',
                transform: 'rotate(-90deg)',
                left: '-30px',
                top: '100px',
                zIndex: '9999999',
                width: '100px',
                height: '20px',
                fontSize: '12px',
                fontWeight: '400',
                color: '#666'
            },
            card: {
                flex: 1,
                flexShrink: 1,
                margin: '20px 20px 40px 0',
                boxSizing: 'border-box',
                background: '#fff',
                boxShadow: '0px 0px 8px rgba(0,0,0,0.12)',
                display: 'flex',
                flexDirection: 'column'
            },
            title: {
                margin: '20px 30px'
            },
            bar_chart_div: {
                margin: '70px 30px',
                display: 'flex',
                flexShrink: 1
            },
            responsive_container: {
                height: '330px',
                flexShrink: 1
            }
        })
    );

    const classes = useStyles();
    const href = window.location.href;
    const res = href.split('?').pop().substr(0, 3);
    const isRun = res === 'run';
    const colors = [' #CAE0CA', '#F0C2C6'];

    if (data.length === 0) {
        return <div />;
    }

    let maxYValue = Math.ceil(
        Math.max.apply(
            Math,
            data.map(o => {
                return o.amt;
            })
        ) * 1.1
    );
    let minYValue = Math.min.apply(
        Math,
        data.map(o => {
            return o.amt;
        })
    );

    if (minYValue >= 0) {
        minYValue = 0;
    } else {
        minYValue = Math.floor(minYValue * 1.1);
    }

    let ticks = [];

    let intervals = [
        1,
        5,
        10,
        25,
        50,
        100,
        200,
        300,
        400,
        500,
        600,
        700,
        800,
        900,
        1000,
        1100,
        1200,
        1300,
        1400,
        1500,
        1600,
        1700,
        1800,
        1900,
        2000,
        2100,
        2200,
        2300,
        2400,
        2500,
        3000,
        3500,
        4000,
        4500,
        5000,
        5500,
        6000,
        6500,
        7000,
        7500,
        8000,
        8500,
        9000,
        9500,
        10000,
        11000,
        12000,
        13000,
        14000,
        15000,
        16000,
        17000,
        18000,
        19000,
        20000,
        25000,
        30000,
        35000,
        40000,
        45000,
        50000,
        75000,
        100000,
        125000,
        150000,
        200000,
        250000,
        300000,
        350000,
        400000,
        450000,
        500000,
        600000,
        700000,
        800000,
        900000,
        1000000,
        2000000,
        3000000,
        4000000,
        5000000,
        6000000,
        7000000,
        8000000,
        9000000,
        10000000,
        15000000,
        20000000,
        25000000,
        30000000,
        35000000,
        40000000,
        45000000,
        50000000,
        50000000000000,
        500000000000000,
        5000000000000000,
        50000000000000000,
        500000000000000000,
        5000000000000000000,
        50000000000000000000,
        500000000000000000000,
        5000000000000000000000,
        50000000000000000000000,
    ];
    let interval = intervals.find(element => {
        return element > (maxYValue - minYValue) / 8;
    });
    if (interval === undefined) {
        interval = 5;
    }

    maxYValue = parseInt(maxYValue) + (interval % maxYValue);
    if (minYValue !== 0) {
        minYValue = parseInt(minYValue) - Util.mod(minYValue, interval);
    }

    for (let i = minYValue; i <= maxYValue; i += interval) {
        ticks.push(i);
    }

    let width = window.innerWidth * 0.7 - 56;
    let barWidth = width / 13 + 8;
    let barChartWidth = data.length * barWidth - 15;
    if (barChartWidth < width) {
        barChartWidth = width - 46;
    }

    const startIndexOfSlice = page === 1 ? 0 : (page - 1) * perPages;

    const newData = data.slice(startIndexOfSlice, startIndexOfSlice + perPages);
    const newDataColors = newData.map(item => {
        return (item.amt > 0);
    });

    return (
        <div className={classes.card}>
            <Box display="flex">
                <Typography variant={'subtitle2'} component="div" align={'left'} className={classes.title}>
                    Pareto of Lost Time
                </Typography>
                <Box flexGrow="1" />
                <PerPage sendResponseToUpPerPage={sendResponseToUpPerPage} />
            </Box>
            <Divider />
            <div className={classes.bar_chart_div}>
                <div style={{ position: 'relative', width: '10px', height: '10px' }}>
                    <div className={classes.side_text}>{isRun ? '% of Total' : 'Lost Hours'}</div>
                </div>
                <div style={{ width: '100%' }} className={classes.responsive_container}>
                    <ResponsiveContainer width={'99%'}>
                        <BarChart
                            data={newData}
                            barCategoryGap={4}
                            isAnimationActive={false}
                            margin={{ top: 4, bottom: 10 }}
                            onClick={props.onLostTimeBarClick}
                        >
                            <XAxis
                                axisLine={false}
                                tickLine={false}
                                height={90}
                                tick={<CustomAxisTickMultiLine isRun={isRun} />}
                                dataKey="reason"
                                interval={0}
                            />
                            <YAxis
                                domain={[ticks[0], ticks[ticks.length - 1]]}
                                tick={<CustomValueLabel anchor="end" textX={-2} textDy={14} />}
                                ticks={ticks}
                                interval={0}
                            />

                            <Tooltip cursor={{ fill: '#f5f5f5' }} content={<CustomToolTip />} />
                            <Bar
                                dataKey="amt"
                                label={
                                    <CustomBarCenterLabel
                                        active_lost_time_reason={props.active_lost_time_reason}
                                        data={data}
                                        isRun={isRun}
                                    />
                                }
                                isAnimationActive={false}
                            >
                                {data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={newDataColors[index] ? colors[1] : colors[0]}
                                        style={{ cursor: 'pointer' }}
                                        opacity={
                                            !props.active_lost_time_reason ||
                                            data[index].reason === props.active_lost_time_reason
                                                ? 1
                                                : 0.5
                                        }
                                        stroke={data[index].reason === props.active_lost_time_reason ? '#000' : ''}
                                    />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div>
                <Box className={classes.pag} display="flex" m="10px auto">
                    <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                        {pages === 1 ? null : (
                            <Pagination
                                shape="rounded"
                                page={page}
                                onChange={handleChangePage}
                                count={pages}
                                color="primary"
                            />
                        )}
                    </Grid>
                </Box>
            </div>
        </div>
    );
}

export default RPCC;
