class Global {
  constructor() {

  }

  setCookie (name, value, days) {
    let expires = "";

    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    }

    document.cookie = name + "=" + value + expires + "; path=/";
  }


  getCookie (name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    let i = 0;

    while (i < ca.length) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      i++;
    }
    return null;
  }


  deleteCookie (name) {
    this.setCookie(name, "", -1);
  }
}


export default new Global();