import auth0 from 'auth0-js';
import decode from 'jwt-decode';

// const baseUrl = 'http://localhost:3000/';
// const baseUrl = 'https://appstaging.livetracking.ca/';
// const baseUrl = 'https://feature-772-scoreboard-customization-ippolito.d1ku164ztliap5.amplifyapp.com/'
// const baseUrl = 'https://feature-emailed-reports-rebranch.d1ku164ztliap5.amplifyapp.com/'
// const baseUrl = 'https://feature-770-customization-for-customer.d1ku164ztliap5.amplifyapp.com/'
// const baseUrl = 'https://bugfix-misc-bugfixes.d1ku164ztliap5.amplifyapp.com/';
// const baseUrl = 'https://feature-import-export-page.d1ku164ztliap5.amplifyapp.com/';
const baseUrl = 'https://app.livetracking.io/';

class Auth {
    constructor() {
        this.auth0 = new auth0.WebAuth({
            domain: 'livetracking.auth0.com',
            clientID: 'GZXVed_6TF5Dub5MTx3g9SDOu3NA6C3g',
            scope: 'profile email',
            audience: 'https://livetracking.auth0.com/api/v2/',
            redirectUri: baseUrl + 'organizations'
        });

        this.getIdTokenPayload = this.getIdTokenPayload.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.setSession = this.setSession.bind(this);

    }

    getIdTokenPayload() {
        let idToken = window.localStorage.getItem('idToken');
        if (idToken) {
            return decode(idToken);
        } else {
            return null;
        }
    }

    getUserEmail() {
        let email = window.localStorage.getItem('email');
        return email || ''
    }

    getUserMetadata() {
        let userMetadata = window.localStorage.getItem('userMetadata');
        return JSON.parse(userMetadata) || null
    }

    handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash({ hash: window.location.hash }, (err, authResult) => {
                if (err) return reject(err);
                if (!authResult || !authResult.idToken) {
                    return reject(err);
                }
                this.setSession(authResult);
                resolve();
            });
        });
    }

    isAuthenticated() {
        let expireAt = window.localStorage.getItem('expireAt');
        return new Date().getTime() < parseInt(expireAt);
    }

    login() {
        this.auth0.authorize({ responseType: 'id_token token code' });
    }

    logout() {
        // clear id token and expiration
        this.idToken = null;
        this.expiresAt = null;
        window.localStorage.removeItem('expireAt');
        Global.deleteCookie(/^com\.auth0\.auth\..*/)
        this.auth0.logout({
            returnTo: baseUrl + 'login',
            client_id: 'GZXVed_6TF5Dub5MTx3g9SDOu3NA6C3g'
        });
    }

    setSession(authResult) {
        this.idToken = authResult.idToken;
        this.idTokenPayload = authResult.idTokenPayload;
        // set the time that the id token will expire at
        let expireAt = authResult.idTokenPayload.exp * 1000;
        this.expiresAt = expireAt;

        window.localStorage.setItem('idToken', authResult.idToken);
        window.localStorage.setItem('expireAt', expireAt.toString());
        window.localStorage.setItem('organizations', JSON.stringify(decode(authResult.idToken)['https://livetracking.ca/app_metadata']['organizations']));
        window.localStorage.setItem('email', decode(authResult.accessToken)['https://livetracking.ca/email'])
        window.localStorage.setItem('userMetadata', JSON.stringify(decode(authResult.idToken)['https://livetracking.ca/user_metadata']))
        let data = {
            auth_token: 'a062e229d655a5f55b318faf33bdcd92f3a16336'
        };
        if (authResult.idToken) {
            User.set(data);
        }
    }

    refreshToken = async callback => {
        await this.auth0.checkSession(
            {
                scope: 'profile email',
                responseType: 'id_token token code'
            },
            callback
        );
    };

    renewToken = () => {
        return new Promise((resolve, reject) => {
            this.auth0.checkSession(
                {
                    scope: 'profile email',
                    responseType: 'id_token token code'
                },
                (err, authResult) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(authResult);
                }
            );
        });
    };
}

export default Auth;
