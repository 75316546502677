import React, {Component} from 'react';

class TimeSplit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            min_date_time: this.getDate(props.run.run_start_time),
            max_date_time: this.getDate(props.run.run_end_time),
            split_at: props.split_at,
            time_rate: this.getTimeRate(props.run.run_start_time, props.run.run_end_time),
            moving: false
        }
    }

    /* -------- LIFECYCLE METHODS -------- */

    componentDidMount() {
        this.props.onSplitTimeChange((new Date(this.state.max_date_time).getTime()) - ((640 - this.state.split_at) * this.state.time_rate * 1000), this.state.split_at)
        document.addEventListener('mouseup', this.handleMouseUp);
        document.addEventListener('mousemove', this.handleMouseMove);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleMouseUp);
        document.removeEventListener('mousemove', this.handleMouseMove);
    }

    /* -------- FUNCTIONS -------- */
    getDate = (d) => {
        if(d == null) {
            return new Date();
        }
        return new Date(d)
    }

    getTimeRate = (s, e) => {
        let start = new Date(s).getTime() / 1000
        let end
        if(e === null) {
            end = new Date().getTime() / 1000
        } else {
            end = new Date(e).getTime() / 1000
        }

        return (end - start) / 640
    }

    handleMouseMove = (e) => {
        if (this.state.moving) {
            let containerRect = this.container.getBoundingClientRect();
            let totalSize = containerRect.width;
            let splitterSize = 6
            let offset = e.clientX - containerRect.left;
            offset -= splitterSize / 2;
            if (offset < 0) {
                offset = 0;
            } else if (offset > totalSize) {
                offset = 640
            }

            this.setState({
                split_at: offset
            });
            this.props.onSplitTimeChange((new Date(this.state.max_date_time).getTime()) - ((640 - this.state.split_at) * this.state.time_rate * 1000), offset)
        }
    }

    handleSplitterMouseDown() {
        this.setState({ moving: true });
    }

    handleMouseUp = () => {
        this.setState({ moving: false });
    }

    getNextTitle = () => {
        if(this.props.split) {
            switch (this.props.dest) {
                case "prev":
                    return "Current Run"
                case "next":
                    return "To New Run"
            }
        } else {
            switch (this.props.dest) {
                case "prev":
                    return "Current Run"
                case "next":
                    return "To Next Run"
            }
        }
    }

    getPrevTitle = () => {
        if(this.props.split) {
            switch (this.props.dest) {
                case "prev":
                    return "To New Run"
                case "next":
                    return "Current Run"
            }
        } else {
            switch (this.props.dest) {
                case "prev":
                    return "To Previous Run"
                case "next":
                    return "Current Run"
            }
        }
    }

    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    render() {

        let splitDurationMax = (this.state.split_at > 580) ? 580 : this.state.split_at
        let splitAt = this.state.split_at + "px" //splitDurationMax + "px" (this.state.split_at > 625) ? 625 + "px" :
        let splitRemainingWidth = (640 - this.state.split_at) + "px" //(640 - splitDurationMax) + "px"
        let markerPosition = "translate(" + this.state.split_at + "px, 0px)"
        let splitAtMilliseconds = (new Date(this.state.max_date_time).getTime()) - ((640 - this.state.split_at) * this.state.time_rate * 1000)
        let gradientLeft = this.props.dest === "prev" ? "0px" : (this.state.split_at - 2.5) + "px"
        let gradientWidth = this.props.dest === "prev" ? this.state.split_at + "px" : (640 - this.state.split_at + 2.5) + "px"
        let dragging = this.state.moving ? " draggable-dragging" : " is-active"
        let previousAreaEnd = (this.state.split_at - 3) + "px"
        let nextAreaStart = (this.state.split_at + 3) + "px"

        return <div className="split_timeline_wrapper">
            <div className="split_timeline_container" ref={(c) => { this.container = c; }}>
                <div className="split_timeline_area">
                    <div className="split_timeline_durations">
                        <div className="split_timeline_durations_duration" style={{left: "0px", width: splitAt}}>
                            {/*<div className="split_timeline_durations_duration_number">{this.getPrevTitle()}</div>*/}
                            <div className="split_timeline_durations_duration_time">{Util.formatHourMinuteSecondsFromSecondsHMS(this.state.split_at * this.state.time_rate)}</div>
                        </div>
                        <div className="split_timeline_durations_duration" style={{left: splitAt, width: splitRemainingWidth}}>
                            {/*<div className="split_timeline_durations_duration_number">{this.getNextTitle()}</div>*/}
                            <div className="split_timeline_durations_duration_time">{Util.formatHourMinuteSecondsFromSecondsHMS((640 - this.state.split_at) * this.state.time_rate)}</div>
                        </div>
                    </div>
                    <div className="split_timeline_durations_gradient" style={{left: gradientLeft, width: gradientWidth}}/>
                    <div className="split_timeline_durations_previous_area" onClick={this.props.switch.bind(this, "prev")} style={{left: 0, width: previousAreaEnd}}/>
                    <div className="split_timeline_durations_next_area" onClick={this.props.switch.bind(this, "next")} style={{left: nextAreaStart, width: "640px"}}/>
                    <div className="split_timeline_durations_point start">
                        {Util.formatTimeSplitHourMinuteAmPm(this.state.min_date_time)}
                    </div>
                    <div className="split_timeline_durations_point end">
                        {Util.formatTimeSplitHourMinuteAmPm(this.state.max_date_time)}
                    </div>
                    <div className={"split_timeline_durations_marker follower" + dragging} style={{transform: markerPosition}} onMouseDown={this.handleSplitterMouseDown.bind(this)}>
                        <div className="split_timeline_durations_marker_container">
                            <div className="split_timeline_durations_marker_mark">
                            </div>
                            <div className="split_timeline_durations_marker_time">
                                {Util.formatTimeSplitHourMinuteAmPm(new Date(splitAtMilliseconds))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default TimeSplit