import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ReworkIcon(props) {
    return (
        <SvgIcon width="14" height="20" viewBox="0 0 14 20" fill="none">
            <path
                d="M13.6856 8.46989L7.23054 5.47807C7.08383 5.41486 6.91617 5.41486 6.76946 5.47807L0.314371 8.46989C0.125749 8.55417 0 8.76486 0 8.97555V16.434C0 16.6447 0.125749 16.8554 0.314371 16.9397L6.76946 19.9526C6.91617 20.0158 7.08383 20.0158 7.23054 19.9526L13.6856 16.9608C13.8743 16.8765 14 16.6658 14 16.4551V8.99662C14 8.76486 13.8743 8.57524 13.6856 8.46989ZM6.24551 18.0774L1.48802 15.8441V10.2818L6.26647 12.5152V18.0774H6.24551ZM7 11.2089L2.51497 9.12304L7 7.01612L11.485 9.10197L7 11.2089ZM12.512 15.8441L7.75449 18.0774V12.5152L12.5329 10.2818V15.8441H12.512Z"
                fill={props.color || 'black'}
            />
            <path
                d="M6.99961 3.70818C7.41877 3.70818 7.7541 3.37107 7.7541 2.94969V0.75849C7.7541 0.337107 7.41877 0 6.99961 0C6.58045 0 6.24512 0.337107 6.24512 0.75849V2.97075C6.24512 3.37107 6.58045 3.70818 6.99961 3.70818Z"
                fill={props.color || 'black'}
            />
            <path
                d="M9.84915 4.53005C9.9749 4.61433 10.1006 4.6354 10.2474 4.6354C10.4989 4.6354 10.7294 4.50898 10.8761 4.27722L12.0288 2.42313C12.2384 2.06496 12.1336 1.60144 11.7983 1.39074C11.442 1.15898 10.9809 1.26433 10.7713 1.62251L9.61861 3.49766C9.38807 3.83477 9.49286 4.29829 9.84915 4.53005Z"
                fill={props.color || 'black'}
            />
            <path
                d="M3.12287 4.27722C3.26957 4.50898 3.50011 4.6354 3.75161 4.6354C3.87736 4.6354 4.02407 4.59326 4.14981 4.53005C4.5061 4.31936 4.61089 3.85584 4.38035 3.49766L3.22766 1.62251C3.01808 1.26433 2.557 1.15898 2.20071 1.39074C1.84443 1.60144 1.73963 2.06496 1.97017 2.42313L3.12287 4.27722Z"
                fill={props.color || 'black'}
            />
        </SvgIcon>
    );
}
