import React from 'react';


const ROLES = [
    {value: "factory_app_user", rank: 1},
    {value: "factory_read_only", rank: 2},
    {value: "read_only", rank: 2},
    {value: "scoreboard", rank: 2},
    {value: "factory_reporter", rank: 3},
    {value: "factory_admin", rank: 4},
    {value: "floor_team", rank: 4},
    {value: "factory_supervisor", rank: 5},
    {value: "supervisor", rank: 5},
    {value: "organization_member", rank: 100},
    {value: "organization_owner", rank: 101},
    {value: "developer", rank: 1000},
    {value: "dosom", rank: 1000},
    {value: "external_developer", rank: 1000},
    {value: "account_owner", rank: 1000},
]


export const minimumRole = (minimum_role) => {
    let userFactory = JSON.parse(window.localStorage.getItem("factory"))
    let userRole = userFactory ? userFactory.role : ""
    return ROLES.find(r => r.value === userRole).rank >= ROLES.find(r => r.value === minimum_role).rank
}