import decode from 'jwt-decode'
import React from "react";

class User {
  constructor() {
    this.changeEvent = 'livetracking:user';
    this.data = {};
    this.subscribers = 0;
    this.fetchTimeout = null;
    this.webAuth = window.Auth0;
    this.lastFetch = null;
    if (Global.getCookie("auth_token")) {
      this.data.token = Global.getCookie("auth_token");
      // this.getMe();
    }
  }


  loggedIn () {
      if(this.data.authResult) {
          console.log("TOKEN EXPIRED? " + this.isTokenExpired(this.data.authResult.idToken))
      }
    return !!this.webAuth.getIdTokenPayload();
  }

  isTokenExpired = token => {
      try {
          const decoded = decode(token);
          return decoded.exp < Date.now() / 1000;
      } catch (err) {
          return false;
      }
  };


  async logout (callback) {
    let logout = await this.webAuth.logout();
    this.set({});
    Global.deleteCookie("auth_token");
    window.localStorage.removeItem("idToken");
    window.localStorage.removeItem("organization")
    window.localStorage.removeItem("organizations")
    window.localStorage.removeItem("factory")
    window.localStorage.removeItem("email")
    window.localStorage.removeItem("userMetadata")
    Global.deleteCookie("line-1");
    Global.deleteCookie("line-2");
    Global.deleteCookie("line-3");
    Global.deleteCookie("line-4");
    Global.deleteCookie("line-5");
    Global.deleteCookie("line-6");
    Global.deleteCookie("line-7");
    Global.deleteCookie("line-8");
  }


  dequeueFetch () {
    clearTimeout(this.fetchTimeout);
    this.fetchTimeout = null;
  }


  subscribe = (callback) => {
    addEventListener(this.changeEvent, callback, false);
    this.subscribers++;

    if (this.data) {
      this.emitChange();
    }
  }


  unsubscribe (callback) {
    removeEventListener(this.changeEvent, callback);
    this.subscribers--;
    
    if (this.subscribers <= 0) dequeueFetch();
  }


  emitChange () {
    let event = document.createEvent('Event');
    event.initEvent(this.changeEvent, true, true);
    dispatchEvent(event);
  }


  set = (data, request) => {
    this.data = data;

    if (data.auth_token) {
      this.setToken(data.auth_token);
    }

    this.emitChange();
  }


  setToken (token) {
    this.data.token = token;
    Global.setCookie('auth_token', token);
    this.emitChange();
  }


  getMe () {
    let params = {
      path: "me",
      success: this.getMeSuccess,
      error: this.getMeError
    }

    API.c(params);
  }


  getMeSuccess = (data) => {
    // TODO: MERGE
    Object.assign(this.data, data);
    this.emitChange();
  }


  getMeError = (error) => {
    console.error("Error retrieving logged in user");
  }

  
  getAuthHeader () {
    this.user = this.get();
    if (this.user && this.user.token) {
      return `Authorization Token ${this.user.token}`;
    } else {
      return "";
    }
  }
    

  setUserProfile (data) {
    this.data.profile = data;
    this.emitChange();
  }


  doQueue () {
    if (this.subscribers > 0) {
      this.queueFetch();
    } else {
      this.dequeueFetch();
    }
  }

  get = () => this.data;

  
  updateHeaderInfo (request) {
    if (!request.getResponseHeader('access-token')) return;
    
    this.token = request.getResponseHeader('access-token');

    window.global.setCookie('auth_token', this.token);

    this.user = this.get();
    this.user.token = this.token;
  }

}

export default User;
