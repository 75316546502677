import React from "react";

export const ShiftContext = React.createContext();

export const ShiftProvider = ({ children }) => {
    const [active, setActive] = React.useState({
        data: null,
        status: false,
    });
    return (
        <ShiftContext.Provider value={{active, setActive}}>
            {children}
        </ShiftContext.Provider>
    );
};
