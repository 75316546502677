import React, { Component } from 'react';
import { Button, CircularProgress, MenuItem, TextField } from '@material-ui/core';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import {
    getDate,
    disableFromFutureDates,
    disableToFutureDates,
    handleDateToChange,
    handleTimeFromChange,
    handleTimeToChange,
    handleDateFromChange
} from './DateTimeHelperUtils';
import moment from 'moment';

class EditEvent extends Component {
    constructor(props) {
        super(props);
        this.events = ['Output', 'Downtime', 'Waste', 'Rework', 'finished_goods'];
        this.state = {
            notes: props.event.notes,
            output_value: props.event.type === 'Output' ? (props.event.value * props.multiplier).toFixed(2) : 0,
            output_value_error: null,
            waste_value:
                props.event.type === 'Waste'
                    ? (props.event.value * props.multiplier * props.target_weight).toFixed(2)
                    : 0,
            waste_value_error: null,
            finished_goods_value:
                props.event.type === 'finished_goods'
                    ? props.event.value.toFixed(2)
                    : 0,
            rework_value:
                props.event.type === 'Rework'
                    ? (props.event.value * props.multiplier * props.target_weight).toFixed(2)
                    : 0,
            rework_value_error: null,
            date_from: getDate(props.event.start_time),
            time_from: getDate(props.event.start_time),
            date_to: getDate(props.event.end_time),
            time_to: getDate(props.event.end_time),
            min_date_time: getDate(props.run.run_start_time),
            max_date_time: getDate(props.run.run_end_time),
            time_from_error: null,
            time_to_error: null,
            submit_status: '',
            loading: false,
            waste_reason: null,
            rework_reason: null,
            downtime_reason: null,
            reasons: null,
            reason: this.getReasonId(props.reasons),
            reason_error: null
        };
    }

    // /* ######## Lifecycle Methods ######## */
    //
    // componentWillMount() {
    //     this.getReasons()
    // }
    //

    /* ######## FUNCTIONS ######## */

    mapReasonCategory = (categoryId) => {
        const category = this.props.reasonCategories.find((category) => category.id === categoryId)
        return category ? `${category.name} - ` : ""
    }

    compareCategory = (a, b) => {
        if (a.category_string === '' || a.category_string === null || !a.category_string) {
            return 1;
        } else if (b.category_string === '' || b.category_string === null || !b.category_string) {
            return -1;
        } else if (a.category_string.toLowerCase() === b.category_string.toLowerCase()) {
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        } else {
            return a.category_string.toLowerCase() < b.category_string.toLowerCase() ? -1 : 1;
        }
    };

    submit = () => {
        switch (this.events.indexOf(this.props.event.type)) {
            case 0:
                this.submitOutput();
                break;
            case 1:
                this.submitDowntime();
                break;
            case 2:
                this.submitWaste();
                break;
            case 3:
                this.submitRework();
                break;
            case 4:
                this.submitFinishedGoods();
                break;
        }
    };

    handleSelectReasonChange = event => {
        this.setState({
            reason: event.target.value
        });
    };

    handleChange(event) {
        let s = this.state;
        let type = event.target.id;
        s[type] = event.target.value;

        this.setState(s);
    }

    handleDateFromChange = date => {
        let state = handleDateFromChange(date, this.state.time_from, this.state.time_to);
        this.setState(state);

        let newDateTime = state.date_from;

        if (this.state.min_date_time.isAfter(newDateTime)) {
            this.setState({
                time_from_error: 'Must be within run time'
            });
        } else if (this.state.max_date_time.isBefore(newDateTime)) {
            this.setState({
                time_from_error: 'Must be within run time'
            });
        } else if (this.state.time_to.isSameOrBefore(newDateTime, 'minute')) {
            this.setState({
                time_from_error: 'End time error'
            });
        } else {
            this.setState({
                time_from_error: null
            });
        }
    };

    handleDateToChange = date => {
        let state = handleDateToChange(date, this.state.time_to, this.state.date_from);
        this.setState(state);
    };

    handleTimeFromChange = time => {
        let state = handleTimeFromChange(time, this.state.time_to, this.state.date_from);
        this.setState(state);

        let newDateTime = state.time_from;

        if (this.state.min_date_time.isAfter(newDateTime)) {
            this.setState({
                time_from_error: 'Must be within run time'
            });
        } else if (this.state.max_date_time.isBefore(newDateTime)) {
            this.setState({
                time_from_error: 'Must be within run time'
            });
        } else if (this.state.time_to.isSameOrBefore(newDateTime)) {
            this.setState({
                time_to_error: 'End time error'
            });
        } else {
            this.setState({
                time_from_error: null
            });
        }

        if (this.state.time_to.isAfter(newDateTime)) {
            this.setState({
                time_to_error: null
            });
        }
    };

    handleTimeToChange = time => {
        let state = handleTimeToChange(time, this.state.time_from, this.state.date_to);
        this.setState(state);
        let newDateTime = state.time_to;

        if (this.state.min_date_time.isAfter(newDateTime)) {
            this.setState({
                time_to_error: 'Must be within run time'
            });
        } else if (this.state.max_date_time.isBefore(newDateTime)) {
            this.setState({
                time_to_error: 'Must be within run time'
            });
        } else if (this.state.time_from.isSameOrAfter(newDateTime, 'minute') || newDateTime.isAfter(moment())) {
            this.setState({
                time_to_error: 'End time error'
            });
        } else {
            this.setState({
                time_to_error: null
            });
        }
    };

    validateOutputInputs = () => {
        let error_free = true;
        this.setState({
            output_value_error: null
        });

        // if(this.state.output_value <= 0) {
        //     error_free = false;
        //     this.setState({
        //         output_value_error: "Quantity must be positive!"
        //     })
        // }

        if (this.state.time_from_error) {
            error_free = false;
        }

        return error_free;
    };

    validateDowntimeInputs = () => {
        let error_free = true;
        this.setState({
            reason_error: null
        });

        if (!this.state.reason) {
            error_free = false;
            this.setState({
                reason_error: 'Reason is mandatory'
            });
        }

        if (this.state.time_from_error) {
            error_free = false;
        }

        return error_free;
    };

    validateWasteInputs = () => {
        let error_free = true;
        this.setState({
            waste_value_error: null,
            reason_error: null
        });

        if (this.state.waste_value <= 0) {
            error_free = false;
            this.setState({
                waste_value_error: 'Quantity must be positive!'
            });
        }

        if (this.state.reason === null) {
            error_free = false;
            this.setState({
                reason_error: 'Reason is mandatory'
            });
        }

        if (this.state.time_from_error) {
            error_free = false;
        }

        return error_free;
    };

    validateReworkInputs = () => {
        let error_free = true;
        this.setState({
            rework_value_error: null,
            reason_error: null
        });

        if (this.state.rework_value <= 0) {
            error_free = false;
            this.setState({
                rework_value_error: 'Quantity must be positive!'
            });
        }

        if (this.state.reason === null) {
            error_free = false;
            this.setState({
                reason_error: 'Reason is mandatory'
            });
        }

        if (this.state.time_from_error) {
            error_free = false;
        }

        return error_free;
    };

    getReasonId = reasons => {
        switch (this.events.indexOf(this.props.event.type)) {
            case 0:
                return '';
            case 1:
                let downtime =
                    reasons.downtime_reason_list[
                    reasons.downtime_reason_list.findIndex(el => el.name === this.props.event.reason_name)
                    ];
                return downtime !== undefined ? downtime.id : '';
            case 2:
                return reasons.waste_reason_list[
                    reasons.waste_reason_list.findIndex(el => el.name === this.props.event.reason_name)
                ].id;
            case 3:
                if (reasons.rework_reason_list.findIndex(el => el.name === this.props.event.reason_name) === -1) {
                    return
                }
                return reasons.rework_reason_list[
                    reasons.rework_reason_list.findIndex(el => el.name === this.props.event.reason_name)
                ].id;
        }
    };

    getInputUnit = type => {
        let { line_units } = this.props;

        return line_units[type] ? line_units[type].input_unit_name : '';
    };

    /* ######## API CALLS ######## */

    submitOutput = () => {
        if (this.validateOutputInputs()) {
            let { multiplier } = this.props;

            this.setState({
                loading: true
            });

            let params = {
                path: 'edit_output',
                path_variables: { OUTPUT_ID: this.props.event.id },
                data: {
                    start_time: this.state.date_from.toISOString(),
                    value: this.state.output_value / multiplier,
                    notes: this.state.notes,
                    run: this.props.run_id
                },
                success: this.submitSuccess,
                error: this.submitError
            };

            API.c(params, 2);
        }
    };

    submitDowntime = () => {
        if (this.validateDowntimeInputs()) {
            this.setState({
                loading: true
            });

            const data = {
                start_time: this.state.date_from.toISOString(),
                amount: this.state.waste_value,
                notes: this.state.notes,
                reason: this.state.reason ? this.state.reason : this.props.event.reason_id,
                run: this.props.run_id
            }
            if (this.props.event.end_time) {
                data.end_time = this.state.date_to.toISOString()
            }

            let params = {
                path: 'edit_downtime_event',
                path_variables: { ID: this.props.event.id },
                data: data,
                success: this.submitSuccess,
                error: this.submitError
            };

            API.c(params, 2);
        }
    };

    submitWaste = () => {
        if (this.validateWasteInputs()) {
            let { multiplier, target_weight } = this.props;

            this.setState({
                loading: true
            });

            let params = {
                path: 'edit_waste',
                path_variables: { ID: this.props.event.id },
                data: {
                    start_time: this.state.date_from.toISOString(),
                    amount: this.state.waste_value / multiplier / target_weight,
                    notes: this.state.notes,
                    reason: this.state.reason,
                    run: this.props.run_id
                },
                success: this.submitSuccess,
                error: this.submitError
            };

            API.c(params, 2);
        }
    };

    submitRework = () => {
        if (this.validateReworkInputs()) {
            let { multiplier, target_weight } = this.props;

            this.setState({
                loading: true
            });

            let params = {
                path: 'edit_rework',
                path_variables: { ID: this.props.event.id },
                data: {
                    start_time: this.state.date_from.toISOString(),
                    amount: this.state.rework_value / multiplier / target_weight,
                    notes: this.state.notes,
                    reason: this.state.reason,
                    run: this.props.run_id
                },
                success: this.submitSuccess,
                error: this.submitError
            };

            API.c(params, 2);
        }
    };

    submitFinishedGoods = () => {
        // if (this.validateReworkInputs()) {
            let { multiplier, target_weight } = this.props;

            this.setState({
                loading: true
            });

            let params = {
                path: 'edit_finished_goods',
                path_variables: { ID: this.props.event.id },
                data: {
                    start_time: this.state.date_from.toISOString(),
                    value: this.state.finished_goods_value,
                    notes: this.state.notes,
                    run: this.props.run_id
                },
                success: this.submitSuccess,
                error: this.submitError
            };

            API.c(params, 2);
        // }
    };

    submitSuccess = data => {
        this.setState({
            loading: false
        });
        this.props.modified();
        this.props.hideModal(this.props.event.type);
    };

    submitError = error => {
        this.setState({
            loading: false,
            submit_status: 'There was an error adding this event'
        });
    };

    /* ######## RENDERS ######## */

    renderButtons = () => {
        if (this.state.loading) {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'baseline',
                        marginTop: '16px'
                    }}
                >
                    <CircularProgress />
                </div>
            );
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'baseline',
                    marginTop: '16px'
                }}
            >
                <Button variant="text" color="primary" onClick={this.props.hideModal}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={this.submit} style={{ marginRight: 12 }}>
                    Submit
                </Button>
            </div>
        );
    };

    renderOptions = () => {
        switch (this.events.indexOf(this.props.event.type)) {
            case 0:
                return this.renderOutputOptions();
            case 1:
                return this.renderDowntimeOptions();
            case 2:
                return this.renderWasteOptions();
            case 3:
                return this.renderReworkOptions();
            case 4:
                return this.renderFinishedGoodsOptions();
        }
    };

    reasons = () => {
        return (
            <TextField
                label={'Reason'}
                value={this.state.reason}
                onChange={this.handleSelectReasonChange}
                style={{ width: '200px' }}
                error={this.state.reason_error !== null}
                helperText={this.state.reason_error ? 'Reason is mandatory' : ""}
                select
            >
                {this.iterateReasons()}
            </TextField>
        );
    };

    iterateReasons = () => {
        if (this.props.reasons) {
            switch (this.events.indexOf(this.props.event.type)) {
                case 1:
                    let reasons = [...this.props.reasons.downtime_reason_list];
                    reasons.sort(this.compareCategory);
                    return this.props.reasons.downtime_reason_list.map((reason, index) => {
                        return (
                            <MenuItem value={reason.id} key={'reason-' + reason.id}>
                                {this.mapReasonCategory(reason.category) + reason.name}
                            </MenuItem>
                        );
                    });
                case 2:
                    return this.props.reasons.waste_reason_list.map((reason, index) => {
                        return (
                            <MenuItem value={reason.id} key={'reason-' + reason.id}>
                                {reason.name}
                            </MenuItem>
                        );
                    });
                case 3:
                    return this.props.reasons.rework_reason_list.map((reason, index) => {
                        return (
                            <MenuItem value={reason.id} key={'reason-' + reason.id}>
                                {reason.name}
                            </MenuItem>
                        );
                    });
            }
        }
    };

    fromDateTimePickers = () => {
        return (
            <div className={'add-event-picker-wrapper'}>
                <DatePicker
                    label="Start Date"
                    shouldDisableDate={disableFromFutureDates.bind(this, this.state.date_to, this.state.max_date_time)}
                    minDate={this.state.min_date_time}
                    value={this.state.date_from}
                    format="yyyy-MMMM-DD"
                    onChange={this.handleDateFromChange}
                    className="add-event-picker"
                />
                <TimePicker
                    id={'start-time-time-picker'}
                    onChange={this.handleTimeFromChange}
                    value={this.state.time_from.tz(this.props.timeZone)}
                    label="Start Time"
                    // errorText={this.state.time_from_error}
                    className="add-event-picker"
                />
            </div>
        );
    };

    toDateTimePickers = () => {
        return (
            <div className={'add-event-picker-wrapper'}>
                <DatePicker
                    label="End Date"
                    minDate={this.state.date_from}
                    shouldDisableDate={disableToFutureDates.bind(this, this.state.max_date_time)}
                    value={this.state.date_to}
                    onChange={this.handleDateToChange}
                    format="yyyy-MMMM-DD"
                    className="add-event-picker"
                />
                <TimePicker
                    id={'end-time-time-picker'}
                    onChange={this.handleTimeToChange}
                    value={this.state.time_to.tz(this.props.timeZone)}
                    label="End Time"
                    // errorText={this.state.time_to_error}
                    className="add-event-picker"
                />
            </div>
        );
    };

    renderOutputOptions = () => {
        return (
            <div className="flex-box__col align-start">
                {this.fromDateTimePickers()}
                <TextField
                    id="output_value"
                    value={this.state.output_value}
                    onChange={this.handleChange.bind(this)}
                    type="number"
                    error={this.state.output_value_error !== null}
                    helperText={this.state.output_value_error !== null ? this.state.output_value_error : ''}
                    label={'Quantity (' + this.getInputUnit('output') + ')'}
                />
                <TextField
                    id="notes"
                    fullWidth
                    value={this.state.notes}
                    onChange={this.handleChange.bind(this)}
                    label="Notes"
                />
            </div>
        );
    };
//
    renderDowntimeOptions = () => {

        return (
            <div className="flex-box__col align-start">
                {this.fromDateTimePickers()}
                {this.props.event.end_time ? (
                    this.toDateTimePickers()
                ) 
                 : (
                    null
                 )}
                {this.reasons()}
                <TextField
                    id="notes"
                    fullWidth
                    value={this.state.notes}
                    onChange={this.handleChange.bind(this)}
                    label="Notes"
                />
            </div>
        );
    };

    renderFinishedGoodsOptions = () => {
        return (
            <div className="flex-box__col align-start">
                {this.fromDateTimePickers()}
                <TextField
                    id="finished_goods_value"
                    value={this.state.finished_goods_value}
                    onChange={this.handleChange.bind(this)}
                    type="number"
                    label={'Quantity (' + this.getInputUnit('finished_goods') + ')'}
                />
                <TextField
                    id="notes"
                    fullWidth
                    value={this.state.notes}
                    onChange={this.handleChange.bind(this)}
                    label="Notes"
                />
            </div>
        );
    };

    renderWasteOptions = () => {
        return (
            <div className="flex-box__col align-start">
                {this.fromDateTimePickers()}
                <TextField
                    id="waste_value"
                    value={this.state.waste_value}
                    onChange={this.handleChange.bind(this)}
                    type="number"
                    error={this.state.waste_value_error !== null}
                    helperText={this.state.waste_value_error !== null ? this.state.waste_value_error : ''}
                    label={'Quantity (' + this.getInputUnit('waste') + ')'}
                />
                {this.reasons()}
                <TextField
                    id="notes"
                    value={this.state.notes}
                    fullWidth
                    onChange={this.handleChange.bind(this)}
                    label="Notes"
                />
            </div>
        );
    };

    renderReworkOptions = () => {
        return (
            <div className="flex-box__col align-start">
                {this.fromDateTimePickers()}
                <TextField
                    id="rework_value"
                    value={this.state.rework_value}
                    onChange={this.handleChange.bind(this)}
                    type="number"
                    error={this.state.rework_value_error !== null}
                    helperText={this.state.rework_value_error !== null ? this.state.rework_value_error : ''}
                    label={'Quantity (' + this.getInputUnit('rework') + ')'}
                />
                {this.reasons()}
                <TextField
                    id="notes"
                    fullWidth
                    value={this.state.notes}
                    onChange={this.handleChange.bind(this)}
                    label="Notes"
                />
            </div>
        );
    };

    render() {
        return (
            <div>
                {this.renderOptions()}
                {this.renderButtons()}
            </div>
        );
    }
}

export default EditEvent;
