import React, { Component } from 'react';

class CustomAxisTickMultiLine extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { x, y, stroke, payload, isRun } = this.props;

        let nameLines = payload.value.split(/(.{10}\S*)\s/).filter(function (e) {
            return e;
        });

        const Tick = () =>
            false ? (
                <g transform={`translate(${x - 85},${y + 14})`}>
                    {nameLines.map((nameLine, index) => {
                        return (
                            <g>
                                <foreignObject x={-10 * index} y={14 * index} dy={0} width={150} height={300}>
                                    <div
                                        style={{
                                            fontSize: '12px',
                                            height: '200px',
                                            width: '60px',
                                            lineHeight: 2,
                                            fontFamily: 'Arial',

                                            textAlign: 'left'
                                        }}
                                    >
                                        {nameLine}
                                    </div>
                                </foreignObject>
                            </g>
                        );
                    })}
                </g>
            ) : (
                <g transform={`translate(${x},${y + 14})`}>
                    {nameLines.map((nameLine, index) => {
                        return (
                            <text
                                key={'name-' + index}
                                x={-40}
                                y={14 * index}
                                dy={0}
                                style={{ fontFamily: 'Arial' }}
                                fontSize="12px"
                                textAnchor="middle"
                                fill="#666"
                                transform={'rotate(-75)'}
                            >
                                {nameLine}
                            </text>
                        );
                    })}
                </g>
            );

        return <Tick />;
    }
}

export default CustomAxisTickMultiLine;
