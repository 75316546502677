import React, { Component } from 'react';
import HeaderTopNav from '../components/HeaderTopNav';
import Footer from '../components/Footer';
import { Redirect } from 'react-router-dom';
import { Paper, Typography, Divider, Grid, Menu, MenuItem, CircularProgress } from '@material-ui/core';
import FactoryIcon from '../components/icons/FactoryIcon';
import { withStyles } from '@material-ui/core/styles';

class Organizations extends Component {
    constructor(props) {
        super(props);
        this.webAuth = Auth0;
        this.state = {
            organizations: null,
            organization: null,
            orgId: -1,
            factoryId: -1,
            redirect_to_login: false,
            redirect_to_liveview: false,
            mouse_over_org: -1,
            mouse_over_factory: -1
        };
    }

    componentDidMount() {
        if (window.location.hash.includes('id_token')) {
            this.webAuth
                .handleAuthentication()
                .then(() => {
                    this.props.history.push('/organizations');
                    let orgs = this.webAuth.getIdTokenPayload()['https://livetracking.ca/app_metadata'][
                        'organizations'
                    ];
                    if (orgs.length === 1 && orgs[0].factories.length === 1) {
                        window.localStorage.setItem('organization', JSON.stringify(orgs[0]));
                        window.localStorage.setItem('factory', JSON.stringify(orgs[0].factories[0]));
                        this.setState({
                            redirect_to_liveview: true
                        });
                    } else {
                        this.setState({
                            organizations: orgs,
                            organization: orgs.length === 1 ? orgs[0] : null,
                            orgId: orgs.length === 1 ? orgs[0].id : -1
                        });
                    }
                })
                .catch(err => console.log('error: ' + JSON.stringify(err)));
        } else if (window.localStorage.getItem('factory')) {
            this.setState({
                redirect_to_liveview: true
            });
        } else if (window.localStorage.getItem('idToken')) {
            let orgs = this.webAuth.getIdTokenPayload()['https://livetracking.ca/app_metadata']['organizations'];
            if (orgs.length === 1 && orgs[0].factories.length === 1) {
                window.localStorage.setItem('organization', JSON.stringify(orgs[0]));
                window.localStorage.setItem('factory', JSON.stringify(orgs[0].factories[0]));
                this.setState({
                    redirect_to_liveview: true
                });
            } else {
                this.setState({
                    organizations: orgs,
                    organization: orgs.length === 1 ? orgs[0] : null,
                    orgId: orgs.length === 1 ? orgs[0].id : -1
                });
            }
        } else {
            this.setState({
                redirect_to_login: true
            });
        }
    }

    onOrgClicked = org => {
        if (org.factories.length === 1) {
            window.localStorage.setItem('organization', JSON.stringify(org));
            window.localStorage.setItem('factory', JSON.stringify(org.factories[0]));
            this.setState({
                redirect_to_liveview: true
            });
        } else {
            this.setState({
                organization: org,
                orgId: org.id
            });
        }
    };

    onFactoryClicked = factory => {
        window.localStorage.setItem('organization', JSON.stringify(this.state.organization));
        window.localStorage.setItem('factory', JSON.stringify(factory));
        this.setState(
            {
                factoryId: factory.id
            },
            () => this.setState({ redirect_to_liveview: true })
        );
    };

    onMouseOverOrg = id => {
        this.setState({
            mouse_over_org: id
        });
    };

    onMouseOutOrg = () => {
        this.setState({
            mouse_over_org: -1
        });
    };

    onMouseOverFactory = id => {
        this.setState({
            mouse_over_factory: id
        });
    };

    onMouseOutFactory = () => {
        this.setState({
            mouse_over_factory: -1
        });
    };

    renderOrganizationsGrid = () => {
        const { classes } = this.props;

        if (!this.state.organizations) {
            return (
                <div>
                    <CircularProgress />
                </div>
            );
        }

        if (this.state.organizations.length === 1) {
            return null;
        }

        return (
            <Grid item className="organizations_grid-container">
                {this.state.organizations.map(org => {
                    return (
                        <MenuItem
                            onClick={this.onOrgClicked.bind(this, org)}
                            onMouseOver={this.onMouseOverOrg.bind(this, org.id)}
                            onMouseOut={this.onMouseOutOrg}
                            value={org.id}
                            selected={org.id === this.state.orgId}
                            classes={{ root: classes.menu_item_root, selected: classes.menu_item_selected }}
                            key={'org-' + org.id}
                        >
                            <FactoryIcon
                                color={
                                    org.id === this.state.orgId
                                        ? '#E31C53'
                                        : this.state.mouse_over_org === org.id
                                        ? '#E31C53'
                                        : '#999'
                                }
                            />
                            <Typography variant="h6" className={classes.menu_item_typography}>
                                {org.name}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </Grid>
        );
    };

    renderFactoriesGrid = () => {
        let { organization } = this.state;
        const { classes } = this.props;

        if (organization) {
            return (
                <Grid item className="organizations_factories-grid">
                    {organization.factories.map(factory => {
                        return (
                            <MenuItem
                                onClick={this.onFactoryClicked.bind(this, factory)}
                                onMouseOver={this.onMouseOverFactory.bind(this, factory.id)}
                                onMouseOut={this.onMouseOutFactory}
                                value={factory.id}
                                selected={factory.id === this.state.factoryId}
                                classes={{ root: classes.menu_item_root, selected: classes.menu_item_selected }}
                                key={'factory-' + factory.id}
                            >
                                <FactoryIcon
                                    color={
                                        factory.id === this.state.factoryId
                                            ? '#E31C53'
                                            : this.state.mouse_over_factory === factory.id
                                            ? '#E31C53'
                                            : '#999'
                                    }
                                />
                                <Typography variant="h6" className={classes.menu_item_typography}>
                                    {factory.name}
                                </Typography>
                            </MenuItem>
                        );
                    })}
                </Grid>
            );
        } else {
            return <div />;
        }
    };

    render() {
        if (this.state.redirect_to_login) {
            return <Redirect to="/login" />;
        }

        if (this.state.redirect_to_liveview) {
            return <Redirect to="/liveview" />;
        }

        let header_class_name = this.state.collapse ? "header header--collapse" : "header"

        return (
            <div className="container">
                <HeaderTopNav className={header_class_name} noFactories />
                <div className="organizations">
                    <Paper elevation={3} className="organizations_container">
                        <Typography variant="subtitle1" className="organizations_subtitle">
                            Select Factory
                        </Typography>
                        <Divider />
                        <Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-start">
                            {this.renderOrganizationsGrid()}
                            <Divider orientation="vertical" flexItem />
                            {this.renderFactoriesGrid()}
                        </Grid>
                    </Paper>
                </div>
                <Footer />
            </div>
        );
    }
}

const styles = theme => ({
    menu_item_root: {
        color: '#999',
        '&:hover': {
            color: '#E31C53'
        }
    },
    menu_item_selected: {
        color: '#E31C53',
        backgroundColor: '#fff !important'
    },
    menu_item_typography: {
        marginLeft: theme.spacing(1)
    }
});

export default withStyles(styles)(Organizations);
