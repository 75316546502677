import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, TextField, Typography } from '@material-ui/core';
import { capitalize } from 'lodash';
import notificationScheduleOptions from '../utils/notificationScheduleOptions';
import { useEffect, useState } from 'react';


export default function NotificationScheduleRow({ day, start, end, onStartChange, onEndChange }) {
    const styles = useStyles();
    const startTimeOptions = [...notificationScheduleOptions];
    startTimeOptions.pop();

    const [endTimeOptions, setEndTimeOptions] = useState([...notificationScheduleOptions]);

    useEffect(() => {
        const newEndTimeOptions = [...notificationScheduleOptions];
        newEndTimeOptions.splice(0, start + 1);
        setEndTimeOptions(newEndTimeOptions);
    }, [start]);

    return <div className={styles.row}>
        <Typography variant={'body2'} className={styles.day}>{capitalize(day)}</Typography>
        <TextField select
                   className={styles.textField}
                   SelectProps={{
                       MenuProps: {
                           classes: {
                               paper: 'account_dropdown'
                           },
                           anchorOrigin: {
                               horizontal: 'left',
                               vertical: 'bottom'
                           },
                           transformOrigin: {
                               vertical: "top",
                               horizontal: "left"
                           },
                           getContentAnchorEl: null
                       }
                   }}
                   size='small'
                   hiddenLabel
                   value={start}
                   onChange={(event) => onStartChange(event, day)}
                   variant={'outlined'}>
            {startTimeOptions.map(option => {
                return <MenuItem key={option.key} value={option.value}>{option.label}</MenuItem>;
            })}
        </TextField>
        {start !== 0 && <>
            <Typography className={styles.to} variant={'body2'}>to</Typography>
            <TextField select
                       className={styles.textField}
                       SelectProps={{
                           MenuProps: {
                               classes: {
                                   paper: 'account_dropdown'
                               },
                               anchorOrigin: {
                                   horizontal: 'left',
                                   vertical: 'bottom'
                               },
                               transformOrigin: {
                                   vertical: "top",
                                   horizontal: "left"
                               },
                               getContentAnchorEl: null
                           }
                       }}
                       size='small'
                       hiddenLabel
                       value={end}
                       onChange={(event) => onEndChange(event, day)}
                       variant={'outlined'}>
                {endTimeOptions.map(option => {
                    return <MenuItem key={option.key} value={option.value}>{option.label}</MenuItem>;
                })}
            </TextField>
        </>
        }
    </div>;
}

//######## STYLE ########
const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        margin: '8px'
    },
    day: {
        marginRight: '8px',
        width: '88px'
    },
    to: {
        margin: 'auto 12px'
    },
    textField: {
        width: '120px'
    }
}));