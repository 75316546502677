import React, {Component} from 'react';

class CustomDiagonalAxisTick extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */


    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    render () {
        const {x, y, stroke, payload} = this.props;

        return (
            <g transform={`translate(${payload.coordinate},${y})`}>
                <text x={0} y={0} dy={4} style={{fontFamily: 'Arial'}}
                      textAnchor="end" fill="#666" fontSize="10px"  transform="rotate(-90)">{payload.value}</text>
            </g>
        );
    }
}

export default CustomDiagonalAxisTick