import React, {Component} from 'react';
import {Button, Chip} from "@material-ui/core";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

class UserManagementUsersTable extends Component {
    constructor(props) {
        super(props);
        this.webAuth = Auth0;
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */

    componentDidMount() {
        this.setState({
            user_role: JSON.parse(window.localStorage.getItem('factory')).role
        })
    }


    /* -------- FUNCTIONS -------- */
    onManageClick = (user) => {
        this.props.onManageUserClicked(user)
    }

    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */

    renderUserFactoryAndRoles = (user) => {
        let organizations = user.app_metadata.organizations
        let organization = organizations.find((org) => org.id === this.props.organization)

        return organization.factories.map((factory) => {
            let role = ""
            switch (factory.role) {
                case "factory_supervisor":
                    role = "Supervisor"
                    break;
                case "factory_admin":
                    role = "Admin"
                    break;
                case "factory_read_only":
                    role = "Read Only"
                    break;
                case "organization_owner":
                    role = "Organization Owner"
                    break;
                case "scoreboard":
                    role = "Scoreboard"
                    break;
                case "read_only":
                    role = "Read Only"
                    break;
                case "floor_team":
                    role = "Floor Team"
                    break;
                case "supervisor":
                    role = "Supervisor"
                    break;
                case "dosom":
                    role = "Dosom"
                    break;
                case "external_developer":
                    role = "Developer"
                    break;
                case "account_owner":
                    role = "Account Owner"
                    break;
                default:
                    role = factory.role;
            }

            return <Chip style={{ marginBottom: "8px" }} 
                        key={"factory-" + factory.id}
                         label={factory.name + " - " + role} />
        })
    }

    renderTable = () => {
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align={'left'} className='users_table_head_cell'>First Name</TableCell>
                            <TableCell align={'left'} className='users_table_head_cell'>Last Name</TableCell>
                            <TableCell align={'left'} className='users_table_head_cell'>Email</TableCell>
                            <TableCell align={'left'} className='users_table_head_cell'>Factory & Role</TableCell>
                            <TableCell align={'right'} className='users_table_head_cell'/>
                        </TableRow>
                    </TableHead>
                    {this.renderTableBody()}
                </Table>
            </TableContainer>
        )
    }

    renderTableBody = () => {
        if(this.props.users && this.props.users.length > 0) {
            return <TableBody>
                {this.props.users.map((user, index) => {
                    if (user.app_metadata.is_internal_developer || user.app_metadata.is_internal_trainer) {
                        return null
                    }
                    return <TableRow sx={{ wordBreak: "break-word" }} key={"user-" + user.identities[0].user_id}>
                        <TableCell align={'left'} className={'users_table_body_cell'}>
                            {user.user_metadata ? user.user_metadata.first_name : ''}
                        </TableCell>
                        <TableCell align={'left'} className={'users_table_body_cell'}>
                            {user.user_metadata ? user.user_metadata.last_name : ''}
                        </TableCell>
                        <TableCell align={'left'} className={'users_table_body_cell'}>{user.email}</TableCell>
                        <TableCell align={'left'} className={'users_table_body_cell'}>
                            <div
                                style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'left'}}>{this.renderUserFactoryAndRoles(user)}</div>
                        </TableCell>
                        <TableCell align={'right'} className={'users_table_body_cell'}>
                        {this.state.user_role !== "scoreboard" && this.state.user_role !== "read_only" && this.state.user_role !== "floor_team" ? (
                            <Button variant="text"
                                color="primary"
                                onClick={this.onManageClick.bind(this, user)}>
                                Manage
                            </Button>
                        ): null}
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        }
        else {
            return <div style={{ height: "140px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "20px" }}>
                No users
            </div>
        }
    }

    render() {
        return <div>
            {this.renderTable()}
        </div>
    }
}

export default UserManagementUsersTable