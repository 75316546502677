import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NoResultOutlinedIcon from '../../icons/NoResultOutlinedIcon';
import NotificationCard from './NotificationCard';
import { intersectionWith, isEmpty, startCase } from 'lodash';

export default function NotificationsResultsSection(props) {
    const classes = useStyles();
    const [filteredNotifications, setFilteredNotifications] = useState(null)

    //######## STATE AND EFFECT ########
    useEffect(() => {
        if (props.notifications.length > 0) {
            if (
                props.selectedLines.length === props.lines.length &&
                (props.type === 'downtime' || props.selectedProducts.length === props.products.length) &&
                props.selectedDowntimeReasons.length === props.downtimeReasons.length &&
                (props.downtimeDuration === '' || props.downtimeDurationType === -1) &&
                props.selectedSubscribers.length === props.subscribers.length)
            {
                setFilteredNotifications(props.notifications)
            } else {
                const filteredArray = props.notifications.filter(n => {
                    let match = true;
                    const notificationLines = n.lines
                    const notificationProducts = n.products
                    const notificationDowntimeReason = n.downtime_reason
                    const notificationDowntimeDuration = n.downtime_duration_secs
                    const notificationSubscribers = n.users

                    if (props.type === 'downtime' && props.selectedDowntimeReasons.length > 0) {
                        if (
                            !(notificationDowntimeReason === null ||
                            props.selectedDowntimeReasons.find(r => r.id === notificationDowntimeReason
                            ))) {
                            match = false;
                        }
                    }

                    if (props.type === 'downtime' && props.downtimeDuration !== '' && props.downtimeDurationType !== -1) {
                        const durationMultiplier =
                            props.downtimeDurationType === 0 ?
                                1 :
                                props.downtimeDurationType === 1 ?
                                    60 :
                                    3600
                        const duration = props.downtimeDuration * durationMultiplier
                        if (notificationDowntimeDuration < duration) {
                            match = false;
                        }
                    }

                    if (props.selectedSubscribers.length > 0) {
                        if (
                            intersectionWith(props.selectedSubscribers, notificationSubscribers, (a, b) => a.id === b.auth0_user_id).length === 0 &&
                            notificationSubscribers.length > 0
                        ) {
                            match = false;
                        }
                    }

                    if (props.selectedLines.length > 0) {
                        if (
                            !(notificationLines === null ||
                            isEmpty(notificationLines) ||
                            intersectionWith(props.selectedLines, notificationLines, (a, b) => a.id === b.id).length > 0)
                        ) {
                            match = false;
                        }
                    }

                    if (props.type !== 'downtime' && props.selectedProducts.length > 0) {
                        if (
                            !(notificationProducts === null ||
                            isEmpty(notificationProducts) ||
                            intersectionWith(props.selectedProducts, notificationProducts, (a, b) => a.id === b.id).length > 0)
                        ) {
                            match = false;
                        }
                    }

                    return match
                })

                setFilteredNotifications(filteredArray);
            }
        }
    }, [
        props.notifications,
        props.selectedLines,
        props.selectedProducts,
        props.selectedDowntimeReasons,
        props.downtimeDuration,
        props.downtimeDurationType,
        props.selectedSubscribers
    ])

    //######## FUNCTIONS ########

    //######## RENDER ########
    const renderLoading = () => {
        return props.loading && <div className={classes.LoadingContainer}>
            <CircularProgress variant='indeterminate'/>
        </div>
    }

    const renderError = () => {
        return props.error && <div className={classes.LoadingContainer}>
            <Typography variant={'subtitle1'}>Error Loading {startCase(props.type)} Notifications</Typography>
        </div>
    }

    const renderNotificationCards = () => {
        if (filteredNotifications.length === 0) {
            return renderNoResultsFound();
        }

        return <div className={classes.notificationsDiv}>
            {
                filteredNotifications.map((n, i) =>
                    <NotificationCard key={props.type+'-'+i}
                                      notification={n}
                                      type={props.type}
                                      products={props.products}
                                      lines={props.lines}
                                      downtimeReasons={props.downtimeReasons}
                                      user={props.user}
                                      onSubscribe={props.onSubscribe}
                                      onEditClick={() => props.onEditClick(n, props.type)}/>)
            }
        </div>
    }

    const renderNoResultsFound = () => {
        return <div className={classes.no_result_found_wrapper}>
            <NoResultOutlinedIcon />
            <Typography variant="subtitle1" className={classes.no_result_text}>
                No results found
            </Typography>
        </div>
    }

    if (filteredNotifications === null) {
        return <div/>
    }

    return (
        <Container className={classes.container} maxWidth={'lg'}>
            <Typography align={'left'} variant={'subtitle2'} className={classes.title}>
                {props.type}
            </Typography>
            {renderLoading()}
            {renderError()}
            {renderNotificationCards()}
        </Container>
    );
}

//######## STYLE ########
const useStyles = makeStyles(theme => ({
    container: {
        padding: 20
    },
    title: {
        color: '#999'
    },
    no_result_found_wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    },
    no_result_text: {
        color: '#bbb',
        marginTop: '20px'
    },
    notificationsDiv: {
        marginTop: 20,
        marginBottom: 30
    },
    LoadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '-20px',
        marginTop: '20px',
        background: '#F2F2F2',
        minHeight: '75vh'
    }
}));
