import React, {Component} from 'react';
import {Table, TableHeader, TableRow, TableRowColumn, TableBody} from "material-ui/Table";
import update from 'immutability-helper';
import UserManagementDeleteUserConfirmation from "./UserManagementDeleteUserConfirmation";
import UserManagementAddManageUserConfirmation from "./UserManagementAddManageUserConfirmation";
import {minimumRole} from "../components/PermissionsHelper";
import {auth0UserManagementAPIInstance} from "../components/Auth0API"
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    TextField,
    Checkbox,
    MenuItem,
    Select,
    CircularProgress
} from "@material-ui/core";

class UserManagementDialog extends Component {
    constructor(props) {
        super(props);
        this.webAuth = Auth0;
        this.state = {
            user: props.user,
            email: props.add ? "" : props.user.email,
            fromList: false,
            first_name: "",
            last_name: "",
            active_factories: this.getInitialActiveFactories(),
            roles: this.getInitialRole(),
            email_error: null,
            first_name_error: null,
            last_name_error: null,
            submitting: false,
            confirm_delete: false,
            confirm_submit: false
        }
    }

    /* -------- LIFECYCLE METHODS -------- */

    componentDidMount() {
        this.getEditableRoles()
    }

    /* -------- FUNCTIONS -------- */

    getEditableRoles = () => {
        // let params = {
        //     path: 'get_editable_roles',
        //     success: this.onGetEditableRolesSuccess,
        //     error: () => {},
        // };
        let defaultOrg = JSON.parse(window.localStorage.getItem("organization")).id
        let org = defaultOrg ? defaultOrg : this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"].id

        let defaultFactory = JSON.parse(window.localStorage.getItem("factory"))
        let factory = defaultFactory ? defaultFactory.id : null

        auth0UserManagementAPIInstance.get(`auth0/user/roles?organization_id=${org}&factory_id=${factory}`)
            .then(this.onGetEditableRolesSuccess)

        // API.c(params, 2);
    }

    onGetEditableRolesSuccess = (data) => {
        this.setState({
            editableRoles: data.data
        })
        if (this.props.add) {
            const newActiveFactories = [...this.state.active_factories]
            newActiveFactories.forEach((factory, i) => {
                newActiveFactories[i].role = data.data[0]
            })
            this.setState({ active_factories: [...newActiveFactories] })
        }
    }

    getRoleName = (role) => {
        let roleName = ''
        switch (role) {
            case "factory_supervisor":
                    roleName = "Supervisor"
                    break;
                case "factory_admin":
                    roleName = "Admin"
                    break;
                case "factory_read_only":
                    roleName = "Read Only"
                    break;
                case "organization_owner":
                    roleName = "Organization Owner"
                    break;
                case "scoreboard":
                    roleName = "Scoreboard"
                    break;
                case "read_only":
                    roleName = "Read Only"
                    break;
                case "floor_team":
                    roleName = "Floor Team"
                    break;
                case "supervisor":
                    roleName = "Supervisor"
                    break;
                case "dosom":
                    roleName = "Dosom"
                    break;
                case "external_developer":
                    roleName = "Developer"
                    break;
                case "account_owner":
                    roleName = "Account Owner"
                    break;
                default:
                    roleName = role;
        }
        return roleName
    }

    getInitialActiveFactories = () => {
        let active_factories = [];
        if(this.props.add) {
            for (let i = 0; i < this.props.factories.length; i++) {
                active_factories = update(active_factories, {[i]: {$set: {
                            factory_id: this.props.factories[i].id,
                            factory_name: this.props.factories[i].name,
                            factory_url: this.props.factories[i].url,
                            is_active: false,
                            role: "factory_admin",
                            initial_is_active: false,
                            initial_role: "",
                            can_manage: true
                }}})
            }
        } else {
            let userOrganizations = this.props.user.app_metadata.organizations
            let userOrganization = userOrganizations ? userOrganizations.find(org => org.id === this.props.organization) : null
            let userFactories = userOrganization ? userOrganization.factories : null
            for (let i = 0; i < this.props.factories.length; i++) {
                let isUserOnFactory = userFactories.find(factory => factory.id === this.props.factories[i].id)
                active_factories = isUserOnFactory ?
                    update(active_factories, {[i]: {$set: {
                                factory_id: isUserOnFactory.id,
                                factory_name: this.props.factories[i].name,
                                factory_url: this.props.factories[i].url,
                                is_active: !!isUserOnFactory,
                                role: isUserOnFactory.role,
                                initial_is_active: !!isUserOnFactory,
                                initial_role: isUserOnFactory.role,
                                can_manage: minimumRole(isUserOnFactory.role)
                    }}}) :
                    update(active_factories, {[i]: {$set: {
                                factory_id: this.props.factories[i].id,
                                factory_name: this.props.factories[i].name,
                                factory_url: this.props.factories[i].url,
                                is_active: !!isUserOnFactory,
                                role: "factory_admin",
                                initial_is_active: false,
                                initial_role: "",
                                can_manage: true
                    }}})
            }
        }

        return active_factories
    }

    getInitialRole = () => {
        let roles = [];

            this.props.factories.forEach(() => {
                let role = this.props.roles[0] ?
                    {id: this.props.roles[0].id} :
                    {id: -1};

                roles.push(role)
            });

        return roles
    }

    onFactoryCheck = (index, factory, event, isInputChecked) => {
        let activeFactories = this.state.active_factories;
        let updatedFactories = update(activeFactories, {[index]: {$merge: {is_active: isInputChecked}}})
        this.setState({
            active_factories: updatedFactories
        })
    }

    onChange = (event, newValue) => {

    }

    onUpdateInput = (event) => {
        let email = event.target.value
        let dataSource = this.getUsersDataSet(this.props.users)
        let fromList = dataSource.find((e) => {return email === e}) !== undefined;
        let user = null;
        let {active_factories} = this.state;
        if(fromList) {
            user = this.props.users.find(user => user.email === email)
            let userOrganizations = user.app_metadata.organizations
            let userOrganization = userOrganizations ? userOrganizations.find(org => org.id === this.props.organization) : null
            let userFactories = userOrganization ? userOrganization.factories : null
            for (let i = 0; i < this.props.factories.length; i++) {
                let isUserOnFactory = userFactories.find(factory => factory.id === this.props.factories[i].id)
                active_factories = isUserOnFactory ?
                    update(active_factories, {[i]: {$set: {
                                factory_id: isUserOnFactory.id,
                                factory_name: this.props.factories[i].name,
                                factory_url: this.props.factories[i].url,
                                is_active: !!isUserOnFactory,
                                role: isUserOnFactory.role,
                                can_manage: minimumRole(isUserOnFactory.role)
                            }}}) :
                    update(active_factories, {[i]: {$set: {
                                factory_id: this.props.factories[i].id,
                                factory_name: this.props.factories[i].name,
                                factory_url: this.props.factories[i].url,
                                is_active: !!isUserOnFactory,
                                role: this.state.editableRoles[0],
                                can_manage: true
                            }}})
            }
        } else {
            user = {email}
            for (let i = 0; i < this.props.factories.length; i++) {
                active_factories = update(active_factories, {[i]: {$set: {
                            factory_id: this.props.factories[i].id,
                            factory_name: this.props.factories[i].name,
                            factory_url: this.props.factories[i].url,
                            is_active: false,
                            role: this.state.editableRoles[0],
                            can_manage: true
                        }}})
            }
        }

        if(this.validateEmail(email)){
            this.setState({
                email,
                fromList,
                user,
                active_factories,
                email_error: null
            })
        } else {
            this.setState({
                email,
                email_error: "invalid email"
            })
        }
    }

    onRoleChange = (factory, factoryIndex, event)=> {
        let role = event.target.value
        let { active_factories } = this.state;
        let updatedRoles = update(active_factories, {[factoryIndex]: {$merge: {role}}})

        this.setState({
            active_factories: updatedRoles
        })
    }

    validateEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validateInput = () => {
        this.setState({
            email_error: null,
            first_name_error: null,
            last_name_error: null
        })

        let error_free = true;

        if(!(this.validateEmail(this.state.email))) {
            error_free = false
            this.setState({email_error: "invalid email"})
        }

        return error_free
    }

    getUsersDataSet = (users) => {
        let dataSet = []
        if(users && users[0]) {
            users.forEach(user => {
                dataSet.push(user.email)
            })
        }

        return dataSet;
    }

    showConfirm = () => {
        this.setState({confirm_submit: true})
    }

    /* -------- API CALLS -------- */
    saveUser = () => {
        if(this.validateInput()) {
            let {email, active_factories } = this.state;
            this.props.addEditUser(email, active_factories)

        }
    }

    /* -------- RENDERS -------- */
    renderFields = () => {

        if(this.state.confirm_delete) {
            return <UserManagementDeleteUserConfirmation
                user={this.state.user}
                organization={this.props.orgName}
                factories={this.state.active_factories}/>
        } else if(this.state.confirm_submit) {
            return <UserManagementAddManageUserConfirmation
                user={this.state.user}
                organization={this.props.orgName}
                active_factories={this.state.active_factories}/>
        } else {
            return <div>
                {!this.props.edit &&
                <TextField value={this.state.email}
                           error={!!this.state.email_error}
                           style={{ width: 360, marginBottom: "30px" }}
                           helperText={this.state.email_error ? this.state.email_error : ""}
                           label="Email address"
                           onChange={this.onUpdateInput}
                           margin="normal" />}
                {this.props.edit &&
                <div style={{display: 'flex', flexDirection: 'column', marginBottom: '30px'}}>
                    <Typography variant={'caption'} style={{fontWeight: 700, color: '#999'}}>Email address</Typography>
                    <Typography variant={'body1'}>{this.state.email}</Typography>
                </div>}
                <Table
                    selectable={false}
                    multiSelectable={false}>
                    <TableHeader
                        displaySelectAll={false}
                        adjustForCheckbox={false}
                        enableSelectAll={false}>
                        <TableRow style={{height: '20px', paddingTop: '20px'}}>
                            <TableRowColumn style={Styles.tableHeaderStyleCheckBox()}/>
                            <TableRowColumn style={Styles.tableHeaderStyle()}>Factory</TableRowColumn>
                            <TableRowColumn style={Styles.tableHeaderStyle()}>Role</TableRowColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        {this.renderFactoriesAndRolesTable()}
                    </TableBody>
                </Table>
            </div>
        }
    }

    renderFactoriesAndRolesTable = () => {
        // let roles = [];
        // this.props.roles.forEach(role => {
        //     if(minimumRole(role.value)) {
        //         roles.push(role)
        //     }
        // })
        let roles = this.state.editableRoles && this.state.editableRoles.length > 0 ? this.state.editableRoles : [""]

        let isLoggedAsRoot = JSON.parse(window.localStorage.getItem("factory")).role === "factory_admin"

        return this.props.factories.map((factory, index) => {
            let active_factory = this.state.active_factories.find(f => f.factory_id === factory.id)
            return <TableRow key={"factory-"+factory.id} style={Styles.tableRowStyle(index + 1)}>
                <TableRowColumn style={Styles.tableRowColumnStyleCheckBox()}>
                    <Checkbox
                        disabled={!isLoggedAsRoot && this.props.edit && (!active_factory || !roles.find((item) => item === active_factory.role))}
                        checked={active_factory ? active_factory.is_active : false}
                        onChange={this.onFactoryCheck.bind(this, index, factory)}
                        color="primary"
                        id={"factory-"+factory.id}/>
                </TableRowColumn>
                <TableRowColumn style={Styles.tableRowColumnUMStyle()}>{factory.name}</TableRowColumn>
                <TableRowColumn style={Styles.tableRowColumnUMStyle()}>
                    <Select
                        className="user-management__dialog-select"
                        disabled={!isLoggedAsRoot && this.props.edit && (!active_factory || !roles.find((item) => item === active_factory.role))}
                        value={this.props.edit ? (active_factory ? active_factory.role : '') : this.props.add ? (active_factory && roles.find((item) => item === active_factory.role) ? active_factory.role : roles[0]) : null}
                        onChange={this.onRoleChange.bind(this, factory, index)}>
                        {roles.map((role) => {
                            return <MenuItem key={"role-"+role} value={role}>{this.getRoleName(role)}</MenuItem>
                        })}
                        {!isLoggedAsRoot && this.props.edit && (!active_factory || !roles.find((item) => item === active_factory.role)) ? (
                            <MenuItem key={"role-"+active_factory.role} value={active_factory.role}>{this.getRoleName(active_factory.role)}</MenuItem>
                        ) : null}
                    </Select>
                </TableRowColumn>
            </TableRow>
        })
    }

    renderErrorMessage = () => {
        if (this.props.saving_user_error) {
            return <div>There was an error saving this user</div>
        }
    }

    renderProgress = () => {
        if (this.props.saving_user) {
            return <CircularProgress/>
        }
    }

    render() {
        let actions;
        if(this.state.confirm_delete) {
            actions = <div>
                <div className="add-user__error">{this.renderErrorMessage()}</div>
                <div style={{display: "inline-flex"}}>{this.renderProgress()}</div>
                <Button variant="text"
                        color="primary"
                        disabled={this.state.submitting}
                        onClick={() => this.setState({confirm_delete: false})}>Cancel</Button>
                {/* <Button variant="contained"
                        color="secondary"
                        disabled={this.state.submitting}
                        onClick={() => console.log("delete")}>{"Revoke User's access to " + this.props.orgName}</Button> */}
            </div>
        } else if (this.state.confirm_submit) {
            actions = <div>
                <div className="add-user__error">{this.renderErrorMessage()}</div>
                <div style={{display: "inline-flex"}}>{this.renderProgress()}</div>
                <Button variant="text"
                        color="primary"
                        disabled={this.state.submitting}
                        onClick={() => this.setState({confirm_submit: false})}>Cancel</Button>
                <Button variant="contained"
                        color="primary"
                        disabled={this.state.submitting}
                        onClick={this.saveUser}>Confirm</Button>
            </div>
        } else {
           actions = <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                <div style={{display: "flex", flex: 1, justifyContent: 'flex-end'}}>
                    <div style={{display: "inline-flex"}}>{this.renderProgress()}</div>
                    <Button variant="text"
                            color="primary"
                            disabled={this.state.submitting}
                            onClick={this.props.onRequestClose}>Cancel</Button>
                    <Button variant="contained"
                            color="primary"
                            disabled={this.state.submitting || !this.validateEmail(this.state.email)}
                            onClick={this.showConfirm}>{this.state.fromList ? "Edit": "Save"}</Button>
                </div>
            </div>
        }

        return <Dialog
            open
            onClose={this.props.onRequestClose}
            scroll="paper"
            disableBackdropClick={this.state.submitting}
            disableEscapeKeyDown={this.state.submitting}>
            <DialogTitle disableTypography>
                <Typography variant="subtitle1">{(this.props.add && !this.state.fromList) ? "Add New User" : "Manage User"}</Typography>
            </DialogTitle>
            <DialogContent>
                {this.renderFields()}
            </DialogContent>
            <DialogActions style={{marginTop: '30px', padding: '12px 24px'}}>
                {actions}
            </DialogActions>
        </Dialog>
    }
}

export default UserManagementDialog