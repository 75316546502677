import React, {useEffect, useState} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, MenuItem,
    TextField,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

export default function AddNewRun(props) {
    const classes = useStyles();
    const [products, setProducts] = useState(null)
    const [productsError, setProductsError] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState('')
    const [selectProductError, setSelectProductError] = useState(null)

    useEffect(() => {
        getLineProducts()
    }, [])

    function getLineProducts(){
        let params = {
            path: "line_products",
            path_variables: {ID: props.line.line_id},
            success: lineProductsFetchSuccess,
            error: lineProductsFetchError
        }

        API.c(params, 2)
    }

    function lineProductsFetchSuccess(data) {
        setProducts(data)
    }

    function lineProductsFetchError(error) {
        setProductsError(true)
        console.error(error)
    }

    function handleSelectProductChange(event) {
        setSelectedProduct(event.target.value)
        setSelectProductError(null)
    }

    function iterateProducts() {
        let sortedProducts = [...products];

        sortedProducts.sort(compareProducts)

        return sortedProducts.map((item) =>
            <MenuItem value={item.product_id}
                      key={"product-"+item.product_id}>
                {item.product_name + " - " + item.product_desc}
            </MenuItem>
        )
    }

    const compareProducts = (a, b) => {
        let aName = a.product_name + " - " + a.product_desc
        let bName = b.product_name + " - " + b.product_desc

        if (aName < bName) {
            return -1;
        } else if (aName > bName) {
            return 1;
        } else {
            return 0;
        }
    }

    function onStartNewRunClick() {
        let params = {
            path: "create_run",
            path_variables: {LINE_ID: props.line.line_id},
            data: {
                line: props.line.line_id,
                product: selectedProduct,
                start_time: new Date().toISOString(),
                name: ''
            },
            success: onStartNewRunSuccess,
            error: onStartNewRunError
        }
        API.c(params, 2)
    }

    function onStartNewRunSuccess(data) {
        props.onStartNewRunSuccess(data)
    }

    function onStartNewRunError(error) {
        if(error && error.error === "There is a run currently running") {
            props.onRunCurrentlyRunning()
        } else {
            console.error(error)
        }
    }

    return <Dialog open={true} maxWidth={'md'}>
        <DialogTitle disableTypography>
            <Typography variant={'h6'}>Start New Run</Typography>
        </DialogTitle>
        <DialogContent className={'liveview-add-run__dialog-content'}>
            <Typography variant={'subtitle2'}
                        color={'secondary'}>{props.line.line_name}</Typography>
            {
                products ?
                    <TextField select
                               className={classes.textField}
                               label="Product"
                               value={selectedProduct}
                               onChange={handleSelectProductChange}
                               error={selectProductError !== null}
                               helperText={selectProductError !== null ? selectProductError : ''}>
                        {iterateProducts()}
                    </TextField>
                    :
                    <CircularProgress/>
            }
        </DialogContent>
        <DialogActions>
            <Button variant={'text'}
                    color={'primary'}
                    onClick={props.hideStartNewRun}>Cancel</Button>
            <Button variant={'contained'}
                    color={'primary'}
                    disabled={selectedProduct === ''}
                    onClick={onStartNewRunClick}>Start New Run</Button>
        </DialogActions>
    </Dialog>
}

const useStyles = makeStyles((theme) => ({
    textField: {
        minWidth: '250px'
    }
}))