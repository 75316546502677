import React, {Fragment, useState} from 'react';
import {
    TableCell,
    TableRow,
    Collapse,
    IconButton,
    Typography,
    Button,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    TextField
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

export default function UnitChangeConfirmationRow(props) {
    const classes = useStyles();

    if(!props.changed) return null;

    const getTotalValue = () => {
        let noValue = '--';
        // let totalValue = props.subValues;
        let totalValue = Number(props.subValues[0]);
        // props.subValues.forEach((v, i) => {
        //     if(v.value > 0) {
        //         if(i === 0) {
        //             totalValue = parseFloat(v.value);
        //         } else {
        //             totalValue *= parseFloat(v.value);
        //         }
        //     }
        // })

        if(totalValue !== -1) {
            return Math.round((totalValue + Number.EPSILON) * 10000) / 10000
        } else {
            return noValue;
        }
    }

    const getTotalOriginal = () => {
        let noValue = '--';
        let totalValue = props.originalValues[0];
        // console.log(props.originalValues);
        // props.originalValues.forEach((v, i) => {
        //     if(v.value > 0) {
        //         if(i === 0) {
        //             totalValue = parseFloat(v.value);
        //         } else {
        //             totalValue *= parseFloat(v.value);
        //         }
        //     }
        // })

        if(totalValue !== -1) {
            return Math.round((totalValue + Number.EPSILON) * 10000) / 10000
        } else {
            return noValue;
        }
    }

    return <Fragment>
        <TableRow>
            <TableCell><Typography variant={"subtitle2"} className={classes.name}>{props.name}</Typography></TableCell>
            <TableCell><Typography variant={"body1"} className={classes.name}>{getTotalOriginal() + " " + props.originalUnitType}</Typography></TableCell>
            <TableCell><Typography variant={"body1"} className={classes.name}>{getTotalValue() + " " + props.unitType}</Typography></TableCell>
        </TableRow>
        {/* <TableRow className={classes.innerRow}>
            <TableCell/>
            <TableCell className={classes.tableCell}>
                {props.originalValues.map((v, i) => {
                    return <Typography key={"original-value-"+i} variant={"body1"}>{v.value ? v.value : v ? v : ""}</Typography>
                })}
            </TableCell>
            <TableCell className={classes.tableCell}> */}
                {/* {props.subValues.map((v, i) => {
                    return <Typography key={"sub-value-"+i} variant={"body1"}>{v.value + " " + v.notes}</Typography>
                })} */}
                {/* <Typography key={"sub-value-"} variant={"body1"}>{props.subValues[0] ? props.subValues[0] : ""}</Typography>
            </TableCell> */}
        {/* </TableRow> */}
    </Fragment>
}

//######## STYLE ########
const useStyles = makeStyles((theme) => ({
    name: {
        color: '#666'
    },
    innerRow: {
        boxShadow: 'inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08)',
        background: '#f2f2f2'
    },
    label: {
        fontWeight: 700,
        color: '#999'
    },
    tableCell: {
        verticalAlign: 'top'
    }
}))