import React, { Component } from 'react';
import { FontIcon } from 'material-ui';
import Checkbox from '@material-ui/core/Checkbox';
import AddProductModal from '../modals/AddProductModal';
import DeleteProductModal from '../modals/DeleteProductModal';
import { Tooltip as TippyTooltip } from 'react-tippy';
import BulkEditProducts from '../modals/BulkEditProducts';
import {
    CircularProgress,
    Button,
    Chip,
    Fab,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    FormControlLabel
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AddOutlined, ArrowDropDown } from '@material-ui/icons';
import SelectLineModal from "../modals/SelectLineModal";
import _, { forEach, orderBy } from 'lodash'
import AddCategoryModal from '../modals/AddCategoryModal';
import API from '../components/API.js';
import AddTagModal from '../modals/AddTagModal';
import SelectTagModal from '../modals/SelectTagModal';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableSortLabel, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SelectCategoryModal from '../modals/SelectCategoryModal';

class Products extends Component {
    constructor(props) {
        super(props);
        this.webAuth = Auth0;
        this.state = {
            lines: null,
            selected_lines: [],
            linesModalOpen: false,
            products: null,
            filtered_products: null,
            productsError: null,
            submitting_edit: false,
            submitting_delete: false,
            deletingItem: false,
            productId: null,
            show_modal: false,
            productDetails: false,
            productDetailsInfo: null,
            detailsError: null,
            product: null,
            sort: 1,
            search: '',
            products_speed: null,
            show_bulk_edit: false,
            is_active: '',
            target_speed: '',
            number_of_lanes: '',
            category: '',
            description: '',
            confirm_bulk_edit: false,
            submitting_bulk_edit: false,
            calls: 0,
            start_calls: 0,
            confirmed_products: [],
            categories: [],
            selected_categories: [],
            categoriesModalOpen: false,
            uncategorized_products: true,
            allCategories: [],
            deleteCategoryConfirmOpen: false,
            deletableCategoryId: 0,
            deleteCategoryConfirmed: false,
            allTags: [],
            selected_tags: [],
            untagged_products: 1,
            tagsModalOpen: false,
            order: 'asc',
            orderBy: 'category'
        };
    }

    componentDidMount() {
        this.getProducts();
        this.getTags();
        this.getProductSpeeds();
        this.getCategories();
        LineStore.subscribeLines(this.onLinesChange);
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        if (params.lines) {
            this.setState({
                selected_lines: params.lines.split(' ').map(Number)
            })
        }
        this.setState({
            user_role: JSON.parse(window.localStorage.getItem('factory')).role
        })
    }

    componentWillUnmount() {
        LineStore.unsubscribe(this.onLinesChange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.factory !== this.props.factory) {
            this.setState({
                lines: null,
                selected_lines: [],
                linesModalOpen: false,
                products: null,
                filtered_products: null,
                productsError: null,
                submitting_edit: false,
                submitting_delete: false,
                deletingItem: false,
                productId: null,
                show_modal: false,
                productDetails: false,
                productDetailsInfo: null,
                detailsError: null,
                product: null,
                sort: 1,
                search: '',
                products_speed: null,
                show_bulk_edit: false,
                is_active: '',
                target_speed: '',
                number_of_lanes: '',
                category: '',
                description: '',
                confirm_bulk_edit: false,
                submitting_bulk_edit: false,
                calls: 0,
                start_calls: 0,
                confirmed_products: [],
                categories: [],
                deleteCategoryConfirmOpen: false,
                deletableCategoryId: 0,
                deleteCategoryConfirmed: false,
                allTags: [],
                selected_tags: [],
                untagged_products: 1,
                tagsModalOpen: false,
            });
            LineStore.clear();
            this.getProducts();
            this.getProductSpeeds();
            LineStore.fetchLines();
        }
    }

    onLinesChange = () => {
        const linesData = LineStore.getLines()
        const selectedLines = []
        if (linesData && Array.isArray(linesData)) {
            linesData.forEach((item, i) => {
                selectedLines.push(item.id)
            })
        }
        this.setState({
            lines: linesData,
            selected_lines: selectedLines
        });
    };

    getProducts() {
        let params = {
            path: 'products',
            success: this.fetchSuccess,
            error: this.fetchError
        };

        API.c(params);
    }

    getCategories = () => {
        let params = {
            path: 'all_categories',
            success: this.fetchSuccessCategories,
            error: this.fetchError
        };

        API.c(params);
    }

    getTags = () => {
        let params = {
            path: 'all_tags',
            success: this.fetchSuccessTags,
            error: this.fetchError
        };

        API.c(params);
    }

    fetchSuccessCategories = data => {
        let categories = [];
        data.forEach(d => {
            categories.push(d);
        });
        const selectedCategories = []
        categories.forEach((item, i) => {
            selectedCategories.push(item.id)
        })
        this.setState({
            allCategories: categories,
            selected_categories: selectedCategories,
            deleteCategoryConfirmed: false,
            deleteCategoryConfirmOpen: false
        });
    }

    fetchSuccessTags = data => {
        let tags = [];
        data.forEach(d => {
            tags.push(d);
        });
        this.setState({
            allTags: tags,
            selected_tags: Array.from(tags, (item) => item.id),
            deleteTagConfirmed: false,
            deleteTagConfirmOpen: false
        });
    }

    fetchSuccess = data => {
        let categories = [];
        data.forEach(d => {
            if (!categories.includes(d.category)) {
                categories.push(d.category);
            }
        });
        let products = data;
        this.setState({
            products,
            filtered_products: products,
            categories
        });
    };

    fetchError = error => {
        this.setState({
            productsError: 'There was an error loading products data!'
        });
    };

    getProductSpeeds = () => {
        let params = {
            path: 'get_product_speed',
            success: this.fetchProductSpeedSuccess,
            error: this.fetchProductSpeedError
        };

        API.c(params);
    };

    fetchProductSpeedSuccess = data => {
        this.setState({
            products_speed: data
        });
    };

    fetchProductSpeedError = error => {
        this.setState({
            products_speed_error: 'There was an error loading products data!'
        });
    };

    handleChange(event, search) {
        let filtered_products = [];

        if (search !== '') {
            this.state.products.forEach(p => {
                let matchSearch = false;

                if (p.name.toLowerCase().includes(search.toLowerCase())) {
                    matchSearch = true;
                }

                if (p.category.toLowerCase().includes(search.toLowerCase())) {
                    matchSearch = true;
                }

                if (p.desc.toLowerCase().includes(search.toLowerCase())) {
                    matchSearch = true;
                }

                if (matchSearch) {
                    filtered_products.push(p);
                    filtered_products.sort(this.compareProduct);
                }
            });
        } else {
            filtered_products = [...this.state.products];
            filtered_products.sort(this.compareProduct);
        }

        this.setState({
            search,
            filtered_products
        });
    }

    onBulkEditFieldChange = (type, value) => {
        let s = this.state;
        s[type] = value;

        this.setState(s);
    };

    getSortIcon = sortOn => {
        let className = 'zmdi zmdi-sort-amount-desc';

        if (sortOn === 'code') {
            switch (this.state.sort) {
                case 0:
                    className = 'zmdi zmdi-sort-amount-desc bold';
                    break;
                case 1:
                    className = 'zmdi zmdi-sort-amount-asc bold';
                    break;
            }
        }
        if (sortOn === 'desc') {
            switch (this.state.sort) {
                case 2:
                    className = 'zmdi zmdi-sort-amount-desc bold';
                    break;
                case 3:
                    className = 'zmdi zmdi-sort-amount-asc bold';
                    break;
            }
        }

        if (sortOn === 'cat') {
            switch (this.state.sort) {
                case 4:
                    className = 'zmdi zmdi-sort-amount-desc bold';
                    break;
                case 5:
                    className = 'zmdi zmdi-sort-amount-asc bold';
                    break;
            }
        }

        return className;
    };

    sortProducts = sortOn => {
        let sort = 0;
        switch (sortOn) {
            case 'code':
                if (this.state.sort === 1) {
                    sort = 0;
                } else {
                    sort = 1;
                }
                break;
            case 'desc':
                if (this.state.sort === 3) {
                    sort = 2;
                } else {
                    sort = 3;
                }
                break;
            case 'cat':
                if (this.state.sort === 5) {
                    sort = 4;
                } else {
                    sort = 5;
                }
                break;
        }

        this.setState({
            sort
        });
    };

    compareProduct = (a, b) => {
        switch (this.state.orderBy) {
            case 'category':
                if (a.category < b.category) {
                    return this.state.order === 'asc' ? -1 : 1;
                } else if (a.category > b.category) {
                    return this.state.order === 'asc' ? 1 : -1;
                } else {
                    return 0;
                }
            case 'code':
                if (a.name < b.name) {
                    return this.state.order === 'asc' ? -1 : 1;
                } else if (a.name > b.name) {
                    return this.state.order === 'asc' ? 1 : -1;
                } else {
                    return 0;
                }
            case 'desc':
                if (a.desc < b.desc) {
                    return this.state.order === 'asc' ? -1 : 1;
                } else if (a.desc > b.desc) {
                    return this.state.order === 'asc' ? 1 : -1;
                } else {
                    return 0;
                }
        }
    };

    showTagsFilter = () => {
        if (this.state.allTags == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (this.state.selected_tags && this.state.allTags && this.state.allTags[0]) {
            if (this.state.selected_tags.length === this.state.allTags.length && this.state.untagged_products) {
                count = 'All';
            } else {
                let untagged_count = this.state.untagged_products ? 1 : 0;
                count = (this.state.selected_tags.length + untagged_count).toString();
            }
        }
        else if (this.state.untagged_products) {
            count = 'All';
        }
        else {
            count = '0'
        }

        return (
            <div style={{ marginRight: '10px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    style={{
                        justifyContent: 'start',
                        fontSize: '16px !important',
                        marginRight: '10px !important',
                        marginTop: '12px !important',
                        textAlign: 'start !important',
                        textTransform: 'capitalize !important'
                    }}
                    variant="text"
                    fullWidth
                    disabled={this.state.allTags == null}
                    onClick={() => this.setState({ tagsModalOpen: true })}
                    endIcon={<ArrowDropDown />}
                >
                    {'Tag Filter (' + count + ')'}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    }

    showTagsFilterModal = () => {
        let tagsParsed = []
        if (this.state.allTags && Array.isArray(this.state.allTags) && this.state.allTags[0]) {
            tagsParsed = this.state.allTags.map((item) => {
                // item.id = item.line_id;
                // item.name = item.line_name;
                return item;
            })
        }
        return (
            this.state.tagsModalOpen && (
                <SelectTagModal
                    style={{ left: "190px", top: "110px" }}
                    tags={this.state.allTags}
                    untagged_products={this.state.untagged_products}
                    selected_tags={this.state.selected_tags}
                    hideSelectTags={this.hideTagsFilterModal}
                    cancelSelectTags={() => { this.setState({ tagsModalOpen: false }) }}
                />
            )
        );
    }

    hideTagsFilterModal = (selected_tags, untagged_products) => {
        let updatedSelection = Array.from(selected_tags);
        this.setState({
            selected_tags: updatedSelection,
            tagsModalOpen: false,
            untagged_products: untagged_products,
        });
    };

    showLines = () => {
        if (this.state.lines == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (this.state.lines && this.state.lines[0])
            if (this.state.selected_lines.length === this.state.lines.length) {
                count = 'All';
            } else {
                count = this.state.selected_lines.length ? this.state.selected_lines.length.toString() : '0';
            }

        return (
            <div style={{ marginRight: '10px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    style={{
                        justifyContent: 'start',
                        fontSize: '16px !important',
                        marginRight: '10px !important',
                        marginTop: '12px !important',
                        textAlign: 'start !important',
                        textTransform: 'capitalize !important'
                    }}
                    variant="text"
                    fullWidth
                    disabled={this.state.lines == null}
                    onClick={() => this.setState({ linesModalOpen: true })}
                    endIcon={<ArrowDropDown />}
                >
                    {'Line Filter (' + count + ')'}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };

    showImportExportButton = () => {
        return (
            <div style={{ padding: '11px 0 12px 12px', float: 'right' }}>
                <Button
                    // style={{
                    //     justifyContent: 'start',
                    //     fontSize: '16px !important',
                    //     marginRight: '10px !important',
                    //     marginTop: '12px !important',
                    //     textAlign: 'start !important',
                    //     textTransform: 'capitalize !important'
                    // }}
                    variant="contained"
                    component={Link}
                    to={`/settings/import-export`}
                    color="primary"
                    fullWidth
                    // disabled={this.state.lines == null}
                    // onClick={() => {  }}
                >
                    Bulk Import/Export Products
                </Button>
                {/* <hr className="filter-button-hr" /> */}
            </div>
        );
    }

    showCategoriesFilter = () => {
        if (this.state.categories == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (this.state.categories && this.state.categories[0])
            if (this.state.selected_categories.length === this.state.categories.length) {
                count = 'All';
            } else {
                count = this.state.selected_categories.length ? this.state.selected_categories.length.toString() : '0';
            }

        return (
            <div style={{ marginRight: '10px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    style={{
                        justifyContent: 'start',
                        fontSize: '16px !important',
                        marginRight: '10px !important',
                        marginTop: '12px !important',
                        textAlign: 'start !important',
                        textTransform: 'capitalize !important'
                    }}
                    variant="text"
                    fullWidth
                    disabled={this.state.categories == null}
                    onClick={() => this.setState({ categoriesModalOpen: true })}
                    endIcon={<ArrowDropDown />}
                >
                    {'Category Filter (' + count + ')'}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };

    showLinesModal = () => {
        let linesParsed = []
        if (this.state.lines && Array.isArray(this.state.lines) && this.state.lines[0]) {
            linesParsed = this.state.lines.map((item) => {
                // item.id = item.line_id;
                // item.name = item.line_name;
                return item;
            })
        }
        return (
            this.state.linesModalOpen && (
                <SelectLineModal
                    style={{
                        top: "60px",
                        left: "15px"
                    }}
                    lines={linesParsed}
                    selected_lines={this.state.selected_lines}
                    hideSelectLines={this.hideLinesModal}
                    cancelSelectLines={() => { this.setState({ linesModalOpen: false }) }}
                />
            )
        );
    };

    showCategoriesModal = () => {
        let categoriesParsed = []
        if (this.state.categories && Array.isArray(this.state.categories) && this.state.categories[0]) {
            categoriesParsed = this.state.categories.map((item) => {
                const parsed = this.state.allCategories.find((category) => category.id === item) != undefined ? this.state.allCategories.find((category) => category.id === item) : null
                // item.id = item.line_id;
                // item.name = item.line_name;
                if (parsed) {
                    return parsed;
                }
                return
            })
            categoriesParsed = categoriesParsed.filter((item) => {
                return item
            });
        }
        return (
            this.state.categoriesModalOpen && (
                <SelectCategoryModal
                    categories={categoriesParsed}
                    left={10}
                    top={110}
                    height={400}
                    uncategorized_products={this.state.uncategorized_products}
                    selected_categories={this.state.selected_categories}
                    hideSelectCategories={this.hideCategoriesModal}
                    cancelSelectCategories={() => { this.setState({ categoriesModalOpen: false }) }}
                />
            )
        );
    };

    hideLinesModal = (new_selected_lines) => {
        this.setState({
            linesModalOpen: false,
            selected_lines: new_selected_lines,
        })

    }

    hideCategoriesModal = (new_selected_categories, uncategorized_products) => {
        this.setState({
            categoriesModalOpen: false,
            selected_categories: new_selected_categories,
            uncategorized_products: uncategorized_products,
        })
        this.forceUpdate()

    }

    onDeleteCategory = (id) => {
        let params = {
            path: 'delete_category',
            path_variables: { ID: id },
            success: this.getCategories,
            error: this.fetchError
        };

        API.c(params);
    }

    onDeleteTag = (id) => {
        let params = {
            path: 'delete_tag',
            path_variables: { ID: id },
            success: this.getTags,
            error: this.fetchError
        };

        API.c(params);
    }

    handleCloseDeleteCategoryConfirmation = () => {
        this.setState({
            deleteCategoryConfirmOpen: false,
            deleteCategoryConfirmed: false
        })
    }

    handleCloseDeleteTagConfirmation = () => {
        this.setState({
            deleteTagConfirmOpen: false,
            deleteTagConfirmed: false
        })
    }

    productShouldBeDisplayed = (item) => {
        let canBeDisplayed = false
        if (this.state.selected_categories.length > 0) {
            this.state.selected_categories.forEach((selected_category) => {
                if (selected_category === item.category) {
                    canBeDisplayed = true
                }
                if (!item.category && this.state.uncategorized_products) {
                    canBeDisplayed = true
                }
            })
        }
        else {
            if (!item.category && this.state.uncategorized_products) {
                canBeDisplayed = true
            }
        }
        return canBeDisplayed
    }

    createSortHandler = (property) => (event) => {
        this.handleRequestSort(event, property);
    };

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };

    getNumOfFilteredLines = () => {
        let count = '0';

        if (this.state.lines && this.state.lines[0])
            if (this.state.selected_lines.length === this.state.lines.length) {
                count = 'All';
            } else {
                count = this.state.selected_lines.length ? this.state.selected_lines.length.toString() : '0';
            }
        return count
    };

    getNumOfFilteredTags = () => {
        let count = '0';

        if (this.state.selected_tags && this.state.allTags && this.state.allTags[0]) {
            if (this.state.selected_tags.length === this.state.allTags.length && this.state.untagged_products) {
                count = 'All';
            } else {
                let untagged_count = this.state.untagged_products ? 1 : 0;
                count = (this.state.selected_tags.length + untagged_count).toString();
            }
        }
        else if (this.state.untagged_products) {
            count = 'All';
        }
        else {
            count = '0'
        }

        return count
    };

    showProductsTable = () => {
        let { filtered_products } = this.state;

        if (this.state.products === null) {
            return (
                <div>
                    <div className="settings__table">
                        <CircularProgress />
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="settings__table">
                    {this.showLines()}
                    {this.showImportExportButton()}
                    {/* {this.showCategoriesFilter()} */}
                    {/* {this.showTagsFilter()} */}
                    <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow style={{ height: '20px', paddingTop: '20px' }}>
                                <TableCell
                                    align={'left'}
                                    className={'products_table_head_cell'}
                                    sortDirection={this.state.orderBy === "category" ? this.state.order : false}
                                    // onClick={this.sortProducts.bind(this, 'cat')}
                                >
                                    <div className={'products_table_filter_cell'} style={{ display: "flex" }}>
                                        <TableSortLabel
                                            active={this.state.orderBy === 'category'}
                                            direction={this.state.orderBy === 'category' ? this.state.order : 'asc'}
                                            onClick={this.createSortHandler('category')}
                                        >
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <div style={{ marginRight: '4px', color: "rgba(0, 0, 0, 1) !important" }}>Category</div>
                                            </div>
                                            {this.state.orderBy === 'category' ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                        <div className={this.state.selected_categories.length + (this.state.uncategorized_products ? 1 : 0) < this.state.categories.length ? 'table_filter_icon_cell_selected' : 'table_filter_icon_cell'} onClick={() => this.setState({ categoriesModalOpen: true })} style={{ display: "flex", alignItems: "center" }}>
                                            {
                                                
                                                    <TippyTooltip
                                                        html={
                                                            <div
                                                                style={{
                                                                    fontFamily: 'Roboto',
                                                                    fontSize: '10px',
                                                                    color: '#fff',
                                                                }}
                                                            >
                                                                Filter
                                                            </div>
                                                        }
                                                        position="right"
                                                        animation="fade"
                                                        theme="dark"
                                                    >
                                                        <FilterAltIcon sx={{ display: "flex", alignItems: "center", color: "rgba(0, 0, 0, 0.54)" }} color='rgba(0, 0, 0, 0.54)' fontSize='small' />
                                                    </TippyTooltip>
                                            }
                                            {/* <span style={{ fontSize: '10px', cursor: "pointer" }}>{this.getNumOfFilteredTags()}</span> */}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell
                                    align={'left'}
                                    className={'products_table_head_cell'}
                                >
                                    <div className={'products_table_filter_cell'} style={{ display: "flex", width: "55px" }}>
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'left',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div style={{ marginRight: '4px' }}>Tags</div>
                                        </div>
                                        <div className={this.state.selected_tags.length + (this.state.untagged_products ? 1 : 0) < this.state.allTags.length + 1 ? 'table_filter_icon_cell_selected' : 'table_filter_icon_cell'} onClick={() => this.setState({ tagsModalOpen: true })} style={{ display: "flex", alignItems: "center" }}>
                                            {
                                                
                                                    <TippyTooltip
                                                        html={
                                                            <div
                                                                style={{
                                                                    fontFamily: 'Roboto',
                                                                    fontSize: '10px',
                                                                    color: '#fff',
                                                                }}
                                                            >
                                                                Filter
                                                            </div>
                                                        }
                                                        position="right"
                                                        animation="fade"
                                                        theme="dark"
                                                    >
                                                        <FilterAltIcon sx={{ display: "flex", alignItems: "center", color: "rgba(0, 0, 0, 0.54)" }} color='rgba(0, 0, 0, 0.54)' fontSize='small' />
                                                    </TippyTooltip>
                                            }
                                            {/* <span style={{ fontSize: '10px', cursor: "pointer" }}>{this.getNumOfFilteredTags()}</span> */}
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell
                                    align={'left'}
                                    className={'products_table_head_cell'}
                                    sortDirection={this.state.orderBy === "code" ? this.state.order : false}
                                >
                                    <TableSortLabel
                                        active={this.state.orderBy === 'code'}
                                        direction={this.state.orderBy === 'code' ? this.state.order : 'asc'}
                                        onClick={this.createSortHandler('code')}
                                    >
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'left',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div style={{ marginRight: '4px' }}>Code</div>
                                        </div>
                                        {this.state.orderBy === 'code' ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    align={'left'}
                                    sx={{maxWidth: "400px"}}
                                    className={'products_table_head_cell'}
                                    sortDirection={this.state.orderBy === "desc" ? this.state.order : false}
                                >
                                    <TableSortLabel
                                        active={this.state.orderBy === 'desc'}
                                        direction={this.state.orderBy === 'desc' ? this.state.order : 'asc'}
                                        onClick={this.createSortHandler('desc')}
                                    >
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'left',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div style={{ marginRight: '4px' }}>Description</div>
                                        </div>
                                        {this.state.orderBy === 'desc' ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align={'left'} className={'products_table_head_cell'}>
                                    Target Speed
                                </TableCell>
                                <TableCell align={'left'} className={'products_table_head_cell'} />
                            </TableRow>
                        </TableHead>
                        <TableBody>{this.showProducts()}</TableBody>
                    </Table>
                    </TableContainer>
                    {this.showTagsFilterModal()}
                    {this.showCategoriesModal()}
                    {this.showLinesModal()}
                </div>
            </div>
        );
    };

    showTagsTable = () => {
        if (this.state.allTags === null) {
            return (
                <div>
                    <div className="settings__table">
                        <CircularProgress />
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="settings__table">
                    <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow style={{ height: '20px', paddingTop: '20px', borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                <TableCell
                                    align={'left'}
                                    className={'products_table_head_cell'}
                                    onClick={this.sortProducts.bind(this, 'cat')}
                                >
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'left',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ marginRight: '4px' }}>Tag Name</div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{this.showTags()}</TableBody>
                    </Table>
                    </TableContainer>
                    {this.showLinesModal()}
                </div>
            </div>
        );
    };

    showTags = () => {
        let { allTags } = this.state;
        let { state } = this;

        return allTags.map((item, index) => {
            return [
                <TableRow key={'product-' + index}>
                    <TableCell sx={{ wordBreak: "break-word" }} align={'left'} className={'products_table_body_cell'}>
                        {item.name}
                    </TableCell>
                    <TableCell />
                    <TableCell align={'right'} style={{ width: "20px" }} className={'products_table_body_cell'}>
                        <Button
                            color="primary"
                            variant="text"
                            onClick={() => this.editTagActionButton(item.id, item.name)}
                            style={Styles.buttonStyle()}
                        >
                            Edit
                        </Button>
                    </TableCell>
                    <TableCell align={'right'} style={{ width: "20px" }} className={'products_table_body_cell'}>
                        <Button
                            color="secondary"
                            variant="text"
                            onClick={() => this.setState({ deletableTagId: item.id, deleteTagConfirmOpen: true })}
                            style={Styles.buttonStyle()}
                        >
                            Delete
                        </Button>
                    </TableCell>
                </TableRow>
            ]
        });
    };

    showCategoriesTable = () => {
        if (this.state.categories === null) {
            return (
                <div>
                    <div className="settings__table">
                        <CircularProgress />
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="settings__table">
                    <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow style={{ height: '20px', paddingTop: '20px', borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                <TableCell
                                    align={'left'}
                                    className={'products_table_head_cell'}
                                    onClick={this.sortProducts.bind(this, 'cat')}
                                >
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'left',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ marginRight: '4px' }}>Category Name</div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{this.showCategories()}</TableBody>
                    </Table>
                    </TableContainer>
                    {this.showLinesModal()}
                </div>
            </div>
        );
    };

    showCategories = () => {
        let { allCategories } = this.state;
        let { state } = this;

        return allCategories.map((item, index) => {
            return [
                <TableRow key={'product-' + index}>
                    <TableCell sx={{ wordBreak: "break-word" }} align={'left'} className={'products_table_body_cell'}>
                        {item.name}
                    </TableCell>
                    <TableCell />
                    <TableCell align={'right'} style={{ width: "20px" }} className={'products_table_body_cell'}>
                        <Button
                            color="primary"
                            variant="text"
                            onClick={() => this.editCategoryActionButton(item.id, item.name)}
                            style={Styles.buttonStyle()}
                        >
                            Edit
                        </Button>
                    </TableCell>
                    <TableCell align={'right'} style={{ width: "20px" }} className={'products_table_body_cell'}>
                        <Button
                            color="secondary"
                            variant="text"
                            onClick={() => this.setState({ deletableCategoryId: item.id, deleteCategoryConfirmOpen: true })}
                            style={Styles.buttonStyle()}
                        >
                            Delete
                        </Button>
                    </TableCell>
                </TableRow>
            ]
        });
    };

    showDeleteCategoryConfirmation = () => {
        return (
            <Dialog
                open={this.state.deleteCategoryConfirmOpen}
                onClose={this.handleCloseDeleteCategoryConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ display: "flex", justifyContent: "center" }}
            >
                <DialogTitle style={{ width: "300px" }} id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <Box style={{ marginTop: "20px" }} />
                <FormControlLabel
                    style={{ margin: "0px 14px" }}
                    control={
                        <Checkbox
                            checked={this.state.deleteCategoryConfirmed}
                            onChange={(event) => this.setState({ deleteCategoryConfirmed: !this.state.deleteCategoryConfirmed })}
                            style={{ width: "20px" }} />
                    }
                    label="Do you want to delete this category?"
                />
                <Box style={{ marginTop: "20px" }} />
                <DialogActions>
                    <Button color="primary"
                        variant="contained" style={{ ...Styles.buttonStyle(), margin: "0px 4px" }} onClick={this.handleCloseDeleteCategoryConfirmation} autoFocus>
                        Cancel
                    </Button>
                    <Button color="secondary"
                        variant="contained" disabled={!this.state.deleteCategoryConfirmed} style={{ ...Styles.buttonStyle(), margin: "0px 4px" }} onClick={() => this.onDeleteCategory(this.state.deletableCategoryId)}>
                        Delete
                    </Button>
                </DialogActions>
                <Box style={{ marginTop: "10px" }} />
            </Dialog>
        )
    }

    showDeleteTagConfirmation = () => {
        return (
            <Dialog
                open={this.state.deleteTagConfirmOpen}
                onClose={this.handleCloseDeleteTagConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ display: "flex", justifyContent: "center" }}
            >
                <DialogTitle style={{ width: "300px" }} id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <Box style={{ marginTop: "20px" }} />
                <FormControlLabel
                    style={{ margin: "0px 14px" }}
                    control={
                        <Checkbox
                            checked={this.state.deleteTagConfirmed}
                            onChange={(event) => this.setState({ deleteTagConfirmed: event.target.checked })}
                            style={{ width: "20px" }} />
                    }
                    label="Do you want to delete this tag?"
                />
                <Box style={{ marginTop: "20px" }} />
                <DialogActions>
                    <Button color="primary"
                        variant="contained" style={{ ...Styles.buttonStyle(), margin: "0px 4px" }} onClick={this.handleCloseDeleteTagConfirmation} autoFocus>
                        Cancel
                    </Button>
                    <Button color="secondary"
                        variant="contained" disabled={!this.state.deleteTagConfirmed} style={{ ...Styles.buttonStyle(), margin: "0px 4px" }} onClick={() => this.onDeleteTag(this.state.deletableTagId)}>
                        Delete
                    </Button>
                </DialogActions>
                <Box style={{ marginTop: "10px" }} />
            </Dialog>
        )
    }

    showProducts = () => {
        let { filtered_products } = this.state;
        let { state } = this;

        let sorted_products = [...filtered_products];
        sorted_products.sort(this.compareProduct);

        return sorted_products.map((item, index) => {
            let tagsFiltered = false
            if ((!item.tag || item.tag.length === 0) && this.state.untagged_products) {
                tagsFiltered = true
            }
            if (!tagsFiltered) {
                this.state.selected_tags.forEach((selected_tag) => {
                    if (item.tag.includes(selected_tag)) {
                        tagsFiltered = true
                    }
                })
            }

            const displayCategory = this.state.allCategories.find((category) => category.id === item.category) != undefined ? this.state.allCategories.find((category) => category.id === item.category).name : ""
            if (this.productShouldBeDisplayed(item)) {

                if (tagsFiltered) {
                    return [
                        <TableRow sx={{ wordBreak: "break-word" }} key={'product-' + index}>
                            <TableCell align={'left'} className={'products_table_body_cell'}>
                                {displayCategory}
                            </TableCell>
                            <TableCell align={'left'} className={'products_table_body_cell'}>
                                {this.renderProductTags(item)}
                            </TableCell>
                            <TableCell align={'left'} className={'products_table_body_cell'}>
                                {item.name}
                            </TableCell>
                            <TableCell sx={{maxWidth: "400px"}} align={'left'} className={'products_table_body_cell'}>
                                {item.desc}
                            </TableCell>
                            <TableCell align={'left'} className={'products_table_body_cell'}>
                                {this.renderProductSpeeds(item.id)}
                            </TableCell>
                            <TableCell align={'left'} className={'products_table_body_cell'}>
                                {state.submitting_edit === false && (
                                    <div>
                                        <Button style={{ wordBreak: "normal" }} variant="text" color="primary" component={Link} to={`/settings/products/${item.id}?lines=${this.state.selected_lines.join('+')}`}>
                                            DETAILS
                                        </Button>
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                    ]
                }
            }
            return null
        });
    };

    actionButton = () => {
        this.setState({
            show_modal: 'add_product'
        });
    };

    categoriesActionButton = () => {
        this.setState({
            show_modal: 'add_category'
        });
    };

    tagsActionButton = () => {
        this.setState({
            show_modal: 'add_tag'
        });
    };

    editCategoryActionButton = (id, name) => {
        this.setState({
            show_modal: 'add_category',
            editedCategoryId: id,
            editedCategoryName: name,
        });
    }

    editTagActionButton = (id, name) => {
        this.setState({
            show_modal: 'add_tag',
            editedTagId: id,
            editedTagName: name,
        });
    }

    modal() {
        if (this.state.show_modal == false) {
            return <div></div>;
        } else {
            return (
                <Dialog open={true} onClose={this.hideModal} class_name="__delete-product">
                    {this.getModalTitle()}
                    {this.getModal()}
                </Dialog>
            );
        }
    }

    hideModal = (type) => {
        this.setState({
            show_modal: false,
            submitting_edit: false,
            editedTagId: null,
            editedTagName: null
        });
        if (type === "categories") {
            this.getCategories()
        }
        else if (type === "tags") {
            this.getTags()
        }
    };

    getModal() {
        switch (this.state.show_modal) {
            case 'add_product':
                return (
                    <AddProductModal
                        hideModal={this.hideModal}
                        openProductDetails={this.openProductDetails}
                        modified={this.modified}
                    />
                );
            case 'delete_product':
                return (
                    <DeleteProductModal
                        hideModal={this.hideModal}
                        modified={this.modified}
                        product={this.state.product}
                    />
                );
            case 'add_category':
                return (
                    <AddCategoryModal
                        hideModal={() => this.hideModal("categories")}
                        id={this.state.editedCategoryId}
                        name={this.state.editedCategoryName}
                    />
                )
            case 'add_tag':
                return (
                    <AddTagModal
                        hideModal={() => this.hideModal("tags")}
                        id={this.state.editedTagId}
                        name={this.state.editedTagName}
                    />
                )
            default:
                return false;
        }
    }

    openProductDetails = item => {
        this.setState({
            productDetails: true,
            productId: item.id
        });
    };

    modified = () => {
        this.getProducts();
    };

    getModalTitle() {
        let sm = this.state.show_modal;

        if (sm === 'add_product') {
            return (
                <DialogTitle disableTypography>
                    <Typography variant="subtitle1">Add New Product</Typography>
                </DialogTitle>
            );
        } else if (sm === 'delete_product') {
            return 'Delete Product?';
        }
        else if (sm === "add_category") {
            return (
                <DialogTitle disableTypography>
                    <Typography variant="subtitle1">Category</Typography>
                </DialogTitle>
            );
        }
        else if (sm === "add_tag") {
            return (
                <DialogTitle disableTypography>
                    <Typography variant="subtitle1">Tag</Typography>
                </DialogTitle>
            );
        } else {
            return '';
        }
    }

    renderProductTags = item => {
        let chips = [];
        if (this.state.allTags &&
            this.state.allTags[0] !== null &&
            item.tag) {
            item.tag.forEach((item) => {
                this.state.allTags.forEach((loopTag) => {
                    if (loopTag.id === item) {
                        chips.push(loopTag.name)
                    }
                })
            })
        }
        if (chips.length > 0) {
            return (
                <div style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap' }}>
                    {chips.map(c => {
                        // Conditional to prevent line being undefined when factories are switched
                        // quickly causing product speed call delays
                        let line = null;
                        if (!Util.isEmpty(this.state.lines)) {
                            line = this.state.lines.find(l => l.id === c.line);
                        }
                        let line_name = line ? line.name : '';
                        return (
                            <Chip
                                key={'chip-' + c}
                                style={{ marginRight: '4px', marginBottom: '4px' }}
                                label={c}
                            />
                        );
                    })}
                </div>
            );
        }
    };

    renderProductSpeeds = product_id => {
        let chips = [];
        if (
            this.state.products_speed &&
            this.state.products_speed[0] !== null &&
            this.state.lines != null &&
            !Util.isEmpty(this.state.lines)
        ) {
            this.state.products_speed.forEach(ps => {
                if (ps.product === product_id && ps.is_active) {
                    chips.push(ps);
                }
            });
        }

        const arrayOfChips = []
        chips.forEach((item) => {
            arrayOfChips.push(item.line)
        })
        const isFiltered = this.state.selected_lines.some(item => arrayOfChips.includes(item))
        if (chips.length > 0 && isFiltered) {
            return (
                <div style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap' }}>
                    {chips.map(c => {
                        // Conditional to prevent line being undefined when factories are switched
                        // quickly causing product speed call delays
                        let line = null;
                        if (!Util.isEmpty(this.state.lines)) {
                            line = this.state.lines.find(l => l.id === c.line);
                        }
                        let line_name = line ? line.name : '';
                        return (
                            <Chip
                                key={'chip-' + c.id}
                                style={{ marginRight: '4px', marginBottom: '4px' }}
                                label={line_name + ' - ' + parseFloat(c.target_speed)}
                            />
                        );
                    })}
                </div>
            );
        }
    };

    status() {
        if (this.state.productsError) {
            return <div className="users__list--status">{this.state.productsError}</div>;
        }
        return <div />;
    }

    showActionButton() {
        if (this.state.productDetails) {
            return;
        }
        if (this.state.user_role === "scoreboard" || this.state.user_role === "factory_read_only" || this.state.user_role === "floor_team") {
            return;
        }
        return (
            <Fab color="secondary" className="settings_fab" onClick={this.actionButton}>
                <AddOutlined />
            </Fab>
        );
    }

    showCategoriesActionButton() {
        if (this.state.productDetails) {
            return;
        }
        if (this.state.user_role === "scoreboard" || this.state.user_role === "factory_read_only" || this.state.user_role === "floor_team") {
            return;
        }
        return (
            <Fab color="secondary" className="settings_fab" onClick={this.categoriesActionButton}>
                <AddOutlined />
            </Fab>
        );
    }

    showTagsActionButton() {
        if (this.state.productDetails) {
            return;
        }
        if (this.state.user_role === "scoreboard" || this.state.user_role === "factory_read_only" || this.state.user_role === "floor_team") {
            return;
        }
        return (
            <Fab color="secondary" className="settings_fab" onClick={this.tagsActionButton}>
                <AddOutlined />
            </Fab>
        );
    }

    handleClose = () => {
        if (this.state.confirm_bulk_edit) {
            this.setState({
                confirm_bulk_edit: false
            });
        } else {
            this.setState({
                show_bulk_edit: false
            });
        }
    };

    render() {
        if (!this.props.type || this.props.type === "products") {
            return (
                <div>
                    {this.modal()}
                    <div className="products">
                        {this.showActionButton()}
                        {this.showProductsTable()}
                        {this.status()}
                    </div>
                </div>
            );
        }
        if (this.props.type === "categories") {
            return (
                <div>
                    {this.modal()}
                    <div>
                        {this.showDeleteCategoryConfirmation()}
                        {this.showCategoriesActionButton()}
                        {this.showCategoriesTable()}
                    </div>
                </div>
            );
        }
        if (this.props.type === "tags") {
            return (
                <div>
                    {this.modal()}
                    <div>
                        {this.showDeleteTagConfirmation()}
                        {this.showTagsActionButton()}
                        {this.showTagsTable()}
                    </div>
                </div>
            );
        }
    }
}

export default Products;
