import React from 'react';

export default function NoResultOutlinedIcon(props) {
    return (
        <svg width="60" height="54" viewBox="0 0 60 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M57.9138 33.2469L45.7701 21.7318H14.2301L2.08632 33.2469L0.413818 31.2898L13.2701 19.0977H46.7301L59.5863 31.2898L57.9138 33.2469Z"
                fill={props.color || '#bbb'}
            />
            <path
                d="M56.25 54H3.75C1.68187 54 0 52.2279 0 50.0488V30.9513H20V32.2684C20 35.5367 22.5231 38.1952 25.625 38.1952H34.375C37.4769 38.1952 40 35.5367 40 32.2684V30.9513H60V50.0488C60 52.2279 58.3181 54 56.25 54ZM2.5 33.5854V50.0488C2.5 50.7752 3.06063 51.3659 3.75 51.3659H56.25C56.9394 51.3659 57.5 50.7752 57.5 50.0488V33.5854H42.4044C41.8013 37.6835 38.43 40.8293 34.375 40.8293H25.625C21.57 40.8293 18.1987 37.6835 17.5956 33.5854H2.5Z"
                fill={props.color || '#bbb'}
            />
            <path
                d="M22.3045 8.51365L17.9294 1.26965L15.8202 2.68392L20.1953 9.92792L22.3045 8.51365Z"
                fill={props.color || '#bbb'}
            />
            <path
                d="M44.8051 2.68146L42.6958 1.26746L38.3216 8.51145L40.4308 9.92546L44.8051 2.68146Z"
                fill={props.color || '#bbb'}
            />
            <path d="M31.25 0H28.75V8.56096H31.25V0Z" fill={props.color || '#bbb'} />
        </svg>
    );
}
