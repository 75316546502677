import React, { Component } from 'react';

class CustomAxisTick extends Component {
    constructor(props) {
        super(props);

        this.state = {
            screenWidth: window.innerWidth
        };
    }


    render () {
        const {x, y, stroke, payload} = this.props;

        if(this.state.screenWidth < 1025) {
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={0} textAnchor="end" fill="#666" fontSize="12px" transform="rotate(-90)">{payload.value}</text>
                </g>
            );
        }
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={0} textAnchor="end" fill="#666" transform="rotate(-90)">{payload.value}</text>
            </g>
        );
    }
};

export default CustomAxisTick;