import React, {Component} from 'react';

class ReferenceLineLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */


    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    render() {
        const {label, viewBox} = this.props;

        return <g transform={`translate(${viewBox.x + (viewBox.width / 2)},${viewBox.y})`}>
            <text x={10} y={0} dy={-4} textAnchor={'middle'} style={{fontFamily: 'Arial'}} fill="#000" fontSize="14px">
                {label}
            </text>
        </g>
    }
}

export default ReferenceLineLabel