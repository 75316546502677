import React, { Component } from 'react';
import update from 'immutability-helper';
import {
    Button,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    TextField,
    Typography,
} from "@material-ui/core";
import { MenuItem } from 'material-ui';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

class DowntimeReasonDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reason: null,
            reasonId: props.reason.id,
            name: '',
            category: '',
            code: '',
            reason_is_active: false,
            reason_is_planned: false,
            factored_in_oee: false,
            category_error: null,
            name_error: null,
            code_error: null,
            status: null,
            submitting_reason: false,
            updated: false,
            lineListStatus: [],
            lineListFactored: []
        }
    }

    componentWillMount() {
        this.getDowntimeReasonDetails()
        this.getCategories()
    }

    componentDidMount() {
        if (Number.isInteger(this.state.category)) {
            this.setState({
                category: this.state.allCategories.find(category => category.id === this.state.category).name
            })
        }
    }

    handleChange(event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    handleActiveReasonCheckboxChange = (event, isInputChecked) => {
        this.setState({
            reason_is_active: isInputChecked
        })
    }

    handleReasonIsPlannedCheckboxChange = (event, isInputChecked) => {
        this.setState({
            reason_is_planned: isInputChecked
        })
    }

    handleFactoredInOEECheckboxChange = (event, isInputChecked) => {
        this.setState({
            factored_in_oee: isInputChecked
        })
    }

    onLineCheck = (index, isInputChecked) => {
        let isChecked = !this.state.lineListStatus[index];
        let lineListStatus = this.state.lineListStatus;
        let updatedLines = update(lineListStatus, { [index]: { $set: isChecked } })
        this.setState({
            lineListStatus: updatedLines
        })
    }

    onLineFactoredCheck = (index, isInputChecked) => {
        let isChecked = !this.state.lineListFactored[index];
        let lineListFactored = this.state.lineListFactored;
        let updatedLines = update(lineListFactored, { [index]: { $set: isChecked } })
        this.setState({
            lineListFactored: updatedLines
        })
    }

    getDowntimeReasonDetails = () => {
        let params = {
            path: "get_downtime_reason_details",
            path_variables: {
                ID: this.state.reasonId
            },
            success: this.onFetchSuccess,
            error: this.onFetchError
        };

        API.c(params);
    }

    getCategories = () => {
        let params = {
            path: 'all_reason_categories',
            success: this.fetchSuccessCategories,
            error: this.fetchError
        };

        API.c(params);
    }

    onFetchSuccess = (data) => {
        let lineListStatus = [];
        let lineListFactored = [];

        data.line_list.map(line => {
            lineListStatus.push(line.is_line_associated_to_reason)
            // lineListFactored.push(line.is_line_factoring_reason)
        })
        this.setState({
            reason: data,
            name: data.reason_name ? data.reason_name : '',
            category: data.reason_category ? data.reason_category : '',
            code: data.reason_code ? data.reason_code : '',
            reason_is_active: data.reason_is_active,
            reason_is_planned: data.reason_is_planned,
            // factored_in_oee: data.factored_in_oee,
            lineListStatus: lineListStatus,
            // lineListFactored: lineListFactored
        })
    }

    onFetchError = (error) => {
        this.setState({
            status: "Error loading downtime reason details"
        })
    }

    fetchSuccessCategories = (data) => {
        let categories = [];
        data.forEach(d => {
            categories.push(d);
        });
        this.setState({
            allCategories: categories,
        });
    }

    submitReason = () => {
        // if(!this.validateInput()){
        //     return;
        // }
        this.setState({
            submitting_reason: true,
            status: null
        });

        let activeList = this.getActiveList();
        let inactiveList = this.getInactiveList();

        // let factoredList = this.getFactoredList();
        // let notFactoredlist = this.getNotFactoredList();

        let data = {
            reason_name: this.state.name,
            reason_category: this.state.category ? this.state.category : null,
            reason_code: this.state.code,
            reason_is_active: this.state.reason_is_active,
            reason_is_planned: this.state.reason_is_planned,
            line_ids_to_add: activeList,
            line_ids_to_delete: inactiveList
        }

        let params = {
            path: "update_downtime_reason",
            path_variables: { ID: this.state.reasonId },
            data: data,
            success: this.submitSuccess,
            error: this.submitError
        }

        API.c(params, 2);
    }

    submitSuccess = (data) => {
        this.props.modified()
        this.setState({
            updated: true,
            submitting_reason: false
        })
    }

    submitError = (error) => {
        this.setState({
            status: error
        })
    }

    getActiveList = () => {
        let activeList = []
        let lineStatus = this.state.lineListStatus
        this.state.reason.line_list.forEach((line, index) => {
            if (!line.is_line_associated_to_reason && (line.is_line_associated_to_reason !== lineStatus[index])) {
                activeList.push(line.line_id)
            }
        })

        return activeList
    }

    getInactiveList = () => {
        let inactiveList = []
        let lineStatus = this.state.lineListStatus
        this.state.reason.line_list.forEach((line, index) => {
            if (line.is_line_associated_to_reason && (line.is_line_associated_to_reason !== lineStatus[index])) {
                inactiveList.push(line.line_id)
            }
        })

        return inactiveList
    }

    getFactoredList = () => {
        let factoredList = []
        let lineFactoredStatus = this.state.lineListFactored
        this.state.reason.line_list.forEach((line, index) => {
            if (!line.is_line_factoring_reason && (line.is_line_factoring_reason !== lineFactoredStatus[index])) {
                factoredList.push(line.line_id)
            }
        })

        return factoredList
    }

    getNotFactoredList = () => {
        let notFactoredList = []
        let lineFactoredStatus = this.state.lineListFactored
        this.state.reason.line_list.forEach((line, index) => {
            if (line.is_line_factoring_reason && (line.is_line_factoring_reason !== lineFactoredStatus[index])) {
                notFactoredList.push(line.line_id)
            }
        })

        return notFactoredList
    }

    iterateCategories = () => {
        if (this.state.allCategories == null || !Array.isArray(this.state.allCategories)) {
            return;
        }
        this.state.allCategories.sort((a, b) => {
            return parseInt(a.name) - parseInt(b.name)
        })
        return (
            this.state.allCategories.map((item, index) => {
                return (
                    <MenuItem value={item.id} key={'category-' + item.id} >
                        {item.name}
                    </MenuItem>
                )
            })
        )
    };


    showEditFields = () => {

        return <div className='settings-reasons_card'>
            <Typography variant='subtitle2'>{this.props.type} Details</Typography>
            <div className='settings-reasons_card--section'>
                <TextField
                    className="settings__reason-details--text-field"
                    id="name"
                    label="Name"
                    value={this.state.name}
                    onChange={this.handleChange.bind(this)}
                    helperText={this.state.name_error !== null ? this.state.name_error : ''}
                    error={this.state.name_error !== null} />
                {/* <TextField
                    className="settings__reason-details--text-field"
                    id="category"
                    label="Category"
                    value={this.state.reason.reason_category_name}
                    onChange={this.handleChange.bind(this)}
                    helperText={this.state.category_error !== null ? this.state.category_error : ''}
                    error={this.state.category_error !== null} /> */}
                <TextField
                    select
                    label="Category"
                    className="settings__reason-details--text-field"
                    value={this.state.category}
                    onChange={(event) => this.setState({ category: event.target.value })}
                >
                    {this.iterateCategories()}
                </TextField>
                <TextField
                    className="settings__reason-details--text-field"
                    id="code"
                    label="Code"
                    value={this.state.code}
                    onChange={this.handleChange.bind(this)}
                    helperText={this.state.code_error !== null ? this.state.code_error : ''}
                    error={this.state.code_error !== null} />
                <div style={{ display: "flex" }} className="settings__reason-details--text-field">
                    <FormControlLabel control={
                        <Checkbox onChange={this.handleActiveReasonCheckboxChange.bind(this)}
                            color="primary"
                            checked={this.state.reason_is_active} 
                        />}
                        style={{ marginTop: "20px" }}
                        label={<div style={{ width: "110px" }}>Active Reason</div>}
                        labelPlacement="end" />
                    <FormControlLabel control={<Checkbox onChange={this.handleReasonIsPlannedCheckboxChange.bind(this)}
                        color="primary"
                        checked={this.state.reason_is_planned} />}
                        style={{ marginTop: "20px", marginLeft: "150px" }}
                        label={<div style={{ width: "140px" }}>Planned Downtime</div>}
                        labelPlacement="end" />
                </div>
            </div>
            {this.showLineListTable()}
            <div className='settings-reasons_card--save-button'>
                {this.updateStatus()}
                {this.showButton()}
            </div>
        </div>
    }

    showLineListTable = () => {
        return <div>
            <Typography className='settings-reasons_card--title' variant='subtitle2'>Associated Lines</Typography>
            <div className="products__recipe-table">
                <TableContainer>
                    <Table>
                        <TableHead className='table-head-elevation'>
                            <TableRow style={{ height: '20px', paddingTop: '20px' }}>
                                <TableCell style={Styles.tableHeaderStyle()}>Active</TableCell>
                                <TableCell style={Styles.tableHeaderStyle()}>Line Name</TableCell>
                                <TableCell style={Styles.editDeleteTableHeaderStyle()} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.showLineList()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    }

    showLineList = () => {
        let lineList = this.state.reason.line_list;

        return lineList.map((line, index) => {
            return <TableRow
                key={"line-" + line.line_id}>
                <TableCell style={Styles.tableRowColumnStyle()}><Checkbox checked={this.state.lineListStatus[index]} color="primary" onChange={this.onLineCheck.bind(this, index)} /></TableCell>
                <TableCell style={Styles.tableRowColumnStyle()}>{line.line_name}</TableCell>
                <TableCell align='right' style={Styles.editDeleteTableRowColumnStyle()} />
            </TableRow>
        })
    }

    showButton = () => {
        if (!this.state.submitting_reason) {
            return <Button variant="contained"
                color="primary"
                onClick={this.submitReason.bind(this)}>
                Update Reason
            </Button>
        } else {
            return <CircularProgress mode="indeterminate" />
        }
    }

    updateStatus = () => {
        if (this.state.status) {
            return <div className="update-status__error">There was an error updating the reason</div>
        } else if (this.state.updated) {
            return <div className="update-status__success">Update Saved</div>
        } else {
            return <div />
        }
    }

    render() {
        if (this.state.reason === null) {
            return <div>
                <Button variant="text" color="primary" style={{ marginLeft: '-16px' }} onClick={this.props.hideReasonDetails}>Back to all reasons</Button>
                <CircularProgress />
            </div>
        }

        return <div className="products__details__container">
            <Button variant="text" color="primary" onClick={this.props.hideReasonDetails}>Back to all reasons</Button>
            {this.showEditFields()}
        </div>
    }
}

export default DowntimeReasonDetails
