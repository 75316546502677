import React, { Component } from 'react';
import { CircularProgress, Button, Fab } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import Modal from '../components/Modal';
import LineDetails from '../components/LineDetails';
import AddLine from '../modals/AddLine';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ReactDragListView from '../../libs/react-drag-listview';

class LinesSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lines: null,
            status: null,
            show_modal: false,
            submitting_edit: false,
            submitting_delete: false,
            lineDetails: false,
            lineId: null,
            lineName: '',
            changed: false,
            submitting_update: false,
            updated: false,
            submit_status: null,
            units: null
        };
    }

    componentWillMount() {
        this.getLines();
        this.getUnits();
    }

    componentDidMount() {
        const headerContainer = document.getElementsByClassName("settings__subhead")[0];
        headerContainer.innerHTML = `<h5 class="MuiTypography-root MuiTypography-h5">Line Settings</h5>`
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.factory !== this.props.factory) {
            this.setState({
                lines: null,
                status: null,
                show_modal: false,
                submitting_edit: false,
                submitting_delete: false,
                lineDetails: false,
                lineId: null,
                lineName: '',
                changed: false,
                submitting_update: false,
                updated: false,
                submit_status: null,
                units: null
            });
            this.getLines();
            this.getUnits();
        }
    }

    getLines = () => {
        let params = {
            path: 'get_lines',
            success: this.fetchLinesSuccess,
            error: this.fetchLinesError
        };

        API.c(params);
    };

    fetchLinesSuccess = data => {
        this.setState({
            lines: data,
            status: null
        });
    };

    fetchLinesError = error => {
        this.setState({
            status: error
        });
    };

    getUnits = () => {
        let params = {
            path: 'get_units',
            success: this.onGetFactoryUnitsSuccess,
            error: this.onGetFactoryUnitsError
        };

        API.c(params, 2);
    };

    onGetFactoryUnitsSuccess = data => {
        this.setState({
            units: data
        });
    };

    onGetFactoryUnitsError = error => {
        this.setState({
            units_error: error
        });
    };

    submitLinesOrder = () => {
        let orderedLines = [];

        this.state.lines.forEach((line, index) => {
            orderedLines.push({ line_id: line.id, line_rank: index + 1 });
        });

        this.setState({
            updated: false,
            submit_status: null,
            submitting_update: true
        });

        let params = {
            path: 'rank_lines',
            data: orderedLines,
            success: this.submitSuccess,
            error: this.submitError
        };

        API.c(params, 2);
    };

    submitSuccess = data => {
        this.setState({
            updated: true,
            submitting_update: false
        });
    };

    submitError = error => {
        this.setState({
            submit_status: error
        });
    };

    showLinesTable = () => {
        if (this.state.lineDetails) {
            return (
                <LineDetails
                    lineId={this.state.lineId}
                    units={this.state.units}
                    hideLineDetails={this.hideLineDetails}
                />
            );
        }

        if (!this.state.lines) {
            return (
                <div className="settings__table">
                    <CircularProgress />
                </div>
            );
        }

        return (
            <div>
                <div className="settings__table">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" style={Styles.tableCellHeaderStyleDragIcon()} />
                                    <TableCell align="left" style={Styles.tableHeaderStyleShort()}>
                                        Status
                                    </TableCell>
                                    <TableCell align="left" style={Styles.tableHeaderStyleShort()}>
                                        Line Name
                                    </TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()} />
                                    <TableCell style={Styles.tableHeaderStyle()} />
                                    <TableCell style={Styles.tableHeaderStyle()} />
                                    <TableCell align="right" style={Styles.editDeleteTableHeaderStyle()} />
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                    {this.showLines()}
                </div>
                {/* <p style={{fontSize: "14px", fontWeight: "500", color: "#999999", marginLeft: "16px"}}>Last Edit: Target speed changed to 28 lbs/min by Mike Yokota on December 25, 2023 at 5:53 PM EST</p> */}
            </div>
        );
    };

    hideLineDetails = modified => {
        this.setState({
            lineDetails: false
        });

        if (modified) {
            this.modified();
        }
    };

    showLines = () => {
        let state = this.state;

        const that = this;
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = state.lines;
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({
                    lines: data,
                    changed: true
                });
            },
            handleSelector: 'i'
        };

        return (
            <ReactDragListView {...this.dragProps}>
                <Table>
                    <TableBody>
                        {this.state.lines.map((line, index) => {
                            return (
                                <TableRow sx={{ wordBreak: "break-word", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} key={'line-' + line.id}>
                                    <TableCell align="left" style={Styles.tableCellStyleDragIcon()}>
                                        <i className="zmdi zmdi-view-headline drag" />
                                    </TableCell>
                                    <TableCell align="left" style={Styles.tableRowColumnStyleShort()}>
                                        {line.is_active ? 'Active' : 'Inactive'}
                                    </TableCell>
                                    <TableCell align="left" style={Styles.tableRowColumnStyleShort()}>
                                        {line.name}
                                    </TableCell>
                                    <TableCell style={Styles.tableRowColumnStyle()} />
                                    <TableCell style={Styles.tableRowColumnStyle()} />
                                    <TableCell style={Styles.tableRowColumnStyle()} />
                                    <TableCell align="right" style={Styles.editDeleteTableRowColumnStyle()}>
                                        <div>
                                            {/* <Button
                                                variant="text"
                                                className={'settings-line-table-button'}
                                                color="secondary"
                                                onClick={this.showDeleteConfirmation.bind(this, line)}
                                            >
                                                REMOVE
                                            </Button> */}
                                            <Button
                                                variant="text"
                                                className={'settings-line-table-button'}
                                                color="primary"
                                                disabled={!this.state.units}
                                                onClick={this.openLineDetails.bind(this, line)}
                                            >
                                                DETAILS
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </ReactDragListView>
        );
    };

    openLineDetails = line => {
        this.setState({
            lineDetails: true,
            lineId: line.id,
            changed: false
        });
    };

    showDeleteConfirmation = line => {
        this.setState({
            show_modal: 'delete_line',
            lineId: line.id,
            lineName: line.name
        });
    };

    showStatus = () => {
        if (this.state.status) {
            return <div>{this.state.status}</div>;
        } else {
            return <div />;
        }
    };

    showActionButton() {
        if (this.state.lineDetails) {
            return;
        }
        return (
            <Fab color="secondary" className="settings_fab" onClick={this.actionButton}>
                <AddOutlined />
            </Fab>
        );
    }

    actionButton = () => {
        this.setState({
            show_modal: 'add_line'
        });
    };

    modal() {
        if (this.state.show_modal == false) {
            return <div></div>;
        } else {
            return (
                <Modal title={this.getModalTitle()} hideModal={this.hideModal} class_name="__add-line">
                    {this.getModal()}
                </Modal>
            );
        }
    }

    hideModal = () => {
        this.setState({
            show_modal: false,
            submitting_edit: false
        });
    };

    getModal() {
        switch (this.state.show_modal) {
            case 'add_line':
                return (
                    <AddLine
                        hideModal={this.hideModal}
                        openLineDetails={this.openLineDetails}
                        units={this.state.units}
                        modified={this.modified}
                    />
                );
            case 'delete_line':
                return (
                    <DeleteConfirmationModal
                        hideModal={this.hideModal}
                        modified={this.modified}
                        item_deleted_text={this.state.lineName}
                        delete_path="delete_line"
                        id={this.state.lineId}
                    />
                );
            default:
                return false;
        }
    }

    modified = () => {
        this.getLines();
    };

    getModalTitle() {
        let sm = this.state.show_modal;

        if (sm == 'add_line') {
            return 'Add a New Line';
        } else if (sm == 'delete_line') {
            return 'Delete Line';
        } else {
            return '';
        }
    }

    showSaveButton = () => {
        if (this.state.changed) {
            return (
                <div style={{ float: 'right', display: 'inline-table', marginTop: '10px' }}>
                    {this.updateStatus()}
                    {this.showButton()}
                </div>
            );
        }

        return <div />;
    };

    showButton = () => {
        if (!this.state.submitting_update) {
            return (
                <Button variant="contained" color="primary" onClick={this.submitLinesOrder.bind(this)}>
                    Update Lines
                </Button>
            );
        } else {
            return <CircularProgress mode="indeterminate" />;
        }
    };

    updateStatus = () => {
        if (this.state.submit_status) {
            return <div className="update-status__error">There was an error updating lines order</div>;
        } else if (this.state.updated) {
            return <div className="update-status__success">Update Saved</div>;
        } else {
            return <div />;
        }
    };

    render() {
        return (
            <div>
                {this.modal()}
                <div className="products" style={{ display: 'flow-root' }}>
                    {this.showActionButton()}
                    {this.showLinesTable()}
                    {this.showSaveButton()}
                    {this.showStatus()}
                </div>
            </div>
        );
    }
}

export default LinesSettings;
