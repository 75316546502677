import React, {Component} from 'react';
import LinePresentation from "../components/LinePresentation";
import {IconButton} from "material-ui";

class PresentationMode extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* ######## LIFECYCLE METHODS ######## */
    componentDidMount () {
        this.setEscape();
    }


    componentWillUnmount () {
        this.cancelEscape();
    }

    /* ######## FUNCTIONS ######## */
    setEscape = () => {
        window.onkeydown = (event) => {
            if (event.shiftKey && event.keyCode === 71 ) {
                this.props.changeViewType(4)
            } else if (event.keyCode === 27 ) {
                this.props.hidePresentationMode();
            }
        };
    }

    cancelEscape = () => {
        window.onkeydown = null;
    }


    hidePresentationMode = () => {
        this.props.hidePresentationMode();
    }

    isLineAvailable = (id, def) => {
        let position = def;
        this.props.lines.forEach((line, index) => {
            if(line.line_id === parseInt(id)) {
                position = index;
            }
        })

        return position
    }

    showSelectGrid = () => {
        this.props.changeViewType(4)
    }

    /* ######## API CALLS ######## */


    /* ######## RENDERS ######## */

    renderLines = () => {
        let line1 = Global.getCookie("line-1")
        let line2 = Global.getCookie("line-2")
        let line3 = Global.getCookie("line-3")
        let line4 = Global.getCookie("line-4")
        let line5 = Global.getCookie("line-5")
        let line6 = Global.getCookie("line-6")
        let line7 = Global.getCookie("line-7")
        let line8 = Global.getCookie("line-8")
        let index1 = this.isLineAvailable(line1, 0);
        let index2 = this.isLineAvailable(line2, 0);
        let index3 = this.isLineAvailable(line3, 0);
        let index4 = this.isLineAvailable(line4, 0);
        let index5 = this.isLineAvailable(line5, 0);
        let index6 = this.isLineAvailable(line6, 0);
        let index7 = this.isLineAvailable(line7, 0);
        let index8 = this.isLineAvailable(line8, 0);

        switch (this.props.grid_view) {
            case 1:
                return <div className="liveview--modal" >
                    <LinePresentation item={this.props.lines[index1]} lines={this.props.lines} position={"1"} grid_view={1} showAllGreen={this.props.showAllGreen}/>
                </div>
            case 2:
                return <div className="flex-box__row liveview--modal">
                    <div className="presentation_col"><LinePresentation item={this.props.lines[index1]} lines={this.props.lines} position={"1"} grid_view={2} showAllGreen={this.props.showAllGreen}/></div>
                    <div className="presentation_col"><LinePresentation item={this.props.lines[index2]} lines={this.props.lines} position={"2"} grid_view={2} showAllGreen={this.props.showAllGreen}/></div>
                </div>
            case 3:
                return <div className="flex-box__row liveview--modal" >
                    <div className="presentation_col--3"><LinePresentation item={this.props.lines[index1]} lines={this.props.lines} position={"1"} grid_view={3} showAllGreen={this.props.showAllGreen}/></div>
                    <div className="presentation_col--3"><LinePresentation item={this.props.lines[index2]} lines={this.props.lines} position={"2"} grid_view={3} showAllGreen={this.props.showAllGreen}/></div>
                    <div className="presentation_col--3"><LinePresentation item={this.props.lines[index3]} lines={this.props.lines} position={"3"} grid_view={3} showAllGreen={this.props.showAllGreen}/></div>
                </div>
            case 4:
                return <div className="flex-box__col liveview--modal" >
                    <div className="flex-box__row--double">
                        <div className="presentation_col"><LinePresentation item={this.props.lines[index1]} lines={this.props.lines} position={"1"} grid_view={4} showAllGreen={this.props.showAllGreen}/></div>
                        <div className="presentation_col"><LinePresentation item={this.props.lines[index2]} lines={this.props.lines} position={"2"} grid_view={4} showAllGreen={this.props.showAllGreen}/></div>
                    </div>
                    <div className="flex-box__row--double">
                        <div className="presentation_col"><LinePresentation item={this.props.lines[index3]} lines={this.props.lines} position={"3"} grid_view={4} showAllGreen={this.props.showAllGreen}/></div>
                        <div className="presentation_col"><LinePresentation item={this.props.lines[index4]} lines={this.props.lines} position={"4"} grid_view={4} showAllGreen={this.props.showAllGreen}/></div>
                    </div>
                </div>
            case 8:
                return <div className="flex-box__col liveview--modal">
                    <div className="flex-box__row--double">
                        <div className="presentation_col"><LinePresentation item={this.props.lines[index1]} lines={this.props.lines} position={"1"} grid_view={8} showAllGreen={this.props.showAllGreen}/></div>
                        <div className="presentation_col"><LinePresentation item={this.props.lines[index2]} lines={this.props.lines} position={"2"} grid_view={8} showAllGreen={this.props.showAllGreen}/></div>
                    </div>
                    <div className="flex-box__row--double">
                        <div className="presentation_col"><LinePresentation item={this.props.lines[index3]} lines={this.props.lines} position={"3"} grid_view={8} showAllGreen={this.props.showAllGreen}/></div>
                        <div className="presentation_col"><LinePresentation item={this.props.lines[index4]} lines={this.props.lines} position={"4"} grid_view={8} showAllGreen={this.props.showAllGreen}/></div>
                    </div>
                    <div className="flex-box__row--double">
                        <div className="presentation_col"><LinePresentation item={this.props.lines[index5]} lines={this.props.lines} position={"5"} grid_view={8} showAllGreen={this.props.showAllGreen}/></div>
                        <div className="presentation_col"><LinePresentation item={this.props.lines[index6]} lines={this.props.lines} position={"6"} grid_view={8} showAllGreen={this.props.showAllGreen}/></div>
                    </div>
                    <div className="flex-box__row--double">
                        <div className="presentation_col"><LinePresentation item={this.props.lines[index7]} lines={this.props.lines} position={"7"} grid_view={8} showAllGreen={this.props.showAllGreen}/></div>
                        <div className="presentation_col"><LinePresentation item={this.props.lines[index8]} lines={this.props.lines} position={"8"} grid_view={8} showAllGreen={this.props.showAllGreen}/></div>
                    </div>
                </div>
        }
    }

    renderGridModalButton = () => {
        return <div style={{position:'absolute', bottom: 0, left: 0, marginLeft: '-12px'}}>
            <IconButton iconClassName="zmdi zmdi-view-module" tooltip="Select Scoreboard Grid (Shift + g)" tooltipPosition="top-right" onClick={this.showSelectGrid}/>
        </div>
    }

    render() {
        return <div>
            {this.renderLines()}
            {this.renderGridModalButton()}
        </div>
    }
}

export default PresentationMode