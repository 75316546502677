import { DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import { Dialog } from 'material-ui';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useEffect, useState } from 'react';
import './SaveEmailedReportStyles.css'

const SaveEmailedReport = (props) => {

    const [isDataTabFilledOut, setIsDataTabFilledOut] = useState(false)
    const [isRecipientsTabFilledOut, setIsRecipientsTabFilledOut] = useState(false)

    const [newReportData, setNewReportData] = useState({})

    const useStyles = makeStyles({
        root: {
          overflowY: 'scroll !important',
          background: 'purple !important'
        },
      });

    const classes = useStyles();

    useEffect(() => {
        let checkData = (element) => element.selected
        let dataOK = false;
        // let recipientsOK = false;
        if (props.lines.some(checkData)) {
            setIsDataTabFilledOut(true)
            dataOK = true;
        }
        
        // checkData = (element) => element.subscribed
        // if (props.factory_users && props.factory_users.some(checkData)) {
        //     setIsRecipientsTabFilledOut(true)
        //     recipientsOK = true;
        // }

        if (dataOK) {

            const lineList = props.lines.filter((line) => line.selected).map((line) => line.id)
            const shiftList = props.shifts.filter((shift) => shift.selected).map((shift) => shift.id)
            const categoryList = props.categories.filter((category) => category.selected).map((category) => category.id)
            const tagList = props.tags.filter((tag) => tag.selected).map((tag) => tag.id)
            const recipientsList = props.factory_users.filter((user) => user.subscribed).map((user) => user.user_id)
            const is_active = props.status

            const generateAt = `${props.compilationTime.getHours()}:${props.compilationTime.getMinutes()}:00`

            let data = {
                line_id_list: lineList,
                shift_id_list: shiftList,
                category_id_list: categoryList,
                tag_id_list: tagList,
                user_auth0_id_list: recipientsList,
                generate_at: generateAt,
                is_active
            }
            if (props.nameChanged) {
                data.name = props.reportName
            }
            if (props.time_period === "Weekly") {
                data.day_of_the_week = props.customCompilationDayDisplay
            }
            else if (props.time_period === "Monthly") {
                data.day_of_the_month = props.customCompilationDay.toString()
            }
            else if (props.time_period === "Custom") {
                data.number_of_days = props.customCompilationDaysToDate.toString()
                data.day_of_the_week = props.customCompilationDayDisplay
            }

            if (props.time_period.toLowerCase() !== props.prev_time_period.toLowerCase() && props.typeOfReport === "edit") {
                data.previous_report_type = props.prev_time_period.toLowerCase()
                data.previous_report_id = props.reportId
                data.new_report_type = props.time_period.toLowerCase()
                data.name = props.reportName

                let params = {
                    path: `change_emailed_report_type`,
                    data,
                    success: (data) => {setNewReportData(data)},
                    error: () => console.log("Error creating")
                };
    
                API.c(params, 2);

            }

            else {
                let params = {
                    path: `${props.typeOfReport === "edit" ? `edit_emailed_report_` : `add_emailed_report_`}${props.time_period.toLowerCase()}`,
                    path_variables: {
                        REPORT_ID: `${props.typeOfReport === "edit" ? props.reportId : ""}`
                    },
                    data,
                    success: (data) => {setNewReportData(data)},
                    error: () => console.log("Error creating")
                };
    
                API.c(params, 2);
            }
    
        }

        
    }, [])

    if (isDataTabFilledOut) {
        let datetime = '';
        if (props.time_period === "Daily") {
            datetime += `${props.time_period}. Start at ${props.compilationTimeDisplay}`
        }
        else if (props.time_period !== "Custom") (
            datetime += `${props.time_period}, ${props.customCompilationDayDisplay}. Start at ${props.compilationTimeDisplay}`
        )
        else {
            datetime += `${props.customCompilationDayDisplay}. Start at ${props.compilationTimeDisplay}`
        }

        return (
                <Dialog
                    open={true}
                    onClose={props.onHide}
                    classes={{
                        root: classes.root,
                        paper: classes.root,
                        container: classes.root,
                        scrollBody: classes.root,
                        scrollPaper: classes.root,
                    }}
                    contentStyle={{
                        width: '422px',
                        maxWidth: '422px',

                    }}
                    style={{ left: "0%", border: "0px", }}
                    scroll="body"
                >
                    <DialogTitle style={{ display: "flex", justifyContent: "center", paddingBottom: "0px", paddingTop: "36px" }} disableTypography>
                        <Typography variant="h3" style={{ color: "#000000", display: "flex", alignItems: "center" }}> Success</Typography>
                    </DialogTitle>
                    <DialogContent style={{ padding: "0px" }}>
                        

                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p style={{ fontSize: "14px", fontWeight: "400" }}>Report Saved Successfully</p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "18px" }}>
                            <CheckCircleIcon style={{ color: "#1975D0", fontSize: "54px" }} />
                        </div>


                        <div style={{ display: "flex", justifyContent: "right", marginTop: "12px"}}>
                            <div onClick={() => { props.onDone(true) }} style={{cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", width: "93px", height: "36px", background: "#1975D0", color: "#F2F2F2", fontWeight: "500", fontSize: "16px", borderRadius: "4px"}}>
                                OK
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
        )
    }
    else if (!isDataTabFilledOut) {
        return (
            <Dialog
                open={true}
                onClose={props.onHide}
                style={{ width: "482px !important", maxWidth: "482px !important", left: "25%" }}
            >
                <DialogTitle style={{ display: "flex", justifyContent: "center" }} disableTypography>
                    <Typography variant="h6">You didn't fill out the "Data" tab</Typography>
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex" , justifyContent: "center", textAlign: "center", fontSize: "20px", fontWeight: "semi-bold", color: "#333333", lineHeight: "28px"}}>
                        Please fill in the "Data" tab to successfully save the report.
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "65px"}}>
                        <div onClick={props.onHide} style={{cursor: "pointer", marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center", width: "148px", height: "51px", background: "transparent", color: "#1975D0", fontWeight: "500", fontSize: "16px", border: "1px solid #1975D0", borderRadius: "8px"}}>
                            Cancel
                        </div>
                        <div onClick={() => { props.onHide(); props.onChangeTab(1) }} style={{cursor: "pointer", marginLeft: "8px", display: "flex", justifyContent: "center", alignItems: "center", width: "148px", height: "51px", background: "#1975D0", color: "#F2F2F2", fontWeight: "500", fontSize: "16px", borderRadius: "8px"}}>
                            To the "Data" tab
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
    // else {
    //     return (
    //         <Dialog
    //             open={true}
    //             onClose={props.onHide}
    //             style={{ width: "482px !important", maxWidth: "482px !important", left: "25%" }}
    //         >
    //             <DialogTitle style={{ display: "flex", justifyContent: "center" }} disableTypography>
    //                 <Typography variant="h6">You didn't fill out the "Recipients" tab</Typography>
    //             </DialogTitle>
    //             <DialogContent>
    //                 <div style={{ display: "flex" , justifyContent: "center", textAlign: "center", fontSize: "20px", fontWeight: "semi-bold", color: "#333333", lineHeight: "28px"}}>
    //                     Please fill in the "Recipients" tab to successfully save the report.
    //                 </div>
    //                 <div style={{ display: "flex", justifyContent: "center", marginTop: "65px"}}>
    //                     <div onClick={props.onHide} style={{cursor: "pointer", marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center", width: "178px", height: "51px", background: "transparent", color: "#1975D0", fontWeight: "500", fontSize: "16px", border: "1px solid #1975D0", borderRadius: "8px"}}>
    //                         Cancel
    //                     </div>
    //                     <div onClick={() => { props.onHide(); props.onChangeTab(2) }} style={{cursor: "pointer", marginLeft: "8px", display: "flex", justifyContent: "center", alignItems: "center", width: "178px", height: "51px", background: "#1975D0", color: "#F2F2F2", fontWeight: "500", fontSize: "16px", borderRadius: "8px"}}>
    //                         To the "Recipients" tab
    //                     </div>
    //                 </div>
    //             </DialogContent>
    //         </Dialog>
    //     )
    // }
}

export default SaveEmailedReport