import { Button, MenuItem, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import clsx from 'clsx';

export default function SelectNotificationTypeModal(props) {
    const classes = useStyles();


    return <Popover open
                    anchorEl={props.anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    className={classes.popover}
                    style={{ overflowY: "hidden" }}
                    onClose={props.hideModal}>
        <div className={classes.container} style={{ overflowY: "hidden" }}>
            <div className={classes.body} style={{ overflowY: "hidden" }}>
                <Typography
                    onClick={() => props.handleTypeChange(0)}
                    className={clsx([classes.item, props.selected === 0 && classes.selected])}>
                    All Types
                </Typography>
                <Typography
                    onClick={() => props.handleTypeChange(1)}
                    className={clsx([classes.item, props.selected === 1 && classes.selected])}>
                    Downtime
                </Typography>
                <Typography
                    onClick={() => props.handleTypeChange(2)}
                    className={clsx([classes.item, props.selected === 2 && classes.selected])}>
                    Run Start
                </Typography>
                <Typography
                    onClick={() => props.handleTypeChange(3)}
                    className={clsx([classes.item, props.selected === 3 && classes.selected])}>
                    Run End
                </Typography>
            </div>
        </div>
    </Popover>
}

//######## STYLE ########
const useStyles = makeStyles(theme => ({
    popover:{
        marginTop: 32
    },
    container: {
        width: 190,
        background: '#fff',
        borderRadius: 8,
    },
    body: {
        margin: '30px 0',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 408,
        width: '100%',
        overflowY: 'scroll'
    },
    item: {
        padding: '10px 40px',
        color: '#666',
        '&:hover': {
            backgroundColor: '#e8e8e8'
        }
    },
    selected: {
        color: '#E31C53'
    }
}));