import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Slide,
    TextField,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty, property, upperCase } from 'lodash';
import SubscriberRow from './SubscriberRow';
import clsx from 'clsx';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NotificationDetail(props) {
    const classes = useStyles();
    const [downtimeReason, setDowntimeReason] = useState(null)
    const [downtimeReasonsList, setDowntimeReasonsList] = useState([])
    const [downtimeDuration, setDowntimeDuration] = useState('')
    const [downtimeDurationError, setDowntimeDurationError] = useState(false)
    const [downtimeDurationType, setDowntimeDurationType] = useState(0)
    const [selectedLines, setSelectedLines] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [productOptions, setProductOptions] = useState([])
    const [notificationTitle, setNotificationTitle] = useState('')
    const [notificationTitleError, setNotificationTitleError] = useState(false)
    const [editNotificationTitle, setEditNotificationTitle] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [subscribeAfterCreate, setSubscribeAfterCreate] = useState(false)

    useEffect(() => {
        if(props.notification) {
            setEditNotificationTitle(false)
            getDowntimeReasonList()
            const notificationLines = isEmpty(props.notification.lines) ? props.lines : props.notification.lines
            setSelectedLines(notificationLines)
            setNotificationTitle(props.notification.name)
            if (props.type === 'downtime') {
                let reasonId = props.reasons.find(r => r.id === props.notification.downtime_reason)?.id || -1
                setDowntimeReason(reasonId)
                setDowntimeDuration((parseInt(props.notification.downtime_duration_secs) / 60).toString())
                setDowntimeDurationType(0)
            } else {
                const selectOptions = props.products.reduce((acc, product) => {
                    acc.push({
                        id: product.id,
                        name: product.name + ' - ' + product.desc,
                        product: product
                    })

                    return acc;
                }, [])

                const selectedProducts = isEmpty(props.notification.products) ?
                    selectOptions :
                    props.notification.products.reduce((acc, product) => {
                    acc.push({
                        id: product.id,
                        name: product.name + ' - ' + product.desc,
                        product: product
                    })

                    return acc;
                }, [])

                setProductOptions(selectOptions)
                setSelectedProducts(selectedProducts)
            }
        }

    }, [props.notification, props.type])

    const getDowntimeReasonList = () => {
        let params = {
            path: 'all_reason_categories',
            success: onGetDowntimeReasonListSuccess,
            error: onGetDowntimeReasonListError
        };

        API.c(params);
    }

    const onGetDowntimeReasonListSuccess = data => {
        setDowntimeReasonsList(data)
    };

    const onGetDowntimeReasonListError = error => {
        // this.setState({
        //     status: error
        // });
    };

    const onLinesSelectAllChange = (event, isInputChecked) => {
        isInputChecked ? setSelectedLines(props.lines) : setSelectedLines([])
    }

    const onLineOptionChange = (index, event, isInputChecked) => {
        const updatedSelected = [...selectedLines];
        if(isInputChecked) {
            updatedSelected.push(props.lines[index])
        } else {
            const unselectIndex = updatedSelected.findIndex(v => v.id === props.lines[index].id)
            if (unselectIndex !== -1) updatedSelected.splice(unselectIndex, 1)
        }
        setSelectedLines(updatedSelected)
    }

    const onProductsSelectAllChange = (event, isInputChecked) => {
        isInputChecked ? setSelectedProducts(productOptions) : setSelectedProducts([])
    }

    const onProductOptionChange = (index, event, isInputChecked) => {
        const updatedSelected = [...selectedProducts];
        if(isInputChecked) {
            updatedSelected.push(productOptions[index])
        } else {
            const unselectIndex = updatedSelected.findIndex(v => v.id === productOptions[index].id)
            if (unselectIndex !== -1) updatedSelected.splice(unselectIndex, 1)
        }
        setSelectedProducts(updatedSelected)
    }

    const onDowntimeReasonChange = (event) => {
        setDowntimeReason(event.target.value ? parseInt(event.target.value) : null)
    }

    const onDowntimeDurationChange = (event) => {
        const reg = downtimeDurationType === 0 ? new RegExp(/^\d+$/) : new RegExp(/^\d*(?:[.]\d*)?$/)
        if (event.target.value === '' || reg.test(event.target.value)) {
            setDowntimeDuration(event.target.value);
        }
    }

    const onDowntimeDurationTypeChange = (event) => {
        setDowntimeDurationType(parseInt(event.target.value))
    }

    const onNotificationTitleChange = (event) => {
        setNotificationTitle(event.target.value);
    }

    const onCancelEditNotificationTitle = () => {
        setNotificationTitle(props.notification.name);
        setEditNotificationTitle(false);
    }

    const validateInput = () => {
        let errorFree = true;

        setNotificationTitleError(false);
        setDowntimeDurationError(false);

        if (isEmpty(notificationTitle)) {
            errorFree = false;
            setNotificationTitleError(true);
        }

        if (props.type === 'downtime' &&
            (Number.isNaN(parseFloat(downtimeDuration)) ||
                (downtimeDurationType === 0 ?
                    parseFloat(downtimeDuration) < 1 :
                    parseFloat(downtimeDuration) < 0)
            )
        ) {
            errorFree = false;
            setDowntimeDurationError(true);
        }

        return errorFree;
    }

    const onSaveClick = () => {
        if (validateInput()) {
            const linesToSubmit = selectedLines.length === props.lines ? [] : selectedLines.map(l => parseInt(l.id));
            let data = {
                name: notificationTitle,
                line_id_list: linesToSubmit
            }

            let path = '';

            if (props.type === 'downtime') {
                let downtimeSeconds = parseFloat(downtimeDuration)
                if (downtimeDurationType === 0) { // minutes
                    downtimeSeconds *= 60
                } else if (downtimeDurationType === 1) { // hours
                    downtimeSeconds *= 3600
                }

                data['product_id_list'] = [];
                data['downtime_duration_secs'] = downtimeSeconds;
                data['downtime_reason_id'] = downtimeReason === -1 ? null : parseInt(downtimeReason);

                path = props.create ? 'create_downtime_notification' : 'edit_downtime_notification'
            } else {
                data['product_id_list'] = selectedProducts === productOptions ? [] : selectedProducts.map(p => parseInt(p.id));

                if (props.type === 'run start') {
                    path = props.create ? 'create_start_run_notification' : 'edit_start_run_notification'
                } else {
                    path = props.create ? 'create_end_run_notification' : 'edit_end_run_notification'
                }
            }

            let params = {
                path,
                data,
                success: onSaveNotificationSuccess,
                error: onSaveNotificationError
            }

            if (!props.create) {
                params['path_variables'] = {ID: props.notification.id}
            }

            setLoading(true);
            API.c(params, 2);
        }
    }

    const onSaveNotificationSuccess = (data) => {
        if (subscribeAfterCreate) {
            props.onSubscribe(data.id, props.type, false, onSubscribeResponse)
        } else {
            setLoading(false);
            props.onSave(props.type)
        }
    }

    const onSaveNotificationError = (error) => {
        setLoading(false);
        setError(true);
    }

    const onSubscribeResponse = (error = false) => {
        setLoading(false)
        if (error) {
            setError(true)
        } else {
            props.onSave(props.type)
        }
    }

    const handleClose = () => {
        setLoading(false);
        setError(false);
        props.handleClose()
    }

    const onSubscribeAfterCreateClick = (event, isInputChecked) => {
        setSubscribeAfterCreate(isInputChecked)
    }

    const renderOption = (label, options, selected, onSelectAllChange, onOptionChange) => {
        return <div className={classes.listBody}>
            <FormControlLabel className={clsx([classes.item, classes.selectAllItem, selected.length === options.length && classes.checked])}
                              control={<Checkbox checked={selected.length === options.length}
                                                 color={"primary"}
                                                 onChange={onSelectAllChange}
                              />}
                              label={label} />
            {
                options.map((option, index) => {
                    const checked = selected.findIndex(v => v.id === option.id) > -1
                    return <FormControlLabel className={clsx([classes.item, checked && classes.checked])}
                                             control={<Checkbox checked={checked}
                                                                color={"primary"}
                                                                onChange={onOptionChange.bind(this, index)}
                                             />}
                                             label={option.name}
                                             key={"option-"+option.id} />
                })
            }
        </div>
    }

    const renderDowntimeReasons = () => {
        return <div className={classes.listBody}>
            <RadioGroup value={downtimeReason} onChange={onDowntimeReasonChange}>
                <FormControlLabel className={clsx([classes.item, classes.selectAllItem, !downtimeReason && classes.checked])}
                                  control={<Radio color={'primary'}/>}
                                  value={-1}
                                  label={'All Reasons'} />
                { props.reasons.map(option => {
                    const category = downtimeReasonsList.find((cat) => cat.id === option.category)
                    return <FormControlLabel className={clsx([classes.item, downtimeReason === option.id && classes.checked])}
                                             control={<Radio color={'primary'}/>}
                                             value={option.id}
                                             label={ (category ? `${category.name} - ${option.name}` : option.name)}
                                             key={"option-"+option.id} />
                })}
            </RadioGroup>
        </div>
    }

    const renderTitleBar = () => {

        if (editNotificationTitle) {
            return <div className={classes.titleBar}>
                <TextField color={'primary'}
                           autoFocus
                           fullWidth
                           inputProps={{className: classes.titleTextField }}
                           className={classes.titleTextField}
                           value={notificationTitle}
                           helperText={notificationTitleError && 'Notification title is required!'}
                           error={notificationTitleError}
                           onChange={onNotificationTitleChange}/>
                <Button variant='text'
                        color='primary'
                        onClick={onCancelEditNotificationTitle}
                        className={classes.titleEditButton}>Cancel</Button>
            </div>
        }


        return <div className={classes.titleBar}>
            <Typography variant='h6'>{notificationTitle}</Typography>
            <Button variant='text'
                    color='primary'
                    onClick={() => setEditNotificationTitle(true)}
                    className={classes.titleEditButton}>Edit</Button>
        </div>
    }

    let disabled = false
    if (props.type === "downtime" || props.type === "run start" || props.type === "run end") {
        if (selectedLines.length === 0) {
            disabled = true
        }
        if (props.type !== "downtime" && selectedProducts.length === 0) {
            disabled = true
        }
        if (!notificationTitle) {
            disabled = true
        }
        if (props.type === "downtime" && downtimeDuration.trim() == 0) {
            disabled = true
        }
    }

    if (!props.open || !props.notification) return <div/>

    return <Dialog fullScreen open={props.open} className={classes.container} onClose={props.handleClose} TransitionComponent={Transition}>
        <div className={classes.topBar}>
            <Typography variant='caption' color='secondary'>{upperCase(props.type)}</Typography>
            <div className={classes.closeButton}>
                <IconButton edge="start" color="inherit" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
        </div>
        {renderTitleBar()}
        <DialogContent className={classes.dialogContent}>
            <div className={classes.leftPanel}>
                <div className={classes.linesSubPanel}>
                    <Typography variant='subtitle2' className={classes.subtitle}>Line</Typography>
                    {renderOption('All Lines', props.lines, selectedLines, onLinesSelectAllChange, onLineOptionChange)}
                </div>
                { props.type !== 'downtime' &&
                    <div className={classes.leftSubPanel}>
                        <Typography variant='subtitle2' className={classes.subtitle}>Product SKU</Typography>
                        {renderOption('All Product SKUs', productOptions, selectedProducts, onProductsSelectAllChange, onProductOptionChange)}
                    </div>
                }
                { props.type === 'downtime' &&
                    <div className={classes.leftMiniSubPanel}>
                        <Typography variant='subtitle2' className={classes.subtitle}>Threshold Duration</Typography>
                        <div className={classes.thresholdContent}>
                            <Typography variant='body1'>Send notification if downtime exceeds</Typography>
                            <TextField variant='filled'
                                       className={classes.durationTextField}
                                       size='small'
                                       hiddenLabel
                                       pattern={/^\d+$/}
                                       onChange={onDowntimeDurationChange}
                                       value={downtimeDuration}
                                       error={downtimeDurationError}
                                       inputProps={{className:classes.durationTextFieldRoot }} />
                            <TextField select
                                       type={'number'}
                                       className={classes.durationTypeTextField}
                                       onChange={onDowntimeDurationTypeChange}
                                       value={downtimeDurationType}>
                                <MenuItem value={0}>minutes</MenuItem>
                                <MenuItem value={1}>hours</MenuItem>
                            </TextField>
                        </div>
                    </div>
                }
                { props.type === 'downtime' &&
                    <div className={classes.leftSubPanel}>
                        <Typography variant='subtitle2' className={classes.subtitle}>Threshold Reason</Typography>
                        {renderDowntimeReasons()}
                    </div>
                }
            </div>
            <div className={classes.rightPanel}>
                <Typography variant='subtitle2' className={classes.subtitle}>Subscribers</Typography>
                {
                    (!props.create && props.notification.users.length === 0) &&
                    <Typography variant='body2' className={classes.description}>No users subscribed</Typography>
                }
                {
                    props.create &&
                    <FormControlLabel className={classes.checkboxControl}
                                      control={<Checkbox checked={subscribeAfterCreate}
                                                         color='primary'
                                                         onChange={onSubscribeAfterCreateClick}/>}
                                      label={
                                          <Typography variant='body2'>
                                              Subscribe to this notification
                                          </Typography>
                                      }/>
                }
                <div className={classes.subscriberList}>
                    {
                        props.notification.users.map((user, index) => {
                            const userInfo = props.orgUsers.find(u => u.user_id === user.auth0_user_id)
                            return userInfo ? <SubscriberRow key={'subscriber-'+index} user={userInfo}/> : <div/>
                        })
                    }
                </div>
            </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
            {loading && <CircularProgress variant={'indeterminate'}/>}
            {error && <Typography variant={'body2'} color={'error'}>Error saving the notification</Typography>}
            <Button variant={'text'}
                    color={'primary'}
                    onClick={handleClose}>Cancel</Button>
            <Button variant={'contained'}
                    color={'primary'}
                    disabled={loading || disabled}
                    onClick={onSaveClick}>Save</Button>
        </DialogActions>
    </Dialog>
}

//######## STYLE ########
const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        background: '#F2F2F2',
        margin: 24
    },
    topBar: {
        padding: 20,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    closeButton: {
        color: '#666'
    },
    titleBar: {
        marginLeft: 20,
        marginRight: 20,
        paddingBottom: 20
    },
    titleTextField: {
        fontSize: 24
    },
    titleEditButton: {
        marginLeft: '-16px'
    },
    dialogContent: {
        display: 'flex',
        padding: 0
    },
    leftPanel: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        borderRight: 'solid 1px #E5E5E5',
        borderTop: 'solid 1px #E5E5E5'
    },
    linesSubPanel: {
        padding: 20
    },
    leftSubPanel: {
        padding: 20,
        borderTop: 'solid 1px #E5E5E5'
    },
    leftMiniSubPanel: {
        padding: 20,
        borderTop: 'solid 1px #E5E5E5'
    },
    rightPanel: {
        flex: 1,
        padding: 20,
        borderTop: 'solid 1px #E5E5E5'
    },
    dialogActions: {
        padding: '20px 25px',
        borderTop: 'solid 1px #E5E5E5'
    },
    subtitle: {
        color: '#666'
    },
    description: {
        marginTop: 20,
        color: '#666'
    },
    subscriberList: {
        paddingTop: 20,
    },
    thresholdContent: {
        display: 'flex',
        alignItems: 'end'
    },
    durationTextField: {
        width: 84,
        marginRight: 20,
        marginLeft: 20
    },
    durationTypeTextField: {
        width: 117
    },
    durationTextFieldRoot: {
        backgroundColor: '#F2F2F2'
    },
    listBody: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 250,
        width: '100%',
        overflowY: 'auto',
    },
    item: {
        width: 'calc(100% - 24px) !important',
        marginRight: '12px !important',
        marginLeft: '12px !important',
        padding: '5px 0',
        color: '#666',
        '&:hover': {
            backgroundColor: '#e8e8e8'
        }
    },
    selectAllItem: {
        marginTop: 30,
        marginBottom: 25
    },
    checked: {
        color: '#000'
    },
    checkboxControl: {
        marginTop: 24
    }
}));