import React, { useState } from 'react';
import { Typography, Button, Box, Divider, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ArrowDropDown } from '@material-ui/icons';
import SelectLineModal from '../../../modals/SelectLineModal';
import SelectProductModal from '../../../modals/SelectProductModal';
import MultiDatesPicker from '../../../modals/MultiDatesPicker';

import ByShiftTop from './by-shift-top';
import FiltersParse from './filters-parse';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SelectCategoryModal from '../../../modals/SelectCategoryModal';
import SelectTagModal from '../../../modals/SelectTagModal';

export default function DailyReportTitleAndOptions(props) {
    const classes = useStyles();

    const [wasChange, setWasChange] = useState(false);
    const wasChanged = () => {
        setWasChange(true);
    };

    if (props.inDetails) {
        return <div />;
    }

    const {
        selected_lines,
        multi_date,
        report_selected_dates,
        selected_products,
        products,
        categories,
        selected_categories,
        tags,
        selected_tags,
        lines,
        printScreen
    } = props;

    const showLines = () => {
        if (props.lines == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (props.selected_lines && props.lines[0])
            if (props.selected_lines.length === props.lines.length) {
                count = 'All';
            } else {
                count = props.selected_lines.length.toString();
            }

        return (
            <div style={{ marginRight: '10px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    className={classes.option_item_dropdown}
                    variant="text"
                    fullWidth
                    disabled={props.lines == null}
                    onClick={props.openSelectLines}
                    endIcon={<ArrowDropDown />}
                >
                    {'Line Filter (' + count + ')'}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };

    const showProducts = () => {
        if (props.products == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (props.selected_products && props.products[0])
            if (props.selected_products.length === props.products.length) {
                count = 'All';
            } else {
                count = props.selected_products.length.toString();
            }

        return (
            <div style={{ marginRight: '10px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    className={classes.option_item_dropdown}
                    variant="text"
                    disabled={props.products == null}
                    onClick={props.openSelectProducts}
                    endIcon={<ArrowDropDown />}
                >
                    {'Product Filter (' + count + ')'}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };
    const showCategories = () => {
        // if (props.categories == null) {
        //     return <div></div>;
        // }

        let count = '0';

        if (props.selected_categories && props.categories && props.categories[0]) {
            if (props.selected_categories.length === props.categories.length && props.uncategorized_products) {
                count = 'All';
            } else {
                let uncategorized_count = props.uncategorized_products ? 1 : 0;
                count = (props.selected_categories.length + uncategorized_count).toString();
            }
        }
        else if (props.uncategorized_products) {
            count = 'All';
        }
        else {
            count = '0'
        }

        return (
            <div style={{ marginRight: '10px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    className={classes.option_item_dropdown}
                    variant="text"
                    disabled={false}
                    onClick={props.openSelectCategories}
                    endIcon={<ArrowDropDown />}
                >
                    {'Category Filter (' + count + ')'}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };
    const showTags = () => {
        if (props.tags == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (props.selected_tags && props.tags && props.tags[0]) {
            if (props.selected_tags.length === props.tags.length && props.untagged_products) {
                count = 'All';
            } else {
                let untagged_count = props.untagged_products ? 1 : 0;
                count = (props.selected_tags.length + untagged_count).toString();
            }
        }
        else if (props.untagged_products) {
            count = 'All';
        }
        else {
            count = '0'
        }

        return (
            <div style={{ marginRight: '10px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    className={classes.option_item_dropdown}
                    variant="text"
                    disabled={props.tags == null}
                    onClick={props.openSelectTags}
                    endIcon={<ArrowDropDown />}
                >
                    {'Tag Filter (' + count + ')'}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };

    const showLinesModal = () => {
        return (
            props.show_select_lines && (
                <SelectLineModal
                    lines={props.lines}
                    selected_lines={props.selected_lines}
                    hideSelectLines={props.hideSelectLines}
                    cancelSelectLines={props.cancelSelectLines}
                />
            )
        );
    };

    const showProductsModal = () => {
        return (
            props.show_select_products && (
                <SelectProductModal
                    products={props.products}
                    selected_products={props.selected_products}
                    hideSelectProducts={props.hideSelectProducts}
                    cancelSelectProducts={props.cancelSelectProducts}
                />
            )
        );
    };

    const showTagsModal = () => {
        return (
            props.show_select_tags && (
                <SelectTagModal
                    style={{ left: "410px" }}
                    tags={props.tags}
                    untagged_products={props.untagged_products}
                    selected_tags={props.selected_tags}
                    hideSelectTags={props.hideSelectTags}
                    cancelSelectTags={props.cancelSelectTags}
                />
            )
        );
    };

    const showCategoriesModal = () => {
        return (
            props.show_select_categories && (
                <SelectCategoryModal
                    categories={props.categories}
                    uncategorized_products={props.uncategorized_products}
                    selected_categories={props.selected_categories}
                    hideSelectCategories={props.hideSelectCategories}
                    cancelSelectCategories={props.cancelSelectCategories}
                />
            )
        );
    };

    const showSelectDates = () => {
        return (
            <div style={{ marginRight: '10px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    className={classes.option_item_dropdown}
                    variant="text"
                    onClick={props.openSelectDates}
                    endIcon={<ArrowDropDown />}
                >
                    {'Dates: ' + props.getDatesLabel()}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };

    const showDatesModal = () => {
        return (
            props.show_select_dates && (
                <MultiDatesPicker
                    setReportMultipleDates={props.setReportMultipleDates}
                    setReportRangeDates={props.setReportRangeDates}
                    type={props.multi_date ? 'days' : 'range'}
                    selectedDays={props.report_selected_dates}
                    from={props.report_date_from}
                    to={props.report_date_to}
                    handleClose={props.handleClose}
                />
            )
        );
    };

    return (
        <div>
            <div className={props.collapse ? classes.card_collapse : classes.card}>
                <div className={classes.title_div}>
                    <Typography style={{ fontWeight: "400" }} variant="h5" className={classes.title}>
                        {/*{props.activeShifts} <br />*/}
                        Daily Report
                    </Typography>
                </div>
                <Box display="flex">
                    <Box>
                        <div className={classes.options_div}>
                            <div className={classes.options_selectors}>
                                <div className={classes.lines_products_div}>
                                    {showSelectDates()}
                                    {showLines()}
                                    {showProducts()}
                                    {showCategories()}
                                    {showTags()}

                                    <Button
                                        style={{
                                            marginTop: '23px',
                                            marginRight: '10px',
                                            height: '40px',
                                            width: '130px'
                                        }}
                                        variant={'contained'}
                                        color={'primary'}
                                        disabled={!wasChange && props.canUpdateOptions()}
                                        onClick={props.getReport.bind(this, 1, true)}
                                    >
                                        Apply Filters
                                    </Button>
                                </div >
                                {/*
                    <Divider orientation={'vertical'} />
                    <div className={classes.date_pickers_div}>
                       //  {showSelectDates()}
                        <div className={classes.options_buttons}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                disabled={props.canUpdateOptions()}
                                onClick={props.getReport.bind(this, 1, true)}
                                className={classes.button}
                            >
                                Apply Filter
                            </Button>
                        </div>
                    </div>
    */}
                            </div >
                            {/*
                <div className={classes.options_buttons}>
                    <Button
                        variant={'text'}
                        color={'primary'}
                        disabled={props.print_status === 'printing' || props.print_status === 'disabled'}
                        onClick={props.printScreen}
                    >
                        {props.print_status === 'printing' ? 'Preparing...' : 'Download PDF'}
                    </Button>
                </div>
                */}
                        </div >

                        {
                            props.usesShifts && (
                                <div>
                                    <ByShiftTop
                                        wasChanged={wasChanged}
                                        lines={selected_lines}
                                        handledCheck={props.handleCheck}
                                        activeShifts={props.activeShifts}
                                        timeZone={props.timeZone}
                                    />
                                </div>
                            )
                        }
                    </Box >

                    <Box flexGrow="1" />

                    <div style={{ width: '120px', marginRight: '40px', display: 'none' }}>
                        <Button
                            startIcon={<ArrowDownwardIcon />}
                            style={{ height: 30, marginTop: 40 }}
                            color="primary"
                            onClick={printScreen}
                            variant="outlined"
                            disabled={props.print_status === 'printing' || props.print_status === 'disabled'}
                        >
                            DOWNLOAD
                        </Button>
                    </div>
                </Box >

                {showLinesModal()}
                {showProductsModal()}
                {showCategoriesModal()}
                {showTagsModal()}
                {showDatesModal()}
            </div >
            <div
                style={{ backgroundColor: '#F2F2F2', boxShadow: 'none' }}
                className={props.collapse ? classes.card_collapse : classes.card}
            >
                <FiltersParse
                    multi_date={multi_date}
                    report_selected_dates={report_selected_dates}
                    selected_products={selected_products}
                    selected_lines={selected_lines}
                    selected_categories={selected_categories}
                    categories={categories}
                    selected_tags={selected_tags}
                    tags={tags}
                    products={products}
                    lines={lines}
                    date={props.getDatesLabel()}
                    date_from={props.date_from}
                    date_to={props.date_to}
                />
            </div>
        </div >
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        card: {
            width: 'calc(100% - 216px)',
            margin: '20px 20px 0px 176px',
            boxSizing: 'border-box',
            background: '#fff',
            boxShadow: '0px 0px 8px rgba(0,0,0,0.3)',
            position: 'relative',
            transition: 'all 195ms ease-in-out',
            display: 'inline-block'
        },
        card_collapse: {
            width: 'calc(100% - 96px)',
            margin: '20px 20px 0px 56px',
            boxSizing: 'border-box',
            background: '#fff',
            boxShadow: '0px 0px 8px rgba(0,0,0,0.3)',
            position: 'relative',
            transition: 'all 195ms ease-in-out',
            display: 'inline-block'
        },
        title_div: {
            background: '#fff',
            padding: '30px'
        },
        title: {
            fontWeight: 'bold'
        },
        options_div: {
            padding: '0px 20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        options_selectors: {
            display: 'flex',
            flexDirection: 'row'
        },
        options_buttons: {
            margin: '30px 0',
            display: 'flex',
            alignItems: 'center'
        },
        lines_products_div: {
            margin: '15px 30px 15px 0',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        date_pickers_div: {
            margin: '15px 30px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        button: {
            marginBottom: '4px'
        },
        option_item: {
            fontSize: '16px !important',
            marginRight: '10px !important',
            marginTop: '12px !important',
            textAlign: 'start !important',
            textTransform: 'capitalize !important'
        },
        option_item_dropdown: {
            justifyContent: 'start',
            fontSize: '16px !important',
            marginRight: '10px !important',
            marginTop: '12px !important',
            textAlign: 'start !important',
            textTransform: 'capitalize !important'
        },
        option_item_date: {
            display: 'flex',
            flexDirection: 'column',
            fontSize: '16px !important',
            marginRight: '10px !important',
            marginTop: '12px !important',
            textAlign: 'start !important',
            textTransform: 'capitalize !important'
        },
        shiftsSelect: {
            marginTop: '-4px',
            justifyContent: 'center',
            width: '204px'
        }
    })
);