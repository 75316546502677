import React, {Component} from 'react';
import {Button, Checkbox, CircularProgress, FormControlLabel} from "@material-ui/core";

class SelectLineModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_lines: props.selected_lines,
            select_button_text: "Select None"
        }
    }

    componentDidMount() {
        this.setKeyListener();
        if(this.props.selected_lines.length === this.props.lines.length) {
            this.setState({
                select_button_text: "Select None"
            })
        } else {
            this.setState({
                select_button_text: "Select All"
            })
        }
    }

    componentWillUnmount() {
        this.cancelKeyListener()
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            event.preventDefault()
            if (event.key === 'Enter') {
                if(this.state.selected_lines[0]) {
                    this.hideSelectLines()
                }
            } else if(event.key === "Escape") {
                this.props.cancelSelectLines()
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }

    handleAllLinesClick = () => {
        let updatedSelectedLines = this.state.selected_lines
        let select_button_text
        if(updatedSelectedLines.length === this.props.lines.length) {
            updatedSelectedLines = []
            select_button_text = "Select All"
        } else {
            updatedSelectedLines.length = 0;
            this.props.lines.forEach(line => {
                updatedSelectedLines.push(line.id)
            })
            select_button_text = "Select None"
        }
        this.setState({
            selected_lines: updatedSelectedLines,
            select_button_text
        })
    }

    onLineCheck = (index, event, isInputChecked) => {
        let updatedSelectedLines = Array.from(this.state.selected_lines);
        if(!isInputChecked) {
            let i = updatedSelectedLines.indexOf(this.props.lines[index].id);
            if (i !== -1) updatedSelectedLines.splice(i, 1);
        } else {
            updatedSelectedLines.push(this.props.lines[index].id)
        }
        let select_button_text;
        if(updatedSelectedLines.length === this.props.lines) {
            select_button_text = "Select None"
        } else {
            select_button_text = "Select All"
        }
        this.setState({
            selected_lines: updatedSelectedLines,
            select_button_text
        })
    }

    hideSelectLines = () => {
        if(this.state.selected_lines[0]) {
            this.props.hideSelectLines(this.state.selected_lines)
        }
    }

    renderProducts = () => {
        if(!this.props.lines[0]) {
            return <div className="reports__loading">
                <CircularProgress />
            </div>
        }

        return this.props.lines.map((line, index) => {
            return <FormControlLabel className="select-lines-body-item"
                                     control={<Checkbox checked={this.state.selected_lines.indexOf(line.id) > -1}
                                                        color={"primary"}
                                                        onChange={this.onLineCheck.bind(this, index)}
                                                        />}
                                     label={line.name}
                                     key={"line-"+line.id} />
        })
    }


    render() {
        return <div>
            <div
                className="select-lines"
                onClick={this.hideSelectLines}
            />
            <div className="select-lines-container" style={this.props.style}>
                <div className="select-lines-header">
                    <div style={{flex: 1, marginLeft: "24px"}}>
                        <div className="select-lines-header-button modal__content-title__text">Line Filter</div>
                    </div>
                    <Button variant="text"
                            color="primary"
                            className="select-lines-header-button"
                            onClick={this.handleAllLinesClick}>{this.state.select_button_text}</Button>
                    <Button variant="contained"
                            color="primary"
                            className="select-lines-header-button"
                            onClick={this.hideSelectLines}
                            disabled={!this.state.selected_lines[0]}>Filter</Button>
                </div>
                <div className="select-lines-body">
                    {this.renderProducts()}
                </div>

            </div>
        </div>
    }
}

export default SelectLineModal