import React, { useContext, useEffect, useState } from 'react';
import { Table, TableBody, TableHeader, TableRow, TableRowColumn } from 'material-ui';
import { Button, Checkbox, TextField, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { ShiftContext } from '../../../context';
import _ from 'lodash';
import { fontSize, fontWeight } from '@material-ui/system';

const ShiftDetail = ({ shiftData, timeZone, shiftsData, getShifts }) => {
    const { data } = shiftData;
    const getDate = string => new Date(0, 0, 0, string.split(':')[0], string.split(':')[1], string.split(':')[2]);
    const classes = useStyles();
    const { active, setActive } = useContext(ShiftContext);
    const currentDate = moment().format('YYYY-MM-DD');
    const formatDate = 'YYYY-MM-DDTHH:mm:ssZZ';
    const [lines, setLines] = useState([]);
    const newDate = new Date();
    const yDate = newDate.getFullYear();
    const mDate = newDate.getMonth();
    const dDate = newDate.getDate();
    const hDate = newDate.getHours();
    const minDate = newDate.getMinutes();
    const [lineData, setLineData] = useState([]);
    const [disableButton, setDisableButton] = useState(!data);
    const [shiftName, setShiftName] = useState(data ? data.name : '');
    const [shiftStartTimeError, setShiftStartTimeError] = useState(null);
    const [shiftEndTimeError, setShiftEndTimeError] = useState(null);
    const [linesError, setLinesError] = useState(null);
    const [shiftNameError, setShiftNameError] = useState(null);
    const [shiftError, setShiftError] = useState(null);
    const [startTime, setStartTime] = useState(
        data ? moment(`${currentDate} ${data.start_time}`).format(formatDate) : moment().format()
    );
    const [endTime, setEndTime] = useState(
        data ? moment(`${currentDate} ${data.end_time}`).format(formatDate) : moment().add(1, 'minutes').format()
    );

    const [timeFrom, setTimeFrom] = useState(
        data ? moment(`${currentDate} ${data.start_time}`).format('HH:mm') : moment(new Date()).format('HH:mm')
    );
    const [timeTo, setTimeTo] = useState(
        data
            ? moment(`${currentDate} ${data.end_time}`).format('HH:mm')
            : moment(new Date(yDate, mDate, dDate, hDate, minDate + 1)).format('HH:mm')
    );
    const [openName, setOpenName] = useState(false);
    const [openLines, setOpenLines] = useState(false);
    const [openStartTime, setOpenStartTime] = useState(false);
    const [openEndTime, setOpenEndTime] = useState(false);
    const errorShift = shiftsData.find(({ id }) => id === +shiftEndTimeError);
    const [errorShiftStartTime, setErrorShiftStartTime] = useState(null);
    const [errorShiftEndTime, setErrorShiftEndTime] = useState(null);

    useEffect(() => {
        let params = {
            path: 'get_lines',
            success: function (data) {
                setLines(data);
            },
            error: function (err) {
                setLinesError(err.detail);
            }
        };

        API.c(params);
    }, []);

    useEffect(() => {
        fetchLines();
    }, [lines.length]);

    useEffect(() => {
        if (errorShift) {
            if (getDate(timeFrom) >= getDate(errorShift.start_time)) {
                setErrorShiftStartTime(`Shift with this time already exists: ${errorShift.name}`);
                setOpenStartTime(true);
            }
            if (getDate(timeTo) <= getDate(errorShift.end_time)) {
                setErrorShiftEndTime(`Shift with this time already exists: ${errorShift.name}`);
                setOpenEndTime(true);
            }
            if (errorShift.lines.filter(errorLine => lineData.includes(line => errorLine === line.line_id))) {
                setOpenEndTime(true);
                setErrorShiftEndTime(`Shift with this time already exists: ${errorShift.name}`);
                setOpenStartTime(true);
                setErrorShiftStartTime(`Shift with this time already exists: ${errorShift.name}`);
            }
        }
    }, [errorShift]);
    const BottomButtons = () => {
        return (
            <div className={classes.lineDetailsColumnButton} style={{ display: 'flex' }}>
                {data ? (
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{
                            marginLeft: 'auto',
                            marginRight: '3rem'
                        }}
                        disabled={false}
                        onClick={() => handleSubmitDelete()}
                    >
                        Delete
                    </Button>
                ) : null}
                <Button
                    disabled={disableButton}
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                    style={{
                        flex: 'initial',
                        marginLeft: data ? 0 : 'auto'
                    }}
                >
                    Save
                </Button>
            </div>
        );
    };

    const handleStartTime = date => {
        const d = moment(date).format('HH:mm');
        setStartTime(date);
        setTimeFrom(d);
        setDisableButton(false);
    };

    const handleEndTime = date => {
        const d = moment(date).format('HH:mm');
        setEndTime(date);
        setTimeTo(d);
        setDisableButton(false);
    };

    const submitSuccess = data => {
        setActive({
            status: false,
            data: null
        });
        getShifts();
    };

    const scrollToTop = () => {
        document.querySelector('.MuiFormControl-root').scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start'
        });
    };

    const handleSubmit = () => {
        const filterLine = _.filter(lineData, ['selected', true]);
        let arrayLine = [];

        filterLine.map(line => {
            arrayLine.push(line.line_id);
        });

        const formData = {
            end_time: moment(`${currentDate} ${timeTo}`).format('HH:mm:ss'),
            lines: arrayLine,
            name: shiftName,
            start_time: moment(`${currentDate} ${timeFrom}`).format('HH:mm:ss'),
            time_zone: data ? data.time_zone : timeZone
        };

        let params = {};

        if (data) {
            params = {
                path: 'update_shift',
                path_variables: { ID: data.id },
                data: formData,
                success: submitSuccess,
                error: function (err) {
                    scrollToTop();
                    if (err.detail) {
                        setOpenEndTime(true);
                        setShiftEndTimeError(err.detail);
                    }
                    if (err.lines) {
                        setOpenLines(true);
                        setLinesError(err.lines);
                    }
                    if (err.non_field_errors) {
                        setShiftEndTimeError(err.non_field_errors[0]);
                    }
                    if (err.start_time) {
                        setOpenStartTime(true);
                        setShiftStartTimeError(err.start_time);
                    }
                    if (err.end_time) {
                        setOpenEndTime(true);
                        setShiftEndTimeError(err.end_time);
                    }
                }
            };
        } else {
            params = {
                path: 'add_shift',
                data: formData,
                success: submitSuccess,
                error: function (err) {
                    scrollToTop();
                    if (err.detail) {
                        setOpenEndTime(true);
                        setShiftEndTimeError(err.detail);
                    }
                    if (err.non_field_errors) {
                        setShiftEndTimeError(err.non_field_errors[0]);
                    }
                    if (err.lines) {
                        setOpenLines(true);
                        setLinesError(err.lines);
                    }
                    if (err.start_time) {
                        setOpenStartTime(true);
                        setShiftStartTimeError(err.start_time);
                    }
                    if (err.end_time) {
                        setOpenEndTime(true);
                        setShiftEndTimeError(err.end_time);
                    }
                }
            };
        }
        if (shiftName === '') {
            setOpenName(true);
            setDisableButton(false);
            setShiftNameError('Required Shift Name');
            scrollToTop();
        } else {
            setOpenName(false);
            setDisableButton(true);
            setShiftNameError('');
            API.c(params, 2);
        }
    };

    const handleSubmitDelete = () => {
        let params = {
            path: 'delete_shift',
            path_variables: { ID: data.id },
            success: function () {
                setActive({
                    status: false,
                    data: null
                });
                getShifts();
            },
            error: function (err) {
                setShiftError(err.detail);
            }
        };

        API.c(params);
    };

    const fetchLines = () => {
        let arr = [];
        for (let i = 0; i < lines.length; i++) {
            let select = false;
            if (data) {
                if (data.lines.includes(lines[i].id)) {
                    select = true;
                }
            }
            arr.push({
                line_id: lines[i].id,
                line_name: lines[i].name,
                selected: select
            });
        }

        setLineData(arr);
    };

    const handleCheckedLine = id => {
        const data = lineData.map(line => {
            if (line.line_id === id) {
                return {
                    line_id: line.line_id,
                    line_name: line.line_name,
                    selected: !line.selected || false
                };
            }
            return {
                line_id: line.line_id,
                line_name: line.line_name,
                selected: line.selected || false
            };
        });
        setDisableButton(false);
        setLineData(data);
    };

    const showLineList = () => {
        if (!_.isEmpty(lineData)) {
            return lineData.map((line, index) => (
                <TableRow style={{ borderBottom: "1px solid rgb(224, 224, 224)" }} key={'line-' + index}>
                    <TableRowColumn style={{ ...Styles.tableRowColumnStyle(), padding: "15px 12px" }}>
                        <Checkbox color='primary' checked={line.selected} onChange={() => handleCheckedLine(line.line_id)} />
                    </TableRowColumn>
                    <TableRowColumn style={{ ...Styles.tableRowColumnStyle(), color: "rgba(0, 0, 0, 0.87)" }}>{line.line_name}</TableRowColumn>
                    <TableRowColumn style={Styles.editDeleteTableRowColumnStyle()} />
                </TableRow>
            ));
        }
    };

    const helperTextStyles = makeStyles(theme => ({
        error: {
            '&.MuiFormHelperText-root.Mui-error': {
                position: 'absolute',
                bottom: '-40px'
            }
        }
    }));

    const helperTextClasses = helperTextStyles();

    return (
        <div>
            <div className={classes.lineDetailsWrapper}>
                <div className={classes.lineDetailsColumn}>
                    <Typography variant="subtitle2">Shift details</Typography>
                    {shiftError ? <p>{shiftError}</p> : null}
                    <div className={classes.timeContainer}>
                        {/*<MuiPickersUtilsProvider utils={moment.utc()}>*/}
                        <KeyboardTimePicker
                            margin="normal"
                            id="start-picker"
                            label="Start time"
                            ampm={true}
                            value={startTime}
                            error={openStartTime}
                            helperText={errorShiftStartTime}
                            onChange={handleStartTime}
                            minDateMessage={setShiftEndTimeError}
                            format="h:mm A"
                            variant="outlined"
                            KeyboardButtonProps={{
                                'aria-label': 'change time'
                            }}
                            FormHelperTextProps={{ classes: helperTextClasses }}
                        />

                        <KeyboardTimePicker
                            margin="normal"
                            id="end-picker"
                            // minTime={startTime}
                            minDate={startTime}
                            error={openEndTime}
                            helperText={errorShiftEndTime}
                            format="h:mm A"
                            ampm={true}
                            label="End time"
                            value={endTime}
                            onChange={handleEndTime}
                            variant="outlined"
                            KeyboardButtonProps={{
                                'aria-label': 'change time'
                            }}
                            FormHelperTextProps={{ classes: helperTextClasses }}
                        />

                        {/*</MuiPickersUtilsProvider>*/}

                        <TextField
                            id="Shift_name"
                            label="Shift Name"
                            value={shiftName}
                            onChange={event => {
                                setShiftName(event.target.value);
                                setDisableButton(false);
                            }}
                            error={openName}
                            helperText={shiftNameError}
                            FormHelperTextProps={{ classes: helperTextClasses }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            style={{
                                marginTop: '7px'
                            }}
                        />
                    </div>
                </div>

                <div>
                    <Typography variant="subtitle2">Associated Lines</Typography>
                    {openLines && <div style={{ color: '#E00000', fontSize: '0.75rem' }}>{linesError}</div>}
                    <div className={classes.shiftTable}>
                        <Table>
                            <TableHeader displaySelectAll={false} adjustForCheckbox={false} enableSelectAll={false}>
                                <TableRow style={{ height: '20px', paddingTop: '20px' }}>
                                    <TableRowColumn style={{ fontSize: "14px", fontWeight: "500", color: "rgba(0, 0, 0, 1)", paddingBottom: "25px" }}>Active</TableRowColumn>
                                    <TableRowColumn style={{ fontSize: "14px", fontWeight: "500", color: "rgba(0, 0, 0, 1)", paddingBottom: "25px" }}>Line Name</TableRowColumn>
                                    <TableRowColumn style={Styles.editDeleteTableHeaderStyle()} />
                                </TableRow>
                            </TableHeader>
                            <TableBody displayRowCheckbox={false}>{showLineList()}</TableBody>
                        </Table>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: '10px' }}>{BottomButtons()}</div>
        </div>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        lineDetailsWrapper: {
            display: 'flex',
            flexDirection: 'column',
            padding: '34px 43px',
            boxShadow: '1px 1px 20px rgb(0 0 0 / 10%)',
            margin: '24px'
        },
        lineDetailsColumn: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '98px'
        },
        lineDetailsUnitsColumn: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '12px'
        },
        lineDetailsColumnButton: {
            flex: 1,
            paddingRight: '24px'
        },
        timeContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '740px'
        },
        timeBlock: {
            marginTop: '16px'
        },
        shiftTable: {
            marginTop: '42px'
        }
    })
);

export default ShiftDetail;
