import React, { Component } from 'react';
import {
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Button,
} from '@material-ui/core';
import TablePagination from '@mui/material/TablePagination';
import { ArrowDropDown } from '@material-ui/icons';
import MultiDatesPicker from '../modals/MultiDatesPicker';
import {auth0UserManagementAPIInstance} from "../components/Auth0API"
import SelectUsernameModal from '../modals/SelectUsernameModal';
import SelectActionModal from '../modals/SelectActionModal';
import SelectPageModal from '../modals/SelectPageModal';
import ReactPaginate from 'react-paginate';
import { Chip } from '@material-ui/core';


class Changelog extends Component {
    constructor(props) {
        super(props);
        this.webAuth = Auth0;
        this.state = {
            logs: [],
            dateType: "days",
            actions: ["Added", "Modified", "Deleted"],
            pages: ["Auto End", "Auto Start", "Downtime Details", "Line Attributes", "Output Details", "Product Attributes", "Product Details", "Rework Details", "Run Details", "Waste Details"],
            selected_users: [],
            selected_actions: ["Added", "Modified", "Deleted"],
            selected_pages: ["Auto End", "Auto Start", "Downtime Details", "Line Attributes", "Output Details", "Product Attributes", "Product Details", "Rework Details", "Run Details", "Waste Details"],
            dates: [new Date],
            factory_users: [],
            currentPage: 0,
            pageCount: 0,
            offset: 0,
            daily_tab_index: 0,
        }
    }

    /* ######## LIFECYCLE METHODS ######## */
    componentDidMount() {
        document.title = "LiveTracking | Changelog";
        let org = this.defaultOrg(this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"]);
        const headerContainer = document.getElementsByClassName("settings__subhead")[0];
        headerContainer.innerHTML = `<h5 class="MuiTypography-root MuiTypography-h5">Changelog</h5>`
        this.setState({
            organizations: this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"],
            organization: org,
            factory_id: this.defaultFactoryId(),
            factory: JSON.parse(window.localStorage.getItem("factory")),
        })
        this.getOrgUsers(org);
    }

    compareDateTime = (a, b) => {
        const dateA = new Date(a.datetime).getTime()
        const dateB = new Date(b.datetime).getTime()

        if (dateA > dateB) {
            return -1
        }
        if (dateA < dateB) {
            return 1
        }
        return 0
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // let org = this.defaultOrg(this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"]);
        // const headerContainer = document.getElementsByClassName("settings__subhead")[0];
        // headerContainer.innerHTML = `<h5 class="MuiTypography-root bold MuiTypography-h5">Changelog</h5>`
        // this.setState({
        //     organizations: this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"],
        //     organization: org,
        //     factory_id: this.defaultFactoryId(),
        //     factory: JSON.parse(window.localStorage.getItem("factory")),
        // })
        // this.getOrgUsers(org);

        if (prevProps.factory !== this.props.factory) {
            this.setState({
                logs: [],
                dateType: "days",
                actions: ["Added", "Modified", "Deleted"],
                pages: ["Auto End", "Auto Start", "Downtime Details", "Line Attributes", "Output Details", "Product Attributes", "Product Details", "Rework Details", "Run Details", "Waste Details"],
                selected_users: [],
                selected_actions: ["Added", "Modified", "Deleted"],
                selected_pages: ["Auto End", "Auto Start", "Downtime Details", "Line Attributes", "Output Details", "Product Attributes", "Product Details", "Rework Details", "Run Details", "Waste Details"],
                dates: [new Date],
                factory_users: [],
                currentPage: 0,
                pageCount: 0,
                offset: 0,
                daily_tab_index: 0,
            })
            let org = this.defaultOrg(this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"]);
            const headerContainer = document.getElementsByClassName("settings__subhead")[0];
            headerContainer.innerHTML = `<h5 class="MuiTypography-root bold MuiTypography-h5">Changelog</h5>`
            this.setState({
                organizations: this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"],
                organization: org,
                factory_id: this.defaultFactoryId(),
                factory: JSON.parse(window.localStorage.getItem("factory")),
            })
            this.getOrgUsers(org);
        }
    }

    onGetLogsSuccess = (resp) => {
        this.setState({ logs: [...this.state.logs, ...resp].sort(this.compareDateTime) })
    }

    getLogs = () => {
        this.setState({logs: []})
        const data = {
            action: this.state.selected_actions
        }
        if (this.state.selected_users.length !== this.state.factory_users.length) {
            data.user = this.state.selected_users
        }
        if (this.state.dateType === "days") {
            data.dates = this.state.dates
        }
        else {
            data.start_date = this.state.date_from
            data.end_date = this.state.date_to
        }
        let params = {
            path: 'get_productsettings_log',
            data,
            success: this.onGetLogsSuccess
        };
        if (this.state.selected_pages.includes("Product Details")) {
            API.c(params, 2);
        }
        params = {
            path: 'get_rundetails_log',
            data,
            success: this.onGetLogsSuccess
        };
        if (this.state.selected_pages.includes("Run Details")) {
            API.c(params, 2);
        }
        params = {
            path: 'get_outputdetails_log',
            data,
            success: this.onGetLogsSuccess
        };
        if (this.state.selected_pages.includes("Output Details")) {
            API.c(params, 2);
        }
        params = {
            path: 'get_reworkdetails_log',
            data,
            success: this.onGetLogsSuccess
        };
        if (this.state.selected_pages.includes("Rework Details")) {
            API.c(params, 2);
        }
        params = {
            path: 'get_wastedetails_log',
            data,
            success: this.onGetLogsSuccess
        };
        if (this.state.selected_pages.includes("Waste Details")) {
            API.c(params, 2);
        }
        params = {
            path: 'get_downtimedetails_log',
            data,
            success: this.onGetLogsSuccess
        };
        if (this.state.selected_pages.includes("Downtime Details")) {
            API.c(params, 2);
        }
        params = {
            path: 'get_autostart_log',
            data,
            success: this.onGetLogsSuccess
        };
        if (this.state.selected_pages.includes("Auto Start")) {
            API.c(params, 2);
        }
        params = {
            path: 'get_autoend_log',
            data,
            success: this.onGetLogsSuccess
        };
        if (this.state.selected_pages.includes("Auto End")) {
            API.c(params, 2);
        }
        params = {
            path: 'get_lineattributes_log',
            data,
            success: this.onGetLogsSuccess
        };
        if (this.state.selected_pages.includes("Line Attributes")) {
            API.c(params, 2);
        }
        params = {
            path: 'get_productattributes_log',
            data,
            success: this.onGetLogsSuccess
        };
        if (this.state.selected_pages.includes("Product Attributes")) {
            API.c(params, 2);
        }
    };

    handlePageClick = (event, page) => {
        let selected = page
        let offset = Math.ceil(selected * 20);
        this.setState({ offset: offset, currentPage: selected });
    };

    getUserEmail = (id, is_system_generated) => {
        let user = "System User";
        if (this.state.factory_users.length > 0) {
            let userFound = this.state.factory_users.find((i) => i.user_id === id)
            if (userFound) {
                let userFirstName = userFound.user_metadata.first_name
                let userLastName = userFound.user_metadata.last_name
                let hasNames = userFirstName || userLastName
                user = hasNames ? `${userFirstName} ${userLastName}` : userFound.email
            }
            else {
                user = is_system_generated ? "System User" : "Not found"
            }
        }
        return user
    }

    convertDateLong = date => {
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
          ];

        const weekNames = [
            "Sun", "Mon", "Tue", "Wed", "Thu",
            "Fri", "Sat"
        ]

        const newDate = new Date(date)
        const dayOfTheWeek = newDate.getDay()
        const day = newDate.getDate()
        const month = newDate.getMonth()
        const year = newDate.getFullYear()

        const displayDate = `${weekNames[dayOfTheWeek]}, ${monthNames[month]} ${day}, ${year}`

        return displayDate
    }

    convertDateShort = date => {
        const newDate = new Date(date)
        const dayOfTheWeek = newDate.getDay()
        const day = newDate.getDate()
        const month = newDate.getMonth() + 1
        const year = newDate.getFullYear()
        const time = newDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });

        const displayDate = `${month >= 10 ? month : `0${month}`}.${day >= 10 ? day : `0${day}`}.${year} ${time}`

        return displayDate
    }

    convertTime = date => {
        const newDate = new Date(date);
        const time = newDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
        return time;
    }

    renderContextualKeys = (page, contKeys, index) => {
        const keys = []
        const valuesMapped = []
        for (const prop in contKeys) {
            keys.push(String(prop))
            valuesMapped.push(contKeys[prop])
        }
        return (
            <>
                <p style={{ fontWeight: "bold", marginBottom: "8px" }}>{page}</p>
                {keys.map((c, i) => {
                    let value = valuesMapped[i]
                    return (
                        <p style={{ marginBottom: '8px', }}>
                            <span
                                key={`${page}-${index}-${i}-key`}
                                style={{ marginRight: '0px', marginBottom: '4px', fontWeight: "bold" }}
                            >
                                {`${keys[i]}: `}
                            </span>
                            <span
                                key={`${page}-${index}-${i}-value`}
                                style={{ marginRight: '4px', marginBottom: '4px', wordBreak: "break-word" }}
                            >
                                {`${String(value).length > 0 ? value : "N/A"}`}
                            </span>
                        </p>
                    );
                })}
            </>
        )
    }

    renderPrevNewValues = (values, index, type, compareValues) => {
        const keys = []
        const valuesMapped = []
        for (const prop in values) {
            keys.push(String(prop))
            valuesMapped.push(values[prop])
        }
        return (
            <div>
                {keys.map((c, i) => {
                    let value = valuesMapped[i]
                    if (c === "Time") {
                        const newDate = new Date(value);
                        const time = newDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
                        value = `${this.convertDateLong(value)}, ${time}`
                    }
                    if (c === "Status") {
                        value = value === true ? "Active" : "Inactive"
                    }
                    if (!value || value.length === 0) {
                        value = "N/A"
                    }
                    if (typeof value === "string" && typeof compareValues[c] === "string") {
                        let lengthDifference
                        lengthDifference = compareValues[c].length - value.length
                        if (lengthDifference > 0) {
                            value += '\u0020'.repeat(lengthDifference)
                        }
                    }
                    return (
                        <p style={{ marginBottom: "8px" }}>
                            <span
                                key={`${type}-${index}-${i}-key`}
                                style={{ marginRight: '0px', marginBottom: '4px', fontWeight: "bold" }}
                            >
                                {`${keys[i]}: `}
                            </span>
                            <span
                                key={`${type}-${index}-${i}-value`}
                                style={{ marginRight: '4px', marginBottom: '4px', whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                            >
                                {`${value}`}
                            </span>
                        </p>
                    );
                })}
            </div>
        );
    };

    getOrgUsers = (org) => {
        auth0UserManagementAPIInstance.get(`auth0/user/search?organization_id=${org}&factory_id=${this.defaultFactoryId()}`)
            .then(res => {
                let org_users = this.filterOrgUsers(res.data, org)
                let factory_users = null
                if(this.state.factory_id) {
                    factory_users = this.filterFactoryUsers(org_users, this.state.factory_id)
                }
                const copy_factory_users = [...factory_users]
                let selected = copy_factory_users.sort((a, b) => a.email.split("@")[1].localeCompare(b.email.split("@")[1]))
                selected.forEach((item, i) => {
                    selected[i] = item.user_id
                })
                const compareUsers = (a, b) => {
                    const aHasName = a.user_metadata.first_name && a.user_metadata.first_name.length > 0;
                    const bHasName = b.user_metadata.first_name && b.user_metadata.first_name.length > 0;
                
                    // Prioritize users with names over those without.
                    if (aHasName && !bHasName) return -1;
                    if (!aHasName && bHasName) return 1;
                
                    // If both users have names, sort by the full name.
                    if (aHasName && bHasName) {
                        const aFullName = `${a.user_metadata.first_name} ${a.user_metadata.last_name}`.trim();
                        const bFullName = `${b.user_metadata.first_name} ${b.user_metadata.last_name}`.trim();
                        if (aFullName < bFullName) {
                            return -1
                        }
                        return 1
                    }
                
                    // If neither user has a name, sort by email.
                    if (a.email < b.email) {
                        return -1
                    }
                    return 1
                }
                
                const unsorted = [...factory_users];
                factory_users.sort(compareUsers);

                this.setState({
                    all_users: res.data,
                    org_users,
                    factory_users: factory_users,
                    selected_users: selected
                })
                this.getLogs()
            })
            .catch(err => {
                console.log(err)
                let error = err
                if(err && err.response && err.response.data) {
                    error = err.response.data.message
                }

                this.setState({
                    error
                })
            })
    }

    defaultOrg = (orgs) => {
        let defaultOrg = JSON.parse(window.localStorage.getItem("organization")).id

        return defaultOrg ? defaultOrg : orgs[0].id
    }

    defaultFactoryId = () => {
        let defaultFactory = JSON.parse(window.localStorage.getItem("factory"))

        return defaultFactory ? defaultFactory.id : null
    }

    filterOrgUsers = (users, org_id) => {
        let orgUsers = null;
        if(users && users[0]) {
            orgUsers = [];
            users.forEach(user => {
                let userOrgs = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    userOrgs = user.app_metadata.organizations;
                }

                if(userOrgs && userOrgs[0]) {
                    let userInThisOrg = userOrgs.find(org => org.id === org_id)
                    if(userInThisOrg) {
                        orgUsers.push(user)
                    }
                }
            })
        }

        return orgUsers
    }

    filterFactoryUsers = (users, factory_id) => {
        let factoryUsers = null;
        if(users && users[0]) {
            factoryUsers = [];
            users.forEach(user => {
                let userFactories = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    let userOrg = user.app_metadata.organizations.find(org => org.id === this.state.organization)
                    userFactories = userOrg ? userOrg.factories : null;
                }

                if(userFactories && userFactories[0]) {
                    let userInFactory = userFactories.find(factory => factory.id === factory_id)
                    if(userInFactory) {
                        factoryUsers.push(user)
                    }
                }
            })
        }

        return factoryUsers
    }

    showSelectDates = () => {
        return (
            <div style={{ marginRight: '10px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    className="option_item_dropdown"
                    style={{ textTransform: "initial", fontSize: "16px"  }}
                    variant="text"
                    onClick={() => this.setState({ show_select_dates: true })}
                    endIcon={<ArrowDropDown />}
                >
                    {`Dates: ${this.getDisplayedDate()}`}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };

    getDisplayedDate = () => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        let displayDate = ""
        if (this.state.dateType === "days") {
            const day = this.state.dates[0].getDate()
            const monthIndex = this.state.dates[0].getMonth()
            const monthName = monthNames[monthIndex]
            const year = this.state.dates[0].getFullYear()
            if (this.state.dates.length > 1) {
                displayDate = "Multiple dates"
            }
            else {
                displayDate = `${monthName} ${day}, ${year}`
            }
        }
        else {
            const firstDay = this.state.date_from.getDate()
            const firstMonthIndex = this.state.date_from.getMonth()
            const firstMonthName = monthNames[firstMonthIndex]
            const firstYear = this.state.date_from.getFullYear()

            const lastDay = this.state.date_to.getDate()
            const lastMonthIndex = this.state.date_to.getMonth()
            const lastMonthName = monthNames[lastMonthIndex]
            const lastYear = this.state.date_to.getFullYear()
            displayDate = `${firstMonthName} ${firstDay}, ${firstYear} - ${lastMonthName} ${lastDay}, ${lastYear}`
        }
        return displayDate
    }

    saveMultipleDate = (selectedDays) => {
        this.setState({ 
            dateType: "days",
            dates: selectedDays,
            show_select_dates: false,
        })
    }

    saveRangeDates = (from, to) => {
        this.setState({ 
            dateType: "range",
            date_from: from,
            date_to: to,
            show_select_dates: false,
         })
    }

    showDatesModal = () => {
        return (
            this.state.show_select_dates && (
                <MultiDatesPicker
                    setReportMultipleDates={this.saveMultipleDate}
                    setReportRangeDates={this.saveRangeDates}
                    type={this.state.dateType}
                    selectedDays={this.state.dates}
                    from={this.state.report_date_from}
                    to={this.state.report_date_to}
                    handleClose={() => this.setState({ show_select_dates: false })}
                />
            )
        );
    };

    showUsernames = () => {
        if (this.state.factory_users == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (this.state.selected_users && this.state.factory_users[0])
            if (this.state.selected_users.length === this.state.factory_users.length) {
                count = 'All';
            } else {
                count = this.state.selected_users.length.toString();
            }

        return (
            <div style={{ marginRight: '10px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    className={""}
                    variant="text"
                    style={{ textTransform: "initial", fontSize: "16px"  }}
                    fullWidth
                    disabled={this.state.factory_users == null}
                    onClick={() => this.setState({ show_select_username: true })}
                    endIcon={<ArrowDropDown />}
                >
                    {'Username Filter (' + count + ')'}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };

    showActions = () => {
        if (this.state.actions == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (this.state.selected_actions && this.state.actions[0])
            if (this.state.selected_actions.length === this.state.actions.length) {
                count = 'All';
            } else {
                count = this.state.selected_actions.length.toString();
            }

        return (
            <div style={{ marginRight: '10px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    className={""}
                    variant="text"
                    style={{ textTransform: "initial", fontSize: "16px"  }}
                    fullWidth
                    disabled={this.state.actions == null}
                    onClick={() => this.setState({ show_select_actions: true })}
                    endIcon={<ArrowDropDown />}
                >
                    {'Actions Filter (' + count + ')'}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };

    showPages = () => {
        if (this.state.pages == null) {
            return <div>Loading...</div>;
        }

        let count = '0';

        if (this.state.selected_pages && this.state.pages[0])
            if (this.state.selected_pages.length === this.state.pages.length) {
                count = 'All';
            } else {
                count = this.state.selected_pages.length.toString();
            }

        return (
            <div style={{ marginRight: '10px', padding: '11px 0 12px 12px', float: 'left' }}>
                <Button
                    className={""}
                    style={{ textTransform: "initial", fontSize: "16px" }}
                    variant="text"
                    fullWidth
                    disabled={this.state.pages == null}
                    onClick={() => this.setState({ show_select_pages: true })}
                    endIcon={<ArrowDropDown />}
                >
                    {'Pages Filter (' + count + ')'}
                </Button>
                <hr className="filter-button-hr" />
            </div>
        );
    };

    showUsernameModal = () => {
        return (
            this.state.show_select_username && (
                <SelectUsernameModal
                    users={this.state.factory_users}
                    selected_users={this.state.selected_users}
                    hideSelectUsers={this.hideSelectUsers}
                    cancelSelectLines={this.cancelSelectUsers}
                />
            )
        );
    };

    showActionModal = () => {
        return (
            this.state.show_select_actions && (
                <SelectActionModal
                    actions={this.state.actions}
                    selected_actions={this.state.selected_actions}
                    hideSelectActions={this.hideSelectActions}
                    cancelSelectActions={this.cancelSelectActions}
                />
            )
        );
    };

    showPagesModal = () => {
        return (
            this.state.show_select_pages && (
                <SelectPageModal
                    pages={this.state.pages}
                    selected_pages={this.state.selected_pages}
                    hideSelectPages={this.hideSelectPages}
                    cancelSelectPages={this.cancelSelectPages}
                />
            )
        );
    };

    hideSelectUsers = selected_users => {
        let updatedSelection = Array.from(selected_users);
        this.setState({
            selected_users: updatedSelection,
            show_select_username: false,
        });
    };

    cancelSelectUsers = () => {
        this.setState({
            show_select_username: false
        });
    };

    hideSelectActions = selected_actions => {
        let updatedSelection = Array.from(selected_actions);
        this.setState({
            selected_actions: updatedSelection,
            show_select_actions: false,
        });
    };

    cancelSelectActions = () => {
        this.setState({
            show_select_actions: false
        });
    };

    hideSelectPages = selected_pages => {
        let updatedSelection = Array.from(selected_pages);
        this.setState({
            selected_pages: updatedSelection,
            show_select_pages: false,
        });
    };

    cancelSelectPages = () => {
        this.setState({
            show_select_pages: false
        });
    };

    renderChildren = () => {
        let pageCount = Math.ceil(this.state.logs.length / 20);
        return (
        
            <div>
                <div className="settings__table">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px 10px" }}>
                        <div style={{ width: "70%" }}>
                            {this.showSelectDates()}
                            {this.showUsernames()}
                            {this.showActions()}
                            {this.showPages()}
                        </div>
                        <Button
                            style={{
                                marginRight: '10px',
                                height: '40px',
                                width: '130px'
                            }}
                            variant={'contained'}
                            color={'primary'}
                            // disabled={!wasChange && props.canUpdateOptions()}
                            onClick={this.getLogs}
                        >
                            Apply Filters
                        </Button>
                    </div >
                    <Table>
                        <TableHead>
                            <TableRow style={{ height: '20px', paddingTop: '20px' }}>
                                <TableCell
                                    style={{ width: "8%" }}
                                    align={'left'}
                                    className={'products_table_head_cell'}
                                >
                                    <div>Date</div>
                                </TableCell>
                                <TableCell
                                    align={'left'}
                                    className={'products_table_head_cell'}
                                    style={{ width: "8%" }}
                                >
                                    <div>Time</div>
                                </TableCell>
                                <TableCell
                                    align={'left'}
                                    style={{ width: "8%" }}
                                    className={'products_table_head_cell'}
                                >
                                    <div>User</div>
                                </TableCell>
                                <TableCell
                                    align={'left'}
                                    style={{ width: "6%" }}
                                    className={'products_table_head_cell'}
                                >
                                    <div>Action</div>
                                </TableCell>
                                <TableCell style={{ width: "22%" }} align={'left'} className={'products_table_head_cell'}>
                                    Page
                                </TableCell>
                                <TableCell style={{ width: "24%" }} align={'left'} className={'products_table_head_cell'}>
                                    Previous Value
                                </TableCell>
                                <TableCell style={{ width: "24%" }} align={'left'} className={'products_table_head_cell'}>
                                    New Value
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{this.showChangelog()}</TableBody>
                    </Table>
                </div>
                { this.state.logs.length > 0 ? (
                    // <div className="flex-box__row justify-center">
                    //     <ReactPaginate
                    //         forcePage={this.state.currentPage}
                    //         previousLabel={'Previous'}
                    //         nextLabel={'Next'}
                    //         breakLabel={'...'}
                    //         pageCount={pageCount}
                    //         previousLinkClassName={'page-previous'}
                    //         nextLinkClassName={'page-next'}
                    //         marginPagesDisplayed={2}
                    //         pageRangeDisplayed={5}
                    //         onPageChange={this.handlePageClick}
                    //         containerClassName={'pagination'}
                    //         pageClassName={'page'}
                    //         pageLinkClassName={'page-link'}
                    //         subContainerClassName={'pages pagination'}
                    //         activeClassName={'active'}
                    //     />
                    // </div>
                    <TablePagination
                        rowsPerPageOptions={[20]}
                        component="div"
                        count={this.state.logs.length}
                        rowsPerPage={20}
                        page={this.state.currentPage}
                        onPageChange={this.handlePageClick}
                    />
                ) : null }
                {this.showDatesModal()}
                {this.showUsernameModal()}
                {this.showActionModal()}
                {this.showPagesModal()}
            </div>

        )
    }

    showChangelog = () => {
        const logsArray = []
        for(let i = this.state.currentPage * 20; i < (this.state.currentPage + 1) * 20; i++) {
            if (this.state.logs[i]) {
                logsArray.push(this.state.logs[i])
            }   
        }
        if (logsArray.length === 0) {
            return (
                <div style={{ position: "absolute", left: "40%", marginTop: "10px", textAlign: "center" }}>
                    No changes in range
                </div>
            )
        }
        return logsArray.map((item, index) => {
            return (
                <TableRow style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} key={'log-' + index}>
                    <TableCell  style={{ verticalAlign: 'top', width: "8%" }} align={'left'} className={'logs_table_body_cell'}>
                        {/* {this.convertDateLong(item.date)} */}
                        {this.convertDateLong(new Date(item["Date"]))}
                    </TableCell>
                    <TableCell  style={{ verticalAlign: 'top', width: "8%" }} align={'left'} className={'logs_table_body_cell'}>
                        {this.convertTime(new Date(item["Date"]))}
                        {/* {item["Static Contextual Keys"]["Start Time"]} */}
                    </TableCell>
                    <TableCell  style={{ verticalAlign: 'top', width: "8%" }} align={'left'} className={'logs_table_body_cell'}>
                        {this.getUserEmail(item["Updated by"], item.is_system_generated)}
                    </TableCell>
                    <TableCell  style={{ verticalAlign: 'top', width: "6%" }} align={'left'} className={'logs_table_body_cell'}>
                        {item["Action"]}
                    </TableCell>
                    <TableCell  style={{ verticalAlign: 'top', width: "22%" }} align={'left'} className={'logs_table_body_cell'}>
                        {this.renderContextualKeys(item["Page"], item["Static Contextual Keys"], index)}
                    </TableCell>
                    <TableCell  style={{ verticalAlign: 'top', width: "24%" }} align={'left'} className={'logs_table_body_cell'}>
                        {this.renderPrevNewValues(item["Previous values"], index, 'prev', item["New values"])}
                    </TableCell>
                    <TableCell  style={{ verticalAlign: 'top', width: "24%" }} align={'left'} className={'logs_table_body_cell'}>
                        {this.renderPrevNewValues(item["New values"], index, 'new', item["Previous values"],)}
                    </TableCell>
                </TableRow>
            )
        })
    };

    render() {
        return <div style={{ marginTop: '20px' }}>
            <div>
                {this.renderChildren()}
            </div>
        </div>
    }
}

export default Changelog