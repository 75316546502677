class Styles {
    tableRowColumnStyle = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 400,
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            border: 'none',
            verticalAlign: 'middle',
            whiteSpace: 'normal'
        };
    };

    tableRowColumnStyleProductDetails = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '14px',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            fontWeight: 400,
            border: 'none',
            verticalAlign: 'middle',
            whiteSpace: 'normal'
        };
    };

    tableRowColumnEditButtonStyle = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: '#000000',
            border: 'none',
            verticalAlign: 'middle',
            display: 'flex',
            justifyContent: 'flex-end'
        };
    };

    tableRowColumnStyleWrap = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '14px',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            border: 'none',
            whiteSpace: 'pre-wrap'
        };
    };

    tableRowColumnStyleNoVerticalMargin = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            color: 'rgba(0, 0, 0, 0.87) !important',
            border: 'none',
            verticalAlign: 'middle'
        };
    };

    tableRowColumnCollapseStyle = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            color: '#000000',
            border: 'none',
            height: 0,
            opacity: 0,
            transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1)'
        };
    };

    tableRowColumnUMStyle = () => {
        return {
            fontSize: '16px',
            fontFamily: 'Roboto',
            paddingTop: '8px',
            paddingBottom: '8px',
            color: '#000000',
            border: 'none',
            verticalAlign: 'middle'
        };
    };

    tableRowColumnExpandStyle = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            color: '#000000',
            border: 'none',
            height: 200,
            opacity: 1,
            transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1)'
        };
    };

    tableRowColumnConfirmTableStyle = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            paddingTop: '8px',
            paddingBottom: '8px',
            color: '#000000',
            border: 'none'
        };
    };

    tableRowColumnProductStyle = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            paddingTop: '8px',
            paddingBottom: '8px',
            color: '#000000',
            border: 'none',
            verticalAlign: 'middle',
            whiteSpace: 'normal'
        };
    };

    tableRowColumnStyleShort = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '14px',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            border: 'none',
            width: '100px'
        };
    };

    tableCellStyleDragIcon = () => {
        return {
            fontSize: '1rem',
            paddingTop: '15px',
            paddingBottom: '15px',
            paddingLeft: '30px',
            color: '#000000',
            border: 'none',
            width: '40px'
        };
    };

    tableRowColumnStyleCheckBox = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            border: 'none',
            width: '20px',
            verticalAlign: 'middle',
            textOverflow: 'initial'
        };
    };

    tableRowColumnStyleShortWrap = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '12px',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            border: 'none',
            width: '100px',
            whiteSpace: 'pre-wrap'
        };
    };

    tableRowColumnStyleMedium = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            border: 'none',
            width: '256px'
        };
    };

    tableRowColumnStyleInactive = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            border: 'none',
            verticalAlign: 'middle',
            whiteSpace: 'normal',
            opacity: 0.5
        };
    };

    tableRowColumnStyleInactiveNoVerticalMargin = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            color: 'rgba(0, 0, 0, 0.87) !important',
            border: 'none',
            opacity: 0.5,
            verticalAlign: 'middle'
        };
    };

    tableRowColumnStyleShortInactive = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            border: 'none',
            width: '100px',
            opacity: 0.5
        };
    };

    tableRowColumnStyleCheckBoxInactive = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            border: 'none',
            width: '20px',
            opacity: 0.5
        };
    };

    tableRowColumnStyleRun = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            padding: '10px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            whiteSpace: 'initial',
            border: 'none',
            textAlign: 'center'
        };
    };

    tableRowStyle = index => {
        let bgColor = '#ffffff';
        if (index % 2 == 0) bgColor = '#f2f2f2';

        return {
            border: 'none',
            backgroundColor: bgColor
        };
    };

    tableRowExpandStyle = (index, visible) => {
        let bgColor = '#ffffff';
        if (index % 2 === 0) bgColor = '#f2f2f2';

        return {
            border: 'none',
            height: visible ? '300px !important' : '0 !important',
            backgroundColor: bgColor
        };
    };

    tableRowTotalColumnStyle = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            paddingTop: '15px',
            paddingBottom: '15px',
            color: '#000000',
            border: 'none',
            fontWeight: 'bold'
        };
    };

    tableRowTotalStyle = () => {
        let bgColor = '#afafaf';

        return {
            border: '1px solid #afafaf',
            backgroundColor: 'none'
        };
    };

    tableHeaderStyleRun = (bg = '#FFFFFF') => {
        return {
            textTransform: 'uppercase',
            minHeight: '20px',
            color: '#fffff',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            padding: '10px',
            whiteSpace: 'initial',
            textAlign: 'center',
            verticalAlign: 'middle',
            backgroundColor: bg
        };
    };

    tableHeaderStyle = (bg = '#FFFFFF') => {
        return {
            textTransform: 'capitalize',
            minHeight: '20px',
            fontSize: "14px !important",
            color: 'rgba(0, 0, 0, 1)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: bg,
            verticalAlign: 'middle',
            whiteSpace: 'pre-wrap',
            fontWeight: 500
        };
    };

    tableHeaderStyleClickable = (bg = '#FFFFFF') => {
        return {
            textTransform: 'uppercase',
            minHeight: '20px',
            color: '#000000',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: bg,
            cursor: 'pointer'
        };
    };

    tableHeaderStyleShort = (bg = '#FFFFFF') => {
        return {
            minHeight: '20px',
            color: 'rgba(0, 0, 0, 1)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: bg,
            width: '100px',
            verticalAlign: 'middle',
            whiteSpace: 'pre-wrap',
            fontSize: '14px',
            fontWeight: 500
        };
    };

    tableCellHeaderStyleDragIcon = () => {
        return {
            color: '#999999',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: '#fff',
            width: '56px',
            marginLeft: '30px',
            verticalAlign: 'middle',
            whiteSpace: 'pre-wrap',
            fontSize: '14px',
            fontWeight: 700
        };
    };

    tableHeaderStyleShortWrap = (bg = '#FFFFFF') => {
        return {
            textTransform: 'uppercase',
            minHeight: '20px',
            color: '#000000',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: bg,
            width: '100px',
            whiteSpace: 'pre-wrap'
        };
    };

    tableHeaderStyleCheckBox = (bg = '#FFFFFF') => {
        return {
            textTransform: 'uppercase',
            minHeight: '20px',
            color: '#000000',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: bg,
            width: '20px',
            verticalAlign: 'middle',
            textOverflow: 'initial'
        };
    };

    tableHeaderStyleMedium = (bg = '#FFFFFF') => {
        return {
            textTransform: 'uppercase',
            minHeight: '20px',
            color: '#000000',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: bg,
            width: '256px'
        };
    };

    editDeleteTableHeaderStyle = (bg = '#FFFFFF') => {
        return {
            textTransform: 'uppercase',
            minHeight: '20px',
            color: '#000000',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: bg,
            width: '200px',
            verticalAlign: 'bottom'
        };
    };

    editDeleteTableRowColumnStyle = () => {
        return {
            fontFamily: 'Roboto',
            fontSize: '12px',
            paddingTop: '0px',
            paddingBottom: '0px',
            color: '#000000',
            border: 'none',
            width: '200px',
            verticalAlign: 'middle'
        };
    };

    buttonStyle() {
        return {
            marginRight: '15px',
            float: 'right'
        };
    }

    deleteButtonStyle() {
        return {
            width: '20px',
            marginRight: '15px',
            height: 'inherit',
            padding: 'inherit'
        };
    }

    repeatingNonOEEBackground = () => {
        return 'repeating-linear-gradient( -45deg, #CE3125, #CE3125 8px, #C1C1C1 8px, #C1C1C1 16px )';
    };

    tableRowColumnStyleRunLeftAlign = () => {
        return {
            textAlign: 'center',
            borderBottom: '1px solid',
            borderTop: '1px solid',
            borderLeft: '1px solid',
            color: 'rgba(0, 0, 0, 0.87) !important'
        };
    };

    tableRowColumnStyleRunCenterBorder = () => {
        return {
            textAlign: 'center',
            borderBottom: '1px solid',
            borderTop: '1px solid',
            color: 'rgba(0, 0, 0, 0.87) !important'
        };
    };

    tableRowColumnStyleRunRightBorder = () => {
        return {
            textAlign: 'center',
            borderBottom: '1px solid',
            borderTop: '1px solid',
            borderRight: '1px solid',
            color: 'rgba(0, 0, 0, 0.87) !important'
        };
    };
}

export default new Styles();
