import React, { Component } from 'react';

import RaisedButton from 'material-ui/RaisedButton';

import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';
import {
    MenuItem, RadioButton, RadioButtonGroup, SelectField,
    CircularProgress
} from "material-ui";
import { DatePicker, TimePicker } from "@material-ui/pickers";

class AssociateRunToBatchRunModal extends Component {
    constructor (props) {
        super(props);
        this.state = {
            edit_confirmed: false,
            submitting_edit: false,
            edit_status: null,
            batch_details: null,
            editing_batch: false,
            editing_batch_type: "add",
            editing_batch_error: null,
            modified_batch: false,
            loading_report: false,
            loading_run: false,
            run: null,
            kill_state: false,
            popped: false,
            run_id: this.props.run_id,
            error: false,
            date_from: this.props.date_from,
            date_to: this.props.date_to,
            show_modal: false,
            edit_batch_modal_data: null,
            date_batch: '',
            batch_runs: null,
            received_data: false,
            batch_id: '',
            chosen_batch: '',
            height: 'inherit',
            loading_batches: false
        };

        if(this.props.editing_run) {
            this.state = {
                lines: this.props.lines,
                date_batch: this.getDate(this.props.run.run_start_time),
                selected_line: this.getLineID(),
                run: this.props.run,
                date_from: this.getDate(this.props.run.run_start_time),
                date_to: this.getDate(this.props.run.run_end_time),
                time_from:this.getDate(this.props.run.run_start_time),
                time_to: this.getDate(this.props.run.run_end_time),
                chosen_batch: this.props.batch_run_id,
                products: this.props.products,
                selected_product: this.getSelectedProduct()
            }
        } else {
            this.state = {
                date_batch: this.getDate(this.props.run_start_date)
            }
        }
    }

    getLineID = () => {
        let selectedLine = 0;
        let props = this.props;
        this.props.lines.forEach(function (line) {
            if (line.name == props.run.line_name) {
                selectedLine = line.line_id;
            }
        })
        return selectedLine
    }

    getSelectedProduct = () => {
        let selectedProduct = 0;
        let props = this.props;
        this.props.products.forEach(function (product) {
            let product_display = props.run.product_name + " - " + props.run.product_desc
            if(product.display_name == product_display) {
                selectedProduct = product.product_id
            }
        })

        return selectedProduct
    }

    getDate = (d) => {
        if(d == null) {
            return new Date();
        }
        return new Date(d)
    }

    getTime = (d) => {
        let date = new Date(d);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        let time = new Date();
        time.setUTCHours(hours);
        time.setUTCMinutes(minutes);
        time.setUTCSeconds(seconds);
        return time;
    }

    showEditRun = () => {
        if(!this.props.editing_run){
            return
        }

        return <div>
            {this.showLines()}
            <DatePicker
                label="Start Date"
                shouldDisableDate={this.disableFutureDates}
                value={this.state.date_from}
                onChange={this.handleDateFromChange}
                format="yyyy-MMMM-DD"
                className="reports__options-item_picker"
            />
            <TimePicker
                id={"start-time-time-picker"}
                onChange={this.handleTimeFromChange}
                value={this.state.time_from}
                label="Start Time"
                className="reports__options-item_picker"
            />
            <DatePicker
                label="End Date"
                shouldDisableDate={this.disableFutureDates}
                value={this.state.date_to}
                onChange={this.handleDateToChange}
                format="yyyy-MMMM-DD"
                className="reports__options-item_picker"
            />
            <TimePicker
                id={"end-time-time-picker"}
                onChange={this.handleTimeToChange}
                value={this.state.time_to}
                label="End Time"
                className="reports__options-item_picker"
            />
            {this.showProducts()}

        </div>
    }

    showProducts = () => {
        if(this.state.products == null) {
            return <div>Loading...</div>
        }

        return <div className="reports__options-item">
            <SelectField
                fullWidth={true}
                floatingLabelText={this.getSelectProductText()}
                value={this.state.selected_product}
                onChange={this.handleSelectProductChange}
                autoWidth={true}
                style={{width: '450px'}}
            >
                <MenuItem value={null} primaryText="" />
                {this.iterateProducts()}
            </SelectField>
        </div>;
    }

    getSelectProductText = () => {
        if (this.state.products.constructor == Array) {
            return "Select Product";
        }
        return "Loading...";
    }

    handleSelectProductChange = (event, index, value) => {
        this.setState({
            selected_product: value
        })

    }

    iterateProducts = () => {
        if(this.state.products == null || this.state.products.constructor != Array) {
            return;
        }

        return this.state.products.map((item, index) =>
            <MenuItem
                value={item.product_id}
                primaryText={item.display_name}
                key={"product-"+index}
            />
        )
    }

    showOptions = () => {
        return <div className="reports__sub">
            <DatePicker
                label="Date"
                shouldDisableDate={this.disableFutureDates}
                value={this.state.date_batch}
                onChange={this.handleDateBatchChange}
                format="yyyy-MMMM-DD"
                className="reports__options-item"
            />
            <RaisedButton
                label="Batch Lookup" className="reports__options-item" style={{marginTop: "11px"}}
                disabled={this.state.loading_batches} primary={true} onClick={this.getAvailableBatches.bind(this)} />
            {this.state.loading_batches &&
            <div><CircularProgress size={20} thickness={2}/></div>
            }
        </div>
    }

    showLines = () => {
        if (this.state.lines == null) {
            return <div>Loading...</div>;
        }

        return <div className="reports__options-item">
            <SelectField
                fullWidth={true}
                floatingLabelText={this.getSelectFieldText()}
                value={this.state.selected_line}
                onChange={this.handleSelectLineChange}
            >
                <MenuItem value={null} primaryText="" />
                {this.iterateLines()}
            </SelectField>
        </div>;
    }

    disableFutureDates(date) {
        if (date >= new Date()) {
            return true;
        } else {
            return false;
        }
    }

    handleDateBatchChange = (event, date) => {
        this.setState({
            date_batch: date
        })
    }

    handleDateFromChange = (event, date) => {
        this.setState({
            date_from: date
        })
    }


    handleDateToChange = (event, date) => {
        this.setState({
            date_to: date
        })
    }

    handleTimeFromChange = (event, time) => {
        this.setState({
            time_from: time,
            date_from: this.appendTimeFrom(time)
        })
    }

    handleTimeToChange = (event, time) => {
        this.setState({
            time_to: time,
            date_to: this.appendTimeTo(time)
        })
    }

    appendTimeFrom = (t) => {
        let date = this.state.date_from;
        date.setHours(t.getHours());
        date.setMinutes(t.getMinutes());
        date.setSeconds(t.getSeconds());

       return date
    }

    appendTimeTo = (t) => {
        let date = this.state.date_to;
        date.setHours(t.getHours());
        date.setMinutes(t.getMinutes());
        date.setSeconds(t.getSeconds());

        return date
    }

    canUpdateOptions = () => {
        if (this.state.date_batch == null /*|| this.state.date_to == null || this.state.selected_line == null*/) {
            return true;
        }

        return false;
    }

    getSelectFieldText = () => {
        if (this.state.lines.constructor == Array) {
            return "Select Line";
        }

        return "Loading...";
    }

    handleSelectLineChange = (event, index, value) => {
        this.setState({selected_line: value});

    }

    iterateLines = () => {
        if (this.state.lines == null || this.state.lines.constructor != Array) {
            return;
        }

        return this.state.lines.map((item, index) =>
            <MenuItem
                value={item.id}
                primaryText={item.name}
                key={"reports-line-"+index}
            />
        )
    }

    getAvailableBatches = () => {
        this.setState({
            received_data: false,
            loading_batches: true
        });

        let params = {
            path: "batch_lookup",
            path_variables: {

            },
            data: {
                date: this.state.date_batch.toISOString(),
            },
            success: this.getAvailableBatchesSuccess,
            error: this.getAvailableBatchesError
        };

        API.c(params);
    }

    getAvailableBatchesSuccess = (data) => {
        this.setState({
            batch_runs: data,
            received_data: true,
            loading_batches: false
        })
        if(data.length > 4) {
            this.setState({
                height: '200px'
            })
        } else {
            this.setState({
                height: 'inherit'
            })
        }
    }


    getAvailableBatchesError = (error) => {
        this.setState({
            received_data: false,
            error: error
        })
    }

    showSelectBatch = () => {
        if(!this.state.received_data){
            return
        }

        return <div className="reports__data-table-info">
            <div className="reports__data-table-info-label">Batches</div>
            <Table
                selectable={false}
                multiSelectable={false}
                height={this.state.height}
            >
                <TableHeader
                    displaySelectAll={false}
                    adjustForCheckbox={false}
                    enableSelectAll={false}
                >
                    <TableRow>
                        <TableHeaderColumn style={this.tableHeaderStyle()}>Line</TableHeaderColumn>
                        <TableHeaderColumn style={this.tableHeaderStyle()}>Product Name</TableHeaderColumn>
                        <TableHeaderColumn style={this.tableHeaderStyle()}>Start Time</TableHeaderColumn>
                        <TableHeaderColumn style={this.tableHeaderStyle()}>End Time</TableHeaderColumn>
                        <TableHeaderColumn style={this.tableHeaderStyle()}>Select</TableHeaderColumn>
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false}>
                    {this.lineDetailBatches()}
                </TableBody>
            </Table>

        </div>
    }

    lineDetailBatches = () => {
        if (!this.state.batch_runs || this.state.batch_runs == null) {
            return;
        }

        return this.state.batch_runs.map((item, index) =>
            <TableRow
                style={this.tableRowStyle(index)}
                className="report__row"
            >
                <TableRowColumn style={this.tableRowColumnStyle()}><div onClick={this.pickBatch.bind(this, item)}>{item.line_name}</div></TableRowColumn>
                <TableRowColumn style={this.tableRowColumnStyle()}><div onClick={this.pickBatch.bind(this, item)}>{item.product_name}</div></TableRowColumn>
                <TableRowColumn style={this.tableRowColumnStyle()}><div onClick={this.pickBatch.bind(this, item)}>{Util.formatFullDateTime(item.start_time)}</div></TableRowColumn>
                <TableRowColumn style={this.tableRowColumnStyle()}><div onClick={this.pickBatch.bind(this, item)}>{Util.formatFullDateTime(item.end_time)}</div></TableRowColumn>
                <TableRowColumn style={this.tableRowColumnStyle()}><RadioButtonGroup name="select-batch" onChange={this.pickBatch.bind(this, item)} valueSelected={this.state.chosen_batch}><RadioButton
                    value={item.batch_run_id}
                /></RadioButtonGroup></TableRowColumn>
            </TableRow>
        )
    }



    pickBatch = (item) => {
        this.setState({
            chosen_batch: item.batch_run_id
        });
    }

    showBatchButtons = () => {
        if (this.state.chosen_batch != '') {
            if(this.state.submitting_edit) {
                return <div className="edit-run-buttons__wrapper"><CircularProgress size={20} thickness={2}/></div>
            }
            return <div className="edit-run-buttons__wrapper">
                <RaisedButton
                    primary={true}
                    onClick={this.updateRun}
                    label="Update Run"
                />
            </div>
        }
    }

    updateRun = () => {
        let data = {};
        let end_time;
        if (this.state.date_to != null) {
            end_time = this.state.date_to.toISOString();
        } else {
            end_time = null
        }
        this.setState({
            submitting_edit: true
        });

        if(this.props.editing_run){
            data = {
                line: this.state.selected_line,
                start_time: this.state.date_from.toISOString(),
                end_time: end_time,
                batch_run: this.state.chosen_batch,
                product: this.state.selected_product
            }
        } else {
            data = {
                batch_run: this.state.chosen_batch,
            }
        }

        let params = {
            path: "update_run",
            path_variables: {
                RUN_ID: this.props.run_id
            },
            data: data,
            success: this.updateRunSuccess,
            error: this.getAvailableBatchesError
        };

        API.c(params);
    }

    updateRunSuccess = () => {
        this.props.modified();
        this.hideModal();
    }

    tableRowColumnStyle() {
        return {
            fontFamily: "Roboto",
            fontSize: "12px",
            paddingTop: "15px",
            paddingBottom: "15px",
            color: "#000000",
            border: "none"

        }
    }


    tableRowColumnStyleRun() {
        return {
            fontFamily: "Roboto",
            fontSize: "12px",
            padding: "10px",
            color: "#000000",
            whiteSpace: "initial",
            border: "none",
            textAlign: "center"
        }
    }


    tableRowStyle(index) {
        let bgColor = "#ffffff";
        if ( index % 2 == 0)  bgColor = "#f2f2f2";

        return {
            border: "none",
            backgroundColor: bgColor
        }
    }


    tableHeaderStyleRun(bg = "#FFFFFF") {
        return {
            textTransform: "uppercase",
            minHeight: "20px",
            color: "#fffff",
            borderBottom: "1px solid #999999",
            padding: "10px",
            whiteSpace: "initial",
            textAlign: "center",
            verticalAlign: "middle",
            backgroundColor: bg
        }
    }


    tableHeaderStyle(bg = "#FFFFFF") {
        return {
            textTransform: "uppercase",
            minHeight: "20px",
            color: "#000000",
            borderBottom: "1px solid #999999",
            backgroundColor: bg
        }
    }



    hideModal = () => {
        this.props.hideModal();
    }


    doHideModal = () => {
        if (this.state.modified_batch == true) {
            this.props.refreshReport();
        }

        this.hideModal();
    }



    editStatus () {
        if (this.state.edit_error) {
            let e = "Unable to save";

            if (error) {
                if (error.error) {
                    e = error.error;
                }

                if (error.detail) {
                    e = error.detail;
                }
            }

            return <div className="delete-user__error">{e}</div>
        } else {
            return <div></div>
        }
    }


    tableRowColumnStyle() {
        return {
            fontFamily: "Roboto",
            fontSize: "12px",
            paddingTop: "15px",
            paddingBottom: "15px",
            color: "#000000",
            border: "none"
        }
    }


    tableRowStyle(index) {
        let bgColor = "#ffffff";
        if ( index % 2 == 0)  bgColor = "#f2f2f2";

        return {
            border: "none",
            backgroundColor: bgColor
        }
    }


    tableHeaderStyle() {
        return {
            textTransform: "uppercase",
            height: "20px",
            color: "#000000",
            borderBottom: "1px solid #999999"
        }
    }


    render() {
        return <div>
            {this.showEditRun()}
            {this.showOptions()}
            {this.showSelectBatch()}
            {this.showBatchButtons()}
            {this.editStatus()}
        </div>
    }
}

export default AssociateRunToBatchRunModal;