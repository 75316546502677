import React, {Component} from 'react';
import {Button} from "@material-ui/core";

class ConfirmLogout extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* ######## Lifecycle Methods ######## */
    componentDidMount = () => {
        this.setKeyListener();
    }

    componentWillUnmount = () => {
        this.cancelKeyListener();
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            if (event.keyCode === 13) {
                this.props.hideModal();
                this.props.logout();
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }



    buttons () {
        return <div className="delete-user__buttons">
            <Button variant="text"
                    color="primary"
                    onClick={this.props.hideModal}>Cancel</Button>
            <Button variant="contained"
                    color="primary"
                    onClick={() => {
                        this.props.hideModal();
                        this.props.logout();}}
                    style={{marginLeft: "16px"}} >Logout</Button>
        </div>;
    }

    render() {
        return <div>
            <div className="delete-user__text">
                Are you sure you want to Sign Out?
            </div>
            {this.buttons()}
        </div>
    }
}

export default ConfirmLogout