import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import ConfirmLogout from '../modals/ConfirmLogout';
import Animate from 'react-smooth/lib';
import { FontIcon, Menu, Popover } from 'material-ui';
import { MenuItem, ListItemIcon, Button, Typography, IconButton } from '@material-ui/core';
import { AssignmentInd, PowerSettingsNew, ChevronLeft, ChevronRight } from '@material-ui/icons';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import Button from '@mui/material/Button';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

import MonitorIcon from '@mui/icons-material/Monitor';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

class HeaderTopNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_modal: false,
            from: 0,
            to: 1,
            open: false,
            logout: false,
            type: '',
            factory: window.localStorage.getItem('factory') ? JSON.parse(window.localStorage.getItem('factory')) : {},
            selected_factory: window.localStorage.getItem('factory')
                ? JSON.parse(window.localStorage.getItem('factory'))
                : {},
            anchorOrigin: {
                horizontal: 'left',
                vertical: 'bottom'
            },
            targetOrigin: {
                horizontal: 'left',
                vertical: 'top'
            },
            organizations: this.getOrganizations(),
            organization: this.getOrganization(),
            selectedOrganization: null,
            factories: this.getFactories(),
            factory_error: null
        };
    }

    getOrganizations = () => {
        let organizations = JSON.parse(window.localStorage.getItem('organizations'));
        return organizations ? organizations : null;
    };

    getOrganization = () => {
        let organization = JSON.parse(window.localStorage.getItem('organization'));
        return organization ? organization : null;
    };

    getFactories = () => {
        let organization = JSON.parse(window.localStorage.getItem('organization'));
        return organization ? organization.factories : null;
    };

    handleTouchTap = (type, event) => {
        // This prevents ghost click.
        event.preventDefault();
        this.setState({
            open: true,
            type: type,
            anchorEl: event.currentTarget
        });
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
            selectedOrganization: null
        });
    };

    handleOrgMenuClick = value => {
        this.setState({
            organization: value,
            selectedOrganization: this.state.selectedOrganization ? null : value,
            factories: value.factories,
            factory: {}
        });
    };

    handleMenuClick = value => {
        this.setState({
            open: false,
            type: '',
            factory: value,
            selected_factory: value,
            selectedOrganization: null
        });
        if (value.id) {
            window.localStorage.setItem('organization', JSON.stringify(this.state.organization));
            window.localStorage.setItem('factory', JSON.stringify(value));
            LineStore.clear();
            this.props.onFactoryChange(value);
        }
    };

    getLabel = () => {
        if (!this.state.factories) {
            return 'Loading...';
        }

        return this.state.selected_factory.name;
    };

    showModal = () => {
        this.setState({
            open: false,
            show_modal: true,
            from: 0,
            to: 1
        });
    };

    hideModal = () => {
        this.setState({
            from: 1,
            to: 0
        });
    };

    onAnimEnd = () => {
        if (this.state.from === 1) {
            this.setState({ show_modal: false });
        }
    };

    logout = () => {
        this.hideModal();
        User.logout();
    };

    showLogoutModal = () => {
        if (this.state.show_modal === false) {
            return <div />;
        } else {
            return (
                <Animate
                    from={{ opacity: this.state.from }}
                    to={{ opacity: this.state.to }}
                    easing="ease-in"
                    duration={250}
                    onAnimationEnd={this.onAnimEnd}
                >
                    {({ opacity }) => (
                        <div style={{ opacity }}>
                            <Modal title={'Confirm Sign Out'} hideModal={this.hideModal} class_name="__add-user">
                                <ConfirmLogout hideModal={this.hideModal} logout={this.logout} />
                            </Modal>
                        </div>
                    )}
                </Animate>
            );
        }
    };

    topNav = () => {
        let url = '/liveview?scoreboard=true';

        return (
            <div className="header__menu-items">
                {this.showFactoryDropdown()}
                <div style={{ right: "120px" }} className="header__menu-item__top">
                    <a style={{ color: "black" }} target="_blank" href="https://help.livetracking.io/knowledge">
                        <HelpOutlineOutlinedIcon />
                    </a>
                </div>
                <div style={{ right: "70px" }} className="header__menu-item__top">
                    <Link className="no_underline" to={url} onClick={this.props.onShowScoreboardClicked}>
                        <MonitorIcon />
                    </Link>
                </div>
                <div style={{ right: "20px" }} className="header__menu-item__top">
                    <PersonOutlineOutlinedIcon onClick={this.handleTouchTap.bind(this, 'user')} />
                </div>
                <Popover
                    open={this.state.open && this.state.type === 'user'}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={this.state.anchorOrigin}
                    targetOrigin={this.state.targetOrigin}
                    onRequestClose={this.handleRequestClose}
                >
                    <Menu>
                        <MenuItem href={'/account'}>
                            <Link className="no_underline" to={'/account'}>
                                <ListItemIcon>
                                    <AssignmentInd color={'inherit'} />
                                </ListItemIcon>
                                Profile
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={this.showModal}>
                            <ListItemIcon>
                                <PowerSettingsNew color={'inherit'} />
                            </ListItemIcon>
                            Sign out
                        </MenuItem>
                    </Menu>
                </Popover>
            </div>
        );
    };

    showFactoryDropdown = () => {
        if (this.props.noFactories) {
            return <div />;
        }

        if (!this.state.factories) {
            return <div />;
        }

        return (
            <div className="header--dropdown">
                <Button
                    variant={'text'}
                    className={'factories--dropdown'}
                    onClick={this.handleTouchTap.bind(this, 'factory')}
                    endIcon={ !this.state.open ? 
                        <FontIcon
                            style={{ fontSize: '20px', color: '#000', fontWeight: 500, paddingLeft: 0 }}
                            className="zmdi zmdi-caret-down"
                        /> : 
                        <FontIcon
                            style={{ fontSize: '20px', color: '#000', fontWeight: 500, paddingLeft: 0 }}
                            className="zmdi zmdi-caret-up"
                        />
                    }
                >
                    {this.getLabel()}
                </Button>
                <Popover
                    open={this.state.open && this.state.type === 'factory'}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={this.state.anchorOrigin}
                    targetOrigin={this.state.targetOrigin}
                    onRequestClose={this.handleRequestClose}
                >
                    <div style={{ display: 'flex', minWidth: '200px' }}>
                        {this.showSelectOrganizationMenu()}
                        {/* {this.showSelectFactoryMenu()} */}
                    </div>
                </Popover>
            </div>
        );

        // return (
        //     <div className="header--dropdown">
        //         <Select
        //             className="factories--dropdown"
        //             value={this.state.selectedOrganization}
        //             onChange={this.handleOrgMenuClick}
        //             label="Age"
        //         >
        //             {this.state.organizations.map((org, i) => {
                        
        //                 return (
        //                     <MenuItem value={org}>
        //                         <MuiAccordion expanded={false}>

        //                         </MuiAccordion>
        //                     </MenuItem>
        //                 )
        //             })}
        //         </Select>
        //     </div>
        // )
    };

    showSelectOrganizationMenu = () => {
        // if (this.state.selectedOrganization !== null) {
        //     return null;
        // }

        return (
            <div className={'factories--dropdown-wrapper'}>
                <Menu className={'factories--dropdown--menu'}>
                    {this.state.organizations.map(organization => {
                        return (
                            <Accordion
                                sx={{ margin: "0px !important", '&:before': { display: 'none' },
                                    '.MuiAccordionSummary-root': { padding: "0px 16px !important" } }}
                                open={this.state.selectedOrganization && this.state.selectedOrganization.name === organization.name}
                                onClick={this.handleOrgMenuClick.bind(this, organization)}
                            >
                                <AccordionSummary sx={{ '.MuiAccordionSummary-content': { alignItems: "center", justifyContent: "space-between", color: "rgba(0, 0, 0, 0.6)" },
                                                        '.MuiAccordionSummary-content.Mui-expanded' : {  },
                                                         }}>
                                    {organization.name}
                                    { this.state.selectedOrganization && (this.state.selectedOrganization.name === organization.name) ?
                                         <KeyboardArrowUpOutlinedIcon className={'factories--dropdown--icon-button'} color={'inherit'} /> :
                                         <KeyboardArrowDownOutlinedIcon className={'factories--dropdown--icon-button'} color={'inherit'} />
                                    }
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: "0px !important" }}>
                                    {organization.factories.map(factory => {
                                        return (
                                            <div
                                                value={factory.id}
                                                className={'factories--dropdown--menu-item'}
                                                style={{ marginLeft: "20px" }}
                                                onClick={this.handleMenuClick.bind(this, factory)}
                                                key={`org-${organization.id}-factory-${factory.id}`}
                                            >
                                                {factory.name}
                                            </div>
                                        );
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </Menu>
            </div>
        );
    };

    showSelectFactoryMenu = () => {
        if (this.state.selectedOrganization === null) {
            return null;
        }

        return (
            <div className={'factories--dropdown-wrapper'}>
                <div className={'factories--dropdown--title'}>
                    <IconButton
                        color={'inherit'}
                        className={'factories--dropdown--icon-button'}
                        onClick={() => this.setState({ selectedOrganization: null })}
                    >
                        <ChevronLeft />
                    </IconButton>
                    <p className={'factories--dropdown-header'}>Select Factory</p>
                </div>
                <Menu className={'factories--dropdown--menu'}>
                    {this.state.factories.map(factory => {
                        return (
                            <MenuItem
                                value={factory}
                                className={'factories--dropdown--menu-item'}
                                onClick={this.handleMenuClick.bind(this, factory)}
                                // innerDivStyle={{background: factory.id === this.state.factory.id ? '#e9e9e9' : 'transparent', margin: '8px', borderRadius: '8px'}}
                                key={'factory-' + factory.id}
                            >
                                {factory.name}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        );
    };

    renderLogout_iFrame = () => {
        //This will force Auth0 to clear SSO cookie
        if (this.state.logout) {
            return <iframe src="https://livetracking.auth0.com/v2/logout" style={{ display: 'none' }} />;
        }
    };

    render() {
        return (
            <div className={this.props.className}>
                {this.topNav()}
                {this.showLogoutModal()}
                {/*{this.renderLogout_iFrame()}*/}
            </div>
        );
    }
}

export default HeaderTopNav;
