import React, { Component } from 'react';
import { browserHistory, Link } from 'react-router-dom';
import { Bar, BarChart, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomEfficiencyToolTip from './CustomEfficiencyToolTip';
import LiveViewLineInfoSection from './LiveViewLineInfoSection';
import { Typography } from '@material-ui/core';
import login from '../views/Login';

class LineItemColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            run_time: 0,
            giveawayStates: {}
        };
    }
    componentDidMount = () => {
    };

    componentWillUnmount() {}

    // getRandomInt(max) {
    //     return Math.floor(Math.random() * max);
    //   }

    showDetails = () => {
        let { item, presentation } = this.props;

        const shouldDisplayOutput = Util.shouldDisplayOutput(item)
        let data = [];

        if (shouldDisplayOutput) {
            item.output_per_hour ?
                item.output_per_hour.map((entry, index) => {
                    return data.push({
                        label: this.formatHour(entry.hour_of_day),
                        amt: Math.floor(entry.output * 100) / 100
                    })
                }):
                data.push({
                    label: 0,
                    amt: 0
                });
        } else {
            item.efficiency_per_hour
                ? item.efficiency_per_hour.map((item, index) =>
                    data.push({
                        label: this.formatHour(item.hour_of_day),
                        amt: Math.floor(item.efficiency_percent * 100) / 100
                    })
                )
                : data.push({
                    label: 0,
                    amt: 0
                });
        }

        let target = item ?
            shouldDisplayOutput ?
                item.target_speed_pieces_per_minute * 60 * (item.line_target_efficiency / 100) :
                item.line_target_efficiency :
            0;


        let ticks = []
        let values = []
        data.map(output => {
            values.push(output.amt)
        })


        let maxValue = shouldDisplayOutput ? Math.max(...values) : 100;
        let minValue = 250;

        if (shouldDisplayOutput) {
            maxValue = maxValue <= 0 ? minValue : maxValue + (50 % maxValue)
            if(maxValue < minValue) {
                maxValue = minValue
            }
            for(let i = 0; i <= maxValue; i += 50) {
                ticks.push(i)
            }
        } else {
            for(let i = 0; i <= 100; i += 25) {
                ticks.push(i)
            }
        }

        return (
            <div className="line-item__column-content">
                <div>
                    <div className="reports__data-lost-time-label">
                        <Typography variant={'subtitle2'}>{shouldDisplayOutput ? "Pieces per Hour" : `Hourly ${localStorage.getItem("efficiency_string_long") !== 'undefined' ? localStorage.getItem("efficiency_string_long") : 'Efficiency'}`}</Typography>
                    </div>
                    <ResponsiveContainer width="100%" height={220}>
                        <BarChart data={data}>
                            <XAxis dataKey="label" domain={['dataMin', 'dataMax']} interval={0} />
                            <YAxis domain={[minValue, maxValue]} ticks={ticks} interval={0}/>
                            <ReferenceLine
                                y={target}
                                stroke="#2967c1"
                                strokeDasharray="10 5"
                                strokeWidth={2}
                                ifOverflow="extendDomain"
                            />
                            <Tooltip active={true} content={<CustomEfficiencyToolTip output={shouldDisplayOutput} unit={item?.unit_info?.output?.display_unit_name}/>} allowEscapeViewBox={{ x: true, y: true }} cursor={data.length !== 0} />
                            <Bar dataKey="amt">
                                {data.map((entry, index) => (
                                    <Cell fill={'#6ebd3a'} key={`cell-${index}`} />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    };

    showAverageLineSpeed = () => {
        let { item } = this.props;

        // if (item.average_speed_per_hour) {
        //     // item.average_speed_per_hour[0].average_speed_per_minute = 100
        //     item.average_speed_per_hour.forEach((el, i) => {
        //         item.average_speed_per_hour[i].average_speed_per_minute = this.getRandomInt(800)
        //     })
        //     item.target_speed_pieces_per_minute = this.getRandomInt(800)
        // }

        let data = [];

        item.average_speed_per_hour
            ? item.average_speed_per_hour.map((item, index) =>
                  data.push({
                      label: this.formatHour(item.hour_of_day),
                      amt: item.average_speed_per_minute,
                      actual: item.average_speed_per_minute
                  })
              )
            : data.push({
                  label: 0,
                  amt: 0,
                  actual: 0
              });

        let ticks = [];
        let values = [];
        data.map(speed => {
            values.push(speed.amt);
        });

        let maxValue = Math.max(...values);
        let yAxisTarget = maxValue * 1.1;

        let target = item.target_speed_pieces_per_minute;
        if (maxValue === 0) {
            yAxisTarget = target * 1.1;
        }

        let intervals = [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            15,
            20,
            25,
            50,
            100,
            200,
            300,
            400,
            500,
            600,
            700,
            800,
            900,
            1000,
            1100,
            1200,
            1300,
            1400,
            1500,
            1600,
            1700,
            1800,
            1900,
            2000,
            2100,
            2200,
            2300,
            2400,
            2500,
            3000,
            3500,
            4000,
            4500,
            5000,
            5500,
            6000,
            6500,
            7000,
            7500,
            8000,
            8500,
            9000,
            9500,
            10000,
            11000,
            12000,
            13000,
            14000,
            15000,
            16000,
            17000,
            18000,
            19000,
            20000,
            25000,
            30000,
            35000,
            40000,
            45000,
            50000,
            75000,
            100000,
            125000,
            150000,
            200000,
            250000,
            300000,
            350000,
            400000,
            450000,
            500000,
            600000,
            700000,
            800000,
            900000,
            1000000,
            2000000,
            3000000,
            4000000,
            5000000,
            6000000,
            7000000,
            8000000,
            9000000,
            10000000,
            15000000,
            20000000,
            25000000,
            30000000,
            35000000,
            40000000,
            45000000,
            50000000,
            50000000000000,
            500000000000000,
            5000000000000000,
            50000000000000000,
            500000000000000000,
            5000000000000000000,
            50000000000000000000,
            500000000000000000000,
            5000000000000000000000,
            50000000000000000000000,
        ];

        let interval = intervals.find(element => {
            if (target > yAxisTarget) {
                return element > target * 1.1 / 8;
            }
            else {
                return element > yAxisTarget / 8
            }
        });
        if (interval === undefined) {
            interval = 5;
        }
        maxValue =
            maxValue <= target ? parseInt(target) + (interval % target) : parseInt(maxValue) + (interval % maxValue);

        for (let i = 0; i <= maxValue; i += interval) {
            ticks.push(i);
        }

        return (
            <div className="line-item__column-content">
                <div>
                    <div className="reports__data-lost-time-label">
                        <Typography variant={'subtitle2'}>Average Line Speed</Typography>
                    </div>
                    <ResponsiveContainer width="100%" height={220}>
                        <BarChart data={data}>
                            <XAxis dataKey="label" domain={['dataMin', 'dataMax']} interval={0} />
                            <YAxis
                                domain={[0, ticks[ticks.length - 1]]}
                                tick={{ fontSize: 28 }}
                                ticks={ticks}
                                interval={0}
                            />
                            <Tooltip active={true} content={this.speedTooltip} cursor={data.length !== 0} />
                            <ReferenceLine
                                y={target}
                                stroke="#2967c1"
                                strokeDasharray="10 5"
                                strokeWidth={2}
                                ifOverflow="extendDomain"
                            />
                            <Bar dataKey="amt">
                                {data.map((entry, index) => (
                                    <Cell fill={'#6ebd3a'} key={`cell-${index}`} />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );
    };

    speedTooltip = ({ payload }) => {
        let { item } = this.props;
        let speed_unit = item.speed_unit;
        if (speed_unit === undefined) {
            speed_unit = '';
        }

        if (!payload[0] || !payload[0].payload) {
            return '';
        }
        return (
            <div className="tooltip">
                <p className="tooltip__reason">
                    {'Average Speed: ' + parseInt(payload[0].payload.actual) + ' ' + speed_unit}
                </p>
            </div>
        );
    };

    formatHour = time => {
        let d = new Date(time);
        let h = d.getHours();

        if (h < 12) {
            if (h == 0) {
                return '12a';
            }
            return h + 'a';
        } else {
            if (h - 12 == 0) {
                return '12p';
            }
            return h - 12 + 'p';
        }
    };

    linkTarget = e => {
        if (this.props.item.line_status !== 'notrunning') {
            return '/reports?run=' + this.props.item.run_id + '&return=liveview';
        }
        return '#';
    };

    render = () => {
        let { item } = this.props;

        return (
            <div className="line-item__column-item">
                <Link
                    to={this.linkTarget()}
                    style={{ cursor: this.props.item.line_status !== 'notrunning' ? 'pointer' : 'initial' }}
                >
                    <div className="line-item__column-header_wrapper">
                        <LiveViewLineInfoSection line={item} onStartRunClick={this.props.onStartRunClick} />
                    </div>
                </Link>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 3 }}>
                    {this.showDetails()}
                    {this.showAverageLineSpeed()}
                </div>
            </div>
        );
    };
}

export default LineItemColumn;
