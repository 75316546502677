import React, { Component } from 'react';
import { CircularProgress, Button, Fab } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import Modal from '../components/Modal';
import LineDetails from '../components/LineDetails';
import { Link } from 'react-router-dom';
import AddLine from '../modals/AddLine';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ReactDragListView from '../../libs/react-drag-listview';
import EmailedReportsOptions from './EmailedReportsOptions';
import RenameEmailedReportModal from './RenameEmailedReportModal';
import DeleteEmailedReportModal from './DeleteEmailedReportModal';

class EmailedReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            status: null,
            show_modal: false,
            submitting_edit: false,
            submitting_delete: false,
            reportDetails: false,
            reportId: null,
            reportName: '',
            changed: false,
            submitting_update: false,
            updated: false,
            submit_status: null,
            units: null
        };
    }

    componentWillMount() {
        this.setState({
            reports: []
        })
        // this.setState({
        //     reports: []
        // })
        // this.getReportsDaily()
        // this.getReportsWeekly()
        // this.getReportsMonthly()
        // this.getReportsCustom()
        
    }

    componentDidMount() {
        const headerContainer = document.getElementsByClassName("settings__subhead")[0];
        headerContainer.innerHTML = `<h5 style="font-size: 1.875rem" class="MuiTypography-root MuiTypography-h5">Emailed Reports</h5>`
        this.setState({
            reports: []
        })
        this.getReportsDaily()
        this.getReportsWeekly()
        this.getReportsMonthly()
        this.getReportsCustom()
        window.history.pushState('page2', 'Title', '/settings/reports');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.factory !== this.props.factory) {
            this.setState({
                reports: [],
                reportDetails: false,
                status: null,
                show_modal: false,
                submitting_edit: false,
                submitting_delete: false,
                reportId: null,
                reportName: '',
                changed: false,
                submitting_update: false,
                updated: false,
                submit_status: null,
                units: null
            });
            this.setState({
                reports: []
            })
            this.getReportsDaily()
            this.getReportsWeekly()
            this.getReportsMonthly()
            this.getReportsCustom()
        }
    }

    getReportsDaily = () => {
        let params = {
            path: 'get_emailed_reports_daily',
            success: this.fetchReportsSuccess,
            error: this.fetchReportsError
        };

        API.c(params);
    };
    
    getReportsWeekly = () => {
        let params = {
            path: 'get_emailed_reports_weekly',
            success: this.fetchReportsSuccess,
            error: this.fetchReportsError
        };

        API.c(params);
    };

    getReportsMonthly = () => {
        let params = {
            path: 'get_emailed_reports_monthly',
            success: this.fetchReportsSuccess,
            error: this.fetchReportsError
        };

        API.c(params);
    };

    getReportsCustom = () => {
        let params = {
            path: 'get_emailed_reports_custom',
            success: this.fetchReportsSuccess,
            error: this.fetchReportsError
        };

        API.c(params);
    };

    fetchReportsSuccess = data => {
        const newReports = [...this.state.reports, ...data].sort((a, b) => a.name.localeCompare(b.name))
        this.setState({
            reports: newReports,
            status: null
    });
    };

    fetchReportsError = error => {
        this.setState({
            status: error
        });
    };

    showReportsTable = () => {
        if (this.state.reportDetails) {
            return (
                <EmailedReportsOptions
                    reports={this.state.reports}
                    report={this.state.report}
                    typeOfReport={this.state.typeOfReport}
                    hideReportDetails={this.hideReportDetails}
                />
            );
        }

        if (!this.state.reports) {
            return (
                <div className="settings__table">
                    <CircularProgress />
                </div>
            );
        }

        return (
            <div>
                <div className="settings__table">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" style={ {...Styles.tableHeaderStyle(), width: "25%", fontSize: "14px", fontWeight: "500"} }>
                                        Report Name
                                    </TableCell>
                                    <TableCell align="left" style={ {...Styles.tableHeaderStyleShort(), fontSize: "14px", width: "10%", fontWeight: "500"}}>
                                        Last Report
                                    </TableCell>
                                    <TableCell align="left" style={ {...Styles.tableHeaderStyleShort(), fontSize: "14px", width: "15%", fontWeight: "500"}}>
                                        Next Report
                                    </TableCell>
                                    <TableCell align="left" style={ {...Styles.tableHeaderStyleShort(), fontSize: "14px", width: "20%", fontWeight: "500"}}>
                                        Time period
                                    </TableCell>
                                    <TableCell align="left" style={ {...Styles.tableHeaderStyleShort(), fontSize: "14px", fontWeight: "500"}}>
                                        <p>Status</p>
                                    </TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()} />
                                    <TableCell align="right" style={Styles.editDeleteTableHeaderStyle()} />
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                    {this.showReports()}
                </div>
            </div>
        );
    };

    hideReportDetails = modified => {
        this.setState({
            reportDetails: false,
        });

        const headerContainer = document.getElementsByClassName("settings__subhead")[0];
        headerContainer.innerHTML = `<h5 class="MuiTypography-root MuiTypography-h5">Emailed Reports</h5>`

        if (modified) {
            this.modified();
        }

        // this.setState({
        //     reports: []
        // })
        // this.getReportsDaily()
        // this.getReportsWeekly()
        // this.getReportsMonthly()
        // this.getReportsCustom()
    };

    getDateOfPrevNextReport = (date) => {
        if (!date) {
            return ""
        }

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        let displayDate = ""
        const day = new Date(date).getDate()
        const monthIndex = new Date(date).getMonth()
        const monthName = monthNames[monthIndex]
        const year = new Date(date).getFullYear()

        displayDate = `${monthName} ${day}, ${year}`

        return displayDate
    }

    showReports = () => {
        let state = this.state;

        return (
            <TableContainer>
                <Table>
                    <TableBody>
                        {this.state.reports.map((report, index) => {
                            return (
                                <TableRow sx={{ wordBreak: "break-word", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} key={'report-' + report.id + "-" + report.name}>
                                    <TableCell align="left" style={ {...Styles.tableRowColumnStyle(), width: "25%", overflow: "hidden", overflowWrap: "anywhere"} }>
                                        {report.name}
                                    </TableCell>
                                    <TableCell align="left" style={ {...Styles.tableRowColumnStyleShort(), width: "10%" }}>
                                        {this.getDateOfPrevNextReport(report.previous_trigger_at)}
                                    </TableCell>
                                    <TableCell align="left" style={ {...Styles.tableRowColumnStyleShort(), width: "15%"}}>
                                        {report.is_active ? this.getDateOfPrevNextReport(report.next_trigger_at) : ""}
                                    </TableCell>
                                    <TableCell align="left" style={ {...Styles.tableRowColumnStyleShort(), width: "20%", textTransform: "capitalize"}}>
                                        {report.report_type}
                                    </TableCell>
                                    <TableCell align="left" style={Styles.tableRowColumnStyleShort()}>
                                        <p style={{ background: `${report.is_active ? "#1F9D00" : "#BDBDBD"}`, width: "57px", height: "32px", borderRadius: "100px", display: "flex", justifyContent: "center", alignItems: "center", color: "#F2F2F2" }}>{report.is_active ? "Active" : "Pause"}</p>
                                    </TableCell>
                                    <TableCell style={Styles.tableRowColumnStyle()} />
                                    <TableCell align="right" style={Styles.editDeleteTableRowColumnStyle()}>
                                        <div>
                                            <Button
                                                variant="text"
                                                className={'settings-line-table-button'}
                                                color="primary"
                                                onClick={this.openReportDetails.bind(this, report, "edit")}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="text"
                                                className={'settings-line-table-button'}
                                                color="secondary"
                                                onClick={this.showDeleteConfirmation.bind(this, report)}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    openNameModal = (report, typeOfReport) => {
        this.setState({
            show_modal: "create_report",
            report,
            typeOfReport
        })
    }

    onCreateReport = (newName) => {
        const newReport = this.state.report
        newReport.name = newName
        newReport.report_type = "daily"
        newReport.newReport = true
        this.setState({
            show_modal: false,
            reportDetails: true,
            report: newReport
        })
    }

    openReportDetails = (report, typeOfReport) => {
        this.setState({
            reportDetails: true,
            report: report,
            typeOfReport,
        });
    };

    showDeleteConfirmation = report => {
        this.setState({
            show_modal: 'delete_report',
            reportId: report.id,
            reportName: report.name,
            reportType: report.report_type
        });
    };

    showStatus = () => {
        if (this.state.status) {
            return <div>{this.state.status}</div>;
        } else {
            return <div />;
        }
    };

    showActionButton() {
        if (this.state.reportDetails) {
            return;
        }
        return (
            <Fab color="secondary" className="settings_fab" onClick={this.actionButton}>
                <AddOutlined />
            </Fab>
        );
    }

    actionButton = () => {
        const date = new Date();
        const options = { month: 'short', day: "2-digit", year: "numeric" };
        const newReport = { id: Math.floor(Math.random() * 1000), is_active: true, name: "", last_report: '-', next_report: date.toLocaleDateString("en-US", options).toUpperCase(), time_period: "Daily" }
        this.openNameModal(newReport, "new")
    };

    modal() {
        if (this.state.show_modal == false) {
            return <div></div>;
        } else {
            return (
                this.getModal()
            );
        }
    }

    hideModal = () => {
        this.setState({
            show_modal: false,
            submitting_edit: false
        });
    };

    getModal() {
        switch (this.state.show_modal) {
            case 'delete_report':
                return (
                    <DeleteEmailedReportModal
                        reportName={this.state.reportName}
                        hideModal={this.hideModal}
                        modified={this.modified}
                        delete_path={`delete_emailed_report_${this.state.reportType}`}
                        id={this.state.reportId}
                    />
                    // <DeleteConfirmationModal
                    //     report
                    //     hideModal={this.hideModal}
                    //     modified={this.modified}
                    //     item_deleted_text={this.state.reportName}
                    //     delete_path={`delete_emailed_report_${this.state.reportType}`}
                    //     id={this.state.reportId}
                    // />
                );
            case 'create_report': 
                return (
                    <RenameEmailedReportModal
                        type="name"
                        reportName={this.state.report.name}
                        reports={this.state.reports}
                        onSave={(newName) => this.onCreateReport(newName)}
                        onHide={this.hideModal}
                    />
                )
            default:
                return false;
        }
    }

    modified = () => {
        this.setState({
            reports: []
        })
        this.getReportsDaily()
        this.getReportsWeekly()
        this.getReportsMonthly()
        this.getReportsCustom()
    };

    getModalTitle() {
        let sm = this.state.show_modal;

        if (sm == 'delete_report') {
            return 'Delete Report';
        } else {
            return '';
        }
    }

    showSaveButton = () => {
        // if (this.state.changed) {
        //     return (
        //         <div style={{ float: 'right', display: 'inline-table', marginTop: '10px' }}>
        //             {this.updateStatus()}
        //             {this.showButton()}
        //         </div>
        //     );
        // }

        // return <div />;
    };

    showButton = () => {
        // if (!this.state.submitting_update) {
        //     return (
        //         <Button variant="contained" color="primary" onClick={this.submitLinesOrder.bind(this)}>
        //             Update Lines
        //         </Button>
        //     );
        // } else {
        //     return <CircularProgress mode="indeterminate" />;
        // }
    };

    updateStatus = () => {
        // if (this.state.submit_status) {
        //     return <div className="update-status__error">There was an error updating lines order</div>;
        // } else if (this.state.updated) {
        //     return <div className="update-status__success">Update Saved</div>;
        // } else {
        //     return <div />;
        // }
    };

    render() {
        return (
            <div>
                {this.modal()}
                <div className="products" style={{ display: 'flow-root' }}>
                    {this.showActionButton()}
                    {this.showReportsTable()}
                    {this.showSaveButton()}
                    {this.showStatus()}
                </div>
            </div>
        );
    }
}

export default EmailedReports;
