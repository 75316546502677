import React, {Component} from 'react';

class CustomTrendLostTimeYLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */
    trimWords = (word, length) => {
        let nameArr = word.split(' ')
        let arr = []
        arr.push(nameArr[0])
        nameArr.shift()

        nameArr.forEach(name => {
            let newWord = arr[arr.length - 1] + " " + name
            if(newWord.length > length) {
                arr.push(name)
            } else {
                arr.pop()
                arr.push(newWord)
            }
        })

        return arr
    }

    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    render() {
        const {x, y, stroke, payload} = this.props;
        if(payload && payload.value) {
            let nameLines = this.trimWords(payload.value, 30)

            return (
                <g transform={`translate(${x},${payload.coordinate})`}>
                    {nameLines.map((nameLine, index) => {
                        return <text key={"name-" + index} x={0} y={14 * index} dy={0} fontSize="12px" textAnchor="end"
                                     style={{fontFamily: 'Arial'}} fill="#666">{nameLine}</text>
                    })}
                </g>
            );
        }

        return <div/>
    }
}

export default CustomTrendLostTimeYLabel