import React from 'react';
import Button from '@material-ui/core/Button';
const FilterButton = ({ value, handleSelectButton, id, selected, buttonHeight, lineProducts }) => {
    return (
        <Button
            onClick={() => handleSelectButton(id, value)}
            disabled={!lineProducts}
            style={{
                width: 136,
                height: buttonHeight,
                color: selected === id ? '#fff' : '#666',
                zIndex: 3,
                marginBottom: '10px',
                marginRight: '20px',
                backgroundColor: selected === id ? '#111' : '#e8e7e7'
            }}
        >
            {value}
        </Button>
    );
};
export default FilterButton;
