import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ReasonSettingsView from "./ReasonSettingsView";
import { Paper, Tab, Tabs } from "@material-ui/core";
import Products from './Products';
import EmailedReportsDetails from './EmailedReportsDetails';
// import { history } from 'react-router';

class EmailedReportsOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsOption: this.getSettingsOptionView(),
            reasonDetails: false
        }
    }

    /* ######## LIFECYCLE METHODS ######## */
    componentDidMount() {
        document.title = "LiveTracking | Settings";
        if (window.ga) {
            window.ga('set', 'page', '/settings');
            window.ga('send', 'pageview');
        }
        window.history.pushState('page2', 'Title', '/settings/reports/reportdetails');
        this._isMounted = true;
        window.onpopstate = ()=> {
        if(this._isMounted) {
            this.props.hideReportDetails()
        }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.factory !== this.props.factory) {
            this.setState({
                reasonDetails: false
            })
        }
    }

    /* ######## FUNCTIONS ######## */
    getSettingsOptionView = () => {

        let option = 0;
        let p = window.location.pathname.split("/");

        switch (p[4]) {
            case "datetime":
                option = 0;
                break;
            case "data":
                option = 1;
                break;
            case "recipients":
                option = 2;
                break;
            case "history":
                option = 3;
                break;
        }
        return option;

    }

    changeSettingType = (event, id) => {
        this.setState({
            settingsOption: id,
            reasonDetails: false
        })
    }

    onOpenReasonDetails = () => {
        this.setState({
            reasonDetails: true
        })
    }

    hideReasonDetails = () => {
        this.setState({
            reasonDetails: false
        })
    }

    getSettingsOptionClass = (id) => {
        let class_name = 'settings-options__item';
        let selected = ''

        if (this.state.screenWidth < 1190) {
            class_name += '--tablet'
        }

        if (this.state.settingsOption == id) {
            selected += '--selected';
        }

        return class_name + selected;
    }

    onChangeTab = (tab) => {
        this.setState({
            settingsOption: tab
        })
    }

    /* ######## API CALLS ######## */

    /* ######## RENDERS ######## */

    renderChildren = () => {
        let type = "datetime"
        switch (this.state.settingsOption) {
            case 0:
                type = "datetime";
                break;
            case 1:
                type = "data";
                break;
            case 2:
                type = "recipients";
                break;
            case 3:
                type = "history";
                break;
        }

        return <EmailedReportsDetails type={type}
            reports={this.props.reports}
            typeOfReport={this.props.typeOfReport}
            report={this.props.report}
            factory={this.props.factory}
            reasonDetails={this.state.reasonDetails}
            onOpenReasonDetails={this.onOpenReasonDetails}
            onChangeTab={this.onChangeTab}
            hideReportDetails={this.props.hideReportDetails} />
    }

    render() {
        return <div style={{ marginTop: '20px' }}>
            <Paper square>
                <Tabs className="tabs"
                    value={this.state.settingsOption}
                    onChange={this.changeSettingType}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary">
                    <Tab label="Date & Time"
                        component={Link}
                        to={'/settings/reports'}
                        style={{ padding: '20px 12px', textTransform: "unset" }} />
                    <Tab label="Data"
                        component={Link}
                        to={'/settings/reports'}
                        style={{ padding: '20px 12px', textTransform: "unset" }} />
                    <Tab label="Recipients"
                        component={Link}
                        to={'/settings/reports'}
                        style={{ padding: '20px 12px', textTransform: "unset" }} />
                    <Tab label="History"
                        component={Link}
                        to={'/settings/reports'}
                        style={{ padding: '20px 12px', textTransform: "unset" }} />
                </Tabs>
            </Paper>
            <div>
                {this.renderChildren()}
            </div>
        </div>
    }
}

export default EmailedReportsOptions