import React, {Component} from 'react';
import TrendReportTopIssue from "./TrendReportTopIssue";

class TrendReportTrendingTopIssues extends Component {
    constructor(props) {
        super(props);
        this.state = {
            max: this.getMax()
        }
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */
    getMax = () => {
        let maxFour = []

        this.props.topIssue.map(issue => {
            maxFour.push(Math.max.apply(Math, issue.lost_time_info.map(function(o) { return o.lost_time_percent; })))
        })

        return Math.max(...maxFour)
    }

    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    render() {
        return <div className="flex-box__col justify-center" style={{width: "100%", marginTop: "24px"}}>
            <div className="reports__data-lost-time-label">Trending Top Issues</div>
            <div className="flex-box__row justify-space-around">
                <TrendReportTopIssue issue={this.props.topIssue[0]} index={0} hoverIndex={this.props.hoverIndex} max={this.state.max}/>
                <TrendReportTopIssue issue={this.props.topIssue[1]} index={1} hoverIndex={this.props.hoverIndex} max={this.state.max}/>
            </div>
            <div className="flex-box__row justify-space-around">
                <TrendReportTopIssue issue={this.props.topIssue[2]} index={2} hoverIndex={this.props.hoverIndex} max={this.state.max}/>
                <TrendReportTopIssue issue={this.props.topIssue[3]} index={3} hoverIndex={this.props.hoverIndex} max={this.state.max}/>
            </div>
        </div>
    }
}

export default TrendReportTrendingTopIssues