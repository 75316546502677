import React, { Component } from 'react';

class DowntimeChrono extends Component {
    constructor(props) {
        super(props);

        this.state = {
            down_time: "",
            down_time_reason: null,
            line_status: null
        };
    }

    componentDidMount = () => {
        this.refreshInterval = setInterval(() => {

            let elapsed = "";

            // down time
            let down_time_reason = null;
            let lineStatus = null;
            if (this.props.line && this.props.line != null && this.props.line.line_status == 'down') {
                let start_time = new Date(this.props.line.current_event_start_time);
                let current_time = new Date();

                let down_time = current_time.getTime() - start_time.getTime();

                let diff = {};


                diff.milliseconds = current_time - start_time;
                diff.days = (diff.milliseconds / 1000) / 60 / 60 / 24;
                diff.hours = (diff.days - Math.floor(diff.days)) * 24;
                diff.minutes = (diff.hours - Math.floor(diff.hours)) * 60;
                diff.seconds = (diff.minutes - Math.floor(diff.minutes)) * 60;

                elapsed = "";

                if (Math.floor(diff.days) > 0) {
                    let days = diff.days;

                    if (Math.floor(days) < 10) {
                        elapsed += "0";
                    }
                    elapsed += Math.floor(days) + ":";
                }

                if (Math.floor(diff.hours) > 0) {
                    let hours = diff.hours;

                    if (Math.floor(hours) < 10) {
                        elapsed += "0";
                    }
                    elapsed += Math.floor(hours) + ":";
                }

                if (Math.floor(diff.minutes) > 0) {
                    let minutes = diff.minutes;
                    if (Math.floor(minutes) < 10) {
                        elapsed += "0";
                    }
                    elapsed += Math.floor(minutes) + ":";
                } else {
                    elapsed += "00:";
                }

                if (Math.floor(diff.seconds) >= 0) {
                    let seconds = diff.seconds;

                    if (Math.floor(seconds) < 10) {
                        elapsed += "0";
                    }

                    elapsed += Math.floor(seconds);
                }
                down_time_reason = this.props.line.current_event_status;

            } else {
                elapsed = "Running";
                lineStatus = this.props.line.line_status
            }

            this.setState({
                down_time: elapsed,
                down_time_reason: down_time_reason,
                line_status: lineStatus
            });

        }, 1000);
    }

    componentWillUnmount () {
        clearInterval(this.refreshInterval);
    }

    getDownTimeClass = (x = false) => {
        if (this.props.line == null) {
            return 'presentation-mode__data-item--not-running';
        }

        let c = 'presentation-mode__data-item';
        if (this.props.line.line_status == 'notrunning') {
            c += '--not-running';
        } else if (this.state.down_time_reason == null) {
            c += '--disabled';
        } else if (this.state.down_time_reason != null && x == false) {
            c += '--error';
        } else {
            c += '--down';
        }

        return c;
    }

    getDownTimeReason = () => {
        if (!this.props.line) {
            return "DownTime";
        }

        if (this.state.down_time_reason != null) {
            return this.state.down_time_reason;
        } else {
            return this.state.line_status
        }
    }

    render () {
        let item = this.props.line;

        return <div className={this.getDownTimeClass(false)}>
            <div className="presentation-mode__data-item__value">{item.line_status == 'notrunning' ? '- -' : this.state.down_time}</div>
            <div className="presentation-mode__data-item__title">{this.getDownTimeReason()}</div>
        </div>

    }

}

export default DowntimeChrono;