import React, { Component } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { Typography } from "@material-ui/core";
import SideNav from '../components/SideNav';
import Footer from "../components/Footer";
import HeaderTopNav from "../components/HeaderTopNav";
import { minimumRole } from "../components/PermissionsHelper";
import Products from "./Products";
import ProductDetail from "../components/settings/products/ProductDetail";
import FactoryContainer from "../components/settings/factory/FactoryContainer";
import LinesSettings from "./LinesSettings";
import ReasonsSettingOptions from "./ReasonsSettingOptions";
import DowntimeExport from "./DowntimeExport";
import NotificationsSettings from "../components/settings/alerts/NotificationsSettings";
import ShiftsSettings from "./settings/shift/ShiftsSettings";
import NotificationDetail from '../components/settings/alerts/NotificationDetail';
import ProductsOptions from './ProductsOptions';
import Changelog from './Changelog';
import NotAllowed from './NotAllowed';
import EmailedReports from './EmailedReports';
import ImportExport from './ImportExport';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: true,
            factory: {}
        }
        
    }

    componentDidMount() {
        document.title = "LiveTracking | Settings";
        if (window.ga) {
            window.ga('set', 'page', '/settings');
            window.ga('send', 'pageview');
        }
        this.setState({
            user_role: JSON.parse(window.localStorage.getItem('factory')).role
        })
    }

    getFactorySettings = () => {
        let params = {
            path: 'get_factory_settings',
            success: (data) => this.onGetFactorySettingsSuccess(data),
            error: () => {}
        };

        API.c(params, 2);
    };

    onGetFactorySettingsSuccess = (data) => {
        localStorage.setItem("efficiency_string_long", data.efficiency_string_long)
        localStorage.setItem("efficiency_string_short", data.efficiency_string_short)
    }

    onCollapseClickChange = (collapse) => {
        this.setState({ collapse })
    }

    getTitle = () => {
        switch (window.location.pathname.split("/")[2]) {
            case "products":
                return "Product Settings"
            case "factory":
                return "Factory Settings"
            case "lines":
                return "Line Settings"
            case "reasons":
                return "Reason Settings"
            case "shifts":
                return "Shift Settings"
            case "automated-reports":
                return "Reports"
            case "notifications":
                return "Notifications"
            case "changelog":
                return "Changelog"
            case "reports":
                return "Emailed Reports"
            default:
                return "User Management"
        }
    };

    render() {
        if (!User.loggedIn()) {
            return <div />;
        }

        // if (!minimumRole("factory_admin")) {
        //     return <Redirect to={{
        //         pathname: '/liveview'
        //     }} />
        // }

        let class_name = this.state.collapse ? "card--collapse" : "card";
        let header_class_name = this.state.collapse ? "header header--collapse" : "header"

        if (this.state.user_role === "dosom" || this.state.user_role === "external_developer" || this.state.user_role === "account_owner") {
            return <div className="container">
                <HeaderTopNav className={header_class_name} onFactoryChange={(factory) => { this.setState({ factory }) }} />
                <SideNav
                    onShowScoreboardClicked={() => { }}
                    onCollapseClick={this.onCollapseClickChange}
                    title="Settings"
                />
                <div className="main_content_wrapper">
                    <div className={class_name}>
                        <div className="settings__subhead">
                            <Typography variant='h5'>{this.getTitle()}</Typography>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>;
        }

        else {
            let header_class_name = this.state.collapse ? "header header--collapse" : "header"
            return (
                <div className="container">
                    <HeaderTopNav className={header_class_name} onFactoryChange={(factory) => { this.setState({ factory }) }} />
                    <SideNav
                        onShowScoreboardClicked={() => { }}
                        onCollapseClick={this.onCollapseClickChange}
                        title="Settings"
                    />
                    <div className="main_content_wrapper">
                        <div className={class_name}>
                            <div className="settings__subhead">
                                <Typography variant='h5'>{this.getTitle()}</Typography>
                            </div>
                            <div className="settings__body">
                                <Switch>
                                    {this.state.user_role === "scoreboard" || this.state.user_role === "factory_read_only" ? (
                                        <Route path="/settings/*" exact render={() => <NotAllowed />} />
                                    ) : (
                                        <>
                                            <Route path="/settings/products" exact render={(props) => <ProductsOptions {...props} factory={this.state.factory} />} />
                                            <Route path="/settings/products/:id" exact render={(props) => <ProductDetail {...props} factory={this.state.factory} />} />
                                            {this.state.user_role !== "floor_team" ? (
                                                <>
                                                    <Route path="/settings/factory" render={(props) => <FactoryContainer {...props} factory={this.state.factory} />} />
                                                    <Route path="/settings/lines" render={(props) => <LinesSettings {...props} factory={this.state.factory} />} />
                                                    <Route path="/settings/reasons" render={(props) => <ReasonsSettingOptions {...props} factory={this.state.factory} />} />
                                                    <Route path="/settings/shifts" render={(props) => <ShiftsSettings {...props} factory={this.state.factory} />} />
                                                    <Route path="/settings/export" render={(props) => <DowntimeExport {...props} factory={this.state.factory} />} />
                                                    <Route path="/settings/reports" render={(props) => <EmailedReports {...props} factory={this.state.factory} />} />
                                                    <Route path="/settings/changelog" render={(props) => <Changelog {...props} factory={this.state.factory} />} />
                                                    <Route path="/settings/automated-reports" render={(props) => <DowntimeExport {...props} factory={this.state.factory} />} />
                                                    <Route path="/settings/import-export" render={(props) => <ImportExport {...props} factory={this.state.factory} />} />
                                                    <Route path="/settings/notifications" render={(props) => <NotificationsSettings {...props} factory={this.state.factory}/>}/>
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                    {/*<Route path="/settings/notifications" render={(props) => <NotificationsSettings {...props} factory={this.state.factory}/>}/>*/}
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Settings;
