import React, {Component} from 'react';
import {
    Bar,
    BarChart,
    Cell,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    ComposedChart,
    Line, Legend, LabelList
} from "recharts";
import CustomDiagonalAxisTick from "../../CustomDiagonalAxisTick";
import CustomTrendOEETooltip from "../../RechartCustomComponents/CustomTrendOEETooltip";
import CustomOverLimitLabel from "../../RechartCustomComponents/CustomOverLimitLabel";
import CustomValueLabel from "../../RechartCustomComponents/CustomValueLabel";
import YAxisLabel from "../../RechartCustomComponents/YAxisLabel";
import ReferenceLineLabel from "../../RechartCustomComponents/ReferenceLineLabel";

Date.prototype.getWeek = function (dowOffset) {
    /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

    dowOffset = typeof(dowOffset) === 'int' ? dowOffset : 0; //default dowOffset to zero
    let newYear = new Date(this.getFullYear(),0,1);
    let day = newYear.getDay() - dowOffset; //the day of week the year begins on
    day = (day >= 0 ? day : day + 7);
    let daynum = Math.floor((this.getTime() - newYear.getTime() -
        (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
    let weeknum;
    //if the year starts before the middle of a week
    if(day < 4) {
        weeknum = Math.floor((daynum+day-1)/7) + 1;
        if(weeknum > 52) {
            let nYear = new Date(this.getFullYear() + 1,0,1);
            let nday = nYear.getDay() - dowOffset;
            nday = nday >= 0 ? nday : nday + 7;
            /*if the next year starts before the middle of
              the week, it is week #1 of that year*/
            weeknum = nday < 4 ? 1 : 53;
        }
    }
    else {
        weeknum = Math.floor((daynum+day-1)/7);
    }
    return weeknum;
};

class TrendReportOEE extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */

    componentDidMount() {
        this.getFactorySettings()
    }


    /* -------- FUNCTIONS -------- */


    /* -------- API CALLS -------- */

    getFactorySettings = () => {
        let params = {
            path: 'get_factory_settings',
            success: (data) => this.setState({data}),
            error: () => {}
        };

        API.c(params, 2);
    };

    /* -------- RENDERS -------- */

    showTitle = () => {
        return <div className="reports__data-lost-time-label">{localStorage.getItem("efficiency_string_short") !== 'undefined' ? localStorage.getItem("efficiency_string_short") : "OEE"} by Week</div>
    }

    showOEEChart = () => {
        let { oee } = this.props;

        let data = []
        if(oee.length > 0) {

            let length = oee.length;

            let twoYears = false
            let yearOne = oee[0].year
            let yearTwo = oee[length - 1].year

            let newYearIndex = length
            if (yearOne !== yearTwo) {
                twoYears = true
                newYearIndex = oee.findIndex(item => item.year !== yearOne)
            }

            oee.map((item, index) => {
                data.push({
                    xAxisTick: 'wk ' + ("0" + item.week_number).substr(-2, 2),
                    label: item.week_range_display,
                    amt: Math.floor(item.efficiency * 100) / 100,
                    trend: item.previous_four_week_average
                })
            })

            let maxY = 100

            return <div style={{width: "100%"}}>
                <ResponsiveContainer width="100%" height={420}>
                    <ComposedChart data={data} syncId={"trendReport"}>
                        <defs>
                            <linearGradient id="colorGreenOEE" gradientTransform="rotate(90)">
                                <stop offset="5%" stopColor="#43a047" stopOpacity={0.65}/>
                                <stop offset="95%" stopColor="#43a047" stopOpacity={0.65}/>
                            </linearGradient>
                        </defs>
                        <Tooltip active={true} content={<CustomTrendOEETooltip data={this.state.data} />}/>
                        <Bar dataKey='amt' fill={"url(#colorGreenOEE)"} isAnimationActive={false}>
                            <LabelList content={<CustomOverLimitLabel max={maxY}/>}/>
                        </Bar>
                        <Line dataKey='trend' type="linear" strokeWidth="2" dot={false}/>
                        <XAxis dataKey='xAxisTick' tick={<CustomDiagonalAxisTick/>} domain={['dataMin', 'dataMax']}
                               interval={0}/>
                        <YAxis domain={[0, maxY]} tick={<CustomValueLabel anchor="end" textX={-2} textDy={4}/>}
                               allowDataOverflow={true} label={<YAxisLabel text={"Percentage (%)"} vertical/>}/>
                    </ComposedChart>
                </ResponsiveContainer>
                <div style={{width: "calc(100% - 60px)", marginLeft: "60px", display: "flex"}}>
                    <div className={"flex-box__row justify-start chart-year-axis"}
                         style={{width: ((newYearIndex / length) * 100) + "%"}}>{yearOne}</div>
                    {twoYears && <div className={"flex-box__row justify-start chart-year-axis"}
                                      style={{width: (((length - newYearIndex) / length) * 100) + "%"}}>{yearTwo}</div>}
                </div>
            </div>
        }
        else {
            data.push({
                xAxisTick: 'wk 00',
                label: '',
                amt: 0,
                trend: 0
            })


            return <div style={{width: "100%"}}>
                <ResponsiveContainer width="100%" height={420}>
                    <BarChart data={data} syncId={"trendReport"}>
                        <ReferenceLine y={50} label={<ReferenceLineLabel label={"No OEE data captured"}/>} className="no-data" stroke="#fff" />
                        <XAxis dataKey='xAxisTick' tick={<CustomDiagonalAxisTick/>} domain={['dataMin', 'dataMax']}
                               interval={0}/>
                        <YAxis domain={[0, 100]} tick={<CustomValueLabel anchor="end" textX={-2} textDy={4}/>}
                               allowDataOverflow={true} label={<YAxisLabel text={"Percentage (%)"} vertical/>}/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        }
    }

    render() {
        return <div className="flex-box__col" style={{width: "100%"}}>
            {this.showTitle()}
            {this.showOEEChart()}
        </div>
    }
}

export default TrendReportOEE