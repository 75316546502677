import React, {Component} from 'react';
import {List, ListItem} from "material-ui";

class UserManagementDeleteUserConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */
    getFactoryRole = (r) => {
        let role = ""
        switch (r) {
            case "factory_supervisor":
                role = "Supervisor"
                break;
            case "factory_admin":
                role = "Admin"
                break;
            case "factory_read_only":
                role = "User - Read Only"
                break;
            case "organization_owner":
                role = "Organization Owner"
                break;
            default:
                role = r;
        }

        return role;
    }

    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    render() {
        let user_first_name = this.props.user.email;
        let user_last_name = "";

        if (this.props.user.user_metadata) {
            if(this.props.user.user_metadata.first_name) {
                user_first_name = this.props.user.user_metadata.first_name
            }

            if(this.props.user.user_metadata.last_name) {
                user_last_name = this.props.user.user_metadata.last_name
            }
        }
        return <div className="user-management-confirmation--body">
            <div className="user-management-confirmation--sub-header">
                Are you sure you want to revoke {user_first_name + " " + user_last_name} access to {this.props.organization}?
            </div>
            <div>
                {user_first_name} will no longer have access to the following factories:
            </div>
            <List className="user-management-confirmation--list">
                {this.props.factories.map(factory => {
                    if(factory.is_active) {
                        return <ListItem primaryText={factory.factory_name}
                                         secondaryText={this.getFactoryRole(factory.role)}
                                         key={factory.factory_id}/>
                    }
                })}
            </List>
        </div>
    }
}

export default UserManagementDeleteUserConfirmation