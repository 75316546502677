import React, {Component} from 'react';
import {
    CircularProgress,
    FlatButton,
    FontIcon,
    Table,
    TableBody,
    TableHeader,
    TableRow,
    TableRowColumn
} from "material-ui";
import axios from 'axios'

class Hubs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hubs: null,
            error: null,
            sort: 0
        }
    }

    /* -------- LIFECYCLE METHODS -------- */
    componentDidMount() {
        this.getHubInfo()
    }


    /* -------- FUNCTIONS -------- */

    compare = (a, b) => {

        switch(this.state.sort) {
            case 0:
                if (a.name < b.name) {
                    return 1;
                } else if (a.name > b.name) {
                    return -1;
                } else {
                    return 0;
                }
            case 1:
                if (a.name < b.name) {
                    return -1;
                } else if (a.name > b.name) {
                    return 1;
                } else {
                    return 0;
                }
            case 2:
                if (a.url < b.url) {
                    return 1;
                } else if (a.url > b.url) {
                    return -1;
                } else {
                    return 0;
                }
            case 3:
                if (a.url < b.url) {
                    return -1;
                } else if (a.url > b.url) {
                    return 1;
                } else {
                    return 0;
                }
        }

    }


    sort = (sortOn) => {
        let sort = 0;
        switch(sortOn) {
            case "name":
                if(this.state.sort === 0) {
                    sort = 1
                } else {
                    sort = 0
                }
                break;
            case "url":
                if(this.state.sort === 2) {
                    sort = 3
                } else {
                    sort = 2
                }
                break;
        }

        this.setState({
            sort: sort
        })
    }

    getSortIcon = (sortOn) => {
        let className = "zmdi zmdi-sort-amount-desc";

        if(sortOn === "name") {
            switch (this.state.sort) {
                case 0:
                    className = "zmdi zmdi-sort-amount-desc bold";
                    break;
                case 1:
                    className = "zmdi zmdi-sort-amount-asc bold";
                    break;
            }
        }

        if(sortOn === "url") {
            switch (this.state.sort) {
                case 2:
                    className = "zmdi zmdi-sort-amount-desc bold";
                    break;
                case 3:
                    className = "zmdi zmdi-sort-amount-asc bold";
                    break;
            }
        }

        return className;
    }

    /* -------- API CALLS -------- */
    getHubInfo = () => {
        axios.get('https://targaryenlivetracking.azurewebsites.net/device/alldata')
            .then(res => this.onGetHubInfoSuccess(res.data))
            .catch(err => this.onGetHubInfoError(err.error))
    }

    onGetHubInfoSuccess = (data) => {
        this.setState({
            hubs: data
        })
    }

    onGetHubInfoError = (error) => {
        this.setState({
            error: error
        })
    }

    /* -------- RENDERS -------- */
    renderTable = () => {

        if(this.state.hubs) {
            return <div className="settings__table">
                <Table
                    style={{overflow: 'scroll'}}
                    wrapperStyle={{overflow: 'scroll'}}
                    bodyStyle={{width: 'fit-content'}}
                    selectable={false}
                    multiSelectable={false}>
                    <TableHeader
                        displaySelectAll={false}
                        adjustForCheckbox={false}
                        enableSelectAll={false}>
                        <TableRow style={{height: '20px', paddingTop: '20px', whiteSpace: 'pre-wrap !important'}}>
                            <TableRowColumn style={Styles.tableHeaderStyleShortWrap()}>
                                <div onClick={this.sort.bind(this, "name")}>
                                    Name
                                    <FontIcon style={{fontSize: "14px", paddingTop: "8px", paddingBottom: "8px", marginLeft: "4px"}} className={this.getSortIcon("name")}/>
                                </div>
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableHeaderStyleShortWrap()}>Line ID</TableRowColumn>
                            <TableRowColumn style={Styles.tableHeaderStyleShortWrap()}>Line Status</TableRowColumn>
                            <TableRowColumn style={Styles.tableHeaderStyleShortWrap()}>Downtime Threshold (Short
                                Stop)</TableRowColumn>
                            <TableRowColumn style={Styles.tableHeaderStyleShortWrap()}>Speed Threshold</TableRowColumn>
                            <TableRowColumn style={Styles.tableHeaderStyleShortWrap()}>Speed Interval
                                Time</TableRowColumn>
                            <TableRowColumn style={Styles.tableHeaderStyleShortWrap()}>Speed Post
                                Interval</TableRowColumn>
                            <TableRowColumn style={Styles.tableHeaderStyleShortWrap()}>Count Post
                                Interval</TableRowColumn>
                            <TableRowColumn style={Styles.tableHeaderStyleShortWrap()}>Last Ping Time (device
                                time)</TableRowColumn>
                            <TableRowColumn style={Styles.tableHeaderStyleShortWrap()}>Network IP
                                Address</TableRowColumn>
                            <TableRowColumn style={Styles.tableHeaderStyle()}>
                                <div onClick={this.sort.bind(this, "url")}>
                                    URL
                                    <FontIcon style={{fontSize: "14px", paddingTop: "8px", paddingBottom: "8px", marginLeft: "4px"}} className={this.getSortIcon("name")}/>
                                </div>
                            </TableRowColumn>
                        </TableRow>
                    </TableHeader>
                    {this.renderTableBody()}
                </Table>
            </div>
        }

        return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '400px'}}>
            <CircularProgress/>
        </div>
    }

    renderTableBody = () => {
        let hubs = this.state.hubs;

        hubs.sort(this.compare)

        return <TableBody displayRowCheckbox={false}>
            {this.state.hubs.map((hub, index) => {
                return <TableRow
                    style={Styles.tableRowStyle(index)}
                    key={"line-"+hub.name}>
                    <TableRowColumn style={Styles.tableRowColumnStyleShort()}>{hub.name}</TableRowColumn>
                    <TableRowColumn style={Styles.tableRowColumnStyleShort()}>{hub.line_id}</TableRowColumn>
                    <TableRowColumn style={Styles.tableRowColumnStyleShort()}>{hub.line_status}</TableRowColumn>
                    <TableRowColumn style={Styles.tableRowColumnStyleShort()}>{hub.downtime_threshold}</TableRowColumn>
                    <TableRowColumn style={Styles.tableRowColumnStyleShort()}>{hub.speed_threshold}</TableRowColumn>
                    <TableRowColumn style={Styles.tableRowColumnStyleShort()}>{hub.speed_interval_time}</TableRowColumn>
                    <TableRowColumn style={Styles.tableRowColumnStyleShort()}>{hub.speed_post_interval}</TableRowColumn>
                    <TableRowColumn style={Styles.tableRowColumnStyleShort()}>{hub.count_post_interval}</TableRowColumn>
                    <TableRowColumn style={Styles.tableRowColumnStyleShortWrap()}>{Util.formatFullDateTime(hub.last_ping_time_utc)}</TableRowColumn>
                    <TableRowColumn style={Styles.tableRowColumnStyleShort()}>{hub.network_ip_add}</TableRowColumn>
                    <TableRowColumn style={Styles.tableRowColumnStyleWrap()}>{hub.url}</TableRowColumn>
                </TableRow>
            })}

        </TableBody>
    }


    render() {
        if(window.location.hostname.includes("localhost") || window.location.hostname.includes("arryn")) {
            return <div>
                <div className="reports--collapse">
                    <div className="settings__subhead">
                        <div className="header__subhead-text">
                            HUBS
                        </div>
                    </div>
                    <div className="settings__body">
                        <div className="products">
                            {this.renderTable()}
                        </div>
                    </div>
                </div>
            </div>
        } else {
            window.location.pathname = "/liveview";
        }

    }
}

export default Hubs