import React, {Component} from 'react';
import {Bar, BarChart, CartesianGrid, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import CustomTrendLostTimeTooltip from "../../RechartCustomComponents/CustomTrendLostTimeTooltip";
import CustomTrendLostTimeYLabel from "../../RechartCustomComponents/CustomTrendLostTimeYLabel";
import CustomValueLabel from "../../RechartCustomComponents/CustomValueLabel";
import CustomLostTimeBarLabel from "../../RechartCustomComponents/CustomLostTimeBarLabel";
import YAxisLabel from "../../RechartCustomComponents/YAxisLabel";
import ReferenceLineLabel from "../../RechartCustomComponents/ReferenceLineLabel";

class TrendReportLostTime extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */
    getShortReason = (reason) => {
        let r = reason

        if (reason && reason.length > 25) {
            r = reason.substr(0,22)
            r += "...";
        }

        return r;
    }

    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    showTitle = () => {
        return <div className="reports__data-lost-time-label">Lost Time (4 Week Average)</div>
    }

    showLostTimePareto = () => {
        let {four_week_lost_time} = this.props

        let data = [];

        let unreportedBar = null
        let unreportedBarInfo = four_week_lost_time.find((lost_time) => {
            return lost_time.reason_name === "Unrecorded";
        })

        if(unreportedBarInfo) {
            unreportedBar = {
                name: this.props.pareto_bars + 1,
                reason: "Unreported",
                short: this.getShortReason("Unreported"),
                time_lost: parseFloat(unreportedBarInfo.lost_time_percent).toFixed(1),
                time_lost_seconds: unreportedBarInfo.lost_time_seconds,
                amt: (Math.floor(unreportedBarInfo.lost_time_percent * 1000) / 1000)
            }
        }

        //Limit pareto bars to 15
        for(let i = 0; i < this.props.pareto_bars; i++) {
            let item = four_week_lost_time[i];

            if(item && item.reason !== "Unrecorded") {
                data.push({
                    name: i + 1,
                    id: item.reason_id,
                    reason: item.reason_name,
                    short: this.getShortReason(item.reason_name),
                    time_lost: parseFloat(item.lost_time_percent).toFixed(1),
                    time_lost_seconds: item.lost_time_seconds,
                    amt: (Math.floor(item.lost_time_percent * 1000) / 1000)
                })
            }
        }

        if(unreportedBar) {
            data.push(unreportedBar)
        }

        if(data.length === 0) {
            data.push(
                {
                    name: 1,
                    id: 1,
                    reason: "1",
                    short: "",
                    time_lost: 0,
                    time_lost_seconds: 0,
                    amt: 0
                },
                {
                    name: 2,
                    id: 2,
                    reason: "2",
                    short: "",
                    time_lost: 0,
                    time_lost_seconds: 0,
                    amt: 0
                },
                {
                    name: 3,
                    id: 3,
                    reason: "3",
                    short: "",
                    time_lost: 0,
                    time_lost_seconds: 0,
                    amt: 0
                },
                {
                    name: 4,
                    id: 4,
                    reason: "4",
                    short: "",
                    time_lost: 0,
                    time_lost_seconds: 0,
                    amt: 0
                },
                {
                    name: 5,
                    id: 5,
                    reason: "5",
                    short: "",
                    time_lost: 0,
                    time_lost_seconds: 0,
                    amt: 0
                }
            )

            return <div style={{width: "100%", marginRight: "8px"}}>
                <ResponsiveContainer width="100%" height={460}>
                    <BarChart data={data}>
                        <ReferenceLine y={3} label={<ReferenceLineLabel label={"No lost time reasons captured for last 4 weeks"}/>} className="no-data" stroke="#fff" />
                        <XAxis type="number" tick={<CustomValueLabel anchor="middle" textX={0} textDy={0}/>} orientation="top" tickLine={false} domain={[0, 28]} label={<YAxisLabel text="% of Total" offsetX={-170}/>}/>
                        <YAxis type="category" dataKey="reason" tick={false} interval={0} tickLine={false}/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        }

        return <div style={{width: "100%", marginRight: "8px"}}>
            <ResponsiveContainer width="100%" height={460}>
                <BarChart layout={'vertical'} data={data} barCategoryGap={4} barSize={24} onClick={this.props.onBarClick} >
                    <defs>
                        <linearGradient id="colorRed" gradientTransform="rotate(90)">
                            <stop offset="5%"  stopColor="#cb2d3e" />
                            <stop offset="95%" stopColor="#cb2d3e"/>
                        </linearGradient>
                        <linearGradient id="colorGreen" gradientTransform="rotate(90)">
                            <stop offset="5%" stopColor="#43a047" stopOpacity={0.95}/>
                            <stop offset="95%" stopColor="#43a047" stopOpacity={0.95}/>
                        </linearGradient>
                    </defs>
                    <XAxis type="number" tick={<CustomValueLabel anchor="middle" textX={0} textDy={0}/>} orientation="top" tickLine={false} label={<YAxisLabel text="% of Total" offsetX={30}/>}/>
                    <YAxis dataKey="reason" tick={<CustomTrendLostTimeYLabel/>} interval={0} type="category" tickLine={false} width={200}/>
                    <Tooltip content={<CustomTrendLostTimeTooltip/>} cursor={data.length !== 0} active/>
                    <Bar dataKey="amt" label={<CustomLostTimeBarLabel/>} isAnimationActive={false} >
                        {
                            data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={data[index].amt < 0 ? "url(#colorGreen)" : "url(#colorRed)"}/>
                            ))
                        }
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    }

    render() {
        return <div className="flex-box__col" style={{width: "100%"}}>
            {this.showTitle()}
            {this.showLostTimePareto()}
        </div>
    }
}

export default TrendReportLostTime