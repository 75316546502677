import React, {Component} from 'react';
import {SvgIcon} from "material-ui";

class CustomOverLimitLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */


    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    render() {
        const {x, y, width, height, value, max, viewBox} = this.props;
        if(value >= max) {
            //Material Chevron Up SVG path
            return <SvgIcon transform={`translate(${(x)}, 0)`} x={x} y={4} dy={0} height={width} width={width}>
                <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/>
                <path d="M0 0h24v24H0z" fill="none"/>
            </SvgIcon>
        }

        return <div/>
    }
}

export default CustomOverLimitLabel