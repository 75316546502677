import React, { Component } from 'react';

class CustomEfficiencyToolTip extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }


  showEfficiency = () => {
    const { payload, output, unit } = this.props;
    let data = payload[0].payload;

      return output ?
          `Output: ${Math.floor(data.amt*100)/100} ${unit}`:
          `${Math.floor(data.amt*100)/100} %`;
  }


  showOutputPerManhour = () => {
    const { payload } = this.props;
    let data = payload[0].payload;

    return `Output Per Manhour: ${Math.floor(data.total*100)/100}`;
  }


  render() {
    const { active } = this.props;

    if (active) {
      const { payload, label } = this.props;

      if (payload === null || payload[0].payload.amt === 0){
        return null;
      }

      return (
        <div className="tooltip">
          <p className="tooltip__reason">{payload[0].payload.label}</p>
          <p className="tooltip__time-lost">{this.showEfficiency()}</p>
          {/*<p className="tooltip__time-lost">{this.showOutputPerManhour()}</p>*/}
        </div>
      );
    }

    return null;
  }
};

export default CustomEfficiencyToolTip;