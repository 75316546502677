import React, { Component } from 'react';
import Modal from '../components/Modal';
import { CircularProgress } from '@material-ui/core';

class LostTimeDrillDownModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            data: null,
            max_duration: 1
        };
    }

    /* -------- LIFECYCLE METHODS -------- */
    componentDidMount() {
        this.getReasonRuns();
    }

    /* -------- FUNCTIONS -------- */
    getLostTime = r => {
        let lost_time = 0;

        switch (r.downtime_reason_name) {
            case 'Slow Running':
                lost_time = r.total_slow_running_seconds;
                break;
            case 'Waste':
                lost_time = r.total_waste_seconds;
                break;
            case 'Rework':
                lost_time = r.total_rework_seconds;
                break;
            default:
                lost_time = r.total_reason_downtime_seconds;
                break;
        }

        return lost_time;
    };

    /* -------- API CALLS -------- */
    getReasonRuns = () => {
        let params = {
            path: 'get_reason_runs',
            data: {
                reason_id: this.props.reason_id,
                run_id: this.props.runs
            },
            success: this.onGetReasonRunsSuccess,
            error: this.onGetReasonRunsError
        };

        API.c(params, 2);
    };

    onGetReasonRunsSuccess = data => {
        let info = [...data];
        info.sort((a, b) => {
            return this.getLostTime(b) - this.getLostTime(a);
        });

        let arr = [];
        info.forEach(e => {
            arr.push(this.getLostTime(e));
        });

        let max = arr.reduce(function (a, b) {
            return Math.max(a, b);
        });

        this.setState({
            loading: false,
            data: info,
            max_duration: max
        });
    };

    onGetReasonRunsError = err => {
        this.setState({
            loading: false,
            error: 'Error loading runs with selected lost time reason'
        });
    };

    /* -------- RENDERS -------- */
    renderModalBody = () => {
        if (this.state.loading) {
            return (
                <div
                    style={{
                        display: 'flex',
                        maxHeight: '366px',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress />
                </div>
            );
        }

        if (this.state.error) {
            return (
                <div
                    style={{
                        display: 'flex',
                        maxHeight: '366px',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {this.state.error}
                </div>
            );
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '366px',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '4px'
                }}
            >
                {this.renderRuns()}
            </div>
        );
    };

    renderLostTimeBar = r => {
        let duration = 0;

        switch (r.downtime_reason_name) {
            case 'Slow Running':
                duration = r.total_slow_running_seconds;
                break;
            case 'Waste':
                duration = r.total_waste_seconds;
                break;
            case 'Rework':
                duration = r.total_rework_seconds;
                break;
            default:
                duration = r.total_reason_downtime_seconds;
                break;
        }

        let { max_duration } = this.state;
        let width = (Math.abs(duration) / max_duration) * 100;

        return (
            <div style={{ width: width + '%', height: '24px', background: duration >= 0 ? '#CB2D3E' : '#A5CF9B' }}>
                &nbsp;
            </div>
        );
    };

    renderRuns = () => {
        let data = [];

        if (this.state.data && this.state.data.length > 0) {
            this.state.data.forEach(r => {
                if (r.downtime_reason_name === this.props.reason) {
                    data.push(r);
                }
            });
        }

        if (data.length === 0) {
            return 'No Runs Available';
        }

        return (
            <div style={{ width: '816px', maxHeight: '366px' }}>
                <div className="drill-down-table-header">
                    <div style={{ marginRight: '4px', width: '196px' }}>Date and Time</div>
                    <div style={{ marginRight: '4px', width: '192px' }}>Line</div>
                    <div style={{ marginRight: '4px', width: '192px' }}>Product</div>
                    <div style={{ width: '200px' }}>Lost Time</div>
                </div>
                <div style={{ width: '100%', maxHeight: '272px', overflowY: 'auto' }}>
                    {data.map(r => {
                        return (
                            <div
                                key={r.run_id}
                                onClick={this.props.onRowClick.bind(this, r)}
                                className="drill-down-row"
                            >
                                <div
                                    style={{
                                        marginRight: '4px',
                                        width: '196px',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <div style={{ marginBottom: '4px' }}>{Util.formatDateFull(r.run_start_time)}</div>
                                    <div>{Util.formatHourMinuteAmPm(r.run_start_time)}</div>
                                </div>
                                <div style={{ marginRight: '4px', width: '192px' }}>{r.line_name}</div>
                                <div
                                    style={{
                                        marginRight: '4px',
                                        width: '192px',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <div style={{ marginBottom: '4px' }}>{r.product_category}</div>
                                    <div>{r.product_desc}</div>
                                </div>
                                <div style={{ width: '200px', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginRight: '4px' }}>
                                        {Util.formatHourMinuteSecondsFromSeconds(this.getLostTime(r))}
                                    </div>
                                    <div style={{ flex: 1 }}>{this.renderLostTimeBar(r)}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    render() {
        let category = this.props.downtime_category ? this.props.downtime_category + ' - ' : '';
        return (
            <Modal
                title={'Downtime Details: ' + category + this.props.reason}
                hideModal={this.props.hideModal}
                class_name={'__reason_drill_down'}
            >
                {this.renderModalBody()}
            </Modal>
        );
    }
}

export default LostTimeDrillDownModal;
