/* USE THIS COMPONENT FOR THE MODAL BODY
<DeleteConfirmationModal hideModal={function to hide the modal} modified={function to update the page on delete success}
                         item_deleted_text={"name of item to be deleted"}
                         delete_path="API path" id={id of item to be deleted}/> */

import React, { Component } from 'react';

import {Button, FormControlLabel, Checkbox, CircularProgress} from "@material-ui/core";

class DeleteConfirmationModal extends Component {
    constructor (props) {
        super(props);
        this.state = {
            delete_confirmed: false,
            submitting_delete: false,
            item_deleted_text: this.props.item_deleted_text,
            delete_status: null,
            archive: props.archive
        };
    }

    /* ######## Lifecycle Methods ######## */
    componentDidMount = () => {
        this.setKeyListener();
    }

    componentWillUnmount = () => {
        this.cancelKeyListener();
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            if (event.keyCode === 13) {
                this.submitDelete()
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }



    deleteText () {
        let _delete = "delete";
        if (this.state.archive) {
            _delete = "archive"
        }
        return <div className="delete-user__text">
            Are you sure you want to {_delete + " " + this.state.item_deleted_text}?
        </div>;
    }


    hideModal = () => {
        this.props.hideModal();
    }


    validateInputs () {
        let error_free = true;
        this.setState({
            confirm_error: null,
        });

        if (this.state.delete_confirmed == false) {
            error_free = false;
        }

        return error_free;
    }


    submitDelete = () => {
        if (this.validateInputs() == false) {
            return false;
        }

        this.setState({
            submitting_delete: true,
            delete_status: null
        })
        let params
        if (this.props.report) {
            params = {
                path: this.props.delete_path,
                path_variables: {
                    REPORT_ID: this.props.id
                },
                success: this.deleteSuccess,
                error: this.deleteError
            }
        }
        else {
            params = {
                path: this.props.delete_path,
                path_variables: {
                    ID: this.props.id
                },
                success: this.deleteSuccess,
                error: this.deleteError
            }
        }

        API.c(params);

    }


    buttonStyle () {
        return {
            marginLeft: "16px"
        }
    }


    deleteSuccess = (data) => {
        this.setState({
            submitting_delete: false,
            delete_error: null,
        });

        this.props.modified();
        this.hideModal();
    }


    deleteError = (error) => {
        let _delete = "Delete";
        if (this.state.archive) {
            _delete = "Archive"
        }
        this.setState({
            submitting_delete: false,
            delete_error: "Couldn't Complete The " + _delete + " Request!"
        })
    }


    deleteButtons () {
        if (this.state.submitting_delete == false) {
            return <div className="delete-user__buttons">
                <Button variant="text"
                        color="primary"
                        onClick={this.hideModal}>cancel</Button>
                <Button variant="contained"
                        color="secondary"
                        disabled={!this.state.delete_confirmed}
                        onClick={this.submitDelete}
                        style={this.buttonStyle()}>{this.state.archive ? "Archive" : "Delete"}</Button>
            </div>;
        } else {
            return <div className="add-user__buttons">
                <CircularProgress mode="indeterminate" />
            </div>;
        }
    }


    changeDeleteConfirmation = (event, value) => {
        this.setState({
            delete_confirmed: value
        });
    }


    deleteCheckbox() {
        let _delete = "delete";
        if (this.state.archive) {
            _delete = "archive"
        }

        return <div className="delete-user__checkbox">
            <div className="delete-user__checkbox-text">
                Confirm {_delete} of {this.state.item_deleted_text}
            </div>
            <FormControlLabel control={<Checkbox onChange={this.changeDeleteConfirmation}
                                                 color="primary"
                                                 checked={this.state.delete_confirmed}/>}
                              label={"Yes I'm sure, confirm " + _delete + "."}/>
        </div>
    }


    deleteStatus () {
        if (this.state.delete_error) {
            return <div className="update-status__error">{this.state.delete_error}</div>;
        } else {
            return <div></div>;
        }
    }


    render() {
        return <div>
            {this.deleteText()}
            {this.deleteCheckbox()}
            {this.deleteButtons()}
            {this.deleteStatus()}
        </div>;
    }
}

export default DeleteConfirmationModal;