import React, { Component } from 'react';
import { Button, CircularProgress, Checkbox, FormControlLabel } from "@material-ui/core";

class SelectTagModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_tags: props.selected_tags,
            untagged_products: props.untagged_products,
            select_button_text: "Select None"
        }
    }

    componentDidMount() {
        this.setKeyListener();
        if (this.props.selected_tags.length === this.props.tags.length) {
            this.setState({
                select_button_text: "Select None"
            })
        } else {
            this.setState({
                select_button_text: "Select All"
            })
        }
    }

    componentWillUnmount() {
        this.cancelKeyListener()
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            event.preventDefault()
            if (event.key === 'Enter') {
                if (this.state.selected_tags[0]) {
                    this.hideSelectTags()
                }
            } else if (event.key === "Escape") {
                this.props.cancelSelectTags()
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }

    handleAllTagsClick = () => {
        let updatedSelectedTags = this.state.selected_tags
        let newUntaggedProducts = false
        let select_button_text
        if (updatedSelectedTags.length === this.props.tags.length) {
            updatedSelectedTags = []
            select_button_text = "Select All"
        } else {
            updatedSelectedTags.length = 0;
            this.props.tags.forEach(tag => {
                updatedSelectedTags.push(tag.id)
            })
            newUntaggedProducts = true
            select_button_text = "Select None"
        }
        this.setState({
            selected_tags: updatedSelectedTags,
            select_button_text,
            untagged_products: newUntaggedProducts
        })
    }

    onTagCheck = (index, event, isInputChecked) => {
        let updatedSelectedTags = Array.from(this.state.selected_tags);
        if (!isInputChecked) {
            let i = updatedSelectedTags.indexOf(this.props.tags[index].id);
            if (i !== -1) updatedSelectedTags.splice(i, 1);
        } else {
            updatedSelectedTags.push(this.props.tags[index].id)
        }
        let select_button_text;
        if (updatedSelectedTags.length === this.props.tags) {
            select_button_text = "Select None"
        } else {
            select_button_text = "Select All"
        }
        this.setState({
            selected_tags: updatedSelectedTags,
            select_button_text
        })
    }

    hideSelectTags = () => {
        if (this.state.selected_tags.length > 0 || this.state.untagged_products) {
            this.props.hideSelectTags(this.state.selected_tags, this.state.untagged_products)
        }
    }

    renderTags = () => {
        if (!this.props.tags || !this.props.tags[0]) {
            return <div className="reports__loading">
                <>
                    <FormControlLabel className="select-products-body-item"
                        control={<Checkbox checked={this.state.untagged_products}
                            color={"primary"}
                            onChange={() => this.setState({ untagged_products: !this.state.untagged_products })} />}
                        label={'No Tag'}
                        key={"untagged"} />
                </>
            </div>
        }

        return (<>
            <FormControlLabel className="select-lines-body-item"
                control={<Checkbox checked={this.state.untagged_products}
                    color={"primary"}
                    onChange={() => this.setState({ untagged_products: !this.state.untagged_products })} />}
                label={'No Tag'}
                key={"untagged"} />
            {this.props.tags.map((tag, index) => {
                return <FormControlLabel className="select-lines-body-item"
                    control={<Checkbox checked={this.state.selected_tags.indexOf(tag.id) > -1}
                        color={"primary"}
                        onChange={this.onTagCheck.bind(this, index)} />}
                    label={tag.name}
                    key={"tag-" + tag.id} />
            })}
        </>)
    }


    render() {

        //This style object is to move the modal on the horizontally to match the open on top of the dropdown field
        let containerStyle = {}
        if (this.props.left) {
            containerStyle['left'] = this.props.left
        }

        return <div>
            <div
                className="select-lines"
                onClick={this.hideSelectTags}
            />
            <div className="select-lines-container" style={this.props.style}>
                <div className="select-lines-header">
                    <div style={{ flex: 1, marginLeft: "24px" }}>
                        <div className="select-lines-header-button modal__content-title__text">Tag Filter</div>
                    </div>
                    <Button variant="text"
                        color="primary"
                        className="select-lines-header-button"
                        onClick={this.handleAllTagsClick}>{this.state.select_button_text}</Button>
                    <Button variant="contained"
                        color="primary"
                        className="select-lines-header-button"
                        onClick={this.hideSelectTags}
                        disabled={this.state.selected_tags.length < 1 && !this.state.untagged_products}>Filter</Button>
                </div>
                <div className="select-lines-body">
                    {this.renderTags()}
                </div>

            </div>
        </div>
    }
}

export default SelectTagModal