import React, {Component} from 'react';
import TrendReportSettings from "./trend_report_factory_settings/TrendReportSettings";
import {CircularProgress} from "@material-ui/core";
import UnitsSettings from "./UnitsSettings";

class FactoryContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            error: null,
            units: null,
            units_error: null,
            loading: false,
            units_loading: false
        }
    }

    /* -------- LIFECYCLE METHODS -------- */
    componentDidMount() {
        this.getFactorySettings()
        this.getFactoryUnits()
        const headerContainer = document.getElementsByClassName("settings__subhead")[0];
        headerContainer.innerHTML = `<h5 class="MuiTypography-root MuiTypography-h5">Factory Settings</h5>`
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.factory !== this.props.factory) {
            this.setState({
                data: null,
                error: null,
                units: null,
                units_error: null,
                loading: false,
                units_loading: false
            })
            this.getFactorySettings()
            this.getFactoryUnits()
        }
    }

    /* -------- FUNCTIONS -------- */
    unitModified = () => {
        this.getFactoryUnits()
    }

    /* -------- API CALLS -------- */
    getFactorySettings = () => {
        this.setState({
            data: null,
            error: null,
            loading: true
        })

        let params = {
            path: "get_factory_settings",
            success: this.onGetFactorySettingsSuccess,
            error: this.onGetFactorySettingsError
        }

        API.c(params, 2)
    }

    onGetFactorySettingsSuccess = (data) => {
        this.setState({
            loading: false,
            data
        })
    }

    onGetFactorySettingsError = (error) => {
        this.setState({
            loading: false,
            data: {name:"",default_weeks_weekly_trend:52,default_weekly_pareto_bars:10,default_daily_pareto_bars:10,start_day_of_week:1,start_hour_of_day_utc:0,start_minute_of_day_utc:0,minimum_automated_run_output:0}, //TODO DELETE ME
            error
        })
    }

    getFactoryUnits = () => {
        this.setState({
            units: null,
            units_error: null,
            units_loading: true
        })

        let params = {
            path: "get_units",
            success: this.onGetFactoryUnitsSuccess,
            error: this.onGetFactoryUnitsError
        }

        API.c(params, 2)
    }

    onGetFactoryUnitsSuccess = (data) => {
        this.setState({
            units_loading: false,
            units: data
        })
    }

    onGetFactoryUnitsError = (error) => {
        this.setState({
            units_loading: false,
            units_error: error
        })
    }


    /* -------- RENDERS -------- */
    renderTrendReportSettings = () => {
        if(this.state.data) {
            return <TrendReportSettings max_weeks={this.state.data.default_weeks_weekly_trend}
                                        pareto_bars={this.state.data.default_weekly_pareto_bars}
                                        start_day_of_week={this.state.data.start_day_of_week}/>
        }

        return <div>
            Error Loading Trend Report Settings
        </div>
    }

    renderUnitsSettings = () => {
        if(this.state.units) {
            return <UnitsSettings units={this.state.units} modified={this.unitModified}/>
        }

        return <div>
            Error Loading Units Settings
        </div>
    }

    render() {
        if(this.state.loading || this.state.units_loading) {
            return <div className="products" style={{display: "flow-root"}}>
                <CircularProgress />
            </div>
        }

        return  <div className="products" style={{display: "flow-root"}}>
            <div className="products__subhead">
                <div className="header__action-user">
                </div>
            </div>
            {this.renderTrendReportSettings()}
            <hr className="settings__hr"/>
            {this.renderUnitsSettings()}
        </div>
    }
}

export default FactoryContainer