import React, { Fragment, useEffect, useState } from 'react';
import {
    Typography,
    Button,
    Box,
    Table,
    Divider,
    TableBody,
    TableRow,
    TableCell,
    CircularProgress
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { minimumRole } from '../../PermissionsHelper';
import AddNewRun from '../../../modals/AddNewRun';
import EndRun from '../../../modals/EndRun';
import Util from '../../Util';

import Filters from './filters';
import { utcToLocal } from '../../../utils/utcToLocal';

/**
 * @return {null}
 */

export default function RunReportTitleCard(props) {
    //console.log(window.API, 'WINDOW API ____________________________');
    //console.log(props, 'props of RUN REPORT TITLE');
    let params = {
        path: 'shift',
        success: data => {
            console.log('success', data);
        },
        error: error => {
            console.log(error);
        }
    };

    //const checkAPIlog = API.c(params);
    /*const checkAPIlog2 = API.c({
        path: 'products',
        success: data => {
            console.log('success', data);
        },
        error: error => {
            console.log(error);
        }
    });
    */

    /*const requestTest = async () => {
        const response = await fetch(
            'http://livetrackingsandbox-env.eba-7gz2ij39.us-east-1.elasticbeanstalk.com/shift/'
        );

        const json = await response.json();
        console.log(json, 'RESPONSE ---------------');
    };
    */

    //requestTest();

    // console.log(checkAPIlog, 'CHECKAPILOG ---------------------------');
    const classes = useStyles();
    const [showEndRunDialog, setShowEndRunDialog] = useState(false);
    const [showAddNewRunDialog, setShowAddNewRunDialog] = useState(false);
    const [user_role, setUser_role] = useState("")
    const [lastEditData, setLastEditData] = useState({})

    const getLastEdit = () => {
        let lastEditParams = {
            path: 'get_last_edit_run',
            path_variables: { RUN_ID: props.runId },
            success: (data) => setLastEditData(data),
            error: () => {}
        };

        API.c(lastEditParams, 2);
    };

    useEffect(() => {
        if (props.inDetails) {
            getLastEdit()
        }
        setUser_role(JSON.parse(window.localStorage.getItem('factory')).role)
    }, [])

    useEffect(() => {
        if (props.inDetails) {
            setLastEditData({})
            getLastEdit()
        }
    }, [props.runId, props.run])

    if (!props.inDetails) {
        return null;
    }

    function promptEndRunDialog() {
        setShowEndRunDialog(true);
    }

    function hideEndRunDialog() {
        setShowEndRunDialog(false);
    }

    function onEndRunSuccess() {
        props.refreshReport();
        hideEndRunDialog();
        promptAddNewRunDialog();
    }

    function promptAddNewRunDialog() {
        setShowAddNewRunDialog(true);
    }

    function hideAddNewRunDialog() {
        setShowAddNewRunDialog(false);
    }

    function onStartNewRunSuccess(data) {
        hideAddNewRunDialog();

        props.goToNewRun(data);
    }

    function onGetRunSuccess(data) {
        props.goToDateRun(data);
    }

    function onGetRunSuccessApply(data) {
        props.goToDateRunApply(data);
    }

    function onRunCurrentlyRunning() {
        hideAddNewRunDialog();

        props.onRunCurrentlyRunning();
    }

    const getLastEditUser = () => {
        if (this.state.factory_users && this.state.factory_users.length > 0) {
            const userData =  this.state.factory_users.filter((user) => {
                return user.user_id === this.state.lastEditData["Updated by"]
            })
            if (userData[0] && userData[0].user_metadata && (userData[0].user_metadata.first_name || userData[0].user_metadata.last_name)) {
                return `${userData[0].user_metadata.first_name} ${userData[0].user_metadata.last_name}`
            }
            else if (userData[0] && userData[0].name) {
                return userData[0].name
            }
            else if (userData[0] && userData[0].email) {
                return userData[0].email
            }
        }

        return "System User"
    }

    const renderLastEditString = () => {
        if (lastEditData["Action"] === "Deleted") {
            return `Last Edit: ${Object.keys(lastEditData["Previous values"])[0]} deleted by ${props.getLastEditUser(lastEditData["Updated by"])} on ${lastEditData["Date"]}`
        }
        else {
            return `Last Edit: ${Object.keys(lastEditData["New values"])[0]} changed to ${Object.values(lastEditData["New values"])[0]} by ${props.getLastEditUser(lastEditData["Updated by"])} on ${lastEditData["Date"]}`
        }
    }

    const backButton = () => {
        if (props.backTo === 'liveview') {
            return (
                <Button variant="text" color="primary" href={'/liveview'}>
                    Back to Liveview
                </Button>
            );
        }

        return (
            <Button
                disabled={props.line_products === null}
                style={{ paddingLeft: 0, paddingRight: 0 }}
                variant="text"
                color="primary"
                onClick={props.onBackToLineClick.bind(this)}
            >
                Back to daily reports
            </Button>
        );
    };

    const renderRunInfo = () => {
        if (props.inDetails && props.run === null) {
            return (
                <div className={classes.loading_card}>
                    <CircularProgress />
                </div>
            );
        } else {
            const start = moment(props.run.run_start_time).tz(props.timeZone).format('LLL');
            const end = moment(props.run.run_end_time).tz(props.timeZone).format('LLL');

            let product_description = props.run.product_desc + ' (' + props.run.product_name + ')';
            let line_name = props.run.line_name;
            let last_updated = '';
            if (Object.keys(lastEditData).length === 0) {
                last_updated = "No recent edits"
            }
            else {
                let lastEditKey = Object.keys(lastEditData["New values"])[0]
                let lastEditValue = Object.values(lastEditData["New values"])[0]
                if (lastEditKey === "Status") {
                    lastEditValue = lastEditValue === true ? "Active" : "Inactive"
                }
                if (lastEditData.Action) {
                    last_updated = renderLastEditString()
            }
            }
            let multiplier = props.run.product_multiplier ? parseInt(props.run.product_multiplier) : 1;

            return (
                <Fragment>
                    <div style={{ width: '100%' }}>
                        <Typography variant="h6">{line_name}</Typography>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '80%' }} className={classes.info_details_column}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.table_cell_title}>
                                                <Typography variant="subtitle1" color="inherit">
                                                    Product
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.table_cell_value}>
                                                <Typography variant="subtitle1">{product_description}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.table_cell_title}>
                                                <Typography variant="subtitle1" color="inherit">
                                                    Started
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.table_cell_value}>
                                                <Typography variant="subtitle1">{start}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.table_cell_title}>
                                                <Typography variant="subtitle1" color="inherit">
                                                    {props.run.run_end_time ? 'Ended' : 'Run Status'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.table_cell_value}>
                                                <Typography variant="subtitle1">
                                                    {props.run.run_end_time ? end : 'In Progress'}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.table_cell_title}>
                                                <Typography variant="subtitle1" color="inherit">
                                                    Finished Goods
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.table_cell_value}>
                                                <Typography variant="subtitle1">
                                                    {parseInt(props.run.run_finished_goods) * multiplier}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        {props.usesHeadcount && (
                                            <TableRow>
                                                <TableCell className={classes.table_cell_title}>
                                                    <Typography variant="subtitle1" color="inherit">
                                                        Labour
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={classes.table_cell_value}>
                                                    <Typography variant="subtitle1">
                                                        {Util.roundTo(parseFloat(props.run.headcount), 2)}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {/*<TableRow>*/}
                                        {/*    <TableCell className={classes.table_cell_title}>*/}
                                        {/*        <Typography variant="subtitle1" color="inherit">*/}
                                        {/*            Target Set Weight*/}
                                        {/*        </Typography>*/}
                                        {/*    </TableCell>*/}
                                        {/*    <TableCell className={classes.table_cell_value}>*/}
                                        {/*        <Typography variant="subtitle1">*/}
                                        {/*            {props.getProductTargetWeight()}*/}
                                        {/*        </Typography>*/}
                                        {/*    </TableCell>*/}
                                        {/*</TableRow>*/}
                                    </TableBody>
                                </Table>
                            </div>

                            <Box flexGrow={1} />

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div>
                                    {minimumRole('factory_admin') && user_role !== "scoreboard" && user_role !== "factory_read_only" && user_role !== "floor_team" && (
                                        <div>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    onClick={props.showEditRunModal}
                                                    disabled={!props.products[0] || !props.line_products}
                                                >
                                                    Edit Run Details
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    onClick={props.showMoveRunDataModal}
                                                    disabled={!props.products[0] || !props.line_products}
                                                >
                                                    Move Run Data
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <p style={{ margin: "2vh 0 3vh 0", width: "100%", color: "#999999" }}>{last_updated}</p>
                        {props.usesShifts && (
                            <div
                                style={{
                                    position: 'relative',
                                    borderTop: '1px solid #ebebeb',
                                    left: '-60px',
                                    marginRight: '-120px',
                                    paddingBottom: '30px'
                                }}
                            />
                        )}
                        {props.usesShifts && (
                            <Filters
                                setCurrentPage={props.setCurrentPage}
                                handlePageClick={props.handlePageClick}
                                start_time={props.run.run_start_time}
                                end_time={props.run.run_end_time}
                                lines={props.selected_lines}
                                timezone={props.timeZone}
                                onGetRunSuccessApply={onGetRunSuccessApply}
                                onGetRunSuccess={onGetRunSuccess}
                                runLineId={props.runLineId}
                                run={props.run}
                                run_id={props.runId}
                                runReportPage={true}
                                lineProducts={props.line_products}
                            />
                        )}
                    </div>
                </Fragment>
            );
        }
    };

    const renderDialog = () => {
        if (showEndRunDialog) {
            return (
                <EndRun
                    line={{ line_id: props.run.line_id, line_name: props.run.line_name }}
                    finishedGoods={props.run.run_finished_goods_cases}
                    runId={props.runId}
                    hideEndRun={hideEndRunDialog}
                    onEndRunSuccess={onEndRunSuccess}
                />
            );
        } else if (showAddNewRunDialog) {
            return (
                <AddNewRun
                    line={{ line_id: props.run.line_id, line_name: props.run.line_name }}
                    hideStartNewRun={hideAddNewRunDialog}
                    onStartNewRunSuccess={onStartNewRunSuccess}
                    onRunCurrentlyRunning={onRunCurrentlyRunning}
                />
            );
        }
    };

    if (props.inDetails) {
        return (
            <div className={props.collapse ? classes.card_collapse : classes.card}>
                <div className={classes.title_div}>
                    {backButton()}
                    <Typography style={{ fontWeight: "400" }} variant="h5" className={classes.title}>
                        Run Report
                    </Typography>
                </div>
                <div className={classes.report_info_div}>{renderRunInfo()}</div>
                {renderDialog()}
            </div>
        );
    }

    return null;
}

const useStyles = makeStyles(theme =>
    createStyles({
        card: {
            width: 'calc(100% - 216px)',
            margin: '20px 20px 20px 176px',
            boxSizing: 'border-box',
            background: '#fff',
            boxShadow: '0px 0px 8px rgba(0,0,0,0.3)',
            position: 'relative',
            transition: 'all 195ms ease-in-out',
            display: 'inline-block'
        },
        card_collapse: {
            width: 'calc(100% - 96px)',
            margin: '20px 20px 20px 56px',
            boxSizing: 'border-box',
            background: '#fff',
            boxShadow: '0px 0px 8px rgba(0,0,0,0.3)',
            position: 'relative',
            transition: 'all 195ms ease-in-out',
            display: 'inline-block'
        },
        title_div: {
            background: '#f2f2f2',
            padding: '30px'
        },
        title: {
            fontWeight: 'bold'
        },
        report_info_div: {
            background: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '60px 60px 0 60px'
        },
        info_details_column: {
            marginTop: '30px'
        },
        last_updated: {
            marginBottom: '30px',
            marginTop: '20px'
        },
        buttons_column: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        button: {
            marginBottom: '8px'
        },
        table_cell_title: {
            color: '#999',
            padding: '0 10px 0 0',
            borderBottom: 'none'
        },
        table_cell_value: {
            padding: '0 10px 0 0',
            borderBottom: 'none'
        },
        loading_card: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40vh',
            width: '100%'
        },
        end_run_button: {
            marginBottom: '30px',
            width: '160px'
        }
    })
);
