import React, { Component } from 'react';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.webAuth = window.Auth0;
  }

  componentDidMount = () => {
      this.webAuth.login()
      if(window.ga) {
          window.ga('set', 'page', '/login');
          window.ga('send', 'pageview');
      }
  }


  render() {
      return <div/>
  }
}

export default Login;