import React, {Component} from 'react';

class CustomValueLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */


    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    render() {
        const {x, y, stroke, payload, anchor, textX, textDy} = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={textX} y={0} dy={textDy} textAnchor={anchor} style={{fontFamily: 'Arial'}} fill="#666" fontSize="12px">
                     {payload.value}
                </text>
            </g>
        );

    }
}

export default CustomValueLabel