import React, {Component} from 'react';

class YAxisLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */


    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    render() {
        const {width, height} = this.props.viewBox;

        let orientation = this.props.vertical ? "-90" : "0"

        let x = this.props.offsetX ? width + this.props.offsetX : width / 2

        return (
            <g transform={`translate(${x},${height / 2})`}>
                <text x={0} y={0} dy={0} fontSize="14px" textAnchor="middle" transform={`rotate(${orientation})`}
                                 style={{fontFamily: 'Arial'}} fill="#000">{this.props.text}</text>
            </g>
        );
    }
}

export default YAxisLabel