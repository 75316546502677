import React, {useState} from 'react';
import {Typography, Button, Divider} from "@material-ui/core";
import {makeStyles, createStyles} from "@material-ui/core/styles";
import {ArrowDropDown} from "@material-ui/icons";
import SelectLineModal from "../../../modals/SelectLineModal";
import SelectProductModal from "../../../modals/SelectProductModal";
import {disableFutureDates} from "../../DateTimeHelperUtils";
import {DatePicker} from "@material-ui/pickers";

export default function TrendReportTitleAndOptions(props) {
    const classes = useStyles();
    const [showSelectLines, setShowSelectLines] = useState(false);
    const [showSelectProducts, setShowSelectProducts] = useState(false);


    const handleDateChange = (date) => {
        props.handleDateChange(date)
    }

    const openSelectLines = () => {
        setShowSelectLines(true)
    }

    const hideSelectLines = (selected_lines) => {
        props.handleSelectLines(selected_lines)
        setShowSelectLines(false)
    }

    const cancelSelectLines = () => {
        setShowSelectLines(false)
    }

    const openSelectProducts = () => {
        setShowSelectProducts(true)
    }

    const hideSelectProducts = (selected_products) => {
        props.handleSelectProducts(selected_products)
        setShowSelectProducts(false)
    }

    const cancelSelectProducts = () => {
        setShowSelectProducts(false)
    }


    const showLines = () => {
        if (props.lines == null) {
            return <div>Loading...</div>;
        }

        let count = "0"

        if (props.selected_lines && props.lines[0])
            if (props.selected_lines.length === props.lines.length) {
                count = "All"
            } else {
                count = props.selected_lines.length.toString()
            }

        return <div style={{marginRight: "10px", padding: "11px 0 12px 12px", float: "left"}}>
            <Button className={classes.option_item_dropdown}
                    variant="text"
                    fullWidth
                    disabled={props.lines == null}
                    onClick={openSelectLines}
                    endIcon={<ArrowDropDown/>}>{"Line Filter (" + count + ")"}</Button>
            <hr className="filter-button-hr"/>
        </div>
    }

    const showProducts = () => {
        if (props.products == null) {
            return <div>Loading...</div>;
        }

        let count = "0"

        if (props.selected_products && props.products[0])
            if (props.selected_products.length === props.products.length) {
                count = "All"
            } else {
                count = props.selected_products.length.toString()
            }

        return <div style={{marginRight: "10px", padding: "11px 0 12px 12px", float: "left"}}>
            <Button className={classes.option_item_dropdown}
                    variant="text"
                    disabled={props.products == null}
                    onClick={openSelectProducts}
                    endIcon={<ArrowDropDown/>}>{"Product Filter (" + count + ")"}</Button>
            <hr className="filter-button-hr"/>
        </div>
    }

    const showLinesModal = () => {
        return showSelectLines &&
            <SelectLineModal lines={props.lines} selected_lines={props.selected_lines}
                             hideSelectLines={hideSelectLines} cancelSelectLines={cancelSelectLines}/>
    }

    const showProductsModal = () => {
        return showSelectProducts &&
            <SelectProductModal products={props.products} selected_products={props.selected_products}
                                hideSelectProducts={hideSelectProducts}
                                cancelSelectProducts={cancelSelectProducts}/>
    }


    return <div className={props.collapse ? classes.card_collapse : classes.card}>
        <div className={classes.title_div}>
            <Typography style={{ fontWeight: "400" }} variant="h5" className={classes.title}>Trend Report</Typography>
        </div>
        <div className={classes.options_div}>
            <div className={classes.options_selectors}>
                <div className={classes.lines_products_div}>
                    {showLines()}
                    {showProducts()}
                </div>
                <Divider orientation={'vertical'}/>
                <div className={classes.date_pickers_div}>
                    <DatePicker
                        label="Date"
                        shouldDisableDate={disableFutureDates.bind(this)}
                        value={props.date}
                        onChange={handleDateChange}
                        className={"reports__options-item"}
                        format="yyyy-MMMM-DD"
                    />
                    <div className={classes.options_buttons}>
                        <Button variant={'contained'}
                                color={'primary'}
                                disabled={props.disabled}
                                onClick={props.buildReport}
                                className={classes.button}>
                            Apply Filter
                        </Button>
                    </div>
                </div>
            </div>
            <div className={classes.options_buttons}>
                <Button variant={'text'}
                        color={'primary'}
                        disabled={props.printStatus === 'printing' || props.printStatus === 'disabled'}
                        onClick={props.print}>
                    {props.printStatus === 'printing' ? 'Preparing...' : 'Download PDF'}
                </Button>
            </div>
        </div>
        {showLinesModal()}
        {showProductsModal()}
    </div>
}


const useStyles = makeStyles((theme) => createStyles({
    card: {
        width: 'calc(100% - 216px)',
        margin: '20px 20px 0px 176px',
        boxSizing: 'border-box',
        background: '#fff',
        boxShadow: '0px 0px 8px rgba(0,0,0,0.3)',
        position: 'relative',
        transition: 'all 195ms ease-in-out',
        display: 'inline-block'
    },
    card_collapse: {
        width: 'calc(100% - 96px)',
        margin: '20px 20px 0px 56px',
        boxSizing: 'border-box',
        background: '#fff',
        boxShadow: '0px 0px 8px rgba(0,0,0,0.3)',
        position: 'relative',
        transition: 'all 195ms ease-in-out',
        display: 'inline-block'
    },
    title_div:{
        background: '#f2f2f2',
        padding: '30px'
    },
    title: {
        fontWeight: 'bold'
    },
    options_div: {
        padding: '0px 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    options_selectors: {
        display: 'flex',
        flexDirection: 'row'
    },
    options_buttons : {
        margin: '30px 0',
        display: 'flex',
        alignItems: 'center'
    },
    lines_products_div: {
        margin: '15px 30px 15px 0',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'baseline'
    },
    date_pickers_div: {
        margin: '15px 30px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'baseline'
    },
    button: {
        marginBottom: '4px'
    },
    option_item: {
        fontSize: '16px !important',
        marginRight: '10px !important',
        marginTop: '12px !important',
        textAlign: 'start !important',
        textTransform: 'capitalize !important'
    },
    option_item_dropdown: {
        justifyContent: 'start',
        fontSize: '16px !important',
        marginRight: '10px !important',
        marginTop: '12px !important',
        textAlign: 'start !important',
        textTransform: 'capitalize !important'
    },
    option_item_date: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '16px !important',
        marginRight: '10px !important',
        marginTop: '12px !important',
        textAlign: 'start !important',
        textTransform: 'capitalize !important'
    }
}))