import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
    CircularProgress,
    Button,
    Paper,
    Typography,
    Divider,
    Fab
} from '@material-ui/core';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { AddOutlined } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { minimumRole } from '../../../components/PermissionsHelper';
import ShiftDetail from './ShiftDetails';
import { ShiftContext } from '../../../context';
import moment from 'moment';

const ShiftsSettings = ({ factory }) => {
    const classes = useStyles();

    const { active, setActive } = useContext(ShiftContext);

    const [shiftsData, setShiftsData] = useState(null);
    const [linesData, setLinesData] = useState(null);
    const [errors, setErrors] = useState('');
    const [timeZone, setTimeZone] = useState(null);
    const currentDate = moment().format('YYYY-MM-DD');

    const fetchShiftsSuccess = data => {
        setShiftsData(data);
    };

    const fetchShiftsError = error => setErrors(error);

    const getShifts = () => {
        let params = {
            path: 'get_shift',
            success: fetchShiftsSuccess,
            error: fetchShiftsError
        };

        API.c(params);
    };

    const onGetFactorySettingsSuccess = data => {
        setTimeZone(data.timezone);
    };

    const onGetFactorySettingsError = error => {
        setErrors(error);
    };

    const getFactorySettings = () => {
        let params = {
            path: 'get_factory_settings',
            success: onGetFactorySettingsSuccess,
            error: onGetFactorySettingsError
        };
        API.c(params, 2);
    };

    useEffect(() => {
        const headerContainer = document.getElementsByClassName("settings__subhead")[0];
        const headerContainerBody = document.getElementsByClassName("settings__body")[0];
        headerContainerBody.style.marginTop = "0px"
        headerContainer.innerHTML = `<h5 class="MuiTypography-root MuiTypography-h5">Shift Settings</h5>`
    }, [])
    useEffect(() => {
        getFactorySettings();
    }, [active]);
    useEffect(() => {
        getShifts();
    }, [factory.id]);

    if (!shiftsData) {
        return (
            <div
                className="settings__table"
                style={{
                    textAlign: 'center',
                    padding: '20px'
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    const ShowShiftContent = () => {
        return (
            <TableBody>
                {shiftsData.map((shift, index) => {
                    return (
                        <TableRow sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} key={'shift-' + shift.id}>
                            <TableCell align="left" style={Styles.tableRowColumnStyle()}>
                                {shift.name}
                            </TableCell>
                            {/*<TableCell align="left" style={Styles.tableRowColumnStyle()}>*/}
                            {/*    {shift.line_names.length < 4 ? shift.line_names.join(', ') : `${shift.line_names[0]} ...`}*/}
                            {/*</TableCell>*/}
                            <TableCell align="left" style={Styles.tableRowColumnStyle()}>
                                {moment(`${currentDate}T${shift.start_time}`).format('LT')}
                            </TableCell>
                            <TableCell align="left" style={Styles.tableRowColumnStyle()}>
                                {moment(`${currentDate}T${shift.end_time}`).format('LT')}
                            </TableCell>
                            <TableCell align="right" style={Styles.tableRowColumnStyle()}>
                                <Button
                                    variant="text"
                                    color="primary"
                                    disabled={false}
                                    onClick={() => {
                                        setActive({
                                            status: true,
                                            data: shiftsData[index]
                                        });
                                    }}
                                >
                                    DETAILS
                                </Button>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        );
    };

    const ShowShiftHeader = () => {
        return (
            <TableHead className="table-head-elevation">
                <TableRow>
                    <TableCell align="left" style={Styles.tableHeaderStyle()}>
                        Shift name
                    </TableCell>
                    {/*<TableCell align="left" style={Styles.tableHeaderStyle()}>*/}
                    {/*    Line*/}
                    {/*</TableCell>*/}
                    <TableCell align="left" style={Styles.tableHeaderStyle()}>
                        Start Time
                    </TableCell>
                    <TableCell align="left" style={Styles.tableHeaderStyle()}>
                        End Time
                    </TableCell>
                    <TableCell align="right" style={Styles.tableHeaderStyle()} />
                </TableRow>
            </TableHead>
        );
    };

    if (active.status) {
        return (
            <Fragment>
                <Button
                    variant="text"
                    style={{ margin: '30px 16px 10px' }}
                    color="primary"
                    onClick={() => {
                        setActive({
                            status: false,
                            data: null
                        });
                    }}
                >
                    Back to shift list
                </Button>
                <ShiftDetail
                    shiftData={active}
                    timeZone={timeZone}
                    lines={linesData}
                    shiftsData={shiftsData}
                    getShifts={getShifts}
                />
            </Fragment>
        );
    }

    return (
        <div className="settings__table" style={{ marginTop: "20px" }}>
            <div className={classes.flex}>
                {minimumRole('factory_admin') && (
                    <Fab
                        color="secondary"
                        className="settings_fab"
                        style={{
                            right: '25px',
                            top: '-27px'
                        }}
                        disabled={false}
                        onClick={() => {
                            setActive({
                                status: true,
                                data: null
                            });
                        }}
                    >
                        <AddOutlined />
                    </Fab>
                )}
            </div>
            {/* <Divider /> */}
            <TableContainer sx={{ marginTop: "20px", boxShadow: "none" }} component={Paper}>
                <Table>
                    {ShowShiftHeader()}
                    {ShowShiftContent()}
                </Table>
            </TableContainer>
        </div>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        card: {
            margin: '0 20px 40px 20px',
            boxSizing: 'border-box',
            background: '#fff',
            boxShadow: '0px 0px 8px rgba(0,0,0,0.12)',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
        },
        fab: {
            position: 'absolute',
            top: 0,
            right: 40,
            marginTop: '-28px'
        },
        title: {
            margin: '20px 30px'
        },
        table_head_cell: {
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 1) !important',
            width: '9.6%'
        },
        table_head_cell_icon: {
            fontSize: '14px',
            color: theme.palette.text.secondary,
            maxWidth: '4%'
        },
        table_cell_date: {
            fontSize: '0.875rem',
            padding: '16px 12px 16px 24px',
            color: theme.palette.text.primary,
            whiteSpace: 'initial',
            border: 'none',
            textAlign: 'center',
            verticalAlign: 'baseline'
        },
        table_cell_normal: {
            fontSize: '0.875rem',
            padding: '16px 12px',
            color: 'rgba(0, 0, 0, 0.87) !important',
            whiteSpace: 'initial',
            border: 'none',
            textAlign: 'center',
            verticalAlign: 'baseline'
        },
        table_cell_icon: {
            paddingTop: '16px',
            padding: '16px 24px 0 12px',
            whiteSpace: 'initial',
            border: 'none',
            textAlign: 'center'
        },
        icon: {
            marginTop: '-12px'
        },
        createBtn: {
            marginRight: '40px',
            marginLeft: '15px'
        },
        detailsBtn: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
            textTransform: 'uppercase',
            color: theme.palette.primary.main
        },
        flex: {
            display: 'flex'
        },
        boxContainer: {
            height: '97px',
            boxShadow: '0px 1px 4px rgb(0 0 0 / 10%)',
            alignItems: 'center',
            zIndex: 2,
            position: 'relative'
        }
    })
);

export default ShiftsSettings;
