import React, {Component} from 'react';

class CustomTrendOEETooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */


    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */

    showEfficiency = () => {
        let { payload } = this.props;
        let data = payload[0].payload;

        return <div className="flex-box__row justify-start">
            <div>
                <svg width="14" height="14" viewBox="0 0 32 32" style={{display: "inline-block", verticalAlign: "middle", marginRight: "4px"}}>
                    <path fill="#43a047" fillOpacity="0.65" d={'M 0 4 h 32 v 24 h -32 Z'}/>
                </svg>
            </div>
            <div>Weekly {localStorage.getItem("efficiency_string_short") !== 'undefined' ? localStorage.getItem("efficiency_string_short") : "OEE"}: {(Math.floor(data.amt*100)/100).toFixed(1)}%</div>
        </div>
    }

    showTrend = () => {
        let { payload } = this.props;
        let data = payload[0].payload;

        return <div className="flex-box__row justify-start">
            <div>
                <svg width="14" height="14" viewBox="0 0 32 32" style={{display: "inline-block", verticalAlign: "middle", marginRight: "4px"}}>
                    <path fill="none" stroke="#3182bd" strokeWidth="4" d={"M 0,16 h 32"}/>
                </svg>
            </div>
            <div>4-Week {localStorage.getItem("efficiency_string_short") !== 'undefined' ? localStorage.getItem("efficiency_string_short") : "OEE"} Trend: {(Math.floor(data.trend*100)/100).toFixed(1)}%</div>
        </div>
    }


    render() {
        let { active } = this.props;

        if (active) {
            const { payload, label } = this.props;


            if (payload === null){
                return null;
            }

            return (
                <div className="tooltip__transparent">
                    <p className="tooltip__title">{payload[0].payload.label}</p>
                    <hr style={{color: "#000"}}/>
                    <div className="tooltip__time-lost">{this.showEfficiency()}</div>
                    <div className="tooltip__time-lost">{this.showTrend()}</div>
                </div>
            );
        }

        return null;
    }
}

export default CustomTrendOEETooltip