import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ReasonSettingsView from "./ReasonSettingsView";
import { Paper, Tab, Tabs } from "@material-ui/core";

class ReasonsSettingOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsOption: this.getSettingsOptionView(),
            reasonDetails: false
        }
    }

    /* ######## LIFECYCLE METHODS ######## */
    componentDidMount() {
        document.title = "LiveTracking | Settings";
        if (window.ga) {
            window.ga('set', 'page', '/settings');
            window.ga('send', 'pageview');
        }
        const headerContainer = document.getElementsByClassName("settings__subhead")[0];
        headerContainer.innerHTML = `<h5 class="MuiTypography-root MuiTypography-h5">Reason Settings</h5>`
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.factory !== this.props.factory) {
            this.setState({
                reasonDetails: false
            })
        }
    }

    /* ######## FUNCTIONS ######## */
    getSettingsOptionView = () => {

        let option = 1;
        let p = window.location.pathname.split("/");

        switch (p[3]) {
            case "downtime":
                option = 0;
                break;
            case "waste":
                option = 1;
                break;
            case "rework":
                option = 2;
                break;
            case "categories":
                option = 3;
                break;
        }
        return option;

    }

    changeSettingType = (event, id) => {
        this.setState({
            settingsOption: id,
            reasonDetails: false
        })
    }

    onOpenReasonDetails = () => {
        this.setState({
            reasonDetails: true
        })
    }

    hideReasonDetails = () => {
        this.setState({
            reasonDetails: false
        })
    }

    getSettingsOptionClass = (id) => {
        let class_name = 'settings-options__item';
        let selected = ''

        if (this.state.screenWidth < 1190) {
            class_name += '--tablet'
        }

        if (this.state.settingsOption == id) {
            selected += '--selected';
        }

        return class_name + selected;
    }
    /* ######## API CALLS ######## */

    /* ######## RENDERS ######## */

    renderChildren = () => {
        let type = "downtime"
        switch (this.state.settingsOption) {
            case 0:
                type = "downtime";
                break;
            case 1:
                type = "waste";
                break;
            case 2:
                type = "rework";
                break;
            case 3:
                type = "categories";
                break;
        }

        return <ReasonSettingsView type={type}
            factory={this.props.factory}
            reasonDetails={this.state.reasonDetails}
            onOpenReasonDetails={this.onOpenReasonDetails}
            hideReasonDetails={this.hideReasonDetails} />
    }

    render() {
        return <div style={{ marginTop: '20px' }}>
            <Paper square>
                <Tabs className="tabs"
                    value={this.state.settingsOption}
                    onChange={this.changeSettingType}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary">
                    <Tab label="Downtime"
                        component={Link}
                        to={'/settings/reasons/downtime'}
                        style={{ padding: '20px 12px' }} />
                    <Tab label="Waste"
                        component={Link}
                        to={'/settings/reasons/waste'}
                        style={{ padding: '20px 12px' }} />
                    <Tab label="Rework"
                        component={Link}
                        to={'/settings/reasons/rework'}
                        style={{ padding: '20px 12px' }} />
                    <Tab label="Categories"
                        component={Link}
                        to={'/settings/reasons/categories'}
                        style={{ padding: '20px 12px' }} />
                </Tabs>
            </Paper>
            <div>
                {this.renderChildren()}
            </div>
        </div>
    }
}

export default ReasonsSettingOptions