import React, {Component} from 'react';
import {Button, TextField, CircularProgress, MenuItem, Typography} from "@material-ui/core";

class TrendReportSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            max_weeks: props.max_weeks,
            max_weeks_error: null,
            pareto_bars: props.pareto_bars,
            pareto_bars_error: null,
            status: '',
            error: false,
            submitting: false,
            start_day_of_week: props.start_day_of_week ? props.start_day_of_week : ''
        }
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */
    handleChange = (event) => {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    handleWeekStartChange = (event) => {
        this.setState({
            start_day_of_week: event.target.value
        });
    }

    validateInput = () => {
        let errorFree = true;

        if(this.state.max_weeks > 52) {
            errorFree = false;
            this.setState({
                max_weeks_error: "Number of weeks must be 52 or less"
            })
        } else if (this.state.max_weeks < 1) {
            errorFree = false;
            this.setState({
                max_weeks_error: "Number of weeks must be at least 1"
            })
        }

        if(this.state.pareto_bars < 1) {
            errorFree = false;
            this.setState({
                pareto_bars_error: "Number of bars must be at least 1"
            })
        }

        return errorFree
    }

    submitTrendReportSettings = () => {
        if(this.validateInput()) {
            this.submit()
        }
    }

    /* -------- API CALLS -------- */
    submit = () => {
        this.setState({
            max_weeks_error: null,
            pareto_bars_error: null,
            status: '',
            error: false,
            submitting: true
        })

        let params = {
            path: "update_factory_settings",
            data: {
                default_weeks_weekly_trend: this.state.max_weeks,
                default_weekly_pareto_bars: this.state.pareto_bars,
                start_day_of_week: this.state.start_day_of_week
            },
            success: this.onSubmitSuccess,
            error: this.onSubmitError
        }

        API.c(params, 2)
    }

    onSubmitSuccess = (data) => {
        this.setState({
            status: "Settings updated",
            submitting: false
        })
    }

    onSubmitError = (error) => {
        this.setState({
            status: "Error Saving",
            error: true,
            submitting: false
        })
    }

    /* -------- RENDERS -------- */
    showButton = () => {
        if (this.state.submitting) {
            return <div className="flex-box__row justify-end align-baseline">
                <CircularProgress />
            </div>
        }

        return <div style={{ justifyContent: "start" }} className="flex-box__row justify-end align-baseline">
            <div className={this.state.error ? 'update-status__error' : 'update-status__success'}>{this.state.status}</div>
            <Button variant="contained"
                    color="primary"
                    onClick={this.submitTrendReportSettings}>Save Trend Report Settings</Button>
        </div>
    }

    render() {
        return <div className="settings__table">
            <Typography variant='subtitle2'>Trend Report Settings</Typography>
            <div className="flex-box__row justify-space-between align-baseline">
                <div className="flex-box__row">
                    <TextField
                        id="max_weeks"
                        value={this.state.max_weeks}
                        style={{width: '219px', marginRight: '8px'}}
                        onChange={this.handleChange.bind(this)}
                        type='number'
                        error={this.state.max_weeks_error !== null}
                        helperText={this.state.max_weeks_error !== null ? this.state.max_weeks_error : ''}
                        label="Maximum number of weeks"/>
                    <TextField
                        id="pareto_bars"
                        value={this.state.pareto_bars}
                        style={{width: '219px', marginRight: '8px'}}
                        onChange={this.handleChange.bind(this)}
                        type='number'
                        error={this.state.pareto_bars_error != null}
                        helperText={this.state.pareto_bars_error != null ? this.state.pareto_bars_error : ''}
                        label="# of bars on the lost time pareto"/>
                    <TextField
                        select
                        style={{width: '219px', marginRight: '8px'}}
                        label='Week Start Day'
                        value={this.state.start_day_of_week}
                        onChange={this.handleWeekStartChange}>
                        <MenuItem value={1}>Monday</MenuItem>
                        <MenuItem value={2}>Tuesday</MenuItem>
                        <MenuItem value={3}>Wednesday</MenuItem>
                        <MenuItem value={4}>Thursday</MenuItem>
                        <MenuItem value={5}>Friday</MenuItem>
                        <MenuItem value={6}>Saturday</MenuItem>
                        <MenuItem value={7}>Sunday</MenuItem>
                    </TextField>
                </div>
                {this.showButton()}
            </div>
        </div>
    }
}

export default TrendReportSettings