import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function OeeIcon(props) {

    return <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M19.1667 2.60868H9.08333C8.75 1.13043 7.41667 0 5.83333 0C4.25 0 3 1.13043 2.58333 2.60868H0.833333C0.333333 2.60868 0 2.95651 0 3.47824C0 3.99998 0.333333 4.3478 0.833333 4.3478H2.58333C2.91667 5.82606 4.25 6.95648 5.83333 6.95648C7.41667 6.95648 8.66667 5.82606 9.08333 4.3478H19.1667C19.6667 4.3478 20 3.99998 20 3.47824C20 2.95651 19.6667 2.60868 19.1667 2.60868ZM5.83333 5.21736C4.91667 5.21736 4.16667 4.43476 4.16667 3.47824C4.16667 2.52173 4.91667 1.73912 5.83333 1.73912C6.75 1.73912 7.5 2.52173 7.5 3.47824C7.5 4.43476 6.75 5.21736 5.83333 5.21736Z"
              fill={props.color || 'black'}/>
        <path d="M19.1667 11.6521H17.4167C17.0833 10.1739 15.75 9.04346 14.1667 9.04346C12.5833 9.04346 11.3333 10.1739 10.9167 11.6521H0.833333C0.333333 11.6521 0 12 0 12.5217C0 13.0434 0.333333 13.3913 0.833333 13.3913H10.9167C11.25 14.8695 12.5833 15.9999 14.1667 15.9999C15.75 15.9999 17 14.8695 17.4167 13.3913H19.1667C19.6667 13.3913 20 13.0434 20 12.5217C20 12 19.6667 11.6521 19.1667 11.6521ZM14.1667 14.2608C13.25 14.2608 12.5 13.4782 12.5 12.5217C12.5 11.5652 13.25 10.7826 14.1667 10.7826C15.0833 10.7826 15.8333 11.5652 15.8333 12.5217C15.8333 13.4782 15.0833 14.2608 14.1667 14.2608Z"
              fill={props.color || 'black'}/>
    </SvgIcon>

}