import React, { Component } from 'react';
import TimeSplit from '../components/TimeSplit';
import RunDataColumn from '../components/RunDataColumn';
import update from 'immutability-helper';
import {
    Button,
    FormControlLabel,
    RadioGroup,
    Radio,
    CircularProgress,
    Step,
    StepLabel,
    Stepper,
    Tab,
    Tabs
} from '@material-ui/core';

class MoveRunData extends Component {
    constructor(props) {
        super(props);
        this.previous = null;
        this.next = null;
        this.state = {
            date_from: this.getDate(props.run.run_start_time),
            time_from: this.getDate(props.run.run_start_time),
            date_to: this.getDate(props.run.run_end_time),
            time_to: this.getDate(props.run.run_end_time),
            split_date: new Date(
                new Date(props.run.run_end_time).getTime() -
                    (640 - 120) * this.getTimeRate(props.run.run_start_time, props.run.run_end_time) * 1000
            ),
            min_date_time: this.getDate(props.run.run_start_time),
            max_date_time: this.getDate(props.run.run_end_time),
            original_product: this.getSelectedProduct(),
            selected_product: this.getSelectedProduct(),
            time_from_error: null,
            time_to_error: null,
            submitting: false,
            error: null,
            confirm: false,
            modify_run_time: true,
            get_confirm_data: false,
            confirm_data: null,
            confirm_data_error: null,
            submit_error: null,
            dest_run: 'prev',
            previous_next_info: null,
            getting_previous_next_info_error: null,
            finished_goods: 0,
            finished_goods_error: null,
            split_at: 320,
            split: false,
            from_max_height: '100px',
            to_max_height: '100px',
            stepIndex: 0,
            split_tab: 'move',
            split_tab_index: 0,
            previous_run: null,
            next_run: null,
            new_run: {
                end_time: this.props.run.run_end_time,
                product_id: this.getSelectedProduct(),
                start_time: this.props.run.run_start_time,
                finished_goods: 0
            }
        };
    }

    /* -------- LIFECYCLE METHODS -------- */

    componentWillMount() {
        this.getPreviousNextRunInfo();
    }

    componentDidMount = () => {
        this.setKeyListener();
    };

    componentWillUnmount = () => {
        this.cancelKeyListener();
    };

    setKeyListener = () => {
        window.onkeydown = event => {
            if (event.keyCode === 13) {
                this.confirmMoveRunData();
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    };

    cancelKeyListener = () => {
        window.onkeydown = null;
    };

    /* -------- FUNCTIONS -------- */
    handleTabChange = (event, index) => {
        this.setState({
            split_tab: index === 0 ? 'move' : 'split',
            split_tab_index: index
        });
    };

    getTimeRate = (s, e) => {
        let start = new Date(s).getTime() / 1000;
        let end;
        if (e === null) {
            end = new Date().getTime() / 1000;
        } else {
            end = new Date(e).getTime() / 1000;
        }

        return (end - start) / 640;
    };

    getSelectedProduct = () => {
        let { line_products, run } = this.props;
        let selectedProduct = line_products.find(
            product => product.product_name + product.product_desc === run.product_name + run.product_desc
        );

        return selectedProduct ? selectedProduct.product_id : null;

        // let selectedProduct = 0;
        // let props = this.props;
        // this.props.products.forEach(function (product) {
        //     let product_display = props.run.product_name + " - " + props.run.product_desc
        //     if(product.display_name === product_display) {
        //         selectedProduct = product.product_id
        //     }
        // })
        //
        // return selectedProduct
    };

    getProductDisplay = id => {
        let product = this.props.line_products.find(product => product.product_id === id);
        return product.product_name + ' - ' + product.product_desc;
    };

    getDate = d => {
        if (d == null) {
            return new Date();
        }
        return new Date(d);
    };

    confirmMoveRunData = () => {
        let { stepIndex } = this.state;

        switch (stepIndex) {
            case 0:
                stepIndex++;
                this.setState({
                    stepIndex
                });
                break;
            case 1:
                stepIndex++;
                this.submitConfirm();
                this.setState({
                    confirm: true,
                    stepIndex
                });
                break;
            case 2:
                this.submitMove();
                break;
        }
        this.props.onTabChange(stepIndex, this.state.split_tab);
    };

    cancel = () => {
        let { stepIndex } = this.state;

        switch (stepIndex) {
            case 0:
                this.props.hideModal();
                break;
            case 1:
                stepIndex--;
                this.setState({
                    stepIndex
                });
                this.submitConfirm();
                break;
            case 2:
                stepIndex--;
                this.setState({
                    confirm: false,
                    confirm_data: null,
                    stepIndex
                });
                break;
        }
        this.props.onTabChange(stepIndex, this.state.split_tab);
    };

    onRadioButtonChange = (event, value) => {
        this.updateStartEndTimes(this.state.split_date, value);
        this.setState({
            dest_run: value
        });
        if (value === 'prev') {
            if (this.state.split_tab === 'move') {
                this.next.reset();
            }
            this.setState({
                date_to: this.state.split_date,
                time_to: this.state.split_date,
                date_from: this.getDate(this.props.run.run_start_time),
                time_from: this.getDate(this.props.run.run_start_time)
            });
        } else {
            if (this.state.split_tab === 'move') {
                this.previous.reset();
            }
            this.setState({
                date_from: this.state.split_date,
                time_from: this.state.split_date,
                date_to: this.getDate(this.props.run.run_end_time),
                time_to: this.getDate(this.props.run.run_end_time)
            });
        }
    };

    switch = dest => {
        if (
            !(
                dest === 'next' &&
                this.state.split_tab === 'move' &&
                !this.state.previous_next_info.next_run_info.product_id
            ) &&
            !(
                dest === 'prev' &&
                this.state.split_tab === 'move' &&
                !this.state.previous_next_info.previous_run_info.product_id
            )
        ) {
            this.setState({
                dest_run: dest
            });
            this.updateStartEndTimes(this.state.split_date, dest);
            if (dest === 'prev') {
                this.setState({
                    date_to: this.state.split_date,
                    time_to: this.state.split_date,
                    date_from: this.getDate(this.props.run.run_start_time),
                    time_from: this.getDate(this.props.run.run_start_time)
                });
            } else {
                this.setState({
                    date_from: this.state.split_date,
                    time_from: this.state.split_date,
                    date_to: this.getDate(this.props.run.run_end_time),
                    time_to: this.getDate(this.props.run.run_end_time)
                });
            }
        }
    };

    onSplitTimeChange = (milliseconds, split_at) => {
        this.updateStartEndTimes(new Date(milliseconds), this.state.dest_run);
        if (this.state.dest_run === 'prev') {
            this.setState({
                date_to: new Date(milliseconds),
                split_date: new Date(milliseconds),
                split_at
            });
        } else {
            this.setState({
                date_from: new Date(milliseconds),
                split_date: new Date(milliseconds),
                split_at
            });
        }
    };

    updateStartEndTimes = (split_date, dest) => {
        let prevRun = this.state.previous_run;
        let nextRun = this.state.next_run;
        let newRun = this.state.new_run;
        let updatedPrevRun;
        let updatedNextRun;
        let updatedNewRun;
        if (this.state.split_tab === 'move') {
            if (dest === 'prev') {
                updatedPrevRun = update(prevRun, { end_time: { $set: split_date.toISOString() } });
                updatedNextRun = update(nextRun, {
                    start_time: { $set: this.state.previous_next_info.next_run_info.start_time }
                });
            } else {
                updatedPrevRun = update(prevRun, {
                    end_time: { $set: this.state.previous_next_info.previous_run_info.end_time }
                });
                updatedNextRun = update(nextRun, { start_time: { $set: split_date.toISOString() } });
            }

            this.setState({
                previous_run: updatedPrevRun,
                next_run: updatedNextRun
            });
        } else {
            if (dest === 'prev') {
                updatedNewRun = update(newRun, {
                    start_time: { $set: this.props.run.run_start_time },
                    end_time: { $set: split_date.toISOString() }
                });
            } else {
                updatedNewRun = update(newRun, {
                    start_time: { $set: split_date.toISOString() },
                    end_time: { $set: this.props.run.run_end_time }
                });
            }

            this.setState({
                new_run: updatedNewRun
            });
        }
    };

    onProductChange = (dest, value) => {
        switch (dest) {
            case 'prev':
                let prevRun = this.state.previous_run;
                let updatedPrevRun = update(prevRun, { product_id: { $set: value } });
                this.setState({
                    previous_run: updatedPrevRun
                });
                break;
            case 'next':
                let nextRun = this.state.next_run;
                let updatedNextRun = update(nextRun, { product_id: { $set: value } });
                this.setState({
                    next_run: updatedNextRun
                });
                break;
            case 'new':
                let newRun = this.state.new_run;
                let updatedNewRun = update(newRun, { product_id: { $set: value } });
                this.setState({
                    new_run: updatedNewRun
                });
                break;
        }

        this.setState({
            selected_product: value
        });
    };

    onFinishedGoodsChange = (dest, value) => {
        let error = null;

        if (value < 0) {
            error = "Can't be negative";
        } else if (value > this.props.run.run_finished_goods_cases) {
            error = "Can't be more than " + this.props.run.run_finished_goods_cases;
        }
        switch (dest) {
            case 'prev':
                let prevRun = this.state.previous_run;
                let updatedPrevRun = update(prevRun, { finished_goods: { $set: value } });
                this.setState({
                    previous_run: updatedPrevRun
                });
                break;
            case 'next':
                let nextRun = this.state.next_run;
                let updatedNextRun = update(nextRun, { finished_goods: { $set: value } });
                this.setState({
                    next_run: updatedNextRun
                });
                break;
            case 'new':
                let newRun = this.state.new_run;
                let updatedNewRun = update(newRun, { finished_goods: { $set: value } });
                this.setState({
                    new_run: updatedNewRun
                });
                break;
        }

        this.setState({
            finished_goods: value,
            finished_goods_error: error
        });
    };

    validateInput = () => {
        let error_free = true;

        if (this.state.previous_next_info) {
            if (
                this.state.split_tab === 'move' &&
                this.state.dest_run === 'prev' &&
                this.state.previous_next_info.previous_run_info.product_id === undefined
            ) {
                error_free = false;
            }
        } else {
            error_free = false;
        }

        if (
            this.state.split_tab === 'move' &&
            this.state.dest_run === 'next' &&
            this.state.previous_next_info.next_run_info.product_id === undefined
        ) {
            error_free = false;
        }

        if (this.state.finished_goods < 0) {
            error_free = false;
        }

        return error_free;
    };

    getChangesTitle = () => {
        if (this.state.split_tab === 'split') {
            return 'New Run:';
        } else {
            switch (this.state.dest_run) {
                case 'prev':
                    return 'Previous Run:';
                case 'next':
                    return 'Next Run:';
            }
        }
    };

    /* -------- API CALLS -------- */

    getPreviousNextRunInfo = () => {
        let params = {
            path: 'get_previous_and_next_on_line',
            path_variables: {
                RUN_ID: this.props.run_id
            },
            success: this.getPreviousNextRunSuccess,
            error: this.getPreviousNextRunError
        };

        API.c(params, 2);
    };

    getPreviousNextRunSuccess = data => {
        let previous_run = data.previous_run_info;
        let next_run = data.next_run_info;

        this.setState({
            previous_next_info: data,
            previous_run,
            next_run
        });
    };

    getPreviousNextRunError = error => {
        this.setState({
            getting_previous_next_info_error: error
        });
    };

    submitConfirm = () => {
        if (this.state.split_tab === 'split') {
            this.setState({
                get_confirm_data: true
            });

            let run = this.state.new_run;
            let product = this.props.line_products.find(prod => prod.product_id === run.product_id);
            let multiplier = this.props.run
                ? parseFloat(this.props.run.product_multiplier) > 0
                    ? parseFloat(this.props.run.product_multiplier)
                    : 1
                : 1;

            let targetWeight = product
                ? parseFloat(product.product_target_weight) > 0
                    ? parseFloat(product.product_target_weight)
                    : 1
                : 1;

            let destination_finished_goods = parseFloat(run.finished_goods) / multiplier / targetWeight;

            let params = {
                path: 'confirm_split_run_data',
                path_variables: { RUN_ID: this.props.run_id },
                data: {
                    split_time: this.state.split_date.toISOString(),
                    product_id: run.product_id,
                    is_new_run_at_start: this.state.dest_run === 'prev',
                    destination_finished_goods
                },
                success: this.confirmSuccess,
                error: this.confirmError
            };

            API.c(params, 2);
        } else {
            this.setState({
                get_confirm_data: true
            });

            let run = null;

            if (this.state.dest_run === 'prev') {
                run = this.state.previous_run;
            } else {
                run = this.state.next_run;
            }

            let params = {
                path: 'confirm_move_run_data',
                path_variables: { RUN_ID: this.props.run_id },
                data: {
                    start_range_time: this.state.date_from.toISOString(),
                    end_range_time: this.state.date_to.toISOString(),
                    destination_run_id: run.run_id,
                    move_to_previous_run: this.state.dest_run === 'prev',
                    move_to_next_run: this.state.dest_run === 'next',
                    modify_run_times: this.state.modify_run_time,
                    destination_product_id: run.product_id
                },
                success: this.confirmSuccess,
                error: this.confirmError
            };

            API.c(params, 2);
        }
    };

    confirmSuccess = data => {
        this.setState({
            get_confirm_data: false,
            confirm_data: data
        });
    };

    confirmError = error => {
        this.setState({
            get_confirm_data: false,
            confirm_data_error: error
        });
    };

    submitMove = () => {
        this.setState({
            submitting: true
        });
        let params;
        let run = null;
        let product;

        if (this.state.split_tab === 'split') {
            run = this.state.new_run;
            product = this.props.line_products.find(prod => prod.product_id === run.product_id);

            let multiplier = product
                ? parseFloat(product.product_multiplier) > 0
                    ? parseFloat(product.product_multiplier)
                    : 1
                : 1;

            let targetWeight = product
                ? parseFloat(product.product_target_weight) > 0
                    ? parseFloat(product.product_target_weight)
                    : 1
                : 1;

            let destination_finished_goods = parseFloat(run.finished_goods) / multiplier / targetWeight;

            params = {
                path: 'split_run',
                path_variables: { RUN_ID: this.props.run_id },
                data: {
                    split_time: this.state.split_date.toISOString(),
                    destination_product_id: run.product_id,
                    is_new_run_at_start: this.state.dest_run === 'prev',
                    destination_finished_goods
                },
                success: this.moveSuccess,
                error: this.moveError
            };
        } else {
            if (this.state.dest_run === 'prev') {
                run = this.state.previous_run;
            } else {
                run = this.state.next_run;
            }
            product = this.props.line_products.find(prod => prod.product_id === run.product_id);

            params = {
                path: 'move_run_data',
                path_variables: { RUN_ID: this.props.run_id },
                data: {
                    start_range_time: this.state.date_from.toISOString(),
                    end_range_time: this.state.date_to.toISOString(),
                    destination_run_id: run.run_id,
                    move_to_previous_run: this.state.dest_run === 'prev',
                    move_to_next_run: this.state.dest_run === 'next',
                    modify_run_times: this.state.modify_run_time,
                    destination_product_id: run.product_id
                },
                success: this.moveSuccess,
                error: this.moveError
            };
        }

        API.c(params, 2);
    };

    moveSuccess = data => {
        this.setState({
            submitting: false
        });
        this.props.modified();
        this.props.hideModal();
    };

    moveError = error => {
        this.setState({
            submitting: false,
            submit_error: error
        });
    };

    /* -------- RENDERS -------- */
    showMoveSplitTabs = () => {
        return (
            <div className="flex-box__row justify-center">
                <div
                    className="margin-bottom-bg"
                    style={{ width: '70%', boxShadow: '0px -4px 9px 0px rgba(0, 0, 0, 0.09)' }}
                >
                    <Tabs
                        className="tabs"
                        value={this.state.split_tab_index}
                        onChange={this.handleTabChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Move" style={{ borderBottomColor: 'rgba(0,0,0,0.5)' }} />
                        <Tab label="Split" style={{ borderBottomColor: 'rgba(0,0,0,0.5)' }} />
                    </Tabs>
                </div>
            </div>
        );
    };

    showSelectPreviousNextRadio = () => {
        let previous_run = this.state.previous_next_info.previous_run_info;
        let next_run = this.state.previous_next_info.next_run_info;
        let previous_label = this.state.split_tab === 'move' ? 'Move to previous run' : 'New run before current run';
        let next_label = this.state.split_tab === 'move' ? 'Move to next run' : 'New run after current run';

        return (
            <div className="flex-box__col align-start" style={{ width: '320px' }}>
                <RadioGroup
                    name="runTo"
                    row
                    value={this.state.dest_run}
                    style={{ width: '640px', display: 'flex', justifyContent: 'space-between' }}
                    onChange={this.onRadioButtonChange}
                >
                    <FormControlLabel
                        value={'prev'}
                        control={<Radio />}
                        label={previous_label}
                        disabled={this.state.split_tab === 'move' && previous_run.product_id === undefined}
                    />
                    <FormControlLabel
                        value={'next'}
                        control={<Radio />}
                        label={next_label}
                        disabled={this.state.split_tab === 'move' && next_run.product_id === undefined}
                    />
                </RadioGroup>
            </div>
        );
    };

    showButtons = () => {
        if (this.state.submitting) {
            return (
                <div className="edit-run-buttons__wrapper">
                    <CircularProgress />
                </div>
            );
        }
        return (
            <div className="edit-run-buttons__wrapper">
                <Button variant="text" color="primary" style={{ marginRight: '5px' }} onClick={this.cancel}>
                    {this.state.stepIndex !== 0 ? 'Back' : 'Cancel'}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.confirmMoveRunData}
                    disabled={
                        (!this.state.confirm && !this.validateInput()) ||
                        (this.state.confirm && this.state.get_confirm_data)
                    }
                >
                    {this.getSubmitLabel()}
                </Button>
            </div>
        );
    };

    getSubmitLabel = () => {
        switch (this.state.stepIndex) {
            case 0:
            case 1:
                return 'Next';
            case 2:
                if (this.state.split_tab === 'move') {
                    return 'Move Run Data';
                } else {
                    return 'Split Run Data';
                }
        }
    };

    showMoveRuns = () => {
        return (
            <div className="flex-box__row justify-space-between align-start">
                <RunDataColumn
                    products={this.props.line_products}
                    run={this.state.previous_run}
                    onProductChange={this.onProductChange.bind(this, 'prev')}
                    onFinishedGoodsChange={this.onFinishedGoodsChange.bind(this, 'prev')}
                    disabled={this.state.dest_run !== 'prev'}
                    ref={instance => {
                        this.previous = instance;
                    }}
                />
                <RunDataColumn
                    products={this.props.line_products}
                    run={this.state.next_run}
                    onProductChange={this.onProductChange.bind(this, 'next')}
                    onFinishedGoodsChange={this.onFinishedGoodsChange.bind(this, 'next')}
                    disabled={this.state.dest_run !== 'next'}
                    ref={instance => {
                        this.next = instance;
                    }}
                />
            </div>
        );
    };

    showSplitRun = () => {
        let run = this.state.new_run;

        let newRun = (
            <RunDataColumn
                products={this.props.line_products}
                run={run}
                isSplit
                onProductChange={this.onProductChange.bind(this, 'new')}
                onFinishedGoodsChange={this.onFinishedGoodsChange.bind(this, 'new')}
            />
        );

        if (this.state.dest_run === 'next') {
            return (
                <div className="flex-box__row justify-space-between align-start">
                    <div
                        className="flex-box__col justify-center"
                        style={{ width: '320px', height: '193px', opacity: '0.3' }}
                    >
                        Current Run
                    </div>
                    <div>{newRun}</div>
                </div>
            );
        } else {
            return (
                <div className="flex-box__row justify-space-between align-start">
                    <div>{newRun}</div>
                    <div
                        className="flex-box__col justify-center"
                        style={{ width: '320px', height: '193px', opacity: '0.3' }}
                    >
                        Current Run
                    </div>
                </div>
            );
        }
    };

    showSelectors = () => {
        if (this.state.confirm) {
            return <div />;
        } else if (!this.state.previous_next_info) {
            if (this.state.getting_previous_next_info_error) {
                return <div className="flex-box__row align-center justify-center font-size-hd">ERROR</div>;
            } else {
                return (
                    <div className="flex-box__row align-center justify-center">
                        <CircularProgress />
                    </div>
                );
            }
        }

        return (
            <div style={{ marginLeft: '18px' }}>
                <TimeSplit
                    run={this.props.run}
                    dest={this.state.dest_run}
                    onSplitTimeChange={this.onSplitTimeChange}
                    switch={this.switch}
                    split={this.state.split}
                    split_at={this.state.split_at}
                />
                <div style={{ marginLeft: '24px' }}>
                    {this.showSelectPreviousNextRadio()}
                    {this.state.split_tab === 'move' ? this.showMoveRuns() : this.showSplitRun()}
                </div>
            </div>
        );
    };

    showSteps = () => {
        let { stepIndex } = this.state;
        return (
            <div className="flex-box__col justify-center" style={{ width: '95%' }}>
                <Stepper activeStep={stepIndex} orientation="horizontal" style={{ width: '70%' }}>
                    <Step>
                        <StepLabel>{'Move/Split'}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{'Run Data'}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{'Confirm'}</StepLabel>
                    </Step>
                </Stepper>
                {this.showStepContent()}
            </div>
        );
    };

    showStepContent = () => {
        switch (this.state.stepIndex) {
            case 0:
                return this.showMoveSplitTabs();
            case 1:
                return this.showSelectors();
            case 2:
                return this.showConfirm();
        }
    };

    showConfirm = () => {
        if (!this.state.confirm) {
            return <div />;
        } else if (this.state.get_confirm_data) {
            return (
                <div className="flex-box__row align-center justify-center">
                    <CircularProgress />
                </div>
            );
        }

        return (
            <div className="flex-box__col align-start margin-bottom-bg" style={{ width: '100%' }}>
                <div className="flex-box__row">
                    <div className="flex-box__row--double align-end margin-bottom-bg">
                        <div className="flex-box__col align-start justify-start">
                            <div className="font-size-bg margin-bottom-sm bold">Current Run:</div>
                            <div className="margin-bottom-sm">
                                {'Start Time: ' + Util.formatShortDateTime(this.state.confirm_data.source_start_time)}
                            </div>
                            <div className="margin-bottom-sm">
                                {'End Time: ' + Util.formatShortDateTime(this.state.confirm_data.source_end_time)}
                            </div>
                            <div className="margin-bottom-sm">
                                Product: {this.getProductDisplay(this.state.original_product)}
                            </div>
                            {this.state.split_tab === 'split' && (
                                <div className="margin-bottom-sm">
                                    Finished Goods: {this.state.confirm_data.source_finished_goods.toFixed(2)}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex-box__row--double align-end margin-bottom-bg">
                        <div className="flex-box__col align-start justify-start">
                            <div className="font-size-bg margin-bottom-sm bold">{this.getChangesTitle()}</div>
                            <div className="margin-bottom-sm">
                                {'Start Time: ' +
                                    Util.formatShortDateTime(this.state.confirm_data.destination_start_time)}
                            </div>
                            <div className="margin-bottom-sm">
                                {'End Time: ' + Util.formatShortDateTime(this.state.confirm_data.destination_end_time)}
                            </div>
                            <div className="margin-bottom-sm">
                                Product: {this.getProductDisplay(this.state.selected_product)}
                            </div>
                            {this.state.split_tab === 'split' && (
                                <div className="margin-bottom-sm">
                                    Finished Goods: {this.state.confirm_data.destination_finished_goods.toFixed(2)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="font-size-bg margin-bottom-sm bold">Moved Data</div>
                {this.state.confirm_data.downtime_duration_seconds !== 0 && (
                    <div className="margin-bottom-sm">
                        {'Downtime: ' +
                            Util.formatHourMinuteSecondsFromSeconds(this.state.confirm_data.downtime_duration_seconds)}
                    </div>
                )}
                {this.state.confirm_data.output !== 0 && (
                    <div className="margin-bottom-sm">
                        {'Sensor Count: ' + Util.numberWithCommasAndFractions(this.state.confirm_data.output)}
                    </div>
                )}
                {this.state.confirm_data.waste !== 0 && (
                    <div className="margin-bottom-sm">
                        {'Waste: ' + Util.numberWithCommasAndFractions(this.state.confirm_data.waste)}
                    </div>
                )}
                {this.state.confirm_data.rework !== 0 && (
                    <div className="margin-bottom-sm">
                        {'Rework: ' + Util.numberWithCommasAndFractions(this.state.confirm_data.rework)}
                    </div>
                )}
            </div>
        );
    };

    render() {
        return (
            <div className="flex-box__col align-start container">
                {this.showSteps()}
                {this.showButtons()}
            </div>
        );
    }
}

export default MoveRunData;
