import React, {Component} from 'react';

class CustomTrendLostTimeTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */
    static str_pad_left(string, pad, length) {
        return (new Array(length+1).join(pad)+string).slice(-length);
    }

    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    showDowntime = () => {
        const { payload } = this.props;

        if (!payload) {
            return '';
        }

        let data = payload[0].payload;
        let lostSeconds = Math.abs(data.time_lost_seconds)
        let hours = 0;
        let minutes = Math.floor(lostSeconds / 60);
        if(minutes > 60) {
            hours   = Math.floor(lostSeconds / 3600);
            minutes = Math.floor((lostSeconds - (hours * 3600)) / 60);
        }
        let seconds = Math.floor(lostSeconds - (minutes * 60) - (hours * 3600));

        let time = minutes + ' minutes ' + CustomTrendLostTimeTooltip.str_pad_left(seconds,'0',2) + ' seconds'

        if(hours > 0) {
            time = hours + ' hours ' + minutes + ' minutes ' + CustomTrendLostTimeTooltip.str_pad_left(seconds,'0',2) + ' seconds'
        }

        let negative = data.time_lost_seconds < 0 ? '-' : '';

        return <div className="flex-box__row justify-start">
            <div>
                <svg width="14" height="14" viewBox="0 0 32 32" style={{display: "inline-block", verticalAlign: "middle", marginRight: "4px"}}>
                    <path fill="#cb2d3e" d={'M 0 4 h 32 v 24 h -32 Z'}/>
                </svg>
            </div>
            <div>Downtime: {negative + time}  ({Math.floor(data.time_lost*100)/100}%)</div>
        </div>
    }


    showReason = () => {
        const { payload } = this.props;

        if (!payload) {
            return '';
        }
        return payload[0].payload.reason;

    }


    render() {
        const { active } = this.props;

        if (active) {
            const { payload, label } = this.props;

            if(payload) {
                return (
                    <div className="tooltip__transparent">
                        <div className="tooltip__title">{this.showReason()}</div>
                        <hr/>
                        <div className="tooltip__time-lost">{this.showDowntime()}</div>
                        <p className="tooltip__click-for-details">Click on bar for details</p>
                    </div>
                );
            } else {
                return null;
            }
        }

        return null;
    }
}

export default CustomTrendLostTimeTooltip