import React, {Component} from 'react';
import CustomEfficiencyToolTip from "./CustomEfficiencyToolTip";
import Chrono from "./Chrono";
import {Bar, BarChart, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Dropdown from "./Dropdown";
import {Menu, MenuItem} from "@material-ui/core"
import CustomAxisTick from "./CustomAxisTick";

class LinePresentation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            open: false,
            anchorOrigin: {
                horizontal: 'left',
                vertical: 'top',
            },
            targetOrigin: {
                horizontal: 'left',
                vertical: 'top',
            },
            lines: props.lines,
            line: props.item.line_id,
            screenHeight: window.innerHeight,
            screenWidth: window.innerWidth,
            DOMRect: {}
        }
        this.lineRef = null;
        this.setRef = element => {
            this.lineRef = element;
        }
    }

    /* ######## LIFECYCLE METHODS ######## */
    componentDidMount(){
        window.addEventListener('resize', this._onResize);
        if(this.lineRef) {
            this.setState({
                DOMRect: this.lineRef.getBoundingClientRect()
            })
        }
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this._onResize)
    }
    /* ######## FUNCTIONS ######## */

    _onResize = () => {
        this.setState({
            screenHeight: window.innerHeight,
            screenWidth: window.innerWidth
        })
    }

    formatTime(t) {
        let d = new Date(t);

        let hours = d.getHours();
        let minutes = ("0"+d.getMinutes()).substr(-2, 2);
        let seconds = ("0"+d.getSeconds()).substr(-2, 2);
        let ampm = d.getHours() >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = ("0"+(hours)).substr(-2, 2);

        return hours+":"+minutes+":"+seconds+" "+ampm;
    }

    formatHour = (time) => {
        let d = new Date(time);
        let h = d.getHours();

        if (h < 12) {
            if (h == 0) {
                return "12a";
            }
            return h + "a";
        } else {
            if (h - 12 == 0) {
                return "12p";
            }
            return (h - 12) + "p";
        }
    }


    handleOpenMenu = (event) => {
        event.preventDefault();
        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    }


    handleClose = () => {
        this.setState({
            anchorEl: null,
            open: false
        });
    };

    handleMenuClick = (value) => {
        this.setState({
            anchorEl: null,
            open: false,
            line: value
        });
        Global.setCookie('line-'+this.props.position, value);
    }

    getLabel = () => {
        let { line } = this.state
        let {lines} = this.props
        if(lines && line) {
            let selectedLine = this.props.lines.find(function(element) {
                return element.line_id === line;
            });
            return selectedLine.line_name
        }

        return "Loading Lines..."
    }

    getBarFill = (entry) => {
        let line = this.props.lines.find(line => {return line.line_id === this.state.line});
        if(this.props.showAllGreen) {
            return "#6ebd3a"
        } else {
            if(entry.amt < line.line_target_efficiency) {
                return "#ce3125"
            } else {
                return "#6ebd3a"
            }
        }
    }


    /* ######## API CALLS ######## */

    /* ######## RENDERS ######## */

    iterateLines = () => {
        if (this.props.lines == null || this.props.lines.constructor !== Array) {
            return;
        }

        return this.props.lines.map((item, index) =>
            <MenuItem
                value={item.line_id}
                onClick={this.handleMenuClick.bind(this, item.line_id)}
                key={"line-"+item.line_id}>
                {item.line_name}
            </MenuItem>
        )
    }

    headerClass (line) {
        let c = "scoreboard--header";
        let t = 0;

        switch (line.line_status) {
            case "down":
                if(line.current_event_status !== 'Short Stop') {
                    t = 0;
                } else {
                    if(parseFloat(line.last_speed_pieces_per_minute) <= (parseFloat(line.target_speed_pieces_per_minute) * 0.97)){
                        t = 1;
                    } else {
                        t = 2;
                    }
                }
                break;
            case "notrunning":
                t = 3;
                break;
            case "slow":
            case "normal":
                if(parseFloat(line.last_speed_pieces_per_minute) <= (parseFloat(line.target_speed_pieces_per_minute) * 0.97)){
                    t = 1;
                } else {
                    t = 2;
                }
                break;
        }

        let options = [
            "--down",
            "--slow",
            "--online",
            "--offline"
        ]

        return c + options[t];
    }

    getTextColor(line){
        switch (line.line_status) {
            case "down":
                if(line.current_event_status !== 'Short Stop') {
                    return '#fff'
                } else {
                    if(parseFloat(line.last_speed_pieces_per_minute) <= (parseFloat(line.target_speed_pieces_per_minute) * 0.97)){
                        return '#000'
                    } else {
                        return '#fff'
                    }
                }
                return '#fff'
            case "normal":
            case "slow":
                if(parseFloat(line.last_speed_pieces_per_minute) <= (parseFloat(line.target_speed_pieces_per_minute) * 0.97)) {
                    return '#000'
                } else {
                    return '#fff'
                }
            case "notrunning":
                return '#2B2E34'
        }
    }


    showStatus() {
        let { item, grid_view } = this.props;
        let fontSize = "font-size-hd";
        switch(grid_view) {
            case 1:
                fontSize = "font-size-xl";
                break;
            case 2:
                fontSize = "font-size-hd";
                break;
            default:
                fontSize = "font-size-xbg"
                break;
        }
        return <div>
            <div className="line-item__column-item__speed">
                <div className={"line-item__column-item__speed-label " + fontSize}>Started</div>
                <div className={"line-item__column-item__speed-info "  + fontSize}>
                    {item.run_start_time ? this.formatTime(item.run_start_time) : 'N/A'}
                </div>
            </div>
            <div className="line-item__column-item__speed">
                <div className={"line-item__column-item__speed-label " + fontSize}>Total Running Time</div>
                <Chrono item={item} style={"line-item__column-item__speed-info "  + fontSize}/>
            </div>
        </div>;
    }

    showDetails = (data, shouldDisplayOutput, outputUnit) => {

        if(this.props.grid_view === 8) {
            return
        }

        let divider = 1;
        switch (this.props.grid_view) {
            case 1:
                divider = 1;
                break;
            case 2:
            case 4:
                divider = 2;
                break;
            case 3:
                divider = 3.3;
                break;
        }

        if(!data) {
            let emptyData = []
            emptyData.push(
                {reason: '', amt: 0, color: ''},
                {reason: '', amt: 0, color: ''}
            )
            return <div className={this.props.grid_view === 4 ? "line-item__column-content--presentation-4  presentation_height" : "line-item__column-content--presentation presentation_height"}>
                <div className={(this.props.grid_view === 4 || this.props.grid_view === 2) ? "scoreboard__chart-full" : "scoreboard__chart"}>
                    <div className="reports__data-lost-time-label font-size-sh">{shouldDisplayOutput ? "Pieces per Hour" : `Hourly ${localStorage.getItem("efficiency_string_long") !== 'undefined' ? localStorage.getItem("efficiency_string_long") : 'Efficiency'}`}</div>
                    <ResponsiveContainer width={(this.state.screenWidth/divider) - 80} height="100%">
                        <BarChart data={emptyData}>
                            <XAxis dataKey="reason" tickLine={false} tick={<CustomAxisTick/>} interval={0}/>
                            <YAxis domain={[0, 100]} interval={0} ticks={[0, 25, 50, 75, 100]} allowDecimals={false}/>
                            <ReferenceLine y={50} label={{value:"LINE NOT RUNNING", className: "no-data"}} stroke="#f2f2f2"/>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        }
        let line = this.props.lines.find(line => {return line.line_id === this.state.line});
        let target = shouldDisplayOutput ? line.target_speed_pieces_per_minute * 60 * (line.line_target_efficiency / 100) : line.line_target_efficiency;

        let ticks = []
        let values = []

        data.map(output => {
            values.push(output.amt)
        })

        let maxValue = Math.max(...values)

        if (shouldDisplayOutput) {
            let minValue = 250
            maxValue = maxValue <= 0 ? minValue : maxValue + (50 % maxValue)
            if(maxValue < minValue) {
                maxValue = minValue
            }
            for(let i = 0; i <= maxValue; i += 50) {
                ticks.push(i)
            }
        } else {
            // maxValue = maxValue <= 0 ? 100 : maxValue + (25 % maxValue)
            maxValue = 100
            if(maxValue < 100) {
                maxValue = 100
            }
            for(let i = 0; i <= maxValue; i += 25) {
                ticks.push(i)
            }
        }


        if(this.props.grid_view === 1) {
            return <div className="line-item__column-content--presentation" style={{height: '600px'}}>
                <div className="scoreboard__chart">
                    <div className="reports__data-lost-time-label font-size-sh">{shouldDisplayOutput ? "Pieces per Hour" : `Hourly ${localStorage.getItem("efficiency_string_long") !== 'undefined' ? localStorage.getItem("efficiency_string_long") : 'Efficiency'}`}</div>
                    <ResponsiveContainer width={1850} height={500}>
                        <BarChart data={data} isAnimationActive={false}>
                            <XAxis dataKey='label' domain={['dataMin', 'dataMax']} interval={0} />
                            <YAxis domain={[0, ticks[ticks.length - 1]]}  tick={{fontSize: 28}} ticks={ticks} interval={0}/>
                            <Tooltip active={true} content={<CustomEfficiencyToolTip output={shouldDisplayOutput} unit={outputUnit}/>} cursor={data.length !== 0}/>
                            <ReferenceLine y={target} stroke="#2967c1" strokeDasharray="10 5" strokeWidth={2} ifOverflow="extendDomain"/>
                            <Bar dataKey='amt'>
                                {
                                    data.map((entry, index) => (
                                        <Cell fill={this.getBarFill(entry)} key={`cell-${index}`}/>
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        }
        return <div className={this.props.grid_view === 4 ? "line-item__column-content--presentation-4 presentation_height" : "line-item__column-content--presentation presentation_height"}>
            <div className={(this.props.grid_view === 4|| this.props.grid_view === 2) ? "scoreboard__chart-full" : "scoreboard__chart"}>
                <div className="reports__data-lost-time-label font-size-sh">{shouldDisplayOutput ? "Pieces per Hour" : `Hourly ${localStorage.getItem("efficiency_string_long") !== 'undefined' ? localStorage.getItem("efficiency_string_long") : 'Efficiency'}`}</div>
                <ResponsiveContainer width={(this.state.screenWidth/divider) - 80} height="100%">
                    <BarChart data={data} isAnimationActive={false}>
                        <XAxis dataKey='label' domain={['dataMin', 'dataMax']} interval={0} />
                        <YAxis domain={[0, ticks[ticks.length - 1]]}  tick={{fontSize: 28}} ticks={ticks} interval={0}/>
                        <Tooltip active={true} content={<CustomEfficiencyToolTip output={shouldDisplayOutput} unit={outputUnit}/>} cursor={data.length !== 0}/>
                        <ReferenceLine y={target} stroke="#2967c1" strokeDasharray="10 5" strokeWidth={2} ifOverflow="extendDomain"/>
                        <Bar dataKey='amt'>
                            {
                                data.map((entry, index) => (
                                    <Cell fill={this.getBarFill(entry)} key={`cell-${index}`}/>
                                ))
                            }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    }

    renderOverspeedOverlay = (line, wrapper, fontSizeValue) => {

        if(parseFloat(line.last_speed_pieces_per_minute) > (parseFloat(line.target_speed_pieces_per_minute) * 1.4)) {

            return <div className={"blink line-item__column-header" + wrapper} style={{
                position: 'absolute',
                top: this.state.DOMRect.top,
                left: this.state.DOMRect.left,
                zIndex: 1000, width: this.state.DOMRect.width,
                backgroundColor: 'rgba(38,193,9,0.7)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div className={fontSizeValue} style={{color: '#fff', marginBottom: "8px"}}>
                    Check Sensor
                </div>
            </div>
        }
    }

    render() {
        let isAgrocrop = false
        let isIppolito = false
        if ( JSON.parse(window.localStorage.getItem("factory")).id === "agrocrop_bolton_2023_11_14" ) {
            isAgrocrop = true
        }
        if ( JSON.parse(window.localStorage.getItem("factory")).id === "ippolito_north_service_2021_03_29" ) {
            isIppolito = true
        }
        let {grid_view} = this.props;
        let item = this.props.lines.find(line => {return line.line_id === this.state.line});
        let data = [];
        let running = (item.line_status !== 'notrunning');
        let down = (item.line_status === 'down' && item.current_event_status !== 'Short Stop');

        const shouldDisplayOutput = Util.shouldDisplayOutput(item)
        const outputDisplayUnit = item?.unit_info?.output?.display_unit_name || '';

        if (running) {
            if (shouldDisplayOutput) {
                item.output_per_hour.map((entry, index) =>
                    data.push({label: this.formatHour(entry.hour_of_day), amt: Math.floor(entry.output * 100) / 100})
                );
            } else {
                item.efficiency_per_hour.map((item, index) =>
                    data.push({label: this.formatHour(item.hour_of_day), amt: Math.floor(item.efficiency_percent*100)/100 })
                );
            }
        } else {
            data = null
        }

        let fontSizeDropdown = '72px';
        let fontSizeProduct = "font-size-hd";
        let fontSizeValue = "font-size-xxl";
        let fontSizeChrono = "font-size-xxl";
        let fontSizeAlert = "font-size-xxl";
        let fontSizeUnit = "font-size-xl";
        let wrapper = "--presentation";
        let alignCenter = '';
        switch(grid_view) {
            case 1:
                fontSizeDropdown = '72px';
                fontSizeProduct = "font-size-xl";
                fontSizeValue = "font-size-presentation";
                fontSizeChrono = "font-size-presentation";
                fontSizeAlert = "font-size-120";
                fontSizeUnit = "font-size-xl";
                wrapper = "--presentation";
                break;
            case 2:
                fontSizeDropdown = '72px';
                fontSizeProduct = "font-size-xl";
                fontSizeValue = "font-size-120";
                fontSizeChrono = "font-size-120";
                fontSizeAlert = "font-size-xxxl";
                fontSizeUnit = "font-size-xl";
                wrapper = "--presentation";
                break;
            case 3:
                fontSizeDropdown = '64px';
                fontSizeProduct = "font-size-xl";
                fontSizeValue = "font-size-xxxl";
                fontSizeChrono = "font-size-xxxl";
                fontSizeAlert = "font-size-xxl";
                fontSizeUnit = "font-size-sh";
                wrapper = "--presentation";
                break;
            case 4:
                fontSizeDropdown = '64px';
                fontSizeProduct = "font-size-xbg";
                fontSizeValue = "font-size-four-up-xxl";
                fontSizeChrono = "font-size-four-up-xxl";
                fontSizeAlert = "font-size-xl";
                fontSizeUnit = "font-size-xbg";
                wrapper = "--presentation-4";
                alignCenter = "align-center";
                break;
            case 8:
                fontSizeDropdown = '64px';
                fontSizeProduct = "font-size-hd";
                fontSizeValue = "font-size-xxxl";
                fontSizeChrono = "font-size-xxxl";
                fontSizeAlert = "font-size-xxl";
                fontSizeUnit = "font-size-sh";
                wrapper = "--presentation-8";
                alignCenter = "align-center";
                break;
        }
//grid_view === 4 ? "presentation_row align-center" :
        return <div className="flex-box__col presentation_height">
            <div className={"line-item__column-header"+wrapper} ref={this.setRef}>
                <div className={this.headerClass(item)}>
                    <div className={grid_view === 3 ? "flex-box__col presentation align-start" : grid_view === 4 ? "flex-box__row presentation justify-space-between"  : "flex-box__col presentation"}>
                        <div className={"presentation_title-column align-center"}>
                            <div className="line-item__column-item__line-name font-size-hd">
                                <Dropdown handleOpenMenu={this.handleOpenMenu} getLabel={this.getLabel} labelStyle={{fontSize: fontSizeDropdown, color: this.getTextColor(item), fontWeight: 600, paddingLeft: 0}}>
                                    {/*<Popover*/}
                                    {/*    open={this.state.open}*/}
                                    {/*    anchorEl={this.state.anchorEl}*/}
                                    {/*    anchorOrigin={this.state.anchorOrigin}*/}
                                    {/*    targetOrigin={this.state.targetOrigin}*/}
                                    {/*    onRequestClose={this.handleClose}>*/}
                                        <Menu open={this.state.open}
                                              anchorEl={this.state.anchorEl}
                                              anchorOrigin={this.state.anchorOrigin}
                                              onClose={this.handleClose} >
                                            {this.iterateLines()}
                                        </Menu>
                                    {/*</Popover>*/}
                                </Dropdown>
                            </div>
                            <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeUnit}>{item.product_name? item.product_name + " - " + item.product_description : ''}</div>
                        </div>
                        {running ? <div className={"presentation_row " + alignCenter}>
                                {isAgrocrop && down ? (
                                    <>
                                        <div className={grid_view === 8 ? "presentation_col--3" : "presentation_col"}>
                                            <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeUnit}>{down ? 'LINE DOWN' : isAgrocrop ? 'FINISHED GOODS' : 'SPEED'}</div>
                                            <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeValue}>
                                                {down ?
                                                    <Chrono item={item} down style={"line-item__column-item__speed-actual-presentation center "  + fontSizeChrono}/> :
                                                    (isAgrocrop ? (
                                                        (item.run_finished_goods_string ?
                                                            new Intl.NumberFormat().format(parseInt(item.run_finished_goods_string)) :
                                                            'N/A')
                                                    ) : (
                                                        (item.last_speed_pieces_per_minute ?
                                                            parseFloat(item.last_speed_pieces_per_minute).toFixed(1) :
                                                            'N/A')
                                                    ))
                                                }
                                            </div>
                                            <div className={"line-item__column-item__speed-actual-presentation--normal center " + fontSizeUnit}>
                                                    {down ? (
                                                    item.current_event_status
                                                    ) : (isAgrocrop ? (
                                                        (item.run_finished_goods_string ? item.unit_info.finished_goods.display_unit_name : '\u00A0')
                                                        ) : (
                                                        (item.last_speed_pieces_per_minute ? item.speed_unit : '\u00A0')
                                                        )
                                                    )
                                                    }
                                                </div>
                                                {grid_view !== 8 && !isAgrocrop && <div className={"line-item__column-item__speed-actual-presentation--normal center margin-bottom-xl " + fontSizeProduct}>
                                                    {down ? '' : 'Target: ' + item.target_speed_pieces_per_minute}
                                                </div>}
                                            </div>
                                            <div className={grid_view === 8 ? "presentation_col--3" : "presentation_col"} style={{height: 'auto'}}>
                                                <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeUnit}>{shouldDisplayOutput ? 'OUTPUT' : (isAgrocrop && down) ? "FINISHED GOODS" : `${localStorage.getItem("efficiency_string_long") !== 'undefined' ? localStorage.getItem("efficiency_string_long").toUpperCase() : 'EFFICIENCY'}`}</div>
                                                <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeValue}>
                                                    {shouldDisplayOutput ?
                                                        item.total_output_pieces ? Util.numberWithCommas(item.total_output_pieces) : 'N/A' :
                                                        (isAgrocrop && down) ? new Intl.NumberFormat().format(parseInt(item.run_finished_goods_string)) :
                                                        (parseFloat(item.daily_efficiency)).toFixed(1)}
                                                </div>
                                                <div className={"line-item__column-item__speed-actual-presentation--normal center " + fontSizeUnit}>
                                                    {shouldDisplayOutput ?
                                                        item.total_output_pieces ? outputDisplayUnit : 'N/A':
                                                        (isAgrocrop && down) ? item.unit_info.finished_goods.display_unit_name :
                                                        '%'}
                                                </div>
                                            </div>
                                    </>
                                ) : (
                                    <>
                                        {(isIppolito && grid_view === 1) ? (
                                            <>
                                                <div className={grid_view === 8 ? "presentation_col--3" : "presentation_col"} style={{height: 'auto'}}>
                                                    <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeUnit}>{ down ? "LINE DOWN" : 'EFFICIENCY'}</div>
                                                    <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeValue}>
                                                        {down ? 
                                                            // '22:22:22'
                                                            <Chrono item={item} down style={"line-item__column-item__speed-actual-presentation center "  + fontSizeChrono}/>
                                                        : 
                                                            // '21.9'
                                                            parseFloat(item.daily_efficiency).toFixed(1)
                                                        }
                                                    </div>
                                                    <div className={"line-item__column-item__speed-actual-presentation--normal center " + fontSizeUnit}>
                                                        {down ? "Uncategorized" : '%'}
                                                    </div>
                                                </div>
                                                <div className={grid_view === 8 ? "presentation_col--3" : "presentation_col"} style={{height: 'auto'}}>
                                                    <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeUnit}>{'TARGET'}</div>
                                                    <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeValue}>
                                                        {/* {'5402'} */}
                                                        {!!item["custom_info_last_150_pulses"] && !!item["custom_info_last_150_pulses"].output_percentage ? item["custom_info_last_150_pulses"].output_percentage.toFixed(1) : "0"}
                                                    </div>
                                                    <div className={"line-item__column-item__speed-actual-presentation--normal center " + fontSizeUnit}>
                                                        {'%'}
                                                    </div>
                                                </div>
                                                <div className={grid_view === 8 ? "presentation_col--3" : "presentation_col"} style={{height: 'auto'}}>
                                                    <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeUnit}>{'OVERFILLED'}</div>
                                                    <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeValue}>
                                                        {/* {'260'} */}
                                                        {!!item["custom_info_last_150_pulses"] && !!item["custom_info_last_150_pulses"].waste_percentage ? item["custom_info_last_150_pulses"].waste_percentage.toFixed(1) : "0"}
                                                    </div>
                                                    <div className={"line-item__column-item__speed-actual-presentation--normal center " + fontSizeUnit}>
                                                        {'%'}
                                                    </div>
                                                </div>
                                                <div className={grid_view === 8 ? "presentation_col--3" : "presentation_col"} style={{height: 'auto'}}>
                                                    <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeUnit}>{'REJECT'}</div>
                                                    <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeValue}>
                                                        {/* {'4.7'} */}
                                                        {!!item["custom_info_last_150_pulses"] && !!item["custom_info_last_150_pulses"].rework_percentage ? item["custom_info_last_150_pulses"].rework_percentage.toFixed(1) : "0"}
                                                    </div>
                                                    <div className={"line-item__column-item__speed-actual-presentation--normal center " + fontSizeUnit}>
                                                        {'%'}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={grid_view === 8 ? "presentation_col--3" : "presentation_col"} style={{height: 'auto'}}>
                                                    <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeUnit}>{shouldDisplayOutput ? 'OUTPUT' : (isAgrocrop && down) ? "FINISHED GOODS" : `${localStorage.getItem("efficiency_string_long") !== 'undefined' ? localStorage.getItem("efficiency_string_long").toUpperCase() : 'EFFICIENCY'}`}</div>
                                                    <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeValue}>
                                                        {shouldDisplayOutput ?
                                                            item.total_output_pieces ? Util.numberWithCommas(item.total_output_pieces) : 'N/A' :
                                                            (isAgrocrop && down) ? new Intl.NumberFormat().format(parseInt(item.run_finished_goods_string)) :
                                                            (parseFloat(item.daily_efficiency)).toFixed(1)}
                                                    </div>
                                                    <div className={"line-item__column-item__speed-actual-presentation--normal center " + fontSizeUnit}>
                                                        {shouldDisplayOutput ?
                                                            item.total_output_pieces ? outputDisplayUnit : 'N/A':
                                                            (isAgrocrop && down) ? item.unit_info.finished_goods.display_unit_name :
                                                            '%'}
                                                    </div>
                                                </div>
                                                <div className={grid_view === 8 ? "presentation_col--3" : "presentation_col"}>
                                                    <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeUnit}>{down ? 'LINE DOWN' : isAgrocrop ? 'FINISHED GOODS' : 'SPEED'}</div>
                                                    <div className={"line-item__column-item__speed-actual-presentation center " + fontSizeValue}>
                                                        {down ?
                                                            <Chrono item={item} down style={"line-item__column-item__speed-actual-presentation center "  + fontSizeChrono}/> :
                                                            (isAgrocrop ? (
                                                                (item.run_finished_goods_string ?
                                                                    new Intl.NumberFormat().format(parseInt(item.run_finished_goods_string)) :
                                                                    'N/A')
                                                            ) : (
                                                                (item.last_speed_pieces_per_minute ?
                                                                    parseFloat(item.last_speed_pieces_per_minute).toFixed(1) :
                                                                    'N/A')
                                                            ))
                                                        }
                                                    </div>
                                                    <div className={"line-item__column-item__speed-actual-presentation--normal center " + fontSizeUnit}>
                                                        {down ? (
                                                        item.current_event_status
                                                        ) : (isAgrocrop ? (
                                                            (item.run_finished_goods_string ? item.unit_info.finished_goods.display_unit_name : '\u00A0')
                                                            ) : (
                                                            (item.last_speed_pieces_per_minute ? item.speed_unit : '\u00A0')
                                                            )
                                                        )
                                                        }
                                                    </div>
                                                    {grid_view !== 8 && !isAgrocrop && <div className={"line-item__column-item__speed-actual-presentation--normal center margin-bottom-xl " + fontSizeProduct}>
                                                        {down ? '' : 'Target: ' + item.target_speed_pieces_per_minute}
                                                    </div>}
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                                {grid_view === 8 && !isAgrocrop && <div className={"presentation_col--3 " + fontSizeProduct}>
                                    {down ? '' : 'Target: ' + item.target_speed_pieces_per_minute}
                                </div>}
                        </div> : <div className={grid_view === 4 ? "presentation_row font-size-xxl" : "presentation_row font-size-xxxl"}>LINE NOT RUNNING</div>}
                    </div>
                </div>
            </div>
            {this.showDetails(data, shouldDisplayOutput, outputDisplayUnit)}
        </div>

    }
}

export default LinePresentation