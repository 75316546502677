import React, { Component, useCallback, useEffect, useState } from 'react';
// import {
//     Table,
//     TableHead,
//     TableCell,
//     TableRow,
//     TableBody,
//     Button,
// } from '@material-ui/core';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import ReactPaginate from 'react-paginate';
import { Dialog, Icon, Paper, TablePagination, Typography } from '@mui/material';
import { Button } from '@material-ui/core'

import { useDropzone } from 'react-dropzone'
import ImportProductListModal from '../modals/ImportProductListModal';
import ViewAllImportErrorsModal from '../modals/ViewAllImportErrorsModal';
import { Link } from 'react-router-dom';

import xlsxIcon from './misc/xlsx.svg'
import downloadIcon from './misc/FileDownloadOutlined.svg'


const ImportExport = (props) => {
    const [imports, setImports] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [offset, setOffset] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isViewAllModalOpen, setIsViewAllModalOpen] = useState(false)
    const [viewAllModalCategory, setViewAllModalCategory] = useState()
    const [validate, setValidate] = useState(null)
    const [droppedFile, setDroppedFile] = useState(null)
    const [error, setError] = useState()


    
    const styles = {
        backButton: {
            marginLeft: "20px",
            marginTop: "8px"
        },
        dropzoneContainer: {
            margin: "20px",
            height: "110px",
            cursor: "pointer",
            border: "2px dashed #0A71CE",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none"
        },
        dropzoneText: {
            color: "#0A71CE"
        },
        uploadFileIcon: {
            color: "#0A71CE",
            marginRight: "8px"
        },
        errorsContainer: {
            background: "#D32F2F",
            padding: "16px",
            margin: "20px",
            borderRadius: "4px"
        },
        errorContainerTitleBox: {
            display: "flex",
            alignItems: "center",
            background: "none",
            boxShadow: "none"
        },
        errorContainerTitle: {
            color: "#ffffff",
            marginLeft: "8px"
        },
        errorContainerUl: {
            marginLeft: "42px",
            color: "#ffffff",
            marginTop: "12px"
        },
        errorContainerHelpBox: {
            color: "#ffffff",
            marginTop: "24px"
        },
        errorContainerHelpLink: {
            textDecoration: "none",
            color: "#A2BBD2"
        },
        historyContainer: {
            margin: "20px",
            boxShadow: "none"
        },
        historyTitle: {
            fontSize: "16px",
            textTransform: "uppercase",
            fontWeight: "400",
            marginTop: "8vh"
        },
        historyTableRow: {
            height: '20px',
            paddingTop: '20px'
        },
        historyTableCell: {
            width: "8%"
        },
        historyItemTableCell: {
            verticalAlign: 'top',
            width: "8%"
        },
        cardsContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "29px 20px 33px 20px",
            boxShadow: "none"
        },
        cardContainer: {
            width: "60%",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
            height: "140px",
            padding: "24px",
            boxShadow: "none",
            position: "relative",
        },
        insideCardContainer: {
            display: "flex",
            width: "70%",
            "@media (max-width: 1422px)": {
                width: "100%"
            },
            boxShadow: "none",
        },
        insideCardImageContainer: {
            marginRight: "24px",
            height: "82px",
            width: "82px",
            minWidth: "82px",
            background: "rgba(10, 113, 206, 0.1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none"
        },
        insideCardTextContainer: {
            maxWidth: "380px",
            boxShadow: "none"
        },
        insideCardTitle: {
            fontSize: "16px",
            color: "#000",
            fontWeight: "600",
            marginBottom: "10px"
        },
        insideCardSubtitle: {
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.5)",
            fontWeight: "500",
            lineHeight: "16px",
            "@media (max-width: 1100px)": {
                fontSize: "10px"
            },
        },
        insideCardButton: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: "24px",
            right: "24px"
        },
        insideCardButtonIcon: {
            marginRight: "8px"
        }
    }


    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
                setError(null)
                const fileData = new FormData();
                fileData.append('file', new Blob([file]));

                setDroppedFile(fileData)

                let params = {
                    path: "validate_product_import",
                    data: fileData,
                    // success: (data) => setValidate(data),
                    success: (data) => setValidate(data),
                    error: (error) => setError(error)
                }
                API.c(params, 0, 0, 1)

                setIsModalOpen(true)
          })
      }, [])

    const {acceptedFiles, getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({
        accept: {
            // 'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
        maxFiles: 1,
        onDrop,
    });

    /* ######## LIFECYCLE METHODS ######## */

    useEffect(() => {
        document.title = "LiveTracking | Import / Export";
        const headerContainer = document.getElementsByClassName("settings__subhead")[0];
        headerContainer.innerHTML = `<h5 class="MuiTypography-root MuiTypography-h5">Product Settings</h5>`

        getHistory()
    }, [])

    const submitImport = () => {
        let params = {
            path: "product_import",
            data: droppedFile,
            success: (data) => { getHistory() },
            error: () => {}
        }
        API.c(params, 0, 0, 1)

        // hideModal()
    }

    const hideModal = () => {
        setIsModalOpen(false);
        setValidate(null);
    }

    const downloadFile = (data) => {
        const url = window.URL.createObjectURL(new Blob([data])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', "product_list.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    const getHistory = () => {
        let params = {
            path: 'get_product_import_history',
            success: onGetHistorySuccess,
            error: () => {}
        };

        API.c(params, 2);
    }

    const onGetHistorySuccess = data => {
        setImports(data)
    }

    const getCSV = () => {
        let params = {
            path: 'get_product_export_scv',
            success: onGetCSVSuccess,
            error: () => {}
        };

        API.c(params, 0, 1);
    }

    const onGetCSVSuccess = data => {
        downloadFile(data)
    }


    const convertDateLong = date => {
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
          ];

        const weekNames = [
            "Sun", "Mon", "Tue", "Wed", "Thu",
            "Fri", "Sat"
        ]

        const newDate = new Date(date)
        const dayOfTheWeek = newDate.getDay()
        const day = newDate.getDate()
        const month = newDate.getMonth()
        const year = newDate.getFullYear()

        const displayDate = `${weekNames[dayOfTheWeek]}, ${monthNames[month]} ${day}, ${year}`

        return displayDate
    }

    const convertDateShort = date => {
        const newDate = new Date(date)
        const dayOfTheWeek = newDate.getDay()
        const day = newDate.getDate()
        const month = newDate.getMonth() + 1
        const year = newDate.getFullYear()
        const time = newDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });

        const displayDate = `${month >= 10 ? month : `0${month}`}.${day >= 10 ? day : `0${day}`}.${year} ${time}`

        return displayDate
    }

    const convertTime = date => {
        const newDate = new Date(date);
        const time = newDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
        return time;
    }

    const onOpenViewAllModal = (category) => {
        setIsViewAllModalOpen(true)
        setViewAllModalCategory(category)
    }

    const hideViewAllModal = () => {
        setIsViewAllModalOpen(false)
        setViewAllModalCategory()
    }

    const handlePageClick = (event, page) => {
        let selected = page
        let offset = Math.ceil(selected * 20);
        setCurrentPage(selected)
        setOffset(offset)
        // offset: offset, currentPage: selected });
    };

    const renderChildren = () => {
        let pageCount = Math.ceil(imports.length / 20);
        return (
            
            <Paper sx={{ boxShadow: "none" }}>
                <Paper sx={{ boxShadow: "none" }}>
                    <Button
                        style={styles.backButton}
                        variant="text"
                        color="primary"
                        component={Link}
                        to={`/settings/products`}
                    >
                        Back to all products
                    </Button>
                </Paper>
                <Paper sx={styles.cardsContainer}>


                    <Paper sx={{...styles.cardContainer, marginRight: "12px" }}>
                        <Paper sx={styles.insideCardContainer}>
                            <Paper sx={styles.insideCardImageContainer}>
                                <img src={xlsxIcon} />
                            </Paper>
                            <Paper sx={styles.insideCardTextContainer}>
                                <Typography sx={styles.insideCardTitle}>
                                    Add New Products
                                </Typography>
                                <Typography sx={styles.insideCardSubtitle}>
                                    To add new products, start by downloading the blank product Excel template
                                </Typography>
                            </Paper>
                            <a target="_blank" href="https://lt-product-export.s3.ca-central-1.amazonaws.com/LiveTracking-Product-Export-Template.xlsx">
                                <Button variant="contained" color="primary" style={styles.insideCardButton}>
                                    <img style={styles.insideCardButtonIcon} src={downloadIcon} />
                                    DOWNLOAD BLANK PRODUCT TEMPLATE
                                </Button>
                            </a>
                        </Paper>
                    </Paper>

                    <Paper sx={{...styles.cardContainer, marginLeft: "12px" }}>
                        <Paper sx={styles.insideCardContainer}>
                            <Paper sx={styles.insideCardImageContainer}>
                                <img src={xlsxIcon} />
                            </Paper>
                            <Paper sx={styles.insideCardTextContainer}>
                                <Typography sx={styles.insideCardTitle}>
                                    Update Existing Products
                                </Typography>
                                <Typography sx={styles.insideCardSubtitle}>
                                    To edit existing products, start by downloading the existing Excel product list
                                </Typography>
                            </Paper>
                                <Button onClick={getCSV} variant="contained" color="primary" style={styles.insideCardButton}>
                                    <img style={styles.insideCardButtonIcon} src={downloadIcon} />
                                    download existing product list
                                </Button>
                        </Paper>
                    </Paper>


                    {/* <div>
                        <p style={{ fontSize: "0.833vw" }}>To add new products, start by downloading a blank product list. Start by clicking on <span style={{ fontWeight: "700" }}>“Download Product Template (.xlsx)”</span></p>
                        <p style={{ fontSize: "0.833vw" }}>To edit existing products, download a copy of your products. Start by clicking on<span style={{ fontWeight: "700" }}>“Download Existing Product List (.xlsx)”</span></p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "end", alignContent: "center" }}>
                        <a target="_blank" href="https://lt-product-export.s3.ca-central-1.amazonaws.com/LiveTracking-Product-Export-Template.xlsx">
                            <Button variant="contained" color="primary" style={{ marginRight: "48px" }}>
                                Download Excel Template
                            </Button>
                        </a>
                        <Button onClick={getCSV} variant="contained" color="primary">
                            Export Product List
                        </Button>
                    </div> */}
                </Paper>
                <section className="container">
                    <Paper {...getRootProps({className: 'dropzone'})} sx={styles.dropzoneContainer}>
                        <input accept=".png" {...getInputProps()} />
                        <UploadFileIcon style={styles.uploadFileIcon} />
                        <Typography sx={styles.dropzoneText}>
                            {`${isDragActive ? "DROP HERE" : "UPLOAD FILE"}`}
                        </Typography>
                    </Paper>
                </section>
                {error ? (
                    <Paper sx={styles.errorsContainer}>
                        <Paper sx={styles.errorContainerTitleBox}>
                            <ErrorOutlineIcon sx={{ color: "#ffffff" }} />
                            <Typography sx={styles.errorContainerTitle}>
                                There has been an error uploading this file
                            </Typography>
                        </Paper>
                        {(error["File structure"] && error["File structure"].length > 0) ||
                         (error["Invalid values"] && error["Invalid values"].length > 0) || 
                         (error["Line default units"] && error["Line default units"].length > 0) ||
                         (error["Not found"] && error["Not found"].length > 0) ? (
                            <ul style={styles.errorContainerUl}>
                                {Object.keys(error).map((errorCategory, i) => (
                                    <>
                                        {error[errorCategory].length > 0 ? (
                                            <li key={i}>
                                                <span style={{ marginRight: "6px" }}>
                                                    •
                                                </span>
                                                <span>
                                                    {errorCategory}
                                                </span>
                                                <span style={{ marginLeft: "6px", textDecoration: "underline", cursor: "pointer" }} onClick={() => onOpenViewAllModal(errorCategory)}>
                                                    view all ({error[errorCategory].length})
                                                </span>
                                            </li>
                                        ) : null}
                                    </>
                                ))}
                            </ul>
                        ) : <ul style={errorContainerUl}>
                                <li key={"unexpected_error"} >
                                    <span style={{ marginRight: "6px" }}>•</span>
                                    <span>Something unexpected happened. Please try again.</span>
                                </li>
                            </ul>}
                        <p style={styles.errorContainerHelpBox}>
                            {"For additional help, please email "}
                            <a style={styles.errorContainerHelpLink} href='mailto:support@livetracking.io'>
                                {"support@livetracking.io "}
                            </a>
                            {"or visit "}
                            <a style={styles.errorContainerHelpLink} target="_blank" href="https://help.livetracking.io/knowledge/kb-tickets/new">
                                {"https://help.livetracking.io "}
                            </a>
                        </p>
                    </Paper>
                ) : null}
                <Paper className="settings__table" sx={styles.historyContainer}>
                    <Typography style={styles.historyTitle}>
                        Historical Imports
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow style={styles.historyTableRow}>
                                    <TableCell
                                        style={styles.historyTableCell}
                                        align={'left'}
                                        className={'products_table_head_cell'}
                                    >
                                        <div>Date</div>
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        className={'products_table_head_cell'}
                                        style={styles.historyTableCell}
                                    >
                                        <div>Records in file</div>
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        style={styles.historyTableCell}
                                        className={'products_table_head_cell'}
                                    >
                                        <div>Added</div>
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        style={styles.historyTableCell}
                                        className={'products_table_head_cell'}
                                    >
                                        <div>Edited</div>
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        style={styles.historyTableCell}
                                        className={'products_table_head_cell'}
                                    >
                                        <div>Archived</div>
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        style={styles.historyTableCell}
                                        className={'products_table_head_cell'}
                                    >
                                        <div>Unchanged</div>
                                    </TableCell>
                                    <TableCell
                                        align={'left'}
                                        style={styles.historyTableCell}
                                        className={'products_table_head_cell'}
                                    >
                                        <div>Status</div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{showImportList()}</TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                { imports.length > 0 ? (
                    // <div className="flex-box__row justify-center">
                    //     <ReactPaginate
                    //         forcePage={currentPage}
                    //         previousLabel={'Previous'}
                    //         nextLabel={'Next'}
                    //         breakLabel={'...'}
                    //         pageCount={pageCount}
                    //         previousLinkClassName={'page-previous'}
                    //         nextLinkClassName={'page-next'}
                    //         marginPagesDisplayed={2}
                    //         pageRangeDisplayed={5}
                    //         onPageChange={() => {}}
                    //         containerClassName={'pagination'}
                    //         pageClassName={'page'}
                    //         pageLinkClassName={'page-link'}
                    //         subContainerClassName={'pages pagination'}
                    //         activeClassName={'active'}
                    //     />
                    // </div>
                    <TablePagination
                        rowsPerPageOptions={[20]}
                        component="div"
                        count={imports.length}
                        rowsPerPage={20}
                        page={currentPage}
                        onPageChange={handlePageClick}
                    />
                ) : null }
            </Paper>

        )
    }

    const showImportList = () => {
        const importsArray = []
        for(let i = currentPage * 20; i < (currentPage + 1) * 20; i++) {
            if (imports[i]) {
                importsArray.push(imports[i])
            }   
        }
        if (importsArray.length === 0) {
            return (
                <div style={{ position: "absolute", left: "45%", marginTop: "10px", textAlign: "center" }}>
                    No imports
                </div>
            )
        }
        return importsArray.map((item, index) => {
            return (
                <TableRow key={'import-' + index}>
                    <TableCell  style={styles.historyItemTableCell} align={'left'} className={'logs_table_body_cell'}>
                        {convertDateLong(item.created_at)}
                    </TableCell>
                    <TableCell  style={styles.historyItemTableCell} align={'left'} className={'logs_table_body_cell'}>
                        {item.records_in_file}
                    </TableCell>
                    <TableCell  style={styles.historyItemTableCell} align={'left'} className={'logs_table_body_cell'}>
                        {item.added}
                    </TableCell>
                    <TableCell  style={styles.historyItemTableCell} align={'left'} className={'logs_table_body_cell'}>
                        {item.edited}
                    </TableCell>
                    <TableCell  style={styles.historyItemTableCell} align={'left'} className={'logs_table_body_cell'}>
                        {item.archived}
                    </TableCell>
                    <TableCell  style={styles.historyItemTableCell} align={'left'} className={'logs_table_body_cell'}>
                        {item.unchanged}
                    </TableCell>
                    <TableCell  style={styles.historyItemTableCell} align={'left'} className={'logs_table_body_cell'}>
                        {item.status}
                    </TableCell>
                </TableRow>
            )
        })

        // return (<></>)
    };

    return <div style={{ marginTop: '20px' }}>
        <div>
            {renderChildren()}
            {isModalOpen && validate ? (
                <Dialog open={true} onClose={() => setIsModalOpen(false)} maxWidth="false" sx={{  }}>
                    <ImportProductListModal
                        validate={validate}
                        submitImport={() => submitImport()}
                        hideModal={() => hideModal() }
                        getCSV={() => getCSV()}
                        // id={this.state.editedCategoryId}
                        // name={this.state.editedCategoryName}
                    />
                </Dialog>
            ) : isViewAllModalOpen ? (
                <Dialog open={true} onClose={() => setIsModalOpen(false)} maxWidth="false" sx={{  }}>
                    <ViewAllImportErrorsModal
                        category={viewAllModalCategory}
                        error={error}
                        hideModal={() => hideViewAllModal() }
                    />
                </Dialog>
            ) : null}
        </div>
    </div>
}

export default ImportExport