import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

export default function EndRun(props) {
    const classes = useStyles();
    const [finishedGoods, setFinishedGoods] = useState(props.finishedGoods);
    const [finishedGoodsError, setFinishedGoodsError] = useState(null);

    function handleFinishedGoodsChange(event) {
        setFinishedGoods(event.target.value)
    }

    function onEndRunClick() {
        if(Number.isNaN(finishedGoods)) {
            setFinishedGoodsError('only numbers allowed')
        } else if(finishedGoods < 0) {
            setFinishedGoodsError('must be 0 or more')
        } else {
            setFinishedGoodsError(null)
            let params = {
                path: "end_run",
                path_variables: {RUN_ID: props.runId},
                data: {
                    end_time: new Date().toISOString(),
                    total_finished_goods: parseFloat(finishedGoods)
                },
                success: props.onEndRunSuccess,
                error: onEndRunError
            }

            API.c(params, 2)
        }
    }

    function onEndRunError(error) {
        if(error && error.message === "Run has already ended") {
            props.onEndRunSuccess()
        } else {
            console.error("Error Ending Run", error)
        }
    }

    return <Dialog open={true} maxWidth={'md'}>
        <DialogTitle disableTypography>
            <Typography variant={'h6'}>End Run</Typography>
        </DialogTitle>
        <DialogContent className={'liveview-add-run__dialog-content'}>
            <Typography variant={'subtitle2'}
                        color={'secondary'}>{props.line.line_name}</Typography>
            <TextField className={classes.textField}
                       label="Finished Goods"
                       value={finishedGoods}
                       onChange={handleFinishedGoodsChange}
                       type={'number'}
                       error={finishedGoodsError !== null}
                       helperText={finishedGoodsError !== null ? finishedGoodsError : ''}/>
        </DialogContent>
        <DialogActions>
            <Button variant={'text'}
                    color={'primary'}
                    onClick={props.hideEndRun}>Cancel</Button>
            <Button variant={'contained'}
                    color={'primary'}
                    disabled={finishedGoods === ''}
                    onClick={onEndRunClick}>Confirm</Button>
        </DialogActions>
    </Dialog>
}

const useStyles = makeStyles((theme) => ({
    textField: {
        minWidth: '250px'
    }
}))