import React, { Component } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Stepper,
    Step,
    StepLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    InputAdornment,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    FormHelperText,
    CircularProgress,
    FormControlLabel,
    Switch,
    Tooltip,
    IconButton,
    ClickAwayListener,
    Snackbar,
    Checkbox
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import UnitRow from '../components/settings/products/UnitRow';
import UnitChangeConfirmationRow from '../components/settings/products/UnitChangeConfirmationRow';
import { ArrowDropDown, HelpOutline, Memory, Speed, Timelapse } from '@material-ui/icons';
import ReworkIcon from '../components/icons/ReworkIcon';
import WasteIcon from '../components/icons/WasteIcon';
import FinishedGoodsIcon from '../components/icons/FinishedGoodsIcon';
import clsx from 'clsx';
import MultiDatesPicker from './MultiDatesPicker';
import hourglassImage from './misc/emoji_hourglass_.svg'

class ManageLinesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            activeStep: 0,
            status: this.getOriginalStatus(),
            target_speed: this.getOriginalTargetSpeed(),
            speed_unit: 'strokes/min',
            sensor_count_values: this.getOriginalUnitValue('output', props.product_on_line_units),
            speed_values: this.getOriginalUnitValue('speed', props.product_on_line_units),
            waste_values: this.getOriginalUnitValue('waste', props.product_on_line_units),
            rework_values: this.getOriginalUnitValue('rework', props.product_on_line_units),
            finished_goods_values: this.getOriginalUnitValue('finished_goods', props.product_on_line_units),
            sensor_count_unit_type: this.getOriginalUnitTypeId('output', props.product_on_line_units),
            speed_unit_type: this.getOriginalUnitTypeId('speed', props.product_on_line_units),
            waste_unit_type: this.getOriginalUnitTypeId('waste', props.product_on_line_units),
            rework_unit_type: this.getOriginalUnitTypeId('rework', props.product_on_line_units),
            finished_goods_unit_type: this.getOriginalUnitTypeId('finished_goods', props.product_on_line_units),
            sensor_count_display_unit_type: this.getOriginalUnitTypeId('output', props.product_on_line_units, true),
            speed_display_unit_type: this.getOriginalUnitTypeId('speed', props.product_on_line_units, true),
            waste_display_unit_type: this.getOriginalUnitTypeId('waste', props.product_on_line_units, true),
            rework_display_unit_type: this.getOriginalUnitTypeId('rework', props.product_on_line_units, true),
            finished_goods_display_unit_type: this.getOriginalUnitTypeId(
                'finished_goods',
                props.product_on_line_units,
                true
            ),
            speed_unit_conversion_value: this.getOriginalBaseUnitConversion('speed', props.product_on_line_units),
            waste_unit_conversion_value: this.getOriginalBaseUnitConversion('waste', props.product_on_line_units),
            rework_unit_conversion_value: this.getOriginalBaseUnitConversion('rework', props.product_on_line_units),
            finished_goods_unit_conversion_value: this.getOriginalBaseUnitConversion(
                'finished_goods',
                props.product_on_line_units
            ),
            sensor_count_change: false,
            speed_change: false,
            waste_change: false,
            rework_change: false,
            finished_goods_change: false,
            calls: 0,
            use_line_defaults: this.getUseLineDefaults(props.product_on_line_units),
            show_snackbar: false,
            recalculateHistoricalData: false,
            recalculateAll: false,
            recalculateInDates: false,
            recalculateDates: [],
        };
    }

    /* -------- LIFECYCLE METHODS -------- */

    /* -------- FUNCTIONS -------- */
    handleNext = () => {
        if (this.state.activeStep === 0) {
            this.setState({ activeStep: 1 });
        }
        else if (this.state.activeStep === 1) {
            if (!this.state.recalculateHistoricalData) {
                this.setState({ activeStep: 2 });
            }
            if (this.state.recalculateHistoricalData && (this.state.recalculateAll || this.state.recalculate_date_from)) {
                this.setState({ activeStep: 2, recalculateDateError: false });
            }
            else if (this.state.recalculateHistoricalData && !this.state.recalculateAll && !this.state.recalculate_date_from) {
                this.setState({ recalculateDateError: true })
            }
        }
        else {
            this.submitProductOnLineUpdate();
        }
    };

    handleBack = () => {
        if (this.state.activeStep === 1) {
            this.setState({ activeStep: 0 });
        }
        else if (this.state.activeStep === 2) {
            this.setState({ activeStep: 1 })
        }
        else {
            this.props.hideModal();
        }
    };

    getLineNames = () => {
        let { lines, line_ids } = this.props;
        let lineNames = '';

        line_ids.forEach((id, i) => {
            let line = lines.find(l => l.line_id === id);
            if (i === line_ids.length - 1) {
                lineNames += line.line_name;
            } else {
                lineNames += line.line_name + ', ';
            }
        });
        return lineNames;
    };

    handleChange(event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    handleLineDefaultSwitchChange = event => {
        this.setState({
            use_line_defaults: event.target.checked,
            show_snackbar: !event.target.checked
        });
        if (event.target.checked) {
            this.resetToLineDefaults();
        }
    };

    handleLineDefaultChangeUndo = event => {
        this.setState({
            use_line_defaults: true,
            show_snackbar: event.target.checked
        });
    };

    handleStatusDropDownChange = event => {
        //TODO add resetting values to line default
        this.setState({
            status: event.target.value
        });
    };

    getSubValue = type => {
        let subValues = [];
        switch (type) {
            case 'output':
                subValues = this.state.sensor_count_values;
                break;
            case 'speed':
                subValues = this.state.speed_values;
                break;
            case 'rework':
                subValues = this.state.rework_values;
                break;
            case 'waste':
                subValues = this.state.waste_values;
                break;
            case 'finished_goods':
                subValues = this.state.finished_goods_values;
        }

        return subValues;
    };

    setSubValues = (type, values) => {
        this.setChange(type);
        switch (type) {
            case 'output':
                this.setState({
                    sensor_count_values: values
                });
                break;
            case 'speed':
                this.setState({
                    speed_values: values
                });
                break;
            case 'rework':
                this.setState({
                    rework_values: values
                });
                break;
            case 'waste':
                this.setState({
                    waste_values: values
                });
                break;
            case 'finished_goods':
                this.setState({
                    finished_goods_values: values
                });
        }
    };

    onConversionValueChange = (type, event) => {
        this.setChange(type);
        switch (type) {
            case 'speed':
                this.setState({
                    speed_unit_conversion_value: event.target.value
                });
                break;
            case 'rework':
                this.setState({
                    rework_unit_conversion_value: event.target.value
                });
                break;
            case 'waste':
                this.setState({
                    waste_unit_conversion_value: event.target.value
                });
                break;
            case 'finished_goods':
                this.setState({
                    finished_goods_unit_conversion_value: event.target.value
                });
                break;
        }
    };

    onValueChange = (type, event) => {
        let subValues = this.getSubValue(type);

        let newSubValue = [...subValues];
        newSubValue[0] = event.target.value;

        this.setSubValues(type, newSubValue);
    };

    onNotesChange = (type, index, event) => {
        let subValues = this.getSubValue(type);

        let newSubValue = [...subValues];
        newSubValue[index]['notes'] = event.target.value;

        this.setSubValues(type, newSubValue);
    };

    addSubValue = type => {
        let subValues = this.getSubValue(type);

        let newSubValue = [...subValues];
        if (!newSubValue[newSubValue.length - 1] || newSubValue[newSubValue.length - 1].value !== '') {
            newSubValue.push('');
            this.setSubValues(type, newSubValue);
        }
    };

    onUnitTypeChange = (type, event) => {
        this.setChange(type);
        switch (type) {
            case 'output':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        sensor_count_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        sensor_count_display_unit_type: event.target.value
                    });
                }
                break;
            case 'speed':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        speed_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        speed_display_unit_type: event.target.value
                    });
                }
                break;
            case 'rework':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        rework_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        rework_display_unit_type: event.target.value
                    });
                }
                break;
            case 'waste':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        waste_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        waste_display_unit_type: event.target.value
                    });
                }
                break;
            case 'finished_goods':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        finished_goods_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        finished_goods_display_unit_type: event.target.value
                    });
                }
                break;
        }
    };

    setChange = type => {
        switch (type) {
            case 'output':
                this.setState({
                    sensor_count_change: true
                });
                break;
            case 'speed':
                this.setState({
                    speed_change: true
                });
                break;
            case 'rework':
                this.setState({
                    rework_change: true
                });
                break;
            case 'waste':
                this.setState({
                    waste_change: true
                });
                break;
            case 'finished_goods':
                this.setState({
                    finished_goods_change: true
                });
                break;
            default:
                this.setState({
                    sensor_count_change: true,
                    speed_change: true,
                    rework_change: true,
                    waste_change: true,
                    finished_goods_change: true
                });
        }
    };

    getUnitType = type => {
        let { units } = this.props;
        let unit_id = -1;
        let unit_type = '';

        switch (type) {
            case 'output':
                unit_id = this.state.sensor_count_unit_type;
                break;
            case 'speed':
                unit_id = this.state.speed_unit_type;
                break;
            case 'speed_display':
                unit_id = this.state.speed_display_unit_type;
                break;
            case 'rework':
                unit_id = this.state.rework_unit_type;
                break;
            case 'waste':
                unit_id = this.state.waste_unit_type;
                break;
            case 'finished_goods':
                unit_id = this.state.finished_goods_unit_type;
        }

        let unit = units.find(u => u.id === unit_id);

        if (unit) {
            unit_type = unit.name;
        }

        return unit_type;
    };

    getOriginalUnitTypeText = unit_id => {
        let { units } = this.props;
        let unit_type = '';
        let unit = units.find(u => u.id === unit_id);

        if (unit) {
            unit_type = unit.name;
        }

        return unit_type;
    };

    getOriginalUnitTypeId = (type, unitsArr, display = false) => {
        if (this.props.line_ids.length > 1) {
            return '';
        }

        let line_id = this.props.line_ids[0];
        let { units } = this.props;

        if (!unitsArr) {
            return '';
        }
        let line = unitsArr.find(l => l.line_id === line_id);
        if (!line) {
            return '';
        }

        let unitObj = line.units[type];

        if (!unitObj) {
            return '';
        }

        let unitType = '';
        let unit_id = display ? unitObj.display_unit_id : unitObj.input_unit_id;
        let unitItem = units.find(u => u.id === unit_id);
        if (unitItem) {
            unitType = unitItem.id;
        }

        return unitType ? unitType : '';
    };

    getOriginalBaseUnitConversion = (type, unitsArr) => {
        if (this.props.line_ids.length > 1) {
            return '';
        }

        let line_id = this.props.line_ids[0];
        let { units } = this.props;

        if (!unitsArr) {
            return '';
        }

        let line = unitsArr.find(l => l.line_id === line_id);
        if (!line) {
            return '';
        }

        let unitObj = line.units[type];

        if (!unitObj) {
            return '';
        }

        return unitObj.base_unit_conversion_value ? unitObj.base_unit_conversion_value : '';
    };

    getUseLineDefaults = unitsArr => {
        let line_id = this.props.line_ids[0];
        let { units } = this.props;

        if (!unitsArr) {
            return '';
        }

        let line = unitsArr.find(l => l.line_id === line_id);
        if (!line || !line.units) {
            return '';
        }

        return line.units.uses_line_default_unit_conversions;
    };

    getOriginalStatus = () => {
        let status = '';
        if (this.props.line_ids.length === 1) {
            let line = this.props.lines.find(l => l.line_id === this.props.line_ids[0]);
            status = line.product_is_active ? 0 : 1;
        }

        return status;
    };

    getOriginalTargetSpeed = () => {
        let targetSpeed = '';
        if (this.props.line_ids.length === 1) {
            let line = this.props.lines.find(l => l.line_id === this.props.line_ids[0]);
            targetSpeed = line.target_speed === null ? '' : line.target_speed;
        }

        return targetSpeed;
    };

    getOriginalUnitValue = (type, unitsArr) => {
        if (this.props.line_ids.length > 1) {
            return [''];
        }

        let line_id = this.props.line_ids[0];

        let line = unitsArr.find(l => l.line_id === line_id);
        if (!line) {
            return [''];
        }

        let unitObj = line.units[type];

        if (!unitObj) {
            return [''];
        }

        let unitArr = [];
        if (unitObj.conversion_value) {
            unitArr.push(unitObj.conversion_value);
        }

        return unitArr;
    };

    getTargetWeight = (name, line_id) => {
        let targetWeight = 0;
        this.props.lines
            .find(l => l.line_id === line_id)
            .giveaway_target_list.forEach(giveawayTarget => {
                if (giveawayTarget.location_name === name) {
                    targetWeight = giveawayTarget.target_weight_kg;
                }
            });

        return targetWeight;
    };

    getIsActive = (name, line_id) => {
        let isActive = false;
        this.props.lines
            .find(l => l.line_id === line_id)
            .giveaway_target_list.forEach(giveawayTarget => {
                if (giveawayTarget.location_name === name) {
                    isActive = giveawayTarget.is_active;
                }
            });

        return isActive;
    };

    getUnitToDisplay = (name, line_id) => {
        let unit_to_display = 'g';
        this.props.lines
            .find(l => l.line_id === line_id)
            .giveaway_target_list.forEach(giveawayTarget => {
                if (giveawayTarget.location_name === name) {
                    unit_to_display = giveawayTarget.unit_to_display;
                }
            });

        return unit_to_display;
    };

    resetToLineDefaults = type => {
        let { line_default_units } = this.props;
        switch (type) {
            case 'output':
                this.setState({
                    sensor_count_unit_type: this.getOriginalUnitTypeId(type, line_default_units),
                    sensor_count_values: this.getOriginalUnitValue(type, line_default_units)
                });
                break;
            case 'speed':
                this.setState({
                    speed_unit_type: this.getOriginalUnitTypeId(type, line_default_units),
                    speed_values: this.getOriginalUnitValue(type, line_default_units)
                });
                break;
            case 'rework':
                this.setState({
                    rework_unit_type: this.getOriginalUnitTypeId(type, line_default_units),
                    rework_values: this.getOriginalUnitValue(type, line_default_units)
                });
                break;
            case 'waste':
                this.setState({
                    waste_unit_type: this.getOriginalUnitTypeId(type, line_default_units),
                    waste_values: this.getOriginalUnitValue(type, line_default_units)
                });
                break;
            case 'finished_goods':
                this.setState({
                    finished_goods_unit_type: this.getOriginalUnitTypeId(type, line_default_units),
                    finished_goods_values: this.getOriginalUnitValue(type, line_default_units)
                });
                break;
            default:
                this.setState({
                    sensor_count_unit_type: this.getOriginalUnitTypeId('output', line_default_units),
                    sensor_count_values: this.getOriginalUnitValue('output', line_default_units),
                    speed_unit_type: this.getOriginalUnitTypeId('speed', line_default_units),
                    speed_values: this.getOriginalUnitValue('speed', line_default_units),
                    rework_unit_type: this.getOriginalUnitTypeId('rework', line_default_units),
                    rework_values: this.getOriginalUnitValue('rework', line_default_units),
                    waste_unit_type: this.getOriginalUnitTypeId('waste', line_default_units),
                    waste_values: this.getOriginalUnitValue('waste', line_default_units),
                    finished_goods_unit_type: this.getOriginalUnitTypeId('finished_goods', line_default_units),
                    sensor_count_display_unit_type: this.getOriginalUnitTypeId('output', line_default_units, true),
                    speed_display_unit_type: this.getOriginalUnitTypeId('speed', line_default_units, true),
                    waste_display_unit_type: this.getOriginalUnitTypeId('waste', line_default_units, true),
                    rework_display_unit_type: this.getOriginalUnitTypeId('rework', line_default_units, true),
                    finished_goods_display_unit_type: this.getOriginalUnitTypeId(
                        'finished_goods',
                        line_default_units,
                        true
                    ),
                    finished_goods_values: this.getOriginalUnitValue('finished_goods', line_default_units),
                    speed_unit_conversion_value: this.getOriginalBaseUnitConversion('speed', line_default_units),
                    waste_unit_conversion_value: this.getOriginalBaseUnitConversion('waste', line_default_units),
                    rework_unit_conversion_value: this.getOriginalBaseUnitConversion('rework', line_default_units),
                    finished_goods_unit_conversion_value: this.getOriginalBaseUnitConversion(
                        'finished_goods',
                        line_default_units
                    )
                });
        }
        this.setChange(type);
    };

    resetToLineOutputDefault = type => {
        let { line_default_units } = this.props;
        switch (type) {
            case 'speed':
                this.setState({
                    speed_unit_conversion_value: this.getOriginalBaseUnitConversion('speed', line_default_units)
                });
                break;
            case 'rework':
                this.setState({
                    rework_unit_conversion_value: this.getOriginalBaseUnitConversion('rework', line_default_units)
                });
                break;
            case 'waste':
                this.setState({
                    waste_unit_conversion_value: this.getOriginalBaseUnitConversion('waste', line_default_units)
                });
                break;
            case 'finished_goods':
                this.setState({
                    finished_goods_unit_conversion_value: this.getOriginalBaseUnitConversion(
                        'finished_goods',
                        line_default_units
                    )
                });
                break;
            default:
                this.setState({
                    speed_unit_conversion_value: this.getOriginalBaseUnitConversion('speed', line_default_units),
                    waste_unit_conversion_value: this.getOriginalBaseUnitConversion('waste', line_default_units),
                    rework_unit_conversion_value: this.getOriginalBaseUnitConversion('rework', line_default_units),
                    finished_goods_unit_conversion_value: this.getOriginalBaseUnitConversion(
                        'finished_goods',
                        line_default_units
                    )
                });
        }
    };

    onSubValueDelete = (type, index) => {
        let subValues = [];
        switch (type) {
            case 'output':
                subValues = [...this.state.sensor_count_values];
                break;
            case 'speed':
                subValues = [...this.state.speed_values];
                break;
            case 'rework':
                subValues = [...this.state.rework_values];
                break;
            case 'waste':
                subValues = [...this.state.waste_values];
                break;
            case 'finished_goods':
                subValues = [...this.state.finished_goods_values];
                break;
        }

        if (subValues.length > 1) {
            subValues.splice(index, 1);
            switch (type) {
                case 'output':
                    this.setState({
                        sensor_count_values: subValues
                    });
                    break;
                case 'speed':
                    this.setState({
                        speed_values: subValues
                    });
                    break;
                case 'rework':
                    this.setState({
                        rework_values: subValues
                    });
                    break;
                case 'waste':
                    this.setState({
                        waste_values: subValues
                    });
                    break;
                case 'finished_goods':
                    this.setState({
                        finished_goods_values: subValues
                    });
                    break;
            }
            this.setChange(type);
        }
    };

    shouldDisableNextButton = () => {
        let {
            target_speed,
            sensor_count_unit_type,
            speed_unit_type,
            waste_unit_type,
            rework_unit_type,
            finished_goods_unit_type,
            sensor_count_display_unit_type,
            speed_display_unit_type,
            waste_display_unit_type,
            rework_display_unit_type,
            finished_goods_display_unit_type,
            sensor_count_values,
            speed_values,
            waste_values,
            rework_values,
            finished_goods_values,
            speed_unit_conversion_value,
            waste_unit_conversion_value,
            rework_unit_conversion_value,
            finished_goods_unit_conversion_value,
            use_line_defaults,
            activeStep,
            recalculateHistoricalData,
            recalculateAll,
            recalculateInDates,
            
        } = this.state;

        let disabled = false;

        if (isNaN(parseFloat(target_speed))) {
            disabled = true;
        }

        if (activeStep === 1 && recalculateHistoricalData && !recalculateAll && !recalculateInDates) {
            disabled = true
        }

        if (!use_line_defaults) {
            if (
                isNaN(parseFloat(speed_unit_conversion_value)) ||
                isNaN(parseFloat(waste_unit_conversion_value)) ||
                isNaN(parseFloat(rework_unit_conversion_value)) ||
                isNaN(parseFloat(finished_goods_unit_conversion_value))
            ) {
                disabled = true;
            }

            if (
                !sensor_count_values[0] ||
                !speed_values[0] ||
                !waste_values[0] ||
                !rework_values[0] ||
                !finished_goods_values[0] ||
                (isNaN(parseFloat(sensor_count_values[0].value)) && isNaN(parseFloat(sensor_count_values[0]))) ||
                (isNaN(parseFloat(speed_values[0].value)) && isNaN(parseFloat(speed_values[0]))) ||
                (isNaN(parseFloat(waste_values[0].value)) && isNaN(parseFloat(waste_values[0]))) ||
                (isNaN(parseFloat(rework_values[0].value)) && isNaN(parseFloat(rework_values[0]))) ||
                (isNaN(parseFloat(finished_goods_values[0].value)) && isNaN(parseFloat(finished_goods_values[0])))
            ) {
                disabled = true;
            }

            if (
                sensor_count_unit_type === '' ||
                speed_unit_type === '' ||
                waste_unit_type === '' ||
                rework_unit_type === '' ||
                finished_goods_unit_type === '' ||
                sensor_count_display_unit_type === '' ||
                speed_display_unit_type === '' ||
                waste_display_unit_type === '' ||
                rework_display_unit_type === '' ||
                finished_goods_display_unit_type === ''
            ) {
                disabled = true;
            }
        }

        return disabled;
    };

    /* -------- API CALLS -------- */
    submitProductOnLineUpdate = () => {


        let {
            target_speed,
            status,
            sensor_count_unit_type,
            speed_unit_type,
            waste_unit_type,
            rework_unit_type,
            finished_goods_unit_type,
            sensor_count_display_unit_type,
            speed_display_unit_type,
            waste_display_unit_type,
            rework_display_unit_type,
            finished_goods_display_unit_type,
            sensor_count_values,
            speed_values,
            waste_values,
            rework_values,
            finished_goods_values,
            speed_unit_conversion_value,
            waste_unit_conversion_value,
            rework_unit_conversion_value,
            finished_goods_unit_conversion_value,
            use_line_defaults
        } = this.state;
        let { line_ids, productId } = this.props;

        let data

        if (use_line_defaults) {
            data = {
                target_speed: parseFloat(target_speed),
                product_is_active: status === 0,
                uses_line_default_unit_conversions: true
            };

            line_ids.forEach(line_id => {
                let params = {
                    path: 'update_product_line_info',
                    path_variables: {
                        LINE_ID: parseInt(line_id),
                        PRODUCT_ID: parseInt(productId)
                    },
                    data,
                    success: this.onUpdateProductOnLineSuccess,
                    error: this.onUpdateProductOnLineError
                };

                this.setState({ calls: this.state.calls + 1 });

                API.c(params, 2);
            });
        } else {
            data = {
                target_speed: parseFloat(this.state.target_speed),
                product_is_active: this.state.status === 0,
                uses_line_default_unit_conversions: false
            };

            //OUTPUT CONVERSION VALUES
            data['output'] = {
                input_unit_id: sensor_count_unit_type,
                display_unit_id: sensor_count_display_unit_type,
                base_unit_conversion_value: 1,
                unit_conversion_value: parseFloat(sensor_count_values[0])
            };

            //SPEED CONVERSION VALUES
            data['speed'] = {
                input_unit_id: speed_unit_type,
                display_unit_id: speed_display_unit_type,
                base_unit_conversion_value: parseFloat(speed_unit_conversion_value),
                unit_conversion_value: parseFloat(speed_values[0])
            };

            //WASTE CONVERSION VALUES
            data['waste'] = {
                input_unit_id: waste_unit_type,
                display_unit_id: waste_display_unit_type,
                base_unit_conversion_value: parseFloat(waste_unit_conversion_value),
                unit_conversion_value: parseFloat(waste_values[0])
            };

            //REWORK CONVERSION VALUES
            data['rework'] = {
                input_unit_id: rework_unit_type,
                display_unit_id: rework_display_unit_type,
                base_unit_conversion_value: parseFloat(rework_unit_conversion_value),
                unit_conversion_value: parseFloat(rework_values[0])
            };

            //FINISHED GOODS CONVERSION VALUES
            data['finished_goods'] = {
                input_unit_id: finished_goods_unit_type,
                display_unit_id: finished_goods_display_unit_type,
                base_unit_conversion_value: parseFloat(finished_goods_unit_conversion_value),
                unit_conversion_value: parseFloat(finished_goods_values[0])
            };

            line_ids.forEach(line_id => {
                let params = {
                    path: 'update_product_line_info',
                    path_variables: {
                        LINE_ID: parseInt(line_id),
                        PRODUCT_ID: parseInt(productId)
                    },
                    data,
                    success: this.onUpdateProductOnLineSuccess,
                    error: this.onUpdateProductOnLineError
                };

                this.setState({ calls: this.state.calls + 1 });

                API.c(params, 2);
            });
        }

        if (this.state.recalculateHistoricalData) {

            line_ids.forEach(line_id => {
                let params = {
                    path: 'rebase_line_run_data',
                    path_variables: {
                        LINE_ID: parseInt(line_id),
                        PRODUCT_ID: parseInt(productId)
                    },
                    data: {
                        new_values: data,
                        previous_values: this.props.prevValues,
                    },
                    success: () => console.log(2333),
                    error: () => console.log(999)
                };

                if (this.state.recalculateInDates) {
                    params.data.start_date = new Date(this.state.recalculate_date_from).toISOString()
                    params.data.end_date = new Date(this.state.recalculate_date_to).toISOString()
                }
                else {
                    params.data.is_rebaseline_all = true
                }

                this.setState({ calls: this.state.calls + 1 });

                API.c(params, 2);
            });

        }

        console.log(123);

    };

    onUpdateProductOnLineSuccess = data => {
        if (this.state.calls === 1) {
            if (this.state.recalculateHistoricalData) {
                this.setState({
                    submit: true
                })
            }
            else {
                this.props.hideModal();
                this.props.refreshProduct();
            }
        } else {
            this.setState({ calls: this.state.calls - 1 });
        }
    };

    onUpdateProductOnLineError = error => {
        this.setState({ calls: this.state.calls - 1 });
    };

    setReportMultipleDates = selectedDates => {
        this.setState({
            multi_date: true,
            recalculate_selected_dates: selectedDates,
            show_select_dates: false,
            inactive: false
        });
    };

    setReportRangeDates = (from, to) => {
        this.setState({
            multi_date: false,
            recalculate_date_from: from,
            recalculate_date_to: to,
            show_select_dates: false,
            inactive: false,
            recalculateDateError: false
        });
    };

    handleMultiDatePickerClose = () => {
        this.setState({
            show_select_dates: false
        });
    };

    formatDateTime = d => {
        let date = new Date(d);

        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let hour = ('0' + date.getHours()).substr(-2, 2);
        let minute = ('0' + date.getMinutes()).substr(-2, 2);
        let second = ('0' + date.getSeconds()).substr(-2, 2);
        let suffix = ' AM';

        if (hour > 12) {
            hour = hour - 12;
            suffix = ' PM';
        } else if (hour == 12) {
            suffix = ' PM';
        } else if (hour == 0) {
            hour = 12;
        }

        let pretty_time = hour + ':' + minute + ':' + second + ' ' + suffix;

        return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    };

    getDatesLabel = () => {
        let label = '';

        if (this.state.multi_date) {
            if (this.state.recalculate_selected_dates.length === 1) {
                label = this.formatDateTime(this.state.recalculate_selected_dates[0]);
            } else {
                label = 'Multiple Dates';
            }
        } else if (this.state.recalculate_date_from && this.state.recalculate_date_to) {
            label = this.formatDateTime(this.state.recalculate_date_from) + ' - ' + this.formatDateTime(this.state.recalculate_date_to);
        }

        return label;
    };

    formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${month}/${day}/${year}`;
    }

    /* -------- RENDERS -------- */

    renderBody = () => {
        const { classes, product_on_line_units, line_default_units } = this.props;
        switch (this.state.activeStep) {
            case 0:
                return (
                    <div>
                        <div className={classes.card}>
                            <Typography variant="subtitle1" color="secondary">
                                {this.getLineNames()}
                            </Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="status-select-label">Status</InputLabel>
                                        <Select
                                            labelId="status-select-label"
                                            id="status"
                                            value={this.state.status}
                                            onChange={this.handleStatusDropDownChange.bind(this)}
                                        >
                                            <MenuItem value={0}>Active</MenuItem>
                                            <MenuItem value={1}>Inactive</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl className={clsx([classes.formControl, classes.marginLeft])}>
                                        <InputLabel htmlFor="target-speed">Target Speed</InputLabel>
                                        <Input
                                            id="target_speed"
                                            value={this.state.target_speed}
                                            type="number"
                                            error={this.state.target_speed <= 0 && this.state.target_speed !== ''}
                                            onChange={this.handleChange.bind(this)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {this.getUnitType('speed_display')}
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText>
                                            {this.state.target_speed <= 0 && this.state.target_speed !== ''
                                                ? 'Positive number only'
                                                : ' '}
                                        </FormHelperText>
                                    </FormControl>
                                </div>
                                <div className={classes.switchWrapper}>
                                    <Switch
                                        color={'primary'}
                                        onClick={this.handleLineDefaultSwitchChange}
                                        checked={this.state.use_line_defaults}
                                    />
                                    <Typography
                                        variant={'caption'}
                                        style={{ color: this.state.use_line_defaults ? '#0A71CE' : '#999' }}
                                    >
                                        Use Line Default
                                    </Typography>
                                    <ClickAwayListener onClickAway={() => {}}>
                                        <Tooltip
                                            PopperProps={{
                                                disablePortal: true
                                            }}
                                            classes={{ tooltipPlacementBottom: classes.tooltipPlacementBottom }}
                                            // onClose={handleTooltipClose}
                                            open={false}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            arrow
                                            title={`Input value is calculated by multiplying the variables added below.`}
                                        >
                                            <IconButton className={classes.labelIconButton} onClick={() => {}}>
                                                <HelpOutline style={{ fontSize: 12 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </ClickAwayListener>
                                </div>
                            </div>
                        </div>
                        <div className={classes.table}>
                            <UnitRow
                                name={'Sensor Count'}
                                icon={<Memory htmlColor={'#666'} />}
                                type={'output'}
                                units={this.props.units}
                                unitType={this.state.sensor_count_unit_type}
                                displayUnitType={this.state.sensor_count_display_unit_type}
                                onValueChange={this.onValueChange}
                                onNotesChange={this.onNotesChange}
                                onUnitTypeChange={this.onUnitTypeChange}
                                subValues={this.state.sensor_count_values}
                                resetToLineDefaults={this.resetToLineDefaults}
                                addSubValue={this.addSubValue}
                                onSubValueDelete={this.onSubValueDelete}
                                enabled={!this.state.use_line_defaults}
                            />
                            <UnitRow
                                name={'Speed'}
                                icon={<Speed htmlColor={'#666'} />}
                                type={'speed'}
                                units={this.props.units}
                                unitType={this.state.speed_unit_type}
                                displayUnitType={this.state.speed_display_unit_type}
                                conversionUnitType={this.state.sensor_count_display_unit_type}
                                conversionValue={this.state.speed_unit_conversion_value}
                                onConversionValueChange={this.onConversionValueChange}
                                onValueChange={this.onValueChange}
                                onNotesChange={this.onNotesChange}
                                onUnitTypeChange={this.onUnitTypeChange}
                                subValues={this.state.speed_values}
                                resetToLineDefaults={this.resetToLineDefaults}
                                resetToLineOutputDefault={this.resetToLineOutputDefault}
                                addSubValue={this.addSubValue}
                                onSubValueDelete={this.onSubValueDelete}
                                enabled={!this.state.use_line_defaults}
                            />
                            <UnitRow
                                name={'Rework'}
                                icon={<ReworkIcon color={'#666'} />}
                                type={'rework'}
                                units={this.props.units}
                                unitType={this.state.rework_unit_type}
                                displayUnitType={this.state.rework_display_unit_type}
                                conversionUnitType={this.state.sensor_count_display_unit_type}
                                conversionValue={this.state.rework_unit_conversion_value}
                                onConversionValueChange={this.onConversionValueChange}
                                onValueChange={this.onValueChange}
                                onNotesChange={this.onNotesChange}
                                onUnitTypeChange={this.onUnitTypeChange}
                                subValues={this.state.rework_values}
                                resetToLineDefaults={this.resetToLineDefaults}
                                resetToLineOutputDefault={this.resetToLineOutputDefault}
                                addSubValue={this.addSubValue}
                                onSubValueDelete={this.onSubValueDelete}
                                enabled={!this.state.use_line_defaults}
                            />
                            <UnitRow
                                name={'Waste'}
                                icon={<WasteIcon color={'#666'} />}
                                type={'waste'}
                                units={this.props.units}
                                unitType={this.state.waste_unit_type}
                                displayUnitType={this.state.waste_display_unit_type}
                                conversionUnitType={this.state.sensor_count_display_unit_type}
                                conversionValue={this.state.waste_unit_conversion_value}
                                onConversionValueChange={this.onConversionValueChange}
                                onValueChange={this.onValueChange}
                                onNotesChange={this.onNotesChange}
                                onUnitTypeChange={this.onUnitTypeChange}
                                subValues={this.state.waste_values}
                                resetToLineDefaults={this.resetToLineDefaults}
                                resetToLineOutputDefault={this.resetToLineOutputDefault}
                                addSubValue={this.addSubValue}
                                onSubValueDelete={this.onSubValueDelete}
                                enabled={!this.state.use_line_defaults}
                            />
                            <UnitRow
                                name={'Finished Goods'}
                                icon={<FinishedGoodsIcon color={'#666'} />}
                                type={'finished_goods'}
                                units={this.props.units}
                                unitType={this.state.finished_goods_unit_type}
                                displayUnitType={this.state.finished_goods_display_unit_type}
                                conversionUnitType={this.state.sensor_count_display_unit_type}
                                conversionValue={this.state.finished_goods_unit_conversion_value}
                                onConversionValueChange={this.onConversionValueChange}
                                onValueChange={this.onValueChange}
                                onNotesChange={this.onNotesChange}
                                onUnitTypeChange={this.onUnitTypeChange}
                                subValues={this.state.finished_goods_values}
                                resetToLineDefaults={this.resetToLineDefaults}
                                resetToLineOutputDefault={this.resetToLineOutputDefault}
                                addSubValue={this.addSubValue}
                                onSubValueDelete={this.onSubValueDelete}
                                enabled={!this.state.use_line_defaults}
                            />
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <p>Recalculate Historical Data?</p>

                            <Switch
                                color="primary"
                                checked={this.state.recalculateHistoricalData}
                                onChange={() => this.setState({ recalculateHistoricalData: !this.state.recalculateHistoricalData })}
                                name="recalculateHistoricalData"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                        {this.state.recalculateHistoricalData ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox
                                    checked={this.state.recalculateAll}
                                    onChange={() => this.setState({ recalculateAll: !this.state.recalculateAll, recalculateInDates: false, recalculateDateError: false })}
                                    color="primary"
                                />
                                <p style={{ marginRight: "26px" }}>Recalculate All Historical Data</p>
                                <Checkbox
                                    checked={this.state.recalculateInDates}
                                    onChange={() => this.setState({ recalculateAll: false, recalculateInDates: !this.state.recalculateInDates })}
                                    color="primary"
                                />
                                <div style={{padding: `11px 0 ${this.state.recalculateDateError ? "0px" : "12px"} 0px` }}>
                                    <Button
                                        variant="text"
                                        onClick={() => this.setState({ show_select_dates: true, recalculateInDates: true, recalculateAll: false })}
                                        endIcon={<ArrowDropDown />}
                                        style={{ minWidth: "160px", justifyContent: "left" }}
                                    >
                                        {'Dates: ' + this.getDatesLabel()}
                                    </Button>
                                    <hr style={{ borderColor: `${this.state.recalculateDateError ? "#CF2C4E" : "#CBCBCB"}` }} className="filter-button-hr" />
                                    {this.state.recalculateDateError ? (
                                        <p style={{ fontSize: "10px", color: "#CF2C4E", marginTop: "4px" }}>Select dates</p>
                                    ) : null}

                                </div>
                                {this.state.show_select_dates ? (
                                    <MultiDatesPicker
                                        setReportMultipleDates={this.setReportMultipleDates}
                                        setReportRangeDates={this.setReportRangeDates}
                                        type={'range'}
                                        onlyRange={true}
                                        selectedDays={this.state.recalculate_selected_dates}
                                        from={this.state.recalculate_date_from}
                                        to={this.state.recalculate_date_to}
                                        handleClose={this.handleMultiDatePickerClose}
                                    />
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                )
            case 2:
                return (
                    <div>
                        {this.state.submit ? (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div>
                                    <p style={{ fontSize: "20px", fontWeight: "700", textAlign: "center", marginBottom: "20px" }}>Changes applied</p>
                                    <p style={{ fontSize: "20px", fontWeight: "400", textAlign: "center", width: "820px", marginBottom: "20px" }}>The recalculation of the historical data will take a few minutes to process. You can continue to use the site as normally while the recalculation is running.</p>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <img src={hourglassImage} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {this.state.recalculateHistoricalData ? (
                                    <>
                                        <p>{this.state.recalculateAll ? "ALL HISTORICAL DATA WILL BE RECALCULATED" : `DATA FROM ${this.formatDate(this.state.recalculate_date_from)} TO ${this.formatDate(this.state.recalculate_date_to)} WILL BE RECALCULATED`}</p>
                                        <p style={{ marginTop: "12px" }}>You have chosen to recalculate all historical data using the edits made to the product, listed below.</p>
                                        <p style={{ marginTop: "28px", marginBottom: "16px" }}>Once you confirm, the product setting will take effect immediately. </p>
                                    </>
                                ) : (
                                    <div style={{ width: "540px" }}>
                                        <p>NO HISTORICAL DATA WILL BE RECALCULATED</p>
                                        <p style={{ marginTop: "12px", marginBottom: "16px" }}>You’ve chosen to make this product edit only for product runs today, and in the future. If you want to apply this change to historical data, please go back a step and select a date range to recalculate.</p>
                                    </div>
                                )}
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell className={classes.label}>Current</TableCell>
                                                <TableCell className={classes.label}>Edits</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.getOriginalStatus() !== this.state.status && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant={'subtitle2'} className={classes.name}>
                                                            Status
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={'body1'} className={classes.name}>
                                                            {this.getOriginalStatus() === 0 ? 'Active' : 'Inactive'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={'body1'} className={classes.name}>
                                                            {this.state.status === 0 ? 'Active' : 'Inactive'}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {parseFloat(this.getOriginalTargetSpeed()) !==
                                                parseFloat(this.state.target_speed) && (
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant={'subtitle2'} className={classes.name}>
                                                            Target Speed
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={'body1'} className={classes.name}>
                                                            {this.getOriginalTargetSpeed() +
                                                                ' ' +
                                                                this.getOriginalUnitTypeText(
                                                                    this.getOriginalUnitTypeId('speed')
                                                                )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant={'body1'} className={classes.name}>
                                                            {this.state.target_speed + ' ' + this.getUnitType('speed_display')}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            <UnitChangeConfirmationRow
                                                name={'Sensor Count'}
                                                type={'output'}
                                                changed={this.state.sensor_count_change}
                                                originalUnitType={this.getOriginalUnitTypeText(
                                                    this.getOriginalUnitTypeId('output', product_on_line_units)
                                                )}
                                                unitType={this.getUnitType('output')}
                                                originalValues={this.getOriginalUnitValue('output', product_on_line_units)}
                                                subValues={this.state.sensor_count_values}
                                            />
                                            <UnitChangeConfirmationRow
                                                name={'Speed'}
                                                type={'speed'}
                                                changed={this.state.speed_change}
                                                originalUnitType={this.getOriginalUnitTypeText(
                                                    this.getOriginalUnitTypeId('speed', product_on_line_units)
                                                )}
                                                originalValues={this.getOriginalUnitValue('speed', product_on_line_units)}
                                                unitType={this.getUnitType('speed')}
                                                subValues={this.state.speed_values}
                                            />
                                            <UnitChangeConfirmationRow
                                                name={'Rework'}
                                                type={'rework'}
                                                changed={this.state.rework_change}
                                                originalUnitType={this.getOriginalUnitTypeText(
                                                    this.getOriginalUnitTypeId('rework', product_on_line_units)
                                                )}
                                                originalValues={this.getOriginalUnitValue('rework', product_on_line_units)}
                                                unitType={this.getUnitType('rework')}
                                                subValues={this.state.rework_values}
                                            />
                                            <UnitChangeConfirmationRow
                                                name={'Waste'}
                                                type={'waste'}
                                                changed={this.state.waste_change}
                                                originalUnitType={this.getOriginalUnitTypeText(
                                                    this.getOriginalUnitTypeId('waste', product_on_line_units)
                                                )}
                                                originalValues={this.getOriginalUnitValue('waste', product_on_line_units)}
                                                unitType={this.getUnitType('waste')}
                                                subValues={this.state.waste_values}
                                            />
                                            <UnitChangeConfirmationRow
                                                name={'Finished Goods'}
                                                type={'finished_goods'}
                                                changed={this.state.finished_goods_change}
                                                originalUnitType={this.getOriginalUnitTypeText(
                                                    this.getOriginalUnitTypeId('finished_goods', product_on_line_units)
                                                )}
                                                originalValues={this.getOriginalUnitValue(
                                                    'finished_goods',
                                                    product_on_line_units
                                                )}
                                                unitType={this.getUnitType('finished_goods')}
                                                subValues={this.state.finished_goods_values}
                                            />
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}
                    </div>
                );
        }
    };

    renderButtons = () => {
        let {
            sensor_count_change,
            speed_change,
            waste_change,
            rework_change,
            finished_goods_change,
            target_speed,
            status,
            sensor_count_display_unit_type
        } = this.state;

        if (!this.state.submitting && !this.state.submit) {
            return (
                <DialogActions
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'baseline',
                        padding: '12px 24px'
                    }}
                >
                    <Button variant="text" color="primary" onClick={this.handleBack}>
                        {this.state.activeStep === 0 ? 'Cancel' : 'Back'}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={this.shouldDisableNextButton()}
                        onClick={this.handleNext}
                    >
                        {this.state.activeStep != 2 ? 'Next' : 'Confirm'}
                    </Button>
                </DialogActions>
            );
        } else if (this.state.submitting) {
            return (
                <DialogActions
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'baseline',
                        padding: '12px 24px'
                    }}
                >
                    <CircularProgress mode="indeterminate" />
                </DialogActions>
            );
        }
        else if (this.state.submit) {
            return (
                <DialogActions
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'baseline',
                        padding: '12px 24px'
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {this.props.hideModal(); this.props.refreshProduct()}}
                    >
                        OK
                    </Button>
                </DialogActions>
            );
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <Dialog
                open={true}
                onClose={this.props.hideModal}
                scroll="paper"
                fullWidth={true}
                classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle disableTypography>
                    <Typography variant="subtitle1">{this.state.activeStep === 0 ? "Edit Line" : this.state.activeStep === 1 ? "Rebaseline" : "Confirm"}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Stepper activeStep={this.state.activeStep} classes={{ root: classes.stepperRoot }}>
                        <Step>
                            <StepLabel>Edit Line</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Recalculate Historical Data</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Confirm Edits</StepLabel>
                        </Step>
                    </Stepper>
                    {this.renderBody()}
                    <Snackbar
                        className={classes.snackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        autoHideDuration={6000}
                        open={this.state.show_snackbar}
                        onClose={() => this.setState({ show_snackbar: false })}
                        message="Line Default is overridden. You can edit units individually."
                        action={
                            <Button
                                className={classes.snackbarActionButton}
                                variant={'text'}
                                onClick={this.handleLineDefaultChangeUndo}
                            >
                                Undo
                            </Button>
                        }
                    />
                </DialogContent>
                {this.renderButtons()}
            </Dialog>
        );
    }
}

const styles = theme => ({
    card: {
        marginBottom: '30px',
        padding: '30px',
        boxSizing: 'border-box',
        background: '#fff',
        boxShadow: '0px 0px 8px rgba(0,0,0,0.12)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    formControl: {
        minWidth: 140
    },
    marginLeft: {
        marginLeft: theme.spacing(1)
    },
    dialogCustomizedWidth: {
        'max-width': '1200px'
    },
    table: {
        display: 'flex',
        flexDirection: 'column'
    },
    stepperRoot: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '65%'
    },
    label: {
        fontWeight: 700,
        color: '#999'
    },
    switchWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    tooltipPlacementBottom: {
        margin: 0
    },
    labelIconButton: {
        padding: '6px'
    },
    snackbar: {
        width: '842px !important',
        top: '48px',
        '& .MuiSnackbarContent-root': {
            width: '100%'
        }
    },
    snackbarActionButton: {
        color: '#fff'
    }
});

export default withStyles(styles)(ManageLinesModal);
