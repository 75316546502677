import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ShiftProvider } from './context';
import LiveTrackingRouter from './LiveTrackingRouter.js';
import packageJson from '../../package.json';
import Global from './components/Global.js';
import Auth from './Auth';
import API from './components/API.js';
import Util from './components/Util.js';
import Styles from './components/Styles.js';
import User from './stores/User.js';
import LineStore from './stores/LineStore.js';
import ProductStore from './stores/ProductStore';

global.appVersion = packageJson.version;

window.Global = Global;
window.Auth0 = new Auth();
window.API = API;
window.Util = Util;
window.Styles = Styles;
window.User = new User();
window.LineStore = new LineStore();
window.ProductStore = new ProductStore();


if (window.location.protocol.indexOf('https', 0) === -1 && window.location.host.indexOf('localhost', 0) === -1 &&
    window.location.host.indexOf('49.12.69.117:8889', 0) === -1){
    window.location.href = 'https://' + window.location.host + window.location.pathname + window.location.search;
}
const checkLocalStorage = JSON.parse(localStorage.getItem('perPage'));
if (!checkLocalStorage) {
    localStorage.setItem('perPage', JSON.stringify(15));
}

const muiTheme = getMuiTheme({
    palette: {
        primary1Color: '#0086FF',
        primary2Color: '#0086FF',
        primary3Color: '#2967c1',
        accentColor: '#2B2E34',
        secondary1Color: '#E1004B'
    },
    tabs: {
        backgroundColor: '#fff', // '#e9e9e9',
        textColor: '#000000',
        selectedTextColor: '#2967c1'
    },
    inkBar: {
        backgroundColor: '#2967c1'
    },

    tableHeaderColumn: {
        textTransform: 'uppercase'
    },
    tableRow: {
        height: 'auto'
    },
    tableRowColumn: {
        height: 'auto'
    }
});

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#0A71CE',
            main: '#0A71CE',
            dark: '#004485'
        },
        secondary: {
            light: '#E31C53',
            main: '#E31C53',
            dark: '#AF0534'
        },
        error: { main: '#E00000' },
        warning: { main: '#FEC600' },
        success: { main: '#1F9D00' },
        text: {
            primary: '#000',
            disabled: '#BBBBBB',
            secondary: '#666666'
        },
        background: {
            default: '#F2F2F2',
            paper: '#fff'
        }
    },
    typography: {
        h4: {
            fontSize: '2.5rem',
            fontWeight: 700
        },
        h5: {
            fontSize: '1.875rem'
        },
        h6: {
            fontSize: '1.5rem'
        },
        subtitle1: {
            fontSize: '1.25rem',
            fontWeight: 'normal'
        },
        subtitle2: {
            fontSize: '1rem',
            fontWeight: 500,
            textTransform: 'uppercase'
        },
        body1: {
            fontSize: '1rem'
        },
        body2: {
            fontSize: '0.875rem'
        },
        button: {
            letterSpacing: '0.16px'
        },
        overline: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '0.75rem'
        }
    }
});

export const App = props => {
    localStorage.removeItem('checkedItemsrunReport');
    localStorage.removeItem('checkedItemsrunList');

    let current_org = JSON.parse(localStorage.getItem("organization"))
    let orgs_list = JSON.parse(localStorage.getItem("organizations"))

    const urlParams = new URLSearchParams(window.location.search)

    for (const [key, value] of urlParams) {
        if (key === "factory_id" && orgs_list) {
            orgs_list.forEach((org) => {
                org.factories.forEach((factory) => {
                    if (value === factory.id) {
                        localStorage.setItem("factory", JSON.stringify(factory))
                        localStorage.setItem("organization", JSON.stringify(org))
                    }
                })
            })
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <MuiThemeProvider muiTheme={muiTheme}>
                {/*<CacheValidation>*/}
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ShiftProvider>
                        <LiveTrackingRouter obj={'thing'} history={props.history} />
                    </ShiftProvider>
                </MuiPickersUtilsProvider>
                {/*</CacheValidation>*/}
            </MuiThemeProvider>
        </ThemeProvider>
    );
};

