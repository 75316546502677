import React, { Component } from 'react';
import { Button, CircularProgress, TextField, TableContainer, Table, TableBody, Paper } from '@material-ui/core';
import UnitRow from '../components/settings/products/UnitRow';
import { Memory, Speed } from '@material-ui/icons';
import ReworkIcon from '../components/icons/ReworkIcon';
import WasteIcon from '../components/icons/WasteIcon';
import FinishedGoodsIcon from '../components/icons/FinishedGoodsIcon';

class AddLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            line_name: '',
            target_efficiency: '',
            rework_unit: 'pieces',
            waste_unit: 'pieces',
            output_unit: 'pieces',
            speed_unit: 'pieces/min',
            line_name_error: null,
            target_efficiency_error: null,
            submitting_line: false,
            description_error: null,
            line_description: '',
            sensor_count_values: [''],
            speed_values: [''],
            waste_values: [''],
            rework_values: [''],
            finished_goods_values: [''],
            sensor_count_unit_type: '',
            speed_unit_type: '',
            waste_unit_type: '',
            rework_unit_type: '',
            finished_goods_unit_type: '',
            sensor_count_display_unit_type: '',
            speed_display_unit_type: '',
            waste_display_unit_type: '',
            rework_display_unit_type: '',
            finished_goods_display_unit_type: '',
            speed_unit_conversion_value: '',
            waste_unit_conversion_value: '',
            rework_unit_conversion_value: '',
            finished_goods_unit_conversion_value: '',
            sensor_count_unit_type_error: false,
            speed_unit_type_error: false,
            waste_unit_type_error: false,
            rework_unit_type_error: false,
            finished_goods_unit_type_error: false,
            sensor_count_change: false,
            speed_change: false,
            waste_change: false,
            rework_change: false,
            finished_goods_change: false,
            sensor_count_values_error: false,
            speed_values_error: false,
            waste_values_error: false,
            rework_values_error: false,
            finished_goods_values_error: false,
            calls: 0
        };
    }

    /* ######## Lifecycle Methods ######## */
    componentDidMount = () => {
        this.setKeyListener();
    };

    componentWillUnmount = () => {
        this.cancelKeyListener();
    };

    setKeyListener = () => {
        window.onkeydown = event => {
            if (event.keyCode === 13) {
                this.submitLine();
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    };

    cancelKeyListener = () => {
        window.onkeydown = null;
    };

    handleChange(event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    handleSelectChange(event, index, value) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event;

        this.setState(s);
    }

    getSubValue = type => {
        let subValues = [];
        switch (type) {
            case 'output':
                subValues = this.state.sensor_count_values;
                break;
            case 'speed':
                subValues = this.state.speed_values;
                break;
            case 'rework':
                subValues = this.state.rework_values;
                break;
            case 'waste':
                subValues = this.state.waste_values;
                break;
            case 'finished_goods':
                subValues = this.state.finished_goods_values;
        }

        return subValues;
    };

    setSubValues = (type, values) => {
        this.setChange(type);
        switch (type) {
            case 'output':
                this.setState({
                    sensor_count_values: values
                });
                break;
            case 'speed':
                this.setState({
                    speed_values: values
                });
                break;
            case 'rework':
                this.setState({
                    rework_values: values
                });
                break;
            case 'waste':
                this.setState({
                    waste_values: values
                });
                break;
            case 'finished_goods':
                this.setState({
                    finished_goods_values: values
                });
        }
    };

    onValueChange = (type, event) => {
        let subValues = this.getSubValue(type);

        let newSubValue = [...subValues];
        newSubValue[0] = event.target.value;

        this.setSubValues(type, newSubValue);
    };

    onNotesChange = (type, index, event) => {
        let subValues = this.getSubValue(type);

        let newSubValue = [...subValues];
        newSubValue[index]['notes'] = event.target.value;

        this.setSubValues(type, newSubValue);
    };

    addSubValue = type => {
        let subValues = this.getSubValue(type);

        let newSubValue = [...subValues];
        if (!newSubValue[newSubValue.length - 1] || newSubValue[newSubValue.length - 1].value !== '') {
            newSubValue.push('');
            this.setSubValues(type, newSubValue);
        }
    };

    onSubValueDelete = (type, index) => {
        let subValues = [];
        switch (type) {
            case 'output':
                subValues = [...this.state.sensor_count_values];
                break;
            case 'speed':
                subValues = [...this.state.speed_values];
                break;
            case 'rework':
                subValues = [...this.state.rework_values];
                break;
            case 'waste':
                subValues = [...this.state.waste_values];
                break;
            case 'finished_goods':
                subValues = [...this.state.finished_goods_values];
                break;
        }

        if (subValues.length > 0) {
            subValues.splice(index, 1);
            switch (type) {
                case 'output':
                    this.setState({
                        sensor_count_values: subValues
                    });
                    break;
                case 'speed':
                    this.setState({
                        speed_values: subValues
                    });
                    break;
                case 'rework':
                    this.setState({
                        rework_values: subValues
                    });
                    break;
                case 'waste':
                    this.setState({
                        waste_values: subValues
                    });
                    break;
                case 'finished_goods':
                    this.setState({
                        finished_goods_values: subValues
                    });
                    break;
            }
            this.setChange(type);
        }
    };

    onConversionValueChange = (type, event) => {
        this.setChange(type);
        switch (type) {
            case 'speed':
                this.setState({
                    speed_unit_conversion_value: event.target.value
                });
                break;
            case 'rework':
                this.setState({
                    rework_unit_conversion_value: event.target.value
                });
                break;
            case 'waste':
                this.setState({
                    waste_unit_conversion_value: event.target.value
                });
                break;
            case 'finished_goods':
                this.setState({
                    finished_goods_unit_conversion_value: event.target.value
                });
                break;
        }
    };

    onUnitTypeChange = (type, event) => {
        this.setChange(type);
        switch (type) {
            case 'output':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        sensor_count_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        sensor_count_display_unit_type: event.target.value
                    });
                }
                break;
            case 'speed':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        speed_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        speed_display_unit_type: event.target.value
                    });
                }
                break;
            case 'rework':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        rework_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        rework_display_unit_type: event.target.value
                    });
                }
                break;
            case 'waste':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        waste_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        waste_display_unit_type: event.target.value
                    });
                }
                break;
            case 'finished_goods':
                if (event.currentTarget.id === 'input-unit-type') {
                    this.setState({
                        finished_goods_unit_type: event.target.value
                    });
                } else {
                    this.setState({
                        finished_goods_display_unit_type: event.target.value
                    });
                }
                break;
        }
    };

    getTotalValue = subValues => {
        let noValue = '--';
        let totalValue = subValues[0];
        // subValues.forEach((v, i) => {
        //     if (v.value > 0) {
        //         if (i === 0) {
        //             totalValue = parseFloat(v.value);
        //         } else {
        //             totalValue *= parseFloat(v.value);
        //         }
        //     }
        // });

        if (totalValue > 0) {
            return Math.round((totalValue + Number.EPSILON) * 10000) / 10000;
        } else {
            return noValue;
        }
    };

    setChange = type => {
        switch (type) {
            case 'output':
                this.setState({
                    sensor_count_change: true
                });
                break;
            case 'speed':
                this.setState({
                    speed_change: true
                });
                break;
            case 'rework':
                this.setState({
                    rework_change: true
                });
                break;
            case 'waste':
                this.setState({
                    waste_change: true
                });
                break;
            case 'finished_goods':
                this.setState({
                    finished_goods_change: true
                });
        }
    };

    showAddLineFields = () => {
        return (
            <div className="add-line-info__fields">
                <div className="add-line-info__fields_wrapper">
                    <TextField
                        id="line_name"
                        label="Line name"
                        value={this.state.line_name}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.line_name_error !== null}
                        helperText={this.state.line_name_error !== null ? this.state.line_name_error : ''}
                        style={{ width: 200, marginRight: 8 }}
                    />
                    <TextField
                        id="line_description"
                        label="Line Description"
                        value={this.state.line_description}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.description_error !== null}
                        helperText={this.state.description_error !== null ? this.state.description_error : ''}
                        style={{ width: 200, marginRight: 8 }}
                    />
                    <TextField
                        id="target_efficiency"
                        label="Target efficiency"
                        value={this.state.target_efficiency}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.target_efficiency_error !== null}
                        helperText={
                            this.state.target_efficiency_error !== null ? this.state.target_efficiency_error : ''
                        }
                        style={{ width: 200, marginRight: 8 }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <UnitRow
                        enabled
                        name={'Sensor Count'}
                        icon={<Memory htmlColor={'#666'} />}
                        type={'output'}
                        units={this.props.units}
                        unitType={this.state.sensor_count_unit_type}
                        displayUnitType={this.state.sensor_count_display_unit_type}
                        unitTypeError={this.state.sensor_count_unit_type_error}
                        onValueChange={this.onValueChange}
                        onNotesChange={this.onNotesChange}
                        onUnitTypeChange={this.onUnitTypeChange}
                        subValues={this.state.sensor_count_values}
                        valueError={this.state.sensor_count_values_error}
                        addSubValue={this.addSubValue}
                        onSubValueDelete={this.onSubValueDelete}
                    />
                    <UnitRow
                        enabled
                        name={'Speed'}
                        icon={<Speed htmlColor={'#666'} />}
                        type={'speed'}
                        units={this.props.units}
                        unitType={this.state.speed_unit_type}
                        displayUnitType={this.state.speed_display_unit_type}
                        conversionUnitType={this.state.sensor_count_display_unit_type}
                        conversionValue={this.state.speed_unit_conversion_value}
                        onConversionValueChange={this.onConversionValueChange}
                        unitTypeError={this.state.speed_unit_type_error}
                        onValueChange={this.onValueChange}
                        onNotesChange={this.onNotesChange}
                        onUnitTypeChange={this.onUnitTypeChange}
                        subValues={this.state.speed_values}
                        valueError={this.state.speed_values_error}
                        addSubValue={this.addSubValue}
                        onSubValueDelete={this.onSubValueDelete}
                    />
                    <UnitRow
                        enabled
                        name={'Rework'}
                        icon={<ReworkIcon color={'#666'} />}
                        type={'rework'}
                        units={this.props.units}
                        unitType={this.state.rework_unit_type}
                        displayUnitType={this.state.rework_display_unit_type}
                        conversionUnitType={this.state.sensor_count_display_unit_type}
                        conversionValue={this.state.rework_unit_conversion_value}
                        onConversionValueChange={this.onConversionValueChange}
                        unitTypeError={this.state.rework_unit_type_error}
                        onValueChange={this.onValueChange}
                        onNotesChange={this.onNotesChange}
                        onUnitTypeChange={this.onUnitTypeChange}
                        subValues={this.state.rework_values}
                        valueError={this.state.rework_values_error}
                        addSubValue={this.addSubValue}
                        onSubValueDelete={this.onSubValueDelete}
                    />
                    <UnitRow
                        enabled
                        name={'Waste'}
                        icon={<WasteIcon color={'#666'} />}
                        type={'waste'}
                        units={this.props.units}
                        unitType={this.state.waste_unit_type}
                        displayUnitType={this.state.waste_display_unit_type}
                        conversionUnitType={this.state.sensor_count_display_unit_type}
                        conversionValue={this.state.waste_unit_conversion_value}
                        onConversionValueChange={this.onConversionValueChange}
                        unitTypeError={this.state.waste_unit_type_error}
                        onValueChange={this.onValueChange}
                        onNotesChange={this.onNotesChange}
                        onUnitTypeChange={this.onUnitTypeChange}
                        subValues={this.state.waste_values}
                        valueError={this.state.waste_values_error}
                        addSubValue={this.addSubValue}
                        onSubValueDelete={this.onSubValueDelete}
                    />
                    <UnitRow
                        enabled
                        name={'Finished Goods'}
                        icon={<FinishedGoodsIcon color={'#666'} />}
                        type={'finished_goods'}
                        units={this.props.units}
                        unitType={this.state.finished_goods_unit_type}
                        displayUnitType={this.state.finished_goods_display_unit_type}
                        conversionUnitType={this.state.sensor_count_display_unit_type}
                        conversionValue={this.state.finished_goods_unit_conversion_value}
                        onConversionValueChange={this.onConversionValueChange}
                        unitTypeError={this.state.finished_goods_unit_type_error}
                        onValueChange={this.onValueChange}
                        onNotesChange={this.onNotesChange}
                        onUnitTypeChange={this.onUnitTypeChange}
                        subValues={this.state.finished_goods_values}
                        valueError={this.state.finished_goods_values_error}
                        addSubValue={this.addSubValue}
                        onSubValueDelete={this.onSubValueDelete}
                    />
                </div>
            </div>
        );
    };

    submitLine = () => {
        if (!this.validateInputs()) {
            return false;
        }

        this.setState({
            submitting_line: true
        });

        let state = this.state;

        let params = {
            path: 'add_line',
            data: {
                name: state.line_name,
                desc: state.line_description,
                target_efficiency: state.target_efficiency
            },
            success: this.submitDefaultUnits,
            error: this.submitError
        };

        API.c(params, 2);
    };

    submitDefaultUnits = payload => {
        let {
            sensor_count_change,
            speed_change,
            waste_change,
            rework_change,
            finished_goods_change,
            sensor_count_unit_type,
            speed_unit_type,
            waste_unit_type,
            rework_unit_type,
            finished_goods_unit_type,
            sensor_count_values,
            speed_values,
            waste_values,
            rework_values,
            finished_goods_values,
            sensor_count_display_unit_type,
            speed_display_unit_type,
            waste_display_unit_type,
            rework_display_unit_type,
            finished_goods_display_unit_type,
            speed_unit_conversion_value,
            waste_unit_conversion_value,
            rework_unit_conversion_value,
            finished_goods_unit_conversion_value
        } = this.state;
        let line_id = payload.id;

        let data = {};
        if (sensor_count_change) {
            data['output'] = {
                input_unit_id: sensor_count_unit_type,
                display_unit_id: sensor_count_display_unit_type,
                base_unit_conversion_value: 1,
                unit_conversion_value: parseFloat(sensor_count_values[0])
            };
        }

        if (speed_change) {
            data['speed'] = {
                input_unit_id: speed_unit_type,
                display_unit_id: speed_display_unit_type,
                base_unit_conversion_value: parseFloat(speed_unit_conversion_value),
                unit_conversion_value: parseFloat(speed_values[0])
            };
        }

        if (waste_change) {
            data['waste'] = {
                input_unit_id: waste_unit_type,
                display_unit_id: waste_display_unit_type,
                base_unit_conversion_value: parseFloat(waste_unit_conversion_value),
                unit_conversion_value: parseFloat(waste_values[0])
            };
        }

        if (rework_change) {
            data['rework'] = {
                input_unit_id: rework_unit_type,
                display_unit_id: rework_display_unit_type,
                base_unit_conversion_value: parseFloat(rework_unit_conversion_value),
                unit_conversion_value: parseFloat(rework_values[0])
            };
        }

        if (finished_goods_change) {
            data['finished_goods'] = {
                input_unit_id: finished_goods_unit_type,
                display_unit_id: finished_goods_display_unit_type,
                base_unit_conversion_value: parseFloat(finished_goods_unit_conversion_value),
                unit_conversion_value: parseFloat(finished_goods_values[0])
            };
        }

        let params = {
            path: 'edit_line_units_defaults',
            path_variables: {
                LINE_ID: parseInt(line_id)
            },
            data,
            success: () => this.submitSuccess(payload),
            error: this.submitError
        };

        API.c(params, 2);
    };

    submitSuccess = data => {
        this.setState({
            submitting_line: false
        });

        this.props.openLineDetails(data);
        this.props.modified();
        this.props.hideModal();
    };

    submitError = error => {
        this.setState({
            submitting_line: false,
            submit_status: 'There was an error adding this line'
        });
    };
    validateInputs() {
        let error_free = true;

        this.setState({
            line_name_error: null,
            target_efficiency_error: null,
            description_error: null,
            sensor_count_unit_type_error: false,
            speed_unit_type_error: false,
            waste_unit_type_error: false,
            rework_unit_type_error: false,
            finished_goods_unit_type_error: false,
            sensor_count_values_error: false,
            speed_values_error: false,
            waste_values_error: false,
            rework_values_error: false,
            finished_goods_values_error: false
        });

        if (this.state.line_name == '') {
            error_free = false;
            this.setState({
                line_name_error: 'Line Name required'
            });
        }

        if (this.state.line_description == '') {
            error_free = false;
            this.setState({
                description_error: 'Line Description required'
            });
        }

        if (this.state.target_efficiency == '' || this.state.target_efficiency_error == 0) {
            error_free = false;
            this.setState({
                target_efficiency_error: 'Target Efficiency required'
            });
        }

        if (this.state.sensor_count_unit_type === '') {
            error_free = false;
            this.setState({
                sensor_count_unit_type_error: true
            });
        }

        if (this.state.speed_unit_type === '') {
            error_free = false;
            this.setState({
                speed_unit_type_error: true
            });
        }

        if (this.state.waste_unit_type === '') {
            error_free = false;
            this.setState({
                waste_unit_type_error: true
            });
        }

        if (this.state.rework_unit_type === '') {
            error_free = false;
            this.setState({
                rework_unit_type_error: true
            });
        }

        if (this.state.finished_goods_unit_type === '') {
            error_free = false;
            this.setState({
                finished_goods_unit_type_error: true
            });
        }

        if (this.getTotalValue(this.state.sensor_count_values) === '--') {
            error_free = false;
            this.setState({
                sensor_count_values_error: true
            });
        }

        if (this.getTotalValue(this.state.speed_values) === '--') {
            error_free = false;
            this.setState({
                speed_values_error: true
            });
        }

        if (this.getTotalValue(this.state.waste_values) === '--') {
            error_free = false;
            this.setState({
                waste_values_error: true
            });
        }

        if (this.getTotalValue(this.state.rework_values) === '--') {
            error_free = false;
            this.setState({
                rework_values_error: true
            });
        }

        if (this.getTotalValue(this.state.finished_goods_values) === '--') {
            error_free = false;
            this.setState({
                finished_goods_values_error: true
            });
        }

        return error_free;
    }

    showSubmitButton() {
        if (!this.state.submitting_line) {
            return (
                <div style={{ float: 'right' }}>
                    <Button variant="contained" color="primary" onClick={this.submitLine.bind(this)}>
                        Submit
                    </Button>
                </div>
            );
        } else {
            return (
                <div style={{ float: 'right' }}>
                    <CircularProgress mode="indeterminate" />
                </div>
            );
        }
    }

    showSubmitStatus() {
        if (this.state.submit_status) {
            return <div className="update-status__error">{this.state.submit_status}</div>;
        } else {
            return <div />;
        }
    }

    render() {
        return (
            <div>
                {this.showAddLineFields()}
                {this.showSubmitButton()}
                {this.showSubmitStatus()}
            </div>
        );
    }
}

export default AddLine;
