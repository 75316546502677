import React, {Component} from 'react';
import HeaderTopNav from '../components/HeaderTopNav'
import Footer from "../components/Footer";
import UserManagementUsersTable from "./UserManagementUsersTable";
import SideNav from "../components/SideNav";
import UserManagementDialog from "./UserManagementDialog";
import UserManagementOrgFactoryDropdown from "./UserManagementOrgFactoryDropdown";
import {auth0UserManagementAPIInstance} from "../components/Auth0API"
import {Fab, CircularProgress} from "@material-ui/core";
import {AddOutlined} from "@material-ui/icons";

const ROLES = [
    {name: "Admin", value: "factory_admin", id: 1},
    {name: "Read Only", value: "factory_read_only", id: 2},
    {name: "Supervisor", value: "factory_supervisor", id: 3},
    {name: "Organization Owner", value: "organization_owner", id: 4},
    {name: "Scoreboard", value: "scoreboard", id: 5},
    {name: "Floor Team", value: "floor_team", id: 6},
    {name: "Dosom", value: "dosom", id: 7},
    {name: "External Developer", value: "external_developer", id: 8},
    {name: "Account Owner", value: "account_owner", id: 9},
    ]

class UserManagementContainer extends Component {
    constructor(props) {
        super(props);
        this.webAuth = Auth0;
        this.state = {
            collapse: true,
            open_new_user_dialog: false,
            organizations: null,
            organization: null,
            factory_id: null,
            factory: null,
            all_users: null,
            org_users: null,
            factory_users: null,
            show_edit_user: false,
            edit_user: null,
            saving_user: false,
            saving_user_error: null,
            calls: 0
        }
    }

    /* -------- LIFECYCLE METHODS -------- */
    componentDidMount() {
        let org = this.defaultOrg(this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"]);
        let userFactory = window.localStorage.getItem("factory");
        this.setState({
            organizations: this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"],
            organization: org,
            factory_id: this.defaultFactoryId(),
            factory: JSON.parse(window.localStorage.getItem("factory")),
            user_role: JSON.parse(window.localStorage.getItem('factory')).role
        })

        this.getOrgUsers(org)
    }


    /* -------- FUNCTIONS -------- */

    onCollapseClickChange = (collapse) => {
        this.setState({collapse})
    }

    openAddNewUserDialog = () => {
        this.setState({
            open_new_user_dialog: true
        })
    }

    closeAddNewUserDialog = () => {
        this.setState({
            open_new_user_dialog: false,
            show_edit_user: false,
            saving_user: false,
            saving_user_error: null
        })
    }

    defaultOrg = (orgs) => {
        let defaultOrg = JSON.parse(window.localStorage.getItem("organization")).id

        return defaultOrg ? defaultOrg : orgs[0].id
    }

    defaultFactoryId = () => {
        let defaultFactory = JSON.parse(window.localStorage.getItem("factory"))

        return defaultFactory ? defaultFactory.id : null
    }

    onOrgSelected = (organization) => {
        if(organization !== this.state.organization) {
            this.getOrgUsers(organization)
            this.setState({
                organization,
                factory: null,
                factory_users: null
            })
        }
    }

    onFactorySelected = (factory) => {
        this.setState({
            factory,
            factory_users: this.filterFactoryUsers(this.state.org_users, factory)
        })
    }

    filterOrgUsers = (users, org_id) => {
        let orgUsers = null;
        if(users && users[0]) {
            orgUsers = [];
            users.forEach(user => {
                let userOrgs = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    userOrgs = user.app_metadata.organizations;
                }

                if(userOrgs && userOrgs[0]) {
                    let userInThisOrg = userOrgs.find(org => org.id === org_id)
                    if(userInThisOrg) {
                        orgUsers.push(user)
                    }
                }
            })
        }

        return orgUsers
    }

    filterFactoryUsers = (users, factory_id) => {
        let factoryUsers = null;
        if(users && users[0]) {
            factoryUsers = [];
            users.forEach(user => {
                let userFactories = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    let userOrg = user.app_metadata.organizations.find(org => org.id === this.state.organization)
                    userFactories = userOrg ? userOrg.factories : null;
                }

                if(userFactories && userFactories[0]) {
                    let userInFactory = userFactories.find(factory => factory.id === factory_id)
                    if(userInFactory) {
                        factoryUsers.push(user)
                    }
                }
            })
        }

        return factoryUsers
    }

    onManageUserClicked = (user) => {
        this.setState({
            show_edit_user: true,
            edit_user: user
        })
    }

    getOrgName = () => {
        if(this.state.organization && this.state.organizations) {
            return this.state.organizations.find((org) => org.id === this.state.organization).name
        }
    }

    /* -------- API CALLS -------- */
    getOrgUsers = (org) => {
        auth0UserManagementAPIInstance.get(`auth0/user/search?organization_id=${org}&factory_id=${this.defaultFactoryId()}`)
            .then(res => {
                let org_users = this.filterOrgUsers(res.data, org)
                let factory_users = null
                if(this.state.factory_id) {
                    factory_users = this.filterFactoryUsers(org_users, this.state.factory_id)
                }
                this.setState({
                    all_users: res.data,
                    org_users,
                    factory_users
                })
            })
            .catch(err => {
                console.log(err)
                let error = err
                if(err && err.response && err.response.data) {
                    error = err.response.data.message
                }

                this.setState({
                    error
                })
            })
    }

    addEditUser = (email, active_factories) => {
        let idToken = window.localStorage.getItem("idToken");
        let calls = 0;
        if(idToken) {
            this.setState({
                saving_user: true
            })

            active_factories.forEach(factory => {
                if(factory.is_active && !factory.initial_is_active) {
                    calls++;
                    let params = {
                        organization: {
                            id: this.state.organization,
                            name: this.getOrgName(),
                        },
                        factory: {
                            id: factory.factory_id,
                            name: factory.factory_name,
                            url: factory.factory_url,
                            role: factory.role
                        },
                        user_email: email
                    }

                    auth0UserManagementAPIInstance.put("auth0/user/factory/add", params)
                        .then(res => {
                            calls--;
                            if(calls <= 0) {
                                this.getOrgUsers(this.state.organization)
                                this.setState({
                                    saving_user: false,
                                    show_edit_user: false,
                                    open_new_user_dialog: false,
                                    edit_user: null
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({
                                saving_user: false,
                                saving_user_error: (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Error Loading Users"
                            })
                        })
                } else if(!factory.is_active && factory.initial_is_active) {
                    calls++;
                    let params = {
                        organization_id: this.state.organization,
                        factory_id: factory.factory_id,
                        user_id_auth0: this.state.edit_user.user_id
                    }

                    auth0UserManagementAPIInstance.put("auth0/user/factory/remove", params)
                        .then(res => {
                            calls--;
                            if(calls <= 0) {
                                this.getOrgUsers(this.state.organization)
                                this.setState({
                                    saving_user: false,
                                    show_edit_user: false,
                                    open_new_user_dialog: false,
                                    edit_user: null
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({
                                saving_user: false,
                                saving_user_error: (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Error Loading Users"
                            })
                        })
                } else if (factory.is_active && (factory.role !== factory.initial_role)) {
                    calls++;
                    let params = {
                        organization: {
                            id: this.state.organization,
                            name: this.getOrgName(),
                        },
                        factory: {
                            id: factory.factory_id,
                            name: factory.factory_name,
                            url: factory.factory_url,
                            role: factory.role
                        },
                        user_email: email
                    }

                    auth0UserManagementAPIInstance.put("auth0/user/factory/add", params)
                        .then(res => {
                            calls--;
                            if(calls <= 0) {
                                this.getOrgUsers(this.state.organization)
                                this.setState({
                                    saving_user: false,
                                    show_edit_user: false,
                                    open_new_user_dialog: false,
                                    edit_user: null
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({
                                saving_user: false,
                                saving_user_error: (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Error Loading Users"
                            })
                        })
                }
            })

        }

    }

    /* -------- RENDERS -------- */
    renderAddUserDialog = () => {
        if(this.state.open_new_user_dialog) {
            let factories = this.state.organizations.find((org) => org.id === this.state.organization).factories
            let orgName = this.state.organizations.find((org) => org.id === this.state.organization).name
            return <UserManagementDialog add
                                         orgName={orgName}
                                         onRequestClose={this.closeAddNewUserDialog}
                                         addEditUser={this.addEditUser}
                                         users={this.state.all_users}
                                         organization={this.state.organization}
                                         factories={factories}
                                         factory={this.state.factory}
                                         roles={ROLES}
                                         saving_user={this.state.saving_user}
                                         saving_user_error={this.state.saving_user_error}
            />
        }
    }

    renderEditUserDialog = () => {
        if(this.state.show_edit_user) {
            let factories = this.state.organizations.find((org) => org.id === this.state.organization).factories
            let orgName = this.state.organizations.find((org) => org.id === this.state.organization).name
            return <UserManagementDialog edit
                                         orgName={orgName}
                                         onRequestClose={this.closeAddNewUserDialog}
                                         addEditUser={this.addEditUser}
                                         users={this.state.all_users}
                                         user={this.state.edit_user}
                                         organization={this.state.organization}
                                         factory={this.state.factory}
                                         factories={factories}
                                         roles={ROLES}
                                         saving_user={this.state.saving_user}
                                         saving_user_error={this.state.saving_user_error}
            />
        }
    }

    renderBody = () => {
        if((!this.state.organizations || !this.state.organization || !this.state.all_users) && !this.state.factory_users) {
            return <div className="card__flex-box">
                <CircularProgress/>
            </div>
        }

        return <div>
            {this.state.user_role !== "scoreboard" && this.state.user_role !== "factory_read_only" && this.state.user_role !== "floor_team" ? (
                <Fab color="secondary" className="user-fab" onClick={this.openAddNewUserDialog}>
                    <AddOutlined />
                </Fab>
            ) : null}
            <UserManagementOrgFactoryDropdown
                organizations={this.state.organizations}
                organization={this.state.organization}
                factory={this.state.factory_id}
                onOrgSelected={this.onOrgSelected}
                onFactorySelected={this.onFactorySelected}/>
            {this.renderUsersTable()}
        </div>
    }

    renderUsersTable = () => {
        if(this.state.factory_users) {
            return <UserManagementUsersTable
                users={this.state.factory_users}
                organization={this.state.organization}
                onManageUserClicked={this.onManageUserClicked}/>
        }
    }

    render() {
        let class_name = this.state.collapse ? "reports--collapse" : "reports"
        let header_class_name = this.state.collapse ? "header header--collapse" : "header"

        return <div className="container">
            <HeaderTopNav className={header_class_name} />
            <SideNav
                onShowScoreboardClicked={()=>{}}
                onCollapseClick={this.onCollapseClickChange}
                title="User Management"
            />
            <div className="main_content_wrapper">
                {this.renderAddUserDialog()}
                {this.renderEditUserDialog()}
                <div className={class_name}>
                    {this.renderBody()}
                </div>
                <Footer/>
            </div>
        </div>
    }
}

export default UserManagementContainer
