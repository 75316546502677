import React, { Component } from 'react';
import { Button, CircularProgress, Checkbox, FormControlLabel } from "@material-ui/core";

class SelectCategoryModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_categories: props.selected_categories,
            uncategorized_products: props.uncategorized_products,
            select_button_text: "Select None"
        }
    }

    componentDidMount() {
        this.setKeyListener();
        if (this.props.categories) {
            if (this.props.selected_categories.length === this.props.categories.length) {
                this.setState({
                    select_button_text: "Select None"
                })
            } else {
                this.setState({
                    select_button_text: "Select All"
                })
            }
        }
        else {
            this.setState({
                select_button_text: ""
            })
        }
    }

    componentWillUnmount() {
        this.cancelKeyListener()
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            event.preventDefault()
            if (event.key === 'Enter') {
                if (this.state.selected_categories[0]) {
                    this.hideSelectCategories()
                }
            } else if (event.key === "Escape") {
                this.props.cancelSelectCategories()
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }

    handleAllCategoriesClick = () => {
        let updatedSelectedCategories = this.state.selected_categories
        let newUncategorizedProducts = false
        let select_button_text
        if (updatedSelectedCategories.length === this.props.categories.length) {
            updatedSelectedCategories = []
            select_button_text = "Select All"
        } else {
            updatedSelectedCategories.length = 0;
            this.props.categories.forEach(category => {
                updatedSelectedCategories.push(category.id)
            })
            newUncategorizedProducts = true
            select_button_text = "Select None"
        }
        this.setState({
            selected_categories: updatedSelectedCategories,
            select_button_text,
            uncategorized_products: newUncategorizedProducts
        })
    }

    onCategoryCheck = (index, event, isInputChecked) => {
        let updatedSelectedCategories = Array.from(this.state.selected_categories);
        if (!isInputChecked) {
            let i = updatedSelectedCategories.indexOf(this.props.categories[index].id);
            if (i !== -1) updatedSelectedCategories.splice(i, 1);
        } else {
            updatedSelectedCategories.push(this.props.categories[index].id)
        }
        let select_button_text;
        if (updatedSelectedCategories.length === this.props.categories) {
            select_button_text = "Select None"
        } else {
            select_button_text = "Select All"
        }
        this.setState({
            selected_categories: updatedSelectedCategories,
            select_button_text
        })
    }

    hideSelectCategories = () => {
        if (this.state.selected_categories.length > 0 || this.state.uncategorized_products) {
            this.props.hideSelectCategories(this.state.selected_categories, this.state.uncategorized_products)
        }
    }

    renderCategories = () => {
        if (!this.props.categories || !this.props.categories[0]) {
            return <div className="reports__loading">
                <>
                    <FormControlLabel className="select-products-body-item"
                        control={<Checkbox checked={this.state.uncategorized_products}
                            color={"primary"}
                            onChange={() => this.setState({ uncategorized_products: !this.state.uncategorized_products })} />}
                        label={'No Category'}
                        key={"uncategorized"} />
                </>
            </div>
        }

        return (
            <>
                <FormControlLabel className="select-products-body-item"
                    control={<Checkbox checked={this.state.uncategorized_products}
                        color={"primary"}
                        onChange={() => this.setState({ uncategorized_products: !this.state.uncategorized_products })} />}
                    label={'No Category'}
                    key={"uncategorized"} />
                {this.props.categories.map((category, index) => {
                    return <FormControlLabel className="select-products-body-item"
                        control={<Checkbox checked={this.state.selected_categories.indexOf(category.id) > -1}
                            color={"primary"}
                            onChange={this.onCategoryCheck.bind(this, index)} />}
                        label={category.name}
                        key={"product-" + category.id} />
                })}
            </>)
    }


    render() {

        //This style object is to move the modal on the horizontally to match the open on top of the dropdown field
        let containerStyle = {}
        if (this.props.left) {
            containerStyle['left'] = this.props.left
        }
        if (this.props.top) {
            containerStyle['top'] = this.props.top
        }
        if (this.props.height) {
            containerStyle['height'] = this.props.height
        }

        return <div>
            <div
                className="select-products"
                onClick={this.hideSelectCategories}
            />
            <div className="select-products-container" style={containerStyle}>
                <div className="select-products-header">
                    <div style={{ flex: 1, marginLeft: "24px" }}>
                        <div className="select-products-header-button modal__content-title__text">Category Filter</div>
                    </div>
                    <Button variant="text"
                        color="primary"
                        className="select-products-header-button"
                        onClick={this.handleAllCategoriesClick}>{this.state.select_button_text}</Button>
                    <Button variant="contained"
                        color="primary"
                        className="select-products-header-button"
                        onClick={this.hideSelectCategories}
                        disabled={this.state.selected_categories.length < 1 && !this.state.uncategorized_products}>Filter</Button>
                </div>
                <div className="select-products-body">
                    {this.renderCategories()}
                </div>

            </div>
        </div>
    }
}

export default SelectCategoryModal