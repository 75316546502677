import React, { Component } from 'react';

import {
    BarChart,
    ComposedChart,
    LineChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Cell
} from 'recharts'
import CustomEfficiencyToolTip from "./CustomEfficiencyToolTip";
import Chrono from "./Chrono";
import DowntimeChrono from "./DowntimeChrono";

class LineDetailsMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lines: null,
            run_time: 0,
            down_time: "",
            down_time_reason: null,
            pareto_modal: false,
            loading: false,
            date_from: this.getLastBusinessDay(),
            report: this.props.line,
            dateRange: '',
            index: this.props.linePosition,
            line_status: null
        }
    }

    getLastBusinessDay = () => {
        let d = new Date();
        if(d.getDay() == 0){
            d.setDate(d.getDate() - 3)
        } else {
            d.setDate(d.getDate() - 1)
        }
        return d;
    }

    componentDidMount = () => {
        LineStore.subscribe(this.handleLineChange);
        this.setEscape();
    }

    setEscape = () => {
        window.onkeydown = (event) => {
            if ( event.keyCode == 27 ) {
                this.props.hidePresentationMode();
            }
        };
    }


    componentWillUnmount () {
        LineStore.unsubscribe(this.handleLineChange);
        clearInterval(this.refreshInterval);
        this.cancelEscape();
    }

    cancelEscape = () => {
        window.onkeydown = null;
    }


    handleLineChange = () => {
        this.setState({
            lines: LineStore.get()
        })
    }

    formatHour = (time) => {
        let d = new Date(time);
        let h = d.getHours();

        if (h < 12) {
            if (h == 0) {
                return "12a";
            }
            return h + "a";
        } else {
            if (h - 12 == 0) {
                return "12p";
            }
            return (h - 12) + "p";
        }
    }


    getDownTimeReason = () => {
        if (!this.state.lines) {
            return "DownTime";
        }

        if (this.state.down_time_reason != null) {
            return this.state.down_time_reason;
        } else {
            return this.state.line_status
        }
    }


    getDownTimeClass = (x = false) => {
        if (this.state.lines == null || this.state.lines.count == 0) {
            return 'presentation-mode__data-item--not-running';
        }

        let c = 'presentation-mode__data-item';
        if (this.state.lines[this.state.index].line_status == 'notrunning') {
            c += '--not-running';
        } else if (this.state.down_time_reason == null) {
            c += '--disabled';
        } else if (this.state.down_time_reason != null && x == false) {
            c += '--error';
        } else {
            c += '--down';
        }

        return c;
    }


    showChart = (data) => {
        if (this.state.lines == null || this.state.lines.count == 0) {
            return;
        }
        let stat = this.state.lines[this.state.index].line_status;

        return <div className="presentation-mode__chart">
            <ResponsiveContainer className="presentation-mode__chart-content" width="100%" height={727}>
                <BarChart data={data}>
                    <XAxis dataKey='label' domain={['dataMin', 'dataMax']}/>
                    <YAxis domain={['dataMin', 50]}/>
                    <CartesianGrid strokeDasharray="1 1"/>
                    <Tooltip active={true} content={<CustomEfficiencyToolTip/>} cursor={data.length == 0 ? false : true}/>
                    <Bar dataKey='amt'>
                        {
                            data.map((entry, index) => (
                                <Cell fill={"#6ebd3a"} key={`cell-${index}`}/>
                            ))
                        }
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            <div className="presentation-mode__label-y">
                Pieces per Hour
            </div>
        </div>
    }

    formatDate = (d) => {
        let date = new Date(d);
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return months[date.getMonth()] + " " + date.getDate();
    }

    render () {
        if (!this.state.lines || this.state.lines == null || this.state.lines.count == 0) {
            return <div>Loading...</div>;
        }

        let item = this.state.lines[this.state.index];

        let data = [];

        if (item.line_status !== 'notrunning') {
            item.output_per_hour.map((item, index) =>
                data.push({label: this.formatHour(item.hour_of_day), amt: Math.floor(item.output*100)/100 })
            );
        } else {
            return <div className="presentation-mode__img">

                <img src="/images/lines_not_running.png" />
            </div>
        }

        // if (this.state.pareto_modal == true) {
        //     return <div>
        //         {this.showParetoModal()}
        //     </div>;
        // }

        return <div className="liveview--modal">
            <div className="presentation-mode__data">
                <div className={this.getDownTimeClass(true)}>
                    <Chrono item={this.state.lines[this.state.index]} style="presentation-mode__data-item__value"/>
                    <div className="presentation-mode__data-item__title">Run Time</div>
                </div>
                <DowntimeChrono line={this.state.lines[this.state.index]}/>
                <div className={this.getDownTimeClass(true)}>
                    <div className="presentation-mode__data-item__value">{item.line_status == 'notrunning' ? '- -' : (item.total_output_pieces + " Pieces")}</div>
                    <div className="presentation-mode__data-item__title">Output</div>
                </div>
                <div className={this.getDownTimeClass(true)}>
                    <div className="presentation-mode__data-item__value">{item.line_status == 'notrunning' ? '- -' : (item.last_speed_pieces_per_minute + " " + item.speed_unit)}</div>
                    <div className="presentation-mode__data-item__title">Speed</div>
                </div>
            </div>

            {this.showChart(data)}
        </div>

    }

}

export default LineDetailsMode;
