import { DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Dialog } from 'material-ui';
import React, { useState } from 'react';

const RenameEmailedReportModal = (props) => {

    const [newName, setNewName] = useState(props.reportName)
    const [isNameAvailable, setIsNameAvailable] = useState(true)

    const onNameChange = (name) => {
        if (name.length < 200) {
            setNewName(name)
        }
    }

    const onSaveName = () => {
        let nameOK = true
        if (props.type === "rename") {
            props.reports.forEach((item) => {
                if (item.name === newName && item.id !== props.reportId) {
                    setIsNameAvailable(false)
                    nameOK = false
                }
            })
            if (newName.length > 0 && nameOK) {
                props.onSave(newName)
            }
        }
        else {
            props.reports.forEach((item) => {
                if (item.name === newName) {
                    setIsNameAvailable(false)
                    nameOK = false
                }
            })
            if (newName.length > 0 && nameOK) {
                props.onSave(newName)
            }
        }
    }

    return (
        <Dialog
            open={true}
            onClose={props.onHide}
            style={{ width: "482px !important", maxWidth: "482px !important", left: "35%" }}
        >
            <DialogTitle style={{ display: "flex", justifyContent: "center" }} disableTypography>
                <Typography variant="h6">{`${props.type === "rename" ? "Rename" : "Name"} Report`}</Typography>
            </DialogTitle>
            <DialogContent>
                <div style={{ display: "flex" , justifyContent: "center"}}>
                    <input placeholder='Enter Report Name' style={{ padding: "10px 16px", width: "328px", height: "18px", marginBottom: `${isNameAvailable ? "0px" : "6px"}`, border: `1px solid ${isNameAvailable ?  "#BDBDBD" : "#E31C53"}`, borderRadius: "4px", background: `${isNameAvailable ? "#E0E0E0" : "rgba(227, 28, 83, 0.05)"}` }} onChange={(e) => onNameChange(e.target.value)} value={newName} />
                </div>
                {isNameAvailable ? null : (
                    <span style={{ fontSize: "14px", fontWeight: "400", color: "#E31C53" }}>Report name already exists.</span>
                )}
                <div style={{ display: "flex", justifyContent: "center", marginTop: "65px"}}>
                    <div onClick={props.onHide} style={{cursor: "pointer", marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center", width: "128px", height: "51px", background: "transparent", color: "#1975D0", fontWeight: "500", fontSize: "16px", border: "1px solid #1975D0", borderRadius: "8px"}}>
                        Cancel
                    </div>
                    <div onClick={onSaveName} style={{cursor: "pointer", marginLeft: "8px", display: "flex", justifyContent: "center", alignItems: "center", width: "128px", height: "51px", background: "#1975D0", color: "#F2F2F2", fontWeight: "500", fontSize: "16px", borderRadius: "8px"}}>
                        {`${props.type === "rename" ? "Rename" : "Continue"}`}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default RenameEmailedReportModal