import React, { Component } from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import PropTypes from 'prop-types';

import Login from './views/Login';
import LiveView from './views/LiveView';
import Settings from './views/Settings';
import Account from './views/Account';
import Reports from './views/Reports';
import DowntimeExport from './views/DowntimeExport';
import { PrivateRoute } from './PrivateRoute';
import Organizations from './views/Organizations';
import UserManagementContainer from './auth0_user_management/UserManagementContainer';
import Hubs from './views/Hubs';
import TrendReportContainer from './components/reports/trend/TrendReportContainer';
import NotAllowed from './views/NotAllowed';
import {auth0UserManagementAPIInstance} from "./components/Auth0API"

class LiveTrackingRouter extends Component {
    constructor(props) {
        super(props);
        this.webAuth = Auth0;
        this.state = {
            verificationComplete: false,
            redirect: null,
            loggedIn: null,
            user: null,
            userRole: null,
            root_role_checked: false,
        };
    }

    getFactorySettings = () => {
        let params = {
            path: 'get_factory_settings',
            success: (data) => this.onGetFactorySettingsSuccess(data),
            error: () => {}
        };

        API.c(params, 2);
    };

    onGetFactorySettingsSuccess = (data) => {
        localStorage.setItem("efficiency_string_long", data.efficiency_string_long)
        localStorage.setItem("efficiency_string_short", data.efficiency_string_short)
    }

    componentDidMount() {
        User.subscribe(this.handleUserChange);
        const payload = this.webAuth.getIdTokenPayload() ? this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"] : null
        let org
        if (payload && JSON.parse(window.localStorage.getItem("organization"))) {
            org = this.defaultOrg(this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"]);
            this.getOrgUsers(org)
        }
        // let org = this.defaultOrg(this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"]);
        // this.getOrgUsers(org)
        const userFactory = window.localStorage.getItem("factory");
        let userRole = ''
        if (userFactory) {
            userRole = JSON.parse(userFactory).role
        }
        this.getFactorySettings()
        this.setState({
            userRole,
            factory_id: this.defaultFactoryId(),
        })
        if (payload) {
            if (payload.is_internal_developer || payload.is_internal_trainer) {
                this.setState({
                    userRole: "factory_admin"
                })
                const factoryData = JSON.parse(window.localStorage.getItem("factory"))
                factoryData.role = "factory_admin"
                window.localStorage.setItem("factory", JSON.stringify(factoryData))
            }
        }
    }

    componentDidUpdate() {
        const payload = this.webAuth.getIdTokenPayload() ? this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"] : null
        if (payload && !this.state.root_role_checked) {
            if (payload.is_internal_developer || payload.is_internal_trainer) {
                const factoryData = JSON.parse(window.localStorage.getItem("factory"))
                if (factoryData) {
                    factoryData.role = "factory_admin"
                    window.localStorage.setItem("factory", JSON.stringify(factoryData))
                    this.setState({
                        userRole: "factory_admin",
                        root_role_checked: true
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        User.unsubscribe(this.handleUserChange);
    }

    getOrgUsers = (org) => {
        auth0UserManagementAPIInstance.get(`auth0/user/search?organization_id=${org}&factory_id=${this.defaultFactoryId()}`)
            .then(res => {
            })
            .catch(err => {
                console.log(err)
                let error = err
                if(err && err.response && err.response.data) {
                    error = err.response.data.message
                }

                this.setState({
                    error
                })
            })
    }

    defaultOrg = (orgs) => {
        let defaultOrg = JSON.parse(window.localStorage.getItem("organization")).id

        return defaultOrg ? defaultOrg : orgs[0].id
    }

    defaultFactoryId = () => {
        let defaultFactory = JSON.parse(window.localStorage.getItem("factory"))

        return defaultFactory ? defaultFactory.id : null
    }

    handleUserChange = () => {
        this.setState({
            user: User.get()
        });
    };

    clickLink(url) {
        this.setState({ redirect: url });
    }

    amOnResetConfirm() {
        let p = window.location.pathname.split('/');

        return p[3] === 'reset' && p[4] === 'confirm';
    }

    amOnForgotPassword() {
        let p = window.location.pathname.split('/');

        return p[1] === 'forgot-password';
    }

    render() {
        if (!User.loggedIn() && window.location.pathname.split('/')[1] === 'settings') {
            window.location.pathname = '/login';
        }

        if (this.amOnResetConfirm() && User.loggedIn()) {
            window.location.pathname = '/liveview';
        }

        if (this.amOnForgotPassword() && User.loggedIn()) {
            window.location.pathname = '/liveview';
        }

        if (window.location.pathname.split('/')[1] === '' && User.loggedIn()) {
            window.location.pathname = '/liveview';
        }

        if (window.location.pathname.split('/')[1] === '' && User.loggedIn()) {
            window.location.pathname = '/organizations';
        }

        return (
            <Router>
                <Switch>
                    <Route path="/login" render={(props) => <Login {...props} />} />
                    <Route path="/organizations" render={(props) => <Organizations {...props}/>} />
                    {this.state.userRole === "scoreboard" || this.state.userRole === "factory_read_only" ? (
                        <PrivateRoute path="/user-management" component={NotAllowed} />
                    ) : (
                        <PrivateRoute path="/user-management" component={UserManagementContainer} />
                    )}
                    <PrivateRoute path="/liveview" component={LiveView} />
                    <PrivateRoute exact path="/" component={LiveView} />
                    <PrivateRoute path="/hubs" component={Hubs} />
                    {this.state.userRole === "scoreboard" || this.state.userRole === "factory_read_only" ? (
                        <PrivateRoute path="/settings" component={NotAllowed} />
                    ) : (
                        <PrivateRoute path="/settings" component={Settings} />
                    )}
                    <PrivateRoute path="/data" component={DowntimeExport} />
                    <PrivateRoute path="/account" component={Account} />
                    <PrivateRoute exact path="/reports" component={Reports} />
                    <Redirect exact from="/reports/daily" to="/reports" />{' '}
                    {/* REROUTES THE REPORTS PAGE FROM RUN REPORT TO DAILY*/}
                    <PrivateRoute path="/reports/trend" component={TrendReportContainer} />
                </Switch>
            </Router>
        );
    }
}

LiveTrackingRouter.propTypes = {
    name: PropTypes.string,
    obj: PropTypes.string
};

LiveTrackingRouter.defaultProps = {
    name: '',
    obj: ''
};

export default LiveTrackingRouter;
