import React, { PureComponent } from 'react';
import { Tooltip as TippyTooltip } from 'react-tippy';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CircularProgress, Divider, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import { utcToLocal } from '../utils/utcToLocal';

class RunTimeline extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /* ######## LIFECYCLE METHODS ######## */

    /* ######## FUNCTIONS ######## */
    getWidth = (event, duration) => {
        return (event.d / duration) * 100 + '%';
    };

    getBackground = event => {
        if (!this.props.active_lost_time_reason) {
            return event.t === 'downtime'
                ? event.e.not_factored_in_oee
                    ? Styles.repeatingNonOEEBackground()
                    : '#ce3125'
                : '#6ebd3a';
        } else {
            if (event.t === 'uptime') {
                return '#6ebd3a80';
            } else {
                switch (event.e.reason_name) {
                    case this.props.active_lost_time_reason:
                        return '#ce3125';
                    default:
                        return '#ce312580';
                }
            }
        }
    };

    compare = (a, b) => {
        if (new Date(a.start_time).getTime() < new Date(b.start_time).getTime()) {
            return -1;
        } else if (new Date(a.start_time).getTime() > new Date(b.start_time).getTime()) {
            return 1;
        } else {
            return 0;
        }
    };

    editEvent = event => {
        if (event.t === 'downtime') {
            if (!this.props.active_lost_time_reason || this.props.active_lost_time_reason === event.e.reason_name) {
                this.props.editEvent(event.e);
            }
        }
    };

    /* ######## API CALLS ######## */

    /* ######## RENDERS ######## */
    renderEventDiv = event => {
        if (event.t === 'downtime') {
            if (!this.props.active_lost_time_reason || this.props.active_lost_time_reason === event.e.reason_name) {
                return (
                    <TippyTooltip
                        html={
                            <div
                                style={{
                                    fontFamily: 'Roboto',
                                    fontSize: '12px',
                                    color: '#000',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start'
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <div>Event: </div>
                                    <div>Reason: </div>
                                    <div>Started: </div>
                                    <div>Ended: </div>
                                    <div>Duration: </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        marginLeft: '8px'
                                    }}
                                >
                                    <div>Downtime</div>
                                    <div>{event.e.reason_name}</div>
                                    <div>{moment(event.e.start_time).tz(this.props.timeZone).format('LTS')}</div>
                                    {/*{Util.formatHourMinuteSecondsAmPm(event.e.start_time)}*/}
                                    <div>
                                        {event.e.end_time
                                            ? moment(event.e.end_time).tz(this.props.timeZone).format('LTS')
                                            : 'In Progress'}
                                    </div>{' '}
                                    {/*{event.e.end_time ? Util.formatHourMinuteSecondsAmPm(event.e.end_time) : "In Progress"}*/}
                                    <div>
                                        {Util.formatHourMinuteSecondsFromSeconds(
                                            ((event.e.end_time ? new Date(event.e.end_time).getTime() : new Date()) -
                                                new Date(event.e.start_time).getTime()) /
                                                1000
                                        )}
                                    </div>
                                </div>
                            </div>
                        }
                        position="top"
                        animation="fade"
                        theme="light"
                        arrow={true}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                cursor: 'pointer'
                            }}
                        >
                            &nbsp;
                        </div>
                    </TippyTooltip>
                );
            } else {
                return <div>&nbsp;</div>;
            }
        } else {
            return <div>&nbsp;</div>;
        }
    };

    renderEvents = (durations, duration) => {
        return durations.map((event, index) => {
            return (
                <div
                    key={'event-' + index}
                    style={{
                        height: '100%',
                        width: this.getWidth(event, duration),
                        background: this.getBackground(event)
                    }}
                    onClick={this.editEvent.bind(this, event)}
                >
                    {this.renderEventDiv(event)}
                </div>
            );
        });
    };

    renderXAxis = () => {
        let startTime;
        let endTime;
        if (this.props.shift_start_time && this.props.run.run_start_time_tz) {
            const shiftStartTime = this.props.shift_start_time
                ? new Date(this.props.shift_start_time.replace(/ /g, 'T')).getTime() / 1000
                : null;
            const shiftEndTime = this.props.shift_end_time
                ? new Date(this.props.shift_end_time.replace(/ /g, 'T')).getTime() / 1000
                : null;
            const runStartTime = this.props.run.run_start_time_tz
                ? new Date(this.props.run.run_start_time_tz.replace(/ /g, 'T')).getTime() / 1000
                : null;
            const runEndTime =
                (this.props.run.run_end_time_tz
                    ? new Date(this.props.run.run_end_time_tz.replace(/ /g, 'T'))
                    : new Date()
                ).getTime() / 1000;
            startTime = shiftStartTime > runStartTime ? shiftStartTime : runStartTime;
            endTime = shiftEndTime < runEndTime ? shiftEndTime : runEndTime;
        } else {
            startTime = this.props.run.run_start_time
                ? new Date(this.props.run.run_start_time.replace(/ /g, 'T')).getTime() / 1000
                : null;
            endTime =
                (this.props.run.run_end_time
                    ? new Date(this.props.run.run_end_time.replace(/ /g, 'T'))
                    : new Date()
                ).getTime() / 1000;
        }

        let duration = endTime - startTime;
        let interval = 60;
        if (duration <= 300) {
            interval = 60;
        } else if (duration > 300 && duration <= 900) {
            interval = 180;
        } else if (duration > 900 && duration <= 1800) {
            interval = 300;
        } else if (duration > 1800 && duration <= 3600) {
            interval = 600;
        } else if (duration > 3600 && duration <= 10800) {
            interval = 1800;
        } else if (duration > 10800) {
            interval = 3600;
        }

        let xAxisLabels = [];
        for (let i = startTime; i < endTime; i += interval) {
            xAxisLabels.push(i * 1000);
        }
        xAxisLabels.push(endTime * 1000);

        let data = [];

        if (this.props.shift_start_time) {
            const shiftStartTime = new Date(this.props.shift_start_time).getTime();
            const shiftEndTime = new Date(this.props.shift_end_time).getTime();
            const runStartTime = new Date(this.props.run.run_start_time).getTime();
            const runEndTime = (this.props.run.run_end_time
                ? new Date(this.props.run.run_end_time)
                : new Date()
            ).getTime();
            data = [
                { time: shiftStartTime > runStartTime ? shiftStartTime : runStartTime },
                { time: shiftEndTime < runEndTime ? shiftEndTime : runEndTime }
            ];
        } else {
            data = [
                { time: new Date(this.props.run.run_start_time).getTime() },
                { time: (this.props.run.run_end_time ? new Date(this.props.run.run_end_time) : new Date()).getTime() }
            ];
        }

        return (
            <div className="reports__data-lost-time" style={{ width: 'calc(100% - 64px)', margin: '-4px 0 60px 16px' }}>
                <ResponsiveContainer width="100%" height={40}>
                    <BarChart data={data}>
                        <XAxis
                            dataKey="time"
                            interval={'preserveStartEnd'}
                            allowDuplicatedCategory
                            scale={'time'}
                            type={'number'}
                            domain={['auto', 'auto']}
                            ticks={xAxisLabels}
                            tickLine={false}
                            tickFormatter={v => {
                                return moment(v).tz(this.props.timeZone).format('LT');
                            }}
                            axisLine={false}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    };

    renderTimeline = () => {
        if (!this.props.log) {
            return (
                <div
                    style={{
                        height: '88px',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-around'
                    }}
                >
                    <CircularProgress />
                </div>
            );
        }

        let startTime;
        let endTime;

        if (this.props.shift_start_time) {
            const shiftStartTime = new Date(this.props.shift_start_time).getTime() / 1000;
            const shiftEndTime = new Date(this.props.shift_end_time).getTime() / 1000;
            const runStartTime = new Date(this.props.run.run_start_time).getTime() / 1000;
            const runEndTime =
                (this.props.run.run_end_time ? new Date(this.props.run.run_end_time) : new Date()).getTime() / 1000;
            startTime = shiftStartTime > runStartTime ? shiftStartTime : runStartTime;
            endTime = shiftEndTime < runEndTime ? shiftEndTime : runEndTime;

            // startTime = new Date(this.props.shift_start_time).getTime() / 1000;
            // endTime = new Date(this.props.shift_end_time).getTime() / 1000;
        } else {
            startTime = new Date(this.props.run.run_start_time).getTime() / 1000;
            endTime =
                (this.props.run.run_end_time ? new Date(this.props.run.run_end_time) : new Date()).getTime() / 1000;
        }

        // 12 -1 am
        let downtimes = this.props.log;
        downtimes.sort(this.compare);

        let duration = endTime - startTime;
        let durations = [];
        let sTime = startTime;
        downtimes.map(downtime => {
            let downtimeStartTime = new Date(downtime.start_time).getTime() / 1000;
            let upTimeDuration = downtimeStartTime - sTime;
            durations.push({ d: upTimeDuration, s: sTime, t: 'uptime' });
            let downtimeEndTime = (downtime.end_time ? new Date(downtime.end_time) : new Date()).getTime() / 1000;
            let downTimeDuration = downtimeEndTime - downtimeStartTime;
            durations.push({ d: downTimeDuration, s: downtimeStartTime, t: 'downtime', e: downtime });
            sTime = downtimeEndTime;
        });

        //last uptime
        let lastUpDuration = endTime - sTime;
        durations.push({ d: lastUpDuration, s: sTime, t: 'uptime' });

        return (
            <div>
                <div style={{ height: '88px', display: 'flex', flexDirection: 'row', width: '100%' }}>
                    {this.renderEvents(durations, duration)}
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="reports_card">
                <Typography variant={'subtitle2'} align={'left'} className="reports_card--title">
                    Run Timeline: Downtime
                </Typography>
                <Divider />
                <div style={{ margin: '60px 48px 0' }}>{this.renderTimeline()}</div>
                {this.renderXAxis()}
            </div>
        );
    }
}

export default RunTimeline;
