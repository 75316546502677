import React, { Component, Fragment } from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Tooltip,
    TextField,
    CircularProgress,
    Box
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import ManageLineUnitsModal from '../modals/ManageLineUnitsModal';
import {auth0UserManagementAPIInstance} from "../components/Auth0API"
import AutoStartEnd from './AutoStartEnd';
import EditRun from '../modals/EditRun';
import AddAutoStartEnd from '../modals/AddAutoStartEnd';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

class LineDetails extends Component {
    constructor(props) {
        super(props);
        this.webAuth = Auth0;
        this.state = {
            line: null,
            lineId: props.lineId,
            line_name: '',
            target_efficiency: 0,
            name_error: null,
            efficiency_error: null,
            modified: false,
            updated: false,
            submit_error_msg: null,
            submitting_info: false,
            notes_option: 'OPTIONAL',
            default_unit_conversion: null,
            show_modal: false,
            is_auto_start_modal_open: false,
            is_auto_end_modal_open: false,
            auto_start_data: [],
            auto_end_data: []
        };
    }

    componentDidMount() {
        this.getLineInfo();
        this.getLastEditLine();
        this.getAutoStartInfo();
        this.getAutoEndInfo();
        this.getDowntimeReasonList();
        let org = this.defaultOrg(this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"]);
        this.setState({
            organizations: this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"],
            organization: org,
            factory_id: this.defaultFactoryId(),
            factory: JSON.parse(window.localStorage.getItem("factory")),
        })
        this.getOrgUsers(org);
    }

    componentDidUpdate() {
        // this.getLastEditLine();
    }

    defaultOrg = (orgs) => {
        let defaultOrg = JSON.parse(window.localStorage.getItem("organization")).id

        return defaultOrg ? defaultOrg : orgs[0].id
    }

    defaultFactoryId = () => {
        let defaultFactory = JSON.parse(window.localStorage.getItem("factory"))

        return defaultFactory ? defaultFactory.id : null
    }

    getOrgUsers = (org) => {
        auth0UserManagementAPIInstance.get(`auth0/user/search?organization_id=${org}&factory_id=${this.defaultFactoryId()}`)
            .then(res => {
                let org_users = this.filterOrgUsers(res.data, org)
                let factory_users = null
                if(this.state.factory_id) {
                    factory_users = this.filterFactoryUsers(org_users, this.state.factory_id)
                }
                const copy_factory_users = [...factory_users]
                let selected = copy_factory_users.sort((a, b) => a.email.split("@")[1].localeCompare(b.email.split("@")[1]))
                selected.forEach((item, i) => {
                    selected[i] = item.user_id
                })
                this.setState({
                    all_users: res.data,
                    org_users,
                    factory_users: factory_users.sort((a, b) => a.email.split("@")[1].localeCompare(b.email.split("@")[1])),
                    selected_users: selected
                })
            })
            .catch(err => {
                console.log(err)
                let error = err
                if(err && err.response && err.response.data) {
                    error = err.response.data.message
                }

                this.setState({
                    error
                })
            })
    }

    filterOrgUsers = (users, org_id) => {
        let orgUsers = null;
        if(users && users[0]) {
            orgUsers = [];
            users.forEach(user => {
                let userOrgs = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    userOrgs = user.app_metadata.organizations;
                }

                if(userOrgs && userOrgs[0]) {
                    let userInThisOrg = userOrgs.find(org => org.id === org_id)
                    if(userInThisOrg) {
                        orgUsers.push(user)
                    }
                }
            })
        }

        return orgUsers
    }

    filterFactoryUsers = (users, factory_id) => {
        let factoryUsers = null;
        if(users && users[0]) {
            factoryUsers = [];
            users.forEach(user => {
                let userFactories = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    let userOrg = user.app_metadata.organizations.find(org => org.id === this.state.organization)
                    userFactories = userOrg ? userOrg.factories : null;
                }

                if(userFactories && userFactories[0]) {
                    let userInFactory = userFactories.find(factory => factory.id === factory_id)
                    if(userInFactory) {
                        factoryUsers.push(user)
                    }
                }
            })
        }

        return factoryUsers
    }

    handleChange(event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState({
            s,
            modified: true
        });
    }

    getLastEditLine = () => {
        let params = {
            path: 'get_last_edit_line',
            path_variables: {
                LINE_ID: this.state.lineId
            },
            success: this.onLastEditSuccess,
            error: this.onLastEditError
        };

        API.c(params);
    };

    getDowntimeReasonList = () => {
        let params = {
            path: 'all_reason_categories',
            success: this.onGetDowntimeReasonListSuccess,
            error: this.onGetDowntimeReasonListError
        };

        API.c(params);
    }

    getLineInfo = () => {
        let params = {
            path: 'get_line_details',
            path_variables: {
                LINE_ID: this.state.lineId
            },
            success: this.onLineInfoSuccess,
            error: this.onLineInfoError
        };

        API.c(params);
    };

    getAutoStartInfo = () => {
        const factoryId = JSON.parse(window.localStorage.getItem("factory")).id
        const url = `${JSON.parse(window.localStorage.getItem("factory")).url}/run/autostart`
        const idToken = window.localStorage.getItem('idToken');
        const getAutoStarts = async (url) => {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'factory-id': factoryId,
                    'Content-Type': 'application/json'
                },
            });
            return await response.json();
        }

        getAutoStarts(url)
            .then((data) => {
                this.setState({
                    auto_start_data: data.filter((item) => item.line === this.state.lineId)
                })
            });
    }

    getAutoEndInfo = () => {
        const factoryId = JSON.parse(window.localStorage.getItem("factory")).id
        const url = `${JSON.parse(window.localStorage.getItem("factory")).url}/run/autoend`
        const idToken = window.localStorage.getItem('idToken');
        const getAutoEnds = async (url) => {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'factory-id': factoryId,
                    'Content-Type': 'application/json'
                },
            });
            return await response.json();
        }

        getAutoEnds(url)
            .then((data) => {
                this.setState({
                    auto_end_data: data.filter((item) => item.line === this.state.lineId)
                })
            });
    }

    onLineInfoSuccess = data => {
        this.setState({
            line: data,
            line_name: data.line_info.name,
            target_efficiency: data.line_info.target_efficiency,
            notes_option: data.line_info.notes_state,
            default_unit_conversion: data.line_info.default_unit_conversion
        });
    };

    onLineInfoError = error => {
        this.setState({
            status: error
        });
    };

    onGetDowntimeReasonListSuccess = data => {
        this.setState({
            downtimeReasonsList: data
        });
    };

    onGetDowntimeReasonListError = error => {
        this.setState({
            status: error
        });
    };

    onLastEditSuccess = data => {
        this.setState({
            lastEditData: data
        });
    };

    onLastEditError = error => {
        this.setState({
            status: error
        });
    };

    parseReasonCategory = (category) => {
        let categoryName
        if (category && this.state.downtimeReasonsList && this.state.downtimeReasonsList.length > 0) {
            const categoryFound = this.state.downtimeReasonsList.find((reasonCategory) => {
                return reasonCategory.id === category
            })
            if (categoryFound && categoryFound.name) {
                categoryName = categoryFound.name
            }
        }
        return categoryName
    }

    getLastEditUser = () => {
        if (this.state.factory_users && this.state.factory_users.length > 0) {
            const userData =  this.state.factory_users.filter((user) => {
                return user.user_id === this.state.lastEditData["Updated by"]
            })
            if (userData[0] && userData[0].user_metadata && (userData[0].user_metadata.first_name || userData[0].user_metadata.last_name)) {
                return `${userData[0].user_metadata.first_name} ${userData[0].user_metadata.last_name}`
            }
            else if (userData[0] && userData[0].name) {
                return userData[0].name
            }
            else if (userData[0] && userData[0].email) {
                return userData[0].email
            }
        }

        return "System User"
    }

    showDowntimeReasonsTable = () => {
        return (
            <div>
                <div className="products__text-title">Associated Downtime Reasons</div>
                <div className="products__recipe-table">
                    <TableContainer>
                        <Table selectable={false} multiSelectable={false}>
                            <TableHead displaySelectAll={false} adjustForCheckbox={false} enableSelectAll={false}>
                                <TableRow style={{ height: '20px', paddingTop: '20px' }}>
                                    <TableCell style={Styles.tableHeaderStyle()}>Reason Code</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()}>Reason Name</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()}>Reason Type</TableCell>
                                    <TableCell style={Styles.editDeleteTableHeaderStyle()} />
                                </TableRow>
                            </TableHead>
                            <TableBody displayRowCheckbox={false}>{this.showDowntimeReasons()}</TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        );
    };

    showDowntimeReasons = () => {
        let downtimeReason = this.state.line.downtime_reasons_associated_to_line;


        return downtimeReason.map((reason, index) => {
            return (
                <TableRow sx={{ wordBreak: "break-word", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} key={'downtime-reason-' + index}>
                    <TableCell style={Styles.tableRowColumnStyle()}>{reason.code}</TableCell>
                    <TableCell style={Styles.tableRowColumnStyle()}>{reason.name}</TableCell>
                    <TableCell style={Styles.tableRowColumnStyle()}>{this.parseReasonCategory(reason.category)}</TableCell>
                    <TableCell style={Styles.editDeleteTableRowColumnStyle()} />
                </TableRow>
            );
        });
    };

    showProductsTable = () => {
        return (
            <div>
                <div className="products__text-title">Associated Products</div>
                <div className="products__recipe-table">
                    <TableContainer>
                        <Table selectable={false} multiSelectable={false}>
                            <TableHead displaySelectAll={false} adjustForCheckbox={false} enableSelectAll={false}>
                                <TableRow style={{ height: '20px', paddingTop: '20px' }}>
                                    <TableCell style={Styles.tableHeaderStyle()}>Code</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()}>Description</TableCell>
                                    <TableCell style={Styles.editDeleteTableHeaderStyle()} />
                                </TableRow>
                            </TableHead>
                            <TableBody displayRowCheckbox={false}>{this.showProducts()}</TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        );
    };

    showProducts = () => {
        let products = this.state.line.products_associated_to_line;

        return products.map((product, index) => {
            return (
                <TableRow sx={{ wordBreak: "break-word", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} key={'downtime-reason-' + product.product_id}>
                    <TableCell style={Styles.tableRowColumnStyle()}>{product.product_name}</TableCell>
                    <TableCell style={Styles.tableRowColumnStyle()}>{product.product_desc}</TableCell>
                    <TableCell style={Styles.editDeleteTableRowColumnStyle()} />
                </TableRow>
            );
        });
    };

    updateStatus = () => {
        if (this.state.submit_error) {
            return <div className="update-status__error">{this.state.submit_error_msg}</div>;
        } else if (this.state.updated) {
            return <div className="update-status__success">Update Saved</div>;
        } else {
            return <div />;
        }
    };

    showSaveButton = () => {
        let { classes } = this.props;

        if (!this.state.submitting_info) {
            return (
                <div className={classes.lineDetailsColumnButton}>
                    <Button
                        disabled={!this.state.modified}
                        color="primary"
                        variant="contained"
                        onClick={this.submitLineUpdate}
                        style={ { height: "36.5px", marginLeft: "16px", marginTop: "8px" } }
                    >
                        Save
                    </Button>
                </div>
            );
        } else if (this.state.submitting_info) {
            return (
                <div className={classes.lineDetailsColumnButton}>
                    <CircularProgress mode="indeterminate" />
                </div>
            );
        } else {
            return <div />;
        }
    };

    showEditUnitsButton = () => {
        let { classes } = this.props;

        return (
            <div className={classes.lineDetailsColumnButton} style={{ marginTop: "6px" }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={this.showEditUnitsModal}
                    style={Styles.buttonStyle()}
                >
                    Edit Units
                </Button>
            </div>
        );
    };

    showEditUnitsModal = () => {
        this.setState({
            show_modal: true
        });
    };

    validateInput = () => {
        let error_free = true;

        if (this.state.line_name === '') {
            error_free = false;
            this.setState({
                name_error: 'Invalid Line Name'
            });
        }

        if (this.state.target_efficiency <= 0) {
            error_free = false;
            this.setState({
                efficiency_error: 'Efficiency Must Be Positive'
            });
        }

        return error_free;
    };

    submitLineUpdate = () => {
        if (this.validateInput()) {
            this.setState({
                submitting_info: true,
                name_error: null,
                efficiency_error: null
            });

            let params = {
                path: 'edit_line',
                path_variables: {
                    ID: this.state.lineId
                },
                data: {
                    name: this.state.line_name,
                    target_efficiency: this.state.target_efficiency,
                    notes_state: this.state.notes_option !== undefined ? this.state.notes_option : 'OPTIONAL'
                },
                success: this.submitSuccess,
                error: this.submitError
            };

            API.c(params, 2);
        }
    };

    submitSuccess = data => {
        this.setState({
            submitting_info: false,
            updated: true
        });
        this.getLastEditLine()
    };

    submitError = error => {
        this.setState({
            status: 'There was an error updating this line'
        });
    };

    refreshLine = () => {
        this.getLineInfo();
        this.getLastEditLine();
    };

    handleNotesOptionChange = event => {
        this.setState({
            notes_option: event.target.value,
            modified: true
        });
    };

    showNotesOptions = () => {
        let { classes } = this.props;

        return (
            <FormControl className={classes.formControl}>
                <InputLabel id="notes-select-label">Downtime Notes</InputLabel>
                <Select
                    labelId="notes-select-label"
                    label="Downtime Notes"
                    id="notes_option"
                    value={this.state.notes_option}
                    onChange={this.handleNotesOptionChange}
                >
                    <MenuItem value={'OPTIONAL'}>Optional</MenuItem>
                    <MenuItem value={'REQUIRED'}>Required</MenuItem>
                    <MenuItem value={'DISABLED'}>Disabled</MenuItem>
                </Select>
            </FormControl>
        );
    };

    getTotalValue = type => {
        let { default_unit_conversion } = this.state;
        let { units } = this.props;

        if (!default_unit_conversion) {
            return '--';
        }

        if (!default_unit_conversion[type]) {
            return '--';
        }

        let noValue = '--';
        let totalValue = default_unit_conversion[type].conversion_value ? default_unit_conversion[type].conversion_value : -1;

        if (totalValue !== -1) {
            let unitObj = units.find(u => u.id === default_unit_conversion[type].unit_id);
            let unit = '';
            if (unitObj) {
                unit = ' ' + unitObj.name;
            }
            return Math.round((totalValue + Number.EPSILON) * 10000) / 10000 + unit;
        } else {
            return noValue;
        }
    };

    hideModal = () => {
        this.setState({
            show_modal: false
        });
    };

    modal = () => {
        if (this.state.show_modal) {
            return (
                <ManageLineUnitsModal
                    line={this.state.line.line_info}
                    line_id={this.state.lineId}
                    units={this.props.units}
                    line_units={this.state.default_unit_conversion}
                    refresh={this.refreshLine}
                    hideModal={this.hideModal}
                />
            );
        }
    };

    renderTooltip = type => {
        return (
            <Fragment>
                <Typography variant={'caption'}>Unit value is calculated by multiplying:</Typography>
                {this.renderTooltipRows(type)}
            </Fragment>
        );
    };

    renderTooltipRows = type => {
        let { default_unit_conversion } = this.state;

        if (!default_unit_conversion) {
            return <div>--</div>;
        }

        if (!default_unit_conversion[type]) {
            return <div>--</div>;
        }

        if (default_unit_conversion[type].length === 0) {
            return <div>--</div>;
        }

        

        return (
            <div key={'tooltip-' + type}>
                <Typography variant={'caption'}>
                    {'• ' + default_unit_conversion[type].conversion_value}
                </Typography>
            </div>
        );
    };

    renderLastEditString = () => {
        if (this.state.lastEditData["Action"] === "Deleted") {
            return `${Object.keys(this.state.lastEditData["Previous values"])[0]} deleted by ${this.getLastEditUser()} on ${this.state.lastEditData["Date"]}`
        }
        else {
            return `${Object.keys(this.state.lastEditData["New values"])[0]} changed to ${Object.values(this.state.lastEditData["New values"])[0]} by ${this.getLastEditUser()} on ${this.state.lastEditData["Date"]}`
        }
    }

    render() {
        let { classes } = this.props;

        if (this.state.line === null) {
            return (
                <div>
                    <Button
                        variant="text"
                        style={{ marginLeft: '-16px' }}
                        color="primary"
                        onClick={this.props.hideLineDetails}
                    >
                        Back to all lines
                    </Button>
                    <CircularProgress />
                </div>
            );
        } else {
            let lineInfo = this.state.line.line_info;
            return (
                <div>
                    <Button
                        variant="text"
                        style={{ marginLeft: '-16px' }}
                        color="primary"
                        onClick={this.props.hideLineDetails}
                    >
                        Back to all lines
                    </Button>
                    <div className={classes.lineDetailsWrapper}>
                        <div className={classes.lineDetailsColumn} style={{ marginRight: "120px" }}>
                            <TextField
                                className={classes.formControl}
                                id="line_name"
                                label="Line Name"
                                value={this.state.line_name}
                                onChange={this.handleChange.bind(this)}
                                error={this.state.name_error}
                                helperText={this.state.name_error != null ? this.state.name_error : ''}
                            />
                            <TextField
                                className={classes.formControl}
                                id="target_efficiency"
                                label={`Target ${localStorage.getItem("efficiency_string_long") !== 'undefined' ? localStorage.getItem("efficiency_string_long") : "Efficiency"} (%)`}
                                value={this.state.target_efficiency}
                                onChange={this.handleChange.bind(this)}
                                error={this.state.efficiency_error}
                                helperText={this.state.efficiency_error != null ? this.state.efficiency_error : ''}
                            />
                            {this.showNotesOptions()}
                            {this.showSaveButton()}
                        </div>
                        <div className={classes.lineDetailsUnitsColumn}>
                                <div className={classes.unitValueDiv}>
                                    <div className={classes.labelDiv}>
                                        <Typography variant="caption" className={classes.label}>
                                            Sensor Count Unit
                                        </Typography>
                                    </div>
                                    <div className={classes.unitValueReadOnlyDiv}>
                                        <Typography variant="body1">{this.getTotalValue('output')}</Typography>
                                    </div>
                                </div>
                                <div className={classes.unitValueDiv}>
                                    <div className={classes.labelDiv}>
                                        <Typography variant="caption" className={classes.label}>
                                            Speed Unit
                                        </Typography>
                                    </div>
                                    <div className={classes.unitValueReadOnlyDiv}>
                                        <Typography variant="body1">{this.getTotalValue('speed')}</Typography>
                                    </div>
                                </div>
                                <div className={classes.unitValueDiv}>
                                    <div className={classes.labelDiv}>
                                        <Typography variant="caption" className={classes.label}>
                                            Rework Unit
                                        </Typography>
                                    </div>
                                    <div className={classes.unitValueReadOnlyDiv}>
                                        <Typography variant="body1">{this.getTotalValue('rework')}</Typography>
                                    </div>
                                </div>
                                <div className={classes.unitValueDiv}>
                                    <div className={classes.labelDiv}>
                                        <Typography variant="caption" className={classes.label}>
                                            Waste Count Unit
                                        </Typography>
                                    </div>
                                    <div className={classes.unitValueReadOnlyDiv}>
                                        <Typography variant="body1">{this.getTotalValue('waste')}</Typography>
                                    </div>
                                </div>
                                <div className={classes.unitValueDiv}>
                                    <div className={classes.labelDiv}>
                                        <Typography variant="caption" className={classes.label}>
                                            Finished Goods Unit
                                        </Typography>
                                    </div>
                                    <div className={classes.unitValueReadOnlyDiv}>
                                        <Typography variant="body1">{this.getTotalValue('finished_goods')}</Typography>
                                    </div>
                                </div>
                            {this.showEditUnitsButton()}
                        </div>
                        <div className={classes.lineDetailsUnitsColumn}>
                            <AutoStartEnd line_products={this.state.line.products_associated_to_line} data={this.state.auto_start_data} openModal={() => this.setState({ is_auto_start_modal_open: true })} lineId={this.state.lineId} label="Auto-Start" />
                            <Box sx={{ marginTop: "30px" }} />
                            <AutoStartEnd line_products={this.state.line.products_associated_to_line} data={this.state.auto_end_data} openModal={() => this.setState({ is_auto_end_modal_open: true })} lineId={this.state.lineId} label="Auto-End" />
                            {this.state.is_auto_start_modal_open ? (
                                <AddAutoStartEnd line_products={this.state.line.products_associated_to_line} onClose={() => { this.setState({ is_auto_start_modal_open: false }); this.getAutoStartInfo(); this.getLastEditLine() }} data={this.state.auto_start_data} lineId={this.state.lineId} line={this.state.line} label={"Start"} />
                            ) : null}
                            {this.state.is_auto_end_modal_open ? (
                                <AddAutoStartEnd onClose={() => { this.setState({ is_auto_end_modal_open: false }); this.getAutoEndInfo(); this.getLastEditLine() }} data={this.state.auto_end_data} lineId={this.state.lineId} line={this.state.line} label={"End"} />
                            ) : null}
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: "1525px", marginTop: '30px' }}>
                        {this.state.lastEditData && this.state.lastEditData["New values"] ? (
                            <p style={{fontSize: "14px", fontWeight: "500", color: "#999999", marginLeft: "16px"}}>{`Last Edit: ${this.renderLastEditString()}`}</p>
                        ) : <p>N/A</p>}
                        {/* <p style={{fontSize: "14px", fontWeight: "500", color: "#999999", marginLeft: "16px"}}>Last Edit: Target speed changed to 28 lbs/min by Mike Yokota on December 25, 2023 at 5:53 PM EST</p> */}
                    </div>
                    {this.showDowntimeReasonsTable()}
                    {this.showProductsTable()}
                    {this.modal()}
                </div>
            );
        }
    }
}

const styles = theme => ({
    formControl: {
        margin: theme.spacing(2),
        width: 272
    },
    label: {
        fontWeight: 700,
        color: '#999'
    },
    labelIcon: {
        color: '#999',
        fontSize: 14
    },
    unitValueDiv: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
        maxWidth: 272
    },
    labelDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '0.75rem',
        marginBottom: theme.spacing(1)
    },
    labelIconButton: {
        padding: '6px'
    },
    unitValueReadOnlyDiv: {
        background: '#f2f2f2',
        borderRadius: '2px',
        padding: '4px 8px',
        marginTop: '-8px'
    },
    lineDetailsWrapper: {
        display: 'flex'
    },
    lineDetailsColumn: {
        // flex: 1,
        marginRight: "460px",
        // marginRight: "80px",
        display: 'flex',
        flexDirection: 'column'
    },
    lineDetailsUnitsColumn: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '12px'
    },
    lineDetailsColumnButton: {
        flex: 1,
        display: "flex",
        paddingRight: '24px'
    },
    tooltip: {
        whiteSpace: 'normal'
    }
});

export default withStyles(styles)(LineDetails);
