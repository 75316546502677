import React, { useEffect, useState } from 'react';
import './by-shift-top.css';
import CheckboxList from './checkbox-list';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import _ from 'lodash';

const ByShiftTop = ({ wasChanged, lines, timeZone, handledCheck, activeShifts }) => {
    const classes = useStyles();
    const [switched, setSwitched] = useState(!!activeShifts.length);
    const runList = 'runList';

    const removeShifts = () => {
        const href = window.location.href;
        const newHref = href.split('&shift_id')[0];
        localStorage.removeItem('checkedItemsrunList');
        window.history.replaceState(null, 'LiveTracking', newHref);
        handledCheck([]);
    };

    const handleChange = () => {
        setSwitched(!switched);
        removeShifts();
    };

    const handleCheck = id => {
        if (!!id) {
            handledCheck(id);
        }
    };

    useEffect(() => {
        window.localStorage.removeItem('shiftIdsrunReport');
    }, []);

    return (
        <div className={classes.root}>
            <div style={{ minHeight: '100px' }}>
                <div style={{ position: 'absolute' }} className="anim-switch">
                    <Typography
                        style={{
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '16.41px',
                            color: '#666666'
                        }}
                        component="span"
                        variant="body1"
                    >
                        FILTER BY SHIFT
                    </Typography>
                    <Switch
                        classes={{
                            track: classes.switch_track,
                            switchBase: classes.switch_base,
                            colorPrimary: classes.switch_primary
                        }}
                        color="primary"
                        checked={switched}
                        onChange={handleChange}
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>

                {switched ? (
                    <CheckboxList
                        filterBy={runList}
                        wasChanged={wasChanged}
                        lines={lines}
                        timeZone={timeZone}
                        runLineId={1}
                        runReportPage={false}
                        activeShifts={activeShifts}
                        switched={switched}
                        handledCheck={handleCheck}
                    />
                ) : null}
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            paddingLeft: 30,
            paddingBottom: 30
        },
        switch_track: {
            backgroundColor: 'gray'
        },
        switch_base: {
            color: '#ebebeb',
            '&.Mui-disabled': {
                color: 'gray'
            },
            '&.Mui-checked': {
                color: 'brown'
            },
            '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: 'green'
            }
        },
        switch_primary: {
            '&.Mui-checked': {
                color: '#0A71CE'
            },
            '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#0A71CE'
            }
        }
    })
);

export default ByShiftTop;
