import React, { Component } from 'react';
import {
    Button,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    CircularProgress,
    MenuItem
} from '@material-ui/core';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import {
    getDate,
    disableFromFutureDates,
    disableToFutureDates,
    handleDateToChange,
    handleTimeFromChange,
    handleTimeToChange,
    handleDateFromChange
} from '../components/DateTimeHelperUtils';
import moment from 'moment';

class AddEvent extends Component {
    constructor(props) {
        super(props);
        this.events = ['Output', 'Downtime', 'Waste', 'Rework', 'finished_goods'];
        this.state = {
            finished: false,
            stepIndex: 0,
            event: '',
            notes: '',
            output_value: 0,
            output_value_error: null,
            waste_value: 0,
            waste_value_error: null,
            rework_value: 0,
            rework_value_error: null,
            date_from: getDate(props.run.run_start_time).add(1, 'minute'),
            time_from: getDate(props.run.run_start_time).add(1, 'minute'),
            date_to: getDate(props.run.run_end_time).subtract(1, 'minute'),
            time_to: getDate(props.run.run_end_time).subtract(1, 'minute'),
            min_date_time: getDate(props.run.run_start_time).add(1, 'minute'),
            max_date_time: getDate(props.run.run_end_time),
            time_from_error: null,
            time_to_error: null,
            submit_status: '',
            loading: false,
            waste_reason: null,
            rework_reason: null,
            downtime_reason: null,
            reason: '',
            reason_error: null
        };
    }

    /* ######## Lifecycle Methods ######## */
    componentDidMount = () => {
        this.setKeyListener();
    };

    componentWillUnmount = () => {
        this.cancelKeyListener();
    };

    setKeyListener = () => {
        window.onkeydown = event => {
            if (event.keyCode === 13) {
                this.handleNext();
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    };

    cancelKeyListener = () => {
        window.onkeydown = null;
    };

    getInputUnit = type => {
        let { line_units } = this.props;

        return line_units[type] ? line_units[type].input_unit_name : '';
    };

    /* ######## FUNCTIONS ######## */

    mapReasonCategory = (categoryId) => {
        const category = this.props.reasonCategories.find((category) => category.id === categoryId)
        return category ? `${category.name} - ` : ""
    }

    compareCategory = (a, b) => {
        if (a.category_string === '' || a.category_string === null || !a.category_string) {
            return 1;
        } else if (b.category_string === '' || b.category_string === null || !b.category_string) {
            return -1;
        } else if (a.category_string.toLowerCase() === b.category_string.toLowerCase()) {
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        } else {
            return a.category_string.toLowerCase() < b.category_string.toLowerCase() ? -1 : 1;
        }
    };

    handleNext = () => {
        let { stepIndex } = this.state;
        if (stepIndex === 0) {
            if (this.state.event !== '') {
                this.setState({
                    stepIndex: 1
                });
            }
        } else {
            switch (this.state.event) {
                case 0:
                    this.submitOutput();
                    break;
                case 1:
                    this.submitDowntime();
                    break;
                case 2:
                    this.submitWaste();
                    break;
                case 3:
                    this.submitRework();
                    break;
                case 4:
                    this.submitFinishedGoods();
                    break;
            }
        }
    };

    handlePrev = () => {
        let { stepIndex } = this.state;
        if (stepIndex > 0) {
            this.setState({
                stepIndex: 0,
                notes: '',
                reason: '',
                reason_error: null
            });
        }
    };

    handleEventSelectChange = event => {
        this.setState({
            event: event.target.value
        });
    };

    handleSelectReasonChange = event => {
        this.setState({
            reason: event.target.value
        });
    };

    handleChange(event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    disableFromFutureDates(date) {
        return disableFromFutureDates(this.state.date_to, this.state.max_date_time, date);
    }

    disableToFutureDates(date) {
        return date >= new Date() || date > this.state.max_date_time;
    }

    handleDateFromChange = date => {
        let state = handleDateFromChange(date, this.state.time_from, this.state.time_to);
        this.setState(state);

        let newDateTime = state.date_from;

        if (this.state.min_date_time.isAfter(newDateTime)) {
            this.setState({
                time_from_error: 'Must be within run time'
            });
        } else if (this.state.max_date_time.isBefore(newDateTime)) {
            this.setState({
                time_from_error: 'Must be within run time'
            });
        } else if (this.state.time_to.isSameOrBefore(newDateTime)) {
            this.setState({
                time_from_error: 'End time error'
            });
        } else {
            this.setState({
                time_from_error: null
            });
        }
    };

    handleDateToChange = date => {
        let state = handleDateToChange(date, this.state.time_to, this.state.date_from);
        this.setState(state);
    };

    handleTimeFromChange = time => {
        let state = handleTimeFromChange(time, this.state.time_to, this.state.date_from);
        this.setState(state);

        let newDateTime = state.time_from;

        if (moment(newDateTime).isBefore(this.state.min_date_time)) {
            this.setState({
                time_from_error: 'Must be within run time'
            });
        } else if (this.state.max_date_time.isBefore(newDateTime)) {
            this.setState({
                time_from_error: 'Must be within run time'
            });
        } else if (this.state.time_to.isSameOrBefore(newDateTime, 'minute')) {
            this.setState({
                time_to_error: 'End time error'
            });
        } else {
            this.setState({
                time_from_error: null
            });
        }

        if (this.state.time_to.isAfter(newDateTime)) {
            this.setState({
                time_to_error: null
            });
        }
    };

    handleTimeToChange = time => {
        let state = handleTimeToChange(time, this.state.time_from, this.state.date_to);
        this.setState(state);
        let newDateTime = state.time_to;

        if (this.state.min_date_time.isAfter(newDateTime)) {
            this.setState({
                time_to_error: 'Must be within run time'
            });
        } else if (this.state.max_date_time.isBefore(newDateTime)) {
            this.setState({
                time_to_error: 'Must be within run time'
            });
        } else if (this.state.time_from.isSameOrAfter(newDateTime) || newDateTime.isAfter(moment())) {
            this.setState({
                time_to_error: 'End time error'
            });
        } else {
            this.setState({
                time_to_error: null
            });
        }
    };

    validateOutputInputs = () => {
        let error_free = true;
        this.setState({
            output_value_error: null
        });

        // if(this.state.output_value <= 0) {
        //     error_free = false;
        //     this.setState({
        //         output_value_error: "Quantity must be positive!"
        //     })
        // }

        if (this.state.time_from_error) {
            error_free = false;
        }

        return error_free;
    };

    validateDowntimeInputs = () => {
        let error_free = true;
        this.setState({
            reason_error: null
        });

        if (this.state.reason === '') {
            error_free = false;
            this.setState({
                reason_error: 'Reason is mandatory'
            });
        }

        if (this.state.time_from_error) {
            error_free = false;
        }

        return error_free;
    };

    validateWasteInputs = () => {
        let error_free = true;
        this.setState({
            waste_value_error: null,
            reason_error: null
        });

        if (this.state.waste_value <= 0) {
            error_free = false;
            this.setState({
                waste_value_error: 'Quantity must be positive!'
            });
        }

        if (this.state.reason === '') {
            error_free = false;
            this.setState({
                reason_error: 'Reason is mandatory'
            });
        }

        if (this.state.time_from_error) {
            error_free = false;
        }

        return error_free;
    };

    validateReworkInputs = () => {
        let error_free = true;
        this.setState({
            rework_value_error: null,
            reason_error: null
        });

        if (this.state.rework_value <= 0) {
            error_free = false;
            this.setState({
                rework_value_error: 'Quantity must be positive!'
            });
        }

        if (this.state.reason === '') {
            error_free = false;
            this.setState({
                reason_error: 'Reason is mandatory'
            });
        }

        if (this.state.time_from_error) {
            error_free = false;
        }

        return error_free;
    };

    validateFinishedGoodsInputs = () => {
        let error_free = true;
        this.setState({
            rework_value_error: null,
            reason_error: null
        });

        if (this.state.rework_value <= 0) {
            error_free = false;
            this.setState({
                rework_value_error: 'Quantity must be positive!'
            });
        }

        if (this.state.time_from_error) {
            error_free = false;
        }

        return error_free;
    };

    /* ######## API CALLS ######## */

    //
    // getReasons = () => {
    //     let params = {
    //         path: "get_line_reasons",
    //         path_variables: {LINE_ID: 1},
    //         success: this.fetchSuccess,
    //         error: this.fetchError
    //     };
    //
    //     API.c(params);
    // }
    //
    // fetchSuccess = (data) => {
    //     this.setState({
    //         reasons: data
    //     })
    // }
    //
    // fetchError = (error) => {
    //     this.setState({
    //         submit_status: error
    //     })
    // }

    submitOutput = () => {
        if (this.validateOutputInputs()) {
            this.setState({
                loading: true
            });

            let { multiplier } = this.props;

            let params = {
                path: 'add_output',
                data: {
                    start_time: this.state.date_from.toISOString(),
                    value: this.state.output_value / multiplier,
                    notes: this.state.notes,
                    run: this.props.run_id
                },
                success: this.submitSuccess,
                error: this.submitError
            };

            API.c(params, 2);
        }
    };

    submitDowntime = () => {
        if (this.validateDowntimeInputs()) {
            this.setState({
                loading: true
            });

            let params = {
                path: 'add_downtime',
                data: {
                    start_time: this.state.date_from.toISOString(),
                    end_time: this.state.date_to.toISOString(),
                    amount: this.state.waste_value,
                    notes: this.state.notes,
                    reason: this.state.reason,
                    run: this.props.run_id
                },
                success: this.submitSuccess,
                error: this.submitError
            };

            API.c(params, 2);
        }
    };

    submitWaste = () => {
        if (this.validateWasteInputs()) {
            let { multiplier, target_weight } = this.props;

            this.setState({
                loading: true
            });

            let params = {
                path: 'add_waste',
                data: {
                    start_time: this.state.date_from.toISOString(),
                    amount: parseFloat(this.state.waste_value) / multiplier / target_weight,
                    notes: this.state.notes,
                    reason: this.state.reason,
                    run: this.props.run_id
                },
                success: this.submitSuccess,
                error: this.submitError
            };

            API.c(params, 2);
        }
    };

    submitRework = () => {
        if (this.validateReworkInputs()) {
            let { multiplier, target_weight } = this.props;

            this.setState({
                loading: true
            });

            let params = {
                path: 'add_rework',
                data: {
                    start_time: this.state.date_from.toISOString(),
                    amount: parseFloat(this.state.rework_value) / multiplier / target_weight,
                    notes: this.state.notes,
                    reason: this.state.reason,
                    run: this.props.run_id
                },
                success: this.submitSuccess,
                error: this.submitError
            };

            API.c(params, 2);
        }
    };

    submitFinishedGoods = () => {
        if (this.validateFinishedGoodsInputs()) {
            let { multiplier, target_weight } = this.props;

            this.setState({
                loading: true
            });

            let params = {
                path: 'add_finished_goods',
                data: {
                    start_time: this.state.date_from.toISOString(),
                    value: parseFloat(this.state.rework_value) / multiplier / target_weight,
                    notes: this.state.notes,
                    run: this.props.run_id
                },
                success: this.submitSuccess,
                error: this.submitError
            };

            API.c(params, 2);
        }
    };

    submitSuccess = data => {
        this.setState({
            loading: false
        });
        this.props.modified();
        this.props.hideModal(this.events[this.state.event]);
    };

    submitError = error => {
        this.setState({
            loading: false,
            submit_status: 'There was an error adding this event'
        });
    };

    /* ######## RENDERS ######## */

    renderStepActions(step) {
        let { stepIndex } = this.state;

        if (this.state.loading) {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'baseline',
                        marginTop: '16px'
                    }}
                >
                    <CircularProgress />
                </div>
            );
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'baseline',
                    marginTop: '16px'
                }}
            >
                {step > 0 && (
                    <Button variant="text" color="primary" disabled={stepIndex === 0} onClick={this.handlePrev}>
                        Back
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    disabled={this.state.event === ''}
                    style={{ marginRight: 12 }}
                >
                    {stepIndex === 1 ? 'Submit' : 'Next'}
                </Button>
            </div>
        );
    }

    renderOptions = () => {
        switch (this.state.event) {
            case 0:
                return this.renderOutputOptions();
            case 1:
                return this.renderDowntimeOptions();
            case 2:
                return this.renderWasteOptions();
            case 3:
                return this.renderReworkOptions();
            case 4:
                return this.renderFinishedGoodsOptions();
        }
    };

    reasons = () => {
        return (
            <div style={{ width: '244px' }}>
                <TextField
                    select
                    className="add-line-info__fields__item"
                    label="Reason"
                    value={this.state.reason}
                    onChange={this.handleSelectReasonChange}
                    fullWidth={true}
                    helperText={this.state.reason_error !== null ? this.state.reason_error : ''}
                    error={this.state.reason_error !== null}
                >
                    {this.iterateReasons()}
                </TextField>
            </div>
        );
    };

    iterateReasons = () => {
        if (this.props.reasons) {
            switch (this.state.event) {
                case 1:
                    let reasons = [...this.props.reasons.downtime_reason_list];
                    reasons.sort(this.compareCategory);
                    return this.props.reasons.downtime_reason_list.map((reason, index) => {
                        return (
                            <MenuItem value={reason.id} key={'reason-' + reason.id}>
                                {this.mapReasonCategory(reason.category) + reason.name}
                            </MenuItem>
                        );
                    });
                case 2:
                    return this.props.reasons.waste_reason_list.map((reason, index) => {
                        return (
                            <MenuItem value={reason.id} key={'reason-' + reason.id}>
                                {reason.name}
                            </MenuItem>
                        );
                    });
                case 3:
                    return this.props.reasons.rework_reason_list.map((reason, index) => {
                        return (
                            <MenuItem value={reason.id} key={'reason-' + reason.id}>
                                {reason.name}
                            </MenuItem>
                        );
                    });
            }
        }
    };

    fromDateTimePickers = () => {
        return (
            <div className={'add-event-picker-wrapper'}>
                <DatePicker
                    label="Start Date"
                    shouldDisableDate={this.disableFromFutureDates.bind(this)}
                    minDate={this.state.min_date_time}
                    value={this.state.date_from}
                    onChange={this.handleDateFromChange}
                    format="yyyy-MMMM-DD"
                    className="add-event-picker"
                />
                <TimePicker
                    id={'start-time-time-picker'}
                    onChange={this.handleTimeFromChange}
                    value={this.state.time_from}
                    label="Start Time"
                    helperText={this.state.time_from_error !== null ? this.state.time_from_error : ''}
                    error={this.state.time_from_error !== null}
                    className="add-event-picker"
                />
            </div>
        );
    };

    toDateTimePickers = () => {
        return (
            <div className={'add-event-picker-wrapper'}>
                <DatePicker
                    label="End Date"
                    minDate={this.state.date_from}
                    shouldDisableDate={disableToFutureDates.bind(this, this.state.max_date_time)}
                    value={this.state.date_to}
                    onChange={this.handleDateToChange}
                    format="yyyy-MMMM-DD"
                    className="add-event-picker"
                />
                <TimePicker
                    id={'end-time-time-picker'}
                    onChange={this.handleTimeToChange}
                    value={this.state.time_to}
                    label="End Time"
                    helperText={this.state.time_to_error !== null ? this.state.time_to_error : ''}
                    error={this.state.time_to_error !== null}
                    className="add-event-picker"
                />
            </div>
        );
    };

    renderOutputOptions = () => {
        return (
            <div>
                {this.fromDateTimePickers()}
                <TextField
                    id="output_value"
                    value={this.state.output_value}
                    onChange={this.handleChange.bind(this)}
                    type="number"
                    error={this.state.output_value_error !== null}
                    helperText={this.state.output_value_error !== null ? this.state.output_value_error : ''}
                    label={'Quantity (' + this.getInputUnit('output') + ')'}
                />
                <TextField
                    id="notes"
                    fullWidth
                    value={this.state.notes}
                    onChange={this.handleChange.bind(this)}
                    label="Notes"
                />
            </div>
        );
    };

    renderDowntimeOptions = () => {
        return (
            <div>
                {this.fromDateTimePickers()}
                {this.toDateTimePickers()}
                {this.reasons()}
                <TextField
                    id="notes"
                    fullWidth
                    value={this.state.notes}
                    onChange={this.handleChange.bind(this)}
                    label="Notes"
                />
            </div>
        );
    };

    renderWasteOptions = () => {
        return (
            <div>
                {this.fromDateTimePickers()}
                <TextField
                    id="waste_value"
                    value={this.state.waste_value}
                    onChange={this.handleChange.bind(this)}
                    type="number"
                    error={this.state.waste_value_error !== null}
                    helperText={this.state.waste_value_error !== null ? this.state.waste_value_error : ''}
                    label={'Quantity (' + this.getInputUnit('waste') + ')'}
                />
                {this.reasons()}
                <TextField
                    id="notes"
                    fullWidth
                    value={this.state.notes}
                    onChange={this.handleChange.bind(this)}
                    label="Notes"
                />
            </div>
        );
    };

    renderReworkOptions = () => {
        return (
            <div>
                {this.fromDateTimePickers()}
                <TextField
                    id="rework_value"
                    value={this.state.rework_value}
                    onChange={this.handleChange.bind(this)}
                    type="number"
                    error={this.state.rework_value_error !== null}
                    helperText={this.state.rework_value_error !== null ? this.state.rework_value_error : ''}
                    label={'Quantity (' + this.getInputUnit('rework') + ')'}
                />
                {this.reasons()}
                <TextField
                    id="notes"
                    fullWidth
                    value={this.state.notes}
                    onChange={this.handleChange.bind(this)}
                    label="Notes"
                />
            </div>
        );
    };

    renderFinishedGoodsOptions = () => {
        return (
            <div>
                {this.fromDateTimePickers()}
                <TextField
                    id="rework_value"
                    value={this.state.rework_value}
                    onChange={this.handleChange.bind(this)}
                    type="number"
                    error={this.state.rework_value_error !== null}
                    helperText={this.state.rework_value_error !== null ? this.state.rework_value_error : ''}
                    label={'Quantity (' + this.getInputUnit('finished_goods') + ')'}
                />
                <TextField
                    id="notes"
                    fullWidth
                    value={this.state.notes}
                    onChange={this.handleChange.bind(this)}
                    label="Notes"
                />
            </div>
        );
    };

    render() {
        let { stepIndex } = this.state;
        let event = '';
        if (this.state.event >= 0) {
            event = this.events[this.state.event];
        }
        return (
            <div style={{ maxWidth: 560, margin: 'auto' }}>
                <Stepper activeStep={stepIndex} orientation="vertical">
                    <Step>
                        <StepLabel>
                            {stepIndex === 0 ? 'Choose Event Type' : event === 'Output' ? 'Sensor Count' : event === "finished_goods" ? 'Finished Goods' : event}
                        </StepLabel>
                        <StepContent>
                            <div style={{ width: '244px' }}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Event Type"
                                    value={this.state.event}
                                    onChange={this.handleEventSelectChange}
                                >
                                    <MenuItem value={0}>Sensor Count</MenuItem>
                                    <MenuItem value={1}>Downtime</MenuItem>
                                    <MenuItem value={2}>Waste</MenuItem>
                                    <MenuItem value={3}>Rework</MenuItem>
                                    <MenuItem value={4}>Finished Goods</MenuItem>
                                </TextField>
                            </div>
                            {this.renderStepActions(0)}
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Add {event === 'Output' ? 'Sensor Count' : event === "finished_goods" ? "Finished Goods" : event} Event Details</StepLabel>
                        <StepContent>
                            {this.renderOptions()}
                            {this.renderStepActions(1)}
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        );
    }
}

export default AddEvent;
