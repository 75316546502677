import React, { Component } from 'react';
import { MenuItem, TextField } from '@material-ui/core';

class RunDataColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            original_product: 0,
            selected_product: props.run.product_id,
            finished_goods: props.disabled ? 0 : this.getOriginalFinishedGoods(),
            finished_goods_error: null
        };
    }

    /* -------- LIFECYCLE METHODS -------- */
    componentDidMount() {}

    /* -------- FUNCTIONS -------- */
    getOriginalFinishedGoods = () => {
        let prod = this.props.products.find(product => product.product_id === this.props.run.product_id);

        return (
            this.props.run.finished_goods *
            (prod ? prod.product_multiplier : 1) *
            (prod ? prod.product_target_weight : 1)
        );
    };

    handleFinishedGoodsChange(event) {
        let error = null;

        if (event.target.value < 0) {
            error = "Can't be negative";
        } else if (event.target.value > this.props.run.run_finished_goods_cases) {
            error = "Can't be more than " + this.props.run.run_finished_goods_cases;
        }

        this.setState({
            finished_goods: event.target.value,
            finished_goods_error: error
        });

        this.props.onFinishedGoodsChange(event.target.value, error);
    }

    handleSelectProductChange = event => {
        this.setState({
            selected_product: event.target.value
        });
        this.props.onProductChange(event.target.value);
    };

    reset = () => {
        this.setState({
            selected_product: this.props.run.product_id,
            finished_goods: this.props.run.finished_goods,
            finished_goods_error: null
        });
    };

    /* -------- API CALLS -------- */

    /* -------- RENDERS -------- */
    showProducts = () => {
        if (this.props.products == null) {
            return <div>Loading...</div>;
        }

        return (
            <div>
                <TextField
                    select
                    fullWidth={true}
                    label="Run Product"
                    value={this.state.selected_product}
                    onChange={this.handleSelectProductChange}
                    disabled={this.props.disabled}
                    style={{ width: '250px', marginTop: '8px', marginBottom: '8px' }}
                >
                    {this.iterateProducts()}
                </TextField>
            </div>
        );
    };

    iterateProducts = () => {
        if (this.props.products == null || this.props.products.constructor !== Array) {
            return;
        }

        return this.props.products.map(item => (
            <MenuItem value={item.product_id} key={'product-' + item.product_id}>
                {item.product_name + ' - ' + item.product_desc}
            </MenuItem>
        ));
    };

    showFinishedGoods = () => {
        if (this.props.isSplit) {
            return (
                <div className="flex-box__row align-end margin-bottom-xl">
                    <TextField
                        id="finished_goods"
                        label={'Finished Goods'}
                        value={this.state.finished_goods}
                        onChange={this.handleFinishedGoodsChange.bind(this)}
                        disabled={this.props.disabled}
                        error={this.state.finished_goods_error !== null}
                        helperText={this.state.finished_goods_error !== null ? this.state.finished_goods_error : ''}
                        style={{ width: '250px', marginTop: '8px' }}
                        type="number"
                    />
                </div>
            );
        } else {
            return <div />;
        }
    };

    render() {
        let { run } = this.props;

        if (!run.product_id) {
            return (
                <div
                    className="flex-box__col justify-center"
                    style={{ width: '320px', height: '193px', opacity: '0.3' }}
                >
                    No Run
                </div>
            );
        }

        let disabled = this.props.disabled ? ' disabled-text' : '';

        return (
            <div className="flex-box__col align-start" style={{ width: '320px' }}>
                {this.showProducts()}
                <div className={'margin-bottom-bg' + disabled}>
                    {'Start time: ' + Util.formatShortDateTime(run.start_time)}
                </div>
                <div className={'margin-bottom-sm' + disabled}>
                    {'End time: ' + Util.formatShortDateTime(run.end_time)}
                </div>
                {this.showFinishedGoods()}
            </div>
        );
    }
}

export default RunDataColumn;
