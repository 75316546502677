import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, FormControlLabel, Popover, Typography } from '@material-ui/core';
import clsx from 'clsx';

export default function GenericSelectModal(props) {
    const classes = useStyles();
    const [selected, setSelected] = useState(props.selected)

    const handleSelectNoneClick = () => {
        setSelected([])
    }

    const handleFilterClick = () => {
        props.onFilterClick(props.title, selected)
        props.hideModal()
    }

    const onOptionCheck = (index, event, isInputChecked) => {
        const updatedSelected = [...selected];
        if(isInputChecked) {
            updatedSelected.push(props.selectOptions[index])
        } else {
            const unselectIndex = updatedSelected.findIndex(v => v.id === props.selectOptions[index].id)
            if (unselectIndex !== -1) updatedSelected.splice(unselectIndex, 1)
        }
        setSelected(updatedSelected)
    }

    const onAllOptionsCheck = (event, isInputChecked) => {
        isInputChecked ? setSelected(props.selectOptions) : setSelected([])
    }

    return <Popover open
                    anchorEl={props.anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    className={classes.popover}
                    onClose={props.hideModal}>
        <div className={classes.container}>
            <div className={classes.header} style={{ overflowY: "hidden" }}>
                <div className={classes.headerInner}>
                    <Typography variant={'subtitle1'}>{props.title}</Typography>
                </div>
                <Button variant="text"
                        color="primary"
                        className={classes.headerButton}
                        onClick={handleSelectNoneClick}>Select None</Button>
                <Button variant="contained"
                        color="primary"
                        className={classes.headerButton}
                        disabled={selected.length === 0}
                        onClick={handleFilterClick}>Filter</Button>
            </div>
            <div className={classes.body}>
                <FormControlLabel className={clsx([classes.item, classes.selectAllItem, selected.length === props.selectOptions.length && classes.checked])}
                                  control={<Checkbox checked={selected.length === props.selectOptions.length}
                                                     color={"primary"}
                                                     onChange={onAllOptionsCheck}
                                  />}
                                  label={props.selectAllLabel} />
                {
                    props.selectOptions.map((option, index) => {
                        const checked = selected.findIndex(v => v.id === option.id) > -1
                        return <FormControlLabel className={clsx([classes.item, checked && classes.checked])}
                                                 control={<Checkbox checked={checked}
                                                                    color={"primary"}
                                                                    onChange={onOptionCheck.bind(this, index)}
                                                 />}
                                                 label={option.name}
                                                 key={"option-"+option.id} />
                    })
                }
            </div>
        </div>
    </Popover>
}

//######## STYLE ########
const useStyles = makeStyles(theme => ({
    popover:{
        marginTop: 32
    },
    container: {
        minWidth: 412,
        maxHeight: 300,
        background: '#fff',
        borderRadius: 8,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 36,
        width: '100%',
        paddingTop: 24,
        paddingBottom: 8
    },
    headerInner: {
        flex: 1,
        marginLeft: 24
    },
    headerButton: {
        marginRight: '24px !important'
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 202,
        width: '100%',
        overflowY: 'scroll',
        marginBottom: 30
    },
    item: {
        width: 'calc(100% - 24px) !important',
        height: 24,
        marginRight: '12px !important',
        marginLeft: '12px !important',
        padding: '5px 0',
        color: '#666',
        '&:hover': {
            backgroundColor: '#e8e8e8'
        }
    },
    selectAllItem: {
        marginTop: 30,
        marginBottom: 25
    },
    checked: {
        color: '#000'
    }
}));