import React, { useState, useEffect } from 'react';
import { Fab, Typography, Button, Divider, CircularProgress, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, AddOutlined } from '@material-ui/icons';
import NoResultOutlinedIcon from '../../icons/NoResultOutlinedIcon';
import NotificationsResultsSection from './NotificationsResultsSection';
import GenericSelectModal from '../../../modals/GenericSelectModal';
import SelectNotificationTypeModal from '../../../modals/SelectNotificationTypeModal';
import SelectThresholdDurationModal from '../../../modals/SelectThresholdDurationModal';
import AddNewNotificationModal from '../../../modals/AddNewNotificationModal';
import NotificationDetail from './NotificationDetail';
import {auth0UserManagementAPIInstance} from '../../Auth0API';
import CloseIcon from '@material-ui/icons/Close';
import {Tooltip as TippyTooltip } from 'react-tippy'

const TYPES = [
    'All Types',
    'Downtime',
    'Run Start',
    'Run End'
]

export default function NotificationsSettings(props) {
    const classes = useStyles();

    //######## STATE AND EFFECT ########
    const [inDetails, setInDetails] = useState(false);
    const [notificationToEdit, setNotificationToEdit] = useState({ notification: null, type: '' });
    const [openTypeSelector, setOpenTypeSelector] = useState(false)
    const [type, setType] = useState(0);
    const [anchor, setAnchor] = useState(null)
    const [openLinesSelector, setOpenLinesSelector] = useState(false);
    const [openSubscribersSelector, setOpenSubscribersSelector] = useState(false);
    const [openProductsSelector, setOpenProductsSelector] = useState(false);
    const [openDowntimeReasonsSelector, setOpenDowntimeReasonsSelector] = useState(false);
    const [lines, setLines] = useState([]);
    const [linesError, setLinesError] = useState(false);
    const [linesLoading, setLinesLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [productsError, setProductsError] = useState(false);
    const [productsLoading, setProductsLoading] = useState(false);
    const [downtimeReasons, setDowntimeReasons] = useState([]);
    const [downtimeReasonsLoading, setDowntimeReasonsLoading] = useState(false);
    const [downtimeReasonsError, setDowntimeReasonsError] = useState(false);
    const [orgUsers, setOrgUsers] = useState([]);
    const [orgUsersLoading, setOrgUsersLoading] = useState(false);
    const [orgUsersError, setOrgUsersError] = useState(false);
    const [selectedLines, setSelectedLines] = useState([]);
    const [selectedSubscribers, setSelectedSubscribers] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedDowntimeReasons, setSelectedDowntimeReasons] = useState([]);
    const [openDowntimeDurationSelector, setOpenDowntimeDurationSelector] = useState(false);
    const [thresholdDuration, setThresholdDuration] = useState('');
    const [thresholdDurationType, setThresholdDurationType] = useState(-1);
    const [showAddNewNotification, setShowAddNewNotification] = useState(false);
    const [downtimeNotifications, setDowntimeNotifications] = useState([]);
    const [runStartNotifications, setRunStartNotifications] = useState([]);
    const [runEndNotifications, setRunEndNotifications] = useState([]);
    const [downtimeNotificationsLoading, setDowntimeNotificationsLoading] = useState(false);
    const [runStartNotificationsLoading, setRunStartNotificationsLoading] = useState(false);
    const [runEndNotificationsLoading, setRunEndNotificationsLoading] = useState(false);
    const [downtimeNotificationsError, setDowntimeNotificationsError] = useState(false);
    const [runStartNotificationsError, setRunStartNotificationsError] = useState(false);
    const [runEndNotificationsError, setRunEndNotificationsError] = useState(false);
    const [createNotification, setCreateNotification] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        let idToken = Auth0.getIdTokenPayload()
        if (idToken) {
            setUser(idToken)
        }
        getLines();
        getProducts();
        getDowntimeReasons();
        getOrgUsers();
        getDowntimeNotifications();
        getRunStartNotifications();
        getRunEndNotifications();
    }, [props.factory]);


    //######## FUNCTIONS ########
    const getLines = () => {

        let params = {
            path: 'get_lines',
            success: fetchLinesSuccess,
            error: fetchLinesError
        };

        setLinesLoading(true);
        setLinesError(false);

        API.c(params, 2);
    }
    const getProducts = () => {

        let params = {
            path: 'products',
            success: fetchProductsSuccess,
            error: fetchProductsError
        };

        setProductsLoading(true);
        setProductsError(false);

        API.c(params, 2);
    }

    const getDowntimeReasons = () => {
        let params = {
            path: 'get_reasons',
            success: fetchDowntimeReasonsSuccess,
            error: fetchDowntimeReasonsError
        };

        setDowntimeReasonsLoading(true);
        setDowntimeReasonsError(false);

        API.c(params, 2);
    }

    const defaultFactoryId = () => {
        let defaultFactory = JSON.parse(window.localStorage.getItem("factory"))

        return defaultFactory ? defaultFactory.id : null
    }

    const getOrgUsers =  () => {

        const organization = localStorage.getItem("organization");
        if (organization) {
            setOrgUsersLoading(true);
            setOrgUsersError(false);
            auth0UserManagementAPIInstance
                .get(`auth0/user/search?organization_id=${JSON.parse(organization).id}&factory_id=${defaultFactoryId()}`)
                .then(res => {
                    setOrgUsers(res.data);
                    setSelectedSubscribers(getSubscribersSelectOptions(res.data))
                    setOrgUsersLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setOrgUsersError(true);
                    setOrgUsersLoading(false);
                })
        } else {
            setOrgUsersError(true);
        }

    }

    const getDowntimeNotifications = (showLoading = true) => {
        let params = {
            path: 'get_downtime_notifications',
            success: fetchDowntimeNotificationsSuccess,
            error: fetchDowntimeNotificationsError
        };

        setDowntimeNotificationsLoading(showLoading);
        setDowntimeNotificationsError(false);

        API.c(params, 2);
    }

    const getRunStartNotifications = (showLoading = true) => {
        let params = {
            path: 'get_start_run_notifications',
            success: fetchRunStartNotificationsSuccess,
            error: fetchRunStartNotificationsError
        };

        setRunStartNotificationsLoading(showLoading);
        setRunStartNotificationsError(false);

        API.c(params, 2);
    }

    const getRunEndNotifications = (showLoading = true) => {
        let params = {
            path: 'get_end_run_notifications',
            success: fetchRunEndNotificationsSuccess,
            error: fetchRunEndNotificationsError
        };

        setRunEndNotificationsLoading(showLoading);
        setRunEndNotificationsError(false);

        API.c(params, 2);
    }

    const handleTypeChange = type => {
        switch (type) {
            case 0:
                setSelectedProducts(products);
                setSelectedDowntimeReasons(getDowntimeReasonsSelectOptions(downtimeReasons));
                setThresholdDurationType(-1);
                setThresholdDuration('');
                break;
            case 1:
                setSelectedProducts(products);
                break;
            case 2:
            case 3:
                setSelectedDowntimeReasons(getDowntimeReasonsSelectOptions(downtimeReasons));
                setThresholdDurationType(-1);
                setThresholdDuration('');
                break;
        }

        setType(type);
        setOpenTypeSelector(false);
        setAnchor(null);
    };

    const fetchLinesSuccess = data => {
        setLines(data);
        setSelectedLines(data)
        setLinesLoading(false);
    };

    const fetchLinesError = error => {
        setLinesError(true);
        setLinesLoading(false);
    };

    const fetchProductsSuccess = data => {
        setProducts(data);

        setSelectedProducts(getProductsSelectOptions(data))
        setProductsLoading(false);
    };

    const fetchProductsError = error => {
        setProductsError(true);
        setProductsLoading(false);
    };

    const fetchDowntimeReasonsSuccess = data => {
        setDowntimeReasons(data.active_downtime_reason_list);

        setSelectedDowntimeReasons(getDowntimeReasonsSelectOptions(data.active_downtime_reason_list))
        setDowntimeReasonsLoading(false);
    };

    const fetchDowntimeReasonsError = error => {
        setDowntimeReasonsError(true);
        setDowntimeReasonsLoading(false);
    };

    const fetchDowntimeNotificationsSuccess = data => {
        setDowntimeNotifications(data);
        setDowntimeNotificationsLoading(false);
    };

    const fetchDowntimeNotificationsError = error => {
        setDowntimeNotificationsError(true);
        setDowntimeNotificationsLoading(false);
    };

    const fetchRunStartNotificationsSuccess = data => {
        setRunStartNotifications(data);
        setRunStartNotificationsLoading(false);
    };

    const fetchRunStartNotificationsError = error => {
        setRunStartNotificationsError(true);
        setRunStartNotificationsLoading(false);
    };

    const fetchRunEndNotificationsSuccess = data => {
        setRunEndNotifications(data);
        setRunEndNotificationsLoading(false);
    };

    const fetchRunEndNotificationsError = error => {
        setRunEndNotificationsError(true);
        setRunEndNotificationsLoading(false);
    };

    const openSelectType = (event) => {
        setAnchor(event.currentTarget)
        setOpenTypeSelector(true)
    }

    const openSelectLines = (event) => {
        setAnchor(event.currentTarget)
        setOpenLinesSelector(true)
    }

    const openSelectSubscribers = (event) => {
        setAnchor(event.currentTarget)
        setOpenSubscribersSelector(true)
    }

    const openSelectProducts = (event) => {
        setAnchor(event.currentTarget)
        setOpenProductsSelector(true)
    }

    const openSelectDowntimeReasons = (event) => {
        setAnchor(event.currentTarget)
        setOpenDowntimeReasonsSelector(true)
    }

    const openSelectDowntimeDuration = (event) => {
        setAnchor(event.currentTarget)
        setOpenDowntimeDurationSelector(true)
    }

    const onFilterClick = (type, selected) => {
        switch (type) {
            case 'Line':
                setSelectedLines(selected);
                setOpenLinesSelector(false);
                break;
            case 'Subscriber':
                setSelectedSubscribers(selected);
                setOpenSubscribersSelector(false);
                break;
            case 'Product':
                setSelectedProducts(selected);
                setOpenProductsSelector(false);
                break;
            case 'Threshold Reason':
                setSelectedDowntimeReasons(selected);
                setOpenDowntimeReasonsSelector(false);
                break;
            default:
                return;
        }
        setAnchor(null);
    }

    const onThresholdDurationFilterClick = (duration, type) => {
        if (duration >= 0) {
            setThresholdDuration(duration)
            setThresholdDurationType(type)
        } else {
            setThresholdDuration('')
            setThresholdDurationType(-1)
        }

        setOpenDowntimeDurationSelector(false);
        setAnchor(null);
    }

    const onAddNewNotificationClick = (title, type) => {
        const notification = {
            name: title,
            downtime_reason: null,
            downtime_duration_secs: 0,
            lines: [],
            products: [],
            users: []
        }

        setNotificationToEdit({ notification, type })
        setCreateNotification(true);
        setShowAddNewNotification(false);
        setInDetails(true);
    }

    const onEditClick = (notification, type) => {
        setNotificationToEdit({ notification, type })
        setCreateNotification(false);
        setInDetails(true);
    }

    const onSubscribe = (notificationId, type, isSubscribed, onResponse) => {
        let params = {
            path_variables: {ID: notificationId},
            data: {
                user_auth0_id: user.sub
            },
            success: () => onSubscribeSuccess(type, onResponse),
            error: () => onSubscribeError(onResponse)
        }
        switch (type) {
            case 'downtime':
                if (isSubscribed) {
                    params['path'] = 'unsubscribe_downtime_notification'
                } else {
                    params['path'] = 'subscribe_downtime_notification'
                }
                break;
            case 'run start':
                if (isSubscribed) {
                    params['path'] = 'unsubscribe_start_run_notification'
                } else {
                    params['path'] = 'subscribe_start_run_notification'
                }
                break;
            case 'run end':
                if (isSubscribed) {
                    params['path'] = 'unsubscribe_end_run_notification'
                } else {
                    params['path'] = 'subscribe_end_run_notification'
                }
                break;
        }

        API.c(params, 2)
    }

    const onSubscribeSuccess = (type, onResponse) => {
        switch (type) {
            case 'downtime':
                getDowntimeNotifications(false)
                onResponse()
                break;
            case 'run start':
                getRunStartNotifications(false)
                onResponse()
                break;
            case 'run end':
                getRunEndNotifications(false)
                onResponse()
                break;
        }
    }

    const onSubscribeError = (onResponse) => {
        onResponse(true)
    }

    const handleInDetailsClose = () => {
        setNotificationToEdit({notification: null, type: ''})
        setCreateNotification(false);
        setInDetails(false);
    }

    const handleOnSave = (type) => {
        if (type === 'downtime') {
            getDowntimeNotifications();
        } else if (type === 'run start') {
            getRunStartNotifications();
        } else {
            getRunEndNotifications();
        }

        handleInDetailsClose();
    }

    const retryLoadingResources = () => {
        getLines();
        getProducts();
        getDowntimeReasons();
        getOrgUsers();
    }

    const clearFilters = () => {
        setSelectedLines(lines);
        setSelectedProducts(getProductsSelectOptions(products));
        setSelectedDowntimeReasons(getDowntimeReasonsSelectOptions(downtimeReasons));
        setSelectedSubscribers(getSubscribersSelectOptions(orgUsers));
        setThresholdDuration('');
        setThresholdDurationType(-1)
    }

    const getProductsSelectOptions = (products) => {
        return products.reduce((acc, product) => {
            acc.push({
                id: product.id,
                name: product.name + ' - ' + product.desc,
                product: product
            })

            return acc;
        }, [])
    }

    const getDowntimeReasonsSelectOptions = (downtimeReasons) => {
        return downtimeReasons.reduce((acc, reason) => {
            const category = reason.category ? reason.category + ' - ' : ''
            acc.push({
                id: reason.id,
                name: category + reason.name,
                reason: reason
            })

            return acc;
        }, [])
    }

    const getSubscribersSelectOptions = (orgUsers) => {
        return orgUsers.reduce((acc, user) => {
            acc.push({
                id: user.user_id,
                name: user.user_metadata.first_name + ' ' + user.user_metadata.last_name + ' (' + user.email + ')',
                user_metadata: user.user_metadata
            })

            return acc;
        }, [])
    }

    const handleRemoveFilter = (tag) => {
        switch (tag.type) {
            case 'line':
                const newSelectedLines = [...selectedLines]
                const removeLineIndex = newSelectedLines.findIndex(l => l.name === tag.name)
                if (removeLineIndex !== -1) {
                    newSelectedLines.splice(removeLineIndex, 1)
                }
                setSelectedLines(newSelectedLines.length === 0 ? lines : newSelectedLines)
                break;
            case 'product':
                const newSelectedProducts = [...selectedProducts]
                const removeProductIndex = newSelectedProducts.findIndex(p => p.name === tag.name)
                if (removeProductIndex !== -1) {
                    newSelectedProducts.splice(removeProductIndex, 1)
                }
                setSelectedProducts(newSelectedProducts.length === 0 ? getProductsSelectOptions(products) : newSelectedProducts)

                break;
            case 'downtimeReason':
                const newSelectedDowntimeReasons = [...selectedDowntimeReasons]
                const removeReasonIndex = newSelectedDowntimeReasons.findIndex(r => r.name === tag.name)
                if (removeReasonIndex !== -1) {
                    newSelectedDowntimeReasons.splice(removeReasonIndex, 1)
                }
                setSelectedDowntimeReasons(newSelectedDowntimeReasons.length === 0 ? getDowntimeReasonsSelectOptions(downtimeReasons) : newSelectedDowntimeReasons)
                break;
            case 'subscriber':
                const newSelectedSubscribers = [...selectedSubscribers]
                const removeSubscriberIndex = newSelectedSubscribers.findIndex(s => s.name === tag.name)
                if (removeSubscriberIndex !== -1) {
                    newSelectedSubscribers.splice(removeSubscriberIndex, 1)
                }
                setSelectedSubscribers(newSelectedSubscribers.length === 0 ? getSubscribersSelectOptions(orgUsers) : newSelectedSubscribers)
                break;
            case 'duration':
                setThresholdDurationType(-1)
                setThresholdDuration('')
                break;
        }
    }

    //######## RENDER ########
    const renderAddNewNotificationModal = () => {
        return showAddNewNotification &&
            <AddNewNotificationModal hideModal={() => setShowAddNewNotification(false)}
                                     onAddNewNotificationClick={onAddNewNotificationClick}/>
    }

    const renderTypeSelector = () => {
        return (
            <div className={classes.type_div} style={{ marginRight: "0px" }}>
                <Button
                    className={classes.option_item_dropdown}
                    variant="text"
                    fullWidth
                    onClick={openSelectType}
                    endIcon={<ArrowDropDown />}
                >
                    {TYPES[type]}
                </Button>
            </div>
        );
    }

    const renderLinesSelector = () => {
        let count = selectedLines.length === lines.length ? 'All' : selectedLines.length;

        return (
            <div className={classes.lines_products_div}  style={{ overflowY: "hidden" }}>
                <Button
                    className={classes.option_item_dropdown}
                    variant="text"
                    fullWidth
                    disabled={lines[0] === null}
                    onClick={openSelectLines}
                    endIcon={<ArrowDropDown />}
                >
                    {'Line' + ' (' + count + ')'}
                </Button>
            </div>
        );
    };

    const renderSubscribersSelector = () => {
        let count = selectedSubscribers.length === orgUsers.length ? 'All' : selectedSubscribers.length;

        return (
            <div className={classes.lines_products_div}  style={{ overflowY: "hidden" }}>
                <Button
                    className={classes.option_item_dropdown}
                    variant="text"
                    fullWidth
                    disabled={orgUsers[0] === null}
                    onClick={openSelectSubscribers}
                    endIcon={<ArrowDropDown />}
                >
                    {'Subscriber' + ' (' + count + ')'}
                </Button>
            </div>
        );
    };

    const renderProductSelector = () => {
        let count = selectedProducts.length === products.length ? 'All' : selectedProducts.length;

        return (
            <div className={classes.lines_products_div}  style={{ overflowY: "hidden" }}>
                <Button
                    className={classes.option_item_dropdown}
                    variant="text"
                    fullWidth
                    onClick={openSelectProducts}
                    endIcon={<ArrowDropDown />}
                >
                    {'Product' + ' (' + count + ')'}
                </Button>
            </div>
        );
    };

    const renderDowntimeReasonsSelector = () => {
        let count = selectedDowntimeReasons.length === downtimeReasons.length ? 'All' : selectedDowntimeReasons.length;

        return (
            <div className={classes.lines_products_div}  style={{ overflowY: "hidden" }}>
                <Button
                    className={classes.option_item_dropdown_downtime}
                    variant="text"
                    fullWidth
                    onClick={openSelectDowntimeReasons}
                    endIcon={<ArrowDropDown />}
                >
                    {'Downtime Reason' + ' (' + count + ')'}
                </Button>
            </div>
        );
    };

    const renderDowntimeDurationSelector = () => {
        return (
            <div className={classes.lines_products_div}  style={{ overflowY: "hidden" }}>
                <Button
                    className={classes.option_item_dropdown_downtime}
                    variant="text"
                    fullWidth
                    onClick={openSelectDowntimeDuration}
                    endIcon={<ArrowDropDown />}
                >
                    {'Threshold Duration' + (parseFloat(thresholdDuration) > 0 ? ' (1)' : '')}
                </Button>
            </div>
        );
    };

    const renderOptionsByType = () => {
        switch (type) {
            case 0:
                return (
                    <div className={classes.options_selectors} style={{ overflowY: "hidden" }}>
                        {renderLinesSelector()}
                        {renderSubscribersSelector()}
                    </div>
                );
            case 1:
                return (
                    <div className={classes.options_selectors} style={{ overflowY: "hidden" }}>
                        {renderLinesSelector()}
                        {renderSubscribersSelector()}
                        {renderDowntimeReasonsSelector()}
                        {renderDowntimeDurationSelector()}
                    </div>
                );
            case 2:
            case 3:
                return (
                    <div className={classes.options_selectors} style={{ overflowY: "hidden" }}>
                        {renderLinesSelector()}
                        {renderSubscribersSelector()}
                        {renderProductSelector()}
                    </div>
                );
        }
    };

    const renderDowntimeNotificationsSection = () => {
        return <NotificationsResultsSection type={'downtime'}
                                            notifications={downtimeNotifications}
                                            loading={downtimeNotificationsLoading}
                                            error={downtimeNotificationsError}
                                            products={products}
                                            lines={lines}
                                            downtimeReasons={downtimeReasons}
                                            subscribers={orgUsers}
                                            selectedLines={selectedLines}
                                            selectedProducts={selectedProducts}
                                            selectedDowntimeReasons={selectedDowntimeReasons}
                                            selectedSubscribers={selectedSubscribers}
                                            downtimeDuration={thresholdDuration}
                                            downtimeDurationType={thresholdDurationType}
                                            user={user}
                                            onSubscribe={onSubscribe}
                                            onEditClick={onEditClick}/>
    }

    const renderRunStartNotificationSection = () => {
        return <NotificationsResultsSection type={'run start'}
                                            notifications={runStartNotifications}
                                            loading={runStartNotificationsLoading}
                                            error={runStartNotificationsError}
                                            products={products}
                                            lines={lines}
                                            downtimeReasons={downtimeReasons}
                                            subscribers={orgUsers}
                                            selectedLines={selectedLines}
                                            selectedProducts={selectedProducts}
                                            selectedDowntimeReasons={selectedDowntimeReasons}
                                            selectedSubscribers={selectedSubscribers}
                                            downtimeDuration={thresholdDuration}
                                            downtimeDurationType={thresholdDurationType}
                                            user={user}
                                            onSubscribe={onSubscribe}
                                            onEditClick={onEditClick}/>
    }

    const renderRunEndNotificationsSection = () => {
        return <NotificationsResultsSection type={'run end'}
                                            notifications={runEndNotifications}
                                            loading={runEndNotificationsLoading}
                                            error={runEndNotificationsError}
                                            products={products}
                                            lines={lines}
                                            downtimeReasons={downtimeReasons}
                                            subscribers={orgUsers}
                                            selectedLines={selectedLines}
                                            selectedProducts={selectedProducts}
                                            selectedDowntimeReasons={selectedDowntimeReasons}
                                            selectedSubscribers={selectedSubscribers}
                                            downtimeDuration={thresholdDuration}
                                            downtimeDurationType={thresholdDurationType}
                                            user={user}
                                            onSubscribe={onSubscribe}
                                            onEditClick={onEditClick}/>
    }

    const renderNotificationsResults = () => {
        switch (type) {
            case 0:
                return (
                    <div key={0} className={classes.results_sections_wrapper}>
                        {renderDowntimeNotificationsSection()}
                        {renderRunStartNotificationSection()}
                        {renderRunEndNotificationsSection()}
                    </div>
                );
            case 1:
                return (
                    <div key={1} className={classes.results_sections_wrapper}>
                        {renderDowntimeNotificationsSection()}
                    </div>
                );
            case 2:
                return (
                    <div key={2} className={classes.results_sections_wrapper}>
                        {renderRunStartNotificationSection()}
                    </div>
                );
            case 3:
                return (
                    <div key={3} className={classes.results_sections_wrapper}>
                        {renderRunEndNotificationsSection()}
                    </div>
                );
            default:
                return (
                    <div className={classes.no_result_found_wrapper}>
                        <NoResultOutlinedIcon />
                        <Typography variant="subtitle1" className={classes.no_result_text}>
                            No results found
                        </Typography>
                    </div>
                );
        }
    };

    const showTypeSelectorModal = () => {
        return (
            openTypeSelector &&
            <SelectNotificationTypeModal
                handleTypeChange={handleTypeChange}
                anchor={anchor}
                hideModal={() => setOpenTypeSelector(false)}
                selected={type}/>
        )
    }

    const showLinesModal = () => {
        return (
            openLinesSelector && (
                <GenericSelectModal
                    title={'Line'}
                    selectOptions={lines}
                    hideModal={() => setOpenLinesSelector(false)}
                    selected={selectedLines}
                    anchor={anchor}
                    selectAllLabel={'All Lines'}
                    onFilterClick={onFilterClick}
                />
            )
        );
    };

    const showSubscribersModal = () => {
        if (openSubscribersSelector) {

            return <GenericSelectModal
                title={'Subscriber'}
                selectOptions={getSubscribersSelectOptions(orgUsers)}
                hideModal={() => setOpenSubscribersSelector(false)}
                selected={selectedSubscribers}
                anchor={anchor}
                selectAllLabel={'All Subscribers'}
                onFilterClick={onFilterClick}
            />
        }

        return null
    };

    const showProductModal = () => {
        if (openProductsSelector) {

            return <GenericSelectModal
                title={'Product'}
                selectOptions={getProductsSelectOptions(products)}
                hideModal={() => setOpenProductsSelector(false)}
                selected={selectedProducts}
                anchor={anchor}
                selectAllLabel={'All Products'}
                onFilterClick={onFilterClick}
            />
        }

        return null
    }

    const showDowntimeReasonModal = () => {
        if (openDowntimeReasonsSelector) {

            return <GenericSelectModal
                title={'Threshold Reason'}
                selectOptions={getDowntimeReasonsSelectOptions(downtimeReasons)}
                hideModal={() => setOpenDowntimeReasonsSelector(false)}
                selected={selectedDowntimeReasons}
                anchor={anchor}
                selectAllLabel={'All Reasons'}
                onFilterClick={onFilterClick}
            />
        }

        return null
    }

    const showThresholdDurationModal = () => {
        return (
            openDowntimeDurationSelector && (
                <SelectThresholdDurationModal
                    hideModal={() => setOpenDowntimeDurationSelector(false)}
                    anchor={anchor}
                    type={thresholdDurationType}
                    duration={thresholdDuration}
                    onFilterClick={onThresholdDurationFilterClick}
                />
            )
        )
    }

    const renderDetailsModal = () => {
        return <NotificationDetail open={inDetails}
                                   create={createNotification}
                                   notification={notificationToEdit.notification}
                                   lines={lines}
                                   products={products}
                                   reasons={downtimeReasons}
                                   orgUsers={orgUsers}
                                   type={notificationToEdit.type}
                                   onSave={handleOnSave}
                                   onSubscribe={onSubscribe}
                                   handleClose={handleInDetailsClose}/>;
    }

    const renderFilterTags = () => {
        const tags = [];

        if (selectedLines.length > 0) {
            if (selectedLines.length === lines.length) {
                tags.push({ name: 'All Lines', type: 'line' })
            } else {
                selectedLines.forEach(l => tags.push({ name: l.name, type: 'line' }))
            }
        }

        if ((type === 2 || type === 3) && selectedProducts.length > 0) {
            if (selectedProducts.length === products.length) {
                tags.push({ name: 'All Product SKUs', type: 'product' })
            } else {
                selectedProducts.forEach(p => tags.push({ name: p.name, type: 'product' }))
            }
        }

        if (type === 1 && selectedDowntimeReasons.length > 0) {
            if (selectedDowntimeReasons.length === downtimeReasons.length) {
                tags.push({ name: 'All Reasons', type: 'downtimeReason' })
            } else {
                selectedDowntimeReasons.forEach(r => tags.push({ name: r.name, type: 'downtimeReason' }))
            }
        }

        if (selectedSubscribers.length > 0) {
            if (selectedSubscribers.length === orgUsers.length) {
                tags.push({ name: 'All Subscribers', type: 'subscriber' })
            } else {
                selectedSubscribers.forEach(s => tags.push({ name: s.name, type: 'subscriber' }))
            }
        }

        if (type === 1 && thresholdDuration !== '' && thresholdDurationType !== -1) {
            const name = '> ' + thresholdDuration +
                (thresholdDurationType === 0 ?
                    ' seconds' :
                    thresholdDurationType === 1 ?
                        ' minutes' :
                        ' hours')
            tags.push({ name, type: 'duration'})
        }

        if (tags.length > 0) {
            return tags.map((t, index) => {
                const shouldHideRemoveIcon =
                    t.name === 'All Lines' ||
                    t.name === 'All Product SKUs' ||
                    t.name === 'All Reasons' ||
                    t.name === 'All Subscribers'

                return <TippyTooltip
                    key={'tag-'+index}
                    html={
                        <div style={{ fontFamily: 'Roboto', fontSize: '10px', color: '#fff' }}>
                            {t.name}
                        </div>
                    }
                    position="bottom"
                    animation="fade"
                    theme="dark"
                >
                    <div className={classes.tag}>
                        <Typography variant='body2'
                                    className={classes.tagText}
                                    color='textPrimary'>{t.name}</Typography>
                        <div className={classes.tagRemoveIcon}>
                            {!shouldHideRemoveIcon &&
                                <IconButton edge="start"
                                            color="inherit"
                                            size='small'
                                            onClick={() => handleRemoveFilter(t)}>
                                    <CloseIcon />
                                </IconButton>
                            }
                        </div>
                    </div>
                </TippyTooltip>
            })
        } else {
            return <div/>
        }
    }

    if (linesLoading || productsLoading || downtimeReasonsLoading || orgUsersLoading) {
        return <div className={classes.LoadingContainer}>
            <CircularProgress variant='indeterminate'/>
        </div>
    }

    if (linesError || productsError || downtimeReasonsError || orgUsersError) {
        return <div className={classes.LoadingContainer}>
            <Typography variant={'subtitle1'}>Error Loading Resources</Typography>
            <Button variant='contained' color='primary' onClick={retryLoadingResources}>Retry</Button>
        </div>
    }

    return (
        <div className={classes.container}>
            <div className={classes.options_div}>
                <Fab className={classes.fab} color={'secondary'}>
                    <AddOutlined onClick={() => setShowAddNewNotification(true)}/>
                </Fab>
                <div className={classes.options_selectors}>
                    {renderTypeSelector()}
                    {/* <Divider orientation={'vertical'} /> */}
                    <div className={classes.date_pickers_div} >{renderOptionsByType()}</div>
                </div>
                <div className={classes.tags_wrapper_div}>
                    <Button variant='text'
                            className={classes.clearAllButton}
                            color='primary'
                            onClick={clearFilters}>Clear All</Button>
                    {renderFilterTags()}
                </div>
                {showTypeSelectorModal()}
                {showLinesModal()}
                {showSubscribersModal()}
                {showProductModal()}
                {showDowntimeReasonModal()}
                {showThresholdDurationModal()}
            </div>
            <div className={classes.results_div}>
                {renderNotificationsResults()}
            </div>
            {renderAddNewNotificationModal()}
            {renderDetailsModal()}
        </div>
    );
}

//######## STYLE ########
const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '-20px',
        marginTop: '20px',
        background: '#F2F2F2',
        minHeight: '75vh'
    },
    LoadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '-20px',
        marginTop: '20px',
        background: '#F2F2F2',
        minHeight: '75vh'
    },
    options_div: {
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        background: '#FFF',
        position: 'relative'
    },
    tags_wrapper_div: {
        padding: '20px 30px',
        display: 'flex',
        alignItems: 'baseline',
        flexWrap: 'wrap'
    },
    clearAllButton: {
        minWidth: 90
    },
    fab: {
        position: 'absolute',
        bottom: 0,
        right: 100,
        marginBottom: '-24px'
    },
    options_selectors: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    options_buttons: {
        margin: '30px 0',
        display: 'flex',
        flexDirection: 'column'
    },
    type_div: {
        margin: '20px 40px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        paddingBottom: '8px'
    },
    lines_products_div: {
        margin: '20px 0 20px 40px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        paddingBottom: '12px'
    },
    date_pickers_div: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    button: {
        marginBottom: '4px'
    },
    option_item: {
        fontSize: '16px !important',
        marginRight: '10px !important',
        marginTop: '12px !important',
        textAlign: 'start !important',
        textTransform: 'capitalize !important'
    },
    option_item_dropdown: {
        justifyContent: 'start',
        fontSize: '16px !important',
        textAlign: 'start !important',
        textTransform: 'capitalize !important',
        paddingBottom: '0px !important',
        color: '#666'
    },
    option_item_dropdown_downtime: {
        justifyContent: 'start',
        fontSize: '16px !important',
        textAlign: 'start !important',
        textTransform: 'capitalize !important',
        paddingBottom: '0px !important',
        color: '#666'
    },
    option_item_dropdown_product: {
        justifyContent: 'start',
        fontSize: '16px !important',
        textAlign: 'start !important',
        textTransform: 'capitalize !important',
        width: '145px',
        paddingBottom: '0px !important',
        color: '#666'
    },
    option_item_date: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '16px !important',
        marginRight: '10px !important',
        marginTop: '12px !important',
        textAlign: 'start !important',
        textTransform: 'capitalize !important'
    },
    option_type: {
        width: '130px',
        '& .MuiInputBase-root': {
            color: '#666'
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none'
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none'
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: '#fff'
        }
    },
    results_div: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        background: '#F2F2F2'
    },
    results_sections_wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        marginTop: '30px',
        marginBottom: '30px'
    },
    no_result_found_wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    },
    no_result_text: {
        color: '#bbb',
        marginTop: '20px'
    },
    tag: {
        backgroundColor: '#F2F2F2',
        margin: 10,
        padding: '13px 10px',
        display: 'flex',
        alignItems: 'baseline',
        maxWidth: 200
    },
    tagText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        padding: 6
    },
    tagRemoveIcon: {
        color: '#999',
        marginLeft: 10
    }
}));
