import React, { Component } from 'react';
import AddReasonModal from "../modals/AddReasonModal";
import DeleteConfirmationModal from "../modals/DeleteConfirmationModal";
import Modal from "../components/Modal";
import DowntimeReasonDetails from "../components/DowntimeReasonDetails";
import { CircularProgress, Button, Fab, Typography, Dialog, DialogTitle, Box, FormControlLabel, DialogActions } from "@material-ui/core";
import { AddOutlined } from "@material-ui/icons";
import AddReasonCategoryModal from '../modals/AddReasonCategoryModal';
import Checkbox from '@material-ui/core/Checkbox';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

class ReasonSettingsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_modal: false,
            // reasonDetails: props.reasonDetails,
            reason: null,
            status: null,
            reasons: null,
            submitting_edit: false,
            loading: false,
            lines: null,
            categories: [],
            allCategories: [],
            deleteCategoryConfirmOpen: false,
            deletableCategoryId: 0,
            deleteCategoryConfirmed: false,
        }
    }

    /* ######## LIFECYCLE METHODS ######## */
    componentDidMount() {
        this.getReasons()
        this.getCategories()
        LineStore.subscribeLines(this.handleLineChange);
    }

    componentWillUnmount() {
        LineStore.unsubscribe(this.handleLineChange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.factory !== this.props.factory) {
            this.setState({
                show_modal: false,
                reason: null,
                status: null,
                reasons: null,
                submitting_edit: false,
                loading: false,
                lines: null
            })
            this.getReasons()
            this.getCategories()
            LineStore.fetchLines()
        }
    }

    /* ######## FUNCTIONS ######## */

    mapReasonCategory = (categoryId) => {
        const category = this.state.allCategories.find((category) => category.id === categoryId)
        return category ? category.name : ""
    }

    handleLineChange = () => {
        this.setState({
            lines: LineStore.getLines()
        });
    }

    actionButton = () => {
        this.setState({
            show_modal: 'add_reason'
        })
    }

    categoryActionButton = () => {
        this.setState({
            show_modal: 'add_category'
        })
    }

    editCategoryActionButton = (id, name) => {
        this.setState({
            show_modal: 'add_category',
            editedCategoryId: id,
            editedCategoryName: name,
        });
    }

    hideModal = () => {
        this.setState({
            show_modal: false,
            submitting_edit: false,
            editedCategoryId: null,
            editedCategoryName: "",
        })
        this.getCategories()
    };



    modified = () => {
        this.getReasons();
    }


    getModalTitle() {
        let sm = this.state.show_modal;

        if (sm === 'add_reason') {
            return <DialogTitle disableTypography>
                <Typography variant="subtitle1">Add New Reason</Typography>
            </DialogTitle>
        } else if (sm === 'delete_reason') {
            return <DialogTitle disableTypography>
                <Typography variant="subtitle1">Archive Reason</Typography>
            </DialogTitle>
        } else if (sm === 'add_category') {
            return <DialogTitle disableTypography>
                <Typography variant="subtitle1">Category</Typography>
            </DialogTitle>
        } else {
            return '';
        }
    }

    handleCloseDeleteCategoryConfirmation = () => {
        this.setState({
            deleteCategoryConfirmOpen: false,
            deleteCategoryConfirmed: false
        })
    }

    openReasonDetails = (reason) => {
        this.setState({
            reason: reason
        })
        this.props.onOpenReasonDetails()
    }

    hideReasonDetails = () => {
        this.props.hideReasonDetails()
    }

    /* ######## API CALLS ######## */

    getReasons = () => {
        let t = this.props.type;
        let path = 'get_reasons';

        this.setState({
            loading: true,
            status: null
        })
        let params = {
            path: path,
            success: this.fetchSuccess,
            error: this.fetchError
        };

        API.c(params);
    }

    getCategories = () => {
        let params = {
            path: 'all_reason_categories',
            success: this.fetchSuccessCategories,
            error: this.fetchError
        };

        API.c(params);
    }

    onDeleteCategory = (id) => {
        let params = {
            path: 'delete_reason_category',
            path_variables: { ID: id },
            success: this.getCategories,
            error: this.fetchError
        };

        API.c(params);
    }

    fetchSuccess = (data) => {
        this.setState({
            loading: false,
            reasons: data,
            status: null
        })
    }

    fetchSuccessCategories = (data) => {
        let categories = [];
        data.forEach(d => {
            categories.push(d);
        });
        this.setState({
            allCategories: categories,
            deleteCategoryConfirmed: false,
            deleteCategoryConfirmOpen: false
        });
    }

    fetchError = (error) => {
        this.setState({
            loading: false,
            status: error
        })
    }

    /* ######## RENDERS ######## */

    showActionButton() {
        return <Fab color="secondary"
            className="settings_fab"
            disabled={this.props.reasonDetails || !this.state.lines || !this.state.lines[0]}
            onClick={this.actionButton}>
            <AddOutlined />
        </Fab>
    }

    showCategoryActionButton() {
        return <Fab color="secondary"
            className="settings_fab"
            disabled={false}
            onClick={this.categoryActionButton}>
            <AddOutlined />
        </Fab>
    }

    modal() {
        if (this.state.show_modal === false) {
            return <div />;
        } else {
            return <Dialog open={true} onClose={this.hideModal} maxWidth={"md"}>
                {this.getModalTitle()}
                {this.getModal()}
            </Dialog>;
        }
    }

    getModal() {
        switch (this.state.show_modal) {
            case 'add_reason':
                return <AddReasonModal allCategories={this.state.allCategories} hideModal={this.hideModal} modified={this.modified} lines={this.state.lines} />
            case 'delete_reason':
                return <DeleteConfirmationModal hideModal={this.hideModal} modified={this.modified}
                    item_deleted_text={this.state.reason.name} archive
                    delete_path="delete_reason" id={this.state.reason.id} />
            case 'add_category':
                return <AddReasonCategoryModal hideModal={this.hideModal}
                    id={this.state.editedCategoryId}
                    name={this.state.editedCategoryName} />
            default:
                return false;
        }
    }

    showDeleteCategoryConfirmation = () => {
        return (
            <Dialog
                open={this.state.deleteCategoryConfirmOpen}
                onClose={this.handleCloseDeleteCategoryConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ display: "flex", justifyContent: "center" }}
            >
                <DialogTitle style={{ width: "300px" }} id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <Box style={{ marginTop: "20px" }} />
                <FormControlLabel
                    style={{ margin: "0px 14px" }}
                    control={
                        <Checkbox
                            checked={this.state.deleteCategoryConfirmed}
                            onChange={(event) => this.setState({ deleteCategoryConfirmed: event.target.checked })}
                            style={{ width: "20px" }} />
                    }
                    label="Do you want to delete this category?"
                />
                <Box style={{ marginTop: "20px" }} />
                <DialogActions>
                    <Button color="primary"
                        variant="contained" style={{ ...Styles.buttonStyle(), margin: "0px 4px" }} onClick={this.handleCloseDeleteCategoryConfirmation} autoFocus>
                        Cancel
                    </Button>
                    <Button color="secondary"
                        variant="contained" disabled={!this.state.deleteCategoryConfirmed} style={{ ...Styles.buttonStyle(), margin: "0px 4px" }} onClick={() => this.onDeleteCategory(this.state.deletableCategoryId)}>
                        Delete
                    </Button>
                </DialogActions>
                <Box style={{ marginTop: "10px" }} />
            </Dialog>
        )
    }

    showCategoriesTable = () => {
        if (this.state.categories === null) {
            return (
                <div>
                    <div className="settings__table">
                        <CircularProgress />
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="settings__table">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow style={{ height: '20px', paddingTop: '20px', borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                    <TableCell
                                        align={'left'}
                                        className={'products_table_head_cell'}
                                    >
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'left',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div style={{ marginRight: '4px' }}>Category Name</div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.showCategories()}</TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        );
    };

    showCategories = () => {
        let { allCategories } = this.state;
        let { state } = this;

        return allCategories.map((item, index) => {
            return [
                <TableRow key={'product-' + index}>
                    <TableCell sx={{ wordBreak: "break-word" }} align={'left'} className={'products_table_body_cell'}>
                        {item.name}
                    </TableCell>
                    <TableCell />
                    <TableCell align={'right'} style={{ width: "20px" }} className={'products_table_body_cell'}>
                        <Button
                            color="primary"
                            variant="text"
                            onClick={() => this.editCategoryActionButton(item.id, item.name)}
                            style={Styles.buttonStyle()}
                        >
                            Edit
                        </Button>
                    </TableCell>
                    <TableCell align={'right'} style={{ width: "20px" }} className={'products_table_body_cell'}>
                        <Button
                            color="secondary"
                            variant="text"
                            onClick={() => this.setState({ deletableCategoryId: item.id, deleteCategoryConfirmOpen: true })}
                            style={Styles.buttonStyle()}
                        >
                            Delete
                        </Button>
                    </TableCell>
                </TableRow>
            ]
        });
    };

    showReasonsTable = () => {
        let t = this.props.type;
        if (this.props.reasonDetails) {
            return <DowntimeReasonDetails hideReasonDetails={this.hideReasonDetails} reason={this.state.reason}
                modified={this.modified} type={t} />
        }

        if (this.state.loading) {
            return <div>
                <div style={{ display: 'inline-grid' }}>
                    <CircularProgress />
                </div>
            </div>
        }

        let type = '';
        switch (t) {
            case 'downtime':
                type = "Downtime";
                break;
            case 'rework':
                type = "Rework";
                break;
            case 'waste':
                type = "Waste";
                break;
        }

        return <div>
            <div>
                <Typography variant='subtitle2' className={'settings-reason-sub-title'}>Active {type} Reasons</Typography>
                <div className="products__recipe-table">
                    <TableContainer>
                        <Table>
                            <TableHead className='table-head-elevation'>
                                <TableRow>
                                    <TableCell style={Styles.tableHeaderStyle()}>Category</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()}>Name</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()}>Code</TableCell>
                                    <TableCell style={Styles.editDeleteTableHeaderStyle()} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.showReasons()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <div>
                <Typography variant='subtitle2' className={'settings-reason-sub-title'}>Inactive {type} Reasons</Typography>
                <div className="products__recipe-table">
                    {this.showInactiveReasonsTable()}
                </div>
            </div>
        </div>
    }

    showReasons = () => {
        let { reasons } = this.state;
        let t = this.props.type;
        if (reasons) {
            let reasonsArr = [];
            switch (t) {
                case 'downtime':
                    reasonsArr = reasons.active_downtime_reason_list;
                    break;
                case 'rework':
                    reasonsArr = reasons.active_rework_reason_list;
                    break;
                case 'waste':
                    reasonsArr = reasons.active_waste_reason_list;
                    break;
            }
            reasonsArr.sort((a, b) => {
                if (a.category === b.category) {
                    return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
                } else if (a.category === "") {
                    return 1;
                } else if (b.category === "") {
                    return -1;
                } else {
                    return (a.category < b.category) ? -1 : 1;
                }
            })
            return reasonsArr.map((reason, index) => {
                const categoryName = this.state.allCategories.find(category => category.id === reason.category) ? this.state.allCategories.find(category => category.id === reason.category) : ""
                return <TableRow sx={{ wordBreak: "break-word", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", height: "60px" }}
                    key={"reason-" + reason.id}>
                    <TableCell style={Styles.tableRowColumnStyle()}>{Number.isInteger(reason.category) ? categoryName.name : reason.category}</TableCell>
                    <TableCell style={Styles.tableRowColumnStyle()}>{reason.name}</TableCell>
                    <TableCell style={Styles.tableRowColumnStyle()}>{reason.code}</TableCell>
                    <TableCell align='right' style={Styles.editDeleteTableRowColumnStyle()}>
                        <div>
                            <Button variant="text"
                                color="primary"
                                onClick={this.openReasonDetails.bind(this, reason)}>DETAILS</Button>
                        </div>
                    </TableCell>
                </TableRow>
            })
        }
    }

    showInactiveReasonsTable = () => {
        if (this.state.loading) {
            return <div >
                <div className="settings__table">
                    <CircularProgress />
                </div>
            </div>
        }

        return <div >
            <TableContainer>
                <Table>
                    <TableHead className='table-head-elevation'>
                        <TableRow sx={{ wordBreak: "break-word" }} style={{ height: '20px', paddingTop: '20px' }}>
                            <TableCell style={Styles.tableHeaderStyle()}>Category</TableCell>
                            <TableCell style={Styles.tableHeaderStyle()}>Name</TableCell>
                            <TableCell style={Styles.tableHeaderStyle()}>Code</TableCell>
                            <TableCell style={Styles.editDeleteTableHeaderStyle()} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.showInactiveReasons()}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    }

    showInactiveReasons = () => {
        let { reasons } = this.state;
        let t = this.props.type;

        if (reasons) {
            let reasonsArr = [];
            switch (t) {
                case 'downtime':
                    reasonsArr = reasons.inactive_downtime_reason_list;
                    break;
                case 'rework':
                    reasonsArr = reasons.inactive_rework_reason_list;
                    break;
                case 'waste':
                    reasonsArr = reasons.inactive_waste_reason_list;
                    break;
                case 'categories':
                    reasonsArr = [];
                    break;
            }
            reasonsArr.sort((a, b) => {
                //if same category, reason will be sorted by reason name alphabetically
                if (a.category === b.category) {
                    return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
                    //if category is empty string, it will be placed last in the order
                } else if (a.category === "") {
                    return 1;
                } else if (b.category === "") {
                    return -1;
                    //if categories aren't same, and non is empty, reason will be sorted by category name alphabetically
                } else {
                    return (a.category < b.category) ? -1 : 1;
                }
            })
            return reasonsArr.map((reason, index) => {
                return <TableRow
                    key={"line-" + reason.id}>
                    <TableCell style={Styles.tableRowColumnStyleInactive()}>{t === "downtime" ? this.mapReasonCategory(reason.category) : reason.category}</TableCell>
                    <TableCell style={Styles.tableRowColumnStyleInactive()}>{reason.name}</TableCell>
                    <TableCell style={Styles.tableRowColumnStyleInactive()}>{reason.code}</TableCell>
                    <TableCell align='right' style={Styles.editDeleteTableRowColumnStyle()}>
                        <div>
                            <Button variant="text"
                                color="primary"
                                onClick={this.openReasonDetails.bind(this, reason)}>DETAILS</Button>
                        </div>
                    </TableCell>
                </TableRow>
            })
        }
    }

    showStatus = () => {
        if (this.state.status) {
            return <div>
                {this.state.status}
            </div>
        } else {
            return <div />
        }
    }

    render() {
        return <div>
            {this.modal()}
            {this.props.type === 'categories' ? (
                <div className="products">
                    {this.showDeleteCategoryConfirmation()}
                    {this.showCategoryActionButton()}
                    {this.showCategoriesTable()}
                    {this.showStatus()}
                </div>
            ) : (
                <div className="products">
                    {this.showActionButton()}
                    {this.showReasonsTable()}
                    {this.showStatus()}
                </div>
            )
            }
        </div>
    }
}

export default ReasonSettingsView
