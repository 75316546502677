import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';

export default function SubscriberRow({user}) {
    const classes = useStyles();

    return <div className={classes.row}>
        <EmailIcon color={user.user_metadata.is_send_email ? 'primary' : 'disabled'} className={classes.icon}/>
        <SmsIcon color={user.user_metadata.is_send_sms ? 'primary' : 'disabled'} className={classes.icon}/>
        <PhonelinkRingIcon color={user.user_metadata.is_send_push ? 'primary' : 'disabled'} className={classes.icon}/>
        <div className={classes.column}>
            <Typography variant='body1'>{user.user_metadata.first_name + ' ' + user.user_metadata.last_name}</Typography>
            <Typography variant='caption' className={classes.email}>{user.email}</Typography>
        </div>
    </div>
}

//######## STYLE ########
const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        alignItems: 'center'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        margin: 10
    },
    email: {
        color: '#999'
    },
    icon: {
        margin: 10
    }
}));