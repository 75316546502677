import { Button, Checkbox, DialogContent, DialogTitle, FormControlLabel, makeStyles, Typography } from '@material-ui/core';
import { Dialog } from 'material-ui';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useEffect, useState } from 'react';
import './SaveEmailedReportStyles.css'

const DeleteEmailedReportModal = (props) => {

    const [delete_confirmed, setDelete_confirmed] = useState(false)

    const submitDelete = () => {
        let params = {
            path: props.delete_path,
            path_variables: {
                REPORT_ID: props.id
            },
            success: deleteSuccess,
            error: () => {}
        }
        API.c(params);

    }

    const deleteSuccess = () => {
        props.modified();
        props.hideModal();
    }

    return (
            <Dialog
                open={true}
                onClose={props.onHide}
                contentStyle={{
                    width: '730px',
                    maxWidth: '730px',

                }}
                style={{ left: "0%", border: "0px", }}
                scroll="body"
            >
                <DialogTitle style={{ display: "flex", marginBottom: "8px" }} disableTypography>
                    <Typography variant="h5" style={{ color: "#000000", display: "flex", justifyContent: "left" }}> Delete Report</Typography>
                </DialogTitle>
                <DialogContent>
                        <p style={{ fontSize: "15px", fontWeight: "400", color: "#000000", marginBottom: "22px" }}>Are you sure you want to delete {props.reportName}?</p>
                        <p style={{ fontSize: "11px", fontWeight: "400", color: "#0086FF" , marginBottom: "24px"}}>Confirm delete of {props.reportName}</p>
                        <FormControlLabel style={{  color: "#000000" }} control={<Checkbox onChange={() => setDelete_confirmed(!delete_confirmed)}
                                                 color="primary"
                                                 checked={delete_confirmed}/>}
                              label={"Yes I'm sure, confirm delete."}/>


                    <div style={{ display: "flex", justifyContent: "right", marginTop: "12px"}}>
                        <Button variant="text"
                            color="primary"
                            style={{ marginRight: "16px", color: "#0086FF" }}
                            onClick={props.hideModal}>
                                CANCEL
                        </Button>
                        <Button variant="contained"
                            color="secondary"
                            disabled={!delete_confirmed}
                            onClick={submitDelete}>
                                DELETE
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
    )
}

export default DeleteEmailedReportModal