import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
//Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';

import _ from 'lodash';

const FiltersParse = props => {
    const {
        date,
        multi_date,
        selected_products,
        report_selected_dates,
        selected_lines,
        date_from,
        date_to,
        lines,
        products,
        tags,
        selected_tags,
        categories,
        selected_categories
    } = props;

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dateFormat = str => {
        const date = new Date(str);
        return `
        ${months[date.getMonth()]}
        ${date.getDate()},
        ${date.getFullYear()}
        `;
    };

    const [open, setOpen] = useState(false);
    const [modalSection, setModalSection] = useState(null);

    const dateSolution = () => {
        let date_initial = date;
        if (!multi_date) {
            if (!date_from) {
                return null;
            }
            return `${dateFormat(date_from)} - ${dateFormat(date_to)}`;
        } else {
            if (typeof multi_date === 'string') {
                return multi_date;
            } else {
                if (Array.isArray(report_selected_dates)) {
                    if (typeof (report_selected_dates[0] === 'string')) {
                        const preferDates = [];
                        report_selected_dates.sort((a, b) => {
                            const formatDate_1 = Date.parse(a);
                            const formatDate_2 = Date.parse(b);
                            return formatDate_1 - formatDate_2;
                        });
                        report_selected_dates.map((item, index) => {
                            preferDates[index] = dateFormat(item);
                        });
                        return preferDates.join('\u00A0 - \u00A0');
                    }
                }
            }
        }

        return date_initial;
    };

    const handleChangeModalSection = section => {
        setModalSection(section);
    };
    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const filterSelect = (idxs, data) => {
        let filtred = [];
        for (let item of data) {
            for (let idx of idxs) {
                if (item.id === idx) {
                    filtred.push(item);
                }
            }
        }
        return filtred;
    };
    const filterSelectProduct = (idxs, data) => {
        let filtred = [];
        for (let item of data) {
            for (let idx of idxs) {
                if (item.product_id === idx) {
                    filtred.push(item);
                }
            }
        }
        return filtred;
    };
    let filtredLines;
    if (Array.isArray(selected_lines)) {
        if (Array.isArray(lines)) {
            filtredLines = filterSelect(selected_lines, lines);
        }
    }

    let filtredProducts;
    if (Array.isArray(selected_products)) {
        if (Array.isArray(products)) {
            filtredProducts = filterSelectProduct(selected_products, products);
        }
    }

    let filtredTags;
    if (Array.isArray(selected_tags)) {
        if (Array.isArray(tags)) {
            filtredTags = filterSelect(selected_tags, tags);
        }
    }

    let filtredCategories;
    if (Array.isArray(selected_categories)) {
        if (Array.isArray(categories)) {
            filtredCategories = filterSelect(selected_categories, categories);
        }
    }

    let modalData;
    if (modalSection === 'lines') {
        modalData = filtredLines;
    } else if (modalSection === 'dates') {
        modalData = dateSolution();
    } else if (modalSection === 'products') {
        modalData = filtredProducts;
    } else if (modalSection === 'categories') {
        modalData = filtredCategories;
    } else {
        modalData = filtredTags;
    }
    const classes = useStyles();

    let countLineShow = 3;
    let countProductShow = 2;
    let countCategoriesShow = 3;
    let countTagsShow = 3;

    if (window.innerWidth < 1500) {
        countProductShow = 1;
    }

    if (window.innerWidth < 1400) {
        countLineShow = 2;
    }

    if (window.innerWidth < 1200) {
        countLineShow = 1;
    }

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {dateSolution() ? (
                <WrapFilterSection>
                    <div style={{ lineHeight: 3, padding: '0px 10px' }}>{dateSolution().substring(0, 196)}</div>
                    {dateSolution().length > 200 && multi_date ? (
                        <ShowAllButton
                            handleChangeModalSection={handleChangeModalSection}
                            modalSection="dates"
                            handleOpenModal={handleOpenModal}
                        />
                    ) : null}
                </WrapFilterSection>
            ) : null}

            {Array.isArray(filtredLines) ? (
                <WrapFilterSection>
                    {_.size(lines) !== _.size(selected_lines) ?
                        filtredLines.slice(0, countLineShow).map((item, index) => {
                            if (index === filtredLines.slice(0, countLineShow).length - 1) {
                                return (
                                    <div key={item.name} className={classes.items}>
                                        {item.name}
                                    </div>
                                );
                            }
                            return (
                                <React.Fragment key={item.name}>
                                    <div className={classes.items}>{item.name}</div>
                                    <TextDivider />
                                </React.Fragment>
                            );
                        }) : (
                            <div className={classes.items}>
                                All lines
                            </div>
                        )
                    }
                    {filtredLines.length > countLineShow ? (
                        <ShowAllButton
                            handleChangeModalSection={handleChangeModalSection}
                            modalSection="lines"
                            handleOpenModal={handleOpenModal}
                        />
                    ) : null}
                </WrapFilterSection>
            ) : null}

            {Array.isArray(filtredProducts) ? (
                <WrapFilterSection>
                    {_.size(products) !== _.size(selected_products) ?
                        filtredProducts.slice(0, countProductShow).map((item, index) => {
                            if (index === filtredProducts.slice(0, countProductShow).length - 1) {
                                return (
                                    <div key={item.display_name} className={classes.items}>
                                        {item.display_name}
                                    </div>
                                );
                            }
                            return (
                                <React.Fragment key={item.display_name}>
                                    <div className={classes.items}>{item.display_name}</div>
                                    <TextDivider />
                                </React.Fragment>
                            );
                        }) : (
                            <div className={classes.items}>
                                All products
                            </div>
                        )
                    }
                    {filtredProducts.length > countProductShow ? (
                        <ShowAllButton
                            handleChangeModalSection={handleChangeModalSection}
                            modalSection="products"
                            handleOpenModal={handleOpenModal}
                        />
                    ) : null}
                </WrapFilterSection>
            ) : null}

            {Array.isArray(filtredCategories) ? (
                <WrapFilterSection>
                    {_.size(categories) !== _.size(selected_categories) ?
                        filtredCategories.slice(0, countCategoriesShow).map((item, index) => {
                            if (index === filtredCategories.slice(0, countCategoriesShow).length - 1) {
                                return (
                                    <div key={item.name} className={classes.items}>
                                        {item.name}
                                    </div>
                                );
                            }
                            return (
                                <React.Fragment key={item.name}>
                                    <div className={classes.items}>{item.name}</div>
                                    <TextDivider />
                                </React.Fragment>
                            );
                        }) : (
                            <div className={classes.items}>
                                All categories
                            </div>
                        )
                    }
                    {filtredProducts && filtredProducts.length > countProductShow ? (
                        <ShowAllButton
                            handleChangeModalSection={handleChangeModalSection}
                            modalSection="categories"
                            handleOpenModal={handleOpenModal}
                        />
                    ) : null}
                </WrapFilterSection>
            ) : null}

            {Array.isArray(filtredTags) ? (
                <WrapFilterSection>
                    {_.size(tags) !== _.size(selected_tags) ?
                        filtredTags.slice(0, countTagsShow).map((item, index) => {
                            if (index === filtredTags.slice(0, countTagsShow).length - 1) {
                                return (
                                    <div key={item.name} className={classes.items}>
                                        {item.name}
                                    </div>
                                );
                            }
                            return (
                                <React.Fragment key={item.name}>
                                    <div className={classes.items}>{item.name}</div>
                                    <TextDivider />
                                </React.Fragment>
                            );
                        }) : (
                            <div className={classes.items}>
                                All tags
                            </div>
                        )
                    }
                    {filtredProducts && filtredProducts.length > countProductShow ? (
                        <ShowAllButton
                            handleChangeModalSection={handleChangeModalSection}
                            modalSection="tags"
                            handleOpenModal={handleOpenModal}
                        />
                    ) : null}
                </WrapFilterSection>
            ) : null}

            <Modal
                modalSection={modalSection}
                modalData={modalData}
                open={open}
                handleCloseModal={handleCloseModal}
                section={modalSection}
            />
        </div>
    );
};

const WrapFilterSection = ({ children }) => {
    const classes = useStyles();
    return (
        <div
            className={classes.items}
            style={{
                padding: 10,
                display: 'flex',
                flexWrap: 'wrap',
                minWidth: '100px',
                borderRadius: '50px',
                color: '#666',
                marginRight: 10,
                marginTop: 10,
                backgroundColor: '#FFF'
            }}
        >
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>{children}</div>
        </div>
    );
};

const TextDivider = () => {
    return (
        <div
            style={{
                fontWeight: 600,
                color: '#004484',
                paddingBotton: 13,
                fontSize: '1.5em'
            }}
        >
            .
        </div>
    );
};

const ShowAllButton = ({ modalSection, handleOpenModal, handleChangeModalSection }) => {
    return (
        <div>
            <Button
                onClick={() => {
                    handleChangeModalSection(modalSection);
                    handleOpenModal();
                }}
                style={{ margin: 0, padding: 10 }}
                color="primary"
            >
                show all
            </Button>
        </div>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        items: {
            ...theme.typography.button,
            padding: 10
        }
    })
);

const Modal = ({ modalSection, modalData, open, handleCloseModal }) => {
    return (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
            <Box pb={3}>
                <DialogTitle>
                    <Box display="flex" p="10px">
                        <Typography component="div" variant="subtitle2">
                            {modalSection}
                        </Typography>
                        <Box flexGrow="1" />
                        <Box>
                            <IconButton size="small" onClick={handleCloseModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    {Array.isArray(modalData)
                        ? modalData.map((item, index) => {
                            return (
                                <div
                                    style={{ padding: '0px 10px 10px 10px' }}
                                    key={(modalSection === 'lines' || modalSection === 'tags' || modalSection === 'categories') ? item.name : item.display_name}
                                >
                                    <Typography variant="body2">
                                        {(modalSection === 'lines' || modalSection === 'tags' || modalSection === 'categories') ? item.name : item.display_name}
                                    </Typography>
                                </div>
                            );
                        })
                        : typeof modalData === 'string'
                            ? modalData.split(' - ').map((item, index) => {
                                if (index === 0) {
                                    return <div style={{ padding: '10px' }}>{`\u00A0${item}`}</div>;
                                }
                                return <div style={{ padding: '10px' }}>{item}</div>;
                            })
                            : null}
                </DialogContent>
            </Box>
        </Dialog>
    );
};

export default FiltersParse;
