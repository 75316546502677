import React, {Component} from 'react';
import {Button, Checkbox, CircularProgress, FormControlLabel} from "@material-ui/core";

class SelectActionModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_actions: props.selected_actions,
            select_button_text: "Select None"
        }
    }

    componentDidMount() {
        this.setKeyListener();
        if(this.props.selected_actions.length === this.props.actions.length) {
            this.setState({
                select_button_text: "Select None"
            })
        } else {
            this.setState({
                select_button_text: "Select All"
            })
        }
    }

    componentWillUnmount() {
        this.cancelKeyListener()
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            event.preventDefault()
            if (event.key === 'Enter') {
                if(this.state.selected_actions[0]) {
                    this.hideSelectActions()
                }
            } else if(event.key === "Escape") {
                this.props.cancelSelectActions()
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }

    handleAllActionsClick = () => {
        let updatedSelectedActions = this.state.selected_actions
        let select_button_text
        if(updatedSelectedActions.length === this.props.actions.length) {
            updatedSelectedActions = []
            select_button_text = "Select All"
        } else {
            updatedSelectedActions.length = 0;
            this.props.actions.forEach(action => {
                updatedSelectedActions.push(action)
            })
            select_button_text = "Select None"
        }
        this.setState({
            selected_actions: updatedSelectedActions,
            select_button_text
        })
    }

    onActionCheck = (index, event, isInputChecked) => {
        let updatedSelectedActions = Array.from(this.state.selected_actions);
        if(!isInputChecked) {
            let i = updatedSelectedActions.indexOf(this.props.actions[index]);
            if (i !== -1) updatedSelectedActions.splice(i, 1);
        } else {
            updatedSelectedActions.push(this.props.actions[index])
        }
        let select_button_text;
        if(updatedSelectedActions.length === this.props.actions) {
            select_button_text = "Select None"
        } else {
            select_button_text = "Select All"
        }
        this.setState({
            selected_actions: updatedSelectedActions,
            select_button_text
        })
    }

    hideSelectActions = () => {
        if(this.state.selected_actions[0]) {
            this.props.hideSelectActions(this.state.selected_actions)
        }
    }

    renderProducts = () => {
        if(!this.props.actions[0]) {
            return <div className="reports__loading">
                <CircularProgress />
            </div>
        }

        return this.props.actions.map((action, index) => {
            return <FormControlLabel className="select-lines-body-item"
                                     control={<Checkbox checked={this.state.selected_actions.indexOf(action) > -1}
                                                        color={"primary"}
                                                        onChange={this.onActionCheck.bind(this, index)}
                                                        />}
                                     label={action}
                                     key={"lines-"+action} />
        })
    }


    render() {
        return <div>
            <div
                className="select-lines"
                onClick={this.hideSelectActions}
            />
            <div className="select-lines-container" style={this.props.style}>
                <div className="select-lines-header">
                    <div style={{flex: 1, marginLeft: "24px"}}>
                        <div className="select-lines-header-button modal__content-title__text">Action Filter</div>
                    </div>
                    <Button variant="text"
                            color="primary"
                            className="select-lines-header-button"
                            onClick={this.handleAllActionsClick}>{this.state.select_button_text}</Button>
                    <Button variant="contained"
                            color="primary"
                            className="select-lines-header-button"
                            onClick={this.hideSelectActions}
                            disabled={!this.state.selected_actions[0]}>Filter</Button>
                </div>
                <div className="select-lines-body">
                    {this.renderProducts()}
                </div>

            </div>
        </div>
    }
}

export default SelectActionModal