import React, { Component } from 'react';
import { FontIcon } from 'material-ui';
import { Tooltip as TippyTooltip } from 'react-tippy';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import ManageLinesModal from '../../../modals/ManageLinesModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

class ManageLinesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            line_list: props.line_list,
            editing_line: null,
            product_is_active: false,
            product_target_speed: '',
            product_number_of_lanes: '',
            line_index: -1,
            submitting_line_edit: false,
            edit_line_error: null,
            product_edited: false,
            bulk_edit: false,
            bulk_target_speed: '',
            bulk_number_of_lanes: '',
            bulk_is_active: null,
            bulk_selected_rows: [], //this.setInitialSelection(),
            submitting_bulk_edit: false,
            calls: 0,
            line_success: false,
            bulk_success: false,
            show_modal: false
        };
    }

    /* -------- LIFECYCLE METHODS -------- */

    componentDidMount() {
        this.setState({
            user_role: JSON.parse(window.localStorage.getItem('factory')).role
        })
    }

    /* -------- FUNCTIONS -------- */
    handleChange(event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    handleEditLine = line_id => {
        this.setState({
            bulk_selected_rows: [line_id],
            show_modal: true
        });
    };

    getTargetWeight = (name, index) => {
        let targetWeight = 0;
        this.state.line_list[index].giveaway_target_list.forEach(giveawayTarget => {
            if (giveawayTarget.location_name === name) {
                targetWeight = giveawayTarget.target_weight_kg;
            }
        });

        return targetWeight;
    };

    getIsActive = (name, index) => {
        let isActive = false;
        this.state.line_list[index].giveaway_target_list.forEach(giveawayTarget => {
            if (giveawayTarget.location_name === name) {
                isActive = giveawayTarget.is_active;
            }
        });

        return isActive;
    };

    getUnitToDisplay = (name, index) => {
        let unit_to_display = 'g';
        this.state.line_list[index].giveaway_target_list.forEach(giveawayTarget => {
            if (giveawayTarget.location_name === name) {
                unit_to_display = giveawayTarget.unit_to_display;
            }
        });

        return unit_to_display;
    };

    handleLineChange = (event, i, v) => {
        switch (event.target.id) {
            case '':
                this.setState({
                    product_is_active: v,
                    product_edited: true
                });
                break;
            case 'product_target_speed':
                this.setState({
                    product_target_speed: i,
                    product_edited: true
                });
                break;
            case 'product_number_of_lanes':
                this.setState({
                    product_number_of_lanes: i,
                    product_edited: true
                });
                break;
        }
    };

    hideModal = () => {
        this.setState({
            show_modal: false
        });
    };

    refreshProduct = () => {
        this.props.submitSuccess();
    };

    /* -------- API CALLS -------- */

    /* -------- RENDERS -------- */
    getUnit = (line_id, type) => {
        let { product_on_line_units, units } = this.props;

        let line = product_on_line_units.find(l => l.line_id === line_id);
        if (!line) {
            return '--';
        }

        let unitObj = line.units[type];

        if (!unitObj || !unitObj.conversion_value) {
            return '--';
        }

        let unit = unitObj.conversion_value;

        let unitType = '';
        let unitItem;
        if (units) {
            unitItem = units.find(u => u.id === unitObj.unit_id);
        }
        if (unitItem) {
            unitType = ' ' + unitItem.name;
        }

        return unit + unitType;
    };

    showLineTable = () => {
        let { bulk_selected_rows, line_list, bulk_edit } = this.state;

        return (
            <div style={{ marginTop: "50px" }}>
                <Typography variant="subtitle1">Line List</Typography>
                <div className="products__line-table">
                    <TableContainer>
                        <Table selectable={false} multiSelectable={false}>
                            <TableHead displaySelectAll={false} adjustForCheckbox={false} enableSelectAll={false}>
                                <TableRow style={{ height: '42px', paddingTop: '20px' }}>
                                    {/*<TableRowColumn style={Styles.tableHeaderStyleCheckBox()}>{*/}
                                    {/*bulk_edit ?*/}
                                    {/*<Checkbox*/}
                                    {/*checked={bulk_selected_rows.length === line_list.length}*/}
                                    {/*onChange={this.onRowSelected.bind(this, 'all')}*/}
                                    {/*style={{fontSize:'16px'}}*/}
                                    {/*/>*/}
                                    {/*:*/}
                                    {/*<div/>*/}
                                    {/*}</TableRowColumn>*/}
                                    <TableCell style={Styles.tableHeaderStyle()}>Line</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()}>Status</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()}>Target Speed</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()}>Sensor Count</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()}>Speed</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()}>Rework</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()}>Waste</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()}>Finished Goods</TableCell>
                                    <TableCell style={Styles.tableHeaderStyle()} />
                                </TableRow>
                            </TableHead>
                            {this.renderLinesTableBody()}
                        </Table>
                    </TableContainer>
                </div>
                {/*{this.renderBulkEditActions()}*/}
            </div>
        );
    };

    tableRowStyle = index => {
        let bgColor = '#ffffff';
        // if ( index % 2 === 0)  bgColor = "#f2f2f2";

        return {
            backgroundColor: bgColor,
            height: '72px',
            borderBottom: '1px solid rgb(0, 0, 0, 0.12)'
        };
    };

    renderLinesTableBody = () => {
        let { bulk_selected_rows, bulk_edit } = this.state;

        return (
            <TableBody displayRowCheckbox={false}>
                {this.props.line_list.map((item, index) => {
                    return (
                        <TableRow sx={{ wordBreak: "break-word", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} key={'product-line' + index}>
                            {/*<TableRowColumn style={Styles.tableRowColumnStyleCheckBox()}>{*/}
                            {/*bulk_edit ?*/}
                            {/*<Checkbox*/}
                            {/*key={'check-'+item.line_id}*/}
                            {/*checked={bulk_selected_rows.includes(item.line_id)}*/}
                            {/*onChange={this.onRowSelected.bind(this, item.line_id)}*/}
                            {/*style={{fontSize:'16px'}}*/}
                            {/*/>*/}
                            {/*:*/}
                            {/*<div/>*/}
                            {/*}*/}
                            {/*</TableRowColumn>*/}
                            <TableCell
                                style={
                                    item.product_is_active
                                        ? Styles.tableRowColumnStyleProductDetails()
                                        : Styles.tableRowColumnStyleInactive()
                                }
                            >
                                {item.line_name}
                            </TableCell>
                            <TableCell
                                style={
                                    item.product_is_active
                                        ? Styles.tableRowColumnStyleProductDetails()
                                        : Styles.tableRowColumnStyleInactive()
                                }
                            >
                                {item.product_is_active ? 'Active' : 'Inactive'}
                            </TableCell>
                            <TableCell
                                style={
                                    item.product_is_active
                                        ? Styles.tableRowColumnStyleProductDetails()
                                        : Styles.tableRowColumnStyleInactive()
                                }
                            >
                                {item.target_speed}
                            </TableCell>
                            <TableCell
                                style={
                                    item.product_is_active
                                        ? Styles.tableRowColumnStyleProductDetails()
                                        : Styles.tableRowColumnStyleInactive()
                                }
                            >
                                {this.getUnit(item.line_id, 'output')}
                            </TableCell>
                            <TableCell
                                style={
                                    item.product_is_active
                                        ? Styles.tableRowColumnStyleProductDetails()
                                        : Styles.tableRowColumnStyleInactive()
                                }
                            >
                                {this.getUnit(item.line_id, 'speed')}
                            </TableCell>
                            <TableCell
                                style={
                                    item.product_is_active
                                        ? Styles.tableRowColumnStyleProductDetails()
                                        : Styles.tableRowColumnStyleInactive()
                                }
                            >
                                {this.getUnit(item.line_id, 'rework')}
                            </TableCell>
                            <TableCell
                                style={
                                    item.product_is_active
                                        ? Styles.tableRowColumnStyleProductDetails()
                                        : Styles.tableRowColumnStyleInactive()
                                }
                            >
                                {this.getUnit(item.line_id, 'waste')}
                            </TableCell>
                            <TableCell
                                style={
                                    item.product_is_active
                                        ? Styles.tableRowColumnStyleProductDetails()
                                        : Styles.tableRowColumnStyleInactive()
                                }
                            >
                                {this.getUnit(item.line_id, 'finished_goods')}
                            </TableCell>
                            <TableCell style={Styles.tableRowColumnEditButtonStyle()}>
                            {this.state.user_role !== "scoreboard" && this.state.user_role !== "factory_read_only" && this.state.user_role !== "floor_team" && this.state.user_role !== "factory_supervisor" ? (
                                <Button
                                    variant="text"
                                    color="primary"
                                    disabled={!this.props.units}
                                    onClick={this.handleEditLine.bind(this, item.line_id)}
                                >
                                    Edit
                                </Button>
                            ) : null }
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        );
    };

    modal = () => {
        if (this.state.show_modal) {

            const lineData = this.state.line_list.find((line) => line.line_id === this.state.bulk_selected_rows[0])
            const lineUnitsData = this.props.product_on_line_units.find((line) => line.line_id === this.state.bulk_selected_rows[0])


            const prevValues = {

                target_speed: lineData && lineData.target_speed ? lineData.target_speed : null,
                product_is_active: lineData && lineData.product_is_active ? lineData.product_is_active : null,
                ...(lineUnitsData && lineUnitsData.units ? lineUnitsData.units : null)
                
            }

            return (
                <ManageLinesModal
                    prevValues={prevValues}
                    lines={this.state.line_list}
                    line_ids={this.state.bulk_selected_rows}
                    units={this.props.units}
                    product_on_line_units={this.props.product_on_line_units}
                    line_default_units={this.props.line_default_units}
                    productId={this.props.productId}
                    refreshProduct={this.refreshProduct}
                    hideModal={this.hideModal}
                />
            );
        }
    };

    render() {
        return (
            <div>
                {this.showLineTable()}
                {this.modal()}
            </div>
        );
    }
}

export default ManageLinesTable;
