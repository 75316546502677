import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {isEmpty} from 'lodash';
import decode from 'jwt-decode';

export default function NotificationCard({notification, onEditClick, type, products, lines, downtimeReasons, onSubscribe, user}) {
    const classes = useStyles();
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        checkIsSubscribed()
    }, [notification])

    const checkIsSubscribed = () => {
        if(!isEmpty(notification.users) && notification.users.find(u => u.auth0_user_id === user.sub)) {
            setIsSubscribed(true);
        } else {
            setIsSubscribed(false);
        }
    }

    const onSubscribeClick = () => {
        setLoading(true)
        setError(false)
        onSubscribe(notification.id, type, isSubscribed, onResponse)
    }

    const onResponse = (error = false) => {
        setLoading(false)
        if (error) {
            setError(true)
        }
    }

    const getLinesDisplay = (notificationLines) => {
        let linesDisplay = ''
        if (!notificationLines || isEmpty(notificationLines)) {
            linesDisplay = 'All Lines'
        } else if (notificationLines.length === lines.length) {
            linesDisplay = 'All Lines'
        } else {
            linesDisplay = notificationLines.map(l => l.name).join(', ')
        }
        return linesDisplay
    }

    const getProductsDisplay = (notificationProducts) => {
        let productsDisplay = ''
        if (!notificationProducts || isEmpty(notificationProducts)) {
            productsDisplay = 'All SKUs'
        } else if (notificationProducts.length === products.length) {
            productsDisplay = 'All SKUs'
        } else {
            productsDisplay = notificationProducts.map(p => p.name + ' - ' + p.desc).join(', ')
        }
        return productsDisplay
    }

    const getReasonsDisplay = (downtime_duration_sec, downtime_reason) => {
        let reasonName = 'All reasons'
        let threshold = '> ' + (downtime_duration_sec / 60) + ' minute';

        if (downtime_reason) {
            const reason = downtimeReasons.find(r => parseInt(r.id) === parseInt(downtime_reason))
            if (reason) {
                reasonName = reason.category + ' - ' + reason.name;
            }
        }

        return `${reasonName} | ${threshold}`
    }

    return <Paper elevation={1} className={classes.paper}>
        <Typography variant={'body1'}>{notification.name}</Typography>
        <div className={classes.bodyDiv}>
            <div className={classes.infoDiv}>
                <div className={classes.bodyHeader}>
                    <Typography className={classes.text}>Line</Typography>
                    {type !== 'downtime' &&<Typography className={classes.text}>SKUs</Typography>}
                    {type === 'downtime' && <Typography className={classes.text}>Threshold</Typography>}
                </div>
                <div className={classes.bodyDetails}>
                    <Typography className={classes.text}>{getLinesDisplay(notification.lines)}</Typography>
                    {type !== 'downtime' &&
                        <Typography className={classes.text}>
                            {getProductsDisplay(notification.products)}
                        </Typography>
                    }
                    {type === 'downtime' &&
                        <Typography className={classes.text}>
                            {getReasonsDisplay(notification.downtime_duration_secs, notification.downtime_reason)}
                        </Typography>
                    }
                </div>
            </div>
            {error && <Typography variant={'body2'} color={'error'} className={classes.error}>Subscription change failed!</Typography>}
            {loading && <CircularProgress variant={'indeterminate'}/>}
            <div className={classes.buttonsDiv}>
                <Button color={isSubscribed ? 'secondary' : 'primary'}
                        variant={'contained'}
                        disabled={loading}
                        onClick={onSubscribeClick}>
                    {isSubscribed ? 'Unsubscribe' : 'Subscribe'}
                </Button>
                <Button color={'primary'}
                        onClick={onEditClick}>
                    Edit
                </Button>
            </div>

        </div>
    </Paper>
}

//######## STYLE ########
const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flex: 1,
        padding: '15px 30px 10px',
        marginBottom: 10
    },
    bodyDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 10,
        justifyContent: 'space-between'
    },
    infoDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: 'calc(100% - 200px)'
    },
    bodyHeader: {
        color: '#bbb',
        marginRight: 15,
        fontWeight: 700
    },
    bodyDetails: {
        color: '#666',
        minWidth: 0,
        flex: 1
    },
    text: {
        fontSize: '0.75rem',
        marginBottom: 5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden'
    },
    error: {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: 8
    },
    buttonsDiv: {
        width: 190,
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));