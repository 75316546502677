import React, { Component } from 'react';
import { Table, TableHeader, TableBody, TableRow, TableRowColumn } from 'material-ui';
import { CircularProgress, Button } from '@material-ui/core';

class ConfirmRunEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /* ######## LIFECYCLE METHODS ######## */

    componentDidMount = () => {
        this.setKeyListener();
    };

    componentWillUnmount = () => {
        this.cancelKeyListener();
    };

    setKeyListener = () => {
        window.onkeydown = event => {
            if (event.keyCode === 13) {
                this.props.update();
            } else if (event.keyCode === 27) {
                this.props.cancel();
            }
        };
    };

    cancelKeyListener = () => {
        window.onkeydown = null;
    };

    /* ######## FUNCTIONS ######## */

    /* ######## RENDERS ######## */

    renderRunDetailsChangesSection = () => {
        let originalRun = this.props.original_run;
        let updatedRun = this.props.updated_run;

        return (
            <div className="confirm-run-changes-section margin-bottom-xl">
                <div className="font-size-bg margin-bottom-bg">Run Details Changes</div>
                <Table selectable={false} multiSelectable={false}>
                    <TableHeader displaySelectAll={false} adjustForCheckbox={false} enableSelectAll={false}>
                        <TableRow style={{ height: '20px', paddingTop: '20px' }}>
                            <TableRowColumn style={Styles.tableHeaderStyle()} />
                            <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>
                                Current Run
                            </TableRowColumn>
                            <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>
                                Edited Run
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableHeaderStyle()} />
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        <TableRow style={Styles.tableRowStyle(0)}>
                            <TableRowColumn className="bold" style={Styles.tableRowColumnConfirmTableStyle()}>
                                Run Start
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.formatShortDateTime(originalRun.start_time)}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.formatShortDateTime(updatedRun.start_time)}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()} />
                        </TableRow>
                        <TableRow style={Styles.tableRowStyle(1)}>
                            <TableRowColumn className="bold" style={Styles.tableRowColumnConfirmTableStyle()}>
                                Run End
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.formatShortDateTime(originalRun.end_time)}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.formatShortDateTime(updatedRun.end_time)}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()} />
                        </TableRow>
                        <TableRow style={Styles.tableRowStyle(2)}>
                            <TableRowColumn className="bold" style={Styles.tableRowColumnConfirmTableStyle()}>
                                Line
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {originalRun.line_name}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {updatedRun.line_name}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()} />
                        </TableRow>
                        <TableRow style={Styles.tableRowStyle(3)}>
                            <TableRowColumn className="bold" style={Styles.tableRowColumnConfirmTableStyle()}>
                                SKU/Product
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnProductStyle()}>
                                {originalRun.product_name + ' - ' + originalRun.product_desc}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnProductStyle()}>
                                {updatedRun.product_name + ' - ' + updatedRun.product_desc}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()} />
                        </TableRow>
                        <TableRow style={Styles.tableRowStyle(4)}>
                            <TableRowColumn className="bold" style={Styles.tableRowColumnConfirmTableStyle()}>
                                Finished Goods
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {parseFloat(originalRun.finished_goods)}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {parseFloat(updatedRun.finished_goods)}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()} />
                        </TableRow>
                        {this.props.usesHeadcount && (
                            <TableRow style={Styles.tableRowStyle(5)}>
                                <TableRowColumn className="bold" style={Styles.tableRowColumnConfirmTableStyle()}>
                                    Labour
                                </TableRowColumn>
                                <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                    {Util.roundTo(parseFloat(originalRun.headcount), 2)}
                                </TableRowColumn>
                                <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                    {Util.roundTo(parseFloat(updatedRun.headcount), 2)}
                                </TableRowColumn>
                                <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        );
    };

    renderResultsChange = () => {
        let originalRun = this.props.original_run;
        let updatedRun = this.props.updated_run;
        return (
            <div className="confirm-run-changes-section margin-bottom-xl">
                <div className="font-size-bg margin-bottom-bg">Results Change</div>
                <Table selectable={false} multiSelectable={false}>
                    <TableHeader displaySelectAll={false} adjustForCheckbox={false} enableSelectAll={false}>
                        <TableRow style={{ height: '20px', paddingTop: '20px' }}>
                            <TableRowColumn style={Styles.tableHeaderStyle()} />
                            <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>
                                Current
                            </TableRowColumn>
                            <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>
                                Edits
                            </TableRowColumn>
                            <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>
                                Change
                            </TableRowColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        <TableRow style={Styles.tableRowStyle(0)}>
                            <TableRowColumn className="bold" style={Styles.tableRowColumnConfirmTableStyle()}>
                                OEE
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {parseFloat(originalRun.efficiency_percent).toFixed(1) + '%'}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {parseFloat(updatedRun.efficiency_percent).toFixed(1) + '%'}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {updatedRun.efficiency_percent - originalRun.efficiency_percent === 0
                                    ? 'No Change'
                                    : parseFloat(
                                          updatedRun.efficiency_percent - originalRun.efficiency_percent
                                      ).toFixed(1) + '%'}
                            </TableRowColumn>
                        </TableRow>
                        <TableRow style={Styles.tableRowStyle(1)}>
                            <TableRowColumn className="bold" style={Styles.tableRowColumnConfirmTableStyle()}>
                                Run Duration
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.formatHourMinuteSecondsFromSeconds(originalRun.run_duration_seconds)}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.formatHourMinuteSecondsFromSeconds(updatedRun.run_duration_seconds)}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {updatedRun.run_duration_seconds - originalRun.run_duration_seconds === 0
                                    ? 'No Change'
                                    : Util.formatHourMinuteSecondsFromSeconds(
                                          updatedRun.run_duration_seconds - originalRun.run_duration_seconds
                                      )}
                            </TableRowColumn>
                        </TableRow>
                        <TableRow style={Styles.tableRowStyle(2)}>
                            <TableRowColumn className="bold" style={Styles.tableRowColumnConfirmTableStyle()}>
                                Downtime
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.formatHourMinuteSecondsFromSeconds(originalRun.downtime_duration_seconds)}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.formatHourMinuteSecondsFromSeconds(updatedRun.downtime_duration_seconds)}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {updatedRun.downtime_duration_seconds - originalRun.downtime_duration_seconds === 0
                                    ? 'No Change'
                                    : Util.formatHourMinuteSecondsFromSeconds(
                                          updatedRun.downtime_duration_seconds - originalRun.downtime_duration_seconds
                                      )}
                            </TableRowColumn>
                        </TableRow>
                        <TableRow style={Styles.tableRowStyle(3)}>
                            <TableRowColumn className="bold" style={Styles.tableRowColumnConfirmTableStyle()}>
                                Output
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.numberWithCommasAndFractions(parseFloat(originalRun.output))}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.numberWithCommasAndFractions(parseFloat(updatedRun.output))}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {updatedRun.output - originalRun.output === 0
                                    ? 'No Change'
                                    : Util.numberWithCommasAndFractions(
                                          parseFloat(updatedRun.output) - parseFloat(originalRun.output)
                                      )}
                            </TableRowColumn>
                        </TableRow>
                        <TableRow style={Styles.tableRowStyle(4)}>
                            <TableRowColumn className="bold" style={Styles.tableRowColumnConfirmTableStyle()}>
                                Waste
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.numberWithCommasAndFractions(parseFloat(originalRun.waste))}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.numberWithCommasAndFractions(parseFloat(updatedRun.waste))}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {parseFloat(updatedRun.waste) - parseFloat(originalRun.waste) === 0
                                    ? 'No Change'
                                    : Util.numberWithCommasAndFractions(
                                          parseFloat(updatedRun.waste) - parseFloat(originalRun.waste)
                                      )}
                            </TableRowColumn>
                        </TableRow>
                        <TableRow style={Styles.tableRowStyle(5)}>
                            <TableRowColumn className="bold table-row" style={Styles.tableRowColumnConfirmTableStyle()}>
                                Rework
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.numberWithCommasAndFractions(parseFloat(originalRun.rework))}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {Util.numberWithCommasAndFractions(parseFloat(updatedRun.rework))}
                            </TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>
                                {parseFloat(updatedRun.rework) - parseFloat(originalRun.rework) === 0
                                    ? 'No Change'
                                    : Util.numberWithCommasAndFractions(
                                          parseFloat(updatedRun.rework) - parseFloat(originalRun.rework)
                                      )}
                            </TableRowColumn>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        );
    };

    showButtons = () => {
        if (this.props.submitting_edit) {
            return (
                <div className="edit-run-buttons__wrapper">
                    <CircularProgress />
                </div>
            );
        }
        return (
            <div className="edit-run-buttons__wrapper">
                <Button variant="text" color="primary" style={{ marginRight: '5px' }} onClick={this.props.cancel}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={this.props.update}>
                    Update Run
                </Button>
            </div>
        );
    };

    render() {
        if (!this.props.original_run) {
            return (
                <div className="container confirm-run-changes">
                    <CircularProgress />
                </div>
            );
        }
        return (
            <div className="container confirm-run-changes">
                {this.renderRunDetailsChangesSection()}
                {this.renderResultsChange()}
                {this.showButtons()}
            </div>
        );
    }
}

export default ConfirmRunEditModal;
