import React, {Component, Fragment} from 'react';
import {Button, Typography, DialogContent, DialogActions, CircularProgress, TextField} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

class AddEditFactoryUnit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.edit ? props.edit_unit.name : "",
            shorthand: props.edit ? props.edit_unit.nickname : "",
            name_error: null,
            shorthand_error: null,
            submitting_unit: false,
            error: null
        }
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */
    validateInput = () => {
        let error_free = true;

        this.setState({
            name_error: null,
            shorthand_error: null,
            error: null
        });

        if (this.state.name === '') {
            error_free = false;
            this.setState({
                name_error: "Name required"
            })
        }

        if (this.state.shorthand === '') {
            error_free = false;
            this.setState({
                shorthand_error: "Shorthand required"
            })
        }

        return error_free
    }

    handleChange (event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    saveUnit = () => {
        if(this.validateInput()) {
            this.setState({
                submitting_unit: true
            })

            let params;

            if(this.props.edit) {
                params = {
                    path: "edit_unit",
                    path_variables: {ID: this.props.edit_unit.id},
                    data: {
                        name: this.state.name,
                        nickname: this.state.shorthand
                    },
                    success: this.onAddEditUnitSuccess,
                    error: this.onAddEditUnitError
                }
            } else {
                params = {
                    path: "add_unit",
                    data: {
                        name: this.state.name,
                        nickname: this.state.shorthand
                    },
                    success: this.onAddEditUnitSuccess,
                    error: this.onAddEditUnitError
                }
            }

            API.c(params, 2)
        }
    }

    onAddEditUnitSuccess = (data) => {
        this.props.modified();
        this.props.hideModal();
    }

    onAddEditUnitError = (err) => {
        this.setState({
            submitting_unit: false,
            error: "There was an error saving!"
        })

        console.error(err)
    }

    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    renderButtons = () => {
        if (!this.state.submitting_unit) {
            return <DialogActions style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'baseline'}}>
                <Button variant="text"
                        color="primary"
                        onClick={this.props.hideModal}>Cancel</Button>
                <Button variant="contained"
                        color="primary"
                        onClick={this.saveUnit}>Save</Button>
            </DialogActions>
        } else {
            return <DialogActions style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'baseline'}}>
                <CircularProgress mode="indeterminate"/>
            </DialogActions>
        }
    }

    render() {
        return <Fragment>
            <DialogContent style={{overflowY: 'initial'}}>
                {this.state.error && <Alert severity="error" variant="outlined"><Typography variant="body2" color="error">{this.state.error}</Typography></Alert>}
                <div style={{display: "flex", alignItems: "baseline", justifyContent: "flex-start"}}>
                    <TextField
                        style={{width: "256px", marginRight: "8px"}}
                        id="name"
                        label="Name"
                        value={this.state.name}
                        onChange={this.handleChange.bind(this)}
                        helperText={this.state.name_error !== null ? this.state.name_error : ''}
                        error={this.state.name_error !== null}/>
                    <TextField
                        style={{width: "256px"}}
                        id="shorthand"
                        label="Shorthand"
                        value={this.state.shorthand}
                        onChange={this.handleChange.bind(this)}
                        helperText={this.state.shorthand_error !== null ? this.state.shorthand_error : ''}
                        error={this.state.shorthand_error !== null}/>
                </div>
            </DialogContent>
            {this.renderButtons()}
        </Fragment>
    }
}

export default AddEditFactoryUnit