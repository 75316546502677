import React, { Component } from 'react';

class CustomEfficiencyXAxisTick extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /* -------- LIFECYCLE METHODS -------- */

    /* -------- FUNCTIONS -------- */

    /* -------- API CALLS -------- */

    /* -------- RENDERS -------- */
    render() {
        let { x, y, amt } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={24}
                    dy={-4}
                    textAnchor={'middle'}
                    style={{ fontFamily: 'Roboto' }}
                    fill="#000"
                    fontSize="14px"
                >
                    Actual {localStorage.getItem("efficiency_string_short") !== 'undefined' ? localStorage.getItem("efficiency_string_short") : "OEE"}
                </text>
                <text
                    x={0}
                    y={56}
                    dy={-4}
                    textAnchor={'middle'}
                    style={{ fontFamily: 'Roboto' }}
                    fill="#000"
                    fontSize="24px"
                >
                    {amt + '%'}
                </text>
            </g>
        );
    }
}

export default CustomEfficiencyXAxisTick;
