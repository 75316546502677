import React, { Component } from 'react';

class CustomToolTip extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    static str_pad_left(string, pad, length) {
        return (new Array(length + 1).join(pad) + string).slice(-length);
    }

    showDowntime = () => {
        const { payload } = this.props;

        if (!payload) {
            return '';
        }

        let data = payload[0].payload;
        let hours = 0;
        let lostSeconds = Math.abs(data.time_lost_seconds);
        let minutes = Math.floor(lostSeconds / 60);
        if (minutes > 60) {
            hours = Math.floor(lostSeconds / 3600);
            minutes = Math.floor((lostSeconds - hours * 3600) / 60);
        }
        let seconds = Math.floor(lostSeconds - minutes * 60 - hours * 3600);

        let time = minutes + ' minutes ' + CustomToolTip.str_pad_left(seconds, '0', 2) + ' seconds';

        if (hours > 0) {
            time = hours + ' hours ' + minutes + ' minutes ' + CustomToolTip.str_pad_left(seconds, '0', 2) + ' seconds';
        }

        let negative = data.time_lost_seconds < 0 ? '-' : '';

        return `Downtime: ${negative + time}  (${Math.floor(data.time_lost * 100) / 100}%)`;
    };

    showReason = () => {
        const { payload } = this.props;

        if (!payload) {
            return '';
        }
        return payload[0].payload.reason;
    };

    render() {
        const { active } = this.props;

        if (active) {
            const { payload, label } = this.props;

            if (payload) {
                return (
                    <div style={{ cursor: 'pointer' }} className="tooltip">
                        <p className="tooltip__reason">{this.showReason()}</p>
                        <p className="tooltip__time-lost">{this.showDowntime()}</p>
                    </div>
                );
            } else {
                return null;
            }
        }

        return null;
    }
}

export default CustomToolTip;
