import React, { Component } from 'react';
import { Button, Snackbar, Typography, IconButton, CircularProgress, TextField, Select, Input, Box, InputLabel, FormControl } from "@material-ui/core";
import ManageLinesTable from "./ManageLinesTable";
import { Link, Redirect } from "react-router-dom";
import { Close } from '@material-ui/icons'
import { MenuItem } from 'material-ui';
import { Checkbox, Chip } from 'material-ui';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import {auth0UserManagementAPIInstance} from "../../../components/Auth0API"
import Util from '../../Util';

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.webAuth = Auth0;
        this.state = {
            productDetailsInfo: null,
            product_name: null,
            product_desc: null,
            product_category: null,
            product_target_weight: null,
            product_name_display: null,
            submitting_product: false,
            product_status: null,
            product_name_error: null,
            product_description_error: null,
            product_target_weight_error: null,
            modified: true,
            updated: false,
            snackbar_open: false,
            snackbar_message: null,
            units: null,
            product_units_calls: 0,
            product_on_line_units: [],
            line_default_units_calls: 0,
            line_default_units: [],
            redirect: false,
            allCategories: [],
            selectedTags: [],
            revealAllTags: false,
            tagsSelectOpen: false,
            tags: [],
            factory_users: [],
            lastEditData: {},
        }
    }

    /* -------- LIFECYCLE METHODS -------- */
    componentDidMount() {
        let org = this.defaultOrg(this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"]);
        this.setState({
            organizations: this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"],
            organization: org,
            factory_id: this.defaultFactoryId(),
            factory: JSON.parse(window.localStorage.getItem("factory")),
        })
        this.getOrgUsers(org);
        this.getLastEdit();
        this.getAllTags()
        this.getProduct(this.props.match.params.id)
        this.getAllCategories()
        this.getUnits()
        this.setState({
            user_role: JSON.parse(window.localStorage.getItem('factory')).role
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.factory !== this.props.factory) {
            this.setState({
                redirect: true
            })
        }
        // this.getLastEdit();
    }

    styles = {
        root: {
            background: "green"
        }
    }

    /* -------- FUNCTIONS -------- */

    getUserEmail = (id, is_system_generated) => {
        let user = "Not found";
        if (this.state.factory_users.length > 0) {
            let userFound = this.state.factory_users.find((i) => i.user_id === id)
            user = userFound ? userFound.email : is_system_generated ? "System" : "Not found"
        }
        return user
    }

    getLastEditUser = () => {
        if (this.state.factory_users && this.state.factory_users.length > 0) {
            const userData =  this.state.factory_users.filter((user) => {
                return user.user_id === this.state.lastEditData["Updated by"]
            })
            if (userData[0] && userData[0].user_metadata && (userData[0].user_metadata.first_name || userData[0].user_metadata.last_name)) {
                return `${userData[0].user_metadata.first_name} ${userData[0].user_metadata.last_name}`
            }
            else if (userData[0] && userData[0].name) {
                return userData[0].name
            }
            else if (userData[0] && userData[0].email) {
                return userData[0].email
            }
        }

        return "System User"
    }

    getLastEdit = () => {
        let lastEditParams = {
            path: 'get_last_edit_product',
            path_variables: { PRODUCT_ID: this.props.match.params.id },
            success: (data) => this.setState({lastEditData: data}),
            error: () => {}
        };

        API.c(lastEditParams, 2);
    };

    getOrgUsers = (org) => {
        auth0UserManagementAPIInstance.get(`auth0/user/search?organization_id=${org}&factory_id=${this.defaultFactoryId()}`)
            .then(res => {
                let org_users = this.filterOrgUsers(res.data, org)
                let factory_users = null
                if(this.state.factory_id) {
                    factory_users = this.filterFactoryUsers(org_users, this.state.factory_id)
                }
                const copy_factory_users = [...factory_users]
                let selected = copy_factory_users.sort((a, b) => a.email.split("@")[1].localeCompare(b.email.split("@")[1]))
                selected.forEach((item, i) => {
                    selected[i] = item.user_id
                })
                this.setState({
                    all_users: res.data,
                    org_users,
                    factory_users: factory_users.sort((a, b) => a.email.split("@")[1].localeCompare(b.email.split("@")[1])),
                    selected_users: selected
                })
            })
            .catch(err => {
                console.log(err)
                let error = err
                if(err && err.response && err.response.data) {
                    error = err.response.data.message
                }

                this.setState({
                    error
                })
            })
    }

    defaultOrg = (orgs) => {
        let defaultOrg = JSON.parse(window.localStorage.getItem("organization")).id

        return defaultOrg ? defaultOrg : orgs[0].id
    }

    defaultFactoryId = () => {
        let defaultFactory = JSON.parse(window.localStorage.getItem("factory"))

        return defaultFactory ? defaultFactory.id : null
    }

    filterOrgUsers = (users, org_id) => {
        let orgUsers = null;
        if(users && users[0]) {
            orgUsers = [];
            users.forEach(user => {
                let userOrgs = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    userOrgs = user.app_metadata.organizations;
                }

                if(userOrgs && userOrgs[0]) {
                    let userInThisOrg = userOrgs.find(org => org.id === org_id)
                    if(userInThisOrg) {
                        orgUsers.push(user)
                    }
                }
            })
        }

        return orgUsers
    }

    filterFactoryUsers = (users, factory_id) => {
        let factoryUsers = null;
        if(users && users[0]) {
            factoryUsers = [];
            users.forEach(user => {
                let userFactories = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    let userOrg = user.app_metadata.organizations.find(org => org.id === this.state.organization)
                    userFactories = userOrg ? userOrg.factories : null;
                }

                if(userFactories && userFactories[0]) {
                    let userInFactory = userFactories.find(factory => factory.id === factory_id)
                    if(userInFactory) {
                        factoryUsers.push(user)
                    }
                }
            })
        }

        return factoryUsers
    }

    handleChange(event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    handleTargetWeightChange = (event) => {
        this.setState({
            product_target_weight: event.target.value
        })
    }

    validateInputs = () => {
        let error_free = true;

        this.setState({
            product_name_error: null,
            product_description_error: null,
            product_target_weight_error: null
        });

        if (this.state.product_name === '') {
            error_free = false;
            this.setState({
                product_name_error: "Product Name required"
            })
        }

        if (this.state.product_description === '') {
            error_free = false;
            this.setState({
                product_description_error: "Product Description required"
            })
        }

        // if (this.state.product_target_weight === '' || this.state.product_target_weight === 0) {
        //     error_free = false;
        //     this.setState({
        //         product_target_weight_error: "Target Weight required"
        //     })
        // }

        return error_free;
    }

    handleSnackbarClose = () => {
        this.setState({
            snackbar_open: false,
            snackbar_message: null
        })
    }

    /* -------- API CALLS -------- */
    getProduct = (id) => {
        let params = {
            path: "product_details",
            path_variables: {
                PRODUCT_ID: id
            },
            success: this.productDetailsSuccess,
            error: this.productDetailsError
        };

        API.c(params);
    }

    getAllCategories = () => {
        let params = {
            path: 'all_categories',
            success: this.fetchSuccessCategories,
            error: () => { }
        };

        API.c(params);
    }

    fetchSuccessCategories = (data) => {
        let categories = [];
        data.forEach(d => {
            categories.push(d);
        });
        this.setState({
            allCategories: categories,
        });
    }

    getAllTags = () => {
        let params = {
            path: 'all_tags',
            success: (data) => this.setState({ tags: data }),
            error: this.fetchError
        };

        API.c(params);
    }

    productDetailsSuccess = (data) => {

        const tagList = []
        this.state.tags.forEach((item) => {
            const index = data.tag.indexOf(item.id)
            if (index > -1) {
                tagList.push(item.name)
            }
        })

        this.setState({
            productDetailsInfo: data,
            product_name: data.product_name,
            selectedTags: tagList,
            product_desc: data.product_desc,
            product_category: this.state.allCategories.find((category) => category.name === data.product_category) ? this.state.allCategories.find((category) => category.name === data.product_category).id : '',
            product_target_weight: data.product_target_weight,
            product_name_display: data.product_name + " - " + data.product_desc,
        })

        data.line_list.forEach(l => {
            if (l.product_is_active) {
                this.setState({
                    product_units_calls: this.state.product_units_calls + 1,
                    line_default_units_calls: this.state.line_default_units_calls + 1
                })
                this.getProductOnLineUnits(l.line_id);
                this.getLineDefaults(l.line_id)
            } else {
                this.setState({
                    line_default_units_calls: this.state.line_default_units_calls + 1
                })
                this.getLineDefaults(l.line_id)
            }
        })
    };

    productDetailsError = (error) => {
        this.setState({
            detailsError: error
        })
    }

    getProductOnLineUnits = (line_id) => {
        let params = {
            path: "get_product_on_line_units",
            path_variables: {
                LINE_ID: line_id,
                PRODUCT_ID: this.props.match.params.id
            },
            success: this.onGetProductOnLineUnitsSuccess.bind(this, line_id),
            error: this.onGetProductOnLineUnitsError
        }

        API.c(params, 2)
    }

    onGetProductOnLineUnitsSuccess = (line_id, data) => {
        let product_on_line_units = [...this.state.product_on_line_units];
        product_on_line_units.push({ line_id, units: data })
        this.setState({
            product_units_calls: this.state.product_units_calls - 1,
            product_on_line_units
        })
    }

    onGetProductOnLineUnitsError = (error) => {
        console.log(error)
    }

    getLineDefaults = (line_id) => {
        let params = {
            path: "get_line_details",
            path_variables: {
                LINE_ID: line_id
            },
            success: this.onLineInfoSuccess.bind(this, line_id),
            error: this.onLineInfoError
        };

        API.c(params);
    }

    onLineInfoSuccess = (line_id, data) => {
        let line_default_units = [...this.state.line_default_units];
        line_default_units.push({ line_id, units: data.line_info.default_unit_conversion })
        this.setState({
            line_default_units_calls: this.state.line_default_units_calls - 1,
            line_default_units
        })
    }

    onLineInfoError = (error) => {
        console.log(error)
    }


    getUnits = () => {
        let params = {
            path: "get_units",
            success: this.onGetFactoryUnitsSuccess,
            error: this.onGetFactoryUnitsError
        }

        API.c(params, 2)
    }

    onGetFactoryUnitsSuccess = (data) => {
        this.setState({
            units: data
        })
    }

    onGetFactoryUnitsError = (error) => {
        this.setState({
            units_error: error
        })
    }

    onRevealAllTags = () => {
        this.setState({
            revealAllTags: true
        })
    }

    submitProduct = () => {
        if (!this.validateInputs()) {
            return false;
        }


        this.setState({
            submitting_product: true,
            product_status: null,
            product_name_error: null,
            product_description_error: null,
            product_target_weight_error: null,
            modified: true,
            updated: false
        });

        let data = {
            name: this.state.product_name,
            desc: this.state.product_desc,
            category: this.state.product_category ? this.state.product_category : null
        }

        let params = {
            path: "update_product",
            path_variables: {
                PRODUCT_ID: this.props.match.params.id
            },
            data: data,
            success: this.submitSuccess,
            error: this.submitError
        }

        API.c(params, 2);
    }

    submitSuccess = (data) => {
        this.setState({
            submitting_product: false,
            updated: true,
            productDetailsInfo: null,
            product_category: null,
            product_name: null,
            product_desc: null,
            product_category: null,
            product_target_weight: null,
            product_name_display: null,
            product_units_calls: 0,
            product_on_line_units: []
        })

        this.getProduct(this.props.match.params.id)


        setTimeout(() => {
            this.getLastEdit();
        }, 1000)

        this.showUpdateSuccess();
    }

    submitError = (error) => {
        this.setState({
            submitting_product: false,
            submit_status: error
        })

        this.showUpdateError()
    }

    handleChangeTags = (event) => {
        this.setState({ selectedTags: typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value, revealAllTags: false, })
        let data = {
            name: this.state.product_name,
            desc: this.state.product_desc,
            target_weight: this.state.product_target_weight,
            product_tag_name_list: typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
        }

        let params = {
            path: "update_product",
            path_variables: {
                PRODUCT_ID: this.props.match.params.id
            },
            data: data,
            success: () => { setTimeout(() => {
                this.getLastEdit();
            }, 1000) },
            error: this.submitError
        }

        API.c(params, 2);
    }

    onRemoveTag = (value) => {
        const index = this.state.selectedTags.indexOf(value);
        const newSelectedTags = this.state.selectedTags
        if (index !== -1) {
            newSelectedTags.splice(index, 1);
        }
        this.setState({ selectedTags: newSelectedTags })

        let data = {
            name: this.state.product_name,
            desc: this.state.product_desc,
            target_weight: this.state.product_target_weight,
            product_tag_name_list: newSelectedTags
        }

        let params = {
            path: "update_product",
            path_variables: {
                PRODUCT_ID: this.props.match.params.id
            },
            data: data,
            success: () => { setTimeout(() => {
                this.getLastEdit();
            }, 1000) },
            error: this.submitError
        }

        API.c(params, 2);
    }

    /* -------- RENDERS -------- */

    iterateCategories = () => {
        if (this.state.allCategories == null || !Array.isArray(this.state.allCategories)) {
            return;
        }
        this.state.allCategories.sort((a, b) => {
            return parseInt(a.name) - parseInt(b.name)
        })
        return this.state.allCategories.map((item, index) => (
            <MenuItem value={item.id} key={'product-' + item.id}>
                {item.name}
            </MenuItem>
        ));
    };

    renderLastEditString = () => {
        if (this.state.lastEditData["Action"] === "Deleted") {
            return `${Object.keys(this.state.lastEditData["Previous values"])[0]} deleted by ${this.getLastEditUser()} on ${this.state.lastEditData["Date"]}`
        }
        else {
            return `${Object.keys(this.state.lastEditData["New values"])[0]} changed to ${Object.values(this.state.lastEditData["New values"])[0]} by ${this.getLastEditUser()} on ${this.state.lastEditData["Date"]}`
        }
    }

    productDetails = () => {
        let state = this.state;
        return <div onClick={() => { if (this.state.revealAllTags === true) { this.setState({ revealAllTags: false }) } }}>
            <Typography variant="h6">
                {this.state.product_name_display}
            </Typography>
            <div>
                <Typography variant="subtitle1">Product Details</Typography>
                <div className="edit-product__fields">
                    <div className="products__details_wrapper">
                        <TextField
                            id="product_name"
                            label="Code"
                            value={state.product_name}
                            onChange={this.handleChange.bind(this)}
                            error={this.state.product_name_error !== null}
                            helperText={this.state.product_name_error !== null ? this.state.product_name_error : ''}
                            fullWidth={true}
                        />
                    </div>
                    <div className="products__details_wrapper">
                        <TextField
                            id="product_desc"
                            label="Product Description"
                            value={state.product_desc}
                            onChange={this.handleChange.bind(this)}
                            error={this.state.product_description_error !== null}
                            helperText={this.state.product_description_error !== null ? this.state.product_description_error : ''}
                            fullWidth={true}
                        />
                    </div>
                    <div className="products__details_wrapper">
                        <TextField
                            select
                            label="Select Category"
                            fullWidth={true}
                            value={this.state.product_category}
                            onChange={(event) => this.setState({ product_category: event.target.value })}
                            style={{}}
                        >
                            {this.iterateCategories()}
                        </TextField>
                    </div>
                    <div className="products__details_wrapper">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <TextField
                                select
                                label={this.state.selectedTags.length > 0 ? "Tags" : "‎ "}
                                placeholder={"Select Tags"}
                                style={{ backgroundColor: "transparent", width: '200px', pointerEvents: "none", marginBottom: "0px" }}
                                SelectProps={{
                                    multiple: true,
                                    value: this.state.selectedTags,
                                    onChange: this.handleChangeTags,
                                    onClose: () => { this.setState({ tagsSelectOpen: false }) },
                                    open: this.state.tagsSelectOpen,
                                    IconComponent: 'qwe',
                                    displayEmpty: true,
                                    renderValue: (selected) => {
                                        if (this.state.selectedTags.length === 0) {
                                            return (<span style={{ opacity: 0.7 }}>Tags</span>)
                                        }
                                        else {
                                            return (
                                                <div style={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    backgroundColor: 'transparent !important',
                                                }}>


                                                    {this.state.selectedTags.map((value, i) => {
                                                        if (i < 3) {
                                                            return (
                                                                <Box sx={{ borderRadius: "12px", display: "flex", fontSize: "10px", fontWeight: "400", padding: "0px 8px", margin: `2px 4px`, background: "#E9E9E9" }}>
                                                                    <div style={{ marginRight: "6px" }}>{value.slice(0, 20)}</div>
                                                                    <div style={{ cursor: "pointer", pointerEvents: "auto", fontSize: "10px" }} onClick={() => this.onRemoveTag(value)}>✖</div>
                                                                </Box>
                                                            )
                                                        }
                                                        else if (i === 3) {
                                                            return (
                                                                <div style={{}}>
                                                                    <Box onClick={this.onRevealAllTags} sx={{ borderRadius: "12px", cursor: "pointer", pointerEvents: "auto", display: "flex", fontSize: "10px", fontWeight: "400", padding: "0px 8px", margin: `2px 4px`, background: "#E9E9E9" }}>
                                                                        <div style={{ marginRight: "0px" }}>{`${this.state.selectedTags.length - 3} more`}</div>
                                                                    </Box>
                                                                    {this.state.revealAllTags ? (
                                                                        <Box sx={{ maxWidth: "300px", flexWrap: "wrap", display: "flex", borderRadius: "12px", position: "absolute", border: "1px solid #E9E9E9", marginTop: "10px", padding: "10px", background: "#FFFFFF" }} >
                                                                            {this.state.selectedTags.map((tag, j) => {
                                                                                if (j >= 3) {
                                                                                    return (
                                                                                        <Box sx={{ borderRadius: "12px", zIndex: "9999", height: "20px", display: "flex", fontSize: "10px", fontWeight: "400", padding: "0px 8px", margin: `2px 4px`, background: "#E9E9E9" }}>
                                                                                            <div style={{ marginRight: "6px" }}>{tag.slice(0, 20)}</div>
                                                                                            <div style={{ cursor: "pointer", pointerEvents: "auto", fontSize: "10px" }} onClick={() => this.onRemoveTag(tag)}>✖</div>
                                                                                        </Box>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </Box>
                                                                    ) : null}
                                                                </div>
                                                            )
                                                        }
                                                    })}

                                                </div>
                                            )
                                        }
                                    }

                                }}
                            >
                                {this.state.tags.map((tag) => (
                                    <MenuItem
                                        key={tag}
                                        value={tag.name}
                                        style={this.state.selectedTags.includes(tag.name) ? { backgroundColor: "rgba(0, 0, 0, 0.066)", fontWeight: "600", fontSize: "14px" } : { fontSize: "14px" }}
                                    >
                                        {tag.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <ArrowDropDownIcon style={{ cursor: "pointer", marginTop: "15px" }}
                                onClick={() => { this.setState({ tagsSelectOpen: !this.state.tagsSelectOpen }); }} />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', position: "absolute", left: "20px", alignItems: 'center', justifyContent: 'flex-end' }}>
                    {this.state.lastEditData["Action"] ?  (
                        <p style={{ fontSize: "14px", fontWeight: "500", color: "#999999" }}>
                            {/* {`Last Edit: ${Object.keys(this.state.lastEditData["New values"])[0]}
                            changed to ${Object.keys(this.state.lastEditData["New values"])[0] === "Status" ? Object.values(this.state.lastEditData["New values"])[0] === true ? "Active" : "Inactive" : this.state.lastEditData["New values"]}
                            by ${this.getUserEmail(this.state.lastEditData["Updated by"], this.state.lastEditData["is_system_generated"])}
                            on ${Util.formatDateFull(this.state.lastEditData.datetime)} at ${Util.formatHourMinuteAmPm(this.state.lastEditData.datetime)}`} */}

                            {`Last Edit: ${this.renderLastEditString()}`}
                        </p>
                    ) : null}
                </div>
                <div style={{ display: 'flex', position: "absolute", right: "20px", width: 'calc(25% - 10px', alignItems: 'center', justifyContent: 'flex-end' }}>
                {this.state.user_role !== "scoreboard" && this.state.user_role !== "factory_read_only" && this.state.user_role !== "floor_team" && this.state.user_role !== "factory_supervisor" ? (
                    this.showUpdateButton()
                ) : null }
                </div>
            </div>
            <ManageLinesTable modified={this.modified}
                productId={this.props.match.params.id}
                line_list={this.state.productDetailsInfo.line_list}
                product_on_line_units={this.state.product_on_line_units}
                line_default_units={this.state.line_default_units}
                submitSuccess={this.submitSuccess}
                units={this.state.units} />
        </div >
    }

    showUpdateButton() {
        if (!this.state.submitting_product) {
            return <Button variant="contained"
                color="primary"
                onClick={this.submitProduct.bind(this)}>Update</Button>
        } else {
            return <CircularProgress mode="indeterminate" />
        }
    }

    showUpdateSuccess = () => {
        this.setState({
            snackbar_open: true,
            snackbar_message: 'Changes saved',
            severity: 'success'
        })
    }

    showUpdateError = () => {
        this.setState({
            snackbar_open: true,
            snackbar_message: 'There was an error saving changes',
            severity: 'error'
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: '/settings/products',
                state: { factory_change: true },
            }} />
        }

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        if (this.state.productDetailsInfo === null || (this.state.productDetailsInfo !== null && (this.state.product_units_calls > 0 || this.state.line_default_units_calls > 0))) {
            return <div className="products">
                <Button variant="text"
                    color="primary"
                    component={Link}
                    to={`/settings/products?lines=${params.lines}`}
                    style={{ marginLeft: '-16px' }}
                    fullWidth={false}>
                    Back to all products
                </Button>
                <div className="products__details">
                    <CircularProgress />
                </div>
            </div>
        }

        return <div className="products">
            <Button variant="text"
                color="primary"
                component={Link}
                to={`/settings/products?lines=${params.lines}`}
                style={{ marginLeft: '-16px' }}
                fullWidth={false}>
                Back to all products
            </Button>
            <div className="products__details">
                {this.productDetails()}
            </div>
            <Snackbar
                style={{ position: 'sticky' }}
                className={'snackbar'}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={this.state.snackbar_open}
                autoHideDuration={6000}
                message={this.state.snackbar_message}
                onClose={this.handleSnackbarClose}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                        <Close fontSize="small" />
                    </IconButton>
                }
            />
        </div>
    }
}

export default ProductDetail