import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WasteIcon(props) {
    return (
        <SvgIcon width="15" height="22" viewBox="0 0 15 22" fill="none">
            <path
                d="M13.7836 8.20117H0.780942C0.351424 8.20117 0 8.5526 0 8.98212C0 9.41163 0.351424 9.76306 0.780942 9.76306H1.24951L2.49901 19.8372C2.57711 20.6181 3.24091 21.2039 4.02185 21.2039H10.5427C11.3237 21.2039 11.9875 20.6181 12.0656 19.8372L13.3151 9.76306H13.7836C14.2131 9.76306 14.5646 9.41163 14.5646 8.98212C14.5646 8.51355 14.2131 8.20117 13.7836 8.20117ZM9.76178 16.1668C9.87892 16.2839 9.87892 16.4792 9.76178 16.5573L9.13702 17.182C9.01988 17.2991 8.82465 17.2991 8.74655 17.182L7.26276 15.6982L5.81802 17.182C5.70088 17.2991 5.50564 17.2991 5.42755 17.182L4.80279 16.5573C4.68565 16.4401 4.68565 16.2449 4.80279 16.1668L6.28658 14.683L4.80279 13.1992C4.68565 13.0821 4.68565 12.8868 4.80279 12.8087L5.42755 12.184C5.54469 12.0668 5.73992 12.0668 5.81802 12.184L7.30181 13.6678L8.7856 12.184C8.90274 12.0668 9.09797 12.0668 9.17607 12.184L9.80082 12.8087C9.91796 12.9259 9.91796 13.1211 9.80082 13.1992L8.31703 14.683L9.76178 16.1668Z"
                fill={props.color || 'black'}
            />
            <path
                d="M7.96582 4.10118L8.2782 6.09258C8.31724 6.20972 8.43439 6.32686 8.55153 6.28782L10.5429 5.97544C10.6601 5.93639 10.7772 5.81925 10.7382 5.70211L10.4258 3.71071C10.3867 3.59357 10.2696 3.47642 10.1525 3.51547L8.16106 3.82785C8.04391 3.82785 7.96582 3.94499 7.96582 4.10118Z"
                fill={props.color || 'black'}
            />
            <path
                d="M5.15473 6.75656C5.23282 6.8737 5.38901 6.8737 5.50615 6.75656L6.9509 5.46801C7.06804 5.38991 7.06804 5.23372 6.9509 5.11658L5.66234 3.67184C5.58425 3.5547 5.42806 3.5547 5.31092 3.67184L3.86618 4.96039C3.74903 5.03849 3.74903 5.19468 3.86618 5.31182L5.15473 6.75656Z"
                fill={props.color || 'black'}
            />
            <path
                d="M5.19302 2.11013L6.833 3.20344C6.95014 3.28154 7.10633 3.24249 7.18442 3.12535L8.27774 1.48537C8.35584 1.36823 8.31679 1.21204 8.19965 1.13395L6.55967 0.0406296C6.44253 -0.0374646 6.28634 0.00158127 6.20825 0.118723L5.11493 1.7587C5.07588 1.87584 5.11493 2.03203 5.19302 2.11013Z"
                fill={props.color || 'black'}
            />
        </SvgIcon>
    );
}
