import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function LiveViewIcon(props) {

    return <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M7.76 16.24C6.67 15.16 6 13.66 6 12C6 10.34 6.67 8.83999 7.76 7.75999L9.18 9.17999C8.45 9.89999 8 10.9 8 12C8 13.1 8.45 14.1 9.17 14.83L7.76 16.24ZM16.24 16.24C17.33 15.16 18 13.66 18 12C18 10.34 17.33 8.83999 16.24 7.75999L14.82 9.17999C15.55 9.89999 16 10.9 16 12C16 13.1 15.55 14.1 14.83 14.83L16.24 16.24ZM12 9.99999C10.9 9.99999 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 9.99999 12 9.99999ZM20 12C20 14.21 19.1 16.21 17.65 17.65L19.07 19.07C20.88 17.26 22 14.76 22 12C22 9.23999 20.88 6.73999 19.07 4.92999L17.65 6.34999C19.1 7.78999 20 9.78999 20 12ZM6.35 6.34999L4.93 4.92999C3.12 6.73999 2 9.23999 2 12C2 14.76 3.12 17.26 4.93 19.07L6.35 17.65C4.9 16.21 4 14.21 4 12C4 9.78999 4.9 7.78999 6.35 6.34999Z"
              fill={props.color || 'black'}/>
    </SvgIcon>

}