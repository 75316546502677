import React, {Component} from 'react';
import {Button, MenuItem, TextField} from "@material-ui/core";

class ChangeScoreboardViewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            grid_view: props.grid_view,
            showAllGreen: props.showAllGreen
        }
    }

    /* ######## LIFECYCLE METHODS ######## */
    componentDidMount = () => {
        // this.setKeyListener();
    }

    componentWillUnmount = () => {
        // this.cancelKeyListener();
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            if (event.keyCode === 13) {
                this.props.onGridSelected(this.state.grid_view, this.state.showAllGreen)
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }

    /* ######## FUNCTIONS ######## */
    handleSelectChange(event) {
        this.setState({
            grid_view: event.target.value,
        })
    }

    handleToggle = (event, isInputChecked) => {
        this.setState({
            showAllGreen: isInputChecked
        })
    }
    /* ######## API CALLS ######## */

    /* ######## RENDERS ######## */
    renderButtons = () => {
        return (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'baseline'}}>
                <Button variant="text"
                        color="primary"
                        onClick={this.props.hideModal}>Cancel</Button>
                <Button variant="contained"
                        color="primary"
                        onClick={() => {
                            this.props.onGridSelected(this.state.grid_view, this.state.showAllGreen)
                        }}
                        style={{marginRight: 12}}>Update Scoreboard</Button>
            </div>
        );
    }

    gridViewMenu = () => {
        return <TextField
            select
            label="Grid Type"
            value={this.state.grid_view}
            style={{width: '224px'}}
            onChange={this.handleSelectChange.bind(this)}>
            <MenuItem value={0}>1 Up</MenuItem>
            <MenuItem value={1}>2 Up</MenuItem>
            <MenuItem value={2}>3 Up</MenuItem>
            <MenuItem value={3}>4 Up</MenuItem>
            <MenuItem value={4}>8 Up</MenuItem>
        </TextField>
    }

    render() {
        return <div>
            {this.gridViewMenu()}
            {this.renderButtons()}
        </div>
    }
}

export default ChangeScoreboardViewModal