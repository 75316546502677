import React, { Component } from 'react';
import ConfirmRunEditModal from './ConfirmRunEditModal';
import { Button, Checkbox, CircularProgress, MenuItem, TextField } from '@material-ui/core';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    getDate,
    handleDateFromChange,
    handleDateToChange,
    handleTimeFromChange,
    handleTimeToChange
} from '../components/DateTimeHelperUtils';
import moment from 'moment';

class EditRun extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date_from: getDate(props.run.run_start_time),
            time_from: getDate(props.run.run_start_time),
            date_to: getDate(props.run.run_end_time),
            time_to: getDate(props.run.run_end_time),
            finished_goods: props.run.run_finished_goods_cases,
            finished_goods_error: null,
            headcount: Util.roundTo(props.run.headcount, 2),
            headcount_error: null,
            time_from_error: null,
            time_to_error: null,
            loading: false,
            selected_product: this.getSelectedProduct(),
            selected_line: this.getLineID(),
            products: props.products,
            line_products: props.line_products,
            lines: props.lines,
            submitting_edit: false,
            error: null,
            delete: false,
            delete_confirmed: false,
            delete_error: null,
            item_deleted_text: '',
            case_count: props.run.product_case_count,
            confirm: false,
            original_run: null,
            updated_run: null,
            old_prod: null,
            new_prod: null,
            product_error: null,
            changed: false
        };
    }

    /* ######## Lifecycle Methods ######## */
    componentDidMount = () => {
        this.setKeyListener();
    };

    componentWillUnmount = () => {
        this.cancelKeyListener();
    };

    setKeyListener = () => {
        window.onkeydown = event => {
            if (event.keyCode === 13) {
                if (!this.state.delete) {
                    this.confirmUpdateRun();
                }
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    };

    cancelKeyListener = () => {
        window.onkeydown = null;
    };

    /* ######## FUNCTIONS ######## */

    getLineID = () => {
        let selectedLine = 0;
        let props = this.props;
        this.props.lines.forEach(function (line) {
            if (line.name === props.run.line_name) {
                selectedLine = line.id;
            }
        });
        return selectedLine;
    };

    getSelectedProduct = () => {
        let { line_products, run } = this.props;
        let selectedProduct = line_products.find(
            product => product.product_name + product.product_desc === run.product_name + run.product_desc
        );

        return selectedProduct ? selectedProduct.product_id : null;
    };

    handleChange(event) {
        let s = this.state;
        let type = event.target.id;
        s.changed = true
        s[type] = event.target.value;
        this.setState(s);
    }

    handleSelectLineChange = event => {
        this.setState({ selected_line: event.target.value, changed: true });
        this.getLineProducts(event.target.value);
    };

    handleSelectProductChange = event => {
        this.setState({
            selected_product: event.target.value,
            product_error: null,
            changed: true
        });
    };

    handleDateFromChange = date => {
        let state = handleDateFromChange(date, this.state.time_from, this.state.time_to);
        this.setState(state);
        let newDateTime = state.date_from;
        this.setState({ changed: true })

        if (this.state.time_to.isSameOrBefore(newDateTime, 'minute')) {
            this.setState({
                time_from_error: 'End time error'
            });
        } else {
            this.setState({
                time_to_error: null,
                time_from_error: null,
            });
        }
    };

    handleDateToChange = date => {
        let state = handleDateToChange(date, this.state.time_to, this.state.date_from);
        this.setState(state);
        let newDateTime = state.date_to;
        this.setState({ changed: true })

        if (this.state.date_from.isSameOrAfter(newDateTime) || newDateTime.isAfter(moment())) {
            this.setState({
                time_to_error: 'End time error'
            });
        } else {
            this.setState({
                time_to_error: null,
            });
        }
    };

    handleTimeFromChange = time => {
        let state = handleTimeFromChange(time, this.state.time_to, this.state.date_from);
        this.setState(state);
        let newDateTime = state.date_from;
        this.setState({ changed: true })

        if (this.state.date_to.isSameOrBefore(newDateTime, 'minute')) {
            this.setState({
                time_to_error: 'End time error'
            });
        } else {
            this.setState({
                time_from_error: null
            });
        }

        if (this.state.date_to.isAfter(newDateTime)) {
            this.setState({
                time_to_error: null
            });
        }
    };

    handleTimeToChange = time => {
        let state = handleTimeToChange(time, this.state.time_from, this.state.date_to);
        this.setState(state);
        let newDateTime = state.date_to;
        this.setState({ changed: true })

        if (this.state.date_from.isSameOrAfter(newDateTime, 'minute') || newDateTime.isAfter(moment())) {
            this.setState({
                time_to_error: 'End time error'
            });
        } else {
            this.setState({
                time_to_error: null,
            });
        }
    };

    deleteRun = () => {
        this.setState({
            delete: true,
            item_deleted_text: 'this run',
            submitting_delete: false
        });

        this.props.changeModalTitle('Delete Run');
    };

    confirmUpdateRun = () => {
        if (this.validateInput()) {
            this.setState({
                confirm: true,
                submitting_edit: false,
                changed: false,
            });
            this.getRunConfirmationInfo();
            this.props.changeModalTitle('Confirm Run Edits');
        }
    };

    cancelUpdate = () => {
        this.setState({
            confirm: false
        });

        this.props.changeModalTitle('Edit Run');
    };

    changeDeleteConfirmation = (event, value) => {
        this.setState({
            delete_confirmed: value
        });
    };

    cancelDelete = () => {
        this.setState({
            delete: false
        });

        this.props.changeModalTitle('Edit Run');
    };

    validateInput = () => {
        let errorFree = true;
        this.setState({
            finished_goods_error: null,
            product_error: null
        });

        if (this.state.finished_goods < 0) {
            errorFree = false;
            this.setState({
                finished_goods_error: 'Should be positive number'
            });
        }

        if (this.state.headcount < 0) {
            errorFree = false;
            this.setState({
                headcount_error: 'Should be positive number'
            });
        }

        if (this.state.selected_product === null) {
            errorFree = false;
            this.setState({
                product_error: 'Please select a product'
            });
        }

        if (this.state.time_from_error) {
            errorFree = false;
        }

        if (this.state.time_to_error) {
            errorFree = false;
        }

        return errorFree;
    };

    /* ######## API CALLS ######## */

    getLineProducts = id => {
        let params = {
            path: 'line_products',
            path_variables: { ID: id },
            success: this.lineProductsFetchSuccess,
            error: this.lineProductsFetchError
        };

        API.c(params, 2);
    };

    lineProductsFetchSuccess = data => {
        let updated_product = data.find(product => product.product_id === this.state.selected_product);
        this.setState({
            line_products: data,
            selected_product: updated_product ? updated_product.product_id : null
        });
    };

    lineProductsFetchError = error => {
        console.log(error);
    };

    getRunConfirmationInfo = () => {
        let end_time;
        if (this.props.run.run_end_time != null) {
            end_time = this.state.date_to.toISOString();
        } else {
            end_time = null;
        }
        let old_prod = this.state.line_products.find(p => p.product_id === this.getSelectedProduct());
        let new_prod = this.state.line_products.find(p => p.product_id === this.state.selected_product);

        this.setState({
            submitting_edit: true,
            old_prod,
            new_prod
        });

        let data = {
            new_start_time: this.state.date_from.toISOString(),
            new_end_time: end_time,
            new_line_id: this.state.selected_line,
            new_product_id: this.state.selected_product,
            new_finished_goods_cases: this.state.finished_goods
        };

        if (this.props.usesHeadcount) {
            data['new_headcount'] = this.state.headcount;
        }

        let params = {
            path: 'check_run_update',
            path_variables: {
                RUN_ID: this.props.run_id
            },
            data: data,
            success: this.checkUpdateSuccess,
            error: this.checkUpdateError
        };

        API.c(params, 2);
    };

    checkUpdateSuccess = data => {
        this.setState({
            submitting_edit: false,
            original_run: data.current_run_info,
            updated_run: data.new_run_info
        });
    };

    checkUpdateError = error => {
        this.setState({
            check_error: error
        });
    };

    updateRun = () => {
        if (this.validateInput()) {
            let end_time;
            if (this.props.run.run_end_time != null) {
                end_time = this.state.date_to.toISOString();
            } else {
                end_time = null;
            }
            this.setState({
                submitting_edit: true
            });

            let data = {
                line: this.state.selected_line,
                start_time: this.state.date_from.toISOString(),
                end_time: end_time,
                product: this.state.selected_product,
                finished_goods:
                    this.state.finished_goods
            };

            if (this.props.usesHeadcount) {
                data['headcount'] = parseFloat(this.state.headcount);
            }

            let params = {
                path: 'update_run',
                path_variables: {
                    RUN_ID: this.props.run_id
                },
                data: data,
                success: this.updateRunSuccess,
                error: this.updateRunError
            };

            const formData = {
                start_date: moment(this.state.time_from).subtract(1, 'days').format('YYYY-MM-DD'),
                end_date: moment(this.state.date_to).add(1, 'days').format('YYYY-MM-DD')
            };

            let params2 = {
                path: 'update_shift_cache',
                data: formData,
                success: function (res) {
                    console.log('res', res);
                },
                error: function (err) {
                    console.log('err', err);
                }
            };

            API.c(params, 2);
            API.c(params2);
        }
    };

    updateRunSuccess = () => {
        this.props.modified();
        this.props.hideModal();
    };

    updateRunError = error => {
        this.setState({
            submitting_edit: false,
            received_data: false,
            error: error
        });
    };

    submitDelete = () => {
        this.setState({
            submitting_delete: true,
            delete_error: null
        });

        let params = {
            path: 'delete_run',
            path_variables: {
                ID: this.props.run_id
            },
            success: this.deleteSuccess,
            error: this.deleteError
        };

        API.c(params);
    };

    deleteSuccess = data => {
        this.setState({
            submitting_delete: false,
            delete_error: null
        });
        this.props.hideModalFromDelete();
    };

    deleteError = error => {
        this.setState({
            submitting_delete: false,
            delete_error: "Couldn't Complete The Delete Request!"
        });
    };

    deleteStatus() {
        if (this.state.delete_error) {
            return <div className="update-status__error">{this.state.delete_error}</div>;
        } else {
            return <div />;
        }
    }

    /* ######## RENDERS ######## */

    showLines = () => {
        if (this.state.lines == null) {
            return <div>Loading...</div>;
        }

        return (
            <div className="reports__options-item">
                <TextField
                    select
                    fullWidth={true}
                    label="Line"
                    value={this.state.selected_line}
                    onChange={this.handleSelectLineChange}
                >
                    {this.iterateLines()}
                </TextField>
            </div>
        );
    };

    iterateLines = () => {
        if (this.state.lines == null || this.state.lines.constructor != Array) {
            return;
        }

        return this.state.lines.map((item, index) => (
            <MenuItem value={item.id} key={'reports-line-' + index}>
                {item.name}
            </MenuItem>
        ));
    };

    disableFromFutureDates = date => {
        let date_to = this.state.date_to;
        return moment(date).isAfter(date_to, 'minute');
    };

    disableToFutureDates = date => {
        return moment(date).isAfter(moment(), 'minute');
    };

    fromDateTimePickers = () => {
        return (
            <div>
                <DatePicker
                    label="Start Date"
                    shouldDisableDate={this.disableFromFutureDates}
                    value={this.state.date_from}
                    format="yyyy-MMMM-DD"
                    onChange={this.handleDateFromChange}
                    className="reports__options-item_picker"
                />
                <TimePicker
                    id={'start-time-time-picker'}
                    onChange={this.handleTimeFromChange}
                    value={this.state.time_from}
                    label="Start Time"
                    error={this.state.time_from_error !== null}
                    helperText={this.state.time_from_error !== null ? this.state.time_from_error : ''}
                    className="reports__options-item_picker"
                />
            </div>
        );
    };

    toDateTimePickers = () => {
        if (this.props.run.run_end_time !== null) {
            return (
                <div>
                    <DatePicker
                        label="End Date"
                        minDate={this.state.date_from}
                        shouldDisableDate={this.disableToFutureDates.bind(this)}
                        value={this.state.date_to}
                        onChange={this.handleDateToChange}
                        format="yyyy-MMMM-DD"
                        className="reports__options-item_picker"
                    />
                    <TimePicker
                        id={'end-time-time-picker'}
                        onChange={this.handleTimeToChange}
                        value={this.state.time_to}
                        label="End Time"
                        error={this.state.time_to_error !== null}
                        helperText={this.state.time_to_error !== null ? this.state.time_to_error : ''}
                        className="reports__options-item_picker"
                    />
                </div>
            );
        }
    };

    showProducts = () => {
        if (this.state.line_products == null) {
            return <div>Loading...</div>;
        }

        return (
            <div className="reports__options-item--products">
                <TextField
                    select
                    fullWidth={true}
                    label="Product"
                    value={this.state.selected_product}
                    onChange={this.handleSelectProductChange}
                    error={this.state.product_error !== null}
                    helperText={this.state.product_error !== null ? this.state.product_error : ''}
                    style={{ width: '450px' }}
                >
                    {this.iterateProducts()}
                </TextField>
            </div>
        );
    };

    iterateProducts = () => {
        if (this.state.line_products == null || this.state.line_products.constructor !== Array) {
            return;
        }
        this.state.line_products.sort((a, b) => {
            return ('' + a.product_name).localeCompare(b.product_name)
        })
        return this.state.line_products.map((item, index) => (
            <MenuItem value={item.product_id} key={'product-' + item.product_id}>
                {item.product_name + ' - ' + item.product_desc}
            </MenuItem>
        ));
    };

    showFinishedGoods = () => {
        return (
            <div className="reports__options-item">
                <TextField
                    id="finished_goods"
                    label={'Finished Goods (' + this.props.run.unit_info.finished_goods.input_unit_name + ')'}
                    value={this.state.finished_goods}
                    onChange={this.handleChange.bind(this)}
                    error={this.state.finished_goods_error !== null}
                    helperText={this.state.finished_goods_error !== null ? this.state.finished_goods_error : ''}
                    style={{ width: '200px', marginBottom: '8px' }}
                    type="number"
                />
            </div>
        );
    };

    showHeadcount = () => {
        return (
            this.props.usesHeadcount && (
                <div className="reports__options-item">
                    <TextField
                        id="headcount"
                        label={'Labour'}
                        value={this.state.headcount}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.headcount_error !== null}
                        helperText={this.state.headcount_error !== null ? this.state.headcount_error : ''}
                        style={{ width: '200px', marginBottom: '8px' }}
                        type="number"
                    />
                </div>
            )
        );
    };

    showButtons = () => {
        if (this.state.chosen_batch !== '') {
            if (this.state.submitting_edit) {
                return (
                    <div className="edit-run-buttons__wrapper">
                        <CircularProgress />
                    </div>
                );
            }
            return (
                <div className="edit-run-buttons__wrapper">
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginRight: '5px', marginTop: "20px" }}
                        onClick={this.deleteRun}
                    >
                        Delete Run
                    </Button>
                    <Button variant="contained" color="primary" disabled={!this.state.changed} style={{ marginTop: "20px" }} onClick={this.confirmUpdateRun}>
                        Update Run
                    </Button>
                </div>
            );
        }
    };

    editStatus() {
        let { error } = this.state;
        if (error) {
            let e = 'Server Error: Please retry';

            if (error) {
                if (error.error) {
                    e = error.error;
                }

                if (error.detail) {
                    e = error.detail;
                }

                if (error.message) {
                    e = error.message;
                }
            }

            return <div className="update-status__error">{e}</div>;
        } else {
            return <div></div>;
        }
    }

    deleteText() {
        return (
            <div className="delete-user__text">
                Are you sure you want to delete {' ' + this.state.item_deleted_text}?
            </div>
        );
    }

    deleteCheckbox() {
        return (
            <div className="delete-user__checkbox">
                <div className="delete-user__checkbox-text">Confirm delete of {this.state.item_deleted_text}</div>

                <FormControlLabel
                    label={"Yes I'm sure, confirm delete."}
                    control={
                        <Checkbox
                            color="primary"
                            onChange={this.changeDeleteConfirmation}
                            checked={this.state.delete_confirmed}
                        />
                    }
                />
            </div>
        );
    }

    buttonStyle() {
        return {
            marginRight: '15px'
        };
    }

    deleteButtons() {
        if (this.state.submitting_delete === false) {
            return (
                <div className="delete-user__buttons">
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={!this.state.delete_confirmed}
                        onClick={this.submitDelete}
                        style={this.buttonStyle()}
                    >
                        Delete
                    </Button>
                    <Button variant="text" color="primary" onClick={this.cancelDelete}>
                        cancel
                    </Button>
                </div>
            );
        } else {
            return (
                <div className="add-user__buttons">
                    <CircularProgress mode="indeterminate" />
                </div>
            );
        }
    }

    render() {
        if (this.state.delete) {
            return (
                <div>
                    {this.deleteText()}
                    {this.deleteCheckbox()}
                    {this.deleteButtons()}
                    {this.deleteStatus()}
                </div>
            );
        }

        if (this.state.confirm) {
            return (
                <ConfirmRunEditModal
                    cancel={this.cancelUpdate}
                    update={this.updateRun}
                    new_prod={this.state.new_prod}
                    old_prod={this.state.old_prod}
                    submitting_edit={this.state.submitting_edit}
                    original_run={this.state.original_run}
                    updated_run={this.state.updated_run}
                    usesHeadcount={this.props.usesHeadcount}
                />
            );
        }

        return (
            <div style={{ height: "180px" }}>
                {this.showLines()}
                {this.showProducts()}
                {this.fromDateTimePickers()}
                {this.toDateTimePickers()}
                {/* {this.showFinishedGoods()} */}
                {this.showHeadcount()}
                {this.showButtons()}
                {this.editStatus()}
            </div>
        );
    }
}

export default EditRun;
