import React, { Component } from 'react';

class CustomBarCenterLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /* -------- LIFECYCLE METHODS -------- */

    /* -------- FUNCTIONS -------- */

    /* -------- API CALLS -------- */

    /* -------- RENDERS -------- */
    render() {
        const { x, y, width, height, value, viewBox, fill, index, data, active_lost_time_reason, isRun } = this.props;

        return (
            <g transform={`translate(${x + width / 2},${y + height / 2})`}>
                <text
                    style={{
                        fontFamily: 'Arial',
                        stroke: 'none',
                        opacity: !active_lost_time_reason || data[index].reason === active_lost_time_reason ? 1 : 0
                    }}
                    strokeWidth={8}
                    strokeLinejoin={'round'}
                    strokeLinecap={'butt'}
                    textAnchor={'middle'}
                    className="bg-text"
                    x={0}
                    y={0}
                    dy={4}
                >
                    {parseFloat(value).toFixed(1)} {isRun ? '' : '%'}
                </text>
                <text
                    style={{ fontFamily: 'Arial', display: 'none' }}
                    textAnchor={'middle'}
                    fill={'#fff'}
                    className="label"
                    x={0}
                    y={0}
                    dy={4}
                >
                    {parseFloat(value).toFixed(1)} {isRun ? '' : '%'}
                </text>
            </g>
        );
    }
}

export default CustomBarCenterLabel;
