import React, { Component } from 'react';

class Chrono extends Component {
    constructor(props) {
        super(props);

        this.state = {
            run_time: 0,
            time: null
        };
    }

    componentDidMount = () => {
        this.refreshInterval = setInterval(() => {
          this.setState({
            run_time: this.calcElapsed()
          });
        });
    };

    componentWillUnmount () {
        this.refreshInterval && clearInterval(this.refreshInterval);
        this.refreshInterval = false;
    }

    calcElapsed = () => {
        let v = this.props.down ? this.props.item.current_event_start_time : this.props.item.run_start_time;
        let start_time = new Date(v);
        let current_time = new Date();

        let run_time = current_time.getTime() - start_time.getTime();

        let diff = {};


        diff.milliseconds = current_time - start_time;
        // diff.days = (diff.milliseconds / 1000) / 60 / 60 / 24;
        diff.hours = (diff.milliseconds / 1000) / 60 / 60;
        diff.minutes = (diff.hours - Math.floor(diff.hours)) * 60;
        diff.seconds = (diff.minutes - Math.floor(diff.minutes)) * 60;

        let elapsed = "";


        if (Math.floor(diff.hours) > 0) {
            let hours = diff.hours;
            elapsed += Math.floor(hours) + ":";
        }

        if (Math.floor(diff.minutes) > 0) {
            let minutes = diff.minutes;
            if (Math.floor(minutes) < 10) {
                elapsed += "0";
            }
            elapsed += Math.floor(minutes) + ":";
        } else {
            elapsed += "00:";
        }

        if (Math.floor(diff.seconds) >= 0) {
            let seconds = diff.seconds;

            if (Math.floor(seconds) < 10) {
                elapsed += "0";
            }

            elapsed += Math.floor(seconds);
        }

        return elapsed;
    }

    render(){
        let {item, down} = this.props

        if(down) {
            return <div className={this.props.style}>
                {this.state.run_time}
            </div>
        }

        return <div className={this.props.style}>
            { item.line_status === 'notrunning' ? '- -' : this.state.run_time }
        </div>

    }
}

export default Chrono