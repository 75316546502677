import { DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Dialog } from 'material-ui';
import React, { useState } from 'react';

const MonthlyDateOfTheMonthModal = (props) => {

    const [newDateOfTheMonth, setNewDateOfTheMonth] = useState(props.monthlyDateOfTheMonth)

    const onDateOfTheMonthChange = (number) => {
        if ((!isNaN(number) || number === "") && number.length < 3) {
            if (parseInt(number) > 31) {
                setNewDateOfTheMonth("31")
            }
            else if (parseInt(number) === 0) {
                setNewDateOfTheMonth("1")
            }
            else {
                setNewDateOfTheMonth(number)
            }
        }
    }

    const onSaveDateOfTheMonth = () => {
        if (newDateOfTheMonth.length > 0) {
            props.onSave(newDateOfTheMonth)
        }
    }

    return (
        <Dialog
            open={true}
            onClose={props.onHide}
            bodyStyle={{ padding: "0px" }}
            style={{ width: "482px !important", maxWidth: "482px !important", left: "35%" }}
        >
            {/* <DialogTitle style={{ textAlign: "center", paddingTop: "0px", width: "100%" }} disableTypography>
                <Typography style={{ textTransform: "none" }} variant="subtitle2">Days to the compilation date</Typography>
            </DialogTitle> */}
            <div style={{ background: "#1975D0", color: "#FFFFFF", height: "32px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <p style={{ fontSize: "14px", fontWeight: "400", textAlign: "center" }}>The date of the month</p>
            </div>
            <DialogContent>
                <div style={{ display: "flex" , justifyContent: "center", marginTop: "28px"}}>
                    <input style={{ padding: "10px 16px", width: "328px", height: "18px", border: "1px solid #BDBDBD", borderRadius: "4px", background: "#E0E0E0" }} onChange={(e) => onDateOfTheMonthChange(e.target.value)} value={newDateOfTheMonth} />
                </div>
                <div style={{ display: "flex", justifyContent: "end", margin: "28px 8px 24px 0px"}}>
                    <div onClick={props.onHide} style={{cursor: "pointer", marginRight: "20px", display: "flex", justifyContent: "center", alignItems: "center", color: "#1975D0", fontWeight: "500", fontSize: "14px"}}>
                        CANCEL
                    </div>
                    <div onClick={onSaveDateOfTheMonth} style={{cursor: "pointer", marginLeft: "20px", display: "flex", justifyContent: "center", alignItems: "center", color: "#1975D0", fontWeight: "500", fontSize: "14px"}}>
                        OK
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default MonthlyDateOfTheMonthModal