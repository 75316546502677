import React, { useEffect, useState } from 'react';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Bar, BarChart, Cell, ReferenceLine, XAxis, YAxis, Label } from 'recharts';
import CustomValueLabel from '../RechartCustomComponents/CustomValueLabel';
import CustomEfficiencyReferenceLineLabel from '../RechartCustomComponents/CustomEfficiencyReferenceLineLabel';
import CustomEfficiencyXAxisTick from '../RechartCustomComponents/CustomEfficiencyXAxisTick';

export default function ReportEfficiencyChart(props) {
    const classes = useStyles();
    let { report } = props;
    const [factoryData, setFactoryData] = useState()

    const getFactorySettings = () => {
        let params = {
            path: 'get_factory_settings',
            success: (data) => setFactoryData(data),
            error: () => {}
        };

        API.c(params, 2);
    };

    useEffect(() => {
        getFactorySettings()
    }, [])

    let efficiency_percent = report.efficiency_percent;
    let amt = parseFloat(efficiency_percent).toFixed(1);
    let total;

    if (efficiency_percent >= 100) {
        total = 0;
    } else if (efficiency_percent >= 0) {
        total = 100 - amt;
    } else if (efficiency_percent > -100) {
        total = -100 - amt;
    } else {
        total = 0;
    }

    if (report.efficiency_percent == null) {
        amt = 'N/A';
    }

    let data = [{ label: 'Active', amt: amt, total: total }];

    let width = 150;
    let height = 300;

    let target = report.efficiency_target ? report.efficiency_target : -1;
    let targetLabel = target + '%';

    return (
        <div className={classes.card}>
            <Typography variant={'subtitle2'} align={'left'} className={classes.title}>
                {localStorage.getItem("efficiency_string_long") !== 'undefined' ? localStorage.getItem("efficiency_string_long") : "Efficiency"}
            </Typography>
            <Divider />
            <div className={classes.bar_chart_div}>
                <BarChart width={width} height={height} data={data} isAnimationActive={false}>
                    <defs>
                        <linearGradient id="colorGrey" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#eeeeee" stopOpacity={0.95} />
                            <stop offset="95%" stopColor="#eeeeee" stopOpacity={0.95} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" tickLine={false} tick={<CustomEfficiencyXAxisTick data={factoryData} amt={amt} />} />
                    <YAxis tick={<CustomValueLabel anchor="end" textX={-2} textDy={4} />} />
                    <Bar dataKey="amt" stackId="a" legendType={'none'} label={'Efficiency'} isAnimationActive={false}>
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={data[index].amt > 0 ? '#1F9D00' : '#cb2d3e'} />
                        ))}
                    </Bar>
                    <Bar dataKey="total" stackId="a" isAnimationActive={false}>
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill="url(#colorGrey)" />
                        ))}
                    </Bar>
                    {target > 0 && <ReferenceLine
                        y={target}
                        stroke="#2967c1"
                        strokeDasharray="10 5"
                        strokeWidth={2}
                        ifOverflow="extendDomain"
                        className={'reference-line'}
                    >
                        <Label
                            content={<CustomEfficiencyReferenceLineLabel data={factoryData} targetLabel={targetLabel} />}
                            position={'right'}
                        />
                    </ReferenceLine>}
                </BarChart>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        card: {
            margin: '20px 20px 40px 20px',
            boxSizing: 'border-box',
            background: '#fff',
            boxShadow: '0px 0px 8px rgba(0,0,0,0.12)',
            display: 'flex',
            flexDirection: 'column',
            flexBasis: '290px',
            flexGrow: 0,
            flexShrink: 0
        },
        title: {
            margin: '20px 30px'
        },
        bar_chart_div: {
            margin: '70px 30px'
        }
    })
);
