import React, {Component} from 'react';
import {Button, Checkbox, CircularProgress, FormControlLabel} from "@material-ui/core";

class SelectUsernameModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_users: props.selected_users,
            select_button_text: "Select None"
        }
    }

    componentDidMount() {
        this.setKeyListener();
        if(this.props.selected_users.length === this.props.users.length) {
            this.setState({
                select_button_text: "Select None"
            })
        } else {
            this.setState({
                select_button_text: "Select All"
            })
        }
    }

    componentWillUnmount() {
        this.cancelKeyListener()
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            event.preventDefault()
            if (event.key === 'Enter') {
                if(this.state.selected_users[0]) {
                    this.hideSelectUsers()
                }
            } else if(event.key === "Escape") {
                this.props.cancelSelectUsers()
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }

    handleAllUsersClick = () => {
        let updatedSelectedUsers = this.state.selected_users
        let select_button_text
        if(updatedSelectedUsers.length === this.props.users.length) {
            updatedSelectedUsers = []
            select_button_text = "Select All"
        } else {
            updatedSelectedUsers.length = 0;
            this.props.users.forEach(user => {
                updatedSelectedUsers.push(user.user_id)
            })
            select_button_text = "Select None"
        }
        this.setState({
            selected_users: updatedSelectedUsers,
            select_button_text
        })
    }

    onUserCheck = (index, event, isInputChecked) => {
        let updatedSelectedUsers = Array.from(this.state.selected_users);
        if(!isInputChecked) {
            let i = updatedSelectedUsers.indexOf(this.props.users[index].user_id);
            if (i !== -1) updatedSelectedUsers.splice(i, 1);
        } else {
            updatedSelectedUsers.push(this.props.users[index].user_id)
        }
        let select_button_text;
        if(updatedSelectedUsers.length === this.props.users) {
            select_button_text = "Select None"
        } else {
            select_button_text = "Select All"
        }
        this.setState({
            selected_users: updatedSelectedUsers,
            select_button_text
        })
    }

    hideSelectUsers = () => {
        if(this.state.selected_users[0]) {
            this.props.hideSelectUsers(this.state.selected_users)
        }
    }

    renderProducts = () => {
        if(!this.props.users[0]) {
            return <div className="reports__loading">
                <CircularProgress />
            </div>
        }

        return this.props.users.map((user, index) => {
            let displayName = user.email
            if (user.user_metadata.first_name && user.user_metadata.first_name.length > 0) {
                displayName = `${user.user_metadata.first_name} ${user.user_metadata.last_name} (${user.email})`
            }

            return <FormControlLabel className="select-lines-body-item"
                                     control={<Checkbox checked={this.state.selected_users.indexOf(user.user_id) > -1}
                                                        color={"primary"}
                                                        onChange={this.onUserCheck.bind(this, index)}
                                                        />}
                                     label={displayName}
                                     key={"lines-"+user.user_id} />
        })
    }


    render() {
        return <div>
            <div
                className="select-lines"
                onClick={this.hideSelectUsers}
            />
            <div className="select-lines-container" style={this.props.style}>
                <div className="select-lines-header">
                    <div style={{flex: 1, marginLeft: "24px"}}>
                        <div className="select-lines-header-button modal__content-title__text">Username Filter</div>
                    </div>
                    <Button variant="text"
                            color="primary"
                            className="select-lines-header-button"
                            onClick={this.handleAllUsersClick}>{this.state.select_button_text}</Button>
                    <Button variant="contained"
                            color="primary"
                            className="select-lines-header-button"
                            onClick={this.hideSelectUsers}
                            disabled={!this.state.selected_users[0]}>Filter</Button>
                </div>
                <div className="select-lines-body">
                    {this.renderProducts()}
                </div>

            </div>
        </div>
    }
}

export default SelectUsernameModal