import React, {Component} from 'react';
import {
    AutoComplete,
    Table,
    TableBody,
    TableHeader,
    TableRow,
    TableRowColumn,
} from "material-ui";
import {
    CircularProgress,
    MenuItem,
    TextField,
    Checkbox
} from "@material-ui/core"

class BulkEditProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_active: '',
            target_speed: '',
            number_of_lanes: '',
            category: '',
            description: ''
        }
    }

    /* -------- LIFECYCLE METHODS -------- */
    componentDidMount() {
    }

    /* -------- FUNCTIONS -------- */
    handleChange (event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);

        this.props.onBulkEditFieldChange(type, event.target.value)
    }

    handleStatusChange = (event) => {
        this.setState({
            is_active: event.target.value
        });

        this.props.onBulkEditFieldChange('is_active', event.target.value)
    }

    onCategoryChange = (category) => {
        this.setState({
            category
        })

        this.props.onBulkEditFieldChange('category', category)
    }

    onUpdateInput = (category) => {
        this.setState({
            category
        })

        this.props.onBulkEditFieldChange('category', category)
    }
    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    renderFields = () => {
        let {is_active, category, description, number_of_lanes, target_speed} = this.state;

        return <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <TextField label={"Status"}
                         style={{width: '184px', marginRight: '8px'}}
                         style={{opacity: is_active === '' ? 0.37 : 1}}
                         onChange={this.handleStatusChange}
                         value={is_active}>
                <MenuItem value={''} style={{opacity: 0.5}}>(No Change!)</MenuItem>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
            <TextField id="target_speed"
                       label="Target Speed"
                       placeholder="(No Change!)"
                       style={{width: '184px', marginRight: '8px'}}
                       value={target_speed}
                       onChange={this.handleChange.bind(this)}/>
            <TextField id="number_of_lanes"
                       label="Number of Lanes"
                       placeholder="(No Change!)"
                       style={{width: '184px', marginRight: '8px'}}
                       value={number_of_lanes}
                       onChange={this.handleChange.bind(this)}/>
            <AutoComplete
                openOnFocus
                id="category"
                searchText={this.state.category}
                floatingLabelText="Category"
                floatingLabelFixed
                hintText="(No Change!)"
                style={{width: '184px', marginRight: '8px'}}
                filter={AutoComplete.caseInsensitiveFilter}
                onNewRequest={this.onCategoryChange}
                onUpdateInput={this.onUpdateInput}
                dataSource={this.props.categories}/>
            <TextField id="description"
                       label="Description"
                       placeholder="(No Change!)"
                       style={{width: '184px', marginRight: '8px'}}
                       value={description}
                       onChange={this.handleChange.bind(this)}/>
        </div>
    }

    renderConfirm = () => {
        return <div className="confirm-run-changes-section margin-bottom-xl">
            <div className="font-size-bg margin-bottom-bg" style={{marginTop: '8px', color: '#000'}}>New Values</div>
            <Table selectable={false}
                   multiSelectable={false}>
                <TableHeader displaySelectAll={false}
                             adjustForCheckbox={false}
                             enableSelectAll={false}>
                    <TableRow  style={{height: '20px', paddingTop: '20px'}}>
                        <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>{"Status"}</TableRowColumn>
                        <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>{"Target Speed"}</TableRowColumn>
                        <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>{"No. of Lanes"}</TableRowColumn>
                        <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>{"Category"}</TableRowColumn>
                        <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>{"Description"}</TableRowColumn>
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false}>
                    <TableRow  style={Styles.tableRowStyle(0)}>
                        <TableRowColumn className="bold" style={Styles.tableRowColumnStyle()}>
                            {
                                this.state.is_active === '' ?
                                    "No Change" :
                                    this.state.is_active === true ?
                                        "Active" :
                                        "Inactive"
                            }
                        </TableRowColumn>
                        <TableRowColumn className="bold" style={Styles.tableRowColumnStyle()}>
                            {
                                this.state.target_speed.trim() === "" ?
                                    "No Change" :
                                    parseFloat(this.state.target_speed).toFixed(2)
                            }
                        </TableRowColumn>
                        <TableRowColumn className="bold" style={Styles.tableRowColumnStyle()}>
                            {
                                this.state.number_of_lanes.trim() === "" ?
                                    "No Change" :
                                    parseFloat(this.state.number_of_lanes).toFixed(2)
                            }
                        </TableRowColumn>
                        <TableRowColumn className="bold" style={Styles.tableRowColumnStyle()}>
                            {
                                this.state.category.trim() === "" ?
                                    "No Change" :
                                    this.state.category
                            }
                        </TableRowColumn>
                        <TableRowColumn className="bold" style={Styles.tableRowColumnStyle()}>
                            {
                                this.state.description.trim() === "" ?
                                    "No Change" :
                                    this.state.description
                            }
                        </TableRowColumn>
                    </TableRow>
                </TableBody>
            </Table>
            <div className="font-size-bg margin-bottom-bg" style={{marginTop: '8px', color: '#000'}}>Products Changing</div>
            <Table selectable={false}
                   multiSelectable={false}>
                <TableHeader displaySelectAll={false}
                             adjustForCheckbox={false}
                             enableSelectAll={false}>
                    <TableRow  style={{height: '20px', paddingTop: '20px'}}>
                        <TableRowColumn style={Styles.tableHeaderStyleShort()}>Change</TableRowColumn>
                        {/*<TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>{"Status"}</TableRowColumn>*/}
                        {/*<TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>{"Target Speed"}</TableRowColumn>*/}
                        {/*<TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>{"No. of Lanes"}</TableRowColumn>*/}
                        <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>Current Category</TableRowColumn>
                        <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>Current Code</TableRowColumn>
                        <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>Current Description</TableRowColumn>
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false}>
                    {this.props.selected_products.map((p, i) => {
                        return <TableRow key={"bulk-edit-product-"+p.id} style={Styles.tableRowStyle(i)}>
                            <TableRowColumn style={Styles.tableRowColumnStyleShort()}>
                                <Checkbox checked={this.props.confirmed_products.includes(p.id)}
                                          onChange={this.props.onConfirmProductClick.bind(this, p.id)}/>
                            </TableRowColumn>
                            {/*<TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>{this.renderLineItem(p.id, "is_active")}</TableRowColumn>*/}
                            {/*<TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>{this.renderLineItem(p.id, "target_speed")}</TableRowColumn>*/}
                            {/*<TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>{this.renderLineItem(p.id, "product_multiplier")}</TableRowColumn>*/}
                            <TableRowColumn style={Styles.tableRowColumnStyle()}>{p.category}</TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnStyle()}>{p.name}</TableRowColumn>
                            <TableRowColumn style={Styles.tableRowColumnStyle()}>{p.desc}</TableRowColumn>
                        </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </div>
    }

    renderProgress = () => {
        let startCalls = this.props.start_calls;
        let currentCalls = this.props.calls;
        let progress = ((startCalls - currentCalls) / startCalls) * 100

        return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '304px', width: '100%'}}>
            <CircularProgress size={80}
                              thickness={5}/>
            <div style={{fontSize: '16px', color: '#000', marginBottom: '8px', marginTop: '8px'}}>{parseInt(progress) + "%"}</div>
            <div style={{fontSize: '16px', color: '#000'}}>Don't close or refresh the page</div>
        </div>
    }

    render() {
        if(this.props.submitting_bulk_edit) {
            return this.renderProgress()
        } else if(this.props.confirm) {
            return this.renderConfirm()
        } else {
            return this.renderFields()
        }
    }
}

export default BulkEditProducts