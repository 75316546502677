import React, {Component} from 'react';

class LegendItem extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }


    render() {
        let {style, content} = this.props;

        let className = "legend-icon"

        return <div className='legend-item'>
            <div className={className}>
                <svg height="16" width="24">
                    <line x1="0" y1="8" x2="24" y2="8" style={style} />
                </svg>
            </div>
            <div>{content}</div>
        </div>
    }
}

export default LegendItem