import React, { useEffect, useState } from 'react';
import { Typography, Divider, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Chrono from './Chrono';
import { Speed, Timelapse, Add } from '@material-ui/icons';
import OeeIcon from './icons/OeeIcon';

export default function LiveViewLineInfoSection(props) {
    const classes = useStyles();

    function onStartRunClicked() {
        props.onStartRunClick(props.line);
    }

    useEffect(() => {
    }, [])

    let headerClass = line => {
        let t = 0;

        switch (line.line_status) {
            case 'down':
                if (line.current_event_status !== 'Short Stop') {
                    t = 0;
                } else {
                    if (
                        parseFloat(line.last_speed_pieces_per_minute) <=
                        parseFloat(line.target_speed_pieces_per_minute) * 0.97
                    ) {
                        t = 1;
                    } else {
                        t = 2;
                    }
                }
                break;
            case 'notrunning':
                t = 3;
                break;
            case 'slow':
            case 'normal':
                if (
                    parseFloat(line.last_speed_pieces_per_minute) <=
                    parseFloat(line.target_speed_pieces_per_minute) * 0.97
                ) {
                    t = 1;
                } else {
                    t = 2;
                }
                break;
        }

        let options = [classes.status_down, classes.status_slow, classes.status_up, classes.status_offline];

        return options[t];
    };

    if (props.line.line_status === 'notrunning') {
        return (
            <div className={classes.wrapper}>
                <div className={classes.status_offline}>
                    <Typography variant="h6">{props.line.line_name}</Typography>
                    <Typography variant="subtitle2">{'\u00A0'}</Typography>
                </div>
                <div className={classes.info_wrapper}>
                    <div className={classes.info_row}>
                        <Typography variant="subtitle2" className={classes.label}>
                            No Active Run
                        </Typography>
                    </div>
                    <div className={classes.info_row}>
                        <div className={classes.buttonDiv}>
                            {/*<Button*/}
                            {/*    variant={"contained"}*/}
                            {/*    color={"primary"}*/}
                            {/*    startIcon={<Add />}*/}
                            {/*    onClick={onStartRunClicked}>*/}
                            {/*    Start a run*/}
                            {/*</Button>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.wrapper}>
            <div className={headerClass(props.line)}>
                <Typography variant="h6">{props.line.line_name}</Typography>
                <Typography variant="subtitle2">
                    {props.line.product_description ? props.line.product_description : '\u00A0'}
                </Typography>
            </div>
            <div className={classes.info_wrapper}>
                <div className={classes.info_row}>
                    <div className={classes.speed_row}>
                        <Speed className={classes.marginRight} />
                        <Typography className={classes.marginRight} variant="h5">
                            {props.line.last_speed_pieces_per_minute
                                ? parseFloat(props.line.last_speed_pieces_per_minute).toFixed(1)
                                : 'N/A'}
                        </Typography>
                        <Typography variant="body2" className={classes.label_black}>
                            {props.line.last_speed_pieces_per_minute
                                ? props.line.unit_info.speed.display_unit_name
                                : '\u00A0'}
                        </Typography>
                    </div>
                    <div className={classes.right_column}>
                        <Typography variant="body2" className={classes.charcoal_text}>
                            {props.line.line_status === 'down' && props.line.current_event_status !== 'Short Stop'
                                ? 'Status'
                                : 'Target Speed'}
                        </Typography>
                        <Typography variant="body1" component="div" className={classes.charcoal_text}>
                            {props.line.line_status === 'down' && props.line.current_event_status !== 'Short Stop'
                                ? props.line.current_event_status > ''
                                    ? props.line.current_event_status
                                    : 'N/A'
                                : props.line.target_speed_pieces_per_minute
                                ? props.line.target_speed_pieces_per_minute +
                                  ' ' +
                                  props.line.unit_info.speed.display_unit_name
                                : 'N/A'}
                        </Typography>
                    </div>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.info_row}>
                    <div className={classes.time_row}>
                        <Timelapse className={classes.marginRight} />
                        <div>
                            <Typography variant="body2" className={classes.label_black}>
                                Run Time
                            </Typography>
                            <Chrono item={props.line} style="line-item__grid-item__status-label__chrono" />
                        </div>
                    </div>
                    <div className={classes.right_column}>
                        <Typography variant="body2" className={classes.charcoal_text}>
                            Start Time
                        </Typography>
                        <Typography variant="body1" className={classes.charcoal_text}>
                            {props.line.run_start_time ? Util.formatTime(props.line.run_start_time) : 'N/A'}
                        </Typography>
                    </div>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.info_row}>
                    <div className={classes.time_row}>
                        <div className={classes.marginRight}>
                            <OeeIcon />
                        </div>
                        <div>
                            <Typography variant="body2" className={classes.label_black}>
                                {localStorage.getItem("efficiency_string_long") !== 'undefined' ? localStorage.getItem("efficiency_string_long") : 'Efficiency'}
                            </Typography>
                            <Typography variant="h5">
                                {parseFloat(props.line.daily_efficiency).toFixed(1) + '%'}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.right_column}>
                        <Typography variant="body2" className={classes.charcoal_text}>
                            Target {localStorage.getItem("efficiency_string_long") !== 'undefined' ? localStorage.getItem("efficiency_string_long") : 'Efficiency'}
                        </Typography>
                        <Typography variant="body1" className={classes.charcoal_text}>
                            {parseFloat(props.line.line_target_efficiency).toFixed(1) + '%'}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            minHeight: '320px',
            width: '390px'
        },
        label: {
            fontWeight: 700,
            color: '#999'
        },
        label_black: {
            fontWeight: 700,
            color: '#000'
        },
        charcoal_text: {
            color: '#666'
        },
        status_down: {
            flex: 1,
            padding: '15px 30px',
            background: theme.palette.error.main,
            textDecoration: 'none',
            color: '#fff'
        },
        status_up: {
            flex: 1,
            padding: '15px 30px',
            background: theme.palette.success.main,
            textDecoration: 'none',
            color: '#fff'
        },
        status_slow: {
            flex: 1,
            padding: '15px 30px',
            background: theme.palette.warning.main,
            textDecoration: 'none',
            color: '#000'
        },
        status_offline: {
            flex: 1,
            padding: '15px 30px',
            background: '#bbb',
            textDecoration: 'none',
            color: '#fff'
        },
        info_wrapper: {
            flex: 2,
            background: '#fff',
            color: theme.palette.text.primary,
            display: 'flex',
            flexDirection: 'column'
        },
        info_column: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
            justifyContent: 'space-between'
        },
        info_row: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            height: '100%',
            padding: '15px 30px',
            justifyContent: 'space-between'
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            height: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        speed_row: {
            display: 'flex',
            flexDirection: 'row',
            flex: 3,
            height: '100%',
            marginTop: '8px',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        time_row: {
            display: 'flex',
            flexDirection: 'row',
            flex: 3,
            height: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-end'
        },
        right_column: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flex: 2
        },
        buttonDiv: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flex: 2
        },
        marginRight: {
            marginRight: 10
        },
        divider: {
            margin: '0 30px'
        },
        icon_offline: {
            color: '#999'
        }
    })
);
