import React, {Component} from 'react';
import {Button, Checkbox, CircularProgress, FormControlLabel} from "@material-ui/core";

class SelectPageModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_pages: props.selected_pages,
            select_button_text: "Select None"
        }
    }

    componentDidMount() {
        this.setKeyListener();
        if(this.props.selected_pages.length === this.props.pages.length) {
            this.setState({
                select_button_text: "Select None"
            })
        } else {
            this.setState({
                select_button_text: "Select All"
            })
        }
    }

    componentWillUnmount() {
        this.cancelKeyListener()
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            event.preventDefault()
            if (event.key === 'Enter') {
                if(this.state.selected_pages[0]) {
                    this.hideSelectPages()
                }
            } else if(event.key === "Escape") {
                this.props.cancelSelectPages()
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }

    handleAllPagesClick = () => {
        let updatedSelectedPages = this.state.selected_pages
        let select_button_text
        if(updatedSelectedPages.length === this.props.pages.length) {
            updatedSelectedPages = []
            select_button_text = "Select All"
        } else {
            updatedSelectedPages.length = 0;
            this.props.pages.forEach(page => {
                updatedSelectedPages.push(page)
            })
            select_button_text = "Select None"
        }
        this.setState({
            selected_pages: updatedSelectedPages,
            select_button_text
        })
    }

    onPageCheck = (index, event, isInputChecked) => {
        let updatedSelectedPages = Array.from(this.state.selected_pages);
        if(!isInputChecked) {
            let i = updatedSelectedPages.indexOf(this.props.pages[index]);
            if (i !== -1) updatedSelectedPages.splice(i, 1);
        } else {
            updatedSelectedPages.push(this.props.pages[index])
        }
        let select_button_text;
        if(updatedSelectedPages.length === this.props.pages) {
            select_button_text = "Select None"
        } else {
            select_button_text = "Select All"
        }
        this.setState({
            selected_pages: updatedSelectedPages,
            select_button_text
        })
    }

    hideSelectPages = () => {
        if(this.state.selected_pages[0]) {
            this.props.hideSelectPages(this.state.selected_pages)
        }
    }

    renderProducts = () => {
        if(!this.props.pages[0]) {
            return <div className="reports__loading">
                <CircularProgress />
            </div>
        }

        return this.props.pages.map((page, index) => {
            return <FormControlLabel className="select-lines-body-item"
                                     control={<Checkbox checked={this.state.selected_pages.indexOf(page) > -1}
                                                        color={"primary"}
                                                        onChange={this.onPageCheck.bind(this, index)}
                                                        />}
                                     label={page}
                                     key={"lines-"+page} />
        })
    }


    render() {
        return <div>
            <div
                className="select-lines"
                onClick={this.hideSelectPages}
            />
            <div className="select-lines-container" style={this.props.style}>
                <div className="select-lines-header">
                    <div style={{flex: 1, marginLeft: "24px"}}>
                        <div className="select-lines-header-button modal__content-title__text">Page Filter</div>
                    </div>
                    <Button variant="text"
                            color="primary"
                            className="select-lines-header-button"
                            onClick={this.handleAllPagesClick}>{this.state.select_button_text}</Button>
                    <Button variant="contained"
                            color="primary"
                            className="select-lines-header-button"
                            onClick={this.hideSelectPages}
                            disabled={!this.state.selected_pages[0]}>Filter</Button>
                </div>
                <div className="select-lines-body">
                    {this.renderProducts()}
                </div>

            </div>
        </div>
    }
}

export default SelectPageModal