import React, {Component} from 'react';
import {Table, TableRowColumn, TableBody, TableHeader, TableRow} from "material-ui";

class UserManagementAddManageUserConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */
    getFactoryRole = (r) => {
        let role = ""
        switch (r) {
            case "factory_supervisor":
                    role = "Supervisor"
                    break;
                case "factory_admin":
                    role = "Admin"
                    break;
                case "factory_read_only":
                    role = "Read Only"
                    break;
                case "organization_owner":
                    role = "Organization Owner"
                    break;
                case "scoreboard":
                    role = "Scoreboard"
                    break;
                case "read_only":
                    role = "Read Only"
                    break;
                case "floor_team":
                    role = "Floor Team"
                    break;
                case "supervisor":
                    role = "Supervisor"
                    break;
                case "dosom":
                    role = "Dosom"
                    break;
                case "external_developer":
                    role = "Developer"
                    break;
                case "account_owner":
                    role = "Account Owner"
                    break;
            default:
                role = r;
        }

        return role;
    }

    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    renderDetailsChangesSection = (user_first_name) => {
        return <div className="user-management-confirmation--table margin-bottom-xl">
            <div className="font-size-md margin-bottom-bg">Factory Permission Changes for {user_first_name}:</div>
            <Table selectable={false}
                   multiSelectable={false}>
                <TableHeader displaySelectAll={false}
                             adjustForCheckbox={false}
                             enableSelectAll={false}>
                    <TableRow  style={{height: '20px', paddingTop: '20px'}}>
                        <TableRowColumn style={Styles.tableHeaderStyle()}/>
                        <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>Previous Permission</TableRowColumn>
                        <TableRowColumn className="bold" style={Styles.tableHeaderStyle()}>New Permission</TableRowColumn>
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false}>
                    {
                        this.props.active_factories.map(factory => {
                            return <TableRow key={factory.factory_id} style={Styles.tableRowStyle(0)}>
                                <TableRowColumn className="bold" style={Styles.tableRowColumnConfirmTableStyle()}>{factory.factory_name}</TableRowColumn>
                                <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>{factory.initial_is_active ? this.getFactoryRole(factory.initial_role) : 'No Access'}</TableRowColumn>
                                <TableRowColumn style={Styles.tableRowColumnConfirmTableStyle()}>{factory.is_active ? this.getFactoryRole(factory.role) : 'No Access'}</TableRowColumn>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </div>
    }

    render() {
        let user_first_name = this.props.user.email;
        let user_last_name = "";

        if (this.props.user.user_metadata) {
            if(this.props.user.user_metadata.first_name) {
                user_first_name = this.props.user.user_metadata.first_name
            }

            if(this.props.user.user_metadata.last_name) {
                user_last_name = this.props.user.user_metadata.last_name
            }
        }
        return <div className="user-management-confirmation--body">
            <div className="user-management-confirmation--sub-header">
                Are you sure you want to change {user_first_name + " " + user_last_name} permission to {this.props.organization} factories?
            </div>
            {this.renderDetailsChangesSection(user_first_name)}
        </div>
    }
}

export default UserManagementAddManageUserConfirmation