import React, {Component} from 'react';
import {
    Button,
    CircularProgress,
    MenuItem,
    TextField
} from "@material-ui/core";
import { DatePicker } from '@material-ui/pickers';

class DowntimeExport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report_type: 0,
            date_from: new Date(),
            date_to: new Date(),
            email_address: '',
            email_error: null,
            success: false,
            loading: false,
            error: false
        }
    }

    handleSelectReportTypeChange = (event) => {
        this.setState({
            report_type: event.target.value,
            loading: false,
            success: false,
            error: false
        })
        if(this.state.date_from > this.state.date_to){
            this.setState({
                date_to: this.state.date_from
            })
        }
    };

    handleChange (event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    handleDateFromChange = (event, date) => {
        this.setState({
            date_from: date,
        })
    }


    handleDateToChange = (event, date) => {
        this.setState({
            date_to: date
        })
    }

    validateEmail = () => {
        let errors = false;
        this.setState({
            email_error: null
        })
        if (
            this.state.email_address.length < 6 ||
            this.state.email_address.indexOf("@", 0) == -1 ||
            this.state.email_address.indexOf(".", 0) == -1
        ) {
            errors = true;
            this.setState({
                email_error: 'Valid email required.'
            })
        }

        if(!errors) {
            this.submitExport()
        }
    }

    submitExport = () => {
        this.setState({
            loading: true,
            success: false,
            error: false
        })

        let path = "export_downtime"

        if(this.state.report_type === 0) {
            path = "export_run_details"
        }
        let params = {
            path: path,
            data: {
                email: this.state.email_address,
                start_date: this.state.date_from.toISOString(),
                end_date: this.state.report_type === 0 ? this.state.date_from.toISOString() : this.state.date_to.toISOString()
            },
            success: this.onSuccess,
            error: this.onError
        }

        API.c(params, 2)
    }

    onSuccess = (data) => {
        this.setState({
            loading: false,
            success: true
        })
    }

    onError = (error) => {
        this.setState({
            loading: false,
            success: false,
            error: true
        })
    }

    disableFromFutureDates(date) {
        if(this.state.report_type === 1){
            return ((date >= new Date()) || (date > this.state.date_to));
        }

        return (date >= new Date());
    }

    disableToFutureDates(date) {
        return (date >= new Date());
    }

    showState = () => {
        let {report_type, success, loading, error} = this.state;

        let type = 'downtime';
        if(report_type === 0) {
            type = 'run details';
        }

        if(loading){
            return <div className="products">
                <div className="downtime-export">
                    <CircularProgress/>
                </div>
            </div>
        } else if(success) {
            return <div className="products">
                <div className="downtime-export">
                    Thank you. Your {type} report will be emailed to {this.state.email_address} shortly.
                </div>
            </div>
        } else if(error) {
            return <div className="products">
                <div className="downtime-export">
                    There was an error requesting the {type} report.
                </div>
            </div>
        }

        return <div className="products">
            <div className="downtime-export">
                &nbsp;
            </div>
        </div>
    }

    render() {

        return <div className="downtime-export-fields">
            <div className="reports__sub">
                <div className="reports__options-item">
                    <TextField
                        select
                        style={{width: '200px', marginTop: '12px'}}
                        label='Report'
                        value={this.state.report_type}
                        onChange={this.handleSelectReportTypeChange}>
                        <MenuItem value={0}>Run Details</MenuItem>
                        <MenuItem value={1}>Downtime</MenuItem>
                    </TextField>
                </div>
                <DatePicker
                    label="Start Date"
                    className="reports__options-item"
                    shouldDisableDate={this.disableFromFutureDates.bind(this)}
                    value={this.state.date_from}
                    onChange={this.handleDateFromChange}
                    format="yyyy-MMMM-DD"/>
                {(this.state.report_type === 1) &&
                <DatePicker
                    label="End Date"
                    className="reports__options-item"
                    shouldDisableDate={this.disableToFutureDates.bind(this)}
                    minDate={this.state.date_from}
                    value={this.state.date_to}
                    onChange={this.handleDateToChange}
                    format="yyyy-MMMM-DD"/>
                  }
                <TextField
                    id="email_address"
                    label="Email"
                    value={this.state.email_address}
                    style={{width: "250px"}}
                    onChange={this.handleChange.bind(this)}
                    className="reports__options-item"
                    fullWidth={true}
                    error={this.state.email_error !== null}
                    helperText={this.state.email_error !== null ? this.state.email_error !== null : ''}
                />
                <Button variant="contained"
                        color="primary"
                        className="reports__options-item"
                        style={{marginTop: "11px"}}
                        disabled={this.state.loading}
                        onClick={this.validateEmail.bind(this)}>Send Report</Button>
            </div>
            {this.showState()}
        </div>
    }
}

export default DowntimeExport