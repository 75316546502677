import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FinishedGoodsIcon(props) {
    return (
        <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M11.9715 1.70075L8.24585 0.0536308C8.16914 0.0183295 8.08512 0 8 0C7.91488 0 7.83085 0.0183295 7.75414 0.0536308L4.02849 1.70075C3.93084 1.74321 3.84834 1.81213 3.7912 1.89899C3.73406 1.98585 3.70478 2.08684 3.70698 2.18946V6.82312L0.340655 8.30735C0.239431 8.3521 0.153641 8.42354 0.0933458 8.51331C0.0330509 8.60308 0.000752518 8.70744 0.000239449 8.81415V13.8098C-0.0030667 13.915 0.0279333 14.0186 0.0889354 14.1062C0.149938 14.1938 0.237915 14.261 0.340655 14.2985L4.06631 15.9457L4.29325 16C4.37858 16.0011 4.46293 15.9824 4.53911 15.9457L8 14.4252L11.4609 15.9457C11.5371 15.9824 11.6214 16.0011 11.7067 16L11.9337 15.9457L15.6593 14.2985C15.7621 14.261 15.8501 14.1938 15.9111 14.1062C15.9721 14.0186 16.0031 13.915 15.9998 13.8098V8.81415C15.9992 8.70744 15.9669 8.60308 15.9067 8.51331C15.8464 8.42354 15.7606 8.3521 15.6593 8.30735L12.293 6.82312V2.18946C12.2952 2.08684 12.2659 1.98585 12.2088 1.89899C12.1517 1.81213 12.0692 1.74321 11.9715 1.70075ZM4.25543 7.78244L6.61943 8.83225L4.29325 9.84587L1.92926 8.81415L4.25543 7.78244ZM7.43264 4.18049V7.98154L4.8417 6.84122V3.04017L7.43264 4.18049ZM8.56736 4.18049L11.1583 3.04017V6.84122L8.56736 7.98154V4.18049ZM4.86061 10.8052L7.43264 9.66486V13.484L4.86061 14.6062V10.8052ZM8.56736 9.66486L11.1394 10.8052V14.6062L8.56736 13.484V9.66486ZM11.7067 9.84587L9.38057 8.83225L11.7446 7.78244L14.0707 8.81415L11.7067 9.84587ZM8 1.13965L10.364 2.18946L8 3.23928L5.636 2.18946L8 1.13965ZM1.13496 9.64676L3.7259 10.8052V14.6062L1.13496 13.4659V9.64676ZM12.2741 14.6062V10.8052L14.865 9.64676V13.4659L12.2741 14.6062Z"
                fill={props.color || 'black'}
            />
        </SvgIcon>
    );
}
