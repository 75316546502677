import React, { Component } from 'react';

import { Button, CircularProgress, TextField, DialogContent, DialogActions } from "@material-ui/core";

class AddReasonCategoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category_name: '',
            category_name_error: null,
            category_description: '',
            category_description_error: null,
            submitting_category: false,
            submit_status: null,
        }

    }

    handleChange(event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    componentDidMount() {
        if (this.props.name) {
            this.setState({
                category_name: this.props.name
            })
        }
    }

    addProductFields = () => {
        return <DialogContent>
            <div style={{ width: 200, height: 60 }}>
                <TextField
                    id="category_name"
                    label="Category Name"
                    value={this.state.category_name}
                    onChange={this.handleChange.bind(this)}
                    error={this.state.category_name_error !== null}
                    helperText={this.state.category_name_error !== null ? this.state.category_name_error : ''}
                    fullWidth={true}
                />
            </div>
        </DialogContent>
    }

    submitCategory = () => {
        if (!this.validateInputs()) {
            return false;
        }

        this.setState({
            submitting_category: true,
            category_status: null,
            category_name_error: null,
        });

        if (this.props.id && this.props.name) {
            let params = {
                path: 'edit_reason_category',
                path_variables: { ID: this.props.id },
                data: {
                    name: this.state.category_name
                },
                success: this.submitSuccess,
                error: this.fetchError
            };

            API.c(params);
        }
        else {
            let params = {
                path: 'add_reason_category',
                data: {
                    name: this.state.category_name,
                },
                success: this.submitSuccess,
                error: this.fetchError
            };

            API.c(params);
        }
    }

    submitSuccess = (data) => {
        this.setState({
            submitting_category: false,
        })

        // this.props.openProductDetails(data);
        // this.props.modified();
        this.hideModal();
    }

    submitError = (error) => {
        this.setState({
            submitting_category: false,
            submit_status: error.name ? "Category with the same Category Name already exists" : "There was an issue creating this category"
        })
    }

    validateInputs() {
        let error_free = true;

        this.setState({
            category_name_error: null,
        });

        if (this.state.category_name == '') {
            error_free = false;
            this.setState({
                category_name_error: "Category Name required"
            })
        }

        return error_free;
    }

    hideModal = () => {
        this.props.hideModal();
    }

    showSubmitButton() {
        if (!this.state.submitting_category) {
            return <DialogActions>
                {this.state.submit_status && <div className="update-status__error">{this.state.submit_status}</div>}
                <Button variant="contained"
                    color="primary"
                    onClick={this.submitCategory.bind(this)}>Submit</Button>
            </DialogActions>
        } else {
            return <DialogActions><CircularProgress mode="indeterminate" /></DialogActions>
        }
    }

    render() {
        return <div>
            {this.addProductFields()}
            {this.showSubmitButton()}
        </div>
    }

}

export default AddReasonCategoryModal