import React, { Component } from 'react';

import { Button, CircularProgress, TextField, DialogContent, DialogActions } from "@material-ui/core";

class AddProductModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product_name: '',
            product_name_error: null,
            product_description: '',
            product_description_error: null,
            case_weight: '',
            case_weight_kg: '',
            case_weight_unit: 'lbs',
            case_weight_error: null,
            target_weight: '',
            target_weight_error: null,
            target_fat: '',
            target_fat_error: null,
            min_fat: '',
            min_fat_error: null,
            max_fat: '',
            max_fat_error: null,
            target_protein: '',
            target_protein_error: null,
            target_moisture: '',
            target_moisture_error: null,
            submitting_product: false,
            submit_status: null,
            product_is_fresh: false
        }

    }


    /* ######## Lifecycle Methods ######## */
    componentDidMount = () => {
        this.setKeyListener();
    }

    componentWillUnmount = () => {
        this.cancelKeyListener();
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            if (event.keyCode === 13) {
                this.submitProduct()
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }

    handleChange(event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    handleSelectChange(event, index, value) {
        this.setState({
            case_weight_unit: value,
            case_weight_kg: (this.state.case_weight / this.getUnitConv(value))
        })
    }

    handleCaseWeightChange(event) {
        this.setState({
            case_weight: event.target.value,
            case_weight_kg: (event.target.value / this.getUnitConv(this.state.case_weight_unit))
        })
    }

    handleTargetWeightChange(event) {
        this.setState({
            target_weight: event.target.value
        })
    }

    getUnitConv = (unit) => {
        switch (unit) {
            case "kg":
                return 1;
            case "lbs":
                return 2.20462262185;
            case "g":
                return 1000;
            default:
                return 1;
        }
    }

    addProductFields = () => {
        return <DialogContent>
            <div className="add-product__fields_wrapper">
                <TextField
                    id="product_name"
                    label="Product Code"
                    value={this.state.product_name}
                    onChange={this.handleChange.bind(this)}
                    error={this.state.product_name_error !== null}
                    helperText={this.state.product_name_error !== null ? this.state.product_name_error : ''}
                    fullWidth={true}
                />
                <TextField
                    id="product_description"
                    label="Product Description"
                    value={this.state.product_description}
                    onChange={this.handleChange.bind(this)}
                    error={this.state.product_description_error !== null}
                    helperText={this.state.product_description_error !== null ? this.state.product_description_error : ''}
                    fullWidth={true}
                />
                {/* <TextField
                        id="target_weight"
                        label="Target Weight (KG)"
                        value={this.state.target_weight}
                        onChange={this.handleTargetWeightChange.bind(this)}
                        error={this.state.target_weight_error !== null}
                        helperText={this.state.target_weight_error !== null ? this.state.target_weight_error : ''}
                        type="number"
                        fullWidth={true}/> */}
            </div>
        </DialogContent>
    }

    updateFresh(event, isInputChecked) {
        this.setState({
            product_is_fresh: isInputChecked
        })
    }

    submitProduct = () => {
        if (!this.validateInputs()) {
            return false;
        }

        this.setState({
            submitting_product: true,
            product_status: null,
            product_name_error: null,
            product_description_error: null,
            case_weight_error: null,
        });

        let data = {
            name: this.state.product_name,
            desc: this.state.product_description,
        }

        let params = {
            path: "add_product",
            data: data,
            success: this.submitSuccess,
            error: this.submitError
        }

        API.c(params, 2);
    }

    submitSuccess = (data) => {
        this.setState({
            submitting_product: false,
        })

        // this.props.openProductDetails(data);
        this.props.modified();
        this.hideModal();
    }

    submitError = (error) => {
        this.setState({
            submitting_product: false,
            submit_status: error.name ? "Product with the same Product Code already exists" : "There was an issue creating this product"
        })
    }

    validateInputs() {
        let error_free = true;

        this.setState({
            product_name_error: null,
            product_description_error: null,
            case_weight_error: null,
            target_weight_error: null
        });

        if (this.state.product_name == '') {
            error_free = false;
            this.setState({
                product_name_error: "Product Name required"
            })
        }

        if (this.state.product_description == '') {
            error_free = false;
            this.setState({
                product_description_error: "Product Description required"
            })
        }

        // if (this.state.target_weight === '' || this.state.target_weight === 0) {
        //     error_free = false;
        //     this.setState({
        //         target_weight_error: "Target Weight required"
        //     })
        // }

        return error_free;
    }

    hideModal = () => {
        this.props.hideModal();
    }

    showSubmitButton() {
        if (!this.state.submitting_product) {
            return <DialogActions>
                {this.state.submit_status && <div className="update-status__error">{this.state.submit_status}</div>}
                <Button variant="contained"
                    color="primary"
                    onClick={this.submitProduct.bind(this)}>Submit</Button>
            </DialogActions>
        } else {
            return <DialogActions><CircularProgress mode="indeterminate" /></DialogActions>
        }
    }

    render() {
        return <div>
            {this.addProductFields()}
            {this.showSubmitButton()}
        </div>
    }

}

export default AddProductModal