import { Button, Switch, Table, TableRow, TableBody, TableCell, TableHead, Tooltip, Typography } from '@material-ui/core';
import { AccessTime, Clear, DoneAll, InfoOutlined } from '@material-ui/icons';
import { DatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { toHaveDescription } from '@testing-library/jest-dom/dist/matchers';
// import { Checkbox } from 'material-ui';
import { Checkbox } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {auth0UserManagementAPIInstance} from "../components/Auth0API"
import CancelEmailedReport from './CancelEmailedReport';
import DaysToTheCompilationDateModal from './DaysToTheCompilationDateModal';
import MonthlyDateOfTheMonthModal from './MonthlyDateOfTheMonthModal';
import RenameEmailedReportModal from './RenameEmailedReportModal';
import SaveEmailedReport from './SaveEmailedReport';
import WeekdayPickerModal from './WeekdayPickerModal';

class EmailedReportsDetails extends Component {

    weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

    constructor(props) {
        super(props);
        this.webAuth = Auth0;
        this.state = {
            report: this.props.report,
            show_modal: false,
            status: this.props.report.is_active,
            lines: [],
            shifts: [],
            categories: [],
            tags: [],
            history: [],
            // weekdayOnly: false,
            typeOfReport: this.props.typeOfReport,
            organization: null,
            organizations: null,
            customCompilationDay: new Date().getDay(),
            customCompilationDayDisplay: this.weekday[new Date().getDay()],
            customCompilationDaysToDate: "1",
            monthlyDateOfTheMonth: "1",
            timePickerOpen: false,
            weekdayPickerOpen: false,
            reportName: this.props.report.name,
            time_period: `${this.props.report.report_type[0].toUpperCase()}${this.props.report.report_type.slice(1).toLowerCase()}`,
            compilationTime: new Date(),
            compilationTimeDisplay: (new Date()).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        }
    }

    styles = {
        header_save: {
            padding: "16px 0px",
            background: "#1975D0",
            color: "#F2F2F2",
            borderRadius: "8px",
            width: "128px",
            height: "51px"
        },
        datetime_container: {
            display: "block",
            width: "100%",
            padding: "0px 5px"
        },
        time_period: {
            marginTop: "20px",
            width: "50%",
            float: "left",
            display: "inline-block"
        },
        time_period_tooltip_box: {
            marginTop: "24px",
            padding: "16px",
            background: "rgba(25, 117, 208, 0.1)",
            color: "rgba(25, 117, 208, 0.8)",
            width: "352px",
            fontSize: "16px",
            fontWeight: "400",
            
            borderRadius: "8px",
        },
        time_period_tooltip_text: {
            borderLeft: "4px solid #1975D0",
            paddingLeft: "8px"
        },
        section_header_container: {
            display: "flex",
        },
        section_header: {
            fontFamily: 'Roboto',
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            color: "#333333",
            marginRight: "5px",
        },
        time_period_buttons: {
            marginTop: "16px"
        },
        time_period_button: {
            padding: "8px 16px",
            background: "#BDBDBD",
            borderRadius: "8px",
            display: "inline-block",
            color: "#F2F2F2",
            marginRight: "8px",
            marginBottom: "8px",
            cursor: "pointer"
        },
        time_period_button_selected: {
            padding: "8px 16px",
            background: "#1975D0",
            borderRadius: "8px",
            display: "inline-block",
            color: "#F2F2F2",
            marginRight: "8px",
            marginBottom: "8px",
        },
        compilation: {
            marginTop: "20px",
            width: "50%",
            float: "left",
            display: "inline-block"
        },
        compilation_text: {
            marginTop: "16px",
            display: "flex",
            alignItems: "center",
        },
        compilation_text_custom: {
            marginTop: "16px",
            display: "flex",
            alignItems: "center",
            borderTop: "1px solid #BDBDBD",
            padding: "16px 0px 0px 0px"
        },
        compilation_text_custom_last: {
            marginTop: "16px",
            display: "flex",
            alignItems: "center",
            borderTop: "1px solid #BDBDBD",
            borderBottom: "1px solid #BDBDBD",
            padding: "16px 0px"
        },
        compilation_text_time: {
            fontFamily: 'Roboto',
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            color: "#333333",
            marginRight: "8px"
        },
        compilation_text_button: {
            padding: "8px 16px",
            background: "#1975D0",
            borderRadius: "8px",
            display: "inline-block",
            color: "#F2F2F2",
            marginRight: "8px",
        },
        compilation_text_edit: {
            fontFamily: 'Roboto',
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            color: "#1975D0",
            cursor: "pointer"
        },
        data_container: {
            padding: "20px 5px 20px 5px",
            display: "flex",
            width: "calc(100%)",
            // display: "grid",
            // gridTemplateColumns: "1fr 1fr 1fr 1fr"
        },
        data_type_container: {
            display: "flex",
            flexWrap: "wrap", 
            // display: "grid",
            // gridTemplateColumns: "1fr 1fr 1fr 1fr",
            // gap: "8px",
        },
        data_type_title: {
            marginBottom: "16px",
            fontWeight: "600",
            fontSize: "16px",
            color: "#333333"
        },
        data_pill: {
            maxWidth: "2000px",
            borderRadius: "8px",
            background: "#BDBDBD",
            textAlign: "center",
            verticalAlign: "middle",
            color: "#F2F2F2",
            fontWeight: "400",
            fontSize: "14px",
            cursor: "pointer",
            marginRight: "8px",
            marginBottom: "8px",
            flexGrow: "1",
            textOverflow: "ellipsis",
            whiteSpace: "no-wrap",
            overflow: "hidden",
            padding: "8px"
        },
        data_pill_selected: {
            maxWidth: "2000px",
            borderRadius: "8px",
            background: "#1975D0",
            textAlign: "center",
            verticalAlign: "middle",
            color: "#F2F2F2",
            fontWeight: "400",
            fontSize: "14px",
            cursor: "pointer",
            marginRight: "8px",
            marginBottom: "8px",
            flexGrow: "1",
            textOverflow: "ellipsis",
            whiteSpace: "no-wrap",
            overflow: "hidden",
            padding: "8px"
        },
        data_no_data_title: {
            display: "flex",
            flexWrap: "wrap",
            padding: "8px 16px",
            borderRadius: "8px",
            background: "#F2F2F2",
        },
        data_column_container: {
            marginRight: "42px",
            width: "25%",
        },
        data_column_container_last: {
            width: "25%",
        }
    }

    renderTooltip = (type) => {
        if (type === "time_period") {
            return (
                <Fragment>
                    <Typography variant={'caption'}>{this.state.time_period === "Daily" ? "The Daily report will be generated every day at the compilation time for the previous production day." :
                                                    this.state.time_period === "Weekly" ? "The Weekly report will be generated for the previous complete production week at the compilation time." :
                                                    this.state.time_period === "Monthly" ? "The Monthly report will be generated for the previous calendar month at the compilation time." :
                                                    "A custom report allows you to pick a day of the week to run a report, and select how many prior production days should be included in that report."}</Typography>
                </Fragment>
            );
        }
        if (type === "report_generated_at") {
            return (
                <Fragment>
                    <Typography variant={'caption'}>{this.state.time_period === "Daily" ? "The time of the day to generate the report for the previous complete production day." :
                                                    this.state.time_period === "Weekly" ? "The day of the week and the time of the day to generate the report for the previous complete production week." :
                                                    this.state.time_period === "Monthly" ? "The date of the month and time of the day to generate the report for the previous complete production month." :
                                                    "The day of the week, the time of day, and the number of previous production days included in the generated report."}</Typography>
                </Fragment>
            );
        }
    };

    updateStatus() {
        const input = document.getElementById("status");
        input.innerHTML = !this.state.status === false ? "Paused" : "Active"
        input.style.color = !this.state.status === false ? "#828282" : "#1975D0"
        this.setState({
            status: !this.state.status
        })
    }

    updateHeader() {
        const headerContainer = document.getElementsByClassName("settings__subhead")[0];
        headerContainer.innerHTML = `
            <div style='display: flex; justify-content: space-between; align-items: center;'>
                <div style='display: flex; max-width: 50%'>
                    <p id='name' style='font-family: Roboto; overflow-wrap: break-word; font-style: normal; font-weight: 400; font-size: 30px; color: #000000; margin-right: 32px; max-width: 45%'>${this.state.reportName}</p>
                    <p id='rename' style='font-family: Roboto; font-style: normal; font-weight: 400; font-size: 16px; color: #1975D0; cursor: pointer; display: flex; align-items: center'>Rename</p>
                </div>
                <div id="status_bar" style='display: flex; align-items: center'>
                    <p style='font-family: Roboto; font-style: normal; font-weight: 400; font-size: 16px; color: #333333;'>Status:</p>
                    <div id="status" style='margin-right: 8px; padding: 5px 16px; display: inline-block; color: ${this.state.status ? "#1975D0" : "#828282"}; margin-left: 8px; cursor: pointer;'>${this.state.status ? "Active" : "Paused"}</div>
                    <input checked=${this.state.status} class='toggle-button' type="checkbox" id="toggle-button" style="transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2); -webkit-appearance: none; -moz-appearance: none; appearance: none; position: relative; display: inline-block; width: 36px; height: 18px; margin: 0; vertical-align: top; background: #ffffff; border: 1px solid #bbc1e1; border-radius: 30px; outline: none; cursor: pointer;">
                    <style>
                        .toggle-button::after {
                            content: "";

                            display: inline-block;
                            position: absolute;
                            left: 3px;
                            top: 1.5px;
                          
                            width: 13px;
                            height: 13px;
                            background-color: ${this.state.status ? "#BDBDBD" : "#1975D0"};
                            border-radius: 50%;
                          
                            transform: ${this.state.status ? "translateX(0)" : "translateX(calc(100% + 3px))"};
                            transition: all 0.3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
                        }
                    </style>
                    <style>
                        .toggle-button:checked::after {
                            transform: ${this.state.status ? "translateX(calc(100% + 3px))" : "translateX(0)"};
                            background-color: ${this.state.status ? "#1975D0" : "#BDBDBD"};
                        }
                    </style>
                    </div>
                <div style='display: flex; margin-left: 10%'>
                    <div id='cancel' style='background: transparent; color: #1975D0; border: 1px solid #1975D0; border-radius: 8px; width: 128px; height: 51px; display: flex; justify-content: center; align-items: center; margin-right: 16px; cursor: pointer;'>Cancel</div>
                    <div id='save' style='background: #1975D0; color: #F2F2F2; border-radius: 8px; width: 128px; height: 51px; display: flex; justify-content: center; align-items: center; cursor: pointer;'>Save Report</div>
                </div>
            </div>
        `
        document.getElementById("rename").addEventListener("click", () => {this.setState({ show_modal: true, modal: "rename" })})
        document.getElementById("toggle-button").addEventListener("click", () => { this.updateStatus() })
        document.getElementById("cancel").addEventListener("click", () => { this.setState({ show_modal: true, modal: "confirmCancel" }) })
        document.getElementById("save").addEventListener("click", () => { this.setState({ show_modal: true, modal: "saveReport" }) })
    }

    componentDidMount() {
        let org = this.defaultOrg(this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"]);
        this.setState({
            organizations: this.webAuth.getIdTokenPayload()["https://livetracking.ca/app_metadata"]["organizations"],
            organization: org,
            factory_id: this.defaultFactoryId(),
            factory: JSON.parse(window.localStorage.getItem("factory")),
            reportName: this.props.report.name,
            customCompilationDay:  this.props.report.next_trigger_at ? new Date(this.props.report.next_trigger_at).getDay() - 1 : new Date().getDay(),
            customCompilationDayDisplay: this.props.report.day_of_the_week ? this.props.report.day_of_the_week : this.weekday[new Date().getDay()],
        })
        this.updateHeader()
        this.getShifts();
        this.getLines();
        this.getCategories();
        this.getTags();
        this.getHistory();
        this.getOrgUsers(org);
        if (this.state.report.generate_at) {
            const compilationTime = new Date()
            compilationTime.setHours(this.state.report.generate_at.slice(0, 2))
            compilationTime.setMinutes(this.state.report.generate_at.slice(3, 5))
            this.setState({
                compilationTime,
                compilationTimeDisplay: compilationTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
            })
        }
    }

    defaultFactoryId = () => {
        let defaultFactory = JSON.parse(window.localStorage.getItem("factory"))

        return defaultFactory ? defaultFactory.id : null
    }

    defaultOrg = (orgs) => {
        let defaultOrg = JSON.parse(window.localStorage.getItem("organization")).id

        return defaultOrg ? defaultOrg : orgs[0].id
    }

    getOrgUsers = (org) => {
        auth0UserManagementAPIInstance.get(`auth0/user/search?organization_id=${org}&factory_id=${this.defaultFactoryId()}`)
            .then(res => {
                let org_users = this.filterOrgUsers(res.data, org)
                let factory_users = null
                if(this.state.factory_id) {
                    factory_users = this.filterFactoryUsers(org_users, this.state.factory_id)
                }
                if (this.state.report.users) {
                    factory_users.forEach((item, i) => {
                        this.state.report.users.forEach((user, j) => {
                            if (item.user_id === user) {
                                factory_users[i].subscribed = true
                            }
                        })
                    })
                }
                this.setState({
                    all_users: res.data,
                    org_users,
                    factory_users: factory_users.sort((a, b) => a.email.split("@")[1].localeCompare(b.email.split("@")[1]))
                })
            })
            .catch(err => {
                console.log(err)
                let error = err
                if(err && err.response && err.response.data) {
                    error = err.response.data.message
                }

                this.setState({
                    error
                })
            })
    }

    filterOrgUsers = (users, org_id) => {
        let orgUsers = null;
        if(users && users[0]) {
            orgUsers = [];
            users.forEach(user => {
                let userOrgs = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    userOrgs = user.app_metadata.organizations;
                }

                if(userOrgs && userOrgs[0]) {
                    let userInThisOrg = userOrgs.find(org => org.id === org_id)
                    if(userInThisOrg) {
                        orgUsers.push(user)
                    }
                }
            })
        }

        return orgUsers
    }

    filterFactoryUsers = (users, factory_id) => {
        let factoryUsers = null;
        if(users && users[0]) {
            factoryUsers = [];
            users.forEach(user => {
                let userFactories = null;
                if(user.app_metadata && user.app_metadata.organizations) {
                    let userOrg = user.app_metadata.organizations.find(org => org.id === this.state.organization)
                    userFactories = userOrg ? userOrg.factories : null;
                }

                if(userFactories && userFactories[0]) {
                    let userInFactory = userFactories.find(factory => factory.id === factory_id)
                    if(userInFactory) {
                        factoryUsers.push(user)
                    }
                }
            })
        }

        return factoryUsers
    }

    getHistory = () => {
        let params = {
            path: `get_emailed_report_history_${this.state.report.report_type}`,
            path_variables: {
                REPORT_ID: this.state.report.id
            },
            success: this.fetchHistorySuccess,
            error: () => console.log("Error getting tags")
        };

        API.c(params);
    }

    fetchHistorySuccess = (data) => {
        const newHistory = data;
        newHistory.forEach((item, i) => {
            newHistory[i].report_created = new Date(item.triggered_at);
            newHistory[i].report_time = new Date(item.triggered_at);
        })
        this.setState({
            history: newHistory.reverse()
        })
    }

    getTags = () => {
        let params = {
            path: 'all_tags',
            success: this.fetchTagsSuccess,
            error: () => console.log("Error getting tags")
        };

        API.c(params);
    }

    fetchTagsSuccess = (data) => {
        const newTags = data
        if (this.state.report.tags) {
            data.forEach((tag, i) => {
                this.state.report.tags.forEach((selected_tag, j) => {
                    if (tag.id === selected_tag) {
                        newTags[i].selected = true
                    }
                })
            })
        }
        this.setState({
            tags: newTags
        })
    }

    getShifts = () => {
        let params = {
            path: 'get_shift',
            success: (data) => this.fetchShiftsSuccess(data),
            error: () => console.log("Error getting shifts")
        };

        API.c(params);
    };

    getLines = () => {
        let params = {
            path: 'get_lines',
            success: this.fetchLinesSuccess,
            error: () => console.log("Error getting lines")
        };

        API.c(params);
    };

    getCategories = () => {
        let params = {
            path: 'all_categories',
            success: this.fetchSuccessCategories,
            error: () => console.log("Error getting categories")
        };

        API.c(params);
    }

    fetchSuccessCategories = (data) => {
        let categories = [];
        data.forEach(d => {
            categories.push(d);
        });
        const newCategories = categories
        if (this.state.report.categories) {
            categories.forEach((category, i) => {
                this.state.report.categories.forEach((selected_category, j) => {
                    if (category.id === selected_category) {
                        newCategories[i].selected = true
                    }
                })
            })
        }
        this.setState({
            categories: newCategories,
        });
    }

    fetchLinesSuccess = data => {
        const newLines = data
        if (this.state.report.lines) {
            data.forEach((line, i) => {
                this.state.report.lines.forEach((selected_line, j) => {
                    if (line.id === selected_line) {
                        newLines[i].selected = true
                    }
                })
            })
        }
        this.setState({
            lines: newLines,
        });
    };

    fetchShiftsSuccess (data) {
        const newShifts = data
        if (this.state.report.shifts && this.state.report.shifts.length > 0) {
            newShifts.forEach((item, i) => {
                this.state.report.shifts.forEach((shift, j) => {
                    if (item.id === shift) {
                        newShifts[i].selected = true
                    }
                })
            })
        }
        this.setState({
            shifts: newShifts
        })
    }

    onRenameSave = (newName) => {
        this.setState({
            reportName: newName,
            show_modal: false
        })
        document.getElementById("name").innerHTML = `${newName}`
    }

    onDaysToDateSave = (daysToDate) => {
        this.setState({
            customCompilationDaysToDate: daysToDate,
            show_modal: false
        })
    }

    onDateOfTheMonthSave = (dateOfTheMonth) => {
        this.setState({
            monthlyDateOfTheMonth: dateOfTheMonth,
            show_modal: false
        })
    }

    onHideModal = (type) => {
        this.setState({
            show_modal: false
        })
    }

    onContinueEditing = (newReportData) => {
        const newReport = this.state.report;
        newReport.id = newReportData.id
        newReport.newReport = false
        newReport.report_type = this.state.time_period
        
        this.setState({
            show_modal: false,
            report: newReport,
            typeOfReport: "edit",
        })
    }

    onChangeCompilationTime = (date) => {
        const newCompilationTime = new Date(date)
        this.setState({
            compilationTime: newCompilationTime,
            compilationTimeDisplay: newCompilationTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        })
    }

    onChangeWeekday = (date) => {
        const newCompilationDay = new Date(date)
        this.setState({
            customCompilationDay: newCompilationDay,
            customCompilationDayDisplay: this.weekday[newCompilationDay.getDay()],
        })
    }

    onWeekdaySave = (day) => {
        this.setState({
            customCompilationDay: day,
            customCompilationDayDisplay: this.weekday[day],
            show_modal: false,
        })
    }

    onSelectData = (type, id) => {
        const index = this.state[type].findIndex((item) => item.id === id)
        const newData = this.state[type]
        newData[index].selected = !newData[index].selected
        this.setState({
            [type]: newData
        })
    }

    onSubscribe = (index) => {
        const newRecipients = this.state.factory_users
        newRecipients[index].subscribed = !newRecipients[index].subscribed
        this.setState({
            factory_users: newRecipients
        })
    }

    // onSwitchWeekdayOnly = () => {
    //     this.setState({
    //         weekdayOnly: !this.state.weekdayOnly
    //     })
    // }

    modal = () => {
        if (this.state.show_modal) {
            if (this.state.modal === "rename") {
                return (
                    <RenameEmailedReportModal reports={this.props.reports} reportId={this.state.report.id} type="rename" reportName={this.state.reportName} onSave={(newName) => this.onRenameSave(newName)} onHide={this.onHideModal} />
                );
            }
            else if (this.state.modal === "name") {
                return (
                    <RenameEmailedReportModal type="name" reportName={this.state.reportName} onSave={(newName) => this.onRenameSave(newName)} onHide={this.onHideModal} />
                );
            }
            else if (this.state.modal === "daysToTheCompilationDate") {
                return (
                    <DaysToTheCompilationDateModal daysToDate={this.state.customCompilationDaysToDate} onSave={(daysToDate) => this.onDaysToDateSave(daysToDate)} onHide={this.onHideModal} />
                );
            }
            else if (this.state.modal === "dateOfTheMonth") {
                return (
                    <MonthlyDateOfTheMonthModal monthlyDateOfTheMonth={this.state.monthlyDateOfTheMonth} onSave={(dateOfTheMonth) => this.onDateOfTheMonthSave(dateOfTheMonth)} onHide={this.onHideModal} />
                );
            }
            else if (this.state.modal === "weekdayPicker") {
                return (
                    <WeekdayPickerModal day={this.state.customCompilationDay} weekday={this.weekday} onSave={(day) => this.onWeekdaySave(day)} onHide={this.onHideModal} />
                )
            }
            else if (this.state.modal === "confirmCancel") {
                return (
                    <CancelEmailedReport onHide={this.onHideModal} onConfirm={this.props.hideReportDetails} />
                )
            }
            else if (this.state.modal === "saveReport") {
                return (
                    <SaveEmailedReport time_period={this.state.time_period}
                                        nameChanged={this.state.report.name !== this.state.reportName || this.props.report.newReport}
                                        prev_time_period={this.state.report.report_type}
                                        customCompilationDay={this.state.customCompilationDay}
                                        customCompilationDayDisplay={this.state.customCompilationDayDisplay}
                                        customCompilationDaysToDate={this.state.customCompilationDaysToDate}
                                        compilationTime={this.state.compilationTime}
                                        compilationTimeDisplay={this.state.compilationTimeDisplay}
                                        reportName={this.state.reportName}
                                        lines={this.state.lines}
                                        shifts={this.state.shifts}
                                        status={this.state.status}
                                        categories={this.state.categories}
                                        tags={this.state.tags}
                                        factory_users={this.state.factory_users}
                                        onContinueEditing={this.onContinueEditing}
                                        onHide={this.onHideModal}
                                        onChangeTab={this.props.onChangeTab}
                                        onConfirm={this.props.hideReportDetails}
                                        typeOfReport={this.state.typeOfReport}
                                        reportId={this.state.report.id}
                                        onDone={this.props.hideReportDetails}
                    />
                )
            }
        }
    };

    select_time_period = (period, custom) => {
        if (!custom) {
            this.setState({
                time_period: period
            })
            return
        }
        return
    }

    render_time_period = () => {
        return (
            <div style={this.styles.time_period}>
                    <div style={this.styles.section_header_container}>
                        <p style={this.styles.section_header}>Time Period</p>
                        <Tooltip
                            disableFocusListener
                            disableTouchListener
                            interactive
                            placement={'right'}
                            title={this.renderTooltip("time_period")}
                        >
                            <InfoOutlined style={{ width: "16px", height: "16px" }} />
                        </Tooltip>
                    </div>
                <div style={this.styles.time_period_buttons}>
                    <div onClick={() => this.select_time_period("Daily")} style={ this.state.time_period === "Daily" ? this.styles.time_period_button_selected : this.styles.time_period_button}>
                        Daily
                    </div>
                    <div onClick={() => this.select_time_period("Weekly")} style={ this.state.time_period === "Weekly" ? this.styles.time_period_button_selected : this.styles.time_period_button}>
                        Weekly
                    </div>
                    <div onClick={() => this.select_time_period("Monthly")} style={ this.state.time_period === "Monthly" ? this.styles.time_period_button_selected : this.styles.time_period_button}>
                        Monthly
                    </div>
                    <div onClick={() => this.select_time_period("Custom")} style={ this.state.time_period === "Custom" ? this.styles.time_period_button_selected : this.styles.time_period_button}>
                        Custom
                    </div>
                </div>
                {/* <div style={this.styles.time_period_tooltip_box}>
                    <p style={this.styles.time_period_tooltip_text}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </p>
                </div> */}
            </div>
        )
    }

    render_compilation = () => {
        return (
            <div style={this.styles.compilation}>
                <div style={this.styles.section_header_container}>
                    <p style={this.styles.section_header}>Report Generated At:</p>
                    <Tooltip
                        disableFocusListener
                        disableTouchListener
                        interactive
                        placement={'right'}
                        title={this.renderTooltip('report_generated_at')}
                    >
                        <InfoOutlined style={{ width: "16px", height: "16px" }} />
                    </Tooltip>
                </div>
                {this.state.time_period === "Daily" ? (
                    <Fragment>
                        <div style={this.styles.compilation_text_custom_last}>
                            <p style={this.styles.compilation_text_time}>Time of the compilation:</p>
                            <div style={this.styles.compilation_text_button}>{`Start at ${this.state.compilationTimeDisplay}`}</div>
                            <p onClick={() => this.setState({ timePickerOpen: true })} style={this.styles.compilation_text_edit}>Edit</p>
                            <KeyboardTimePicker
                                ampm={true}
                                value={this.state.compilationTime}
                                format="h:mm A"
                                style={{ display: "none" }}
                                open={this.state.timePickerOpen}
                                onChange={() => {}}
                                onClose={() => this.setState({ timePickerOpen: false })}
                                onAccept={(date) => this.onChangeCompilationTime(date)}
                            />
                        </div>
                        {/* <div style={this.styles.compilation_text_custom_last}>
                            <p style={this.styles.compilation_text_time}>Weekday only:</p>
                            <div style={this.styles.compilation_text_button}>{`${this.state.weekdayOnly ? "Yes" : "No"}`}</div>
                            <Switch checked={this.state.weekdayOnly} onChange={this.onSwitchWeekdayOnly} color={"primary"} />
                        </div> */}
                    </Fragment>
                ) : this.state.time_period === "Weekly" ? (
                    <Fragment >
                        <div style={this.styles.compilation_text_custom}>
                            <p style={this.styles.compilation_text_time}>Day of the week:</p>
                            <div style={this.styles.compilation_text_button}>{`${this.state.customCompilationDayDisplay}`}</div>
                            <p onClick={() => this.setState({ show_modal: true, modal: "weekdayPicker" })} style={this.styles.compilation_text_edit}>Edit</p>
                        </div>
                        <div style={this.styles.compilation_text_custom_last}>
                            <p style={this.styles.compilation_text_time}>Time of the compilation:</p>
                            <div style={this.styles.compilation_text_button}>{`Start at ${this.state.compilationTimeDisplay}`}</div>
                            <p onClick={() => this.setState({ timePickerOpen: true })} style={this.styles.compilation_text_edit}>Edit</p>
                            <KeyboardTimePicker
                                ampm={true}
                                value={this.state.compilationTime}
                                format="h:mm A"
                                style={{ display: "none" }}
                                open={this.state.timePickerOpen}
                                onChange={() => {}}
                                onClose={() => this.setState({ timePickerOpen: false })}
                                onAccept={(date) => this.onChangeCompilationTime(date)}
                            />
                        </div>
                    </Fragment>
                ) : this.state.time_period !== "Custom" ? (
                    <Fragment >
                        <div style={this.styles.compilation_text_custom}>
                        <p style={this.styles.compilation_text_time}>The date of the month:</p>
                            <div style={this.styles.compilation_text_button}>{`${this.state.monthlyDateOfTheMonth.length < 2 ? `0${this.state.monthlyDateOfTheMonth}` : this.state.monthlyDateOfTheMonth}`}</div>
                            <p onClick={() => this.setState({ show_modal: true, modal: "dateOfTheMonth" })} style={this.styles.compilation_text_edit}>Edit</p>
                        </div>
                        <div style={this.styles.compilation_text_custom_last}>
                            <p style={this.styles.compilation_text_time}>Time of the compilation:</p>
                            <div style={this.styles.compilation_text_button}>{`Start at ${this.state.compilationTimeDisplay}`}</div>
                            <p onClick={() => this.setState({ timePickerOpen: true })} style={this.styles.compilation_text_edit}>Edit</p>
                            <KeyboardTimePicker
                                ampm={true}
                                value={this.state.compilationTime}
                                format="h:mm A"
                                style={{ display: "none" }}
                                open={this.state.timePickerOpen}
                                onChange={() => {}}
                                onClose={() => this.setState({ timePickerOpen: false })}
                                onAccept={(date) => this.onChangeCompilationTime(date)}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <Fragment >
                        <div style={this.styles.compilation_text_custom}>
                            <p style={this.styles.compilation_text_time}>Day of the week:</p>
                            <div style={this.styles.compilation_text_button}>{`${this.state.customCompilationDayDisplay}`}</div>
                            <p onClick={() => this.setState({ show_modal: true, modal: "weekdayPicker" })} style={this.styles.compilation_text_edit}>Edit</p>
                        </div>
                        <div style={this.styles.compilation_text_custom}>
                            <p style={this.styles.compilation_text_time}>Time of the compilation:</p>
                            <div style={this.styles.compilation_text_button}>{`Start at ${this.state.compilationTimeDisplay}`}</div>
                            <p onClick={() => this.setState({ timePickerOpen: true })} style={this.styles.compilation_text_edit}>Edit</p>
                            <KeyboardTimePicker
                                ampm={true}
                                value={this.state.compilationTime}
                                format="h:mm A"
                                style={{ display: "none" }}
                                open={this.state.timePickerOpen}
                                onChange={() => {}}
                                onClose={() => this.setState({ timePickerOpen: false })}
                                onAccept={(date) => this.onChangeCompilationTime(date)}
                            />
                        </div>
                        <div style={this.styles.compilation_text_custom_last}>
                            <p style={this.styles.compilation_text_time}>Days to the compilation date:</p>
                            <div style={this.styles.compilation_text_button}>{`${this.state.customCompilationDaysToDate}`}</div>
                            <p onClick={() => this.setState({ show_modal: true, modal: "daysToTheCompilationDate" })} style={this.styles.compilation_text_edit}>Edit</p>
                        </div>
                    </Fragment>
                )}
            </div>
        )
    }

    render_data = (type) => {
        return (
            <Fragment>
                {this.state[type].map((item, i) => {
                    return (
                        <span onClick={() => this.onSelectData(type, item.id)} style={item.selected ? this.styles.data_pill_selected : this.styles.data_pill}>
                            {item.name}
                        </span>
                    )
                })}
            </Fragment>
        )
    }

    reportDetails = () => {
        return (
            <div>
                <div style={this.styles.datetime_container}>
                    <div>
                        {this.render_time_period()}
                    </div>
                    <div>
                        {this.render_compilation()}
                    </div>
                </div>
            </div>
        )
    }

    showRecipients = () => {
        return (
            <Table>
                <TableBody>
                    {this.state.factory_users && this.state.factory_users.length > 0 ? (
                        <>
                            {this.state.factory_users.map((user, index) => {
                                return (
                                    <TableRow style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} key={'user-' + user.user_id}>
                                        <TableCell align="left" style={ {...Styles.tableRowColumnStyleShort(), width: "14%"} }>
                                            <Checkbox checked={user.subscribed} color='primary' onClick={() => this.onSubscribe(index)} />
                                        </TableCell>
                                        <TableCell align="left" style={ {...Styles.tableRowColumnStyle(), width: "28%" }}>
                                            {user.user_metadata.first_name && user.user_metadata.first_name.length > 0 ? user.user_metadata.first_name : ""}
                                        </TableCell>
                                        <TableCell align="left" style={ {...Styles.tableRowColumnStyle(), width: "28%" }}>
                                            {user.user_metadata.last_name && user.user_metadata.last_name.length > 0 ? user.user_metadata.last_name : ""}
                                        </TableCell>
                                        <TableCell align="left" style={ {...Styles.tableRowColumnStyle(), width: "28%" }}>
                                            <a style={{ textDecoration: "none", color: "#1975D0" }} href={`mailto:${user.email}`}>{user.email}</a>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </>
                    ) : null}
                </TableBody>
            </Table>
        );
    }

    reportRecipients = () => {
        return (
            <div>
                <div className="settings__table">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={ {...Styles.tableHeaderStyleShort(), width: "14%"} }>
                                    Subscription
                                </TableCell>
                                <TableCell align="left" style={ {...Styles.tableHeaderStyleShort(), width: "28%"} }>
                                    First Name
                                </TableCell>
                                <TableCell align="left" style={ {...Styles.tableHeaderStyleShort(), width: "28%"} }>
                                    Last Name
                                </TableCell>
                                <TableCell align="left" style={ {...Styles.tableHeaderStyleShort(), width: "28%"} }>
                                    Email
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    {this.showRecipients()}
                </div>
            </div>
        )
    }

    showHistory = () => {
        return (
            <Table>
                <TableBody>
                    {this.state.history.map((report, index) => {
                        return (
                            <TableRow style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} key={'history-' + index}>
                                <TableCell align="left" style={ {...Styles.tableRowColumnStyle(), width: "25%"} }>
                                    <div style={{ display: "inline-block" }}>
                                        {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 21.5px", background: `${report.status ? "#1975D0" : "#EB5757"}`, borderRadius: "8px", color: "#F2F2F2" }}> */}
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 21.5px", background: "#1975D0", borderRadius: "8px", color: "#F2F2F2" }}>
                                            {/* { report.status ? <DoneAll fontSize="small" style={{ marginRight: "5px" }} /> : <Clear fontSize="small" style={{ marginRight: "5px" }} /> } */}
                                            {/* {report.status ? "Success" : "Failure"} */}
                                            <DoneAll fontSize="small" style={{ marginRight: "5px" }} />Success
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell align="left" style={ {...Styles.tableRowColumnStyle(), width: "25%"} }>
                                    {report.report_created.toLocaleDateString("en-US", { month: 'short', day: "2-digit", year: "numeric" }).toUpperCase()}
                                </TableCell>
                                <TableCell align="left" style={ {...Styles.tableRowColumnStyle(), width: "25%"} }>
                                    {report.report_time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                </TableCell>
                                <TableCell align="left" style={ {...Styles.tableRowColumnStyle(), width: "25%"} }>
                                    {report.number_of_recipients}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }

    reportHistory = () => {
        return (
            <div>
                <div className="settings__table_history">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={ {...Styles.tableHeaderStyle(), width: "25%"} }>
                                    Report Status
                                </TableCell>
                                <TableCell align="left" style={ {...Styles.tableHeaderStyle(), width: "25%"} }>
                                    Report Created
                                </TableCell>
                                <TableCell align="left" style={ {...Styles.tableHeaderStyle(), width: "25%"} }>
                                    Report Time
                                </TableCell>
                                <TableCell align="left" style={ {...Styles.tableHeaderStyle(), width: "25%"} }>
                                    Number of Recipients
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    {this.showHistory()}
                </div>
            </div>
        )
    }

    reportData = () => {
        console.log(this.state.shifts);
        return (
            <div>
                <div style={this.styles.data_container}>
                    <div style={this.styles.data_column_container}>
                        <p style={this.styles.data_type_title}>Shifts</p>
                        {this.state.shifts && this.state.shifts.length > 0 ? (
                            <p style={this.styles.data_type_container}>
                                {this.render_data("shifts")}
                            </p>
                        ) : (
                            <p style={this.styles.data_no_data_title}>No shifts. Create shifts in the appropriate section</p>
                        )}
                    </div>
                    <div style={this.styles.data_column_container}>
                        <p style={this.styles.data_type_title}>Line</p>
                        {this.state.lines && this.state.lines.length > 0 ? (
                            <p style={this.styles.data_type_container}>
                                {this.render_data("lines")}
                            </p>
                        ) : (
                            <p style={this.styles.data_no_data_title}>No lines. Create lines in the appropriate section</p>
                        )}
                    </div>
                    <div style={this.styles.data_column_container}>
                        <p style={this.styles.data_type_title}>Product Categories</p>
                        {this.state.categories && this.state.categories.length > 0 ? (
                            <p style={this.styles.data_type_container}>
                                {this.render_data("categories")}
                            </p>
                        ) : (
                            <p style={this.styles.data_no_data_title}>No categories. Create categories in the appropriate section</p>
                        )}
                    </div>
                    <div style={this.styles.data_column_container_last}>
                        <p style={this.styles.data_type_title}>Product Tags</p>
                        {this.state.tags && this.state.tags.length > 0 ? (
                            <p style={this.styles.data_type_container}>
                                {this.render_data("tags")}
                            </p>
                        ) : (
                            <p style={this.styles.data_no_data_title}>No tags. Create tags in the appropriate section</p>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        // if (!this.props.type || this.props.type === "products") {
            return (
                <div>
                    {this.modal()}
                    <div className="products__details">
                        {this.props.type === "datetime" ? (
                            this.reportDetails()
                        ) : this.props.type === "data" ? (
                            this.reportData()
                        ) : this.props.type === "recipients" ? (
                            this.reportRecipients()
                        ) : this.props.type === "history" ? (
                            this.reportHistory()
                        ) : null}
                    </div>
                </div>
            );
        // }
        // return null
    }
}

export default EmailedReportsDetails;