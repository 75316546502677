import React, { Component } from 'react';

import { Button, DialogContent, DialogActions, DialogTitle } from "@material-ui/core";

class ViewAllImportErrorsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    showErrors = () => {
        return (
            
        <>
        <DialogTitle>
            {this.props.category}
        </DialogTitle>
        <DialogContent style={{ width: "800px", height: "300px"}}>
            <div>
                {this.props.error[this.props.category].map((item) => {
                    return <p>{item}</p>
                })}
            </div>
        </DialogContent>
        </>

        )
    }

    showButtons() {
        return <DialogActions style={{ margin: "16px" }} >
                    <Button variant="text"
                        color="primary"
                        onClick={() => this.props.hideModal() } >Close</Button>
                </DialogActions>
    }

    render() {
        return <div>
            {this.showErrors()}
            {this.showButtons()}
        </div>
    }

}

export default ViewAllImportErrorsModal