Number.prototype.pad = function (n) {
    return (new Array(n).join('0') + this).slice(-n);
};

class Util {
    formatHourMinute = t => {
        let d = new Date(t);

        let hours = ('0' + d.getHours()).substr(-2, 2);
        let minutes = ('0' + d.getMinutes()).substr(-2, 2);

        let month = ('0' + (d.getMonth() + 1)).substr(-2, 2);
        let date = ('0' + d.getDate()).substr(-2, 2);

        return hours + ':' + minutes;
    };

    formatDate = d => {
        let date = new Date(d);
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return months[date.getMonth()] + ' ' + date.getDate();
    };

    formatYearMonthDate = d => {
        let date = new Date(d);
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return date.getFullYear() + '-' + months[date.getMonth()] + '-' + date.getDate();
    };

    formatDateMMMDDYYYY = d => {
        let date = new Date(d);

        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let hour = ('0' + date.getHours()).substr(-2, 2);
        let minute = ('0' + date.getMinutes()).substr(-2, 2);
        let second = ('0' + date.getSeconds()).substr(-2, 2);
        let suffix = ' AM';

        if (hour > 12) {
            hour = hour - 12;
            suffix = ' PM';
        } else if (hour == 12) {
            suffix = ' PM';
        } else if (hour == 0) {
            hour = 12;
        }

        let pretty_time = hour + ':' + minute + ':' + second + ' ' + suffix;

        return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    };

    formatDayDate = d => {
        let date = new Date(d);
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return days[date.getDay()] + ' ' + months[date.getMonth()] + ' ' + date.getDate();
    };

    formatYearMonthDateURL = d => {
        let date = d ? new Date(d) : new Date();
        let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        let day = ('0' + date.getDate()).substr(-2, 2);
        return date.getFullYear() + months[date.getMonth()] + day;
    };

    formatHourMinuteAmPm(t) {
        let d = new Date(t);

        let hours = d.getHours(); //("0"+(d.getHours())).substr(-2, 2);
        let minutes = ('0' + d.getMinutes()).substr(-2, 2);

        let month = ('0' + (d.getMonth() + 1)).substr(-2, 2);
        let date = ('0' + d.getDate()).substr(-2, 2);

        let suffix = ' AM';

        if (hours > 12) {
            hours = hours - 12;
            suffix = ' PM';
        } else if (hours == 12) {
            suffix = ' PM';
        } else if (hours == 0) {
            hours = 12;
        }

        return hours + ':' + minutes + suffix;
    }

    formatTimeSplitHourMinuteAmPm(t) {
        let d = new Date(t);

        let hours = d.getHours(); //("0"+(d.getHours())).substr(-2, 2);
        let minutes = ('0' + d.getMinutes()).substr(-2, 2);
        let seconds = ('0' + d.getSeconds()).substr(-2, 2);

        let month = ('0' + (d.getMonth() + 1)).substr(-2, 2);
        let date = ('0' + d.getDate()).substr(-2, 2);

        let suffix = 'am';

        if (hours > 12) {
            hours = hours - 12;
            suffix = 'pm';
        } else if (hours == 12) {
            suffix = 'pm';
        } else if (hours == 0) {
            hours = 12;
        }

        return hours + ':' + minutes + ':' + seconds + suffix;
    }

    formatHourMinuteSecondsAmPm(t) {
        let d = new Date(t);

        let hours = d.getHours(); //("0"+(d.getHours())).substr(-2, 2);
        let minutes = ('0' + d.getMinutes()).substr(-2, 2);
        let seconds = ('0' + d.getSeconds()).substr(-2, 2);

        let month = ('0' + (d.getMonth() + 1)).substr(-2, 2);
        let date = ('0' + d.getDate()).substr(-2, 2);

        let suffix = ' AM';

        if (hours > 12) {
            hours = hours - 12;
            suffix = ' PM';
        } else if (hours == 12) {
            suffix = ' PM';
        } else if (hours == 0) {
            hours = 12;
        }

        return hours + ':' + minutes + ':' + seconds + suffix;
    }

    formatHourMinuteSecondsFromSeconds(s) {
        let sec_num = Math.abs(parseInt(s, 10)); // don't forget the second param
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - hours * 3600) / 60);
        let seconds = sec_num - hours * 3600 - minutes * 60;

        // if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        return (s < 0 ? '-' : '') + hours + ':' + minutes + ':' + seconds;
    }

    formatHourMinuteFromMinutes(m) {
        let m_num = Math.abs(parseInt(m, 10));
        let hours = Math.floor(m_num / 60);
        let minutes = Math.floor(m_num - hours * 60);

        if (hours < 10) {
            hours   = '0' + hours;
        }

        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        return (m < 0 ? '-' : '+') + hours + ':' + minutes;
    }

    formatHourMinuteSecondsFromSecondsHMS(s) {
        let sec_num = parseInt(s, 10); // don't forget the second param
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - hours * 3600) / 60);
        let seconds = sec_num - hours * 3600 - minutes * 60;
        let hours_display = hours + 'h ';
        let minutes_display = minutes + 'm ';
        if (hours === 0) {
            hours_display = '';
        }
        if (minutes === 0) {
            minutes_display = '';
        }

        // if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        return hours_display + minutes_display + seconds + 's';
    }

    formatMinuteSecondsFromSeconds(s) {
        return new Date(1000 * s)
            .toISOString()
            .substr(11, 8)
            .replace(/^[0:]+/, '');
    }

    formatFullDateTime(d) {
        if (d != null) {
            let date = new Date(d);

            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            let hour = date.getHours(); //("0"+(date.getHours())).substr(-2, 2);
            let minute = ('0' + date.getMinutes()).substr(-2, 2);
            let second = ('0' + date.getSeconds()).substr(-2, 2);
            let suffix = ' AM';

            if (hour > 12) {
                hour = hour - 12;
                suffix = ' PM';
            } else if (hour == 12) {
                suffix = ' PM';
            } else if (hour == 0) {
                hour = 12;
            }

            let pretty_time = hour + ':' + minute + ':' + second + ' ' + suffix;

            return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear() + ' ' + pretty_time;
        }

        return 'Run In Progress';
    }

    formatShortDateTime = d => {
        if (d != null) {
            let date = new Date(d);

            let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            let hour = date.getHours(); //("0"+(date.getHours())).substr(-2, 2);
            let minute = ('0' + date.getMinutes()).substr(-2, 2);
            let second = ('0' + date.getSeconds()).substr(-2, 2);
            let suffix = ' AM';

            if (hour > 12) {
                hour = hour - 12;
                suffix = ' PM';
            } else if (hour == 12) {
                suffix = ' PM';
            } else if (hour == 0) {
                hour = 12;
            }

            let pretty_time = hour + ':' + minute + ' ' + suffix;

            return pretty_time + ' - ' + date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
        }

        return 'Run In Progress';
    };

    formatDateFull = d => {
        let date = new Date(d);

        let months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        let hour = ('0' + date.getHours()).substr(-2, 2);
        let minute = ('0' + date.getMinutes()).substr(-2, 2);
        let second = ('0' + date.getSeconds()).substr(-2, 2);
        let suffix = ' AM';

        if (hour > 12) {
            hour = hour - 12;
            suffix = ' PM';
        } else if (hour == 12) {
            suffix = ' PM';
        } else if (hour == 0) {
            hour = 12;
        }

        return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    };

    validateJulianDate = date => {
        // Since we are not taking into account days, the function will only verify the numberOfYearsInPast minus 364 days
        let numberOfYearsInPast = 4;

        let now = new Date();
        let start = new Date(now.getFullYear(), 0, 0);
        let diff = now - start + (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
        let oneDay = 1000 * 60 * 60 * 24;
        let currentDay = Math.floor(diff / oneDay);
        let currentYear = new Date().getFullYear().toString().substr(3);
        let currentJulianDate = currentDay.pad(3) + currentYear;

        // Get years and days for Julian dates
        let currentJulianDateYear = currentJulianDate.substring(3);
        let currentJulianDateDays = currentJulianDate.substring(0, 3);
        let julianDateYear = date.substr(3);
        let julianDateDays = date.substr(0, 3);

        // Check if same year. If so, look at dates
        if (currentJulianDateYear == julianDateYear) {
            if (julianDateDays <= currentJulianDateDays) {
                // In past of same year
                return true;
            } else {
                // In future
                return false;
            }
        }
        // Both years are in same cycle. No reset for either.
        else if (currentJulianDateYear > julianDateYear) {
            // Less than numberOfYearsInPast of a difference
            if (currentJulianDateYear - julianDateYear < numberOfYearsInPast) {
                return true;
            }
            // Less than numberOfYearsInPast of a difference
            else {
                return false;
            }
        }

        // Check case where Julian date year restarted or is in future (julianDateYear > currentJulianDateYear)
        else {
            // Traverse numberOfYearsInPast from current year
            for (let i = 0; i < numberOfYearsInPast; i++) {
                currentJulianDate--;
                if (currentJulianDate < 0) {
                    currentJulianDate = 10 - currentJulianDate;
                }

                if (currentJulianDate == julianDateYear) {
                    return true;
                }
            }
        }

        return false;
    };

    formatBatches = batches => {
        let batchesString = '';

        batches.map((item, index) => {
            if (item.batch_time != null && item.batch_time != 'null') {
                let batchTime = new Date(item.batch_time);
                let batchCalendar = batchTime;
                let calendar = new Date();
                calendar.setHours(0, 0, 0, 0);
                batchTime.setHours(0, 0, 0, 0);

                if (batchTime != calendar) {
                    batchesString += item.batch_number + ' - ' + this.GetJulianDateFromCalendar(batchCalendar);
                } else {
                    batchesString += item.batch_number + ' (' + item.batch_cases + ')';
                }
            } else if (item.batch_number == 0 || item.batch_number == null) {
                batchesString += 'No Batches (' + item.batch_cases + ')';
            } else {
                batchesString += item.batch_number + ' (' + item.batch_cases + ')';
            }

            if (index != batches.length - 1) {
                batchesString += ', ';
            }
        });

        return batchesString;
    };

    GetJulianDateFromCalendar = calendar => {
        let GCalendar = new Date(calendar);

        let start = new Date(GCalendar.getFullYear(), 0, 0);
        let diff = GCalendar - start + (start.getTimezoneOffset() - GCalendar.getTimezoneOffset()) * 60 * 1000;

        let oneDay = 86400000; //one day millis
        let currentDay = Math.floor(diff / oneDay);
        let currentYear = new Date(calendar).getFullYear().toString().substr(3);
        return currentDay.pad(3) + currentYear;
    };

    roundTo = (n, digits) => {
        if (digits === undefined) {
            digits = 0;
        }

        let multiplicator = Math.pow(10, digits);
        n = parseFloat((n * multiplicator).toFixed(11));
        let test = Math.round(n) / multiplicator;
        return +test.toFixed(digits);
    };

    disableFutureDates = date => {
        if (date >= new Date()) {
            return true;
        } else {
            return false;
        }
    };

    convertFromKg = (amountKg, unitTo) => {
        switch (unitTo) {
            case 'kg':
                return amountKg;
            case 'g':
                return amountKg * 1000;
            case 'lbs':
                return amountKg * 2.20462262185;
        }
    };

    convertToKg = (amount, unitFrom) => {
        switch (unitFrom) {
            case 'kg':
                return amount;
            case 'g':
                return amount / 1000;
            case 'lbs':
                return amount / 2.20462262185;
        }
    };

    CalculateEfficiency(startTimeMillis, endTimeMillis, totalOutput, targetSpeedPerMin) {
        let actualTimeMillis;

        actualTimeMillis = endTimeMillis - startTimeMillis;

        let potentialTimeSecs = targetSpeedPerMin === 0 ? 0 : totalOutput / (targetSpeedPerMin / 60);
        let actualTimeSecs = actualTimeMillis / 1000;

        let efficiency;

        if (actualTimeSecs === 0 || potentialTimeSecs === 0) {
            efficiency = 0;
        } else {
            efficiency = (potentialTimeSecs / actualTimeSecs) * 100;
        }

        if (!isFinite(efficiency)) {
            efficiency = 0;
        }

        return efficiency.toFixed(2) + '%';
    }

    numberWithCommas = x => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    numberWithCommasAndFractions = (x, fraction = 2) => {
        if (isNaN(x)) return '';

        return x
            .toFixed(fraction)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    //checks if an object is empty
    isEmpty = obj => {
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) return false;
        }

        return true;
    };

    //Modulo % function for negative numbers
    mod = (n, m) => {
        return ((n % m) + m) % m;
    };

    disableFromFutureDates = date => {
        return date >= Date.now();
    };

    replaceZeroWithBlank = (value, percent) => {
        let v = value;

        if (v == 0) {
            v = '';
        } else if (percent == '%') {
            v += percent;
        }

        return v;
    };

    formatTime(t) {
        let d = new Date(t);

        let hours = d.getHours();
        let minutes = ('0' + d.getMinutes()).substr(-2, 2);
        let seconds = ('0' + d.getSeconds()).substr(-2, 2);
        let ampm = d.getHours() >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        // hours = ("0"+(hours)).substr(-2, 2);

        return hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    }

    calculateAverageSpeed = runs => {
        let average_speed = 0;
        let total_actual = 0;
        let total_downtime = 0;
        runs.forEach(r => {
            total_actual += r.actual_seconds;
            total_downtime += r.downtime_seconds;
        });

        let total_uptime = total_actual - total_downtime;

        runs.forEach(r => {
            let amt = ((r.actual_seconds - r.downtime_seconds) / total_uptime) * r.average_speed_pieces_per_min;
            average_speed += amt;
        });

        return average_speed.toFixed(2);
    };

    shouldDisplayOutput = line => {
        const userFactory = JSON.parse(window.localStorage.getItem("factory"))
        return userFactory?.id === "agrocrop_2021_11_12" && line.line_id === 126
    }
}

export default new Util();
