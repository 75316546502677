import React, {Component} from 'react';

class CustomEfficiencyReferenceLineLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */

    /* -------- FUNCTIONS -------- */

    /* -------- API CALLS -------- */

    /* -------- RENDERS -------- */

    render() {
        const {x, y, width, height, targetLabel, viewBox} = this.props;

        return <g transform={`translate(${viewBox.x},${viewBox.y})`}>
            <text x={130} y={0} dy={-4} textAnchor={'middle'} style={{fontFamily: 'Roboto'}} fill="#999" fontSize="14px">
                Target {localStorage.getItem("efficiency_string_short") !== 'undefined' ? localStorage.getItem("efficiency_string_short") : "OEE"}
            </text>
            <text x={130} y={24} dy={-4} textAnchor={'middle'} style={{fontFamily: 'Roboto'}} fill="#999" fontSize="24px">
                {targetLabel}
            </text>
        </g>
    }
}

export default CustomEfficiencyReferenceLineLabel