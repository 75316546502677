import React, { Component } from 'react';
import HeaderTopNav from '../components/HeaderTopNav'
import SideNav from '../components/SideNav'
import NotAllowedImage from './misc/NotAllowedImage.svg'

class NotAllowed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: true,
            factory: {}
        }
    }

    onCollapseClickChange = (collapse) => {
        this.setState({ collapse })
    }

    getFactorySettings = () => {
        let params = {
            path: 'get_factory_settings',
            success: (data) => this.onGetFactorySettingsSuccess(data),
            error: () => {}
        };

        API.c(params, 2);
    };

    onGetFactorySettingsSuccess = (data) => {
        localStorage.setItem("efficiency_string_long", data.efficiency_string_long)
        localStorage.setItem("efficiency_string_short", data.efficiency_string_short)
    }

    render() {
        return (
            <>
                <HeaderTopNav onFactoryChange={(factory) => { this.setState({ factory }); this.getFactorySettings() }} />
                <SideNav
                    onShowScoreboardClicked={() => { }}
                    onCollapseClick={this.onCollapseClickChange}
                    title="Settings"
                />
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div style={{ textAlign: "center", position: "absolute", top: "24%", width: "400px" }}>
                                <p style={{ fontSize: "28px", fontWeight: "700", marginBottom: "14px" }}>403 - Oh no!</p>
                                <p style={{ fontSize: "24px", fontWeight: "500" }}>Oh no! It looks like you aren't allowed
                                                                                    to access the page you requested.
                                                                                    Please contact your administrator
                                                                                    or support@livetracking.io if you 
                                                                                    need help.
                                </p>
                            </div>
                        </div>
                        <img style={{ margin: "auto", display: "block" }} width="75%" src={NotAllowedImage} />
                    </div>
                </div>
            </>
        )
    }
}

export default NotAllowed