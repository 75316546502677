import React, { Component } from 'react';
import FontIcon from 'material-ui/FontIcon';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.setKeyListener();
  }

  componentWillUnmount() {
    this.cancelKeyListener();
  }

  setKeyListener = () => {
      window.onkeydown = (event) => {
          if (event.keyCode === 27) {
              this.props.hideModal();
          }
      };
  }

  cancelKeyListener = () => {
      window.onkeydown = null;
  }


  hideModal = (e) => {
    this.props.hideModal();
    e.preventDefault();
    e.stopPropagation();
  }


  hideModalWithKey = (e) => {
    if (e.key == 27) {
      this.props.hideModal();
    }
  }


  render () {

    let classExt = '';
    if(this.props.class_name !== undefined){
      classExt = this.props.class_name
    }
    return <div className="modal" onKeyUp={this.hideModalWithKey} >
      <div
        className="modal__bg"
        onClick={this.hideModal}
        />

      <div className={"modal__content"+classExt}>
        <div className="modal__content-title">
          <div className="modal__content-title__text">{this.props.title}</div>
          <div
            className="modal__content-title__close"
            onClick={this.props.hideModal}>
            <FontIcon className="zmdi zmdi-close" />
          </div>
        </div>
        <div className="modal__content-body">{this.props.children}</div>
      </div>
    </div>;
  }
}

export default Modal;
