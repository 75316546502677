import React, {Component} from 'react';
import {FontIcon} from "material-ui";
import {Button} from "@material-ui/core";

class Dropdown extends Component {
    /***
     * <Dropdown title={STRING}  handleOpenMenu={function} getLabel={function} labelStyle={{}}>
     *     <Popover
                 open={this.state.open}
                 anchorEl={this.state.anchorEl}
                 anchorOrigin={this.state.anchorOrigin}
                 targetOrigin={this.state.targetOrigin}
                 onRequestClose={this.handleClose}>
                 <Menu onChange={this.handleMenuClick.bind(this)}>
                 {this.iterateLines()}
                 </Menu>
             </Popover>
 *     </Dropdown>
    */
    constructor(props) {
        super(props);
        this.state = {}
    }

    showLines = () => {
        return <div className="header__subhead-text margin-bottom-bg">
            <div className='flex-box__row'>
                <div>{this.props.title}</div>
                <div className="header__subhead-text">
                    <Button
                        className='lines--dropdown'
                        onClick={this.props.handleOpenMenu}
                        style={this.props.labelStyle}
                        endIcon={<FontIcon style={{...this.props.labelStyle, fontSize: '48px'}} className="zmdi zmdi-caret-down"/>}>
                        {this.props.getLabel()}
                    </Button>
                    {this.props.children}
                </div>
            </div>
        </div>
    }

    render() {
        return <div>
            {this.showLines()}
        </div>
    }
}

export default Dropdown