import React, {Component} from 'react';

class CustomLostTimeBarLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */


    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    render() {
        const {x, y, width, height, value, viewBox} = this.props;

        return <g transform={`translate(${x + width},${y + (height/ 2)})`}>
            <text x={2} y={0} dy={4} textAnchor={'start'} style={{fontFamily: 'Arial'}} fill="#000" fontSize="10px">
                {parseFloat(value).toFixed(1) + '%'}
            </text>
        </g>

    }
}

export default CustomLostTimeBarLabel