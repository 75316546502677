import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import FilterButton from './filter-button';
import moment from 'moment-timezone';

import Button from '@material-ui/core/Button';
import CheckboxList from '../../daily/by-shift-top/checkbox-list';
import { useWindowDimensions } from '../../../../utils/getWindowDimentions';

Date.prototype.addDays = function (days) {
    const dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
};

function getDates(startDate, stopDate) {
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(currentDate);
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}

const Filters = ({
    start_time,
    end_time,
    lines,
    timezone,
    onGetRunSuccessApply,
    onGetRunSuccess,
    runLineId,
    runReportPage,
    run,
    run_id,
    setCurrentPage,
    handlePageClick,
    lineProducts
}) => {
    const [wasChange, setWasChange] = useState(false);
    const wasChanged = () => {
        setWasChange(true);
    };
    const { width } = useWindowDimensions();
    const getRowNumber = width => {
        if (width >= 1170) return 6;
        if (width < 1170 && width >= 1000) return 5;
        if (width < 1000 && width >= 830) return 4;
        if (width < 830 && width >= 660) return 3;
        if (width < 660) return 2;
    };
    const runReport = 'runReport';
    const newStartDate = start_time ? start_time.replace(/ /g, 'T') : null;
    const newEndDate = end_time ? end_time.replace(/ /g, 'T') : null;
    let date_from = new Date(newStartDate);
    let date_to = end_time ? new Date(newEndDate) : new Date();
    const dateMomentStart = moment(newStartDate).tz(timezone).format('DDD');
    const dateMomentEnd = moment(date_to).tz(timezone).format('DDD');
    const difference_in_day = +dateMomentEnd - dateMomentStart;
    const datesList = getDates(date_from, date_from.addDays(difference_in_day));
    const [datesLog, setDatesLog] = useState([]);
    const [isAddHeight, setIsAddHeight] = useState(false);
    const [selectedButton, setSelectedButton] = useState(0);
    const [displayButton, setDisplayButton] = useState(false);
    const [isDayList, setIsDayList] = useState(true);
    const rowNumber = getRowNumber(width);
    const firstUpdate = useRef(true);
    const shifts = JSON.parse(localStorage.getItem(`shiftIds${runReport}`));

    useEffect(() => {
        let params = {
            path: 'shift_for_run',
            data: {
                run: run_id
            },
            success: resp => {
                setDatesLog(resp.dates);
            }
        };
        API.c(params);
    }, [run_id]);
    const buttonHeight = 60;
    const buttonMarginBottom = 10;
    const newDates = datesLog.map(day => moment(day).format('DD'));
    const currentDates = datesList.filter(day => newDates.includes(moment(day).tz(timezone).format('DD')));
    const dates = isDayList ? currentDates.slice(0, rowNumber - 1) : currentDates.slice();

    const handleSubmitApply = () => {
        if (runReportPage) {
            setCurrentPage();
            handlePageClick();
            const shifts_dox = JSON.parse(localStorage.getItem(`shiftIds${runReport}`));
            const checked = JSON.parse(localStorage.getItem(`checkedItems${runReport}`));
            let shifts_selected = [];
            if (shifts_dox && checked) {
                let filterShifts = checked.map((item, index) => {
                    if (item) {
                        shifts_selected.push(shifts_dox[index]);
                    }
                });
            }
            const shiftsWithDates = shifts_selected.map(
                s =>
                    `${s}:${moment(dates[selectedButton - 1])
                        .tz(timezone)
                        .format('YYYY-MM-DD')}`
            );
            onGetRunSuccessApply({
                shiftIndexes: shiftsWithDates,
                runLineId: runLineId
            });
        }
    };

    const selectDefault = () => {
        setSelectedButton(0);
        setIsAddHeight(false);
        setDisplayButton(false);
    };

    const handleDatesChange = () => {
        setIsDayList(!isDayList);
        if (selectedButton < rowNumber) {
            setSelectedButton(selectedButton);
        } else {
            selectDefault();
        }
    };

    useEffect(() => {
        if (currentDates.length >= rowNumber && selectedButton / rowNumber >= 1 && isDayList) {
            selectDefault();
        }
    }, [width, selectedButton]);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (selectedButton === 0) {
            onGetRunSuccessApply({
                shiftIndexes: [],
                runLineId: runLineId
            });
        }
    }, [selectedButton]);

    const handleSelectButton = index => {
        setSelectedButton(index);
        if (index === 0) {
            setIsAddHeight(false);
        } else {
            setIsAddHeight(true);
        }

        if (index > 0) {
            setDisplayButton(true);
        }

        if (index === 0) {
            setDisplayButton(false);
        }
    };

    return (
        <React.Fragment>
            <div style={{ position: 'relative', width: '10px' }}>
                <div style={{ marginLeft: '-24px' }}>
                    <div
                        style={{
                            width: `${156 * rowNumber}px`
                        }}
                    >
                        <div
                            style={{
                                maxWidth: `${156 * rowNumber}px`,
                                position: 'relative',
                                paddingBottom: currentDates.length >= rowNumber ? 0 : '20px'
                            }}
                        >
                            {['ENTIRE RUN', ...dates].map((item, index) => {
                                return (
                                    <FilterButton
                                        key={`filter-${index}`}
                                        handleSelectButton={handleSelectButton}
                                        selected={selectedButton}
                                        id={index}
                                        buttonHeight={buttonHeight}
                                        value={index === 0 ? item : moment(item).tz(timezone).format('MMM D YYYY')}
                                        lineProducts={lineProducts}
                                    />
                                );
                            })}
                        </div>
                        {currentDates.length >= rowNumber && (
                            <div style={{ paddingBottom: '30px' }}>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => handleDatesChange()}
                                    style={{
                                        width: 136,
                                        height: buttonHeight,
                                        zIndex: 3
                                    }}
                                >
                                    {isDayList ? 'SHOW MORE' : 'SHOW LESS'}
                                </Button>
                            </div>
                        )}
                        <div
                            style={{
                                display: displayButton ? 'block' : 'none'
                            }}
                        >
                            <div style={{ paddingBottom: '30px', marginTop: '-20px' }}>
                                <CheckboxList
                                    filterBy={runReport}
                                    wasChanged={wasChanged}
                                    lines={lines}
                                    timeZone={timezone}
                                    onGetRunSuccess={onGetRunSuccess}
                                    runLineId={runLineId}
                                    runReportPage={runReportPage}
                                    run={run}
                                    run_id={run_id}
                                    dates={[...dates]}
                                    selectItem={selectedButton}
                                    date_to={date_to}
                                    rowNumber={rowNumber}
                                    isDayList={isDayList}
                                    setCurrentPage={setCurrentPage}
                                    selectedButton={selectedButton}
                                    handlePageClick={handlePageClick}
                                    handleSubmitApply={handleSubmitApply}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Filters;
