import React, { Component } from 'react';
import SideNav from '../components/SideNav';

import {
    Button,
    TextField,
    CircularProgress,
    Typography,
    Checkbox,
    FormControlLabel,
    MenuItem
} from '@material-ui/core';

import Footer from '../components/Footer';
import HeaderTopNav from '../components/HeaderTopNav';
import { auth0UserManagementAPIInstance } from '../components/Auth0API';
import MuiPhoneNumber from 'material-ui-phone-number';
import NotificationScheduleRow from '../components/NotificationScheduleRow';
import notificationScheduleOptions from '../utils/notificationScheduleOptions';
import allTimezones from '../utils/timezonesList';
import moment from 'moment-timezone';

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: false,
            first_name: '',
            last_name: '',
            phone_number: '',
            email: '',
            is_send_email: false,
            is_send_push: false,
            is_send_sms: false,
            reset_status: null,
            resetting: false,
            current_password: '',
            current_password_error: null,
            new_password: '',
            new_password_error: null,
            new_password_confirm: '',
            new_password_confirm_error: null,
            update_email_error: null,
            update_first_name_error: null,
            update_last_name_error: null,
            update_phone_number_error: null,
            update_username_error: null,
            updating: false,
            update_status: null,
            collapse: true,
            user_timezone: '',
            allow_notification_sunday_start_time: '',
            allow_notification_sunday_end_time: '',
            allow_notification_monday_start_time: '',
            allow_notification_monday_end_time: '',
            allow_notification_tuesday_start_time: '',
            allow_notification_tuesday_end_time: '',
            allow_notification_wednesday_start_time: '',
            allow_notification_wednesday_end_time: '',
            allow_notification_thursday_start_time: '',
            allow_notification_thursday_end_time: '',
            allow_notification_friday_start_time: '',
            allow_notification_friday_end_time: '',
            allow_notification_saturday_start_time: '',
            allow_notification_saturday_end_time: '',
            timezonesList: allTimezones
        };
    }

    async componentDidMount() {
        let userMetadata = Auth0.getUserMetadata();
        let email = Auth0.getUserEmail();

        if (userMetadata) {
            let phone_number = userMetadata.phone_number;
            if (phone_number && phone_number.length === 10) {
                phone_number = '1' + phone_number;
            } else {
                phone_number = '';
            }

            this.setState({
                first_name: userMetadata.first_name,
                last_name: userMetadata.last_name,
                phone_number: phone_number,
                email: email,
                is_send_email: userMetadata.is_send_email,
                is_send_push: userMetadata.is_send_push,
                is_send_sms: userMetadata.is_send_sms,
                user_timezone: userMetadata.user_timezone || this.getUserCurrentTimezone(),
                allow_notification_sunday_start_time: this.getNotificationTimeValue(userMetadata.allow_notification_sunday_start_time),
                allow_notification_sunday_end_time: this.getNotificationTimeValue(userMetadata.allow_notification_sunday_end_time),
                allow_notification_monday_start_time: this.getNotificationTimeValue(userMetadata.allow_notification_monday_start_time),
                allow_notification_monday_end_time: this.getNotificationTimeValue(userMetadata.allow_notification_monday_end_time),
                allow_notification_tuesday_start_time: this.getNotificationTimeValue(userMetadata.allow_notification_tuesday_start_time),
                allow_notification_tuesday_end_time: this.getNotificationTimeValue(userMetadata.allow_notification_tuesday_end_time),
                allow_notification_wednesday_start_time: this.getNotificationTimeValue(userMetadata.allow_notification_wednesday_start_time),
                allow_notification_wednesday_end_time: this.getNotificationTimeValue(userMetadata.allow_notification_wednesday_end_time),
                allow_notification_thursday_start_time: this.getNotificationTimeValue(userMetadata.allow_notification_thursday_start_time),
                allow_notification_thursday_end_time: this.getNotificationTimeValue(userMetadata.allow_notification_thursday_end_time),
                allow_notification_friday_start_time: this.getNotificationTimeValue(userMetadata.allow_notification_friday_start_time),
                allow_notification_friday_end_time: this.getNotificationTimeValue(userMetadata.allow_notification_friday_end_time),
                allow_notification_saturday_start_time: this.getNotificationTimeValue(userMetadata.allow_notification_saturday_start_time),
                allow_notification_saturday_end_time: this.getNotificationTimeValue(userMetadata.allow_notification_saturday_end_time)
            });
        }
        this.setState({
            user: true
        });
        document.title = 'LiveTracking | Accounts';
        if (window.ga) {
            window.ga('set', 'page', '/account');
            window.ga('send', 'pageview');
        }
    }

    getUserCurrentTimezone = () => {
        const userTimezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone
        let userTimezoneObj
        if (userTimezone) {
            userTimezoneObj = allTimezones.find((a) => moment().tz(a.key).utcOffset() === moment().tz(userTimezone).utcOffset())
        }

        if (!userTimezoneObj) {
            userTimezoneObj = {
                key: userTimezone,
                label: userTimezone.split('/')[1] || userTimezone
            }
            const updatedTimezones = [...allTimezones];
            updatedTimezones.push(userTimezoneObj)
            this.setState({
                timezonesList: updatedTimezones
            })
        }

        return userTimezoneObj ? userTimezoneObj.key : userTimezone;
    }

    getNotificationTimeValue = (time) => {
        if (!time) {
            return 0;
        }

        const timeOption = notificationScheduleOptions.find(option => option.key === time);

        return timeOption ? timeOption.value : 0;
    };


    handleChange(event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    handlePhoneNumberChange(value) {

        // hard-coding the +1
        let v = value;
        if (v[1] !== '1') {
            v = '+1' + value.substr(1);
        }
        this.setState({
            phone_number: v
        });
    }

    onIsSendEmailChange = (event, isInputChecked) => {
        this.setState({ is_send_email: isInputChecked });
    };

    onIsSendSMSChange = (event, isInputChecked) => {
        this.setState({ is_send_sms: isInputChecked });
    };

    onIsSendPushChange = (event, isInputChecked) => {
        this.setState({ is_send_push: isInputChecked });
    };

    onStartChange = (event, day) => {
        const startKey = `allow_notification_${day}_start_time`;
        const endKey = `allow_notification_${day}_end_time`;
        let s = this.state;
        s[startKey] = event.target.value;
        if (parseInt(event.target.value) >= parseInt(s[endKey])) {
            s[endKey] = parseInt(event.target.value) + 1;
        }

        this.setState(s);
    };

    onEndChange = (event, day) => {
        const key = `allow_notification_${day}_end_time`;
        let s = this.state;
        s[key] = event.target.value;
        this.setState(s);
    };

    showUpdateButton() {
        if (this.state.updating === false) {
            return <Button
                variant={'contained'}
                color={'primary'}
                onClick={this.submitUpdate.bind(this)}>Update</Button>;
        } else {
            return <CircularProgress variant='indeterminate' />;
        }
    }

    validateUpdateInputs() {
        let error_free = true;

        this.setState({
            update_username_error: null,
            update_email_error: null,
            update_first_name_error: null,
            update_last_name_error: null,
            update_phone_number_error: null
        });

        if (this.state.first_name.length < 1) {
            error_free = false;
            this.setState({
                update_first_name_error: 'First Name can\'t be blank'
            });
        }

        if (this.state.last_name.length < 1) {
            error_free = false;
            this.setState({
                update_last_name_error: 'Last Name can\'t be blank'
            });
        }

        const phoneNumber = this.state.phone_number.replace(/[- )(+]/g, '');
        // if (!phoneNumber || phoneNumber.length !== 11 || Number.isNaN(parseInt(phoneNumber))) {
        //     error_free = false;
        //     this.setState({
        //         update_phone_number_error: 'Valid phone number required'
        //     });
        // }

        return error_free;
    }

    getNotificationTimeKey = (value) => {
        if (value === 0) {
            return null;
        }

        const timeOption = notificationScheduleOptions.find(option => option.value === value);
        return timeOption ? timeOption.key : null;
    };

    submitUpdate() {
        if (!this.validateUpdateInputs()) {
            this.setState({ update_status: null })
            return false;
        }

        this.setState({
            updating: true,
            update_status: null,
            update_username_error: null,
            update_email_error: null,
            update_first_name_error: null,
            update_last_name_error: null,
            update_phone_number_error: null
        });

        const phoneNumber = this.state.phone_number
            .replace(/[- )(+]/g, '')
            .substr(1, 10);

        auth0UserManagementAPIInstance
            .put('auth0/user/profile', {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                phone_number: phoneNumber,
                is_send_email: this.state.is_send_email,
                is_send_push: this.state.is_send_push,
                is_send_sms: this.state.is_send_sms,
                user_timezone: this.state.user_timezone,
                allow_notification_sunday_start_time: this.getNotificationTimeKey(this.state.allow_notification_sunday_start_time),
                allow_notification_sunday_end_time: this.getNotificationTimeKey(this.state.allow_notification_sunday_end_time),
                allow_notification_monday_start_time: this.getNotificationTimeKey(this.state.allow_notification_monday_start_time),
                allow_notification_monday_end_time: this.getNotificationTimeKey(this.state.allow_notification_monday_end_time),
                allow_notification_tuesday_start_time: this.getNotificationTimeKey(this.state.allow_notification_tuesday_start_time),
                allow_notification_tuesday_end_time: this.getNotificationTimeKey(this.state.allow_notification_tuesday_end_time),
                allow_notification_wednesday_start_time: this.getNotificationTimeKey(this.state.allow_notification_wednesday_start_time),
                allow_notification_wednesday_end_time: this.getNotificationTimeKey(this.state.allow_notification_wednesday_end_time),
                allow_notification_thursday_start_time: this.getNotificationTimeKey(this.state.allow_notification_thursday_start_time),
                allow_notification_thursday_end_time: this.getNotificationTimeKey(this.state.allow_notification_thursday_end_time),
                allow_notification_friday_start_time: this.getNotificationTimeKey(this.state.allow_notification_friday_start_time),
                allow_notification_friday_end_time: this.getNotificationTimeKey(this.state.allow_notification_friday_end_time),
                allow_notification_saturday_start_time: this.getNotificationTimeKey(this.state.allow_notification_saturday_start_time),
                allow_notification_saturday_end_time: this.getNotificationTimeKey(this.state.allow_notification_saturday_end_time)
            })
            .then(res => {
                this.updateSuccess(res.data);
            })
            .catch(err => {
                this.setState({ update_status: null })
                this.updateError((err && err.response && err.response.data && err.response.data.message) ?
                    err.response.data.message :
                    'Error Updating Account Information'
                );
            });
    }


    updateSuccess = (data) => {
        Auth0.refreshToken((err, authResult) => {
            if (authResult) {
                Auth0.setSession(authResult);
            } else {
                window.localStorage.setItem('userMetadata', JSON.stringify(data.user_metadata));
            }
        });
        this.setState({
            updating: false,
            update_status: 'You have updated your account details.',
            update_username_error: null,
            update_first_name_error: null,
            update_last_name_error: null,
            update_email_error: null
        });
    };


    updateError = (error) => {
        this.setState({
            submitting_register: false,
            submit_error: error
        });
    };


    showUpdateStatus() {
        if (this.state.update_status) {
            return <div className='update-status__success'>{this.state.update_status}</div>;
        } else {
            return <div />;
        }
    }


    editUserInfo() {
        if (!this.state.user) {
            return <div>Loading account info...</div>;
        }

        return <div>
            <div className={'account_details-div'}>
                <TextField
                    className='account_text_field'
                    id='first_name'
                    label='First name'
                    value={this.state.first_name}
                    onChange={this.handleChange.bind(this)}
                    error={this.state.update_first_name_error !== null}
                    helperText={this.state.update_first_name_error !== null ? this.state.update_first_name_error : ''}
                />
                <TextField
                    id='last_name'
                    label='Last name'
                    className='account_text_field'
                    value={this.state.last_name}
                    onChange={this.handleChange.bind(this)}
                    error={this.state.update_last_name_error !== null}
                    helperText={this.state.update_last_name_error !== null ? this.state.update_last_name_error : ''}
                />
                <MuiPhoneNumber defaultCountry={'us'}
                                value={this.state.phone_number}
                                label='Phone Number'
                                className='account_text_field'
                    // disableCountryCode
                                disableDropdown
                                countryCodeEditable={false}
                                onChange={this.handlePhoneNumberChange.bind(this)}
                                error={this.state.update_phone_number_error !== null}
                                helperText={this.state.update_phone_number_error !== null ? this.state.update_phone_number_error : ''} />
                <div className='account_text_field'>
                    <label className='account_email-label'>
                        Email
                    </label>
                    <div className='account_email-div'>
                        {this.state.email}
                    </div>
                </div>
            </div>
            <hr color={'#b9b9b9'}/>
            <Typography variant={'h6'}>Notification Delivery</Typography>
            <div className={'account_notification-delivery-div'}>
                <FormControlLabel control={<Checkbox onChange={this.onIsSendEmailChange}
                                                     color='primary'
                                                     checked={this.state.is_send_email} />}
                                  label='E-mail' />
                <FormControlLabel control={<Checkbox onChange={this.onIsSendSMSChange}
                                                     color='primary'
                                                     checked={this.state.is_send_sms} />}
                                  label='SMS' />
                <FormControlLabel control={<Checkbox onChange={this.onIsSendPushChange}
                                                     color='primary'
                                                     checked={this.state.is_send_push} />}
                                  label='Push notification' />
            </div>
            <hr color={'#b9b9b9'}/>
            <div className={'account_notification-schedule-div'}>
                <Typography variant={'h6'}>Notification Schedule</Typography>
                <Typography variant={'body1'}>You'll only receive notifications in the hours you choose.</Typography>
                <Typography variant={'body1'}>Outside of those times, notifications will be paused.</Typography>
                <TextField select
                           size='small'
                           className={'account_timezone_text_field'}
                           variant={'outlined'}
                           color={'primary'}
                           value={this.state.user_timezone}
                           onChange={(event) => this.setState({ user_timezone: event.target.value })}
                           label={'Notifications Timezone'}
                           helperText={'Used to send notifications'}
                           SelectProps={{ MenuProps: {classes: { paper: 'account_dropdown'}} }}
                           FormHelperTextProps={{ variant: 'standard'}}
                >
                    {this.state.timezonesList
                        .sort((a, b) => moment().tz(a.key).utcOffset() - moment().tz(b.key).utcOffset())
                        .map(zone => {
                            return <MenuItem key={zone.key}
                                             value={zone.key}>
                                {'(UTC' + Util.formatHourMinuteFromMinutes(moment().tz(zone.key).utcOffset()) + ') ' + zone.label}
                            </MenuItem>;
                        })}
                </TextField>
                <NotificationScheduleRow day={'monday'}
                                         start={this.state.allow_notification_monday_start_time}
                                         end={this.state.allow_notification_monday_end_time}
                                         onStartChange={this.onStartChange}
                                         onEndChange={this.onEndChange} />
                <NotificationScheduleRow day={'tuesday'}
                                         start={this.state.allow_notification_tuesday_start_time}
                                         end={this.state.allow_notification_tuesday_end_time}
                                         onStartChange={this.onStartChange}
                                         onEndChange={this.onEndChange} />
                <NotificationScheduleRow day={'wednesday'}
                                         start={this.state.allow_notification_wednesday_start_time}
                                         end={this.state.allow_notification_wednesday_end_time}
                                         onStartChange={this.onStartChange}
                                         onEndChange={this.onEndChange} />
                <NotificationScheduleRow day={'thursday'}
                                         start={this.state.allow_notification_thursday_start_time}
                                         end={this.state.allow_notification_thursday_end_time}
                                         onStartChange={this.onStartChange}
                                         onEndChange={this.onEndChange} />
                <NotificationScheduleRow day={'friday'}
                                         start={this.state.allow_notification_friday_start_time}
                                         end={this.state.allow_notification_friday_end_time}
                                         onStartChange={this.onStartChange}
                                         onEndChange={this.onEndChange} />
                <NotificationScheduleRow day={'saturday'}
                                         start={this.state.allow_notification_saturday_start_time}
                                         end={this.state.allow_notification_saturday_end_time}
                                         onStartChange={this.onStartChange}
                                         onEndChange={this.onEndChange} />
                <NotificationScheduleRow day={'sunday'}
                                         start={this.state.allow_notification_sunday_start_time}
                                         end={this.state.allow_notification_sunday_end_time}
                                         onStartChange={this.onStartChange}
                                         onEndChange={this.onEndChange} />
            </div>

            <div style={{ display: 'flex', alignItems: 'baseline', marginTop: 24 }}>
                {this.showUpdateButton()}
                {this.state.update_status ? (
                    <div className='update-status__success'>{this.state.update_status}</div>
                ) : (
                    <div />
                )}
            </div>
        </div>;

    }

    onCollapseClickChange = (collapse) => {
        this.setState({ collapse });
    };

    getFactorySettings = () => {
        let params = {
            path: 'get_factory_settings',
            success: (data) => this.onGetFactorySettingsSuccess(data),
            error: () => {}
        };

        API.c(params, 2);
    };

    onGetFactorySettingsSuccess = (data) => {
        localStorage.setItem("efficiency_string_long", data.efficiency_string_long)
        localStorage.setItem("efficiency_string_short", data.efficiency_string_short)
    }

    render () {
        if (!User.loggedIn()) {
            return <div />;
        }

        let class_name = this.state.collapse ? "account--collapse" : "account"
        let header_class_name = this.state.collapse ? "header header--collapse" : "header"

        return <div className="container">
            <HeaderTopNav className={header_class_name} onFactoryChange={()=>{}}/>
            <SideNav
                onShowScoreboardClicked={() => {
                }}
                onCollapseClick={this.onCollapseClickChange}
                title='Account' />

            <div className='main_content_wrapper'>
                <div className={class_name}>
                    <Typography variant={'h6'}>User Information</Typography>
                    {this.editUserInfo()}
                </div>

                <Footer />
            </div>
        </div>;
    }
}

export default Account;