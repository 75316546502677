import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';


export default function FactoryIcon(props) {

    return <SvgIcon width="48" height="36" viewBox="0 0 48 36" fill="none">
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M0 18.0303L7.31486 15.9748V0.0151367H12.3929V14.524L19.3451 12.5894V0.0151367H24.4232V11.199L31.3149 9.20405V0.0151367H36.3929V7.81363L48 4.54914V35.9849H40.5038V24.1965H29.9849V35.9849H0V18.0303ZM16.9874 24.7406H23.2746V27.9446H16.9874V24.7406ZM5.80353 24.7406H12.0907V27.9446H5.80353V24.7406Z"
              fill={props.color || 'black'}/>
    </SvgIcon>

}