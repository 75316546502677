import React, { Component } from 'react';

import { Button, CircularProgress, TextField, DialogContent, DialogActions, Stepper, Step, StepLabel } from "@material-ui/core";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import ClockIcon from './misc/clock.svg';

class ImportProductListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
        }

    }

    nextStepHandler() {
        if (this.state.activeStep === 0 || this.state.activeStep === 1) {
            if (this.state.activeStep === 1) {
                this.props.submitImport()
            }
            this.setState({
                activeStep: this.state.activeStep + 1
            })
        }
        else {
            this.props.hideModal()
        }
    }

    isDisabled() {
        console.log(this.state.activeStep);
        if (this.state.activeStep === 1) {
            if (this.props.validate) {
                if (this.props.validate["total records"] && this.props.validate["unchanged"]) {
                    if (this.props.validate["total records"] - this.props.validate["unchanged"] === 0) {
                        return true
                    }
                }
            }
        }
        return false
    }

    showBackupStep() {
        return (
                <div>
                    <p style={{ textAlign: "center", marginBottom: "20px" }}>Make sure you backup your products list</p>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button onClick={this.props.getCSV} variant="outlined" color="primary">
                            Export Product List
                        </Button>
                        <p style={{ marginLeft: "4px", fontSize: "20px" }}>.xlsx</p>
                    </div>
                </div>
        )
    }

    showUpdates() {
        return (
            <div>
                <TableContainer component={Paper}>
                    <Table sx={{ ".MuiTable-root": { boxShadow: "none" }, minWidth: 740 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "700 !important", fontSize: "16px" }} align="center">Records in file</TableCell>
                                <TableCell sx={{ fontWeight: "700 !important", fontSize: "16px" }} align="center">Added</TableCell>
                                <TableCell sx={{ fontWeight: "700 !important", fontSize: "16px" }} align="center">Edited</TableCell>
                                <TableCell sx={{ fontWeight: "700 !important", fontSize: "16px" }} align="center">Archived</TableCell>
                                <TableCell sx={{ fontWeight: "700 !important", fontSize: "16px" }} align="center">Unchanged</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ border: "none", fontWeight: "700 !important", fontSize: "16px" }} align="center" component="th" scope="row">{this.props.validate && this.props.validate["total records"] !== null ? this.props.validate["total records"] : ""}</TableCell>
                                <TableCell sx={{ border: "none", fontWeight: "700 !important", fontSize: "16px" }} align="center">{this.props.validate && this.props.validate["created"] !== null ? this.props.validate["created"] : ""}</TableCell>
                                <TableCell sx={{ border: "none", fontWeight: "700 !important", fontSize: "16px" }} align="center">{this.props.validate && this.props.validate["edited"] !== null ? this.props.validate["edited"] : ""}</TableCell>
                                <TableCell sx={{ border: "none", fontWeight: "700 !important", fontSize: "16px" }} align="center">{this.props.validate && this.props.validate["archived"] !== null ? this.props.validate["archived"] : ""}</TableCell>
                                <TableCell sx={{ border: "none", fontWeight: "700 !important", fontSize: "16px" }} align="center">{this.props.validate && this.props.validate["unchanged"] !== null ? this.props.validate["unchanged"] : ""}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    showConfirmation() {
        return (
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }} >
                {/* <p>{`You are editing ${this.props.validate && this.props.validate["total records"] && this.props.validate["unchanged"] ? this.props.validate["total records"] - this.props.validate["unchanged"] : 0} items.`}</p> */}
                <p style={{ marginBottom: "24px", fontWeight: "600" }} >Importing in progress - this may take a few minutes</p>
                <img width="100px" src={ClockIcon} />
            </div>
        )
    }

    addProductFields = () => {
        return <DialogContent style={{ width: "800px", height: "300px"}}>
            {/* <div style={{ width: 800, height: 400  }}> */}
                    <Stepper activeStep={this.state.activeStep} style={{ marginTop: "40px" }} classes={{ }}>
                        <Step>
                            <StepLabel>Make A Copy Of Your Products</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Preview Updates</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Complete Import</StepLabel>
                        </Step>
                    </Stepper>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "40px" }}>
                        {this.state.activeStep === 0 ? (
                            this.showBackupStep()
                        ) : this.state.activeStep === 1 ? (
                            this.showUpdates()
                        ) : this.state.activeStep === 2 ? (
                            this.showConfirmation()
                        ) : null}
                    </div>
            {/* </div> */}
        </DialogContent>
    }

    showSubmitButton() {
        return <DialogActions style={{ margin: "32px" }} >
                    {this.state.activeStep !== 2 ? (
                        <Button variant="text"
                            color="primary"
                            onClick={() => this.props.hideModal() } >Cancel</Button>
                    ) : null}
                    <Button variant="contained"
                        color="primary"
                        // disabled={this.isDisabled()}
                        onClick={() => this.nextStepHandler()} >{this.state.activeStep === 0 ? "Next Step" : this.state.activeStep === 2 ? "Close" : "Confirm"}</Button>
                </DialogActions>
    }

    render() {
        return <div>
            {this.addProductFields()}
            {this.showSubmitButton()}
        </div>
    }

}

export default ImportProductListModal