const notificationScheduleOptions = [
    { key: null, value: 0, label: 'None'},
    //Morning
    { key: '00:00', value: 1, label: '12:00 AM'},
    { key: '00:30', value: 2, label: '12:30 AM'},
    { key: '01:00', value: 3, label: '1:00 AM'},
    { key: '01:30', value: 4, label: '1:30 AM'},
    { key: '02:00', value: 5, label: '2:00 AM'},
    { key: '02:30', value: 6, label: '2:30 AM'},
    { key: '03:00', value: 7, label: '3:00 AM'},
    { key: '03:30', value: 8, label: '3:30 AM'},
    { key: '04:00', value: 9, label: '4:00 AM'},
    { key: '04:30', value: 10, label: '4:30 AM'},
    { key: '05:00', value: 11, label: '5:00 AM'},
    { key: '05:30', value: 12, label: '5:30 AM'},
    { key: '06:00', value: 13, label: '6:00 AM'},
    { key: '06:30', value: 14, label: '6:30 AM'},
    { key: '07:00', value: 15, label: '7:00 AM'},
    { key: '07:30', value: 16, label: '7:30 AM'},
    { key: '08:00', value: 17, label: '8:00 AM'},
    { key: '08:30', value: 18, label: '8:30 AM'},
    { key: '09:00', value: 19, label: '9:00 AM'},
    { key: '09:30', value: 20, label: '9:30 AM'},
    { key: '10:00', value: 21, label: '10:00 AM'},
    { key: '10:30', value: 22, label: '10:30 AM'},
    { key: '11:00', value: 23, label: '11:00 AM'},
    { key: '11:30', value: 24, label: '11:30 AM'},
    //Afternoon
    { key: '12:00', value: 25, label: '12:00 PM'},
    { key: '12:30', value: 26, label: '12:30 PM'},
    { key: '13:00', value: 27, label: '1:00 PM'},
    { key: '13:30', value: 28, label: '1:30 PM'},
    { key: '14:00', value: 29, label: '2:00 PM'},
    { key: '14:30', value: 30, label: '2:30 PM'},
    { key: '15:00', value: 31, label: '3:00 PM'},
    { key: '15:30', value: 32, label: '3:30 PM'},
    { key: '16:00', value: 33, label: '4:00 PM'},
    { key: '16:30', value: 34, label: '4:30 PM'},
    { key: '17:00', value: 35, label: '5:00 PM'},
    { key: '17:30', value: 36, label: '5:30 PM'},
    { key: '18:00', value: 37, label: '6:00 PM'},
    { key: '18:30', value: 38, label: '6:30 PM'},
    { key: '19:00', value: 39, label: '7:00 PM'},
    { key: '19:30', value: 40, label: '7:30 PM'},
    { key: '20:00', value: 41, label: '8:00 PM'},
    { key: '20:30', value: 42, label: '8:30 PM'},
    { key: '21:00', value: 43, label: '9:00 PM'},
    { key: '21:30', value: 44, label: '9:30 PM'},
    { key: '22:00', value: 45, label: '10:00 PM'},
    { key: '22:30', value: 46, label: '10:30 PM'},
    { key: '23:00', value: 47, label: '11:00 PM'},
    { key: '23:30', value: 48, label: '11:30 PM'},
    { key: '23:59', value: 49, label: 'Midnight'},
]

export default notificationScheduleOptions