class ProductStore {
    constructor() {
        this.changeEvent = 'livetracking:liveview';
        this.calls = 0;
        this.data = {};
        this.products = {};
        this.subscribers = 0;
        this.fetchTimeout = null;
        this.lastFetch = null;
        this.ttl = 30000;
        this.hasData = false;
    }

    subscribe = callback => {
        addEventListener(this.changeEvent, callback, false);

        this.calls = 1;

        if (!this.hasData) {
            this.fetch();
        }

        if (this.data) {
            this.emitChange();
        }
    };

    subscribeFull = callback => {
        addEventListener(this.changeEvent, callback, false);

        this.calls = 2;

        if (!this.hasData) {
            this.fetch();
        }

        if (this.data && this.products) {
            this.emitChange();
        }
    };

    unsubscribe = callback => {
        removeEventListener(this.changeEvent, callback);
    };

    emitChange() {
        let event = document.createEvent('Event');
        event.initEvent(this.changeEvent, true, true);
        dispatchEvent(event);
    }

    isEmpty = obj => {
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) return false;
        }

        return true;
    };

    fetchSuccess = json => {
        this.data = json;
        this.hasData = true;
        this.calls--;

        if (this.calls === 0) {
            this.emitChange();
        }
    };

    allFetchSuccess = json => {
        this.products = json;
        this.calls--;

        if (this.calls === 0) {
            this.emitChange();
        }
    };

    fetchError = response => {
        console.error('Error fetching products data');
    };

    fetch = () => {
        let params = {
            path: 'all_products',
            success: this.fetchSuccess,
            error: this.fetchError
        };

        API.c(params, 2);

        let full_product_info_params = {
            path: 'products',
            success: this.allFetchSuccess,
            error: this.fetchError
        };

        API.c(full_product_info_params, 2);
    };

    get = () => {
        return this.data;
    };

    getFull = () => {
        return this.products;
    };

    clearFull = () => {
        this.data = {};
        this.products = {};
        this.hasData = false;
    };
}

export default ProductStore;
