import React, {Component} from 'react';
import TrendReportTopIssue from "./TrendReportTopIssue";
import {CircularProgress} from "@material-ui/core";

class TrendReportIssueModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            issue: null,
            error: null,
            loading: true
        }
    }

    /* -------- LIFECYCLE METHODS -------- */

    componentWillMount() {
        this.getIssue()
    }

    /* -------- FUNCTIONS -------- */
    getMax = () => {
        return Math.max.apply(Math, this.state.issue.lost_time_info.map(function(o) { return o.lost_time_percent; }))
    }

    /* -------- API CALLS -------- */
    getIssue = () => {

        let data

        if(this.props.selected_lines[0] && this.props.selected_products[0]) {
            data = {
                line_id: this.props.selected_lines,
                product_id: this.props.selected_products,
                end_range_date: this.props.date.toISOString()
            }
        } else if(this.props.selected_lines[0]) {
            data = {
                line_id: this.props.selected_lines,
                end_range_date: this.props.date.toISOString()
            }
        } else if(this.props.selected_products[0]) {
            data = {
                product_id: this.props.selected_products,
                end_range_date: this.props.date.toISOString()
            }
        } else {
            data = {
                end_range_date: this.props.date.toISOString()
            }
        }

        if(this.props.id) {
            data['reason_id'] = this.props.id
        } else {
            data['reason_name'] = this.props.name
        }

        let params = {
            path: 'get_individual_issue',
            data: data,
            success: this.onGetIssueSuccess,
            error: this.onGetIssueError
        }

        API.c(params, 2)
    }

    onGetIssueSuccess = (issue) => {
        this.setState({
            issue,
            loading: false
        })
    }

    onGetIssueError = (error) => {
        this.setState({
            error: 'There was an error getting this issue\'s data',
            loading: false
        })
    }

    /* -------- RENDERS -------- */

    renderContent = () => {
        if(this.state.loading) {
            return <div className="flex-box__col align-center">
                <p className="margin-bottom-sm">Loading Data</p>
                <CircularProgress />
            </div>
        }

        if(this.state.error) {
            return <div>{this.state.error}</div>
        }

        return <TrendReportTopIssue issue={this.state.issue} tall max={this.getMax()} name={this.props.name}/>
    }

    render() {
        return <div className="modal" onKeyUp={this.hideModalWithKey} >
            <div
                className="modal__bg"
                onClick={this.props.hideModal}
            />

            <div className="modal__content__trend-issue">
                <div className="flex-box__row justify-center">
                    {this.renderContent()}
                </div>
            </div>
        </div>;
    }
}

export default TrendReportIssueModal
