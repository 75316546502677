class LineStore {
    constructor() {
        this.changeEvent = 'livetracking:liveview';
        this.data = {};
        this.lines = {};
        this.calls = 0;
        this.subscribers = 0;
        this.fetchTimeout = null;
        this.ttl = 30000;
    }


    subscribe = (callback) => {
        addEventListener(this.changeEvent, callback, false);
        this.subscribers++;
        this.calls = 2;

        this.fetch();
        this.fetchLines();
        this.queueFetch();

        if (this.data && this.lines) {
            this.emitChange();
        }
    }

    subscribeLines = (callback) => {
        addEventListener(this.changeEvent, callback, false);
        this.subscribers++;
        this.calls = 1;

        this.fetchLines();

        if (!this.isEmpty(this.lines)) {
            this.emitChange();
        }
    }


    unsubscribe = (callback) => {
        removeEventListener(this.changeEvent, callback);
        this.subscribers--;


        if (this.subscribers <= 0) this.dequeueFetch();
    }


    emitChange() {
        let event = document.createEvent('Event');
        event.initEvent(this.changeEvent, true, true);
        dispatchEvent(event);
    }


    isEmpty = (obj) => {
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop))
                return false;
        }

        return true;
    }

    queueFetch() {
        if (this.fetchTimeout == null) {
            this.fetchTimeout = setInterval(this.fetch, this.ttl);
        }
    }


    dequeueFetch() {
        clearInterval(this.fetchTimeout)
        this.fetchTimeout = null;
    }


    fetchSuccess = (json) => {
        this.data = json ? json["liveview_info"] : null;

        if (this.calls > 0) this.calls--;

        if (this.calls === 0) {
            this.emitChange();
        }

        if (this.subscribers <= 0) {
            this.dequeueFetch();
        }
    }

    fetchLinesSuccess = (json) => {
        this.lines = json

        if (this.calls > 0) this.calls--;

        if (this.calls === 0) {
            this.emitChange();
        }
    }


    fetchError = (response) => {
        console.error('Error fetching liveview data');


        if (this.subscribers <= 0) {
            this.dequeueFetch();
        }
    }


    fetch = () => {
        if (!(window.location.pathname.split("/")[1] !== "liveview" && !this.isEmpty(this.data))) {
            let params = {
                path: "liveview_all",
                success: this.fetchSuccess,
                error: this.fetchError
            };

            API.c(params, 2);
        }
    };

    fetchLines = () => {
        if (this.isEmpty(this.lines)) {
            let params = {
                path: "get_lines",
                success: this.fetchLinesSuccess,
                error: this.fetchError
            };

            API.c(params, 2);
        } else {
            if (this.calls > 0) this.calls--;

            if (this.calls === 0) {
                this.emitChange();
            }
        }
    };


    get = () => {
        return this.data;
    }

    getLines = () => {
        return this.lines;
    }

    clear = () => {
        this.data = {}
        this.lines = {}
    }
}

export default LineStore;