import React from 'react'
import moment from 'moment';

export const getDate = (d = null) => {
    if(d == null) {
        return moment();
    }
    return moment(d)
}

export const disableFutureDates = (date) => {
    return ((moment(date).isAfter(moment(), 'minute')));
}

export const disableFromFutureDates = (date_to, max_date_time, date) => {
    return ((moment(date).isAfter(moment(), 'minute')) || (moment(date).isAfter(date_to, 'minute')) || (moment(date).isAfter(max_date_time, 'minute')));
}

export const disableToFutureDates = (max_date_time, date) => {
    return ((moment(date).isAfter(moment(), 'minute')) || (moment(date).isAfter(max_date_time, 'minute')));
}

export const handleDateFromChange = (date, time_from, time_to) => {
    let newDate = date;
    let t = time_from;
    let state;

    newDate.hours(t.hours());
    newDate.minutes(t.minutes());
    newDate.seconds(t.seconds());

    if(moment(time_to).isSameOrBefore(newDate, 'minute')) {
        state = {
            date_from: newDate,
            time_from: newDate,
            time_to_error: "End time error"
        }
    } else {
        state = {
            date_from: newDate,
            time_from: newDate,
            time_to_error: null
        }
    }

    return state;
}


export const handleDateToChange = (date, time_to, date_from) => {
    let newDate = date;
    let t = time_to;
    let state;
    newDate.hours(t.hours());
    newDate.minutes(t.minutes());
    newDate.seconds(t.seconds());

    if((moment(date_from).isSameOrAfter(newDate, 'minute')) || (moment().isBefore(newDate, 'minute'))) {
        state = {
            date_to: newDate,
            time_to: newDate,
            time_to_error: "End time error"
        }
    } else {
        state = {
            date_to: newDate,
            time_to: newDate,
            time_to_error: null
        }
    }

    return state;
}

export const handleTimeFromChange = (time, time_to, date_from) => {
    let newDateTime = appendTimeFrom(time, date_from)
    let state = {
        time_from: newDateTime,
        date_from: newDateTime,
        time_from_error: null
    }

    if(moment(time_to).isSameOrBefore(newDateTime, 'minute')) {
        state['time_to_error'] = "End time error"
    } else {
        state['time_to_error'] = null
    }

    if(moment(time_to).isAfter(newDateTime, 'minute')) {
        state['time_to_error'] = null
    }

    return state
}

export const handleTimeToChange = (time, time_from, date_to) => {
    let newDateTime = appendTimeTo(time, date_to)
    let state = {
        time_to: newDateTime,
        date_to: newDateTime
    }

    if((moment(time_from).isSameOrAfter(newDateTime, 'minute')) || (moment().isBefore(newDateTime, 'minute'))) {
        state['time_to_error'] = "End time error"
    } else {
        state['time_to_error'] = null
    }

    return state
}

export const appendTimeFrom = (t, date_from) => {
    let date = date_from;
    date.hours(t.hours());
    date.minutes(t.minutes());
    date.seconds(t.seconds());

    return date
}

export const appendTimeTo = (t, date_to) => {
    let date = date_to;
    date.hours(t.hours());
    date.minutes(t.minutes());
    date.seconds(t.seconds());

    return date
}