import React, {Component} from 'react';
import {
    Bar,
    BarChart,
    Cell,
    ComposedChart,
    LabelList,
    Line, ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import CustomEfficiencyToolTip from "../../CustomEfficiencyToolTip";
import CustomDiagonalAxisTick from "../../CustomDiagonalAxisTick";
import CustomTrendIssueTooltip from "../../RechartCustomComponents/CustomTrendIssueTooltip";
import CustomOverLimitLabel from "../../RechartCustomComponents/CustomOverLimitLabel";
import CustomValueLabel from "../../RechartCustomComponents/CustomValueLabel";
import YAxisLabel from "../../RechartCustomComponents/YAxisLabel";
import ReferenceLineLabel from "../../RechartCustomComponents/ReferenceLineLabel";

class TrendReportTopIssue extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */


    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    showTitle = () => {
        let name;

        if(this.props.issue) {
            name = this.props.issue.reason_name
        }

        if (!name && this.props.name) {
            name = this.props.name
        }

        return <div className="reports__data-lost-time-label-sub">{name}</div>
    }

    showTopTrendChart = () => {
        let { issue, tall } = this.props;

        let data = []

        let height = 260

        let maxY = this.props.max * 1.1

        let intervals = [1, 2, 5, 10, 15, 20, 25]
        let interval = intervals.find((element) => {
            return element > (maxY / 4);
        })

        if(interval === undefined){
            interval = 5
        }

        maxY = parseInt(maxY) + (interval % maxY)

        let ticks = []
        for(let i = 0; i <= maxY; i += interval) {
            ticks.push(i)
        }

        if(!issue) {
            data.push(
                {
                    xAxisTick: '1',
                    label: '',
                    amt: 0,
                    lost_time_seconds: 0,
                    trend: 0
                },
                {
                    xAxisTick: '2',
                    label: '',
                    amt: 0,
                    lost_time_seconds: 0,
                    trend: 0
                },
            )

            ticks=[0, 10, 20, 30, 40]

            return <div style={{width: "100%"}}>
                <ResponsiveContainer width="100%" height={height}>
                    <ComposedChart data={data}>
                        <ReferenceLine y={20} label={<ReferenceLineLabel label={"No data captured"}/>} className="no-data" stroke="#fff"/>
                        <XAxis dataKey='xAxisTick' tick={false} domain={['dataMin', 'dataMax']} interval={0}/>
                        <YAxis domain={[0, 40]} tick={<CustomValueLabel anchor="end" textX={-2} textDy={4}/>} ticks={ticks} allowDataOverflow={true} label={<YAxisLabel text="% of Total" vertical/>}/>
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        }

        let length = issue.lost_time_info.length;

        let twoYears = false
        let yearOne = issue.lost_time_info[0].year
        let yearTwo = issue.lost_time_info[length - 1].year

        let newYearIndex = length
        if(yearOne !== yearTwo) {
            twoYears = true
            newYearIndex = issue.lost_time_info.findIndex(item => item.year !== yearOne)
        }

        issue.lost_time_info.map((item, index) =>
            data.push({
                xAxisTick: 'wk ' + ("0" + item.week_number).substr(-2, 2),
                label: item.week_range_display,
                amt: item.lost_time_percent,
                lost_time_seconds: item.lost_time_seconds,
                trend: item.previous_four_week_lost_time_percent
            })
        );

        let gradientId = tall ? 'red_modal' : 'red'

        return <div style={{width: "100%"}}>
            <ResponsiveContainer width="100%" height={height}>
                <ComposedChart data={data} syncId={"trendReport"}>
                    <defs>
                        <linearGradient id={gradientId} gradientTransform="rotate(90)">
                            <stop offset="5%"  stopColor="#cb2d3e" />
                            <stop offset="95%" stopColor="#cb2d3e"/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey='xAxisTick' tick={<CustomDiagonalAxisTick/>} domain={['dataMin', 'dataMax']} interval={0}/>
                    <YAxis domain={[0, ticks[ticks.length - 1]]} tick={<CustomValueLabel anchor="end" textX={-2} textDy={4}/>} ticks={ticks} allowDataOverflow={true} label={<YAxisLabel text="% of Total" vertical/>}/>
                    <Tooltip active={true} content={<CustomTrendIssueTooltip/>}/>
                    <Bar dataKey='amt' fill={"url(#" + gradientId + ")"} isAnimationActive={false}>
                        <LabelList content={<CustomOverLimitLabel max={maxY}/>}/>
                    </Bar>
                    <Line dataKey='trend' type="linear" strokeWidth="2" dot={false} connectNulls/>
                </ComposedChart>
            </ResponsiveContainer>
            <div style={{width: "calc(100% - 60px)", marginLeft: "60px", display: "flex"}}>
                <div className={"flex-box__row justify-start chart-year-axis"} style={{width: ((newYearIndex/length) * 100) + "%"}}>{yearOne}</div>
                {twoYears && <div className={"flex-box__row justify-start chart-year-axis"} style={{width: (((length - newYearIndex)/length) * 100) + "%"}}>{yearTwo}</div> }
            </div>
        </div>
    }

    render() {
        let opacity = 1;
        if (this.props.hoverIndex < 4 && this.props.hoverIndex > -1 && (this.props.index !== this.props.hoverIndex)) {
            opacity = 0.3
        }

        let width = "50%";

        if(this.props.tall) {
            width = "100%"
        }

        return <div style={{width: width, float: 'left', marginBottom: "24px", opacity: opacity}}>
            {this.showTitle()}
            {this.showTopTrendChart()}
        </div>
    }
}

export default TrendReportTopIssue