import React, { Component } from 'react';
import TrendReportTitleAndOptions from './TrendReportTitleAndOptions';
import TrendReportOEE from './TrendReportOEE';
import TrendReportLostTime from './TrendReportLostTime';
import TrendReportTrendingTopIssues from './TrendReportTrendingTopIssues';
import { CircularProgress } from '@material-ui/core';
import TrendReportIssueModal from './TrendReportIssueModal';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { getDate } from '../../DateTimeHelperUtils';
import HeaderTopNav from '../../HeaderTopNav';
import SideNav from '../../SideNav';
import Footer from '../../Footer';

class TrendReportContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lines: {},
            products: {},
            error: null,
            selected_lines: [],
            selected_products: [],
            date: getDate(),
            data: null,
            show_issue_modal: false,
            getting_report: false,
            modal_issue: '',
            number_of_weeks: 0,
            pareto_bars: 0,
            print_status: 'disabled',
            selected_lines_display: [],
            selected_products_display: [],
            selected_date_display: null,
            clicked_name: null,
            clicked_id: null,
            isCacheRebuilding: false,
            factory_name: '',
            collapse: true
        };
    }

    /* -------- LIFECYCLE METHODS -------- */
    componentWillMount() {
        this.getFactorySettings();
        LineStore.subscribeLines(this.handleLineChange);
        ProductStore.subscribeFull(this.handleProductStoreChange);
    }

    componentWillUnmount() {
        LineStore.unsubscribe(this.handleLineChange);
        ProductStore.unsubscribe(this.handleProductStoreChange);
    }

    /* -------- FUNCTIONS -------- */
    onFactoryChange = () => {
        this.setState({
            lines: {},
            products: {},
            error: null,
            selected_lines: [],
            selected_products: [],
            date: getDate(),
            data: null,
            show_issue_modal: false,
            getting_report: false,
            modal_issue: '',
            number_of_weeks: 0,
            pareto_bars: 0,
            print_status: 'disabled',
            selected_lines_display: [],
            selected_products_display: [],
            selected_date_display: null,
            clicked_name: null,
            clicked_id: null,
            isCacheRebuilding: false,
            factory_name: '',
            collapse: true
        });
        LineStore.clear();
        ProductStore.clearFull();
        this.getFactorySettings();
        LineStore.fetchLines();
        ProductStore.fetch();
    };

    onCollapseClickChange = collapse => {
        this.setState({ collapse });
    };

    print = () => {
        this.setState({
            print_status: 'printing'
        });

        let line_name = this.state.selected_lines_display[0]
            ? this.state.lines.find(line => line.line_id === this.state.selected_lines_display[0]).line_name
            : 'All Lines';

        if (this.state.selected_lines_display.length > 1) {
            line_name = 'Multi-line';
        }

        if (this.state.selected_lines_display.length === this.state.lines.length) {
            line_name = 'All Lines';
        }

        let product_description = this.state.selected_products_display[0]
            ? this.state.products.find(product => product.product_id === this.state.selected_products_display[0])
                  .display_name
            : 'All Products';

        if (this.state.selected_products_display.length > 1) {
            product_description = 'Multi-product';
        }

        if (this.state.selected_products_display.length === this.state.products.length) {
            product_description = 'All Products';
        }

        let title = `${line_name} - ${product_description} - ${Util.formatDateMMMDDYYYY(
            this.state.selected_date_display
        )}`;

        let doc = new jsPDF({ format: 'letter' }); // 8.5" x 11" paper size
        let count = 3;

        html2canvas(document.getElementById('oee-chart'), { logging: false }).then(canvas => {
            doc.addImage(document.getElementById('logo'), 'JPEG', 8, 4, 40, 8);
            doc.setDrawColor(41, 103, 193);
            doc.setLineWidth(0.5);
            doc.line(0, 16, 215.9, 16);

            doc.setFontSize(16);
            doc.text(`${this.state.factory_name} - Weekly Trend Report`, 8, 24);
            doc.text(title, 8, 30);

            let img = canvas.toDataURL('image/png');
            doc.addImage(img, 'JPEG', 0, 34, 121, 78);
            count--;
            this.checkHtml2CanvasCount(count, doc, title);
        });

        html2canvas(document.getElementById('lost-time-pareto'), { logging: false }).then(paretoCanvas => {
            let paretoImg = paretoCanvas.toDataURL('image/png');
            doc.addImage(paretoImg, 'JPEG', 125, 34, 80, 80);
            count--;
            this.checkHtml2CanvasCount(count, doc, title);
        });

        html2canvas(document.getElementById('top-four-charts'), { logging: false }).then(topFourCanvas => {
            let topFourImg = topFourCanvas.toDataURL('image/png');
            doc.addImage(topFourImg, 'JPEG', 0, 120, 210, 125);
            count--;
            this.checkHtml2CanvasCount(count, doc, title);
        });
    };

    checkHtml2CanvasCount = (count, doc, title) => {
        if (count === 0) {
            doc.setDrawColor(41, 103, 193);
            doc.setLineWidth(0.5);
            doc.line(0, 268, 215.9, 268);

            doc.setFontSize(6);
            doc.text(`${this.state.factory_name} - Weekly Trend Report - ${title}`, 207.9, 272, null, null, 'right');
            doc.save(`Weekly Trend Report - ${title}.pdf`);
            this.setState({
                print_status: 'enabled'
            });
        }
    };

    handleLineChange = () => {
        this.setState({
            lines: LineStore.getLines(),
            selected_lines: this.getSelectedLines()
        });
    };

    getSelectedLines() {
        let selectedLines = [];
        let allLines = LineStore.getLines();

        if (allLines[0]) {
            allLines.forEach(line => {
                selectedLines.push(line.id);
            });
        }

        return selectedLines;
    }

    handleProductStoreChange = () => {
        this.setState({
            products: ProductStore.get(),
            selected_products: this.getSelectedProducts()
        });
    };

    getSelectedProducts() {
        let selectedProducts = [];

        if (this.state.selected_products[0]) {
            selectedProducts = this.state.selected_products;
        } else {
            let allProducts = ProductStore.get();

            if (allProducts.length > 0) {
                allProducts.forEach(product => {
                    selectedProducts.push(product.product_id);
                });
            }
        }

        return selectedProducts;
    }

    handleDateChange = date => {
        this.setState({
            date
        });
    };

    handleSelectLines = selected_lines => {
        let updatedSelection = Array.from(selected_lines);
        this.setState({
            selected_lines: updatedSelection
        });
    };

    handleSelectProducts = selected_products => {
        let updatedSelection = Array.from(selected_products);
        this.setState({
            selected_products: updatedSelection
        });
    };

    hideModal = () => {
        this.setState({
            show_issue_modal: false,
            clicked_name: null,
            clicked_id: null
        });
    };

    showModal = props => {
        this.setState({
            show_issue_modal: true,
            clicked_name: props.activeLabel,
            clicked_id: props.activePayload[0].payload.id
        });
    };

    onLostTimeBarHover = props => {
        if (props.activeTooltipIndex !== undefined) {
            this.setState({
                hoverIndex: props.activeTooltipIndex
            });
        } else {
            this.setState({
                hoverIndex: -1
            });
        }
    };

    /* -------- API CALLS -------- */
    getFactorySettings = () => {
        this.setState({
            getting_report: true
        });

        let params = {
            path: 'get_factory_settings',
            success: this.onGetFactorySettingsSuccess,
            error: this.onGetFactorySettingsError
        };

        API.c(params, 2);
    };

    onGetFactorySettingsSuccess = data => {
        localStorage.setItem("efficiency_string_long", data.efficiency_string_long)
        localStorage.setItem("efficiency_string_short", data.efficiency_string_short)
        this.setState(
            {
                loading: false,
                number_of_weeks: data.default_weeks_weekly_trend ? data.default_weeks_weekly_trend : 52,
                pareto_bars: data.default_weekly_pareto_bars ? data.default_weekly_pareto_bars : 10,
                factory_name: data.name,
                usesShifts: data.uses_shifts
            },
            this.getWeeklyTrend
        );
    };

    onGetFactorySettingsError = error => {
        this.setState({
            error
        });
    };

    getWeeklyTrend = () => {
        if (this.state.isCacheRebuilding) {
            this.setState({
                error: null,
                print_status: 'disabled'
            });
        } else {
            this.setState({
                getting_report: true,
                error: null,
                print_status: 'disabled'
            });
        }

        let data = {
            end_range_date: this.state.date.toISOString()
        };

        let allProducts = this.state.products && this.state.selected_products[this.state.products.length - 1];
        let allLines = this.state.lines && this.state.selected_lines[this.state.lines.length - 1];

        if (!allLines) {
            data['line_id'] = this.state.selected_lines;
        }

        if (!allProducts) {
            data['product_id'] = this.state.selected_products;
        }

        let params = {
            path: 'report_weekly_cache',
            data: data,
            success: this.onGetWeeklyTrendSuccess,
            error: this.onGetWeeklyTrendError
        };

        API.c(params, 2);
    };

    getShortReason = reason => {
        let r = reason;

        if (reason.length > 25) {
            r = reason.substr(0, 22);
            r += '...';
        }

        return r;
    };

    onGetWeeklyTrendSuccess = data => {
        this.setState({
            getting_report: false,
            print_status: 'enabled',
            selected_lines_display: this.state.selected_lines,
            selected_products_display: this.state.selected_products,
            selected_date_display: this.state.date,
            isCacheRebuilding: data.is_cache_rebuilding,
            data
        });

        if (data.is_cache_rebuilding) {
            setTimeout(this.getWeeklyTrend, 30000);
        }
    };

    onGetWeeklyTrendError = error => {
        this.setState({
            getting_report: false,
            error: error ? error : 'Error Getting The Trend Report'
        });
    };

    /* -------- RENDERS -------- */
    renderOptionsSelectors = () => {
        return (
            <TrendReportTitleAndOptions
                lines={this.state.lines}
                selected_lines={this.state.selected_lines}
                handleSelectLines={this.handleSelectLines}
                products={this.state.products}
                selected_products={this.state.selected_products}
                handleSelectProducts={this.handleSelectProducts}
                date={this.state.date}
                handleDateChange={this.handleDateChange}
                disabled={this.state.getting_report}
                print={this.print}
                printStatus={this.state.print_status}
                cacheRebuilding={this.state.isCacheRebuilding}
                collapse={this.state.collapse}
                buildReport={this.getWeeklyTrend}
            />
        );
    };

    renderReport = () => {
        if (this.state.getting_report) {
            return (
                <div className="reports__container">
                    <div className="card__flex-box">
                        <CircularProgress />
                    </div>
                </div>
            );
        }

        if (this.state.error) {
            return (
                <div className="reports__container">
                    <div className="card__flex-box">{this.state.error}</div>
                </div>
            );
        }

        return (
            <div className="reports__container">
                <div className="flex-box__col" style={{ flex: 1 }}>
                    <div className="flex-box__row justify-space-around align-start">
                        <div style={{ width: '60%' }}>
                            <TrendReportOEE oee={this.state.data.weekly_efficiency_range_list} />
                        </div>
                        <div style={{ width: '40%' }}>
                            <TrendReportLostTime
                                four_week_lost_time={this.state.data.four_week_lost_time_list}
                                onMouseMove={this.onLostTimeBarHover}
                                pareto_bars={this.state.pareto_bars}
                                onBarClick={this.showModal}
                            />
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <TrendReportTrendingTopIssues
                            topIssue={this.state.data.top_lost_time_seconds_range_list}
                            hoverIndex={this.state.hoverIndex}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderPrintArea = () => {
        if (this.state.data) {
            let printStyle = {
                maxWidth: '1345px',
                minWidth: '1345px',
                marginLeft: 'auto',
                marginRight: 'auto'
            };

            return (
                <div style={{ left: 10000, position: 'fixed', background: '#fff' }}>
                    <div className="flex-box__col" style={printStyle}>
                        <div className="flex-box__row justify-space-around align-start">
                            <div id="oee-chart" style={{ width: '60%' }}>
                                <TrendReportOEE oee={this.state.data.weekly_efficiency_range_list} />
                            </div>
                            <div id="lost-time-pareto" style={{ width: '40%' }}>
                                <TrendReportLostTime
                                    four_week_lost_time={this.state.data.four_week_lost_time_list}
                                    onMouseMove={this.onLostTimeBarHover}
                                    pareto_bars={this.state.pareto_bars}
                                    onBarClick={this.showModal}
                                />{' '}
                                :<div>&nbsp;</div>
                            </div>
                        </div>
                        <div id="top-four-charts" style={{ width: '100%' }}>
                            <TrendReportTrendingTopIssues
                                topIssue={this.state.data.top_lost_time_seconds_range_list}
                                hoverIndex={this.state.hoverIndex}
                            />{' '}
                            :<div>&nbsp;</div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    renderIssueModal = () => {
        if (this.state.show_issue_modal) {
            return (
                <TrendReportIssueModal
                    hideModal={this.hideModal}
                    selected_lines={this.state.selected_lines}
                    selected_products={this.state.selected_products}
                    date={this.state.date}
                    id={this.state.clicked_id}
                    name={this.state.clicked_name}
                    issue={this.state.modal_issue}
                />
            );
        } else {
            return <div />;
        }
    };

    render() {
        let class_name = this.state.collapse ? 'reports--collapse' : 'reports';
        let header_class_name = this.state.collapse ? "header header--collapse" : "header"

        return (
            <div className="container">
                <HeaderTopNav className={header_class_name} onFactoryChange={this.onFactoryChange} />
                <SideNav
                    onShowScoreboardClicked={() => {}}
                    onCollapseClick={this.onCollapseClickChange}
                    title="Reports"
                />
                <div className="main_content_wrapper">
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            {this.renderOptionsSelectors()}
                            <div className={class_name}>
                                {this.renderPrintArea()}
                                {this.renderReport()}
                            </div>
                            <Footer />
                        </div>
                    </div>
                    {this.renderIssueModal()}
                </div>
            </div>
        );
    }
}

export default TrendReportContainer;
