import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography
} from '@material-ui/core';

export default function AddNewNotificationModal(props) {
    const classes = useStyles();
    const [type, setType] = useState('')
    const [title, setTitle] = useState('')

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    }

    const handleTypeChange = (event) => {
        setType(event.target.value);
    }

    const onAddNewNotificationClick = () => {
        props.onAddNewNotificationClick(title, type)
    }

    return <Dialog open={true} maxWidth={'md'}>
        <DialogTitle disableTypography>
            <Typography variant={'h6'}>New Notification</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <TextField label='Notification Title'
                       className={classes.titleTextField}
                       onChange={handleTitleChange}
                       value={title}/>
            <TextField select
                       label='Notification Type'
                       className={classes.typeTextField}
                       onChange={handleTypeChange}
                       value={type}>
                <MenuItem value={'downtime'}>Downtime</MenuItem>
                <MenuItem value={'run start'}>Run Start</MenuItem>
                <MenuItem value={'run end'}>Run End</MenuItem>
            </TextField>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
            <Button variant={'text'}
                    color={'primary'}
                    onClick={props.hideModal}>Cancel</Button>
            <Button variant={'contained'}
                    color={'primary'}
                    disabled={title === '' || type === ''}
                    onClick={onAddNewNotificationClick}>Next</Button>
        </DialogActions>
    </Dialog>
}

const useStyles = makeStyles(theme => ({
    dialogContent: {
        width: 400,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    titleTextField: {
        width: 284
    },
    typeTextField: {
        width: 174,
        marginTop: 30
    },
    dialogActions: {
        padding: '20px 25px'
    }
}));