import axios from 'axios';

export const auth0UserManagementAPIInstance = axios.create({
    baseURL: 'https://usermanagementapi.livetrackingsystems.com/',
    // baseURL: 'https://usermanagementapistaging.livetrackingsystems.com/',
    headers: {
        'Content-Type': 'application/json'
    }
});

auth0UserManagementAPIInstance.interceptors.request.use(
    async config => {
        let idToken = window.localStorage.getItem('idToken');
        if (idToken) {
            if (Auth0.isAuthenticated()) {
                config.headers['Authorization'] = 'Bearer ' + idToken;
                return config;
            } else {
                return await Auth0.renewToken()
                    .then(authResult => {
                        if (authResult) {
                            Auth0.setSession(authResult);
                            config.headers['Authorization'] = 'Bearer ' + authResult.idToken;
                        } else {
                            window.location.pathname = '/login';
                        }
                        // Renewed tokens or error

                        if (authResult) {
                            return config;
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        window.location.pathname = '/login';
                    });
            }
        }
    },
    error => {
        console.log(error);
        Promise.reject(error);
    }
);
