import React, { Component } from 'react';
import {Button, FormControlLabel, Checkbox, CircularProgress} from "@material-ui/core";

class DeleteProductModal extends Component {
    constructor (props) {
        super(props);
        this.state = {
            delete_confirmed: false,
            submitting_delete: false,
            delete_status: null,
        };
    }

    /* ######## Lifecycle Methods ######## */
    componentDidMount = () => {
        this.setKeyListener();
    }

    componentWillUnmount = () => {
        this.cancelKeyListener();
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            if (event.keyCode === 13) {
                this.submitDelete()
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }



    deleteText () {
        return <div className="delete-user__text">
            Are you sure you want to delete {this.props.product.name} - {this.props.product.desc}?
        </div>;
    }


    hideModal = () => {
        this.props.hideModal();
    }


    validateInputs () {
        let error_free = true;
        this.setState({
            confirm_error: null,
        });

        if (this.state.delete_confirmed == false) {
            error_free = false;
        }

        return error_free;
    }


    submitDelete = () => {
        if (this.validateInputs() == false) {
            return false;
        }

        this.setState({
            submitting_delete: true,
            delete_status: null
        })


        let params = {
            path: "delete_product",
            path_variables: {
                ID: this.props.product.id
            },
            success: this.deleteSuccess,
            error: this.deleteError
        }

        API.c(params);

    }


    buttonStyle () {
        return {
            marginRight: "16px"
        }
    }


    deleteSuccess = (data) => {
        this.setState({
            submitting_delete: false,
            delete_error: null,
        });

        this.props.modified();
        this.hideModal();
    }


    deleteError = (error) => {
        this.setState({
            submitting_delete: false,
            delete_error: error
        })
    }


    deleteButtons () {
        if (this.state.submitting_delete === false) {
            return <div className="delete-user__buttons">
                <Button variant="text"
                        color="primary"
                        onClick={this.hideModal}>cancel</Button>
                <Button variant="contained"
                        color="secondary"
                        disabled={!this.state.delete_confirmed}
                        onClick={this.submitDelete}
                        style={this.buttonStyle()}>Delete Product</Button>
            </div>;
        } else {
            return <div className="add-user__buttons">
                <CircularProgress mode="indeterminate" />
            </div>;
        }
    }


    changeDeleteConfirmation = (event, value) => {
        this.setState({
            delete_confirmed: value
        });
    }


    deleteCheckbox() {
        return <div className="delete-user__checkbox">
            <div className="delete-user__checkbox-text">
                Confirm deletion of this product.
            </div>
            <FormControlLabel control={<Checkbox onChange={this.changeDeleteConfirmation}
                                                 color="primary"
                                                 checked={this.state.delete_confirmed}/>}
                              label="Yes I'm sure, delete this product."/>

        </div>
    }


    deleteStatus () {
        if (this.state.delete_error) {
            return <div className="delete-user__error">{this.state.delete_error}</div>;
        } else {
            return <div></div>;
        }
    }


    render() {
        return <div>
            {this.deleteText()}
            {this.deleteCheckbox()}
            {this.deleteButtons()}
            {this.deleteStatus()}
        </div>;
    }
}

export default DeleteProductModal;