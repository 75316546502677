import React, { Component } from "react";

import LineItemColumn from "../components/LineItemColumn";

class Lines extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lines: this.props.lines,
            line: "",
            lineDetailsMode: false,
        };
    }

    prioritySort(lines) {
        let sorted_lines = [];
        let priorities = ["down", "slow", "normal", "notrunning"];
        for (let priority of priorities) {
            for (let line of lines) {
                if (line.line_status == priority) {
                    sorted_lines.push(line);
                }
            }
        }

        return sorted_lines;
    }

    showLineDetails = (item) => {
        let lines = this.props.lines;
        let position = 0;
        lines.forEach(function callback(line, index, array) {
            if (line.line_id === item.line_id) {
                position = index;
            }
        });

        this.setState({
            line: item,
        });
        this.changeViewType(5, position);
    };

    changeViewType = (type, index) => {
        this.props.changeViewType(type, index);
    };

    getLines() {
        if (this.props.view_type == 1 || this.props.view_type == 3) {
            let lines = this.props.lines;

            if (this.props.view_type == 1) {
                lines = this.prioritySort(lines);
            }
            return (
                <div>
                    {" "}
                    {lines.map((item, index) => (
                        <LineItemColumn
                            key={"line-item-column-" + index}
                            item={item}
                            index={index}
                            preventClick={this.props.preventClick}
                            onStartRunClick={this.props.onStartRunClick}
                            lineDetails={this.showLineDetails.bind(this, item)}
                        />
                    ))}
                    <div className="last_updated_stamp">
                        Last updated: {new Date().toLocaleTimeString()}
                    </div>
                </div>
            );
        }

        if (this.props.view_type == 4) {
            let lines = this.props.lines;
            // lines = this.prioritySort(lines);

            return lines.map((item, index) => (
                <LineItemColumn
                    presentation={true}
                    key={"line-item-column-" + index}
                    item={item}
                    index={index}
                    preventClick={this.props.preventClick}
                    lineDetails={this.showLineDetails.bind(this, item)}
                />
            ));
        }
    }

    render() {
        if (this.state.lines == null) return;
        if (this.props.view_type == 4) {
            return <div className="lines presentation">{this.getLines()}</div>;
        } else {
            return <div className="lines">{this.getLines()}</div>;
        }
    }
}

export default Lines;
