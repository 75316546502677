import React, { Component } from 'react';
import {
    Table, TableBody, TableHeader, TableRow, TableRowColumn
} from "material-ui";
import update from "immutability-helper/index";
import {
    Button,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    MenuItem,
    TextField,
    Step,
    StepContent,
    StepLabel,
    Stepper, DialogContent
} from "@material-ui/core";

class AddReasonModal extends Component {
    constructor(props) {
        super(props);
        this.events = ["Downtime", "Waste", "Rework"];
        this.state = {
            name: '',
            category: '',
            code: '',
            event: '',
            stepIndex: 0,
            type_error: null,
            reason_is_active: true,
            name_error: null,
            category_error: null,
            code_error: null,
            submitting_reason: false,
            updated: false,
            status: null,
            line_list: this.props.lines,
            lineListStatus: []
        }
    }

    /* ######## Lifecycle Methods ######## */
    componentDidMount() {
        this.handleLines()
        this.setKeyListener();
    }

    componentWillUnmount = () => {
        this.cancelKeyListener();
    }

    handleLines = () => {
        let lineListStatus = [];
        let lines = this.props.lines
        if (lines.length > 0) {
            lines.map(line => {
                lineListStatus.push(true)
            })
            this.setState({
                lineListStatus: lineListStatus
            });
        }

    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            if (event.keyCode === 13) {
                this.submitReason()
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }


    handleChange(event) {
        let s = this.state;
        let type = event.target.id;

        s[type] = event.target.value;

        this.setState(s);
    }

    handleCheckboxChange = (event, isInputChecked) => {
        this.setState({
            reason_is_active: isInputChecked
        })
    }

    handleEventSelectChange = (event) => {
        this.setState({
            event: event.target.value
        })
    }

    validateInput() {
        let error_free = true;

        this.setState({
            name_error: null,
            type_error: null
        });

        if (this.state.event === '') {
            error_free = false;
            this.setState({
                type_error: "Please choose a type"
            })
        }

        if (this.state.name === '') {
            error_free = false;
            this.setState({
                name_error: "Name is required"
            })
        }

        return error_free
    }

    submitReason = () => {
        if (!this.validateInput()) {
            return;
        }


        this.setState({
            submitting_reason: true,
            status: null
        });

        let data = {
            name: this.state.name,
            category: this.state.category.length > 0 ? this.state.category : null,
            code: this.state.code,
            is_active: this.state.reason_is_active,
            type: this.events[this.state.event]
        }

        let params = {
            path: "add_downtime_reason",
            data: data,
            success: this.updateReason,
            error: this.submitError
        }

        API.c(params, 2);
    }

    updateReason = (idData) => {
        let activeList = this.getActiveList();
        let inactiveList = this.getInactiveList();

        let data = {
            reason_name: this.state.name,
            reason_category: this.state.category ? this.state.category : null,
            reason_code: this.state.code,
            reason_is_active: this.state.reason_is_active,
            line_ids_to_add: activeList,
            line_ids_to_delete: inactiveList
        }

        let params = {
            path: "update_downtime_reason",
            path_variables: { ID: idData.id },
            data: data,
            success: this.submitSuccess,
            error: this.submitError
        }

        API.c(params, 2);
    }

    iterateCategories = () => {
        if (this.props.allCategories == null || !Array.isArray(this.props.allCategories)) {
            return;
        }
        this.props.allCategories.sort((a, b) => {
            return parseInt(a.name) - parseInt(b.name)
        })
        return this.props.allCategories.map((item, index) => (
            <MenuItem value={item.id} key={'product-' + item.id}>
                {item.name}
            </MenuItem>
        ));
    };

    submitSuccess = (data) => {
        this.props.modified()
        this.props.hideModal()
    }

    submitError = (error) => {
        this.setState({
            status: error
        })
    }

    showEditFields = () => {

        return <div style={{ display: "grid" }}>
            <div>
                <div style={{ marginLeft: '12px', width: '224px' }}>
                    <TextField
                        label="Event Type"
                        fullWidth={true}
                        select
                        value={this.state.event}
                        onChange={this.handleEventSelectChange}
                        helperText={this.state.type_error !== null ? this.state.type_error : ''}
                        error={this.state.type_error !== null}>
                        <MenuItem value={0}>Downtime</MenuItem>
                        <MenuItem value={1}>Waste</MenuItem>
                        <MenuItem value={2}>Rework</MenuItem>
                    </TextField>
                </div>
                <div className="settings__reason-details--add-info-table">
                    <TextField
                        className="settings__reason-details--text-field"
                        id="name"
                        label="Name"
                        value={this.state.name}
                        onChange={this.handleChange.bind(this)}
                        fullWidth={true}
                        helperText={this.state.name_error !== null ? this.state.name_error : ''}
                        error={this.state.name_error !== null} />
                    {/* <TextField
                        className="settings__reason-details--text-field"
                        id="category"
                        label="Category"
                        value={this.state.category}
                        onChange={this.handleChange.bind(this)}
                        fullWidth={true}
                        helperText={this.state.category_error !== null ? this.state.category_error : ''}
                        error={this.state.category_error !== null} /> */}
                    <TextField
                        className="settings__reason-details--text-field"
                        select
                        id="category"
                        label="Category"
                        fullWidth={true}
                        value={this.state.category}
                        onChange={(event) => this.setState({ category: event.target.value })}
                    >
                        {this.iterateCategories()}
                    </TextField>
                </div>
                <div className="settings__reason-details--add-info-table">
                    <TextField
                        className="settings__reason-details--text-field"
                        id="code"
                        label="Code"
                        value={this.state.code}
                        onChange={this.handleChange.bind(this)}
                        fullWidth={true}
                        helperText={this.state.code_error !== null ? this.state.code_error : ''}
                        error={this.state.code_error !== null} />
                    <FormControlLabel control={<Checkbox onChange={this.handleCheckboxChange.bind(this)}
                        color="primary"
                        checked={this.state.reason_is_active} />}
                        id="line_name"
                        label="Active Reason"
                        className="settings__reason-details--text-field"
                        labelPlacement="start" />
                </div>
            </div>
        </div>

    }

    showLines = () => {
        return <div style={{ display: 'inline-grid', marginBottom: "8px" }}>
            <Table>
                <TableHeader
                    displaySelectAll={false}
                    adjustForCheckbox={false}
                    enableSelectAll={false}>
                    <TableRow style={{ height: '20px', paddingTop: '20px' }}>
                        <TableRowColumn style={Styles.tableHeaderStyle()}>Active</TableRowColumn>
                        <TableRowColumn style={Styles.tableHeaderStyle()}>Line Name</TableRowColumn>
                        <TableRowColumn style={Styles.editDeleteTableHeaderStyle()} />
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false}>
                    {this.showLineList()}
                </TableBody>
            </Table>
        </div>
    }

    showLineList = () => {
        let lineList = this.state.line_list;

        return lineList.map((line, index) => {
            return <TableRow
                style={Styles.tableRowStyle(index)}
                key={"line-" + (line.line_id ? line.line_id : line.id)}>
                <TableRowColumn style={Styles.tableRowColumnStyle()}><Checkbox checked={this.state.lineListStatus[index]} onChange={this.onLineCheck.bind(this, index)} /></TableRowColumn>
                <TableRowColumn style={Styles.tableRowColumnStyle()}>{line.line_name ? line.line_name : line.name}</TableRowColumn>
                <TableRowColumn style={Styles.editDeleteTableRowColumnStyle()} />
            </TableRow>
        })
    }

    onLineCheck = (index, isInputChecked) => {
        let isChecked = !this.state.lineListStatus[index];
        let lineListStatus = this.state.lineListStatus;
        let updatedLines = update(lineListStatus, { [index]: { $set: isChecked } })
        this.setState({
            lineListStatus: updatedLines
        })
    }

    getActiveList = () => {
        let activeList = []
        let lineStatus = this.state.lineListStatus
        this.state.line_list.forEach((line, index) => {
            if (lineStatus[index]) {
                activeList.push(line.line_id ? line.line_id : line.id)
            }
        })

        return activeList
    }

    getInactiveList = () => {
        let inactiveList = []
        let lineStatus = this.state.lineListStatus
        this.state.line_list.forEach((line, index) => {
            if (!lineStatus[index]) {
                inactiveList.push(line.line_id ? line.line_id : line.id)
            }
        })

        return inactiveList
    }

    handleNext = () => {
        let { stepIndex } = this.state;
        if (stepIndex === 0) {
            if (!this.validateInput()) {
                return;
            }

            this.setState({
                stepIndex: 1
            });
        } else {
            this.submitReason();
        }
    }

    handlePrev = () => {
        let { stepIndex } = this.state;
        if (stepIndex > 0) {
            this.setState({
                stepIndex: 0,
                name_error: null,
                type_error: null
            });
        }
    };

    showButton = (step) => {
        let { stepIndex } = this.state;
        if (!this.state.submitting_reason) {
            return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'baseline' }}>
                {step > 0 && (
                    <Button variant="text"
                        color="primary"
                        disabled={stepIndex === 0}
                        onClick={this.handlePrev}>Back</Button>
                )}
                <Button variant="contained"
                    color="primary"
                    disabled={this.state.event === ''}
                    onClick={this.handleNext.bind(this)}>{stepIndex === 1 ? 'Submit' : 'Next'}</Button>
            </div>
        } else {
            return <CircularProgress mode="indeterminate" />
        }
    }

    updateStatus = () => {
        if (this.state.status) {
            return <div className="update-status__error">There was an error updating the reason</div>
        } else if (this.state.updated) {
            return <div className="update-status__success">Saved</div>
        } else {
            return <div />
        }
    }


    render() {
        let { stepIndex } = this.state;

        return <DialogContent>
            <Stepper activeStep={stepIndex} orientation="vertical">
                <Step>
                    <StepLabel>Reason Details</StepLabel>
                    <StepContent>
                        {this.showEditFields()}
                        {this.updateStatus()}
                        {this.showButton(0)}
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>Lines</StepLabel>
                    <StepContent>
                        {this.showLines()}
                        {this.updateStatus()}
                        {this.showButton(1)}
                    </StepContent>
                </Step>
            </Stepper>
        </DialogContent>
    }
}

export default AddReasonModal
