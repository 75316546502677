import React, {Component} from 'react';
import { Button, CircularProgress, Checkbox, FormControlLabel } from "@material-ui/core";

class SelectProductModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_products: props.selected_products,
            select_button_text: "Select None"
        }
    }

    componentDidMount() {
        this.setKeyListener();
        if(this.props.selected_products.length === this.props.products.length) {
            this.setState({
                select_button_text: "Select None"
            })
        } else {
            this.setState({
                select_button_text: "Select All"
            })
        }
    }

    componentWillUnmount() {
        this.cancelKeyListener()
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            event.preventDefault()
            if (event.key === 'Enter') {
                if(this.state.selected_products[0]) {
                    this.hideSelectProducts()
                }
            } else if(event.key === "Escape") {
                this.props.cancelSelectProducts()
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }

    handleAllProductsClick = () => {
        let updatedSelectedProducts = this.state.selected_products
        let select_button_text
        if(updatedSelectedProducts.length === this.props.products.length) {
            updatedSelectedProducts = []
            select_button_text = "Select All"
        } else {
            updatedSelectedProducts.length = 0;
            this.props.products.forEach(product => {
                updatedSelectedProducts.push(product.product_id)
            })
            select_button_text = "Select None"
        }
        this.setState({
            selected_products: updatedSelectedProducts,
            select_button_text
        })
    }

    onProductCheck = (index, event, isInputChecked) => {
        let updatedSelectedProducts = Array.from(this.state.selected_products);
        if(!isInputChecked) {
            let i = updatedSelectedProducts.indexOf(this.props.products[index].product_id);
            if (i !== -1) updatedSelectedProducts.splice(i, 1);
        } else {
            updatedSelectedProducts.push(this.props.products[index].product_id)
        }
        let select_button_text;
        if(updatedSelectedProducts.length === this.props.products) {
            select_button_text = "Select None"
        } else {
            select_button_text = "Select All"
        }
        this.setState({
            selected_products: updatedSelectedProducts,
            select_button_text
        })
    }

    hideSelectProducts = () => {
        if(this.state.selected_products[0]) {
            this.props.hideSelectProducts(this.state.selected_products)
        }
    }

    renderProducts = () => {
        if(!this.props.products[0]) {
            return <div className="reports__loading">
                <CircularProgress />
            </div>
        }

        return this.props.products.map((product, index) => {
            return <FormControlLabel className="select-products-body-item"
                                     control={<Checkbox checked={this.state.selected_products.indexOf(product.product_id) > -1}
                                                        color={"primary"}
                                                        onChange={this.onProductCheck.bind(this, index)}/>}
                                     label={product.display_name}
                                     key={"product-"+product.product_id}/>
        })
    }


    render() {

        //This style object is to move the modal on the horizontally to match the open on top of the dropdown field
        let containerStyle = {}
        if(this.props.left) {
            containerStyle['left'] = this.props.left
        }

        return <div>
            <div
                className="select-products"
                onClick={this.hideSelectProducts}
            />
            <div className="select-products-container" style={containerStyle}>
                <div className="select-products-header">
                    <div style={{flex: 1, marginLeft: "24px"}}>
                        <div className="select-products-header-button modal__content-title__text">Product Filter</div>
                    </div>
                    <Button variant="text"
                            color="primary"
                            className="select-products-header-button"
                            onClick={this.handleAllProductsClick}>{this.state.select_button_text}</Button>
                    <Button variant="contained"
                            color="primary"
                            className="select-products-header-button"
                            onClick={this.hideSelectProducts}
                            disabled={!this.state.selected_products[0]}>Filter</Button>
                </div>
                <div className="select-products-body">
                    {this.renderProducts()}
                </div>

            </div>
        </div>
    }
}

export default SelectProductModal