import React, { Component } from 'react';
import update from "immutability-helper/index";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";

class FilterDowntimeCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: props.categories,
            select_button_text: "",
            all_selected: true
        }
    }

    componentDidMount() {
        this.isAllSelected()
        this.setKeyListener();
    }

    componentWillUnmount = () => {
        this.cancelKeyListener();
    }

    setKeyListener = () => {
        window.onkeydown = (event) => {
            if (event.keyCode === 13) {
                this.filter()
            } else if (event.keyCode === 27) {
                this.props.hideModal();
            }
        };
    }

    cancelKeyListener = () => {
        window.onkeydown = null;
    }

    isAllSelected = () => {
        let all_selected = true
        this.state.categories.forEach(cat => {
            if (!cat[1]) {
                all_selected = false
            }
        })

        let select_button_text = all_selected ? "Select None" : "Select All"

        this.setState({
            select_button_text,
            all_selected
        })
    }

    filter = () => {
        this.props.onFilterPressed(this.state.categories)
        this.props.hideModal()
    }

    onCheck = (index, event, isInputChecked) => {
        let oldCategories = Array.from(this.state.categories);
        let updatedCategories = update(oldCategories, { [index]: { [1]: { $set: isInputChecked } } })
        this.setState({
            categories: updatedCategories
        }, this.isAllSelected)
    }

    handleSelectAllNoneClick = () => {
        let categories = []
        if (this.state.all_selected) {
            this.state.categories.forEach(cat => {
                let category = cat[0]
                categories.push([category, false])
            })
        } else {
            this.state.categories.forEach(cat => {
                let category = cat[0]
                categories.push([category, true])
            })
        }

        this.setState({
            categories
        }, this.isAllSelected)
    }

    /* ######## RENDERS ######## */

    renderCategoriesChecklist = () => {

        if (this.state.categories != null) {
            return this.state.categories.map(((category, index) => {
                return <FormControlLabel control={<Checkbox key={"category-" + category[0]}
                    onChange={this.onCheck.bind(this, index)}
                    checked={category[1]} />}
                    style={{ marginBottom: 16 }}
                    labelPlacement="start"
                    label={category[0]} />
            }))
        }
    }

    showButtons = () => {
        return <div className="edit-run-buttons__wrapper">
            <Button variant="text"
                color="primary"
                className="select-lines-header-button"
                onClick={this.handleSelectAllNoneClick}>{this.state.select_button_text}</Button>
            <Button variant="contained"
                color="primary"
                onClick={this.filter}>Filter</Button>
        </div>
    }


    render() {
        return <div>
            {this.renderCategoriesChecklist()}
            {this.showButtons()}
        </div>
    }
}

export default FilterDowntimeCategories