import React, {Component} from 'react';
import DeleteConfirmationModal from "../../../modals/DeleteConfirmationModal";
import Modal from "../../Modal";
import AddEditFactoryUnit from "../../../modals/AddEditFactoryUnit";
import {Button, Dialog, DialogTitle, Typography, Fab } from "@material-ui/core";
import {Add, AddOutlined} from "@material-ui/icons";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

class UnitsSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_modal: null,
            edit_unit: null
        }
    }

    /* -------- LIFECYCLE METHODS -------- */


    /* -------- FUNCTIONS -------- */
    showAddUnitDialog = () => {
        this.setState({
            show_modal: 'add_unit'
        })
    }

    openUnitDetails = (unit) => {
        this.setState({
            show_modal: 'edit_unit',
            edit_unit: unit
        })
    }

    showDeleteConfirmation = (unit) => {
        this.setState({
            show_modal: 'delete_unit',
            edit_unit: unit
        })
    }

    hideModal = () => {
        this.setState({
            show_modal: null,
            edit_unit: null
        })
        this.props.getFactoryUnits()
    };

    modified = () => {
        this.props.modified()
    }

    /* -------- API CALLS -------- */


    /* -------- RENDERS -------- */
    modal() {
        if (!this.state.show_modal) {
            return <div/>;
        } else if (this.state.show_modal === "delete_unit") {
            return <Modal title={this.getDialogTitle()} hideModal={this.hideModal} class_name='__add-line'>
                {this.getModal()}
            </Modal>;
        } else {
            return <Dialog open={true} onClose={this.hideModal} scroll="body">
                {this.getDialogTitle()}
                {this.getModal()}
            </Dialog>
        }
    }

    getDialogTitle = () => {
        switch (this.state.show_modal) {
            case "add_unit":
                return <DialogTitle disableTypography>
                    <Typography variant="subtitle1">Add New Unit</Typography>
                </DialogTitle>
            case "edit_unit":
                return <DialogTitle disableTypography>
                    <Typography variant="subtitle1">Edit Unit</Typography>
                </DialogTitle>
            case "delete_unit":
                return "Archive Unit";
        }
    }

    getModal = () => {
        switch (this.state.show_modal) {
            case "add_unit":
                return <AddEditFactoryUnit hideModal={this.hideModal} modified={this.modified}/>;
            case "edit_unit":
                return <AddEditFactoryUnit hideModal={this.hideModal} modified={this.modified} edit edit_unit={this.state.edit_unit}/>;
            case "delete_unit":
                return <DeleteConfirmationModal hideModal={this.hideModal} modified={() => {console.log("modified")}}
                                                item_deleted_text={this.state.edit_unit.name}
                                                archive
                                                delete_path="archive_unit"
                                                id={this.state.edit_unit.id}/>
        }
    }

    renderUnitsTable = () => {
        let {units} = this.props;

        return units.map((item, index) =>
            <TableRow sx={{ wordBreak: "break-word", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
                key={"unit-"+item.id}>
                <TableCell align='left' style={Styles.tableRowColumnStyle()}>{item.name}</TableCell>
                <TableCell align='left' style={Styles.tableRowColumnStyle()}>{item.nickname}</TableCell>
                <TableCell align='right' style={Styles.editDeleteTableRowColumnStyle()}>
                    <div>
                        <Button variant="text"
                                color="primary"
                                onClick={this.openUnitDetails.bind(this, item)}>EDIT</Button>
                        <Button variant="text"
                                color="secondary"
                                onClick={this.showDeleteConfirmation.bind(this, item)}>ARCHIVE</Button>
                    </div>
                </TableCell>
            </TableRow>)
    }

    render() {
        return <div className="settings__table">
            <Fab color="secondary" className="settings_units_fab" onClick={this.showAddUnitDialog}>
                <AddOutlined/>
            </Fab>
            <Typography variant='subtitle2' style={{marginBottom: '26px'}}>Units Settings</Typography>
            <div className="flex-box__row">
                <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='left' style={Styles.tableHeaderStyle()}>Name</TableCell>
                            <TableCell align='left' style={Styles.tableHeaderStyle()}>Shorthand</TableCell>
                            <TableCell align='right' style={Styles.editDeleteTableHeaderStyle()}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderUnitsTable()}
                    </TableBody>
                </Table>
                </TableContainer>
            </div>
            {this.modal()}
        </div>
    }
}

export default UnitsSettings